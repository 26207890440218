(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.gp.dg === region.hn.dg)
	{
		return 'on line ' + region.gp.dg;
	}
	return 'on lines ' + region.gp.dg + ' through ' + region.hn.dg;
}



// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.lS,
		impl.oQ,
		impl.oj,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	var initPair = init(result.a);
	var model = initPair.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		var pair = A2(update, msg, model);
		stepper(model = pair.a, viewMetadata);
		_Platform_enqueueEffects(managers, pair.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, initPair.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		mp: func(record.mp),
		ja: record.ja,
		iu: record.iu
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.mp;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.ja;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.iu) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.lS,
		impl.oQ,
		impl.oj,
		function(sendToApp, initialModel) {
			var view = impl.oZ;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.lS,
		impl.oQ,
		impl.oj,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.gi && impl.gi(sendToApp)
			var view = impl.oZ;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.kc);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.oI) && (_VirtualDom_doc.title = title = doc.oI);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.nc;
	var onUrlRequest = impl.nd;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		gi: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.iB === next.iB
							&& curr.hJ === next.hJ
							&& curr.iq.a === next.iq.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		lS: function(flags)
		{
			return A3(impl.lS, flags, _Browser_getUrl(), key);
		},
		oZ: impl.oZ,
		oQ: impl.oQ,
		oj: impl.oj
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { lJ: 'hidden', kt: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { lJ: 'mozHidden', kt: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { lJ: 'msHidden', kt: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { lJ: 'webkitHidden', kt: 'webkitvisibilitychange' }
		: { lJ: 'hidden', kt: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		iU: _Browser_getScene(),
		o_: {
			D: _Browser_window.pageXOffset,
			E: _Browser_window.pageYOffset,
			fg: _Browser_doc.documentElement.clientWidth,
			en: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		fg: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		en: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			iU: {
				fg: node.scrollWidth,
				en: node.scrollHeight
			},
			o_: {
				D: node.scrollLeft,
				E: node.scrollTop,
				fg: node.clientWidth,
				en: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			iU: _Browser_getScene(),
			o_: {
				D: x,
				E: y,
				fg: _Browser_doc.documentElement.clientWidth,
				en: _Browser_doc.documentElement.clientHeight
			},
			hm: {
				D: x + rect.left,
				E: y + rect.top,
				fg: rect.width,
				en: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.lq.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.lq.b, xhr)); });
		$elm$core$Maybe$isJust(request.oO) && _Http_track(router, xhr, request.oO.a);

		try {
			xhr.open(request.mr, request.oU, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.oU));
		}

		_Http_configureRequest(xhr, request);

		request.kc.a && xhr.setRequestHeader('Content-Type', request.kc.a);
		xhr.send(request.kc.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.hF; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.oH.a || 0;
	xhr.responseType = request.lq.d;
	xhr.withCredentials = request.jW;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		oU: xhr.responseURL,
		od: xhr.status,
		oe: xhr.statusText,
		hF: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			n0: event.loaded,
			gl: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			nG: event.loaded,
			gl: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}



// STRINGS


var _Parser_isSubString = F5(function(smallString, offset, row, col, bigString)
{
	var smallLength = smallString.length;
	var isGood = offset + smallLength <= bigString.length;

	for (var i = 0; isGood && i < smallLength; )
	{
		var code = bigString.charCodeAt(offset);
		isGood =
			smallString[i++] === bigString[offset++]
			&& (
				code === 0x000A /* \n */
					? ( row++, col=1 )
					: ( col++, (code & 0xF800) === 0xD800 ? smallString[i++] === bigString[offset++] : 1 )
			)
	}

	return _Utils_Tuple3(isGood ? offset : -1, row, col);
});



// CHARS


var _Parser_isSubChar = F3(function(predicate, offset, string)
{
	return (
		string.length <= offset
			? -1
			:
		(string.charCodeAt(offset) & 0xF800) === 0xD800
			? (predicate(_Utils_chr(string.substr(offset, 2))) ? offset + 2 : -1)
			:
		(predicate(_Utils_chr(string[offset]))
			? ((string[offset] === '\n') ? -2 : (offset + 1))
			: -1
		)
	);
});


var _Parser_isAsciiCode = F3(function(code, offset, string)
{
	return string.charCodeAt(offset) === code;
});



// NUMBERS


var _Parser_chompBase10 = F2(function(offset, string)
{
	for (; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (code < 0x30 || 0x39 < code)
		{
			return offset;
		}
	}
	return offset;
});


var _Parser_consumeBase = F3(function(base, offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var digit = string.charCodeAt(offset) - 0x30;
		if (digit < 0 || base <= digit) break;
		total = base * total + digit;
	}
	return _Utils_Tuple2(offset, total);
});


var _Parser_consumeBase16 = F2(function(offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (0x30 <= code && code <= 0x39)
		{
			total = 16 * total + code - 0x30;
		}
		else if (0x41 <= code && code <= 0x46)
		{
			total = 16 * total + code - 55;
		}
		else if (0x61 <= code && code <= 0x66)
		{
			total = 16 * total + code - 87;
		}
		else
		{
			break;
		}
	}
	return _Utils_Tuple2(offset, total);
});



// FIND STRING


var _Parser_findSubString = F5(function(smallString, offset, row, col, bigString)
{
	var newOffset = bigString.indexOf(smallString, offset);
	var target = newOffset < 0 ? bigString.length : newOffset + smallString.length;

	while (offset < target)
	{
		var code = bigString.charCodeAt(offset++);
		code === 0x000A /* \n */
			? ( col=1, row++ )
			: ( col++, (code & 0xF800) === 0xD800 && offset++ )
	}

	return _Utils_Tuple3(newOffset, row, col);
});


// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.mC) { flags += 'm'; }
	if (options.ks) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;



var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});



// DECODER

var _File_decoder = _Json_decodePrim(function(value) {
	// NOTE: checks if `File` exists in case this is run on node
	return (typeof File !== 'undefined' && value instanceof File)
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FILE', value);
});


// METADATA

function _File_name(file) { return file.name; }
function _File_mime(file) { return file.type; }
function _File_size(file) { return file.size; }

function _File_lastModified(file)
{
	return $elm$time$Time$millisToPosix(file.lastModified);
}


// DOWNLOAD

var _File_downloadNode;

function _File_getDownloadNode()
{
	return _File_downloadNode || (_File_downloadNode = document.createElement('a'));
}

var _File_download = F3(function(name, mime, content)
{
	return _Scheduler_binding(function(callback)
	{
		var blob = new Blob([content], {type: mime});

		// for IE10+
		if (navigator.msSaveOrOpenBlob)
		{
			navigator.msSaveOrOpenBlob(blob, name);
			return;
		}

		// for HTML5
		var node = _File_getDownloadNode();
		var objectUrl = URL.createObjectURL(blob);
		node.href = objectUrl;
		node.download = name;
		_File_click(node);
		URL.revokeObjectURL(objectUrl);
	});
});

function _File_downloadUrl(href)
{
	return _Scheduler_binding(function(callback)
	{
		var node = _File_getDownloadNode();
		node.href = href;
		node.download = '';
		node.origin === location.origin || (node.target = '_blank');
		_File_click(node);
	});
}


// IE COMPATIBILITY

function _File_makeBytesSafeForInternetExplorer(bytes)
{
	// only needed by IE10 and IE11 to fix https://github.com/elm/file/issues/10
	// all other browsers can just run `new Blob([bytes])` directly with no problem
	//
	return new Uint8Array(bytes.buffer, bytes.byteOffset, bytes.byteLength);
}

function _File_click(node)
{
	// only needed by IE10 and IE11 to fix https://github.com/elm/file/issues/11
	// all other browsers have MouseEvent and do not need this conditional stuff
	//
	if (typeof MouseEvent === 'function')
	{
		node.dispatchEvent(new MouseEvent('click'));
	}
	else
	{
		var event = document.createEvent('MouseEvents');
		event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
		document.body.appendChild(node);
		node.dispatchEvent(event);
		document.body.removeChild(node);
	}
}


// UPLOAD

var _File_node;

function _File_uploadOne(mimes)
{
	return _Scheduler_binding(function(callback)
	{
		_File_node = document.createElement('input');
		_File_node.type = 'file';
		_File_node.accept = A2($elm$core$String$join, ',', mimes);
		_File_node.addEventListener('change', function(event)
		{
			callback(_Scheduler_succeed(event.target.files[0]));
		});
		_File_click(_File_node);
	});
}

function _File_uploadOneOrMore(mimes)
{
	return _Scheduler_binding(function(callback)
	{
		_File_node = document.createElement('input');
		_File_node.type = 'file';
		_File_node.multiple = true;
		_File_node.accept = A2($elm$core$String$join, ',', mimes);
		_File_node.addEventListener('change', function(event)
		{
			var elmFiles = _List_fromArray(event.target.files);
			callback(_Scheduler_succeed(_Utils_Tuple2(elmFiles.a, elmFiles.b)));
		});
		_File_click(_File_node);
	});
}


// CONTENT

function _File_toString(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(reader.result));
		});
		reader.readAsText(blob);
		return function() { reader.abort(); };
	});
}

function _File_toBytes(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(new DataView(reader.result)));
		});
		reader.readAsArrayBuffer(blob);
		return function() { reader.abort(); };
	});
}

function _File_toUrl(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(reader.result));
		});
		reader.readAsDataURL(blob);
		return function() { reader.abort(); };
	});
}

var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$List$cons = _List_cons;
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.v) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.y),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.y);
		} else {
			var treeLen = builder.v * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.B) : builder.B;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.v);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.y) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.y);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{B: nodeList, v: (len / $elm$core$Array$branchFactor) | 0, y: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {hv: fragment, hJ: host, io: path, iq: port_, iB: protocol, iC: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$document = _Browser_document;
var $elm$json$Json$Decode$field = _Json_decodeField;
var $author$project$Main$GotInitData = $elm$core$Basics$identity;
var $author$project$Components$Notification$Inactive = {$: 0};
var $author$project$Main$Initializing = function (a) {
	return {$: 0, a: a};
};
var $author$project$Main$Msg_Initializing = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Platform$Cmd$map = _Platform_map;
var $justinmimbs$date$Date$RD = $elm$core$Basics$identity;
var $elm$core$Basics$clamp = F3(
	function (low, high, number) {
		return (_Utils_cmp(number, low) < 0) ? low : ((_Utils_cmp(number, high) > 0) ? high : number);
	});
var $elm$core$Basics$modBy = _Basics_modBy;
var $elm$core$Basics$neq = _Utils_notEqual;
var $justinmimbs$date$Date$isLeapYear = function (y) {
	return ((!A2($elm$core$Basics$modBy, 4, y)) && (!(!A2($elm$core$Basics$modBy, 100, y)))) || (!A2($elm$core$Basics$modBy, 400, y));
};
var $justinmimbs$date$Date$daysBeforeMonth = F2(
	function (y, m) {
		var leapDays = $justinmimbs$date$Date$isLeapYear(y) ? 1 : 0;
		switch (m) {
			case 0:
				return 0;
			case 1:
				return 31;
			case 2:
				return 59 + leapDays;
			case 3:
				return 90 + leapDays;
			case 4:
				return 120 + leapDays;
			case 5:
				return 151 + leapDays;
			case 6:
				return 181 + leapDays;
			case 7:
				return 212 + leapDays;
			case 8:
				return 243 + leapDays;
			case 9:
				return 273 + leapDays;
			case 10:
				return 304 + leapDays;
			default:
				return 334 + leapDays;
		}
	});
var $justinmimbs$date$Date$floorDiv = F2(
	function (a, b) {
		return $elm$core$Basics$floor(a / b);
	});
var $justinmimbs$date$Date$daysBeforeYear = function (y1) {
	var y = y1 - 1;
	var leapYears = (A2($justinmimbs$date$Date$floorDiv, y, 4) - A2($justinmimbs$date$Date$floorDiv, y, 100)) + A2($justinmimbs$date$Date$floorDiv, y, 400);
	return (365 * y) + leapYears;
};
var $justinmimbs$date$Date$daysInMonth = F2(
	function (y, m) {
		switch (m) {
			case 0:
				return 31;
			case 1:
				return $justinmimbs$date$Date$isLeapYear(y) ? 29 : 28;
			case 2:
				return 31;
			case 3:
				return 30;
			case 4:
				return 31;
			case 5:
				return 30;
			case 6:
				return 31;
			case 7:
				return 31;
			case 8:
				return 30;
			case 9:
				return 31;
			case 10:
				return 30;
			default:
				return 31;
		}
	});
var $justinmimbs$date$Date$fromCalendarDate = F3(
	function (y, m, d) {
		return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + A3(
			$elm$core$Basics$clamp,
			1,
			A2($justinmimbs$date$Date$daysInMonth, y, m),
			d);
	});
var $elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$floor(numerator / denominator);
	});
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.gp, posixMinutes) < 0) {
					return posixMinutes + era.f1;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var $elm$time$Time$toAdjustedMinutes = F2(
	function (_v0, time) {
		var defaultOffset = _v0.a;
		var eras = _v0.b;
		return A3(
			$elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var $elm$core$Basics$ge = _Utils_ge;
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2($elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		d5: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		mw: month,
		fi: year + ((month <= 2) ? 1 : 0)
	};
};
var $elm$time$Time$toDay = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).d5;
	});
var $elm$time$Time$Apr = 3;
var $elm$time$Time$Aug = 7;
var $elm$time$Time$Dec = 11;
var $elm$time$Time$Feb = 1;
var $elm$time$Time$Jan = 0;
var $elm$time$Time$Jul = 6;
var $elm$time$Time$Jun = 5;
var $elm$time$Time$Mar = 2;
var $elm$time$Time$May = 4;
var $elm$time$Time$Nov = 10;
var $elm$time$Time$Oct = 9;
var $elm$time$Time$Sep = 8;
var $elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _v0 = $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).mw;
		switch (_v0) {
			case 1:
				return 0;
			case 2:
				return 1;
			case 3:
				return 2;
			case 4:
				return 3;
			case 5:
				return 4;
			case 6:
				return 5;
			case 7:
				return 6;
			case 8:
				return 7;
			case 9:
				return 8;
			case 10:
				return 9;
			case 11:
				return 10;
			default:
				return 11;
		}
	});
var $elm$time$Time$toYear = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).fi;
	});
var $justinmimbs$date$Date$fromPosix = F2(
	function (zone, posix) {
		return A3(
			$justinmimbs$date$Date$fromCalendarDate,
			A2($elm$time$Time$toYear, zone, posix),
			A2($elm$time$Time$toMonth, zone, posix),
			A2($elm$time$Time$toDay, zone, posix));
	});
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$here = _Time_here(0);
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $justinmimbs$date$Date$today = A3($elm$core$Task$map2, $justinmimbs$date$Date$fromPosix, $elm$time$Time$here, $elm$time$Time$now);
var $author$project$Main$init_Initializing = function (flags) {
	var initialModel = {
		P: $author$project$Components$Notification$Inactive,
		_: $author$project$Main$Initializing(
			{fK: flags})
	};
	return _Utils_Tuple2(
		initialModel,
		A2(
			$elm$core$Platform$Cmd$map,
			$author$project$Main$Msg_Initializing,
			A2($elm$core$Task$perform, $elm$core$Basics$identity, $justinmimbs$date$Date$today)));
};
var $author$project$Main$init = function (flags) {
	return $author$project$Main$init_Initializing(flags);
};
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$Main$Msg_Authenticating = function (a) {
	return {$: 1, a: a};
};
var $author$project$Main$Msg_LoggedIn = function (a) {
	return {$: 2, a: a};
};
var $author$project$Main$Msg_TouchEventDetected = {$: 3};
var $author$project$Main$TimePassed = function (a) {
	return {$: 4, a: a};
};
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $elm$time$Time$Every = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$State = F2(
	function (taggers, processes) {
		return {iy: processes, jc: taggers};
	});
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$time$Time$init = $elm$core$Task$succeed(
	A2($elm$time$Time$State, $elm$core$Dict$empty, $elm$core$Dict$empty));
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$time$Time$addMySub = F2(
	function (_v0, state) {
		var interval = _v0.a;
		var tagger = _v0.b;
		var _v1 = A2($elm$core$Dict$get, interval, state);
		if (_v1.$ === 1) {
			return A3(
				$elm$core$Dict$insert,
				interval,
				_List_fromArray(
					[tagger]),
				state);
		} else {
			var taggers = _v1.a;
			return A3(
				$elm$core$Dict$insert,
				interval,
				A2($elm$core$List$cons, tagger, taggers),
				state);
		}
	});
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$time$Time$setInterval = _Time_setInterval;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$time$Time$spawnHelp = F3(
	function (router, intervals, processes) {
		if (!intervals.b) {
			return $elm$core$Task$succeed(processes);
		} else {
			var interval = intervals.a;
			var rest = intervals.b;
			var spawnTimer = $elm$core$Process$spawn(
				A2(
					$elm$time$Time$setInterval,
					interval,
					A2($elm$core$Platform$sendToSelf, router, interval)));
			var spawnRest = function (id) {
				return A3(
					$elm$time$Time$spawnHelp,
					router,
					rest,
					A3($elm$core$Dict$insert, interval, id, processes));
			};
			return A2($elm$core$Task$andThen, spawnRest, spawnTimer);
		}
	});
var $elm$time$Time$onEffects = F3(
	function (router, subs, _v0) {
		var processes = _v0.iy;
		var rightStep = F3(
			function (_v6, id, _v7) {
				var spawns = _v7.a;
				var existing = _v7.b;
				var kills = _v7.c;
				return _Utils_Tuple3(
					spawns,
					existing,
					A2(
						$elm$core$Task$andThen,
						function (_v5) {
							return kills;
						},
						$elm$core$Process$kill(id)));
			});
		var newTaggers = A3($elm$core$List$foldl, $elm$time$Time$addMySub, $elm$core$Dict$empty, subs);
		var leftStep = F3(
			function (interval, taggers, _v4) {
				var spawns = _v4.a;
				var existing = _v4.b;
				var kills = _v4.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, interval, spawns),
					existing,
					kills);
			});
		var bothStep = F4(
			function (interval, taggers, id, _v3) {
				var spawns = _v3.a;
				var existing = _v3.b;
				var kills = _v3.c;
				return _Utils_Tuple3(
					spawns,
					A3($elm$core$Dict$insert, interval, id, existing),
					kills);
			});
		var _v1 = A6(
			$elm$core$Dict$merge,
			leftStep,
			bothStep,
			rightStep,
			newTaggers,
			processes,
			_Utils_Tuple3(
				_List_Nil,
				$elm$core$Dict$empty,
				$elm$core$Task$succeed(0)));
		var spawnList = _v1.a;
		var existingDict = _v1.b;
		var killTask = _v1.c;
		return A2(
			$elm$core$Task$andThen,
			function (newProcesses) {
				return $elm$core$Task$succeed(
					A2($elm$time$Time$State, newTaggers, newProcesses));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v2) {
					return A3($elm$time$Time$spawnHelp, router, spawnList, existingDict);
				},
				killTask));
	});
var $elm$time$Time$onSelfMsg = F3(
	function (router, interval, state) {
		var _v0 = A2($elm$core$Dict$get, interval, state.jc);
		if (_v0.$ === 1) {
			return $elm$core$Task$succeed(state);
		} else {
			var taggers = _v0.a;
			var tellTaggers = function (time) {
				return $elm$core$Task$sequence(
					A2(
						$elm$core$List$map,
						function (tagger) {
							return A2(
								$elm$core$Platform$sendToApp,
								router,
								tagger(time));
						},
						taggers));
			};
			return A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$succeed(state);
				},
				A2($elm$core$Task$andThen, tellTaggers, $elm$time$Time$now));
		}
	});
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$time$Time$subMap = F2(
	function (f, _v0) {
		var interval = _v0.a;
		var tagger = _v0.b;
		return A2(
			$elm$time$Time$Every,
			interval,
			A2($elm$core$Basics$composeL, f, tagger));
	});
_Platform_effectManagers['Time'] = _Platform_createManager($elm$time$Time$init, $elm$time$Time$onEffects, $elm$time$Time$onSelfMsg, 0, $elm$time$Time$subMap);
var $elm$time$Time$subscription = _Platform_leaf('Time');
var $elm$time$Time$every = F2(
	function (interval, tagger) {
		return $elm$time$Time$subscription(
			A2($elm$time$Time$Every, interval, tagger));
	});
var $author$project$Main$isTouchEventDetected = function (model) {
	var _v0 = model._;
	switch (_v0.$) {
		case 0:
			return false;
		case 1:
			return false;
		default:
			var loggedInModel = _v0.a;
			return loggedInModel.q.gy;
	}
};
var $elm$core$Platform$Sub$map = _Platform_map;
var $elm$core$Platform$Sub$none = $elm$core$Platform$Sub$batch(_List_Nil);
var $author$project$Main$GotLoginPageMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$Decode$keyDecoder = A2($elm$json$Json$Decode$field, 'key', $elm$json$Json$Decode$string);
var $author$project$Pages$Login$LoginButtonClicked = {$: 0};
var $author$project$Pages$Login$NoOp = {$: 5};
var $author$project$Pages$Login$keyToMsg = function (keyString) {
	if (keyString === 'Enter') {
		return $author$project$Pages$Login$LoginButtonClicked;
	} else {
		return $author$project$Pages$Login$NoOp;
	}
};
var $elm$browser$Browser$Events$Document = 0;
var $elm$browser$Browser$Events$MySub = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$browser$Browser$Events$State = F2(
	function (subs, pids) {
		return {ip: pids, jb: subs};
	});
var $elm$browser$Browser$Events$init = $elm$core$Task$succeed(
	A2($elm$browser$Browser$Events$State, _List_Nil, $elm$core$Dict$empty));
var $elm$browser$Browser$Events$nodeToKey = function (node) {
	if (!node) {
		return 'd_';
	} else {
		return 'w_';
	}
};
var $elm$browser$Browser$Events$addKey = function (sub) {
	var node = sub.a;
	var name = sub.b;
	return _Utils_Tuple2(
		_Utils_ap(
			$elm$browser$Browser$Events$nodeToKey(node),
			name),
		sub);
};
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $elm$browser$Browser$Events$Event = F2(
	function (key, event) {
		return {hp: event, l7: key};
	});
var $elm$browser$Browser$Events$spawn = F3(
	function (router, key, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var actualNode = function () {
			if (!node) {
				return _Browser_doc;
			} else {
				return _Browser_window;
			}
		}();
		return A2(
			$elm$core$Task$map,
			function (value) {
				return _Utils_Tuple2(key, value);
			},
			A3(
				_Browser_on,
				actualNode,
				name,
				function (event) {
					return A2(
						$elm$core$Platform$sendToSelf,
						router,
						A2($elm$browser$Browser$Events$Event, key, event));
				}));
	});
var $elm$core$Dict$union = F2(
	function (t1, t2) {
		return A3($elm$core$Dict$foldl, $elm$core$Dict$insert, t2, t1);
	});
var $elm$browser$Browser$Events$onEffects = F3(
	function (router, subs, state) {
		var stepRight = F3(
			function (key, sub, _v6) {
				var deads = _v6.a;
				var lives = _v6.b;
				var news = _v6.c;
				return _Utils_Tuple3(
					deads,
					lives,
					A2(
						$elm$core$List$cons,
						A3($elm$browser$Browser$Events$spawn, router, key, sub),
						news));
			});
		var stepLeft = F3(
			function (_v4, pid, _v5) {
				var deads = _v5.a;
				var lives = _v5.b;
				var news = _v5.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, pid, deads),
					lives,
					news);
			});
		var stepBoth = F4(
			function (key, pid, _v2, _v3) {
				var deads = _v3.a;
				var lives = _v3.b;
				var news = _v3.c;
				return _Utils_Tuple3(
					deads,
					A3($elm$core$Dict$insert, key, pid, lives),
					news);
			});
		var newSubs = A2($elm$core$List$map, $elm$browser$Browser$Events$addKey, subs);
		var _v0 = A6(
			$elm$core$Dict$merge,
			stepLeft,
			stepBoth,
			stepRight,
			state.ip,
			$elm$core$Dict$fromList(newSubs),
			_Utils_Tuple3(_List_Nil, $elm$core$Dict$empty, _List_Nil));
		var deadPids = _v0.a;
		var livePids = _v0.b;
		var makeNewPids = _v0.c;
		return A2(
			$elm$core$Task$andThen,
			function (pids) {
				return $elm$core$Task$succeed(
					A2(
						$elm$browser$Browser$Events$State,
						newSubs,
						A2(
							$elm$core$Dict$union,
							livePids,
							$elm$core$Dict$fromList(pids))));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$sequence(makeNewPids);
				},
				$elm$core$Task$sequence(
					A2($elm$core$List$map, $elm$core$Process$kill, deadPids))));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$browser$Browser$Events$onSelfMsg = F3(
	function (router, _v0, state) {
		var key = _v0.l7;
		var event = _v0.hp;
		var toMessage = function (_v2) {
			var subKey = _v2.a;
			var _v3 = _v2.b;
			var node = _v3.a;
			var name = _v3.b;
			var decoder = _v3.c;
			return _Utils_eq(subKey, key) ? A2(_Browser_decodeEvent, decoder, event) : $elm$core$Maybe$Nothing;
		};
		var messages = A2($elm$core$List$filterMap, toMessage, state.jb);
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Platform$sendToApp(router),
					messages)));
	});
var $elm$browser$Browser$Events$subMap = F2(
	function (func, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var decoder = _v0.c;
		return A3(
			$elm$browser$Browser$Events$MySub,
			node,
			name,
			A2($elm$json$Json$Decode$map, func, decoder));
	});
_Platform_effectManagers['Browser.Events'] = _Platform_createManager($elm$browser$Browser$Events$init, $elm$browser$Browser$Events$onEffects, $elm$browser$Browser$Events$onSelfMsg, 0, $elm$browser$Browser$Events$subMap);
var $elm$browser$Browser$Events$subscription = _Platform_leaf('Browser.Events');
var $elm$browser$Browser$Events$on = F3(
	function (node, name, decoder) {
		return $elm$browser$Browser$Events$subscription(
			A3($elm$browser$Browser$Events$MySub, node, name, decoder));
	});
var $elm$browser$Browser$Events$onKeyPress = A2($elm$browser$Browser$Events$on, 0, 'keypress');
var $author$project$Pages$Login$subscriptions = $elm$core$Platform$Sub$batch(
	_List_fromArray(
		[
			$elm$browser$Browser$Events$onKeyPress(
			A2($elm$json$Json$Decode$map, $author$project$Pages$Login$keyToMsg, $author$project$Decode$keyDecoder))
		]));
var $author$project$Main$subscriptions_Authenticating = function (_v0) {
	return A2($elm$core$Platform$Sub$map, $author$project$Main$GotLoginPageMsg, $author$project$Pages$Login$subscriptions);
};
var $author$project$Main$subscriptions_Initializing = function (_v0) {
	return $elm$core$Platform$Sub$none;
};
var $author$project$Main$CheckLoginStatus = {$: 2};
var $author$project$Main$DayChanged = function (a) {
	return {$: 4, a: a};
};
var $author$project$Main$GotHolidaysPageMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$Main$GotReportingPageMsg = function (a) {
	return {$: 10, a: a};
};
var $author$project$Main$GotTasklistPageMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$Main$GotWorkTimeTrackingPageMsg = function (a) {
	return {$: 8, a: a};
};
var $author$project$Main$NoOp = {$: 12};
var $author$project$Main$TokenRemoved = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $justinmimbs$timezone_data$TimeZone$Specification$DateTime = F5(
	function (year, month, day, time, clock) {
		return {aY: clock, d5: day, mw: month, e6: time, fi: year};
	});
var $justinmimbs$timezone_data$TimeZone$Specification$Rules = function (a) {
	return {$: 1, a: a};
};
var $justinmimbs$timezone_data$TimeZone$Specification$WallClock = 2;
var $justinmimbs$timezone_data$TimeZone$Specification$Zone = F2(
	function (history, current) {
		return {au: current, aq: history};
	});
var $justinmimbs$timezone_data$TimeZone$Specification$ZoneState = F2(
	function (standardOffset, zoneRules) {
		return {go: standardOffset, gH: zoneRules};
	});
var $justinmimbs$timezone_data$TimeZone$maxYear = 2037;
var $justinmimbs$timezone_data$TimeZone$minYear = 1970;
var $justinmimbs$timezone_data$TimeZone$Specification$Universal = 0;
var $justinmimbs$timezone_data$TimeZone$Specification$dropChangesBeforeEpoch = function (_v0) {
	dropChangesBeforeEpoch:
	while (true) {
		var initial = _v0.a;
		var changes = _v0.b;
		if (changes.b) {
			var change = changes.a;
			var rest = changes.b;
			if (change.gp <= 0) {
				var $temp$_v0 = _Utils_Tuple2(change.f1, rest);
				_v0 = $temp$_v0;
				continue dropChangesBeforeEpoch;
			} else {
				return _Utils_Tuple2(initial, changes);
			}
		} else {
			return _Utils_Tuple2(initial, _List_Nil);
		}
	}
};
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $justinmimbs$timezone_data$RataDie$weekdayNumber = function (rd) {
	var _v0 = A2($elm$core$Basics$modBy, 7, rd);
	if (!_v0) {
		return 7;
	} else {
		var n = _v0;
		return n;
	}
};
var $justinmimbs$timezone_data$RataDie$weekdayToNumber = function (wd) {
	switch (wd) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		default:
			return 7;
	}
};
var $justinmimbs$timezone_data$RataDie$floorWeekday = F2(
	function (weekday, rd) {
		var daysSincePreviousWeekday = A2(
			$elm$core$Basics$modBy,
			7,
			($justinmimbs$timezone_data$RataDie$weekdayNumber(rd) + 7) - $justinmimbs$timezone_data$RataDie$weekdayToNumber(weekday));
		return rd - daysSincePreviousWeekday;
	});
var $justinmimbs$timezone_data$RataDie$ceilingWeekday = F2(
	function (weekday, rd) {
		var floored = A2($justinmimbs$timezone_data$RataDie$floorWeekday, weekday, rd);
		return _Utils_eq(rd, floored) ? rd : (floored + 7);
	});
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $justinmimbs$timezone_data$RataDie$isLeapYear = function (y) {
	return ((!A2($elm$core$Basics$modBy, 4, y)) && (!(!A2($elm$core$Basics$modBy, 100, y)))) || (!A2($elm$core$Basics$modBy, 400, y));
};
var $justinmimbs$timezone_data$RataDie$daysBeforeMonth = F2(
	function (y, m) {
		var leapDays = $justinmimbs$timezone_data$RataDie$isLeapYear(y) ? 1 : 0;
		switch (m) {
			case 0:
				return 0;
			case 1:
				return 31;
			case 2:
				return 59 + leapDays;
			case 3:
				return 90 + leapDays;
			case 4:
				return 120 + leapDays;
			case 5:
				return 151 + leapDays;
			case 6:
				return 181 + leapDays;
			case 7:
				return 212 + leapDays;
			case 8:
				return 243 + leapDays;
			case 9:
				return 273 + leapDays;
			case 10:
				return 304 + leapDays;
			default:
				return 334 + leapDays;
		}
	});
var $justinmimbs$timezone_data$RataDie$daysBeforeYear = function (y1) {
	var y = y1 - 1;
	var leapYears = (((y / 4) | 0) - ((y / 100) | 0)) + ((y / 400) | 0);
	return (365 * y) + leapYears;
};
var $justinmimbs$timezone_data$RataDie$dayOfMonth = F3(
	function (y, m, d) {
		return ($justinmimbs$timezone_data$RataDie$daysBeforeYear(y) + A2($justinmimbs$timezone_data$RataDie$daysBeforeMonth, y, m)) + d;
	});
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $justinmimbs$timezone_data$RataDie$daysInMonth = F2(
	function (y, m) {
		switch (m) {
			case 0:
				return 31;
			case 1:
				return $justinmimbs$timezone_data$RataDie$isLeapYear(y) ? 29 : 28;
			case 2:
				return 31;
			case 3:
				return 30;
			case 4:
				return 31;
			case 5:
				return 30;
			case 6:
				return 31;
			case 7:
				return 31;
			case 8:
				return 30;
			case 9:
				return 31;
			case 10:
				return 30;
			default:
				return 31;
		}
	});
var $justinmimbs$timezone_data$RataDie$lastOfMonth = F2(
	function (y, m) {
		return ($justinmimbs$timezone_data$RataDie$daysBeforeYear(y) + A2($justinmimbs$timezone_data$RataDie$daysBeforeMonth, y, m)) + A2($justinmimbs$timezone_data$RataDie$daysInMonth, y, m);
	});
var $justinmimbs$timezone_data$TimeZone$Specification$minutesFromRataDie = function (rd) {
	return (rd - 719163) * 1440;
};
var $elm$core$List$sortBy = _List_sortBy;
var $justinmimbs$timezone_data$TimeZone$Specification$utcAdjustment = F2(
	function (clock, _v0) {
		var standard = _v0.bh;
		var save = _v0.Q;
		switch (clock) {
			case 0:
				return 0;
			case 1:
				return 0 - standard;
			default:
				return 0 - (standard + save);
		}
	});
var $justinmimbs$timezone_data$TimeZone$Specification$minutesFromDateTime = function (_v0) {
	var year = _v0.fi;
	var month = _v0.mw;
	var day = _v0.d5;
	var time = _v0.e6;
	return $justinmimbs$timezone_data$TimeZone$Specification$minutesFromRataDie(
		A3($justinmimbs$timezone_data$RataDie$dayOfMonth, year, month, day)) + time;
};
var $justinmimbs$timezone_data$TimeZone$Specification$utcMinutesFromDateTime = F2(
	function (offset, datetime) {
		return $justinmimbs$timezone_data$TimeZone$Specification$minutesFromDateTime(datetime) + A2($justinmimbs$timezone_data$TimeZone$Specification$utcAdjustment, datetime.aY, offset);
	});
var $justinmimbs$timezone_data$TimeZone$Specification$rulesToOffsetChanges = F5(
	function (previousOffset, start, until, standardOffset, rules) {
		var transitions = A2(
			$elm$core$List$concatMap,
			function (year) {
				return A2(
					$elm$core$List$sortBy,
					function ($) {
						return $.gp;
					},
					A2(
						$elm$core$List$map,
						function (rule) {
							return {
								aY: rule.aY,
								Q: rule.Q,
								gp: $justinmimbs$timezone_data$TimeZone$Specification$minutesFromRataDie(
									function () {
										var _v2 = rule.d5;
										switch (_v2.$) {
											case 0:
												var day = _v2.a;
												return A3($justinmimbs$timezone_data$RataDie$dayOfMonth, year, rule.mw, day);
											case 1:
												var weekday = _v2.a;
												var after = _v2.b;
												return A2(
													$justinmimbs$timezone_data$RataDie$ceilingWeekday,
													weekday,
													A3($justinmimbs$timezone_data$RataDie$dayOfMonth, year, rule.mw, after));
											case 2:
												var weekday = _v2.a;
												var before = _v2.b;
												return A2(
													$justinmimbs$timezone_data$RataDie$floorWeekday,
													weekday,
													A3($justinmimbs$timezone_data$RataDie$dayOfMonth, year, rule.mw, before));
											default:
												var weekday = _v2.a;
												return A2(
													$justinmimbs$timezone_data$RataDie$floorWeekday,
													weekday,
													A2($justinmimbs$timezone_data$RataDie$lastOfMonth, year, rule.mw));
										}
									}()) + rule.e6
							};
						},
						A2(
							$elm$core$List$filter,
							function (rule) {
								return (_Utils_cmp(rule.hx, year) < 1) && (_Utils_cmp(year, rule.jj) < 1);
							},
							rules)));
			},
			A2($elm$core$List$range, start.fi - 1, until.fi));
		var initialOffset = {Q: 0, bh: standardOffset};
		var initialChange = {
			f1: standardOffset,
			gp: A2($justinmimbs$timezone_data$TimeZone$Specification$utcMinutesFromDateTime, previousOffset, start)
		};
		var _v0 = A3(
			$elm$core$List$foldl,
			F2(
				function (transition, _v1) {
					var currentOffset = _v1.a;
					var changes = _v1.b;
					var newOffset = {Q: transition.Q, bh: standardOffset};
					if (_Utils_cmp(
						transition.gp + A2($justinmimbs$timezone_data$TimeZone$Specification$utcAdjustment, transition.aY, previousOffset),
						initialChange.gp) < 1) {
						var updatedInitialChange = {f1: standardOffset + transition.Q, gp: initialChange.gp};
						return _Utils_Tuple2(
							newOffset,
							_List_fromArray(
								[updatedInitialChange]));
					} else {
						if (_Utils_cmp(
							transition.gp + A2($justinmimbs$timezone_data$TimeZone$Specification$utcAdjustment, transition.aY, currentOffset),
							A2($justinmimbs$timezone_data$TimeZone$Specification$utcMinutesFromDateTime, currentOffset, until)) < 0) {
							var change = {
								f1: standardOffset + transition.Q,
								gp: transition.gp + A2($justinmimbs$timezone_data$TimeZone$Specification$utcAdjustment, transition.aY, currentOffset)
							};
							return _Utils_Tuple2(
								newOffset,
								A2($elm$core$List$cons, change, changes));
						} else {
							return _Utils_Tuple2(currentOffset, changes);
						}
					}
				}),
			_Utils_Tuple2(
				initialOffset,
				_List_fromArray(
					[initialChange])),
			transitions);
		var nextOffset = _v0.a;
		var descendingChanges = _v0.b;
		return _Utils_Tuple2(
			$elm$core$List$reverse(descendingChanges),
			nextOffset);
	});
var $justinmimbs$timezone_data$TimeZone$Specification$stateToOffsetChanges = F4(
	function (previousOffset, start, until, _v0) {
		var standardOffset = _v0.go;
		var zoneRules = _v0.gH;
		if (!zoneRules.$) {
			var save = zoneRules.a;
			return _Utils_Tuple2(
				_List_fromArray(
					[
						{
						f1: standardOffset + save,
						gp: A2($justinmimbs$timezone_data$TimeZone$Specification$utcMinutesFromDateTime, previousOffset, start)
					}
					]),
				{Q: save, bh: standardOffset});
		} else {
			var rules = zoneRules.a;
			return A5($justinmimbs$timezone_data$TimeZone$Specification$rulesToOffsetChanges, previousOffset, start, until, standardOffset, rules);
		}
	});
var $justinmimbs$timezone_data$TimeZone$Specification$stripDuplicatesByHelp = F4(
	function (f, a, rev, list) {
		stripDuplicatesByHelp:
		while (true) {
			if (!list.b) {
				return $elm$core$List$reverse(rev);
			} else {
				var x = list.a;
				var xs = list.b;
				var b = f(x);
				var rev_ = (!_Utils_eq(a, b)) ? A2($elm$core$List$cons, x, rev) : rev;
				var $temp$f = f,
					$temp$a = b,
					$temp$rev = rev_,
					$temp$list = xs;
				f = $temp$f;
				a = $temp$a;
				rev = $temp$rev;
				list = $temp$list;
				continue stripDuplicatesByHelp;
			}
		}
	});
var $justinmimbs$timezone_data$TimeZone$Specification$zoneToRanges = F3(
	function (zoneStart, zoneUntil, zone) {
		var _v0 = A3(
			$elm$core$List$foldl,
			F2(
				function (_v1, _v2) {
					var state = _v1.a;
					var nextStart = _v1.b;
					var start = _v2.a;
					var ranges = _v2.b;
					return _Utils_Tuple2(
						nextStart,
						A2(
							$elm$core$List$cons,
							_Utils_Tuple3(start, state, nextStart),
							ranges));
				}),
			_Utils_Tuple2(zoneStart, _List_Nil),
			zone.aq);
		var currentStart = _v0.a;
		var historyRanges = _v0.b;
		return $elm$core$List$reverse(
			A2(
				$elm$core$List$cons,
				_Utils_Tuple3(currentStart, zone.au, zoneUntil),
				historyRanges));
	});
var $justinmimbs$timezone_data$TimeZone$Specification$toOffsets = F3(
	function (minYear, maxYear, zone) {
		var initialState = function () {
			var _v5 = zone.aq;
			if (_v5.b) {
				var _v6 = _v5.a;
				var earliest = _v6.a;
				return earliest;
			} else {
				return zone.au;
			}
		}();
		var initialOffset = {
			Q: function () {
				var _v4 = initialState.gH;
				if (!_v4.$) {
					var save = _v4.a;
					return save;
				} else {
					return 0;
				}
			}(),
			bh: initialState.go
		};
		var ascendingChanges = A4(
			$justinmimbs$timezone_data$TimeZone$Specification$stripDuplicatesByHelp,
			function ($) {
				return $.f1;
			},
			initialOffset.bh + initialOffset.Q,
			_List_Nil,
			A3(
				$elm$core$List$foldl,
				F2(
					function (_v1, _v2) {
						var start = _v1.a;
						var state = _v1.b;
						var until = _v1.c;
						var prevOffset = _v2.a;
						var prevChanges = _v2.b;
						var _v3 = A4($justinmimbs$timezone_data$TimeZone$Specification$stateToOffsetChanges, prevOffset, start, until, state);
						var nextChanges = _v3.a;
						var nextOffset = _v3.b;
						return _Utils_Tuple2(
							nextOffset,
							_Utils_ap(prevChanges, nextChanges));
					}),
				_Utils_Tuple2(initialOffset, _List_Nil),
				A3(
					$justinmimbs$timezone_data$TimeZone$Specification$zoneToRanges,
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, minYear, 0, 1, 0, 0),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, maxYear + 1, 0, 1, 0, 0),
					zone)).b);
		var _v0 = $justinmimbs$timezone_data$TimeZone$Specification$dropChangesBeforeEpoch(
			_Utils_Tuple2(initialOffset.bh + initialOffset.Q, ascendingChanges));
		var initial = _v0.a;
		var ascending = _v0.b;
		return _Utils_Tuple2(
			$elm$core$List$reverse(ascending),
			initial);
	});
var $justinmimbs$timezone_data$TimeZone$fromSpecification = function (zone) {
	var _v0 = A3($justinmimbs$timezone_data$TimeZone$Specification$toOffsets, $justinmimbs$timezone_data$TimeZone$minYear, $justinmimbs$timezone_data$TimeZone$maxYear, zone);
	var descending = _v0.a;
	var bottom = _v0.b;
	return A2($elm$time$Time$customZone, bottom, descending);
};
var $justinmimbs$timezone_data$TimeZone$Specification$Day = function (a) {
	return {$: 0, a: a};
};
var $justinmimbs$timezone_data$TimeZone$Specification$Last = function (a) {
	return {$: 3, a: a};
};
var $justinmimbs$timezone_data$TimeZone$Specification$Next = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $justinmimbs$timezone_data$TimeZone$Specification$Rule = F7(
	function (from, to, month, day, time, clock, save) {
		return {aY: clock, d5: day, hx: from, mw: month, Q: save, e6: time, jj: to};
	});
var $elm$time$Time$Sun = 6;
var $justinmimbs$timezone_data$TimeZone$rules_EU = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1980,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		60,
		0,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1977,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		60,
		0,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1978,
		1978,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		60,
		0,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1979,
		1995,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		60,
		0,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1981,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		60,
		0,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1996,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		60,
		0,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$rules_Finland = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1981,
		1982,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1981,
		1982,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		180,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$europe__helsinki = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Finland)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1983, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				120,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU))));
};
var $author$project$Helpers$posixToDate = function (posix) {
	return A2(
		$justinmimbs$date$Date$fromPosix,
		$justinmimbs$timezone_data$TimeZone$europe__helsinki(0),
		posix);
};
var $author$project$Pages$Holidays$Msg_Loaded = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Holidays$State_Loaded$MsTimePassed = function (a) {
	return {$: 12, a: a};
};
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Helpers$oneMinuteInMs = 60000;
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $author$project$Pages$Holidays$State_Loaded$subscriptions = function (model) {
	var _v0 = model.aw;
	if (!_v0.$) {
		return $elm$core$Platform$Sub$none;
	} else {
		var pinValidForMs = _v0.a.cn;
		return A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Platform$Sub$none,
			A2(
				$elm$core$Maybe$map,
				function (_v1) {
					return A2(
						$elm$time$Time$every,
						$author$project$Helpers$oneMinuteInMs,
						function (_v2) {
							return $author$project$Pages$Holidays$State_Loaded$MsTimePassed($author$project$Helpers$oneMinuteInMs);
						});
				},
				pinValidForMs));
	}
};
var $author$project$Pages$Holidays$subscriptions = function (model) {
	var _v0 = model._;
	switch (_v0.$) {
		case 0:
			return $elm$core$Platform$Sub$none;
		case 1:
			var model_Loading = _v0.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Pages$Holidays$Msg_Loaded,
				$author$project$Pages$Holidays$State_Loaded$subscriptions(model_Loading));
		default:
			return $elm$core$Platform$Sub$none;
	}
};
var $author$project$Pages$Reporting$Msg_PinOk = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Reporting$PinExpired = {$: 7};
var $author$project$Components$PinDialog$pinValidForMinutes = 5;
var $author$project$Components$PinDialog$pinValidForMs = $author$project$Components$PinDialog$pinValidForMinutes * $author$project$Helpers$oneMinuteInMs;
var $author$project$Pages$Reporting$subscriptions = function (model) {
	var _v0 = model._;
	switch (_v0.$) {
		case 0:
			return $elm$core$Platform$Sub$none;
		case 1:
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Pages$Reporting$Msg_PinOk,
				A2(
					$elm$time$Time$every,
					$author$project$Components$PinDialog$pinValidForMs,
					function (_v1) {
						return $author$project$Pages$Reporting$PinExpired;
					}));
		default:
			return $elm$core$Platform$Sub$none;
	}
};
var $author$project$Pages$Tasklist$Msg_Errored = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Tasklist$Msg_Loaded = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Tasklist$Msg_Loading = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Tasklist$subscriptions_Errored = function (_v0) {
	return $elm$core$Platform$Sub$none;
};
var $author$project$Pages$Tasklist$GotTaskQueueMsg = function (a) {
	return {$: 28, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$GotDragMsg = function (a) {
	return {$: 21, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$GotLayoutResizeRequest = {$: 11};
var $author$project$Pages$Tasklist$TaskQueue$Msg_Loaded = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Events$Window = 1;
var $elm$browser$Browser$Events$onResize = function (func) {
	return A3(
		$elm$browser$Browser$Events$on,
		1,
		'resize',
		A2(
			$elm$json$Json$Decode$field,
			'target',
			A3(
				$elm$json$Json$Decode$map2,
				func,
				A2($elm$json$Json$Decode$field, 'innerWidth', $elm$json$Json$Decode$int),
				A2($elm$json$Json$Decode$field, 'innerHeight', $elm$json$Json$Decode$int))));
};
var $author$project$Components$Drag$ErrorDecodingDragEvents = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $author$project$Components$Drag$GotDragMsg = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $author$project$Components$Drag$DragBeacon = F5(
	function (id, x, y, width, height) {
		return {en: height, er: id, fg: width, D: x, E: y};
	});
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $elm$json$Json$Decode$map5 = _Json_map5;
var $author$project$Components$Drag$beaconDecoder = A6(
	$elm$json$Json$Decode$map5,
	$author$project$Components$Drag$DragBeacon,
	A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'x', $elm$json$Json$Decode$float),
	A2($elm$json$Json$Decode$field, 'y', $elm$json$Json$Decode$float),
	A2($elm$json$Json$Decode$field, 'width', $elm$json$Json$Decode$float),
	A2($elm$json$Json$Decode$field, 'height', $elm$json$Json$Decode$float));
var $author$project$Helpers$Coordinates = F2(
	function (x, y) {
		return {D: x, E: y};
	});
var $author$project$Decode$coordinatesDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Helpers$Coordinates,
	A2($elm$json$Json$Decode$field, 'x', $elm$json$Json$Decode$float),
	A2($elm$json$Json$Decode$field, 'y', $elm$json$Json$Decode$float));
var $author$project$Components$Drag$Move = 1;
var $author$project$Components$Drag$Start = 0;
var $author$project$Components$Drag$Stop = 2;
var $elm$json$Json$Decode$fail = _Json_fail;
var $author$project$Components$Drag$eventDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (eventType) {
		switch (eventType) {
			case 'start':
				return $elm$json$Json$Decode$succeed(0);
			case 'move':
				return $elm$json$Json$Decode$succeed(1);
			case 'stop':
				return $elm$json$Json$Decode$succeed(2);
			default:
				return $elm$json$Json$Decode$fail('Unknown drag event type ' + eventType);
		}
	},
	$elm$json$Json$Decode$string);
var $elm$json$Json$Decode$list = _Json_decodeList;
var $elm$json$Json$Decode$map3 = _Json_map3;
var $author$project$Components$Drag$dragMsgDecoder = A4(
	$elm$json$Json$Decode$map3,
	F3(
		function (event, cursorCoordinates, beacons) {
			return A3($author$project$Components$Drag$GotDragMsg, event, cursorCoordinates, beacons);
		}),
	A2($elm$json$Json$Decode$field, 'type', $author$project$Components$Drag$eventDecoder),
	A2($elm$json$Json$Decode$field, 'cursor', $author$project$Decode$coordinatesDecoder),
	A2(
		$elm$json$Json$Decode$field,
		'beacons',
		$elm$json$Json$Decode$list($author$project$Components$Drag$beaconDecoder)));
var $author$project$Components$Drag$decodeDragEvents = function (value) {
	var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$Components$Drag$dragMsgDecoder, value);
	if (!_v0.$) {
		var msg = _v0.a;
		return msg;
	} else {
		var err = _v0.a;
		return $author$project$Components$Drag$ErrorDecodingDragEvents(err);
	}
};
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $author$project$Components$Drag$dragEvents = _Platform_incomingPort('dragEvents', $elm$json$Json$Decode$value);
var $author$project$Components$Drag$subscriptions = $author$project$Components$Drag$dragEvents($author$project$Components$Drag$decodeDragEvents);
var $author$project$Pages$Tasklist$TaskQueue$subscriptions = function (model) {
	var _v0 = model._;
	switch (_v0.$) {
		case 0:
			return $elm$core$Platform$Sub$none;
		case 1:
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Pages$Tasklist$TaskQueue$Msg_Loaded,
				$elm$core$Platform$Sub$batch(
					_List_fromArray(
						[
							A2($elm$core$Platform$Sub$map, $author$project$Pages$Tasklist$TaskQueue$GotDragMsg, $author$project$Components$Drag$subscriptions),
							$elm$browser$Browser$Events$onResize(
							F2(
								function (_v1, _v2) {
									return $author$project$Pages$Tasklist$TaskQueue$GotLayoutResizeRequest;
								}))
						])));
		default:
			return $elm$core$Platform$Sub$none;
	}
};
var $author$project$Pages$Tasklist$subscriptions_Loaded = function (model) {
	return A2(
		$elm$core$Platform$Sub$map,
		$author$project$Pages$Tasklist$GotTaskQueueMsg,
		$author$project$Pages$Tasklist$TaskQueue$subscriptions(model.N));
};
var $author$project$Pages$Tasklist$subscriptions_Loading = function (_v0) {
	return $elm$core$Platform$Sub$none;
};
var $author$project$Pages$Tasklist$subscriptions = function (model) {
	var _v0 = model._;
	switch (_v0.$) {
		case 0:
			var loadingModel = _v0.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Pages$Tasklist$Msg_Loading,
				$author$project$Pages$Tasklist$subscriptions_Loading(loadingModel));
		case 1:
			var loadedModel = _v0.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Pages$Tasklist$Msg_Loaded,
				$author$project$Pages$Tasklist$subscriptions_Loaded(loadedModel));
		default:
			var erroredModel = _v0.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Pages$Tasklist$Msg_Errored,
				$author$project$Pages$Tasklist$subscriptions_Errored(erroredModel));
	}
};
var $author$project$Pages$WorkTimeTracking$PinExpired = {$: 5};
var $author$project$Pages$WorkTimeTracking$subscriptions = function (model) {
	var _v0 = model._;
	switch (_v0.$) {
		case 0:
			return $elm$core$Platform$Sub$none;
		case 1:
			return $elm$core$Platform$Sub$none;
		case 2:
			return A2(
				$elm$time$Time$every,
				$author$project$Components$PinDialog$pinValidForMs,
				function (_v1) {
					return $author$project$Pages$WorkTimeTracking$PinExpired;
				});
		case 3:
			return A2(
				$elm$time$Time$every,
				$author$project$Components$PinDialog$pinValidForMs,
				function (_v2) {
					return $author$project$Pages$WorkTimeTracking$PinExpired;
				});
		default:
			return $elm$core$Platform$Sub$none;
	}
};
var $author$project$Main$tokenRemoved = _Platform_incomingPort('tokenRemoved', $elm$json$Json$Decode$string);
var $author$project$Main$subscriptions_LoggedIn = function (stateModel) {
	var fiveMinutesInMs = 5 * 60000;
	var pollLoginStatusEvery5min = A2(
		$elm$time$Time$every,
		fiveMinutesInMs,
		function (_v2) {
			return $author$project$Main$CheckLoginStatus;
		});
	var checkIfDayChanged = A2(
		$elm$time$Time$every,
		fiveMinutesInMs,
		A2(
			$elm$core$Basics$composeR,
			$author$project$Helpers$posixToDate,
			function (today) {
				return (!_Utils_eq(stateModel.q.dE, today)) ? $author$project$Main$DayChanged(today) : $author$project$Main$NoOp;
			}));
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				$author$project$Main$tokenRemoved($author$project$Main$TokenRemoved),
				checkIfDayChanged,
				function () {
				var _v0 = stateModel.A;
				switch (_v0.$) {
					case 0:
						return $elm$core$Platform$Sub$none;
					case 1:
						return pollLoginStatusEvery5min;
					case 2:
						return pollLoginStatusEvery5min;
					case 3:
						return $elm$core$Platform$Sub$none;
					default:
						return pollLoginStatusEvery5min;
				}
			}(),
				function () {
				var _v1 = stateModel.A;
				switch (_v1.$) {
					case 0:
						var model_HolidaysPage = _v1.a;
						return A2(
							$elm$core$Platform$Sub$map,
							$author$project$Main$GotHolidaysPageMsg,
							$author$project$Pages$Holidays$subscriptions(model_HolidaysPage));
					case 1:
						return $elm$core$Platform$Sub$none;
					case 2:
						var model_ReportingPage = _v1.a;
						return A2(
							$elm$core$Platform$Sub$map,
							$author$project$Main$GotReportingPageMsg,
							$author$project$Pages$Reporting$subscriptions(model_ReportingPage));
					case 3:
						var tasklistPageModel = _v1.a;
						return A2(
							$elm$core$Platform$Sub$map,
							$author$project$Main$GotTasklistPageMsg,
							$author$project$Pages$Tasklist$subscriptions(tasklistPageModel));
					default:
						var subModel = _v1.a;
						return A2(
							$elm$core$Platform$Sub$map,
							$author$project$Main$GotWorkTimeTrackingPageMsg,
							$author$project$Pages$WorkTimeTracking$subscriptions(subModel));
				}
			}()
			]));
};
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $author$project$Main$touchEventDetected = _Platform_incomingPort('touchEventDetected', $elm$json$Json$Decode$bool);
var $author$project$Main$subscriptions = function (model) {
	var stateSubs = function () {
		var _v2 = model._;
		switch (_v2.$) {
			case 0:
				var initializingModel = _v2.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Main$Msg_Initializing,
					$author$project$Main$subscriptions_Initializing(initializingModel));
			case 1:
				var authenticatingModel = _v2.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Main$Msg_Authenticating,
					$author$project$Main$subscriptions_Authenticating(authenticatingModel));
			default:
				var loggedInModel = _v2.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Main$Msg_LoggedIn,
					$author$project$Main$subscriptions_LoggedIn(loggedInModel));
		}
	}();
	var mainSubs = $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				function () {
				var _v0 = model.P;
				if (_v0.$ === 1) {
					return A2(
						$elm$time$Time$every,
						500,
						$elm$core$Basics$always(
							$author$project$Main$TimePassed(500)));
				} else {
					return $elm$core$Platform$Sub$none;
				}
			}(),
				$author$project$Main$isTouchEventDetected(model) ? $elm$core$Platform$Sub$none : $author$project$Main$touchEventDetected(
				function (_v1) {
					return $author$project$Main$Msg_TouchEventDetected;
				})
			]));
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[mainSubs, stateSubs]));
};
var $author$project$Main$LoggedIn = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $author$project$Components$Notification$Active = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Components$Notification$run = F2(
	function (ms, state) {
		if (state.$ === 1) {
			var notification = state.a;
			var visibleFor = state.b;
			var updatedVisibleFor = visibleFor - ms;
			return (updatedVisibleFor >= 0) ? A2($author$project$Components$Notification$Active, notification, updatedVisibleFor) : $author$project$Components$Notification$Inactive;
		} else {
			return $author$project$Components$Notification$Inactive;
		}
	});
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $author$project$Main$stopDetectingTouchEvents = _Platform_outgoingPort(
	'stopDetectingTouchEvents',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Main$Authenticating = function (a) {
	return {$: 1, a: a};
};
var $author$project$Main$GotInvoicingPageMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$Types$Page$InvoicingPage = function (a) {
	return {$: 1, a: a};
};
var $author$project$Types$Page$ReportingPage = function (a) {
	return {$: 2, a: a};
};
var $author$project$Types$Page$TasklistPage = function (a) {
	return {$: 3, a: a};
};
var $author$project$Types$AppContext$create = function (_v0) {
	var apiUrl = _v0.j0;
	var loggedInUser = _v0.h4;
	var loginUrl = _v0.ag;
	var today = _v0.dE;
	var dataRefreshIntervalMs = _v0.fx;
	var token = _v0.oK;
	var touchEventDetected = _v0.gy;
	var globalPin = _v0.lA;
	return {
		lA: globalPin,
		h4: loggedInUser,
		n1: {j0: apiUrl, fx: dataRefreshIntervalMs, ag: loginUrl, oK: token},
		dE: today,
		gy: touchEventDetected
	};
};
var $author$project$Types$Page$getTitle = function (pageLink) {
	switch (pageLink) {
		case 0:
			return 'LOMAT';
		case 1:
			return 'LASKUTUS';
		case 2:
			return 'RAPORTOINTI';
		case 3:
			return 'TYÖKIRJAUS';
		default:
			return 'TYÖAJAT';
	}
};
var $author$project$Components$TopMenu$UserMenu$MenuClosed = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$TopMenu$UserMenu$init = {
	_: $author$project$Components$TopMenu$UserMenu$MenuClosed(
		{})
};
var $author$project$Components$Vehicles$Search$WaitingForSearchString = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Vehicles$Search$init = {
	_: $author$project$Components$Vehicles$Search$WaitingForSearchString('')
};
var $author$project$Components$TopMenu$init = function () {
	var initialModel = {cF: $author$project$Components$TopMenu$UserMenu$init, dL: $author$project$Components$Vehicles$Search$init};
	return initialModel;
}();
var $author$project$Pages$Invoicing$ClosedTab = 0;
var $author$project$Pages$Invoicing$GotData = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pages$Invoicing$NoDialog = {$: 0};
var $elm$core$Task$onError = _Scheduler_onError;
var $elm$core$Task$attempt = F2(
	function (resultToMessage, task) {
		return $elm$core$Task$command(
			A2(
				$elm$core$Task$onError,
				A2(
					$elm$core$Basics$composeL,
					A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
					$elm$core$Result$Err),
				A2(
					$elm$core$Task$andThen,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
						$elm$core$Result$Ok),
					task)));
	});
var $author$project$Types$Customer$Customer = F3(
	function (id, name, customerType) {
		return {kY: customerType, er: id, mD: name};
	});
var $author$project$Types$Customer$OneTimeCustomer = 1;
var $author$project$Types$Customer$RegularCustomer = 0;
var $author$project$Data$Customers$customerTypeDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (customerTypeString) {
		switch (customerTypeString) {
			case 'REGULAR_CUSTOMER':
				return $elm$json$Json$Decode$succeed(0);
			case 'ONE_TIME_CUSTOMER':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail(customerTypeString);
		}
	},
	$elm$json$Json$Decode$string);
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required = F3(
	function (key, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$field, key, valDecoder),
			decoder);
	});
var $author$project$Data$Customers$customerDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'type',
	$author$project$Data$Customers$customerTypeDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'name',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'id',
			$elm$json$Json$Decode$int,
			$elm$json$Json$Decode$succeed($author$project$Types$Customer$Customer))));
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $elm$http$Http$BadBody = function (a) {
	return {$: 4, a: a};
};
var $elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$NetworkError = {$: 2};
var $elm$http$Http$Timeout = {$: 1};
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $author$project$JwtHttp$handleJsonResponse = F2(
	function (decoder, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 3:
				var statusCode = response.a.od;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(statusCode));
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			default:
				var body = response.b;
				var _v1 = A2($elm$json$Json$Decode$decodeString, decoder, body);
				if (_v1.$ === 1) {
					return $elm$core$Result$Err(
						$elm$http$Http$BadBody(body));
				} else {
					var result = _v1.a;
					return $elm$core$Result$Ok(result);
				}
		}
	});
var $elm$http$Http$stringResolver = A2(_Http_expect, '', $elm$core$Basics$identity);
var $elm$http$Http$Header = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$header = $elm$http$Http$Header;
var $elm$core$Task$fail = _Scheduler_fail;
var $elm$http$Http$resultToTask = function (result) {
	if (!result.$) {
		var a = result.a;
		return $elm$core$Task$succeed(a);
	} else {
		var x = result.a;
		return $elm$core$Task$fail(x);
	}
};
var $elm$http$Http$task = function (r) {
	return A3(
		_Http_toTask,
		0,
		$elm$http$Http$resultToTask,
		{jW: false, kc: r.kc, lq: r.nJ, hF: r.hF, mr: r.mr, oH: r.oH, oO: $elm$core$Maybe$Nothing, oU: r.oU});
};
var $author$project$JwtHttp$task = function (params) {
	return $elm$http$Http$task(
		{
			kc: params.kc,
			hF: _List_fromArray(
				[
					A2($elm$http$Http$header, 'Authorization', 'Bearer ' + params.oK)
				]),
			mr: params.mr,
			nJ: params.nJ,
			oH: $elm$core$Maybe$Nothing,
			oU: params.oU
		});
};
var $author$project$Data$Customers$fetch = function (_v0) {
	var apiUrl = _v0.j0;
	var token = _v0.oK;
	return $author$project$JwtHttp$task(
		{
			kc: $elm$http$Http$emptyBody,
			mr: 'GET',
			nJ: $elm$http$Http$stringResolver(
				$author$project$JwtHttp$handleJsonResponse(
					$elm$json$Json$Decode$list($author$project$Data$Customers$customerDecoder))),
			oH: $elm$core$Maybe$Nothing,
			oK: token,
			oU: apiUrl + '/customers'
		});
};
var $author$project$Types$AvailableProduct$AvailableProduct = F3(
	function (id, code, name) {
		return {ha: code, er: id, mD: name};
	});
var $author$project$Data$Products$availableProductDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'name',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'code',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'id',
			$elm$json$Json$Decode$int,
			$elm$json$Json$Decode$succeed($author$project$Types$AvailableProduct$AvailableProduct))));
var $author$project$Data$Products$fetchAll = function (_v0) {
	var apiUrl = _v0.j0;
	var token = _v0.oK;
	return $author$project$JwtHttp$task(
		{
			kc: $elm$http$Http$emptyBody,
			mr: 'GET',
			nJ: $elm$http$Http$stringResolver(
				$author$project$JwtHttp$handleJsonResponse(
					$elm$json$Json$Decode$list($author$project$Data$Products$availableProductDecoder))),
			oH: $elm$core$Maybe$Nothing,
			oK: token,
			oU: apiUrl + '/products'
		});
};
var $author$project$Types$Worker$Worker = F8(
	function (id, code, name, isSelectedByDefault, deletedAt, hasVacationTracking, hasWorkTimeTracking, compensationType) {
		return {ha: code, kI: compensationType, k9: deletedAt, lF: hasVacationTracking, lG: hasWorkTimeTracking, er: id, l4: isSelectedByDefault, mD: name};
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder = F3(
	function (pathDecoder, valDecoder, fallback) {
		var nullOr = function (decoder) {
			return $elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						decoder,
						$elm$json$Json$Decode$null(fallback)
					]));
		};
		var handleResult = function (input) {
			var _v0 = A2($elm$json$Json$Decode$decodeValue, pathDecoder, input);
			if (!_v0.$) {
				var rawValue = _v0.a;
				var _v1 = A2(
					$elm$json$Json$Decode$decodeValue,
					nullOr(valDecoder),
					rawValue);
				if (!_v1.$) {
					var finalResult = _v1.a;
					return $elm$json$Json$Decode$succeed(finalResult);
				} else {
					var finalErr = _v1.a;
					return $elm$json$Json$Decode$fail(
						$elm$json$Json$Decode$errorToString(finalErr));
				}
			} else {
				return $elm$json$Json$Decode$succeed(fallback);
			}
		};
		return A2($elm$json$Json$Decode$andThen, handleResult, $elm$json$Json$Decode$value);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional = F4(
	function (key, valDecoder, fallback, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder,
				A2($elm$json$Json$Decode$field, key, $elm$json$Json$Decode$value),
				valDecoder,
				fallback),
			decoder);
	});
var $elm$parser$Parser$Advanced$Bad = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$Good = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Parser = $elm$core$Basics$identity;
var $elm$parser$Parser$Advanced$andThen = F2(
	function (callback, _v0) {
		var parseA = _v0;
		return function (s0) {
			var _v1 = parseA(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				var _v2 = callback(a);
				var parseB = _v2;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3($elm$parser$Parser$Advanced$Good, p1 || p2, b, s2);
				}
			}
		};
	});
var $elm$parser$Parser$andThen = $elm$parser$Parser$Advanced$andThen;
var $elm$parser$Parser$ExpectingEnd = {$: 10};
var $elm$parser$Parser$Advanced$AddRight = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$DeadEnd = F4(
	function (row, col, problem, contextStack) {
		return {ft: col, kO: contextStack, iw: problem, iP: row};
	});
var $elm$parser$Parser$Advanced$Empty = {$: 0};
var $elm$parser$Parser$Advanced$fromState = F2(
	function (s, x) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, s.iP, s.ft, x, s.j));
	});
var $elm$parser$Parser$Advanced$end = function (x) {
	return function (s) {
		return _Utils_eq(
			$elm$core$String$length(s.oc),
			s.f1) ? A3($elm$parser$Parser$Advanced$Good, false, 0, s) : A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$end = $elm$parser$Parser$Advanced$end($elm$parser$Parser$ExpectingEnd);
var $elm$parser$Parser$Advanced$isSubChar = _Parser_isSubChar;
var $elm$parser$Parser$Advanced$chompWhileHelp = F5(
	function (isGood, offset, row, col, s0) {
		chompWhileHelp:
		while (true) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, offset, s0.oc);
			if (_Utils_eq(newOffset, -1)) {
				return A3(
					$elm$parser$Parser$Advanced$Good,
					_Utils_cmp(s0.f1, offset) < 0,
					0,
					{ft: col, j: s0.j, l: s0.l, f1: offset, iP: row, oc: s0.oc});
			} else {
				if (_Utils_eq(newOffset, -2)) {
					var $temp$isGood = isGood,
						$temp$offset = offset + 1,
						$temp$row = row + 1,
						$temp$col = 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				} else {
					var $temp$isGood = isGood,
						$temp$offset = newOffset,
						$temp$row = row,
						$temp$col = col + 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$chompWhile = function (isGood) {
	return function (s) {
		return A5($elm$parser$Parser$Advanced$chompWhileHelp, isGood, s.f1, s.iP, s.ft, s);
	};
};
var $elm$parser$Parser$chompWhile = $elm$parser$Parser$Advanced$chompWhile;
var $elm$parser$Parser$Advanced$mapChompedString = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					A2(
						func,
						A3($elm$core$String$slice, s0.f1, s1.f1, s0.oc),
						a),
					s1);
			}
		};
	});
var $elm$parser$Parser$Advanced$getChompedString = function (parser) {
	return A2($elm$parser$Parser$Advanced$mapChompedString, $elm$core$Basics$always, parser);
};
var $elm$parser$Parser$getChompedString = $elm$parser$Parser$Advanced$getChompedString;
var $elm$parser$Parser$Problem = function (a) {
	return {$: 12, a: a};
};
var $elm$parser$Parser$Advanced$problem = function (x) {
	return function (s) {
		return A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$problem = function (msg) {
	return $elm$parser$Parser$Advanced$problem(
		$elm$parser$Parser$Problem(msg));
};
var $elm$core$Basics$round = _Basics_round;
var $elm$parser$Parser$Advanced$succeed = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, false, a, s);
	};
};
var $elm$parser$Parser$succeed = $elm$parser$Parser$Advanced$succeed;
var $elm$core$String$toFloat = _String_toFloat;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs = A2(
	$elm$parser$Parser$andThen,
	function (str) {
		if ($elm$core$String$length(str) <= 9) {
			var _v0 = $elm$core$String$toFloat('0.' + str);
			if (!_v0.$) {
				var floatVal = _v0.a;
				return $elm$parser$Parser$succeed(
					$elm$core$Basics$round(floatVal * 1000));
			} else {
				return $elm$parser$Parser$problem('Invalid float: \"' + (str + '\"'));
			}
		} else {
			return $elm$parser$Parser$problem(
				'Expected at most 9 digits, but got ' + $elm$core$String$fromInt(
					$elm$core$String$length(str)));
		}
	},
	$elm$parser$Parser$getChompedString(
		$elm$parser$Parser$chompWhile($elm$core$Char$isDigit)));
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts = F6(
	function (monthYearDayMs, hour, minute, second, ms, utcOffsetMinutes) {
		return $elm$time$Time$millisToPosix((((monthYearDayMs + (((hour * 60) * 60) * 1000)) + (((minute - utcOffsetMinutes) * 60) * 1000)) + (second * 1000)) + ms);
	});
var $elm$parser$Parser$Advanced$map2 = F3(
	function (func, _v0, _v1) {
		var parseA = _v0;
		var parseB = _v1;
		return function (s0) {
			var _v2 = parseA(s0);
			if (_v2.$ === 1) {
				var p = _v2.a;
				var x = _v2.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v2.a;
				var a = _v2.b;
				var s1 = _v2.c;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p1 || p2,
						A2(func, a, b),
						s2);
				}
			}
		};
	});
var $elm$parser$Parser$Advanced$ignorer = F2(
	function (keepParser, ignoreParser) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$always, keepParser, ignoreParser);
	});
var $elm$parser$Parser$ignorer = $elm$parser$Parser$Advanced$ignorer;
var $elm$parser$Parser$Advanced$keeper = F2(
	function (parseFunc, parseArg) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$apL, parseFunc, parseArg);
	});
var $elm$parser$Parser$keeper = $elm$parser$Parser$Advanced$keeper;
var $elm$parser$Parser$Advanced$Append = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$oneOfHelp = F3(
	function (s0, bag, parsers) {
		oneOfHelp:
		while (true) {
			if (!parsers.b) {
				return A2($elm$parser$Parser$Advanced$Bad, false, bag);
			} else {
				var parse = parsers.a;
				var remainingParsers = parsers.b;
				var _v1 = parse(s0);
				if (!_v1.$) {
					var step = _v1;
					return step;
				} else {
					var step = _v1;
					var p = step.a;
					var x = step.b;
					if (p) {
						return step;
					} else {
						var $temp$s0 = s0,
							$temp$bag = A2($elm$parser$Parser$Advanced$Append, bag, x),
							$temp$parsers = remainingParsers;
						s0 = $temp$s0;
						bag = $temp$bag;
						parsers = $temp$parsers;
						continue oneOfHelp;
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$oneOf = function (parsers) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$oneOfHelp, s, $elm$parser$Parser$Advanced$Empty, parsers);
	};
};
var $elm$parser$Parser$oneOf = $elm$parser$Parser$Advanced$oneOf;
var $elm$parser$Parser$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$core$String$append = _String_append;
var $elm$parser$Parser$UnexpectedChar = {$: 11};
var $elm$parser$Parser$Advanced$chompIf = F2(
	function (isGood, expecting) {
		return function (s) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, s.f1, s.oc);
			return _Utils_eq(newOffset, -1) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : (_Utils_eq(newOffset, -2) ? A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{ft: 1, j: s.j, l: s.l, f1: s.f1 + 1, iP: s.iP + 1, oc: s.oc}) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{ft: s.ft + 1, j: s.j, l: s.l, f1: newOffset, iP: s.iP, oc: s.oc}));
		};
	});
var $elm$parser$Parser$chompIf = function (isGood) {
	return A2($elm$parser$Parser$Advanced$chompIf, isGood, $elm$parser$Parser$UnexpectedChar);
};
var $elm$parser$Parser$Advanced$loopHelp = F4(
	function (p, state, callback, s0) {
		loopHelp:
		while (true) {
			var _v0 = callback(state);
			var parse = _v0;
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p1 = _v1.a;
				var step = _v1.b;
				var s1 = _v1.c;
				if (!step.$) {
					var newState = step.a;
					var $temp$p = p || p1,
						$temp$state = newState,
						$temp$callback = callback,
						$temp$s0 = s1;
					p = $temp$p;
					state = $temp$state;
					callback = $temp$callback;
					s0 = $temp$s0;
					continue loopHelp;
				} else {
					var result = step.a;
					return A3($elm$parser$Parser$Advanced$Good, p || p1, result, s1);
				}
			} else {
				var p1 = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p || p1, x);
			}
		}
	});
var $elm$parser$Parser$Advanced$loop = F2(
	function (state, callback) {
		return function (s) {
			return A4($elm$parser$Parser$Advanced$loopHelp, false, state, callback, s);
		};
	});
var $elm$parser$Parser$Advanced$map = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					func(a),
					s1);
			} else {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			}
		};
	});
var $elm$parser$Parser$map = $elm$parser$Parser$Advanced$map;
var $elm$parser$Parser$Advanced$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Advanced$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$toAdvancedStep = function (step) {
	if (!step.$) {
		var s = step.a;
		return $elm$parser$Parser$Advanced$Loop(s);
	} else {
		var a = step.a;
		return $elm$parser$Parser$Advanced$Done(a);
	}
};
var $elm$parser$Parser$loop = F2(
	function (state, callback) {
		return A2(
			$elm$parser$Parser$Advanced$loop,
			state,
			function (s) {
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$toAdvancedStep,
					callback(s));
			});
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt = function (quantity) {
	var helper = function (str) {
		if (_Utils_eq(
			$elm$core$String$length(str),
			quantity)) {
			var _v0 = $elm$core$String$toInt(str);
			if (!_v0.$) {
				var intVal = _v0.a;
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$Done,
					$elm$parser$Parser$succeed(intVal));
			} else {
				return $elm$parser$Parser$problem('Invalid integer: \"' + (str + '\"'));
			}
		} else {
			return A2(
				$elm$parser$Parser$map,
				function (nextChar) {
					return $elm$parser$Parser$Loop(
						A2($elm$core$String$append, str, nextChar));
				},
				$elm$parser$Parser$getChompedString(
					$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
		}
	};
	return A2($elm$parser$Parser$loop, '', helper);
};
var $elm$parser$Parser$ExpectingSymbol = function (a) {
	return {$: 8, a: a};
};
var $elm$parser$Parser$Advanced$Token = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$isSubString = _Parser_isSubString;
var $elm$core$Basics$not = _Basics_not;
var $elm$parser$Parser$Advanced$token = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	var progress = !$elm$core$String$isEmpty(str);
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$isSubString, str, s.f1, s.iP, s.ft, s.oc);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : A3(
			$elm$parser$Parser$Advanced$Good,
			progress,
			0,
			{ft: newCol, j: s.j, l: s.l, f1: newOffset, iP: newRow, oc: s.oc});
	};
};
var $elm$parser$Parser$Advanced$symbol = $elm$parser$Parser$Advanced$token;
var $elm$parser$Parser$symbol = function (str) {
	return $elm$parser$Parser$Advanced$symbol(
		A2(
			$elm$parser$Parser$Advanced$Token,
			str,
			$elm$parser$Parser$ExpectingSymbol(str)));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear = 1970;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay = function (day) {
	return $elm$parser$Parser$problem(
		'Invalid day: ' + $elm$core$String$fromInt(day));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear = function (year) {
	return (!A2($elm$core$Basics$modBy, 4, year)) && ((!(!A2($elm$core$Basics$modBy, 100, year))) || (!A2($elm$core$Basics$modBy, 400, year)));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore = function (y1) {
	var y = y1 - 1;
	return (((y / 4) | 0) - ((y / 100) | 0)) + ((y / 400) | 0);
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay = 86400000;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear = 31536000000;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay = function (_v0) {
	var year = _v0.a;
	var month = _v0.b;
	var dayInMonth = _v0.c;
	if (dayInMonth < 0) {
		return $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth);
	} else {
		var succeedWith = function (extraMs) {
			var yearMs = $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear * (year - $rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear);
			var days = ((month < 3) || (!$rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year))) ? (dayInMonth - 1) : dayInMonth;
			var dayMs = $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay * (days + ($rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore(year) - $rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore($rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear)));
			return $elm$parser$Parser$succeed((extraMs + yearMs) + dayMs);
		};
		switch (month) {
			case 1:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(0);
			case 2:
				return ((dayInMonth > 29) || ((dayInMonth === 29) && (!$rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year)))) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(2678400000);
			case 3:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(5097600000);
			case 4:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(7776000000);
			case 5:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(10368000000);
			case 6:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(13046400000);
			case 7:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(15638400000);
			case 8:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(18316800000);
			case 9:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(20995200000);
			case 10:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(23587200000);
			case 11:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(26265600000);
			case 12:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(28857600000);
			default:
				return $elm$parser$Parser$problem(
					'Invalid month: \"' + ($elm$core$String$fromInt(month) + '\"'));
		}
	}
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs = A2(
	$elm$parser$Parser$andThen,
	$rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay,
	A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				$elm$parser$Parser$succeed(
					F3(
						function (year, month, day) {
							return _Utils_Tuple3(year, month, day);
						})),
				$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(4)),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$ignorer,
							$elm$parser$Parser$succeed($elm$core$Basics$identity),
							$elm$parser$Parser$symbol('-')),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
					]))),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$ignorer,
						$elm$parser$Parser$succeed($elm$core$Basics$identity),
						$elm$parser$Parser$symbol('-')),
					$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
					$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
				]))));
var $rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetInMinutes = function () {
	var utcOffsetMinutesFromParts = F3(
		function (multiplier, hours, minutes) {
			return (multiplier * (hours * 60)) + minutes;
		});
	return A2(
		$elm$parser$Parser$keeper,
		$elm$parser$Parser$succeed($elm$core$Basics$identity),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$map,
					function (_v0) {
						return 0;
					},
					$elm$parser$Parser$symbol('Z')),
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							$elm$parser$Parser$succeed(utcOffsetMinutesFromParts),
							$elm$parser$Parser$oneOf(
								_List_fromArray(
									[
										A2(
										$elm$parser$Parser$map,
										function (_v1) {
											return 1;
										},
										$elm$parser$Parser$symbol('+')),
										A2(
										$elm$parser$Parser$map,
										function (_v2) {
											return -1;
										},
										$elm$parser$Parser$symbol('-'))
									]))),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								A2(
								$elm$parser$Parser$keeper,
								A2(
									$elm$parser$Parser$ignorer,
									$elm$parser$Parser$succeed($elm$core$Basics$identity),
									$elm$parser$Parser$symbol(':')),
								$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
								$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2),
								$elm$parser$Parser$succeed(0)
							]))),
					A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(0),
					$elm$parser$Parser$end)
				])));
}();
var $rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601 = A2(
	$elm$parser$Parser$andThen,
	function (datePart) {
		return $elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							A2(
								$elm$parser$Parser$keeper,
								A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed(
											$rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts(datePart)),
										$elm$parser$Parser$symbol('T')),
									$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
								$elm$parser$Parser$oneOf(
									_List_fromArray(
										[
											A2(
											$elm$parser$Parser$keeper,
											A2(
												$elm$parser$Parser$ignorer,
												$elm$parser$Parser$succeed($elm$core$Basics$identity),
												$elm$parser$Parser$symbol(':')),
											$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
											$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
										]))),
							$elm$parser$Parser$oneOf(
								_List_fromArray(
									[
										A2(
										$elm$parser$Parser$keeper,
										A2(
											$elm$parser$Parser$ignorer,
											$elm$parser$Parser$succeed($elm$core$Basics$identity),
											$elm$parser$Parser$symbol(':')),
										$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
										$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
									]))),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$symbol('.')),
									$rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs),
									$elm$parser$Parser$succeed(0)
								]))),
					A2($elm$parser$Parser$ignorer, $rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetInMinutes, $elm$parser$Parser$end)),
					A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A6($rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts, datePart, 0, 0, 0, 0, 0)),
					$elm$parser$Parser$end)
				]));
	},
	$rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs);
var $elm$parser$Parser$DeadEnd = F3(
	function (row, col, problem) {
		return {ft: col, iw: problem, iP: row};
	});
var $elm$parser$Parser$problemToDeadEnd = function (p) {
	return A3($elm$parser$Parser$DeadEnd, p.iP, p.ft, p.iw);
};
var $elm$parser$Parser$Advanced$bagToList = F2(
	function (bag, list) {
		bagToList:
		while (true) {
			switch (bag.$) {
				case 0:
					return list;
				case 1:
					var bag1 = bag.a;
					var x = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$core$List$cons, x, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
				default:
					var bag1 = bag.a;
					var bag2 = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$parser$Parser$Advanced$bagToList, bag2, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
			}
		}
	});
var $elm$parser$Parser$Advanced$run = F2(
	function (_v0, src) {
		var parse = _v0;
		var _v1 = parse(
			{ft: 1, j: _List_Nil, l: 1, f1: 0, iP: 1, oc: src});
		if (!_v1.$) {
			var value = _v1.b;
			return $elm$core$Result$Ok(value);
		} else {
			var bag = _v1.b;
			return $elm$core$Result$Err(
				A2($elm$parser$Parser$Advanced$bagToList, bag, _List_Nil));
		}
	});
var $elm$parser$Parser$run = F2(
	function (parser, source) {
		var _v0 = A2($elm$parser$Parser$Advanced$run, parser, source);
		if (!_v0.$) {
			var a = _v0.a;
			return $elm$core$Result$Ok(a);
		} else {
			var problems = _v0.a;
			return $elm$core$Result$Err(
				A2($elm$core$List$map, $elm$parser$Parser$problemToDeadEnd, problems));
		}
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime = function (str) {
	return A2($elm$parser$Parser$run, $rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601, str);
};
var $author$project$Helpers$parseTimeString = function (iso8601String) {
	var _v0 = $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime(iso8601String);
	if (!_v0.$) {
		var posix = _v0.a;
		return $elm$core$Maybe$Just(posix);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Decode$timeDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		return $elm$json$Json$Decode$succeed(
			$author$project$Helpers$parseTimeString(str));
	},
	$elm$json$Json$Decode$string);
var $author$project$Types$Worker$HourlyWage = 0;
var $author$project$Types$Worker$MonthlyWage = 1;
var $author$project$Types$Worker$NoCompensation = 2;
var $author$project$Types$Worker$Provision = 3;
var $author$project$Types$Worker$workerCompensationTypeJsonDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (compensationTypeString) {
		return (compensationTypeString === 'HOURLY_WAGE') ? $elm$json$Json$Decode$succeed(0) : ((compensationTypeString === 'MONTHLY_WAGE') ? $elm$json$Json$Decode$succeed(1) : ((compensationTypeString === 'NO_COMPENSATION') ? $elm$json$Json$Decode$succeed(2) : ((compensationTypeString === 'PROVISION') ? $elm$json$Json$Decode$succeed(3) : $elm$json$Json$Decode$fail('Invalid WorkerCompensationType \'' + (compensationTypeString + '\'')))));
	},
	$elm$json$Json$Decode$string);
var $author$project$Types$Worker$jsonDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'compensationType',
	$author$project$Types$Worker$workerCompensationTypeJsonDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'hasWorkTimeTracking',
		$elm$json$Json$Decode$bool,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'hasVacationTracking',
			$elm$json$Json$Decode$bool,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'deletedAt',
				$author$project$Decode$timeDecoder,
				$elm$core$Maybe$Nothing,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'isSelectedByDefault',
					$elm$json$Json$Decode$bool,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'name',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'code',
							$elm$json$Json$Decode$string,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'id',
								$elm$json$Json$Decode$int,
								$elm$json$Json$Decode$succeed($author$project$Types$Worker$Worker)))))))));
var $author$project$Types$Worker$fetchAll = function (_v0) {
	var apiUrl = _v0.j0;
	var token = _v0.oK;
	return $author$project$JwtHttp$task(
		{
			kc: $elm$http$Http$emptyBody,
			mr: 'GET',
			nJ: $elm$http$Http$stringResolver(
				$author$project$JwtHttp$handleJsonResponse(
					$elm$json$Json$Decode$list($author$project$Types$Worker$jsonDecoder))),
			oH: $elm$core$Maybe$Nothing,
			oK: token,
			oU: apiUrl + '/workers'
		});
};
var $author$project$Types$Tasklist$Tasklist = function (id) {
	return function (tasklistTemplateId) {
		return function (customerId) {
			return function (productIds) {
				return function (defaultVehicleMakeId) {
					return function (defaultPrices) {
						return function (user) {
							return function (group) {
								return function (name) {
									return function (tasks) {
										return function (deliveryOn) {
											return function (invoiceOn) {
												return function (status) {
													return function (oneTimeCustomerName) {
														return function (oneTimeCustomerContactInfo) {
															return function (openedAt) {
																return function (closedAt) {
																	return function (invoiceQueuedAt) {
																		return function (invoicedAt) {
																			return {fs: closedAt, kX: customerId, k7: defaultPrices, k8: defaultVehicleMakeId, fA: deliveryOn, fN: group, er: id, fT: invoiceOn, l_: invoiceQueuedAt, hX: invoicedAt, mD: name, ne: oneTimeCustomerContactInfo, nf: oneTimeCustomerName, ni: openedAt, nE: productIds, cw: status, op: tasklistTemplateId, aj: tasks, gA: user};
																		};
																	};
																};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Types$AvailableProduct$DefaultPrice = F2(
	function (productId, defaultPrice) {
		return {k6: defaultPrice, iz: productId};
	});
var $author$project$Data$Products$defaultPriceDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'defaultPrice',
	$elm$json$Json$Decode$float,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'productId',
		$elm$json$Json$Decode$int,
		$elm$json$Json$Decode$succeed($author$project$Types$AvailableProduct$DefaultPrice)));
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {lR: index, mj: match, mG: number, oi: submatches};
	});
var $elm$regex$Regex$findAtMost = _Regex_findAtMost;
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $elm$regex$Regex$fromString = function (string) {
	return A2(
		$elm$regex$Regex$fromStringWith,
		{ks: false, mC: false},
		string);
};
var $elm$regex$Regex$never = _Regex_never;
var $jweir$elm_iso8601$ISO8601$iso8601Regex = A2(
	$elm$regex$Regex$findAtMost,
	1,
	A2(
		$elm$core$Maybe$withDefault,
		$elm$regex$Regex$never,
		$elm$regex$Regex$fromString('(\\d{4})-?' + ('(\\d{2})?-?' + ('(\\d{2})?' + ('T?' + ('(\\d{2})?:?' + ('(\\d{2})?:?' + ('(\\d{2})?' + ('([.,]\\d{1,})?' + ('(Z|[+-]\\d{2}:?\\d{2})?' + '(.*)?')))))))))));
var $elm$regex$Regex$replaceAtMost = _Regex_replaceAtMost;
var $jweir$elm_iso8601$ISO8601$parseMilliseconds = function (msString) {
	if (msString.$ === 1) {
		return 0;
	} else {
		var s = msString.a;
		var decimalStr = A4(
			$elm$regex$Regex$replaceAtMost,
			1,
			A2(
				$elm$core$Maybe$withDefault,
				$elm$regex$Regex$never,
				$elm$regex$Regex$fromString('[,.]')),
			function (_v1) {
				return '0.';
			},
			s);
		var decimal = A2(
			$elm$core$Maybe$withDefault,
			0.0,
			$elm$core$String$toFloat(decimalStr));
		return $elm$core$Basics$round(1000 * decimal);
	}
};
var $jweir$elm_iso8601$ISO8601$Extras$toInt = function (str) {
	return A2(
		$elm$core$Maybe$withDefault,
		0,
		$elm$core$String$toInt(str));
};
var $jweir$elm_iso8601$ISO8601$parseOffset = function (timeString) {
	var re = A2(
		$elm$core$Maybe$withDefault,
		$elm$regex$Regex$never,
		$elm$regex$Regex$fromString('(Z|([+-]\\d{2}:?\\d{2}))?'));
	var match = A3(
		$elm$regex$Regex$findAtMost,
		1,
		A2(
			$elm$core$Maybe$withDefault,
			$elm$regex$Regex$never,
			$elm$regex$Regex$fromString('([-+])(\\d\\d):?(\\d\\d)')),
		A2($elm$core$Maybe$withDefault, '', timeString));
	var parts = A2(
		$elm$core$List$map,
		function ($) {
			return $.oi;
		},
		match);
	var calc = F2(
		function (modifier, minutes) {
			switch (modifier) {
				case '+':
					return minutes;
				case '-':
					return (-1) * minutes;
				default:
					return minutes;
			}
		});
	_v0$2:
	while (true) {
		if ((((parts.b && parts.a.b) && (!parts.a.a.$)) && parts.a.b.b) && (!parts.a.b.a.$)) {
			if (parts.a.b.b.b) {
				if (((!parts.a.b.b.a.$) && (!parts.a.b.b.b.b)) && (!parts.b.b)) {
					var _v1 = parts.a;
					var modifier = _v1.a.a;
					var _v2 = _v1.b;
					var hour_ = _v2.a.a;
					var _v3 = _v2.b;
					var minute_ = _v3.a.a;
					return A2(
						calc,
						modifier,
						($jweir$elm_iso8601$ISO8601$Extras$toInt(hour_) * 60) + $jweir$elm_iso8601$ISO8601$Extras$toInt(minute_));
				} else {
					break _v0$2;
				}
			} else {
				if (!parts.b.b) {
					var _v4 = parts.a;
					var modifier = _v4.a.a;
					var _v5 = _v4.b;
					var hour_ = _v5.a.a;
					return A2(
						calc,
						modifier,
						$jweir$elm_iso8601$ISO8601$Extras$toInt(hour_) * 60);
				} else {
					break _v0$2;
				}
			}
		} else {
			break _v0$2;
		}
	}
	return 0;
};
var $elm$core$Array$fromListHelp = F3(
	function (list, nodeList, nodeListSize) {
		fromListHelp:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, list);
			var jsArray = _v0.a;
			var remainingItems = _v0.b;
			if (_Utils_cmp(
				$elm$core$Elm$JsArray$length(jsArray),
				$elm$core$Array$branchFactor) < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					true,
					{B: nodeList, v: nodeListSize, y: jsArray});
			} else {
				var $temp$list = remainingItems,
					$temp$nodeList = A2(
					$elm$core$List$cons,
					$elm$core$Array$Leaf(jsArray),
					nodeList),
					$temp$nodeListSize = nodeListSize + 1;
				list = $temp$list;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue fromListHelp;
			}
		}
	});
var $elm$core$Array$fromList = function (list) {
	if (!list.b) {
		return $elm$core$Array$empty;
	} else {
		return A3($elm$core$Array$fromListHelp, list, _List_Nil, 0);
	}
};
var $jweir$elm_iso8601$ISO8601$Extras$calendar = $elm$core$Array$fromList(
	_List_fromArray(
		[
			_Utils_Tuple3('January', 31, 31),
			_Utils_Tuple3('February', 28, 29),
			_Utils_Tuple3('March', 31, 31),
			_Utils_Tuple3('April', 30, 30),
			_Utils_Tuple3('May', 31, 31),
			_Utils_Tuple3('June', 30, 30),
			_Utils_Tuple3('July', 31, 31),
			_Utils_Tuple3('August', 31, 31),
			_Utils_Tuple3('September', 30, 30),
			_Utils_Tuple3('October', 31, 31),
			_Utils_Tuple3('November', 30, 30),
			_Utils_Tuple3('December', 31, 31)
		]));
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $elm$core$Array$bitMask = 4294967295 >>> (32 - $elm$core$Array$shiftStep);
var $elm$core$Elm$JsArray$unsafeGet = _JsArray_unsafeGet;
var $elm$core$Array$getHelp = F3(
	function (shift, index, tree) {
		getHelp:
		while (true) {
			var pos = $elm$core$Array$bitMask & (index >>> shift);
			var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (!_v0.$) {
				var subTree = _v0.a;
				var $temp$shift = shift - $elm$core$Array$shiftStep,
					$temp$index = index,
					$temp$tree = subTree;
				shift = $temp$shift;
				index = $temp$index;
				tree = $temp$tree;
				continue getHelp;
			} else {
				var values = _v0.a;
				return A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, values);
			}
		}
	});
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $elm$core$Array$tailIndex = function (len) {
	return (len >>> 5) << 5;
};
var $elm$core$Array$get = F2(
	function (index, _v0) {
		var len = _v0.a;
		var startShift = _v0.b;
		var tree = _v0.c;
		var tail = _v0.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? $elm$core$Maybe$Nothing : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? $elm$core$Maybe$Just(
			A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, tail)) : $elm$core$Maybe$Just(
			A3($elm$core$Array$getHelp, startShift, index, tree)));
	});
var $jweir$elm_iso8601$ISO8601$Extras$isLeapYear = function (year) {
	var c = !A2($elm$core$Basics$modBy, 400, year);
	var b = !A2($elm$core$Basics$modBy, 100, year);
	var a = !A2($elm$core$Basics$modBy, 4, year);
	var _v0 = _List_fromArray(
		[a, b, c]);
	_v0$3:
	while (true) {
		if ((_v0.b && _v0.a) && _v0.b.b) {
			if (!_v0.b.a) {
				if (_v0.b.b.b && (!_v0.b.b.b.b)) {
					var _v3 = _v0.b;
					var _v4 = _v3.b;
					return true;
				} else {
					break _v0$3;
				}
			} else {
				if (_v0.b.b.b) {
					if (_v0.b.b.a) {
						if (!_v0.b.b.b.b) {
							var _v1 = _v0.b;
							var _v2 = _v1.b;
							return true;
						} else {
							break _v0$3;
						}
					} else {
						if (!_v0.b.b.b.b) {
							var _v5 = _v0.b;
							var _v6 = _v5.b;
							return false;
						} else {
							break _v0$3;
						}
					}
				} else {
					break _v0$3;
				}
			}
		} else {
			break _v0$3;
		}
	}
	return false;
};
var $jweir$elm_iso8601$ISO8601$Extras$daysInMonth = F2(
	function (year, monthInt) {
		var calMonth = A2($elm$core$Array$get, monthInt - 1, $jweir$elm_iso8601$ISO8601$Extras$calendar);
		if (!calMonth.$) {
			var _v1 = calMonth.a;
			var days = _v1.b;
			var leapDays = _v1.c;
			return $jweir$elm_iso8601$ISO8601$Extras$isLeapYear(year) ? leapDays : days;
		} else {
			return 0;
		}
	});
var $jweir$elm_iso8601$ISO8601$validateHour = function (time) {
	var s = time.eX;
	var m = time.ex;
	var h = time.ep;
	return ((h === 24) && ((m + s) > 0)) ? $elm$core$Result$Err('hour is out of range') : (((h < 0) || (h > 24)) ? $elm$core$Result$Err('hour is out of range') : (((m < 0) || (m > 59)) ? $elm$core$Result$Err('minute is out of range') : (((s < 0) || (s > 59)) ? $elm$core$Result$Err('second is out of range') : $elm$core$Result$Ok(time))));
};
var $jweir$elm_iso8601$ISO8601$validateTime = function (time) {
	var maxDays = $jweir$elm_iso8601$ISO8601$Extras$daysInMonth;
	return ((time.mw < 1) || (time.mw > 12)) ? $elm$core$Result$Err('month is out of range') : (((time.d5 < 1) || (_Utils_cmp(
		time.d5,
		A2($jweir$elm_iso8601$ISO8601$Extras$daysInMonth, time.fi, time.mw)) > 0)) ? $elm$core$Result$Err('day is out of range') : $jweir$elm_iso8601$ISO8601$validateHour(time));
};
var $jweir$elm_iso8601$ISO8601$fromString = function (s) {
	var unwrap = F2(
		function (x, d) {
			return $jweir$elm_iso8601$ISO8601$Extras$toInt(
				A2($elm$core$Maybe$withDefault, d, x));
		});
	var parts = A2(
		$elm$core$List$map,
		function ($) {
			return $.oi;
		},
		$jweir$elm_iso8601$ISO8601$iso8601Regex(s));
	if (((((((((((parts.b && parts.a.b) && parts.a.b.b) && parts.a.b.b.b) && parts.a.b.b.b.b) && parts.a.b.b.b.b.b) && parts.a.b.b.b.b.b.b) && parts.a.b.b.b.b.b.b.b) && parts.a.b.b.b.b.b.b.b.b) && parts.a.b.b.b.b.b.b.b.b.b) && (!parts.a.b.b.b.b.b.b.b.b.b.b)) && (!parts.b.b)) {
		var _v1 = parts.a;
		var y = _v1.a;
		var _v2 = _v1.b;
		var mon = _v2.a;
		var _v3 = _v2.b;
		var d = _v3.a;
		var _v4 = _v3.b;
		var h = _v4.a;
		var _v5 = _v4.b;
		var min = _v5.a;
		var _v6 = _v5.b;
		var sec = _v6.a;
		var _v7 = _v6.b;
		var mil = _v7.a;
		var _v8 = _v7.b;
		var off = _v8.a;
		var _v9 = _v8.b;
		var invalid = _v9.a;
		if (!invalid.$) {
			return $elm$core$Result$Err('unexpected text');
		} else {
			return $jweir$elm_iso8601$ISO8601$validateTime(
				{
					d5: A2(unwrap, d, '1'),
					ep: A2(unwrap, h, '0'),
					h6: $jweir$elm_iso8601$ISO8601$parseMilliseconds(mil),
					ex: A2(unwrap, min, '0'),
					mw: A2(unwrap, mon, '1'),
					f1: $jweir$elm_iso8601$ISO8601$parseOffset(off),
					eX: A2(unwrap, sec, '0'),
					fi: A2(unwrap, y, '0')
				});
		}
	} else {
		return $elm$core$Result$Err('Unable to parse time');
	}
};
var $author$project$Types$IsoTime$from_string = function (string) {
	var _v0 = $jweir$elm_iso8601$ISO8601$fromString(string);
	if (!_v0.$) {
		var time = _v0.a;
		return $elm$core$Maybe$Just(time);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Decode$isoTimeDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (isoTimeString) {
		var _v0 = $author$project$Types$IsoTime$from_string(isoTimeString);
		if (!_v0.$) {
			var isoTime = _v0.a;
			return $elm$json$Json$Decode$succeed(isoTime);
		} else {
			return $elm$json$Json$Decode$fail(isoTimeString);
		}
	},
	$elm$json$Json$Decode$string);
var $justinmimbs$date$Date$deadEndToString = function (_v0) {
	var problem = _v0.iw;
	if (problem.$ === 12) {
		var message = problem.a;
		return message;
	} else {
		return 'Expected a date in ISO 8601 format';
	}
};
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $justinmimbs$date$Date$MonthAndDay = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $justinmimbs$date$Date$OrdinalDay = function (a) {
	return {$: 2, a: a};
};
var $justinmimbs$date$Date$WeekAndWeekday = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$backtrackable = function (_v0) {
	var parse = _v0;
	return function (s0) {
		var _v1 = parse(s0);
		if (_v1.$ === 1) {
			var x = _v1.b;
			return A2($elm$parser$Parser$Advanced$Bad, false, x);
		} else {
			var a = _v1.b;
			var s1 = _v1.c;
			return A3($elm$parser$Parser$Advanced$Good, false, a, s1);
		}
	};
};
var $elm$parser$Parser$backtrackable = $elm$parser$Parser$Advanced$backtrackable;
var $elm$parser$Parser$Advanced$commit = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, true, a, s);
	};
};
var $elm$parser$Parser$commit = $elm$parser$Parser$Advanced$commit;
var $elm$parser$Parser$mapChompedString = $elm$parser$Parser$Advanced$mapChompedString;
var $justinmimbs$date$Date$int1 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	$elm$parser$Parser$chompIf($elm$core$Char$isDigit));
var $justinmimbs$date$Date$int2 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed(0),
			$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
		$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
var $justinmimbs$date$Date$int3 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed(0),
				$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
			$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
		$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
var $elm$parser$Parser$Expecting = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$toToken = function (str) {
	return A2(
		$elm$parser$Parser$Advanced$Token,
		str,
		$elm$parser$Parser$Expecting(str));
};
var $elm$parser$Parser$token = function (str) {
	return $elm$parser$Parser$Advanced$token(
		$elm$parser$Parser$toToken(str));
};
var $justinmimbs$date$Date$dayOfYear = $elm$parser$Parser$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed($elm$core$Basics$identity),
				$elm$parser$Parser$token('-')),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$elm$parser$Parser$backtrackable(
						A2(
							$elm$parser$Parser$andThen,
							$elm$parser$Parser$commit,
							A2($elm$parser$Parser$map, $justinmimbs$date$Date$OrdinalDay, $justinmimbs$date$Date$int3))),
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							$elm$parser$Parser$succeed($justinmimbs$date$Date$MonthAndDay),
							$justinmimbs$date$Date$int2),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$token('-')),
									$justinmimbs$date$Date$int2),
									$elm$parser$Parser$succeed(1)
								]))),
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							A2(
								$elm$parser$Parser$ignorer,
								$elm$parser$Parser$succeed($justinmimbs$date$Date$WeekAndWeekday),
								$elm$parser$Parser$token('W')),
							$justinmimbs$date$Date$int2),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$token('-')),
									$justinmimbs$date$Date$int1),
									$elm$parser$Parser$succeed(1)
								])))
					]))),
			$elm$parser$Parser$backtrackable(
			A2(
				$elm$parser$Parser$andThen,
				$elm$parser$Parser$commit,
				A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						$elm$parser$Parser$succeed($justinmimbs$date$Date$MonthAndDay),
						$justinmimbs$date$Date$int2),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								$justinmimbs$date$Date$int2,
								$elm$parser$Parser$succeed(1)
							]))))),
			A2($elm$parser$Parser$map, $justinmimbs$date$Date$OrdinalDay, $justinmimbs$date$Date$int3),
			A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($justinmimbs$date$Date$WeekAndWeekday),
					$elm$parser$Parser$token('W')),
				$justinmimbs$date$Date$int2),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$justinmimbs$date$Date$int1,
						$elm$parser$Parser$succeed(1)
					]))),
			$elm$parser$Parser$succeed(
			$justinmimbs$date$Date$OrdinalDay(1))
		]));
var $justinmimbs$date$Date$isBetweenInt = F3(
	function (a, b, x) {
		return (_Utils_cmp(a, x) < 1) && (_Utils_cmp(x, b) < 1);
	});
var $justinmimbs$date$Date$numberToMonth = function (mn) {
	var _v0 = A2($elm$core$Basics$max, 1, mn);
	switch (_v0) {
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		case 7:
			return 6;
		case 8:
			return 7;
		case 9:
			return 8;
		case 10:
			return 9;
		case 11:
			return 10;
		default:
			return 11;
	}
};
var $justinmimbs$date$Date$fromCalendarParts = F3(
	function (y, mn, d) {
		return (A3($justinmimbs$date$Date$isBetweenInt, 1, 12, mn) && A3(
			$justinmimbs$date$Date$isBetweenInt,
			1,
			A2(
				$justinmimbs$date$Date$daysInMonth,
				y,
				$justinmimbs$date$Date$numberToMonth(mn)),
			d)) ? $elm$core$Result$Ok(
			($justinmimbs$date$Date$daysBeforeYear(y) + A2(
				$justinmimbs$date$Date$daysBeforeMonth,
				y,
				$justinmimbs$date$Date$numberToMonth(mn))) + d) : $elm$core$Result$Err(
			'Invalid calendar date (' + ($elm$core$String$fromInt(y) + (', ' + ($elm$core$String$fromInt(mn) + (', ' + ($elm$core$String$fromInt(d) + ')'))))));
	});
var $justinmimbs$date$Date$fromOrdinalParts = F2(
	function (y, od) {
		return (A3($justinmimbs$date$Date$isBetweenInt, 1, 365, od) || ((od === 366) && $justinmimbs$date$Date$isLeapYear(y))) ? $elm$core$Result$Ok(
			$justinmimbs$date$Date$daysBeforeYear(y) + od) : $elm$core$Result$Err(
			'Invalid ordinal date (' + ($elm$core$String$fromInt(y) + (', ' + ($elm$core$String$fromInt(od) + ')'))));
	});
var $justinmimbs$date$Date$weekdayNumber = function (_v0) {
	var rd = _v0;
	var _v1 = A2($elm$core$Basics$modBy, 7, rd);
	if (!_v1) {
		return 7;
	} else {
		var n = _v1;
		return n;
	}
};
var $justinmimbs$date$Date$daysBeforeWeekYear = function (y) {
	var jan4 = $justinmimbs$date$Date$daysBeforeYear(y) + 4;
	return jan4 - $justinmimbs$date$Date$weekdayNumber(jan4);
};
var $justinmimbs$date$Date$firstOfYear = function (y) {
	return $justinmimbs$date$Date$daysBeforeYear(y) + 1;
};
var $justinmimbs$date$Date$is53WeekYear = function (y) {
	var wdnJan1 = $justinmimbs$date$Date$weekdayNumber(
		$justinmimbs$date$Date$firstOfYear(y));
	return (wdnJan1 === 4) || ((wdnJan1 === 3) && $justinmimbs$date$Date$isLeapYear(y));
};
var $justinmimbs$date$Date$fromWeekParts = F3(
	function (wy, wn, wdn) {
		return (A3($justinmimbs$date$Date$isBetweenInt, 1, 7, wdn) && (A3($justinmimbs$date$Date$isBetweenInt, 1, 52, wn) || ((wn === 53) && $justinmimbs$date$Date$is53WeekYear(wy)))) ? $elm$core$Result$Ok(
			($justinmimbs$date$Date$daysBeforeWeekYear(wy) + ((wn - 1) * 7)) + wdn) : $elm$core$Result$Err(
			'Invalid week date (' + ($elm$core$String$fromInt(wy) + (', ' + ($elm$core$String$fromInt(wn) + (', ' + ($elm$core$String$fromInt(wdn) + ')'))))));
	});
var $justinmimbs$date$Date$fromYearAndDayOfYear = function (_v0) {
	var y = _v0.a;
	var doy = _v0.b;
	switch (doy.$) {
		case 0:
			var mn = doy.a;
			var d = doy.b;
			return A3($justinmimbs$date$Date$fromCalendarParts, y, mn, d);
		case 1:
			var wn = doy.a;
			var wdn = doy.b;
			return A3($justinmimbs$date$Date$fromWeekParts, y, wn, wdn);
		default:
			var od = doy.a;
			return A2($justinmimbs$date$Date$fromOrdinalParts, y, od);
	}
};
var $justinmimbs$date$Date$int4 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					A2(
						$elm$parser$Parser$ignorer,
						$elm$parser$Parser$succeed(0),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									$elm$parser$Parser$chompIf(
									function (c) {
										return c === '-';
									}),
									$elm$parser$Parser$succeed(0)
								]))),
					$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
				$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
			$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
		$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $justinmimbs$date$Date$resultToParser = function (result) {
	if (!result.$) {
		var x = result.a;
		return $elm$parser$Parser$succeed(x);
	} else {
		var message = result.a;
		return $elm$parser$Parser$problem(message);
	}
};
var $justinmimbs$date$Date$parser = A2(
	$elm$parser$Parser$andThen,
	A2($elm$core$Basics$composeR, $justinmimbs$date$Date$fromYearAndDayOfYear, $justinmimbs$date$Date$resultToParser),
	A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$keeper,
			$elm$parser$Parser$succeed($elm$core$Tuple$pair),
			$justinmimbs$date$Date$int4),
		$justinmimbs$date$Date$dayOfYear));
var $justinmimbs$date$Date$fromIsoString = A2(
	$elm$core$Basics$composeR,
	$elm$parser$Parser$run(
		A2(
			$elm$parser$Parser$keeper,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			A2(
				$elm$parser$Parser$ignorer,
				$justinmimbs$date$Date$parser,
				A2(
					$elm$parser$Parser$andThen,
					$justinmimbs$date$Date$resultToParser,
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								A2($elm$parser$Parser$map, $elm$core$Result$Ok, $elm$parser$Parser$end),
								A2(
								$elm$parser$Parser$map,
								$elm$core$Basics$always(
									$elm$core$Result$Err('Expected a date only, not a date and time')),
								$elm$parser$Parser$chompIf(
									$elm$core$Basics$eq('T'))),
								$elm$parser$Parser$succeed(
								$elm$core$Result$Err('Expected a date only'))
							])))))),
	$elm$core$Result$mapError(
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$head,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Maybe$map($justinmimbs$date$Date$deadEndToString),
				$elm$core$Maybe$withDefault('')))));
var $author$project$Helpers$parseDateString = function (string) {
	var _v0 = $justinmimbs$date$Date$fromIsoString(string);
	if (!_v0.$) {
		var date = _v0.a;
		return $elm$core$Maybe$Just(date);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Types$Date$jsonDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (dateString) {
		var _v0 = $author$project$Helpers$parseDateString(dateString);
		if (!_v0.$) {
			var date = _v0.a;
			return $elm$json$Json$Decode$succeed(date);
		} else {
			return $elm$json$Json$Decode$fail(dateString);
		}
	},
	$elm$json$Json$Decode$string);
var $elm$json$Json$Decode$maybe = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
};
var $author$project$Types$TasklistTask$Task = function (id) {
	return function (workerIds) {
		return function (vehicle) {
			return function (date) {
				return function (products) {
					return function (workerOperationNotice) {
						return function (didSkipQueue) {
							return function (comment) {
								return function (createdAt) {
									return function (updatedAt) {
										return {kH: comment, d4: createdAt, b4: date, lg: didSkipQueue, er: id, iA: products, fa: updatedAt, oX: vehicle, jz: workerIds, o4: workerOperationNotice};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Types$SelectedProduct$SelectedProduct = F2(
	function (productId, price) {
		return {iv: price, iz: productId};
	});
var $author$project$Types$SelectedProduct$selectedProductDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'price',
	A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$float),
	$elm$core$Maybe$Nothing,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'productId',
		$elm$json$Json$Decode$int,
		$elm$json$Json$Decode$succeed($author$project$Types$SelectedProduct$SelectedProduct)));
var $author$project$Types$Vehicle$Vehicle$Vehicle = F3(
	function (licenseNumber, make, model) {
		return {de: licenseNumber, mi: make, mv: model};
	});
var $author$project$Types$Vehicle$LicenseNumber$InvalidLicenseNumber = function (a) {
	return {$: 1, a: a};
};
var $author$project$Types$Vehicle$LicenseNumber$ValidLicenseNumber = function (a) {
	return {$: 0, a: a};
};
var $elm$core$String$endsWith = _String_endsWith;
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $author$project$Types$Vehicle$LicenseNumber$create = function (string) {
	var startsWithDash = A2($elm$core$String$startsWith, '-', string);
	var isValidCharacter = function (character) {
		return $elm$core$Char$isAlphaNum(character) || (character === '-');
	};
	var hasMultipleDashes = function (dashCount) {
		return dashCount > 1;
	}(
		$elm$core$List$length(
			A2(
				$elm$core$List$filter,
				$elm$core$Basics$eq('-'),
				$elm$core$String$toList(string))));
	var hasInvalidCharacters = !$elm$core$List$isEmpty(
		A2(
			$elm$core$List$filter,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, isValidCharacter),
			$elm$core$String$toList(string)));
	var endsInDash = A2($elm$core$String$endsWith, '-', string);
	var passesValidation = !(hasInvalidCharacters || (hasMultipleDashes || (startsWithDash || endsInDash)));
	return passesValidation ? $author$project$Types$Vehicle$LicenseNumber$ValidLicenseNumber(string) : $author$project$Types$Vehicle$LicenseNumber$InvalidLicenseNumber(string);
};
var $author$project$Types$Vehicle$LicenseNumber$licenseNumberDecoder = A2(
	$elm$json$Json$Decode$andThen,
	A2($elm$core$Basics$composeR, $author$project$Types$Vehicle$LicenseNumber$create, $elm$json$Json$Decode$succeed),
	$elm$json$Json$Decode$string);
var $author$project$Decode$vehicleDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'model',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'make',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'licenseNo',
			$author$project$Types$Vehicle$LicenseNumber$licenseNumberDecoder,
			$elm$json$Json$Decode$succeed($author$project$Types$Vehicle$Vehicle$Vehicle))));
var $author$project$Types$TasklistTask$WorkerTaskOperationNotice = F4(
	function (taskId, userId, reason, isAcknowledged) {
		return {l$: isAcknowledged, nF: reason, jd: taskId, oV: userId};
	});
var $author$project$Types$TasklistTask$WorkerCreatedTask = 0;
var $author$project$Types$TasklistTask$WorkerEditedTask = 1;
var $author$project$Data$Tasklists$workerTaskOperationNoticeReasonDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (reasonString) {
		switch (reasonString) {
			case 'CREATED':
				return $elm$json$Json$Decode$succeed(0);
			case 'EDITED':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('Invalid WorkerTaskOpearationReason \'' + (reasonString + '\''));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Data$Tasklists$workerOperationNoticeDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'isAcknowledged',
	$elm$json$Json$Decode$bool,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'reason',
		$author$project$Data$Tasklists$workerTaskOperationNoticeReasonDecoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'userId',
			$elm$json$Json$Decode$int,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'taskId',
				$elm$json$Json$Decode$int,
				$elm$json$Json$Decode$succeed($author$project$Types$TasklistTask$WorkerTaskOperationNotice)))));
var $author$project$Data$Tasklists$taskDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'updatedAt',
	$author$project$Decode$isoTimeDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'createdAt',
		$author$project$Decode$isoTimeDecoder,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'comment',
			$elm$json$Json$Decode$string,
			'',
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'didSkipQueue',
				$elm$json$Json$Decode$bool,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'workerOperationNotice',
					$elm$json$Json$Decode$maybe($author$project$Data$Tasklists$workerOperationNoticeDecoder),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'selectedProducts',
						$elm$json$Json$Decode$list($author$project$Types$SelectedProduct$selectedProductDecoder),
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'workDate',
							$elm$json$Json$Decode$maybe($author$project$Types$Date$jsonDecoder),
							$elm$core$Maybe$Nothing,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'vehicle',
								$author$project$Decode$vehicleDecoder,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'workerIds',
									$elm$json$Json$Decode$list($elm$json$Json$Decode$int),
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'id',
										$elm$json$Json$Decode$int,
										$elm$json$Json$Decode$succeed($author$project$Types$TasklistTask$Task)))))))))));
var $author$project$Types$Tasklist$ClosedTasklist = 1;
var $author$project$Types$Tasklist$InvoiceQueuedTasklist = 3;
var $author$project$Types$Tasklist$InvoicedTasklist = 2;
var $author$project$Types$Tasklist$OpenTasklist = 0;
var $author$project$Data$Tasklists$tasklistStatusDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (statusString) {
		return (statusString === 'OPEN') ? $elm$json$Json$Decode$succeed(0) : ((statusString === 'CLOSED') ? $elm$json$Json$Decode$succeed(1) : ((statusString === 'INVOICE_QUEUED') ? $elm$json$Json$Decode$succeed(3) : ((statusString === 'INVOICED') ? $elm$json$Json$Decode$succeed(2) : $elm$json$Json$Decode$fail('Invalid TasklistStatus \'' + (statusString + '\'')))));
	},
	$elm$json$Json$Decode$string);
var $author$project$Types$User$AdminUser = function (a) {
	return {$: 0, a: a};
};
var $author$project$Types$User$CompanyManagerUser = function (a) {
	return {$: 2, a: a};
};
var $author$project$Types$User$MobileWorkerUser = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Types$User$OfficeUser = function (a) {
	return {$: 1, a: a};
};
var $author$project$Types$User$SiteManagerUser = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Types$User$SiteUser = function (a) {
	return {$: 3, a: a};
};
var $author$project$Types$User$SiteWorkerUser = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Types$User$UserJson = F3(
	function (role, info, siteUserInfo) {
		return {hR: info, iO: role, i4: siteUserInfo};
	});
var $author$project$Types$User$Info = F6(
	function (id, username, firstName, lastName, fullName, isActive) {
		return {lx: firstName, hz: fullName, er: id, bt: isActive, me: lastName, cG: username};
	});
var $author$project$Types$User$infoDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'isActive',
	$elm$json$Json$Decode$bool,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'fullName',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'lastName',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'firstName',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'username',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'id',
						$elm$json$Json$Decode$int,
						$elm$json$Json$Decode$succeed($author$project$Types$User$Info)))))));
var $author$project$Types$User$SiteUserInfo = F5(
	function (costCenter, nvSalesPersonCode, priceInputMode, site, workerIds) {
		return {kP: costCenter, mH: nvSalesPersonCode, nC: priceInputMode, eZ: site, jz: workerIds};
	});
var $author$project$Types$User$WithVat = 0;
var $author$project$Types$User$WithoutVat = 1;
var $author$project$Types$User$priceInputModeDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'WITH_VAT':
				return $elm$json$Json$Decode$succeed(0);
			case 'WITHOUT_VAT':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('Unknown user price input mode \'' + (string + '\''));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Types$Site$MobileSite = function (a) {
	return {$: 1, a: a};
};
var $author$project$Types$Site$WashSite = function (a) {
	return {$: 0, a: a};
};
var $author$project$Types$Site$SiteJson = F7(
	function (id, userIds, name, abbreviation, useQueueSkipNotice, type_, isActive) {
		return {jC: abbreviation, er: id, bt: isActive, mD: name, gz: type_, bN: useQueueSkipNotice, cE: userIds};
	});
var $author$project$Types$Site$siteJsonDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'isActive',
	$elm$json$Json$Decode$bool,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'type',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'useQueueSkipNotice',
			$elm$json$Json$Decode$bool,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'abbreviation',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'name',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'userIds',
						$elm$json$Json$Decode$list($elm$json$Json$Decode$int),
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'id',
							$elm$json$Json$Decode$int,
							$elm$json$Json$Decode$succeed($author$project$Types$Site$SiteJson))))))));
var $author$project$Types$Site$siteDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (json) {
		var _v0 = json.gz;
		switch (_v0) {
			case 'WASH_SITE':
				return $elm$json$Json$Decode$succeed(
					$author$project$Types$Site$WashSite(
						{jC: json.jC, er: json.er, bt: json.bt, mD: json.mD, bN: json.bN, cE: json.cE}));
			case 'MOBILE_SITE':
				return $elm$json$Json$Decode$succeed(
					$author$project$Types$Site$MobileSite(
						{jC: json.jC, er: json.er, bt: json.bt, mD: json.mD, bN: json.bN, cE: json.cE}));
			default:
				return $elm$json$Json$Decode$fail('Unknown site type \'' + (json.gz + '\''));
		}
	},
	$author$project$Types$Site$siteJsonDecoder);
var $author$project$Types$User$siteUserInfoDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'workerIds',
	$elm$json$Json$Decode$list($elm$json$Json$Decode$int),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'site',
		$author$project$Types$Site$siteDecoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'priceInputMode',
			$author$project$Types$User$priceInputModeDecoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'nvSalesPersonCode',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'costCenter',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$Types$User$SiteUserInfo))))));
var $author$project$Types$User$userJsonDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'siteUserInfo',
	$elm$json$Json$Decode$maybe($author$project$Types$User$siteUserInfoDecoder),
	$elm$core$Maybe$Nothing,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'info',
		$author$project$Types$User$infoDecoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'role',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$Types$User$UserJson))));
var $author$project$Types$User$userDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (json) {
		var _v0 = json;
		var role = _v0.iO;
		var info = _v0.hR;
		var siteUserInfo = _v0.i4;
		var _v1 = _Utils_Tuple2(role, siteUserInfo);
		_v1$6:
		while (true) {
			if (_v1.b.$ === 1) {
				switch (_v1.a) {
					case 'ADMIN':
						var _v2 = _v1.b;
						return $elm$json$Json$Decode$succeed(
							$author$project$Types$User$AdminUser(info));
					case 'OFFICE':
						var _v3 = _v1.b;
						return $elm$json$Json$Decode$succeed(
							$author$project$Types$User$OfficeUser(info));
					case 'COMPANY_MANAGER':
						var _v4 = _v1.b;
						return $elm$json$Json$Decode$succeed(
							$author$project$Types$User$CompanyManagerUser(info));
					default:
						break _v1$6;
				}
			} else {
				switch (_v1.a) {
					case 'MOBILE_WORKER':
						var siteUserInfo2 = _v1.b.a;
						return $elm$json$Json$Decode$succeed(
							$author$project$Types$User$SiteUser(
								A2($author$project$Types$User$MobileWorkerUser, info, siteUserInfo2)));
					case 'SITE_MANAGER':
						var siteUserInfo2 = _v1.b.a;
						return $elm$json$Json$Decode$succeed(
							$author$project$Types$User$SiteUser(
								A2($author$project$Types$User$SiteManagerUser, info, siteUserInfo2)));
					case 'SITE_WORKER':
						var siteUserInfo2 = _v1.b.a;
						return $elm$json$Json$Decode$succeed(
							$author$project$Types$User$SiteUser(
								A2($author$project$Types$User$SiteWorkerUser, info, siteUserInfo2)));
					default:
						break _v1$6;
				}
			}
		}
		return $elm$json$Json$Decode$fail('Invalid \'role\' & \'siteUserInfo\' combination in User JSON');
	},
	$author$project$Types$User$userJsonDecoder);
var $author$project$Data$Tasklists$tasklistDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'invoicedAt',
	$elm$json$Json$Decode$maybe($author$project$Decode$isoTimeDecoder),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'invoiceQueuedAt',
		$elm$json$Json$Decode$maybe($author$project$Decode$isoTimeDecoder),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'closedAt',
			$elm$json$Json$Decode$maybe($author$project$Decode$isoTimeDecoder),
			$elm$core$Maybe$Nothing,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'openedAt',
				$author$project$Decode$isoTimeDecoder,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'oneTimeCustomerContactInfo',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'oneTimeCustomerName',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'status',
							$author$project$Data$Tasklists$tasklistStatusDecoder,
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
								'invoiceOn',
								$elm$json$Json$Decode$maybe($author$project$Types$Date$jsonDecoder),
								$elm$core$Maybe$Nothing,
								A4(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
									'deliveryOn',
									$elm$json$Json$Decode$maybe($author$project$Types$Date$jsonDecoder),
									$elm$core$Maybe$Nothing,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'tasks',
										$elm$json$Json$Decode$list($author$project$Data$Tasklists$taskDecoder),
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'name',
											$elm$json$Json$Decode$string,
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'group',
												$elm$json$Json$Decode$string,
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'user',
													$author$project$Types$User$userDecoder,
													A3(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
														'defaultPrices',
														$elm$json$Json$Decode$list($author$project$Data$Products$defaultPriceDecoder),
														A3(
															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
															'defaultVehicleMakeId',
															$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
															A3(
																$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																'productIds',
																$elm$json$Json$Decode$list($elm$json$Json$Decode$int),
																A3(
																	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																	'customerId',
																	$elm$json$Json$Decode$int,
																	A3(
																		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																		'tasklistTemplateId',
																		$elm$json$Json$Decode$int,
																		A3(
																			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																			'id',
																			$elm$json$Json$Decode$int,
																			$elm$json$Json$Decode$succeed($author$project$Types$Tasklist$Tasklist))))))))))))))))))));
var $author$project$Data$Tasklists$fetchClosed = function (_v0) {
	var apiUrl = _v0.j0;
	var token = _v0.oK;
	return $author$project$JwtHttp$task(
		{
			kc: $elm$http$Http$emptyBody,
			mr: 'GET',
			nJ: $elm$http$Http$stringResolver(
				$author$project$JwtHttp$handleJsonResponse(
					$elm$json$Json$Decode$list($author$project$Data$Tasklists$tasklistDecoder))),
			oH: $elm$core$Maybe$Nothing,
			oK: token,
			oU: apiUrl + '/tasklists/closed'
		});
};
var $author$project$Data$Tasklists$fetchInvoiceQueued = function (_v0) {
	var apiUrl = _v0.j0;
	var token = _v0.oK;
	return $author$project$JwtHttp$task(
		{
			kc: $elm$http$Http$emptyBody,
			mr: 'GET',
			nJ: $elm$http$Http$stringResolver(
				$author$project$JwtHttp$handleJsonResponse(
					$elm$json$Json$Decode$list($author$project$Data$Tasklists$tasklistDecoder))),
			oH: $elm$core$Maybe$Nothing,
			oK: token,
			oU: apiUrl + '/tasklists/invoice-queued'
		});
};
var $author$project$Data$Tasklists$fetchInvoiced7d = function (_v0) {
	var apiUrl = _v0.j0;
	var token = _v0.oK;
	return $author$project$JwtHttp$task(
		{
			kc: $elm$http$Http$emptyBody,
			mr: 'GET',
			nJ: $elm$http$Http$stringResolver(
				$author$project$JwtHttp$handleJsonResponse(
					$elm$json$Json$Decode$list($author$project$Data$Tasklists$tasklistDecoder))),
			oH: $elm$core$Maybe$Nothing,
			oK: token,
			oU: apiUrl + '/tasklists/invoiced/last-7-days'
		});
};
var $elm$core$Task$map3 = F4(
	function (func, taskA, taskB, taskC) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return A2(
							$elm$core$Task$andThen,
							function (c) {
								return $elm$core$Task$succeed(
									A3(func, a, b, c));
							},
							taskC);
					},
					taskB);
			},
			taskA);
	});
var $author$project$Pages$Invoicing$fetchData = function (serverContext) {
	var fetchTasklists = A4(
		$elm$core$Task$map3,
		F3(
			function (invoicedTasklists, closedTasklists, invoiceQueuedTasklists) {
				return {r: closedTasklists, U: invoiceQueuedTasklists, ae: invoicedTasklists};
			}),
		$author$project$Data$Tasklists$fetchInvoiced7d(serverContext),
		$author$project$Data$Tasklists$fetchClosed(serverContext),
		$author$project$Data$Tasklists$fetchInvoiceQueued(serverContext));
	var fetchOtherEntities = A4(
		$elm$core$Task$map3,
		F3(
			function (customers, workers, products) {
				return {ap: customers, iA: products, jA: workers};
			}),
		$author$project$Data$Customers$fetch(serverContext),
		$author$project$Types$Worker$fetchAll(serverContext),
		$author$project$Data$Products$fetchAll(serverContext));
	return A3(
		$elm$core$Task$map2,
		F2(
			function (_v0, _v1) {
				var invoicedTasklists = _v0.ae;
				var closedTasklists = _v0.r;
				var invoiceQueuedTasklists = _v0.U;
				var customers = _v1.ap;
				var workers = _v1.jA;
				var products = _v1.iA;
				return {r: closedTasklists, ap: customers, U: invoiceQueuedTasklists, ae: invoicedTasklists, iA: products, jA: workers};
			}),
		fetchTasklists,
		fetchOtherEntities);
};
var $author$project$Pages$Invoicing$init = function (serverContext) {
	var initialModel = {r: $elm$core$Dict$empty, cS: 0, ap: _List_Nil, ea: $elm$core$Maybe$Nothing, U: $elm$core$Dict$empty, ae: $elm$core$Dict$empty, dj: $author$project$Pages$Invoicing$NoDialog, iA: $elm$core$Dict$empty, w: $elm$core$Dict$empty, jA: $elm$core$Dict$empty};
	return _Utils_Tuple2(
		initialModel,
		A2(
			$elm$core$Task$attempt,
			$author$project$Pages$Invoicing$GotData,
			$author$project$Pages$Invoicing$fetchData(serverContext)));
};
var $author$project$Pages$Reporting$AskingPin = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Reporting$Msg_AskingPin = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Reporting$NoOp_AskingPin = {$: 2};
var $elm$browser$Browser$Dom$focus = _Browser_call('focus');
var $author$project$Pages$Reporting$init_AskingPin = function () {
	var initialModel = {bn: ''};
	return _Utils_Tuple2(
		{
			_: $author$project$Pages$Reporting$AskingPin(initialModel)
		},
		A2(
			$elm$core$Platform$Cmd$map,
			$author$project$Pages$Reporting$Msg_AskingPin,
			A2(
				$elm$core$Task$attempt,
				function (_v0) {
					return $author$project$Pages$Reporting$NoOp_AskingPin;
				},
				$elm$browser$Browser$Dom$focus('reporting-pin-input'))));
}();
var $author$project$Pages$Reporting$NoAccessForUser = {$: 2};
var $author$project$Pages$Reporting$init_NoAccessForUser = _Utils_Tuple2(
	{_: $author$project$Pages$Reporting$NoAccessForUser},
	$elm$core$Platform$Cmd$none);
var $author$project$Pages$Reporting$CompanyWideReportsTab = {$: 0};
var $author$project$Pages$Reporting$GotReportLinks = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Reporting$GotSites = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$Reporting$PinOk = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Reporting$SiteReportsTab = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Types$User$getBasicInfo = function (user) {
	switch (user.$) {
		case 0:
			var info = user.a;
			return info;
		case 1:
			var info = user.a;
			return info;
		case 2:
			var info = user.a;
			return info;
		default:
			var siteUser = user.a;
			switch (siteUser.$) {
				case 2:
					var info = siteUser.a;
					return info;
				case 0:
					var info = siteUser.a;
					return info;
				default:
					var info = siteUser.a;
					return info;
			}
	}
};
var $author$project$Types$User$id = function (user) {
	return $author$project$Types$User$getBasicInfo(user).er;
};
var $author$project$Pages$Reporting$ReportLink = F4(
	function (reportType, reportPeriod, filename, downloadUrl) {
		return {hj: downloadUrl, ed: filename, iJ: reportPeriod, cp: reportType};
	});
var $author$project$Pages$Reporting$ReportPeriod = F3(
	function (year, month, week) {
		return {mw: month, ao: week, fi: year};
	});
var $author$project$Pages$Reporting$reportPeriodDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'week',
	A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$int),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'month',
		A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$int),
		$elm$core$Maybe$Nothing,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'year',
			$elm$json$Json$Decode$int,
			$elm$json$Json$Decode$succeed($author$project$Pages$Reporting$ReportPeriod))));
var $author$project$Pages$Reporting$MonthlySalesReport = 1;
var $author$project$Pages$Reporting$PivotSourceReport = 2;
var $author$project$Pages$Reporting$SiteReport = 3;
var $author$project$Pages$Reporting$WeeklySalesReport = 0;
var $author$project$Pages$Reporting$reportTypeDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (reportTypeString) {
		switch (reportTypeString) {
			case 'WEEKLY_SALES_REPORT':
				return $elm$json$Json$Decode$succeed(0);
			case 'MONTHLY_SALES_REPORT':
				return $elm$json$Json$Decode$succeed(1);
			case 'PIVOT_SOURCE_REPORT':
				return $elm$json$Json$Decode$succeed(2);
			case 'SITE_REPORT':
				return $elm$json$Json$Decode$succeed(3);
			default:
				return $elm$json$Json$Decode$fail('Unknown report type \'' + (reportTypeString + '\''));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Pages$Reporting$reportLinkDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'downloadUrl',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'filename',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'reportPeriod',
			$author$project$Pages$Reporting$reportPeriodDecoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'reportType',
				$author$project$Pages$Reporting$reportTypeDecoder,
				$elm$json$Json$Decode$succeed($author$project$Pages$Reporting$ReportLink)))));
var $author$project$Pages$Reporting$fetchReportLinks = F2(
	function (_v0, selectedUser) {
		var apiUrl = _v0.j0;
		var token = _v0.oK;
		return $author$project$JwtHttp$task(
			{
				kc: $elm$http$Http$emptyBody,
				mr: 'GET',
				nJ: $elm$http$Http$stringResolver(
					$author$project$JwtHttp$handleJsonResponse(
						$elm$json$Json$Decode$list($author$project$Pages$Reporting$reportLinkDecoder))),
				oH: $elm$core$Maybe$Nothing,
				oK: token,
				oU: apiUrl + ('/reports/report-links/for-user/' + $elm$core$String$fromInt(
					$author$project$Types$User$id(selectedUser)))
			});
	});
var $author$project$Types$Site$sitesUrl = function (apiUrl) {
	return apiUrl + '/sites/';
};
var $author$project$Types$Site$fetchAll = function (_v0) {
	var apiUrl = _v0.j0;
	var token = _v0.oK;
	return $author$project$JwtHttp$task(
		{
			kc: $elm$http$Http$emptyBody,
			mr: 'GET',
			nJ: $elm$http$Http$stringResolver(
				$author$project$JwtHttp$handleJsonResponse(
					$elm$json$Json$Decode$list($author$project$Types$Site$siteDecoder))),
			oH: $elm$core$Maybe$Nothing,
			oK: token,
			oU: $author$project$Types$Site$sitesUrl(apiUrl)
		});
};
var $author$project$Pages$Reporting$fetchSites = function (serverContext) {
	return $author$project$Types$Site$fetchAll(serverContext);
};
var $author$project$Pages$Reporting$GotHtmlTable = F3(
	function (a, b, c) {
		return {$: 2, a: a, b: b, c: c};
	});
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$http$Http$resolve = F2(
	function (toResult, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 3:
				var metadata = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.od));
			default:
				var body = response.b;
				return A2(
					$elm$core$Result$mapError,
					$elm$http$Http$BadBody,
					toResult(body));
		}
	});
var $elm$http$Http$expectString = function (toMsg) {
	return A2(
		$elm$http$Http$expectStringResponse,
		toMsg,
		$elm$http$Http$resolve($elm$core$Result$Ok));
};
var $author$project$JwtHttp$Get = 0;
var $elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {iK: reqs, jb: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.oO;
							if (_v4.$ === 1) {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.iK));
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.jb)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					jW: r.jW,
					kc: r.kc,
					lq: A2(_Http_mapExpect, func, r.lq),
					hF: r.hF,
					mr: r.mr,
					oH: r.oH,
					oO: r.oO,
					oU: r.oU
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{jW: false, kc: r.kc, lq: r.lq, hF: r.hF, mr: r.mr, oH: r.oH, oO: r.oO, oU: r.oU}));
};
var $author$project$JwtHttp$request = F5(
	function (token, method, url, body, expect) {
		var methodString = function () {
			switch (method) {
				case 0:
					return 'GET';
				case 1:
					return 'POST';
				case 2:
					return 'PUT';
				default:
					return 'DELETE';
			}
		}();
		return $elm$http$Http$request(
			{
				kc: body,
				lq: expect,
				hF: _List_fromArray(
					[
						A2($elm$http$Http$header, 'Authorization', 'Bearer ' + token)
					]),
				mr: methodString,
				oH: $elm$core$Maybe$Nothing,
				oO: $elm$core$Maybe$Nothing,
				oU: url
			});
	});
var $author$project$JwtHttp$get = F3(
	function (token, url, expect) {
		return A5($author$project$JwtHttp$request, token, 0, url, $elm$http$Http$emptyBody, expect);
	});
var $author$project$Types$Site$getSiteData = function (site) {
	if (!site.$) {
		var siteData = site.a;
		return siteData;
	} else {
		var siteData = site.a;
		return siteData;
	}
};
var $author$project$Types$Site$id = function (site) {
	return $author$project$Types$Site$getSiteData(site).er;
};
var $author$project$Types$Year$toString = function (year) {
	return $elm$core$String$fromInt(year);
};
var $author$project$Types$HalfYear$toString = function (halfYear) {
	return _Utils_ap(
		$author$project$Types$Year$toString(halfYear.fi),
		function () {
			var _v0 = halfYear.lE;
			if (!_v0) {
				return '/1';
			} else {
				return '/2';
			}
		}());
};
var $author$project$Pages$Reporting$fetchWeeklySiteReportHtmlTable = F3(
	function (_v0, site, halfYear) {
		var apiUrl = _v0.j0;
		var token = _v0.oK;
		return A3(
			$author$project$JwtHttp$get,
			token,
			apiUrl + ('/reports/site/' + ($elm$core$String$fromInt(
				$author$project$Types$Site$id(site)) + ('/sales-html/' + $author$project$Types$HalfYear$toString(halfYear)))),
			$elm$http$Http$expectString(
				A2($author$project$Pages$Reporting$GotHtmlTable, site, halfYear)));
	});
var $author$project$Types$HalfYear$FirstHalf = 0;
var $author$project$Types$HalfYear$SecondHalf = 1;
var $author$project$Types$HalfYear$halfForWeek = function (weekNumber) {
	return (weekNumber <= 26) ? 0 : 1;
};
var $elm$time$Time$Fri = 4;
var $elm$time$Time$Mon = 0;
var $elm$time$Time$Sat = 5;
var $elm$time$Time$Thu = 3;
var $elm$time$Time$Tue = 1;
var $elm$time$Time$Wed = 2;
var $justinmimbs$date$Date$numberToWeekday = function (wdn) {
	var _v0 = A2($elm$core$Basics$max, 1, wdn);
	switch (_v0) {
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		default:
			return 6;
	}
};
var $justinmimbs$date$Date$divWithRemainder = F2(
	function (a, b) {
		return _Utils_Tuple2(
			A2($justinmimbs$date$Date$floorDiv, a, b),
			A2($elm$core$Basics$modBy, b, a));
	});
var $justinmimbs$date$Date$year = function (_v0) {
	var rd = _v0;
	var _v1 = A2($justinmimbs$date$Date$divWithRemainder, rd, 146097);
	var n400 = _v1.a;
	var r400 = _v1.b;
	var _v2 = A2($justinmimbs$date$Date$divWithRemainder, r400, 36524);
	var n100 = _v2.a;
	var r100 = _v2.b;
	var _v3 = A2($justinmimbs$date$Date$divWithRemainder, r100, 1461);
	var n4 = _v3.a;
	var r4 = _v3.b;
	var _v4 = A2($justinmimbs$date$Date$divWithRemainder, r4, 365);
	var n1 = _v4.a;
	var r1 = _v4.b;
	var n = (!r1) ? 0 : 1;
	return ((((n400 * 400) + (n100 * 100)) + (n4 * 4)) + n1) + n;
};
var $justinmimbs$date$Date$toWeekDate = function (_v0) {
	var rd = _v0;
	var wdn = $justinmimbs$date$Date$weekdayNumber(rd);
	var wy = $justinmimbs$date$Date$year(rd + (4 - wdn));
	var week1Day1 = $justinmimbs$date$Date$daysBeforeWeekYear(wy) + 1;
	return {
		o$: 1 + (((rd - week1Day1) / 7) | 0),
		jv: wy,
		o0: $justinmimbs$date$Date$numberToWeekday(wdn)
	};
};
var $justinmimbs$date$Date$weekNumber = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toWeekDate,
	function ($) {
		return $.o$;
	});
var $justinmimbs$date$Date$weekYear = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toWeekDate,
	function ($) {
		return $.jv;
	});
var $author$project$Types$HalfYear$forDate = function (date) {
	return {
		lE: $author$project$Types$HalfYear$halfForWeek(
			$justinmimbs$date$Date$weekNumber(date)),
		fi: $justinmimbs$date$Date$weekYear(date)
	};
};
var $author$project$Types$User$site = function (siteUser) {
	switch (siteUser.$) {
		case 2:
			var siteUserInfo = siteUser.b;
			return siteUserInfo.eZ;
		case 0:
			var siteUserInfo = siteUser.b;
			return siteUserInfo.eZ;
		default:
			var siteUserInfo = siteUser.b;
			return siteUserInfo.eZ;
	}
};
var $author$project$Types$User$toSiteUser = function (user) {
	if (user.$ === 3) {
		var siteUser = user.a;
		return $elm$core$Maybe$Just(siteUser);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Pages$Reporting$init_PinOk = F2(
	function (_v0, selectedUser) {
		var serverContext = _v0.n1;
		var today = _v0.dE;
		var _v1 = function (maybeSiteUser) {
			if (!maybeSiteUser.$) {
				var siteUser = maybeSiteUser.a;
				var site = $author$project$Types$User$site(siteUser);
				return _Utils_Tuple3(
					A2($author$project$Pages$Reporting$SiteReportsTab, site, _List_Nil),
					A3(
						$author$project$Pages$Reporting$fetchWeeklySiteReportHtmlTable,
						serverContext,
						site,
						$author$project$Types$HalfYear$forDate(today)),
					$elm$core$Platform$Cmd$none);
			} else {
				return _Utils_Tuple3(
					$author$project$Pages$Reporting$CompanyWideReportsTab,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$Task$attempt,
						$author$project$Pages$Reporting$GotSites,
						$author$project$Pages$Reporting$fetchSites(serverContext)));
			}
		}(
			$author$project$Types$User$toSiteUser(selectedUser));
		var selectedTab = _v1.a;
		var fetchFirstSiteReportHtmlTableCmd = _v1.b;
		var fetchSitesCmd = _v1.c;
		var initialModel = {
			dR: _List_Nil,
			fG: $elm$core$Maybe$Nothing,
			af: !_Utils_eq(fetchFirstSiteReportHtmlTableCmd, $elm$core$Platform$Cmd$none),
			eA: _List_Nil,
			h9: $author$project$Components$PinDialog$pinValidForMs,
			eO: _List_Nil,
			gg: selectedTab,
			gk: _List_Nil,
			fe: _List_Nil,
			cJ: _List_Nil
		};
		return _Utils_Tuple2(
			{
				_: $author$project$Pages$Reporting$PinOk(initialModel)
			},
			A2(
				$elm$core$Platform$Cmd$map,
				$author$project$Pages$Reporting$Msg_PinOk,
				$elm$core$Platform$Cmd$batch(
					_List_fromArray(
						[
							A2(
							$elm$core$Task$attempt,
							$author$project$Pages$Reporting$GotReportLinks,
							A2($author$project$Pages$Reporting$fetchReportLinks, serverContext, selectedUser)),
							fetchFirstSiteReportHtmlTableCmd,
							fetchSitesCmd
						]))));
	});
var $author$project$Types$User$AccessWithPin = 0;
var $author$project$Types$User$AccessWithoutPin = 1;
var $author$project$Types$User$NoAccess = 2;
var $author$project$Types$User$reportingPageAccess = function (user) {
	switch (user.$) {
		case 0:
			return 1;
		case 2:
			return 1;
		case 1:
			return 1;
		default:
			switch (user.a.$) {
				case 2:
					var _v1 = user.a;
					return 1;
				case 0:
					var _v2 = user.a;
					return 0;
				default:
					var _v3 = user.a;
					return 2;
			}
	}
};
var $author$project$Pages$Reporting$init = F2(
	function (appContext, selectedUser) {
		var _v0 = $author$project$Types$User$reportingPageAccess(selectedUser);
		switch (_v0) {
			case 0:
				return $author$project$Pages$Reporting$init_AskingPin;
			case 1:
				return A2($author$project$Pages$Reporting$init_PinOk, appContext, selectedUser);
			default:
				return $author$project$Pages$Reporting$init_NoAccessForUser;
		}
	});
var $author$project$Pages$Tasklist$GotData = $elm$core$Basics$identity;
var $author$project$Pages$Tasklist$Loading = function (a) {
	return {$: 0, a: a};
};
var $author$project$Types$Vehicle$Vehicle$VehicleMake = F3(
	function (id, name, abbreviation) {
		return {jC: abbreviation, er: id, mD: name};
	});
var $author$project$Decode$vehicleMakeDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'abbreviation',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'name',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'id',
			$elm$json$Json$Decode$int,
			$elm$json$Json$Decode$succeed($author$project$Types$Vehicle$Vehicle$VehicleMake))));
var $author$project$Data$VehicleMakes$fetch = function (_v0) {
	var apiUrl = _v0.j0;
	var token = _v0.oK;
	return $author$project$JwtHttp$task(
		{
			kc: $elm$http$Http$emptyBody,
			mr: 'GET',
			nJ: $elm$http$Http$stringResolver(
				$author$project$JwtHttp$handleJsonResponse(
					$elm$json$Json$Decode$list($author$project$Decode$vehicleMakeDecoder))),
			oH: $elm$core$Maybe$Nothing,
			oK: token,
			oU: apiUrl + '/vehicles/makes'
		});
};
var $author$project$Types$Customer$customerTypeDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (customerTypeString) {
		switch (customerTypeString) {
			case 'REGULAR_CUSTOMER':
				return $elm$json$Json$Decode$succeed(0);
			case 'ONE_TIME_CUSTOMER':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail(customerTypeString);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Types$Customer$customerDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'type',
	$author$project$Types$Customer$customerTypeDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'name',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'id',
			$elm$json$Json$Decode$int,
			$elm$json$Json$Decode$succeed($author$project$Types$Customer$Customer))));
var $author$project$Types$Site$fetchCustomers = F2(
	function (_v0, site) {
		var apiUrl = _v0.j0;
		var token = _v0.oK;
		return $author$project$JwtHttp$task(
			{
				kc: $elm$http$Http$emptyBody,
				mr: 'GET',
				nJ: $elm$http$Http$stringResolver(
					$author$project$JwtHttp$handleJsonResponse(
						$elm$json$Json$Decode$list($author$project$Types$Customer$customerDecoder))),
				oH: $elm$core$Maybe$Nothing,
				oK: token,
				oU: apiUrl + ('/sites/' + ($elm$core$String$fromInt(
					$author$project$Types$Site$id(site)) + '/customers'))
			});
	});
var $author$project$Data$Products$fetchForUser = F2(
	function (userId, _v0) {
		var apiUrl = _v0.j0;
		var token = _v0.oK;
		return $author$project$JwtHttp$task(
			{
				kc: $elm$http$Http$emptyBody,
				mr: 'GET',
				nJ: $elm$http$Http$stringResolver(
					$author$project$JwtHttp$handleJsonResponse(
						$elm$json$Json$Decode$list($author$project$Data$Products$availableProductDecoder))),
				oH: $elm$core$Maybe$Nothing,
				oK: token,
				oU: apiUrl + ('/users/' + ($elm$core$String$fromInt(userId) + '/products'))
			});
	});
var $author$project$Types$User$toUser = $author$project$Types$User$SiteUser;
var $author$project$Data$Tasklists$fetchOpenForSite = F2(
	function (_v0, siteUser) {
		var apiUrl = _v0.j0;
		var token = _v0.oK;
		return $author$project$JwtHttp$task(
			{
				kc: $elm$http$Http$emptyBody,
				mr: 'GET',
				nJ: $elm$http$Http$stringResolver(
					$author$project$JwtHttp$handleJsonResponse(
						$elm$json$Json$Decode$list($author$project$Data$Tasklists$tasklistDecoder))),
				oH: $elm$core$Maybe$Nothing,
				oK: token,
				oU: apiUrl + ('/users/' + ($elm$core$String$fromInt(
					$author$project$Types$User$id(
						$author$project$Types$User$toUser(siteUser))) + '/tasklists/open'))
			});
	});
var $author$project$Types$Site$fetchWorkers = F2(
	function (_v0, site) {
		var apiUrl = _v0.j0;
		var token = _v0.oK;
		return $author$project$JwtHttp$task(
			{
				kc: $elm$http$Http$emptyBody,
				mr: 'GET',
				nJ: $elm$http$Http$stringResolver(
					$author$project$JwtHttp$handleJsonResponse(
						$elm$json$Json$Decode$list($author$project$Types$Worker$jsonDecoder))),
				oH: $elm$core$Maybe$Nothing,
				oK: token,
				oU: apiUrl + ('/sites/' + ($elm$core$String$fromInt(
					$author$project$Types$Site$id(site)) + '/workers'))
			});
	});
var $author$project$Pages$Tasklist$fetchData = F2(
	function (serverContext, siteUser) {
		var fetchSiteCustomersAndWorkers = A3(
			$elm$core$Task$map2,
			F2(
				function (siteCustomers, siteWorkers) {
					return {X: siteCustomers, as: siteWorkers};
				}),
			A2(
				$author$project$Types$Site$fetchCustomers,
				serverContext,
				$author$project$Types$User$site(siteUser)),
			A2(
				$author$project$Types$Site$fetchWorkers,
				serverContext,
				$author$project$Types$User$site(siteUser)));
		var fetchProducts = A3(
			$elm$core$Task$map2,
			F2(
				function (allAvailableProducts, userAvailableProducts) {
					return {T: allAvailableProducts, aT: userAvailableProducts};
				}),
			$author$project$Data$Products$fetchAll(serverContext),
			A2(
				$author$project$Data$Products$fetchForUser,
				$author$project$Types$User$id(
					$author$project$Types$User$toUser(siteUser)),
				serverContext));
		var fetchOtherEntites = A4(
			$elm$core$Task$map3,
			F3(
				function (allWorkers, tasklists, vehicleMakes) {
					return {bU: allWorkers, e: tasklists, am: vehicleMakes};
				}),
			$author$project$Types$Worker$fetchAll(serverContext),
			A2($author$project$Data$Tasklists$fetchOpenForSite, serverContext, siteUser),
			$author$project$Data$VehicleMakes$fetch(serverContext));
		return A4(
			$elm$core$Task$map3,
			F3(
				function (_v0, _v1, _v2) {
					var allAvailableProducts = _v0.T;
					var userAvailableProducts = _v0.aT;
					var siteCustomers = _v1.X;
					var siteWorkers = _v1.as;
					var allWorkers = _v2.bU;
					var tasklists = _v2.e;
					var vehicleMakes = _v2.am;
					return {T: allAvailableProducts, bU: allWorkers, X: siteCustomers, as: siteWorkers, e: tasklists, aT: userAvailableProducts, am: vehicleMakes};
				}),
			fetchProducts,
			fetchSiteCustomersAndWorkers,
			fetchOtherEntites);
	});
var $author$project$Pages$Tasklist$init_Loading = F2(
	function (serverContext, user) {
		return _Utils_Tuple2(
			{
				_: $author$project$Pages$Tasklist$Loading(
					{gA: user})
			},
			A2(
				$elm$core$Platform$Cmd$map,
				$author$project$Pages$Tasklist$Msg_Loading,
				A2(
					$elm$core$Task$attempt,
					$elm$core$Basics$identity,
					A2($author$project$Pages$Tasklist$fetchData, serverContext, user))));
	});
var $author$project$Pages$Tasklist$init = F2(
	function (serverContext, user) {
		return A2($author$project$Pages$Tasklist$init_Loading, serverContext, user);
	});
var $author$project$Types$User$isOfficeUser = function (user) {
	if (user.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Types$Page$HolidaysPageLink = 0;
var $author$project$Types$Page$InvoicingPageLink = 1;
var $author$project$Types$Page$ReportingPageLink = 2;
var $author$project$Types$Page$TasklistPageLink = 3;
var $author$project$Types$Page$WorkTimeTrackingPageLink = 4;
var $author$project$Types$Page$pageToPageLink = function (page) {
	switch (page.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		case 3:
			return 3;
		default:
			return 4;
	}
};
var $elm$json$Json$Encode$string = _Json_wrap;
var $author$project$Main$storeToken = _Platform_outgoingPort('storeToken', $elm$json$Json$Encode$string);
var $author$project$Main$init_LoggedIn = F8(
	function (model, loggedInUser, token, apiUrl, loginUrl, today, dataRefetchIntervalMs, globalPin) {
		var appContext = $author$project$Types$AppContext$create(
			{j0: apiUrl, fx: dataRefetchIntervalMs, lA: globalPin, h4: loggedInUser, ag: loginUrl, dE: today, oK: token, gy: false});
		var _v0 = function () {
			var initTasklistPage = function (siteUser) {
				var _v4 = A2($author$project$Pages$Tasklist$init, appContext.n1, siteUser);
				var pageModel = _v4.a;
				var pageCmd = _v4.b;
				return _Utils_Tuple2(
					$author$project$Types$Page$TasklistPage(pageModel),
					A2($elm$core$Platform$Cmd$map, $author$project$Main$GotTasklistPageMsg, pageCmd));
			};
			var initReportingPage = function (currentUser) {
				var _v3 = A2($author$project$Pages$Reporting$init, appContext, currentUser);
				var pageModel = _v3.a;
				var pageCmd = _v3.b;
				return _Utils_Tuple2(
					$author$project$Types$Page$ReportingPage(pageModel),
					A2($elm$core$Platform$Cmd$map, $author$project$Main$GotReportingPageMsg, pageCmd));
			};
			var initInvoicingPage = function () {
				var _v2 = $author$project$Pages$Invoicing$init(appContext.n1);
				var pageModel = _v2.a;
				var pageCmd = _v2.b;
				return _Utils_Tuple2(
					$author$project$Types$Page$InvoicingPage(pageModel),
					A2($elm$core$Platform$Cmd$map, $author$project$Main$GotInvoicingPageMsg, pageCmd));
			}();
			var _v1 = $author$project$Types$User$toSiteUser(loggedInUser);
			if (!_v1.$) {
				var siteUser = _v1.a;
				return initTasklistPage(siteUser);
			} else {
				return $author$project$Types$User$isOfficeUser(loggedInUser) ? initInvoicingPage : initReportingPage(loggedInUser);
			}
		}();
		var defaultPage = _v0.a;
		var defaultPageInitCmd = _v0.b;
		var loggedInModel = {
			q: appContext,
			A: defaultPage,
			eM: 'AutoDent | ' + $author$project$Types$Page$getTitle(
				$author$project$Types$Page$pageToPageLink(defaultPage)),
			aS: loggedInUser,
			dG: $author$project$Components$TopMenu$init,
			bO: $elm$core$Maybe$Nothing
		};
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					P: $author$project$Components$Notification$Inactive,
					_: $author$project$Main$LoggedIn(loggedInModel)
				}),
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Platform$Cmd$map, $author$project$Main$Msg_LoggedIn, defaultPageInitCmd),
						$author$project$Main$storeToken(token)
					])));
	});
var $author$project$Pages$Login$OutLoggedIn = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Pages$Login$OutNoOp = {$: 0};
var $author$project$Pages$Login$ShowCredentialsInputs = 1;
var $author$project$Pages$Login$clearLoginFields = function (model) {
	return _Utils_update(
		model,
		{dd: $elm$core$Maybe$Nothing, cl: '', cG: ''});
};
var $author$project$Pages$Login$GotLoginResponse = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Login$LoginResponse = F2(
	function (user, token) {
		return {oK: token, gA: user};
	});
var $elm$http$Http$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			$elm$http$Http$resolve(
				function (string) {
					return A2(
						$elm$core$Result$mapError,
						$elm$json$Json$Decode$errorToString,
						A2($elm$json$Json$Decode$decodeString, decoder, string));
				}));
	});
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $elm$http$Http$post = function (r) {
	return $elm$http$Http$request(
		{kc: r.kc, lq: r.lq, hF: _List_Nil, mr: 'POST', oH: $elm$core$Maybe$Nothing, oO: $elm$core$Maybe$Nothing, oU: r.oU});
};
var $author$project$Pages$Login$loginCmd = F3(
	function (url, username, password) {
		var loginResponseDecoder = A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'token',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'user',
				$author$project$Types$User$userDecoder,
				$elm$json$Json$Decode$succeed($author$project$Pages$Login$LoginResponse)));
		var body = $elm$http$Http$jsonBody(
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'username',
						$elm$json$Json$Encode$string(username)),
						_Utils_Tuple2(
						'password',
						$elm$json$Json$Encode$string(password))
					])));
		return $elm$http$Http$post(
			{
				kc: body,
				lq: A2($elm$http$Http$expectJson, $author$project$Pages$Login$GotLoginResponse, loginResponseDecoder),
				oU: url
			});
	});
var $author$project$Pages$Login$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 1:
				if (!msg.a.$) {
					var user = msg.a.a.gA;
					var token = msg.a.a.oK;
					return _Utils_Tuple3(
						$author$project$Pages$Login$clearLoginFields(model),
						$elm$core$Platform$Cmd$none,
						A2($author$project$Pages$Login$OutLoggedIn, user, token));
				} else {
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{_: 1}),
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Login$OutNoOp);
				}
			case 2:
				if (!msg.a.$) {
					var user = msg.a.a.gA;
					var token = msg.a.a.oK;
					return _Utils_Tuple3(
						$author$project$Pages$Login$clearLoginFields(model),
						$elm$core$Platform$Cmd$none,
						A2($author$project$Pages$Login$OutLoggedIn, user, token));
				} else {
					var httpError = msg.a.a;
					var errorMessage = function () {
						if ((httpError.$ === 3) && (httpError.a === 401)) {
							return 'väärä käyttäjä tai salasana';
						} else {
							return 'palvelinvirhe';
						}
					}();
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								dd: $elm$core$Maybe$Just(errorMessage)
							}),
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Login$OutNoOp);
				}
			case 0:
				return _Utils_Tuple3(
					model,
					A3($author$project$Pages$Login$loginCmd, model.ag, model.cG, model.cl),
					$author$project$Pages$Login$OutNoOp);
			case 3:
				var str = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{cl: str}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Login$OutNoOp);
			case 4:
				var str = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{cG: str}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Login$OutNoOp);
			default:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$Login$OutNoOp);
		}
	});
var $author$project$Main$update_Authenticating = F3(
	function (model, authenticatingMsg, stateModel) {
		if (!authenticatingMsg.$) {
			var loginPageMsg = authenticatingMsg.a;
			var _v1 = A2($author$project$Pages$Login$update, loginPageMsg, stateModel.di);
			var updatedLoginPageModel = _v1.a;
			var loginPageCmd = _v1.b;
			var outMsg = _v1.c;
			var cmd = A2(
				$elm$core$Platform$Cmd$map,
				$author$project$Main$Msg_Authenticating,
				A2($elm$core$Platform$Cmd$map, $author$project$Main$GotLoginPageMsg, loginPageCmd));
			var updatedStateModel = _Utils_update(
				stateModel,
				{di: updatedLoginPageModel});
			if (outMsg.$ === 1) {
				var user = outMsg.a;
				var token = outMsg.b;
				return A8($author$project$Main$init_LoggedIn, model, user, token, stateModel.j0, stateModel.ag, stateModel.dE, stateModel.av, stateModel.lA);
			} else {
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							_: $author$project$Main$Authenticating(updatedStateModel)
						}),
					cmd);
			}
		} else {
			var user = authenticatingMsg.a;
			var token = authenticatingMsg.b;
			return A8($author$project$Main$init_LoggedIn, model, user, token, stateModel.j0, stateModel.ag, stateModel.dE, stateModel.av, stateModel.lA);
		}
	});
var $author$project$Pages$Login$FetchingUserByToken = 0;
var $author$project$Pages$Login$GotTokenToUserResponse = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Login$TokenToUserResponse = F2(
	function (user, token) {
		return {oK: token, gA: user};
	});
var $author$project$Pages$Login$fetchUserWithTokenCmd = F2(
	function (url, token) {
		var responseDecoder = A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'token',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'user',
				$author$project$Types$User$userDecoder,
				$elm$json$Json$Decode$succeed($author$project$Pages$Login$TokenToUserResponse)));
		var body = $elm$http$Http$jsonBody(
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'token',
						$elm$json$Json$Encode$string(token))
					])));
		return $elm$http$Http$post(
			{
				kc: body,
				lq: A2($elm$http$Http$expectJson, $author$project$Pages$Login$GotTokenToUserResponse, responseDecoder),
				oU: url
			});
	});
var $author$project$Pages$Login$init = F2(
	function (loginUrl, currentToken) {
		var _v0 = function () {
			if (!currentToken.$) {
				var token = currentToken.a;
				return _Utils_Tuple2(
					0,
					A2($author$project$Pages$Login$fetchUserWithTokenCmd, loginUrl, token));
			} else {
				return _Utils_Tuple2(1, $elm$core$Platform$Cmd$none);
			}
		}();
		var state = _v0.a;
		var initialCmd = _v0.b;
		var initialModel = {dd: $elm$core$Maybe$Nothing, ag: loginUrl, cl: '', _: state, cG: ''};
		return _Utils_Tuple2(initialModel, initialCmd);
	});
var $author$project$Main$init_Authenticating = F2(
	function (model, _v0) {
		var apiUrl = _v0.j0;
		var dataRefetchIntervalMs = _v0.av;
		var loginUrl = _v0.ag;
		var today = _v0.dE;
		var token = _v0.oK;
		var globalPin = _v0.lA;
		var _v1 = A2($author$project$Pages$Login$init, loginUrl, token);
		var loginPageModel = _v1.a;
		var loginPageCmd = _v1.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					_: $author$project$Main$Authenticating(
						{j0: apiUrl, av: dataRefetchIntervalMs, lA: globalPin, di: loginPageModel, ag: loginUrl, dE: today, oK: token})
				}),
			A2(
				$elm$core$Platform$Cmd$map,
				$author$project$Main$Msg_Authenticating,
				A2($elm$core$Platform$Cmd$map, $author$project$Main$GotLoginPageMsg, loginPageCmd)));
	});
var $author$project$Main$update_Initializing = F3(
	function (model, initializingMsg, stateModel) {
		var today = initializingMsg;
		var _v1 = stateModel.fK;
		var apiUrl = _v1.j0;
		var dataRefetchIntervalMs = _v1.av;
		var loginUrl = _v1.ag;
		var token = _v1.oK;
		var globalPin = _v1.lA;
		return A2(
			$author$project$Main$init_Authenticating,
			model,
			{j0: apiUrl, av: dataRefetchIntervalMs, lA: globalPin, ag: loginUrl, dE: today, oK: token});
	});
var $author$project$Main$GotTopMenuMsg = function (a) {
	return {$: 9, a: a};
};
var $author$project$Main$GotUserNavigatorMsg = function (a) {
	return {$: 11, a: a};
};
var $author$project$Types$Page$HolidaysPage = function (a) {
	return {$: 0, a: a};
};
var $author$project$Types$Page$WorkTimeTrackingPage = function (a) {
	return {$: 4, a: a};
};
var $author$project$Main$GotLoginStatus = function (a) {
	return {$: 3, a: a};
};
var $author$project$Main$checkLoginStatusCmd = function (_v0) {
	var apiUrl = _v0.j0;
	var token = _v0.oK;
	return A3(
		$author$project$JwtHttp$get,
		token,
		apiUrl + '/auth/login-status',
		A2($elm$http$Http$expectJson, $author$project$Main$GotLoginStatus, $elm$json$Json$Decode$bool));
};
var $author$project$Components$Notification$Bottom = 1;
var $author$project$Components$Notification$Notification = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Main$logoutUser = _Platform_outgoingPort(
	'logoutUser',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Components$Notification$start = function (notification) {
	return A2($author$project$Components$Notification$Active, notification, 5000);
};
var $author$project$Main$handleAuthError = F2(
	function (model, commands) {
		var notification = A2($author$project$Components$Notification$Notification, 'Kirjautuminen vanhentui', 1);
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					P: $author$project$Components$Notification$start(notification)
				}),
			$elm$core$Platform$Cmd$batch(
				A2(
					$elm$core$List$cons,
					$author$project$Main$logoutUser(0),
					commands)));
	});
var $author$project$Types$User$isAdminUser = function (user) {
	if (!user.$) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Types$User$isCompanyManagerUser = function (user) {
	if (user.$ === 2) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Main$getDefaultPageLink = function (user) {
	return ($author$project$Types$User$isAdminUser(user) || $author$project$Types$User$isCompanyManagerUser(user)) ? 2 : ($author$project$Types$User$isOfficeUser(user) ? 1 : 3);
};
var $author$project$Types$User$canEditVacations = function (user) {
	switch (user.$) {
		case 0:
			return true;
		case 1:
			return true;
		case 2:
			return true;
		default:
			var siteUser = user.a;
			switch (siteUser.$) {
				case 2:
					return true;
				case 0:
					return true;
				default:
					return false;
			}
	}
};
var $author$project$Pages$Holidays$State_Loading$GotData = $elm$core$Basics$identity;
var $author$project$Pages$Holidays$State_Loading$OutNoOp = {$: 3};
var $elm$json$Json$Encode$int = _Json_wrap;
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $author$project$Types$VacationBudget$VacationBudget = F4(
	function (dayCount, vacationType, workerId, year) {
		return {hf: dayCount, jr: vacationType, jy: workerId, fi: year};
	});
var $author$project$Types$VacationType$ExtraVacation = 0;
var $author$project$Types$VacationType$SummerVacation = 1;
var $author$project$Types$VacationType$WinterVacation = 2;
var $author$project$Types$VacationType$jsonDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (vacationTypeString) {
		switch (vacationTypeString) {
			case 'EXTRA':
				return $elm$json$Json$Decode$succeed(0);
			case 'SUMMER':
				return $elm$json$Json$Decode$succeed(1);
			case 'WINTER':
				return $elm$json$Json$Decode$succeed(2);
			default:
				return $elm$json$Json$Decode$fail(vacationTypeString);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Types$VacationBudget$vacationBudgetJsonDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'year',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'workerId',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'vacationType',
			$author$project$Types$VacationType$jsonDecoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'dayCount',
				$elm$json$Json$Decode$int,
				$elm$json$Json$Decode$succeed($author$project$Types$VacationBudget$VacationBudget)))));
var $author$project$Types$VacationBudget$fetchForWorkers = F2(
	function (_v0, workerIds) {
		var apiUrl = _v0.j0;
		var token = _v0.oK;
		return $author$project$JwtHttp$task(
			{
				kc: $elm$http$Http$jsonBody(
					$elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'workerIds',
								A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$int, workerIds))
							]))),
				mr: 'POST',
				nJ: $elm$http$Http$stringResolver(
					$author$project$JwtHttp$handleJsonResponse(
						$elm$json$Json$Decode$list($author$project$Types$VacationBudget$vacationBudgetJsonDecoder))),
				oH: $elm$core$Maybe$Nothing,
				oK: token,
				oU: apiUrl + '/vacations/budgets/get'
			});
	});
var $author$project$Types$VacationDay$VacationDay = F4(
	function (workerId, date, hasConfirmation, hasRequest) {
		return {b4: date, ek: hasConfirmation, el: hasRequest, jy: workerId};
	});
var $author$project$Types$VacationDay$jsonDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'hasRequest',
	$elm$json$Json$Decode$bool,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'hasConfirmation',
		$elm$json$Json$Decode$bool,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'date',
			$author$project$Types$Date$jsonDecoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'workerId',
				$elm$json$Json$Decode$int,
				$elm$json$Json$Decode$succeed($author$project$Types$VacationDay$VacationDay)))));
var $author$project$Types$VacationDay$fetchForWorkers = F2(
	function (_v0, workerIds) {
		var apiUrl = _v0.j0;
		var token = _v0.oK;
		return $author$project$JwtHttp$task(
			{
				kc: $elm$http$Http$jsonBody(
					$elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'workerIds',
								A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$int, workerIds))
							]))),
				mr: 'POST',
				nJ: $elm$http$Http$stringResolver(
					$author$project$JwtHttp$handleJsonResponse(
						$elm$json$Json$Decode$list($author$project$Types$VacationDay$jsonDecoder))),
				oH: $elm$core$Maybe$Nothing,
				oK: token,
				oU: apiUrl + '/vacations/days/get'
			});
	});
var $author$project$Types$Week$maxWeekNumber = function (year) {
	return A2(
		$elm$core$Basics$max,
		52,
		$justinmimbs$date$Date$weekNumber(
			A3($justinmimbs$date$Date$fromCalendarDate, year, 11, 31)));
};
var $author$project$Types$Week$forYear = function (year) {
	return A2(
		$elm$core$List$map,
		function (weekNumber) {
			return {o$: weekNumber, fi: year};
		},
		A2(
			$elm$core$List$range,
			1,
			$author$project$Types$Week$maxWeekNumber(year)));
};
var $author$project$Types$Week$forDate = function (date) {
	return {
		o$: $justinmimbs$date$Date$weekNumber(date),
		fi: $justinmimbs$date$Date$weekYear(date)
	};
};
var $author$project$Types$Week$hasDate = F2(
	function (date, week) {
		return _Utils_eq(
			week,
			$author$project$Types$Week$forDate(date));
	});
var $elm$core$List$sort = function (xs) {
	return A2($elm$core$List$sortBy, $elm$core$Basics$identity, xs);
};
var $elm$core$String$cons = _String_cons;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $elm$core$String$padLeft = F3(
	function (n, _char, string) {
		return _Utils_ap(
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)),
			string);
	});
var $author$project$Types$Week$toString = function (_v0) {
	var year = _v0.fi;
	var weekNumber = _v0.o$;
	return $elm$core$String$fromInt(year) + ('/' + A3(
		$elm$core$String$padLeft,
		2,
		'0',
		$elm$core$String$fromInt(weekNumber)));
};
var $elm$core$Set$Set_elm_builtin = $elm$core$Basics$identity;
var $elm$core$Set$empty = $elm$core$Dict$empty;
var $elm$core$Set$insert = F2(
	function (key, _v0) {
		var dict = _v0;
		return A3($elm$core$Dict$insert, key, 0, dict);
	});
var $elm$core$Dict$member = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$get, key, dict);
		if (!_v0.$) {
			return true;
		} else {
			return false;
		}
	});
var $elm$core$Set$member = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$member, key, dict);
	});
var $elm_community$list_extra$List$Extra$uniqueHelp = F4(
	function (f, existing, remaining, accumulator) {
		uniqueHelp:
		while (true) {
			if (!remaining.b) {
				return $elm$core$List$reverse(accumulator);
			} else {
				var first = remaining.a;
				var rest = remaining.b;
				var computedFirst = f(first);
				if (A2($elm$core$Set$member, computedFirst, existing)) {
					var $temp$f = f,
						$temp$existing = existing,
						$temp$remaining = rest,
						$temp$accumulator = accumulator;
					f = $temp$f;
					existing = $temp$existing;
					remaining = $temp$remaining;
					accumulator = $temp$accumulator;
					continue uniqueHelp;
				} else {
					var $temp$f = f,
						$temp$existing = A2($elm$core$Set$insert, computedFirst, existing),
						$temp$remaining = rest,
						$temp$accumulator = A2($elm$core$List$cons, first, accumulator);
					f = $temp$f;
					existing = $temp$existing;
					remaining = $temp$remaining;
					accumulator = $temp$accumulator;
					continue uniqueHelp;
				}
			}
		}
	});
var $elm_community$list_extra$List$Extra$unique = function (list) {
	return A4($elm_community$list_extra$List$Extra$uniqueHelp, $elm$core$Basics$identity, $elm$core$Set$empty, list, _List_Nil);
};
var $justinmimbs$date$Date$weekdayToNumber = function (wd) {
	switch (wd) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		default:
			return 7;
	}
};
var $justinmimbs$date$Date$fromWeekDate = F3(
	function (wy, wn, wd) {
		var weeksInWY = $justinmimbs$date$Date$is53WeekYear(wy) ? 53 : 52;
		return ($justinmimbs$date$Date$daysBeforeWeekYear(wy) + ((A3($elm$core$Basics$clamp, 1, weeksInWY, wn) - 1) * 7)) + $justinmimbs$date$Date$weekdayToNumber(wd);
	});
var $author$project$Types$Week$dateAtWeekday = F2(
	function (_v0, weekday) {
		var year = _v0.fi;
		var weekNumber = _v0.o$;
		return A3($justinmimbs$date$Date$fromWeekDate, year, weekNumber, weekday);
	});
var $author$project$Types$VacationDay$workingDayForWeekday = F3(
	function (workerId, week, weekday) {
		return {
			b4: A2($author$project$Types$Week$dateAtWeekday, week, weekday),
			ek: false,
			el: false,
			jy: workerId
		};
	});
var $author$project$Types$WorkerVacationWeek$fetchForWorkers = F2(
	function (serverContext, workerIds) {
		return A2(
			$elm$core$Task$map,
			function (vacationDays) {
				var vacationDaysByWorker = $elm$core$Dict$fromList(
					A2(
						$elm$core$List$map,
						function (workerId) {
							return function (workerVacationDays) {
								return _Utils_Tuple2(workerId, workerVacationDays);
							}(
								A2(
									$elm$core$List$filter,
									A2(
										$elm$core$Basics$composeR,
										function ($) {
											return $.jy;
										},
										$elm$core$Basics$eq(workerId)),
									vacationDays));
						},
						$elm_community$list_extra$List$Extra$unique(
							A2(
								$elm$core$List$map,
								function ($) {
									return $.jy;
								},
								vacationDays))));
				var allWeeksForAllYears = A2(
					$elm$core$List$sortBy,
					$author$project$Types$Week$toString,
					$elm$core$List$concat(
						A2(
							$elm$core$List$map,
							$author$project$Types$Week$forYear,
							$elm$core$List$sort(
								$elm_community$list_extra$List$Extra$unique(
									A2(
										$elm$core$List$map,
										A2(
											$elm$core$Basics$composeR,
											function ($) {
												return $.b4;
											},
											$justinmimbs$date$Date$year),
										vacationDays))))));
				return $elm$core$List$concat(
					A2(
						$elm$core$List$map,
						function (workerId) {
							return A2(
								$elm$core$List$map,
								function (week) {
									var vacationDaysForWeek = $elm$core$Dict$fromList(
										A2(
											$elm$core$List$map,
											function (vacationDay) {
												return _Utils_Tuple2(
													$justinmimbs$date$Date$weekdayNumber(vacationDay.b4),
													vacationDay);
											},
											A2(
												$elm$core$List$filter,
												function (_v0) {
													var date = _v0.b4;
													return A2($author$project$Types$Week$hasDate, date, week);
												},
												A2(
													$elm$core$Maybe$withDefault,
													_List_Nil,
													A2($elm$core$Dict$get, workerId, vacationDaysByWorker)))));
									var getVacationDay = F2(
										function (weekday, dict) {
											return A2(
												$elm$core$Maybe$withDefault,
												A3($author$project$Types$VacationDay$workingDayForWeekday, workerId, week, weekday),
												A2(
													$elm$core$Dict$get,
													$justinmimbs$date$Date$weekdayToNumber(weekday),
													dict));
										});
									return {
										hw: A2(getVacationDay, 4, vacationDaysForWeek),
										h7: A2(getVacationDay, 0, vacationDaysForWeek),
										iS: A2(getVacationDay, 5, vacationDaysForWeek),
										ji: A2(getVacationDay, 3, vacationDaysForWeek),
										jq: A2(getVacationDay, 1, vacationDaysForWeek),
										ju: A2(getVacationDay, 2, vacationDaysForWeek),
										ao: week,
										jy: workerId
									};
								},
								allWeeksForAllYears);
						},
						workerIds));
			},
			A2($author$project$Types$VacationDay$fetchForWorkers, serverContext, workerIds));
	});
var $author$project$Pages$Holidays$State_Loading$fetchData = F4(
	function (serverContext, site, canEditVacations, selectedYear) {
		return A2(
			$elm$core$Task$andThen,
			function (siteWorkers) {
				var workerIds = A2(
					$elm$core$List$map,
					function ($) {
						return $.er;
					},
					siteWorkers);
				return A3(
					$elm$core$Task$map2,
					F2(
						function (vacationBudgets, workerVacationWeeks) {
							return {jV: workerVacationWeeks, kn: canEditVacations, bG: selectedYear, oW: vacationBudgets, jA: siteWorkers};
						}),
					A2($author$project$Types$VacationBudget$fetchForWorkers, serverContext, workerIds),
					A2($author$project$Types$WorkerVacationWeek$fetchForWorkers, serverContext, workerIds));
			},
			A2($author$project$Types$Site$fetchWorkers, serverContext, site));
	});
var $author$project$Pages$Holidays$State_Loading$init = F4(
	function (serverContext, selectedYear, site, canEditVacations) {
		var initialModel = {};
		return _Utils_Tuple3(
			initialModel,
			A2(
				$elm$core$Task$attempt,
				$elm$core$Basics$identity,
				A4($author$project$Pages$Holidays$State_Loading$fetchData, serverContext, site, canEditVacations, selectedYear)),
			$author$project$Pages$Holidays$State_Loading$OutNoOp);
	});
var $author$project$Pages$State_NoAccess$OutNoOp = 0;
var $author$project$Pages$State_NoAccess$init = _Utils_Tuple3(
	{},
	$elm$core$Platform$Cmd$none,
	0);
var $author$project$Pages$Holidays$Loading = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Holidays$Msg_Loading = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Holidays$OutGotAuthError = {$: 1};
var $author$project$Pages$Holidays$OutNoOp = {$: 2};
var $author$project$Pages$Holidays$OutNotification = function (a) {
	return {$: 0, a: a};
};
var $author$project$Utils$Modules$convertSubToMain = F4(
	function (subModelToState, subMsgToMsg, outMsgHandler, _v0) {
		var subModel = _v0.a;
		var subCmd = _v0.b;
		var subOutMsg = _v0.c;
		return _Utils_Tuple3(
			{
				_: subModelToState(subModel)
			},
			A2($elm$core$Platform$Cmd$map, subMsgToMsg, subCmd),
			outMsgHandler(subOutMsg));
	});
var $author$project$Pages$Holidays$update_loadingState = A3(
	$author$project$Utils$Modules$convertSubToMain,
	$author$project$Pages$Holidays$Loading,
	$author$project$Pages$Holidays$Msg_Loading,
	function (subOutMsg2) {
		switch (subOutMsg2.$) {
			case 0:
				return $author$project$Pages$Holidays$OutNoOp;
			case 1:
				return $author$project$Pages$Holidays$OutGotAuthError;
			case 2:
				var notification = subOutMsg2.a;
				return $author$project$Pages$Holidays$OutNotification(notification);
			default:
				return $author$project$Pages$Holidays$OutNoOp;
		}
	});
var $author$project$Pages$Holidays$Msg_NoAccess = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Holidays$NoAccess = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Holidays$update_noAccessState = A3(
	$author$project$Utils$Modules$convertSubToMain,
	$author$project$Pages$Holidays$NoAccess,
	$author$project$Pages$Holidays$Msg_NoAccess,
	function (subOutMsg2) {
		return $author$project$Pages$Holidays$OutNoOp;
	});
var $author$project$Pages$Holidays$init = F3(
	function (serverContext, selectedYear, selectedUser) {
		var _v0 = $author$project$Types$User$toSiteUser(selectedUser);
		if (!_v0.$) {
			var siteUser = _v0.a;
			return $author$project$Pages$Holidays$update_loadingState(
				A4(
					$author$project$Pages$Holidays$State_Loading$init,
					serverContext,
					selectedYear,
					$author$project$Types$User$site(siteUser),
					$author$project$Types$User$canEditVacations(
						$author$project$Types$User$toUser(siteUser))));
		} else {
			return $author$project$Pages$Holidays$update_noAccessState($author$project$Pages$State_NoAccess$init);
		}
	});
var $author$project$Components$PinDialog$NoOp = {$: 2};
var $author$project$Components$PinDialog$OutNoOp = 1;
var $author$project$Components$PinDialog$pinInputId = 'reporting-pin-input';
var $author$project$Components$PinDialog$init = function () {
	var initialModel = {cR: _List_Nil};
	return _Utils_Tuple3(
		initialModel,
		A2(
			$elm$core$Task$attempt,
			$elm$core$Basics$always($author$project$Components$PinDialog$NoOp),
			$elm$browser$Browser$Dom$focus($author$project$Components$PinDialog$pinInputId)),
		1);
}();
var $author$project$Pages$WorkTimeTracking$State_Loading$GotData = $elm$core$Basics$identity;
var $author$project$Pages$WorkTimeTracking$State_Loading$OutNoOp = {$: 3};
var $author$project$Types$Worker$fetchActive = function (_v0) {
	var apiUrl = _v0.j0;
	var token = _v0.oK;
	return $author$project$JwtHttp$task(
		{
			kc: $elm$http$Http$emptyBody,
			mr: 'GET',
			nJ: $elm$http$Http$stringResolver(
				$author$project$JwtHttp$handleJsonResponse(
					$elm$json$Json$Decode$list($author$project$Types$Worker$jsonDecoder))),
			oH: $elm$core$Maybe$Nothing,
			oK: token,
			oU: apiUrl + '/workers/active'
		});
};
var $author$project$Types$WorkTimeEntry$WorkTimeEntry = function (id) {
	return function (workerId) {
		return function (balanceEffect) {
			return function (comment) {
				return function (entryType) {
					return function (on) {
						return function (reflectInPay) {
							return function (signature) {
								return function (payment) {
									return function (createdAt) {
										return function (createdBy) {
											return function (updatedAt) {
												return function (updatedBy) {
													return {gX: balanceEffect, kH: comment, d4: createdAt, kR: createdBy, ln: entryType, er: id, mJ: on, cm: payment, bF: reflectInPay, i2: signature, fa: updatedAt, oS: updatedBy, jy: workerId};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Types$WorkTimeEntry$Correction = 0;
var $author$project$Types$WorkTimeEntry$HourlyWork = 1;
var $author$project$Types$WorkTimeEntry$Overtime = 2;
var $author$project$Types$WorkTimeEntry$TimeOff = 3;
var $author$project$Types$WorkTimeEntry$entryTypeJsonDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'CORRECTION':
				return $elm$json$Json$Decode$succeed(0);
			case 'HOURLY_WORK':
				return $elm$json$Json$Decode$succeed(1);
			case 'TIME_OFF':
				return $elm$json$Json$Decode$succeed(3);
			case 'OVERTIME':
				return $elm$json$Json$Decode$succeed(2);
			default:
				var unknownString = string;
				return $elm$json$Json$Decode$fail(unknownString);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Types$Signature$Signature = F3(
	function (id, at, by) {
		return {j4: at, kl: by, er: id};
	});
var $author$project$Types$Signature$jsonDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'by',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'at',
		$author$project$Decode$isoTimeDecoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'id',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$Types$Signature$Signature))));
var $author$project$Types$WorkTimePayment$WorkTimePayment = F8(
	function (id, workerId, entryIds, balanceEffect, comment, on, createdAt, createdBy) {
		return {gX: balanceEffect, kH: comment, d4: createdAt, kR: createdBy, ho: entryIds, er: id, mJ: on, jy: workerId};
	});
var $author$project$Types$WorkTimePayment$jsonDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'createdBy',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'createdAt',
		$author$project$Decode$isoTimeDecoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'on',
			$author$project$Types$Date$jsonDecoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'comment',
				$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'balanceEffect',
					$elm$json$Json$Decode$float,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'entryIds',
						$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'workerId',
							$elm$json$Json$Decode$int,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'id',
								$elm$json$Json$Decode$string,
								$elm$json$Json$Decode$succeed($author$project$Types$WorkTimePayment$WorkTimePayment)))))))));
var $author$project$Types$WorkTimeEntry$jsonDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'updatedBy',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'updatedAt',
		$author$project$Decode$isoTimeDecoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'createdBy',
			$elm$json$Json$Decode$int,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'createdAt',
				$author$project$Decode$isoTimeDecoder,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'payment',
					$elm$json$Json$Decode$maybe($author$project$Types$WorkTimePayment$jsonDecoder),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'signature',
						$elm$json$Json$Decode$maybe($author$project$Types$Signature$jsonDecoder),
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'reflectInPay',
							$elm$json$Json$Decode$bool,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'on',
								$author$project$Types$Date$jsonDecoder,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'entryType',
									$author$project$Types$WorkTimeEntry$entryTypeJsonDecoder,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'comment',
										$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'balanceEffect',
											$elm$json$Json$Decode$float,
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'workerId',
												$elm$json$Json$Decode$int,
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'id',
													$elm$json$Json$Decode$string,
													$elm$json$Json$Decode$succeed($author$project$Types$WorkTimeEntry$WorkTimeEntry))))))))))))));
var $author$project$Types$WorkTimeEntry$fetchForWorkers = F2(
	function (_v0, workerIds) {
		var apiUrl = _v0.j0;
		var token = _v0.oK;
		return $author$project$JwtHttp$task(
			{
				kc: $elm$http$Http$jsonBody(
					$elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'workerIds',
								A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$int, workerIds))
							]))),
				mr: 'POST',
				nJ: $elm$http$Http$stringResolver(
					$author$project$JwtHttp$handleJsonResponse(
						$elm$json$Json$Decode$list($author$project$Types$WorkTimeEntry$jsonDecoder))),
				oH: $elm$core$Maybe$Nothing,
				oK: token,
				oU: apiUrl + '/work-time/entries/get'
			});
	});
var $author$project$Types$WorkTimePayment$fetchForWorkers = F2(
	function (_v0, workerIds) {
		var apiUrl = _v0.j0;
		var token = _v0.oK;
		return $author$project$JwtHttp$task(
			{
				kc: $elm$http$Http$jsonBody(
					$elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'workerIds',
								A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$int, workerIds))
							]))),
				mr: 'POST',
				nJ: $elm$http$Http$stringResolver(
					$author$project$JwtHttp$handleJsonResponse(
						$elm$json$Json$Decode$list($author$project$Types$WorkTimePayment$jsonDecoder))),
				oH: $elm$core$Maybe$Nothing,
				oK: token,
				oU: apiUrl + '/work-time/payments/get'
			});
	});
var $author$project$Pages$WorkTimeTracking$State_Loading$fetchData = function (serverContext) {
	return A2(
		$elm$core$Task$andThen,
		function (workers) {
			var workersWithWorkTimeTracking = A2(
				$elm$core$List$filter,
				function ($) {
					return $.lG;
				},
				workers);
			return function (workerIds) {
				return A3(
					$elm$core$Task$map2,
					F2(
						function (entries, payments) {
							return {lm: entries, nr: payments, jA: workersWithWorkTimeTracking};
						}),
					A2($author$project$Types$WorkTimeEntry$fetchForWorkers, serverContext, workerIds),
					A2($author$project$Types$WorkTimePayment$fetchForWorkers, serverContext, workerIds));
			}(
				A2(
					$elm$core$List$map,
					function ($) {
						return $.er;
					},
					workersWithWorkTimeTracking));
		},
		$author$project$Types$Worker$fetchActive(serverContext));
};
var $author$project$Pages$WorkTimeTracking$State_Loading$init = function (serverContext) {
	var initialModel = {};
	return _Utils_Tuple3(
		initialModel,
		A2(
			$elm$core$Task$attempt,
			$elm$core$Basics$identity,
			$author$project$Pages$WorkTimeTracking$State_Loading$fetchData(serverContext)),
		$author$project$Pages$WorkTimeTracking$State_Loading$OutNoOp);
};
var $author$project$Pages$WorkTimeTracking$AskingPin = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$WorkTimeTracking$Msg_AskingPin = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$WorkTimeTracking$OutNoOp = {$: 2};
var $author$project$Pages$WorkTimeTracking$update_askingPinState = A3(
	$author$project$Utils$Modules$convertSubToMain,
	$author$project$Pages$WorkTimeTracking$AskingPin,
	$author$project$Pages$WorkTimeTracking$Msg_AskingPin,
	function (subOutMsg2) {
		if (!subOutMsg2) {
			return $author$project$Pages$WorkTimeTracking$OutNoOp;
		} else {
			return $author$project$Pages$WorkTimeTracking$OutNoOp;
		}
	});
var $author$project$Pages$WorkTimeTracking$Loading = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$WorkTimeTracking$Msg_Loading = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$WorkTimeTracking$OutGotAuthError = {$: 1};
var $author$project$Pages$WorkTimeTracking$OutNotification = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$WorkTimeTracking$update_loadingState = A3(
	$author$project$Utils$Modules$convertSubToMain,
	$author$project$Pages$WorkTimeTracking$Loading,
	$author$project$Pages$WorkTimeTracking$Msg_Loading,
	function (subOutMsg2) {
		switch (subOutMsg2.$) {
			case 0:
				return $author$project$Pages$WorkTimeTracking$OutNoOp;
			case 1:
				return $author$project$Pages$WorkTimeTracking$OutGotAuthError;
			case 2:
				var notification = subOutMsg2.a;
				return $author$project$Pages$WorkTimeTracking$OutNotification(notification);
			default:
				return $author$project$Pages$WorkTimeTracking$OutNoOp;
		}
	});
var $author$project$Pages$WorkTimeTracking$Msg_NoAccess = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$WorkTimeTracking$NoAccessPage = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$WorkTimeTracking$update_noAccessState = A3(
	$author$project$Utils$Modules$convertSubToMain,
	$author$project$Pages$WorkTimeTracking$NoAccessPage,
	$author$project$Pages$WorkTimeTracking$Msg_NoAccess,
	function (subOutMsg2) {
		return $author$project$Pages$WorkTimeTracking$OutNoOp;
	});
var $author$project$Types$User$workingHoursPageAccess = function (user) {
	switch (user.$) {
		case 0:
			return 1;
		case 2:
			return 1;
		case 1:
			return 1;
		default:
			switch (user.a.$) {
				case 0:
					var _v1 = user.a;
					return 0;
				case 2:
					var _v2 = user.a;
					return 2;
				default:
					var _v3 = user.a;
					return 2;
			}
	}
};
var $author$project$Pages$WorkTimeTracking$init = F2(
	function (_v0, user) {
		var serverContext = _v0.n1;
		var _v1 = $author$project$Types$User$workingHoursPageAccess(user);
		switch (_v1) {
			case 2:
				return $author$project$Pages$WorkTimeTracking$update_noAccessState($author$project$Pages$State_NoAccess$init);
			case 0:
				return $author$project$Pages$WorkTimeTracking$update_askingPinState($author$project$Components$PinDialog$init);
			default:
				return $author$project$Pages$WorkTimeTracking$update_loadingState(
					$author$project$Pages$WorkTimeTracking$State_Loading$init(serverContext));
		}
	});
var $author$project$Main$navigateTo = F2(
	function (stateModel, pageLink) {
		var _v0 = stateModel;
		var appContext = _v0.q;
		var currentPage = _v0.A;
		var selectedUser = _v0.aS;
		var _v1 = function () {
			switch (pageLink) {
				case 0:
					var _v3 = A3(
						$author$project$Pages$Holidays$init,
						appContext.n1,
						$justinmimbs$date$Date$year(appContext.dE),
						stateModel.aS);
					var pageInitModel = _v3.a;
					var initCmd = _v3.b;
					return _Utils_Tuple2(
						$author$project$Types$Page$HolidaysPage(pageInitModel),
						A2($elm$core$Platform$Cmd$map, $author$project$Main$GotHolidaysPageMsg, initCmd));
				case 1:
					var _v4 = $author$project$Pages$Invoicing$init(appContext.n1);
					var pageInitModel = _v4.a;
					var initCmd = _v4.b;
					return _Utils_Tuple2(
						$author$project$Types$Page$InvoicingPage(pageInitModel),
						A2($elm$core$Platform$Cmd$map, $author$project$Main$GotInvoicingPageMsg, initCmd));
				case 2:
					var _v5 = A2($author$project$Pages$Reporting$init, appContext, selectedUser);
					var pageInitModel = _v5.a;
					var initCmd = _v5.b;
					return _Utils_Tuple2(
						$author$project$Types$Page$ReportingPage(pageInitModel),
						A2($elm$core$Platform$Cmd$map, $author$project$Main$GotReportingPageMsg, initCmd));
				case 3:
					return A2(
						$elm$core$Maybe$withDefault,
						_Utils_Tuple2(currentPage, $elm$core$Platform$Cmd$none),
						A2(
							$elm$core$Maybe$map,
							function (siteUser) {
								var _v6 = A2($author$project$Pages$Tasklist$init, appContext.n1, siteUser);
								var pageInitModel = _v6.a;
								var initCmd = _v6.b;
								return _Utils_Tuple2(
									$author$project$Types$Page$TasklistPage(pageInitModel),
									A2($elm$core$Platform$Cmd$map, $author$project$Main$GotTasklistPageMsg, initCmd));
							},
							$author$project$Types$User$toSiteUser(selectedUser)));
				default:
					var _v7 = A2($author$project$Pages$WorkTimeTracking$init, appContext, selectedUser);
					var pageInitModel = _v7.a;
					var initCmd = _v7.b;
					return _Utils_Tuple2(
						$author$project$Types$Page$WorkTimeTrackingPage(pageInitModel),
						A2($elm$core$Platform$Cmd$map, $author$project$Main$GotWorkTimeTrackingPageMsg, initCmd));
			}
		}();
		var updatedCurrentPage = _v1.a;
		var cmd = _v1.b;
		return _Utils_Tuple2(
			_Utils_update(
				stateModel,
				{
					A: updatedCurrentPage,
					eM: 'AutoDent | ' + $author$project$Types$Page$getTitle(pageLink)
				}),
			cmd);
	});
var $author$project$Main$switchSelectedUser = F2(
	function (stateModel, newSelectedUser) {
		var userDefaultPageLink = $author$project$Main$getDefaultPageLink(newSelectedUser);
		var updatedModel = _Utils_update(
			stateModel,
			{aS: newSelectedUser});
		return A2($author$project$Main$navigateTo, updatedModel, userDefaultPageLink);
	});
var $author$project$Main$hideUserNavigator = function (stateModel) {
	return A2(
		$author$project$Main$switchSelectedUser,
		_Utils_update(
			stateModel,
			{bO: $elm$core$Maybe$Nothing}),
		stateModel.q.h4);
};
var $author$project$Components$UserNavigator$GotData = $elm$core$Basics$identity;
var $author$project$Components$UserNavigator$Loading = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$UserNavigator$Msg_Loading = function (a) {
	return {$: 0, a: a};
};
var $author$project$Types$User$fetch = function (_v0) {
	var apiUrl = _v0.j0;
	var token = _v0.oK;
	return $author$project$JwtHttp$task(
		{
			kc: $elm$http$Http$emptyBody,
			mr: 'GET',
			nJ: $elm$http$Http$stringResolver(
				$author$project$JwtHttp$handleJsonResponse(
					$elm$json$Json$Decode$list($author$project$Types$User$userDecoder))),
			oH: $elm$core$Maybe$Nothing,
			oK: token,
			oU: apiUrl + '/users'
		});
};
var $author$project$Data$Tasklists$activeCountsPerSiteDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Dict$fromList,
	$elm$json$Json$Decode$list(
		A3(
			$elm$json$Json$Decode$map2,
			$elm$core$Tuple$pair,
			A2($elm$json$Json$Decode$field, 'siteId', $elm$json$Json$Decode$int),
			A2($elm$json$Json$Decode$field, 'activeTasklists', $elm$json$Json$Decode$int))));
var $author$project$Data$Tasklists$fetchActiveCountsBySite = function (_v0) {
	var apiUrl = _v0.j0;
	var token = _v0.oK;
	return $author$project$JwtHttp$task(
		{
			kc: $elm$http$Http$emptyBody,
			mr: 'GET',
			nJ: $elm$http$Http$stringResolver(
				$author$project$JwtHttp$handleJsonResponse($author$project$Data$Tasklists$activeCountsPerSiteDecoder)),
			oH: $elm$core$Maybe$Nothing,
			oK: token,
			oU: apiUrl + '/tasklists/active-counts-by-site'
		});
};
var $author$project$Components$UserNavigator$fetchData = function (serverContext) {
	return A4(
		$elm$core$Task$map3,
		F3(
			function (sites, users, activeTasklistCountsBySite) {
				return {aG: activeTasklistCountsBySite, dw: sites, S: users};
			}),
		$author$project$Types$Site$fetchAll(serverContext),
		$author$project$Types$User$fetch(serverContext),
		$author$project$Data$Tasklists$fetchActiveCountsBySite(serverContext));
};
var $author$project$Components$UserNavigator$init_Loading = F2(
	function (loggedInUser, serverContext) {
		return _Utils_Tuple2(
			{
				h4: loggedInUser,
				_: $author$project$Components$UserNavigator$Loading(
					{})
			},
			A2(
				$elm$core$Platform$Cmd$map,
				$author$project$Components$UserNavigator$Msg_Loading,
				A2(
					$elm$core$Task$attempt,
					$elm$core$Basics$identity,
					$author$project$Components$UserNavigator$fetchData(serverContext))));
	});
var $author$project$Components$UserNavigator$init = function (appContext) {
	return A2($author$project$Components$UserNavigator$init_Loading, appContext.h4, appContext.n1);
};
var $author$project$Main$showUserNavigator = function (stateModel) {
	var _v0 = $author$project$Components$UserNavigator$init(stateModel.q);
	var userNavigatorModel = _v0.a;
	var userNavigatorCmd = _v0.b;
	return _Utils_Tuple2(
		_Utils_update(
			stateModel,
			{
				bO: $elm$core$Maybe$Just(userNavigatorModel)
			}),
		A2($elm$core$Platform$Cmd$map, $author$project$Main$GotUserNavigatorMsg, userNavigatorCmd));
};
var $author$project$Components$TopMenu$GotUserMenuMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$TopMenu$GotVehicleSearchMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$TopMenu$OutLogout = {$: 3};
var $author$project$Components$TopMenu$OutNavigate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$TopMenu$OutNoOp = {$: 4};
var $author$project$Components$TopMenu$OutNotification = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$TopMenu$OutShowUserNavigator = {$: 1};
var $author$project$Components$TopMenu$hideUserMenu = function (model) {
	return _Utils_update(
		model,
		{cF: $author$project$Components$TopMenu$UserMenu$init});
};
var $author$project$Components$TopMenu$UserMenu$OutNoOp = {$: 3};
var $author$project$Components$TopMenu$UserMenu$OutShowUserNavigator = {$: 0};
var $author$project$Components$TopMenu$UserMenu$MenuOpen = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$TopMenu$UserMenu$Msg_MenuClosed = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$TopMenu$UserMenu$NoOp_MenuClosed = 1;
var $author$project$Components$TopMenu$UserMenu$firstMenuItemId = 'first-menu-item';
var $author$project$Components$TopMenu$UserMenu$focusOnFirstMenuItem = function (msg) {
	return A2(
		$elm$core$Task$attempt,
		function (_v0) {
			return msg;
		},
		$elm$browser$Browser$Dom$focus($author$project$Components$TopMenu$UserMenu$firstMenuItemId));
};
var $author$project$Components$TopMenu$UserMenu$to_MenuOpen = _Utils_Tuple3(
	{
		_: $author$project$Components$TopMenu$UserMenu$MenuOpen(
			{
				eg: $elm$core$Maybe$Just($author$project$Components$TopMenu$UserMenu$firstMenuItemId)
			})
	},
	A2(
		$elm$core$Platform$Cmd$map,
		$author$project$Components$TopMenu$UserMenu$Msg_MenuClosed,
		$author$project$Components$TopMenu$UserMenu$focusOnFirstMenuItem(1)),
	$author$project$Components$TopMenu$UserMenu$OutNoOp);
var $author$project$Components$TopMenu$UserMenu$update_MenuClosed = F2(
	function (msg, stateModel) {
		if (!msg) {
			return $author$project$Components$TopMenu$UserMenu$to_MenuOpen;
		} else {
			return _Utils_Tuple3(
				{
					_: $author$project$Components$TopMenu$UserMenu$MenuClosed(stateModel)
				},
				$elm$core$Platform$Cmd$none,
				$author$project$Components$TopMenu$UserMenu$OutNoOp);
		}
	});
var $author$project$Components$TopMenu$UserMenu$OutLogout = {$: 2};
var $author$project$Components$TopMenu$UserMenu$Msg_MenuOpen = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$TopMenu$UserMenu$NoOp_MenuOpen = {$: 5};
var $author$project$Components$TopMenu$UserMenu$menuButtonId = 'toggle-dropdown-menu-button';
var $author$project$Components$TopMenu$UserMenu$focusOnMenuButton = function (msg) {
	return A2(
		$elm$core$Task$attempt,
		function (_v0) {
			return msg;
		},
		$elm$browser$Browser$Dom$focus($author$project$Components$TopMenu$UserMenu$menuButtonId));
};
var $author$project$Components$TopMenu$UserMenu$to_MenuClosed = function (outMsg) {
	return _Utils_Tuple3(
		{
			_: $author$project$Components$TopMenu$UserMenu$MenuClosed(
				{})
		},
		A2(
			$elm$core$Platform$Cmd$map,
			$author$project$Components$TopMenu$UserMenu$Msg_MenuOpen,
			$author$project$Components$TopMenu$UserMenu$focusOnMenuButton($author$project$Components$TopMenu$UserMenu$NoOp_MenuOpen)),
		outMsg);
};
var $author$project$Components$TopMenu$UserMenu$GotPwdDialogMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$TopMenu$UserMenu$Msg_PwdDialog = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$TopMenu$UserMenu$PwdDialog = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$TopMenu$ChangePasswordDialog$NoOp = {$: 6};
var $author$project$Components$TopMenu$ChangePasswordDialog$currentPasswordInputId = 'existing-password';
var $author$project$Components$TopMenu$ChangePasswordDialog$focusOnCurrentPasswordInput = A2(
	$elm$core$Task$attempt,
	function (_v0) {
		return $author$project$Components$TopMenu$ChangePasswordDialog$NoOp;
	},
	$elm$browser$Browser$Dom$focus($author$project$Components$TopMenu$ChangePasswordDialog$currentPasswordInputId));
var $author$project$Components$TopMenu$ChangePasswordDialog$init = function () {
	var initialModel = {aJ: '', G: '', aN: '', a8: $elm$core$Maybe$Nothing};
	return _Utils_Tuple2(initialModel, $author$project$Components$TopMenu$ChangePasswordDialog$focusOnCurrentPasswordInput);
}();
var $author$project$Components$TopMenu$UserMenu$to_PwdDialog = function () {
	var _v0 = $author$project$Components$TopMenu$ChangePasswordDialog$init;
	var pwdInitialModel = _v0.a;
	var pwdCmd = _v0.b;
	return _Utils_Tuple3(
		{
			_: $author$project$Components$TopMenu$UserMenu$PwdDialog(pwdInitialModel)
		},
		A2(
			$elm$core$Platform$Cmd$map,
			$author$project$Components$TopMenu$UserMenu$Msg_PwdDialog,
			A2($elm$core$Platform$Cmd$map, $author$project$Components$TopMenu$UserMenu$GotPwdDialogMsg, pwdCmd)),
		$author$project$Components$TopMenu$UserMenu$OutNoOp);
}();
var $author$project$Components$TopMenu$UserMenu$update_MenuOpen = F2(
	function (msg, stateModel) {
		switch (msg.$) {
			case 0:
				return $author$project$Components$TopMenu$UserMenu$to_MenuClosed($author$project$Components$TopMenu$UserMenu$OutNoOp);
			case 3:
				return $author$project$Components$TopMenu$UserMenu$to_PwdDialog;
			case 2:
				return $author$project$Components$TopMenu$UserMenu$to_MenuClosed($author$project$Components$TopMenu$UserMenu$OutShowUserNavigator);
			case 1:
				var menuItemId = msg.a;
				return _Utils_Tuple3(
					{
						_: $author$project$Components$TopMenu$UserMenu$MenuOpen(
							{
								eg: $elm$core$Maybe$Just(menuItemId)
							})
					},
					$elm$core$Platform$Cmd$none,
					$author$project$Components$TopMenu$UserMenu$OutNoOp);
			case 4:
				return _Utils_Tuple3(
					{
						_: $author$project$Components$TopMenu$UserMenu$MenuOpen(stateModel)
					},
					$elm$core$Platform$Cmd$none,
					$author$project$Components$TopMenu$UserMenu$OutLogout);
			default:
				return _Utils_Tuple3(
					{
						_: $author$project$Components$TopMenu$UserMenu$MenuOpen(stateModel)
					},
					$elm$core$Platform$Cmd$none,
					$author$project$Components$TopMenu$UserMenu$OutNoOp);
		}
	});
var $author$project$Components$TopMenu$UserMenu$OutNotification = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$TopMenu$ChangePasswordDialog$OutCancel = 1;
var $author$project$Components$TopMenu$ChangePasswordDialog$OutNoOp = 2;
var $author$project$Components$TopMenu$ChangePasswordDialog$OutPasswordChanged = 0;
var $author$project$Components$TopMenu$ChangePasswordDialog$GotChangePasswordMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$JwtHttp$Post = 1;
var $author$project$JwtHttp$post = F4(
	function (token, url, body, expect) {
		return A5($author$project$JwtHttp$request, token, 1, url, body, expect);
	});
var $author$project$Data$ServerResponse$ServerResponse = F3(
	function (success, msg, data) {
		return {k_: data, mB: msg, ok: success};
	});
var $author$project$Data$ServerResponse$serverResponseDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'data',
	A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$string),
	$elm$core$Maybe$Nothing,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'msg',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'success',
			$elm$json$Json$Decode$bool,
			$elm$json$Json$Decode$succeed($author$project$Data$ServerResponse$ServerResponse))));
var $author$project$Components$TopMenu$ChangePasswordDialog$changePasswordCmd = F3(
	function (_v0, currentPassword, newPassword) {
		var apiUrl = _v0.j0;
		var token = _v0.oK;
		var body = $elm$http$Http$jsonBody(
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'currentPassword',
						$elm$json$Json$Encode$string(currentPassword)),
						_Utils_Tuple2(
						'newPassword',
						$elm$json$Json$Encode$string(newPassword))
					])));
		return A4(
			$author$project$JwtHttp$post,
			token,
			apiUrl + '/auth/change-password',
			body,
			A2($elm$http$Http$expectJson, $author$project$Components$TopMenu$ChangePasswordDialog$GotChangePasswordMsg, $author$project$Data$ServerResponse$serverResponseDecoder));
	});
var $elm$core$String$trim = _String_trim;
var $author$project$Components$TopMenu$ChangePasswordDialog$update = F3(
	function (serverContext, msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, 1);
			case 1:
				var value = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							aJ: $elm$core$String$trim(value),
							a8: $elm$core$Maybe$Nothing
						}),
					$elm$core$Platform$Cmd$none,
					2);
			case 2:
				var value = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							G: $elm$core$String$trim(value),
							a8: $elm$core$Maybe$Nothing
						}),
					$elm$core$Platform$Cmd$none,
					2);
			case 3:
				var value = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							aN: $elm$core$String$trim(value),
							a8: $elm$core$Maybe$Nothing
						}),
					$elm$core$Platform$Cmd$none,
					2);
			case 4:
				return _Utils_Tuple3(
					model,
					A3($author$project$Components$TopMenu$ChangePasswordDialog$changePasswordCmd, serverContext, model.aJ, model.G),
					2);
			case 5:
				if (!msg.a.$) {
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{aJ: '', G: '', aN: '', a8: $elm$core$Maybe$Nothing}),
						$elm$core$Platform$Cmd$none,
						0);
				} else {
					var httpError = msg.a.a;
					var errorMessage = function () {
						if ((httpError.$ === 3) && (httpError.a === 401)) {
							return 'väärä salasana';
						} else {
							return 'palvelinvirhe';
						}
					}();
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								a8: $elm$core$Maybe$Just(errorMessage)
							}),
						$elm$core$Platform$Cmd$none,
						2);
				}
			default:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, 2);
		}
	});
var $author$project$Components$TopMenu$UserMenu$update_PwdDialogOpen = F3(
	function (serverContext, msg, stateModel) {
		if (!msg.$) {
			var pwdDialogMsg = msg.a;
			var _v1 = A3($author$project$Components$TopMenu$ChangePasswordDialog$update, serverContext, pwdDialogMsg, stateModel);
			var updatedStateModel = _v1.a;
			var cmd = _v1.b;
			var outMsg = _v1.c;
			switch (outMsg) {
				case 0:
					var pwdChangeSuccessNotification = A2($author$project$Components$Notification$Notification, 'Salasana vaihdettu', 1);
					return $author$project$Components$TopMenu$UserMenu$to_MenuClosed(
						$author$project$Components$TopMenu$UserMenu$OutNotification(pwdChangeSuccessNotification));
				case 1:
					return $author$project$Components$TopMenu$UserMenu$to_MenuClosed($author$project$Components$TopMenu$UserMenu$OutNoOp);
				default:
					return _Utils_Tuple3(
						{
							_: $author$project$Components$TopMenu$UserMenu$PwdDialog(updatedStateModel)
						},
						A2(
							$elm$core$Platform$Cmd$map,
							$author$project$Components$TopMenu$UserMenu$Msg_PwdDialog,
							A2($elm$core$Platform$Cmd$map, $author$project$Components$TopMenu$UserMenu$GotPwdDialogMsg, cmd)),
						$author$project$Components$TopMenu$UserMenu$OutNoOp);
			}
		} else {
			return _Utils_Tuple3(
				{
					_: $author$project$Components$TopMenu$UserMenu$PwdDialog(stateModel)
				},
				$elm$core$Platform$Cmd$none,
				$author$project$Components$TopMenu$UserMenu$OutNoOp);
		}
	});
var $author$project$Components$TopMenu$UserMenu$update = F3(
	function (appContext, msg, model) {
		var _v0 = _Utils_Tuple2(model._, msg);
		_v0$4:
		while (true) {
			switch (_v0.b.$) {
				case 0:
					if (!_v0.a.$) {
						var stateModel = _v0.a.a;
						var stateMsg = _v0.b.a;
						return A2($author$project$Components$TopMenu$UserMenu$update_MenuClosed, stateMsg, stateModel);
					} else {
						break _v0$4;
					}
				case 1:
					if (_v0.a.$ === 1) {
						var stateModel = _v0.a.a;
						var stateMsg = _v0.b.a;
						return A2($author$project$Components$TopMenu$UserMenu$update_MenuOpen, stateMsg, stateModel);
					} else {
						break _v0$4;
					}
				case 2:
					if (_v0.a.$ === 2) {
						var stateModel = _v0.a.a;
						var stateMsg = _v0.b.a;
						return A3($author$project$Components$TopMenu$UserMenu$update_PwdDialogOpen, appContext.n1, stateMsg, stateModel);
					} else {
						break _v0$4;
					}
				default:
					var _v1 = _v0.b;
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Components$TopMenu$UserMenu$OutShowUserNavigator);
			}
		}
		return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Components$TopMenu$UserMenu$OutNoOp);
	});
var $author$project$Components$Vehicles$Search$AllHistoriesHidden = {$: 0};
var $author$project$Components$Vehicles$Search$DisplayingVehicleHistory = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Vehicles$Search$DisplayingVehicles = F3(
	function (a, b, c) {
		return {$: 2, a: a, b: b, c: c};
	});
var $author$project$Components$Vehicles$Search$FetchingMatchingVehicles = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Vehicles$Search$NoOp = {$: 6};
var $author$project$Components$Vehicles$Search$OutNoOp = {$: 1};
var $author$project$Components$Vehicles$Search$OutNotification = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Notification$Top = 0;
var $author$project$Types$Vehicle$LicenseNumberField$LicenseNumberField = $elm$core$Basics$identity;
var $elm$core$String$fromList = _String_fromList;
var $elm$core$String$replace = F3(
	function (before, after, string) {
		return A2(
			$elm$core$String$join,
			after,
			A2($elm$core$String$split, before, string));
	});
var $elm$core$String$toUpper = _String_toUpper;
var $elm$core$String$trimLeft = _String_trimLeft;
var $author$project$Types$Vehicle$LicenseNumberField$create = function (inputString) {
	var isValidCharacter = function (character) {
		return $elm$core$Char$isAlphaNum(character) || ((character === '-') || (character === ' '));
	};
	var dropMultipleDashes = A2(
		$elm$core$Basics$composeR,
		$elm$core$String$toList,
		A2(
			$elm$core$List$foldl,
			F2(
				function (character, carry) {
					return ((character === '-') && A2($elm$core$String$contains, '-', carry)) ? carry : _Utils_ap(
						carry,
						$elm$core$String$fromChar(character));
				}),
			''));
	var dropInvalidCharacters = A2(
		$elm$core$Basics$composeR,
		$elm$core$String$toList,
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$filter(isValidCharacter),
			$elm$core$String$fromList));
	var validatedString = dropMultipleDashes(
		dropInvalidCharacters(
			A3(
				$elm$core$String$replace,
				'  ',
				' ',
				$elm$core$String$trimLeft(
					$elm$core$String$toUpper(inputString)))));
	return validatedString;
};
var $author$project$Types$Vehicle$LicenseNumberField$toString = function (_v0) {
	var licenseNumber = _v0;
	return licenseNumber;
};
var $author$project$Types$Vehicle$LicenseNumberField$add = F2(
	function (newCharacter, licenseNumber) {
		return $author$project$Types$Vehicle$LicenseNumberField$create(
			function (licenseNumberString) {
				return _Utils_ap(
					licenseNumberString,
					$elm$core$String$fromChar(newCharacter));
			}(
				$author$project$Types$Vehicle$LicenseNumberField$toString(licenseNumber)));
	});
var $author$project$Types$Vehicle$LicenseNumberField$endsWithDash = A2(
	$elm$core$Basics$composeR,
	$author$project$Types$Vehicle$LicenseNumberField$toString,
	$elm$core$String$endsWith('-'));
var $author$project$Types$Vehicle$LicenseNumberField$hasDash = A2(
	$elm$core$Basics$composeR,
	$author$project$Types$Vehicle$LicenseNumberField$toString,
	$elm$core$String$contains('-'));
var $elm_community$list_extra$List$Extra$find = F2(
	function (predicate, list) {
		find:
		while (true) {
			if (!list.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var first = list.a;
				var rest = list.b;
				if (predicate(first)) {
					return $elm$core$Maybe$Just(first);
				} else {
					var $temp$predicate = predicate,
						$temp$list = rest;
					predicate = $temp$predicate;
					list = $temp$list;
					continue find;
				}
			}
		}
	});
var $author$project$Types$Vehicle$LicenseNumberField$hasNumbers = A2(
	$elm$core$Basics$composeR,
	$author$project$Types$Vehicle$LicenseNumberField$toString,
	A2(
		$elm$core$Basics$composeR,
		$elm$core$String$toList,
		A2(
			$elm$core$Basics$composeR,
			$elm_community$list_extra$List$Extra$find($elm$core$Char$isDigit),
			$elm$core$Basics$neq($elm$core$Maybe$Nothing))));
var $author$project$Types$Vehicle$LicenseNumberField$hasSpaces = A2(
	$elm$core$Basics$composeR,
	$author$project$Types$Vehicle$LicenseNumberField$toString,
	$elm$core$String$contains(' '));
var $author$project$Types$Vehicle$LicenseNumberField$length = A2($elm$core$Basics$composeR, $author$project$Types$Vehicle$LicenseNumberField$toString, $elm$core$String$length);
var $author$project$Types$Vehicle$LicenseNumberField$addDashAfterThreeLettersTyped = F2(
	function (previousInput, currentInput) {
		return function (input) {
			return ((!$author$project$Types$Vehicle$LicenseNumberField$endsWithDash(previousInput)) && ((!$author$project$Types$Vehicle$LicenseNumberField$hasDash(previousInput)) && ((!$author$project$Types$Vehicle$LicenseNumberField$hasNumbers(currentInput)) && ((!$author$project$Types$Vehicle$LicenseNumberField$hasSpaces(currentInput)) && ($author$project$Types$Vehicle$LicenseNumberField$length(currentInput) === 3))))) ? A2($author$project$Types$Vehicle$LicenseNumberField$add, '-', input) : input;
		}(currentInput);
	});
var $author$project$Components$Vehicles$SearchTerm$SearchTerm = $elm$core$Basics$identity;
var $author$project$Components$Vehicles$SearchTerm$countWildcardCharacters = A2(
	$elm$core$Basics$composeR,
	$elm$core$String$toList,
	A2(
		$elm$core$Basics$composeR,
		$elm$core$List$filter(
			$elm$core$Basics$eq('*')),
		$elm$core$List$length));
var $author$project$Components$Vehicles$SearchTerm$isValidCharacter = function (additionalCharacter) {
	return $elm$core$Char$isAlphaNum(additionalCharacter) || ((additionalCharacter === '*') || (additionalCharacter === '-'));
};
var $author$project$Components$Vehicles$SearchTerm$create = function (string) {
	var validatedString = $elm$core$String$trim(string);
	var normalCharCount = $elm$core$String$length(validatedString) - $author$project$Components$Vehicles$SearchTerm$countWildcardCharacters(validatedString);
	var invalidCharacters = A2(
		$elm$core$List$filter,
		A2($elm$core$Basics$composeL, $elm$core$Basics$not, $author$project$Components$Vehicles$SearchTerm$isValidCharacter),
		$elm$core$String$toList(string));
	return ($elm$core$List$isEmpty(invalidCharacters) && (normalCharCount >= 3)) ? $elm$core$Maybe$Just(validatedString) : $elm$core$Maybe$Nothing;
};
var $author$project$Components$Vehicles$Search$GotVehicleHistory = function (a) {
	return {$: 5, a: a};
};
var $author$project$Types$Vehicle$LicenseNumber$toString = function (licenseNumber) {
	if (!licenseNumber.$) {
		var string = licenseNumber.a;
		return string;
	} else {
		var string = licenseNumber.a;
		return string;
	}
};
var $author$project$Components$Vehicles$History$NoVehicleHistoryFound = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Vehicles$History$VehicleHistoryFound = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Components$Vehicles$History$VehicleHistoryJob = F9(
	function (tasklistName, tasklistStatus, price, products, workers, comment, at, siteName, sequenceNumber) {
		return {j4: at, kH: comment, iv: price, iA: products, gh: sequenceNumber, i3: siteName, jf: tasklistName, jg: tasklistStatus, jA: workers};
	});
var $author$project$Components$Vehicles$History$VehicleHistoryProduct = F3(
	function (code, name, price) {
		return {ha: code, mD: name, iv: price};
	});
var $author$project$Components$Vehicles$History$vehicleHistoryProductDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'price',
	$elm$json$Json$Decode$maybe($elm$json$Json$Decode$float),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'name',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'code',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$Components$Vehicles$History$VehicleHistoryProduct))));
var $author$project$Components$Vehicles$History$VehicleHistoryWorker = F2(
	function (code, name) {
		return {ha: code, mD: name};
	});
var $author$project$Components$Vehicles$History$vehicleHistoryWorkerDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'name',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'code',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Components$Vehicles$History$VehicleHistoryWorker)));
var $author$project$Components$Vehicles$History$vehicleHistoryJobDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'sequenceNumber',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'siteName',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'at',
			$author$project$Decode$isoTimeDecoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'comment',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'workers',
					$elm$json$Json$Decode$list($author$project$Components$Vehicles$History$vehicleHistoryWorkerDecoder),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'products',
						$elm$json$Json$Decode$list($author$project$Components$Vehicles$History$vehicleHistoryProductDecoder),
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'price',
							$elm$json$Json$Decode$float,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'tasklistStatus',
								$author$project$Data$Tasklists$tasklistStatusDecoder,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'tasklistName',
									$elm$json$Json$Decode$string,
									$elm$json$Json$Decode$succeed($author$project$Components$Vehicles$History$VehicleHistoryJob))))))))));
var $author$project$Components$Vehicles$History$vehicleHistorySearchResultDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'licenseNo',
			$author$project$Types$Vehicle$LicenseNumber$licenseNumberDecoder,
			$elm$json$Json$Decode$succeed($author$project$Components$Vehicles$History$NoVehicleHistoryFound)),
			A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'jobs',
			$elm$json$Json$Decode$list($author$project$Components$Vehicles$History$vehicleHistoryJobDecoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'vehicle',
				$author$project$Decode$vehicleDecoder,
				$elm$json$Json$Decode$succeed($author$project$Components$Vehicles$History$VehicleHistoryFound)))
		]));
var $author$project$Components$Vehicles$History$searchCmd = F3(
	function (_v0, licenseNumber, onResult) {
		var apiUrl = _v0.j0;
		var token = _v0.oK;
		return A3(
			$author$project$JwtHttp$get,
			token,
			apiUrl + ('/vehicles/' + ($author$project$Types$Vehicle$LicenseNumber$toString(licenseNumber) + '/history')),
			A2($elm$http$Http$expectJson, onResult, $author$project$Components$Vehicles$History$vehicleHistorySearchResultDecoder));
	});
var $author$project$Components$Vehicles$Search$fetchHistoryForVehicle = F3(
	function (licenseNumber, model, appContext) {
		return _Utils_Tuple3(
			model,
			A3($author$project$Components$Vehicles$History$searchCmd, appContext.n1, licenseNumber, $author$project$Components$Vehicles$Search$GotVehicleHistory),
			$author$project$Components$Vehicles$Search$OutNoOp);
	});
var $author$project$Components$Vehicles$Search$GotVehicles = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Vehicles$SearchTerm$toString = function (_v0) {
	var searchTerm = _v0;
	return searchTerm;
};
var $author$project$Components$Vehicles$Search$fetchVehiclesThatMatchSearchTerm = F2(
	function (_v0, searchTerm) {
		var apiUrl = _v0.j0;
		var token = _v0.oK;
		var body = $elm$http$Http$jsonBody(
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'searchTerm',
						$elm$json$Json$Encode$string(
							$author$project$Components$Vehicles$SearchTerm$toString(searchTerm)))
					])));
		return A4(
			$author$project$JwtHttp$post,
			token,
			apiUrl + '/vehicles/search',
			body,
			A2(
				$elm$http$Http$expectJson,
				$author$project$Components$Vehicles$Search$GotVehicles,
				$elm$json$Json$Decode$list($author$project$Decode$vehicleDecoder)));
	});
var $author$project$Components$CloseButton$myId = 'closeDialogButton';
var $author$project$Components$CloseButton$focusOnMe = $elm$browser$Browser$Dom$focus($author$project$Components$CloseButton$myId);
var $author$project$Helpers$focusOn = F2(
	function (elementId, msg) {
		return A2(
			$elm$core$Task$attempt,
			function (_v0) {
				return msg;
			},
			$elm$browser$Browser$Dom$focus(elementId));
	});
var $author$project$Components$Vehicles$Search$licenseNumberSearchInputId = 'license-number-search-input';
var $author$project$Components$Vehicles$Search$focusOnSearchInput = A2($author$project$Helpers$focusOn, $author$project$Components$Vehicles$Search$licenseNumberSearchInputId, $author$project$Components$Vehicles$Search$NoOp);
var $author$project$Components$Vehicles$Search$gotoWaitingForSearchStringState = F2(
	function (searchString, outMsg) {
		return _Utils_Tuple3(
			{
				_: $author$project$Components$Vehicles$Search$WaitingForSearchString(searchString)
			},
			$author$project$Components$Vehicles$Search$focusOnSearchInput,
			outMsg);
	});
var $author$project$Components$Vehicles$SearchTerm$hasWildcardCharacters = function (string) {
	return $author$project$Components$Vehicles$SearchTerm$countWildcardCharacters(string) > 0;
};
var $author$project$Components$Vehicles$Search$update = F3(
	function (appContext, msg, model) {
		var _v0 = _Utils_Tuple2(model._, msg);
		_v0$8:
		while (true) {
			switch (_v0.a.$) {
				case 0:
					switch (_v0.b.$) {
						case 1:
							var oldSearchString = _v0.a.a;
							var newSearchString = _v0.b.a;
							var validatedNewSearchString = $elm$core$String$fromList(
								A2(
									$elm$core$List$filter,
									$author$project$Components$Vehicles$SearchTerm$isValidCharacter,
									$elm$core$String$toList(
										$elm$core$String$toUpper(newSearchString))));
							var searchStringWithPossibleDashAdded = $author$project$Components$Vehicles$SearchTerm$hasWildcardCharacters(validatedNewSearchString) ? validatedNewSearchString : $author$project$Types$Vehicle$LicenseNumberField$toString(
								A2(
									$author$project$Types$Vehicle$LicenseNumberField$addDashAfterThreeLettersTyped,
									$author$project$Types$Vehicle$LicenseNumberField$create(oldSearchString),
									$author$project$Types$Vehicle$LicenseNumberField$create(validatedNewSearchString)));
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{
										_: $author$project$Components$Vehicles$Search$WaitingForSearchString(searchStringWithPossibleDashAdded)
									}),
								$elm$core$Platform$Cmd$none,
								$author$project$Components$Vehicles$Search$OutNoOp);
						case 0:
							var typedInCharacters = _v0.a.a;
							var _v1 = _v0.b;
							var maybeSearchTerm = $author$project$Components$Vehicles$SearchTerm$create(typedInCharacters);
							if (!maybeSearchTerm.$) {
								var searchTerm = maybeSearchTerm.a;
								return _Utils_Tuple3(
									_Utils_update(
										model,
										{
											_: $author$project$Components$Vehicles$Search$FetchingMatchingVehicles(searchTerm)
										}),
									A2($author$project$Components$Vehicles$Search$fetchVehiclesThatMatchSearchTerm, appContext.n1, searchTerm),
									$author$project$Components$Vehicles$Search$OutNoOp);
							} else {
								return _Utils_Tuple3(
									model,
									$author$project$Components$Vehicles$Search$focusOnSearchInput,
									$author$project$Components$Vehicles$Search$OutNotification(
										A2($author$project$Components$Notification$Notification, 'Haussa pitää olla vähintään 3 kirjainta ja/tai numeroa (*-merkkiä ei lasketa)', 1)));
							}
						default:
							break _v0$8;
					}
				case 1:
					if (_v0.b.$ === 2) {
						if (!_v0.b.a.$) {
							var searchTerm = _v0.a.a;
							var matchingVehicles = _v0.b.a.a;
							if (!matchingVehicles.b) {
								return _Utils_Tuple3(
									_Utils_update(
										model,
										{
											_: $author$project$Components$Vehicles$Search$WaitingForSearchString(
												$author$project$Components$Vehicles$SearchTerm$toString(searchTerm))
										}),
									$author$project$Components$Vehicles$Search$focusOnSearchInput,
									$author$project$Components$Vehicles$Search$OutNotification(
										A2(
											$author$project$Components$Notification$Notification,
											'Haulla \'' + ($author$project$Components$Vehicles$SearchTerm$toString(searchTerm) + '\' ei löytynyt ajoneuvoja'),
											0)));
							} else {
								if (!matchingVehicles.b.b) {
									var singleMatchingVehicle = matchingVehicles.a;
									return A3(
										$author$project$Components$Vehicles$Search$fetchHistoryForVehicle,
										singleMatchingVehicle.de,
										_Utils_update(
											model,
											{
												_: A3(
													$author$project$Components$Vehicles$Search$DisplayingVehicles,
													searchTerm,
													_List_fromArray(
														[singleMatchingVehicle]),
													$author$project$Components$Vehicles$Search$AllHistoriesHidden)
											}),
										appContext);
								} else {
									var moreThanOneMatchingVehicle = matchingVehicles;
									return _Utils_Tuple3(
										_Utils_update(
											model,
											{
												_: A3($author$project$Components$Vehicles$Search$DisplayingVehicles, searchTerm, moreThanOneMatchingVehicle, $author$project$Components$Vehicles$Search$AllHistoriesHidden)
											}),
										A2(
											$elm$core$Task$attempt,
											function (_v4) {
												return $author$project$Components$Vehicles$Search$NoOp;
											},
											$author$project$Components$CloseButton$focusOnMe),
										$author$project$Components$Vehicles$Search$OutNoOp);
								}
							}
						} else {
							var searchTerm = _v0.a.a;
							return A2(
								$author$project$Components$Vehicles$Search$gotoWaitingForSearchStringState,
								$author$project$Components$Vehicles$SearchTerm$toString(searchTerm),
								$author$project$Components$Vehicles$Search$OutNotification(
									A2($author$project$Components$Notification$Notification, 'Virhe haettaessa ajoneuvoja', 1)));
						}
					} else {
						break _v0$8;
					}
				default:
					switch (_v0.b.$) {
						case 3:
							var _v5 = _v0.a;
							var searchTerm = _v5.a;
							var vehicles = _v5.b;
							var vehicleHistoryState = _v5.c;
							var vehicleInRow = _v0.b.a;
							switch (vehicleHistoryState.$) {
								case 0:
									return A3($author$project$Components$Vehicles$Search$fetchHistoryForVehicle, vehicleInRow.de, model, appContext);
								case 1:
									return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Components$Vehicles$Search$OutNoOp);
								case 2:
									if (!vehicleHistoryState.a.$) {
										var licenseNumber = vehicleHistoryState.a.a;
										var isExpandedVehicleClicked = _Utils_eq(licenseNumber, vehicleInRow.de);
										return isExpandedVehicleClicked ? _Utils_Tuple3(
											_Utils_update(
												model,
												{
													_: A3($author$project$Components$Vehicles$Search$DisplayingVehicles, searchTerm, vehicles, $author$project$Components$Vehicles$Search$AllHistoriesHidden)
												}),
											$elm$core$Platform$Cmd$none,
											$author$project$Components$Vehicles$Search$OutNoOp) : A3($author$project$Components$Vehicles$Search$fetchHistoryForVehicle, licenseNumber, model, appContext);
									} else {
										var _v7 = vehicleHistoryState.a;
										var vehicleThatHasHistoryOpened = _v7.a;
										var isExpandedVehicleClicked = _Utils_eq(vehicleThatHasHistoryOpened.de, vehicleInRow.de);
										return isExpandedVehicleClicked ? _Utils_Tuple3(
											_Utils_update(
												model,
												{
													_: A3($author$project$Components$Vehicles$Search$DisplayingVehicles, searchTerm, vehicles, $author$project$Components$Vehicles$Search$AllHistoriesHidden)
												}),
											$elm$core$Platform$Cmd$none,
											$author$project$Components$Vehicles$Search$OutNoOp) : A3($author$project$Components$Vehicles$Search$fetchHistoryForVehicle, vehicleInRow.de, model, appContext);
									}
								default:
									return A2(
										$author$project$Components$Vehicles$Search$gotoWaitingForSearchStringState,
										'',
										$author$project$Components$Vehicles$Search$OutNotification(
											A2($author$project$Components$Notification$Notification, 'Virhe haettaessa ajoneuvoja', 1)));
							}
						case 4:
							var _v8 = _v0.a;
							var _v9 = _v0.b;
							return A2($author$project$Components$Vehicles$Search$gotoWaitingForSearchStringState, '', $author$project$Components$Vehicles$Search$OutNoOp);
						case 5:
							if (!_v0.b.a.$) {
								var _v10 = _v0.a;
								var searchTerm = _v10.a;
								var vehicles = _v10.b;
								var vehicleHistorySearchResult = _v0.b.a.a;
								var updatedVehicleHistoryState = $author$project$Components$Vehicles$Search$DisplayingVehicleHistory(vehicleHistorySearchResult);
								return _Utils_Tuple3(
									_Utils_update(
										model,
										{
											_: A3($author$project$Components$Vehicles$Search$DisplayingVehicles, searchTerm, vehicles, updatedVehicleHistoryState)
										}),
									$elm$core$Platform$Cmd$none,
									$author$project$Components$Vehicles$Search$OutNoOp);
							} else {
								var _v11 = _v0.a;
								var searchTerm = _v11.a;
								return A2(
									$author$project$Components$Vehicles$Search$gotoWaitingForSearchStringState,
									$author$project$Components$Vehicles$SearchTerm$toString(searchTerm),
									$author$project$Components$Vehicles$Search$OutNotification(
										A2($author$project$Components$Notification$Notification, 'Virhe haettaessa ajoneuvon historiatietoja', 1)));
							}
						default:
							break _v0$8;
					}
			}
		}
		return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Components$Vehicles$Search$OutNoOp);
	});
var $author$project$Components$TopMenu$update = F3(
	function (appContext, msg, model) {
		switch (msg.$) {
			case 0:
				var pageLink = msg.a;
				return _Utils_Tuple3(
					$author$project$Components$TopMenu$hideUserMenu(model),
					$elm$core$Platform$Cmd$none,
					$author$project$Components$TopMenu$OutNavigate(pageLink));
			case 1:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Components$TopMenu$OutNoOp);
			case 2:
				var userMenuMsg = msg.a;
				var _v1 = A3($author$project$Components$TopMenu$UserMenu$update, appContext, userMenuMsg, model.cF);
				var updatedUserMenuModel = _v1.a;
				var userMenuCmd = _v1.b;
				var userMenuOutMsg = _v1.c;
				var outMsg = function () {
					switch (userMenuOutMsg.$) {
						case 1:
							var notification = userMenuOutMsg.a;
							return $author$project$Components$TopMenu$OutNotification(notification);
						case 0:
							return $author$project$Components$TopMenu$OutShowUserNavigator;
						case 2:
							return $author$project$Components$TopMenu$OutLogout;
						default:
							return $author$project$Components$TopMenu$OutNoOp;
					}
				}();
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{cF: updatedUserMenuModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Components$TopMenu$GotUserMenuMsg, userMenuCmd),
					outMsg);
			default:
				var vehicleSearchMsg = msg.a;
				var _v3 = A3($author$project$Components$Vehicles$Search$update, appContext, vehicleSearchMsg, model.dL);
				var updatedVehicleSearchModel = _v3.a;
				var vehicleSearchCmd = _v3.b;
				var vehicleSearchOutMsg = _v3.c;
				var outMsg = function () {
					if (!vehicleSearchOutMsg.$) {
						var notification = vehicleSearchOutMsg.a;
						return $author$project$Components$TopMenu$OutNotification(notification);
					} else {
						return $author$project$Components$TopMenu$OutNoOp;
					}
				}();
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{dL: updatedVehicleSearchModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Components$TopMenu$GotVehicleSearchMsg, vehicleSearchCmd),
					outMsg);
		}
	});
var $author$project$Components$UserNavigator$OutNoOp = {$: 3};
var $author$project$Components$UserNavigator$update_Error = F3(
	function (model, errorMsg, _v0) {
		return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Components$UserNavigator$OutNoOp);
	});
var $author$project$Components$UserNavigator$GotDataRefresh = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$UserNavigator$Loaded = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$UserNavigator$Msg_Loaded = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$UserNavigator$OutExit = {$: 1};
var $author$project$Components$UserNavigator$OutSelectUser = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$UserNavigator$RequestDataRefresh = {$: 1};
var $author$project$Components$UserNavigator$MobileUsersGroup = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$UserNavigator$UsersWithoutSiteGroup = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$UserNavigator$WashSitesGroup = function (a) {
	return {$: 0, a: a};
};
var $author$project$Types$User$canBeSwitchedTo = A2($elm$core$Basics$composeL, $elm$core$Basics$not, $author$project$Types$User$isAdminUser);
var $author$project$Helpers$firstWhere = F2(
	function (xToBool, xs) {
		return $elm$core$List$head(
			A2($elm$core$List$filter, xToBool, xs));
	});
var $author$project$Types$User$userIdsToUsers = F2(
	function (users, userIds) {
		return A2(
			$elm$core$List$filterMap,
			function (userId) {
				return A2(
					$author$project$Helpers$firstWhere,
					A2(
						$elm$core$Basics$composeR,
						$author$project$Types$User$id,
						$elm$core$Basics$eq(userId)),
					users);
			},
			userIds);
	});
var $author$project$Types$SiteWithUsers$create = F2(
	function (allUsers, site) {
		var siteData = function () {
			if (!site.$) {
				var data = site.a;
				return data;
			} else {
				var data = site.a;
				return data;
			}
		}();
		return {
			eZ: site,
			dv: siteData,
			S: A2($author$project$Types$User$userIdsToUsers, allUsers, siteData.cE)
		};
	});
var $author$project$Types$User$isSiteUser = function (user) {
	if (user.$ === 3) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Components$UserNavigator$buildNavigation = F3(
	function (model, sites, users) {
		var usersAvailableForNavigation = A2(
			$elm$core$List$filter,
			$elm$core$Basics$neq(model.h4),
			A2($elm$core$List$filter, $author$project$Types$User$canBeSwitchedTo, users));
		var usersWithoutSite = $author$project$Components$UserNavigator$UsersWithoutSiteGroup(
			A2(
				$elm$core$List$filter,
				A2($elm$core$Basics$composeL, $elm$core$Basics$not, $author$project$Types$User$isSiteUser),
				usersAvailableForNavigation));
		var sitesWithUsers = A2(
			$elm$core$List$filter,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.S;
				},
				A2($elm$core$Basics$composeL, $elm$core$Basics$not, $elm$core$List$isEmpty)),
			A2(
				$elm$core$List$map,
				$author$project$Types$SiteWithUsers$create(usersAvailableForNavigation),
				sites));
		var washSites = A2(
			$elm$core$List$filterMap,
			function (siteWithUsers) {
				var _v1 = siteWithUsers.eZ;
				if (!_v1.$) {
					return $elm$core$Maybe$Just(siteWithUsers);
				} else {
					return $elm$core$Maybe$Nothing;
				}
			},
			sitesWithUsers);
		var washSitesGroup = $author$project$Components$UserNavigator$WashSitesGroup(washSites);
		var mobileSites = A2(
			$elm$core$List$filterMap,
			function (siteWithUsers) {
				var _v0 = siteWithUsers.eZ;
				if (!_v0.$) {
					return $elm$core$Maybe$Nothing;
				} else {
					return $elm$core$Maybe$Just(siteWithUsers);
				}
			},
			sitesWithUsers);
		var mobileUsersGroup = $author$project$Components$UserNavigator$MobileUsersGroup(mobileSites);
		return {ey: mobileUsersGroup, fb: usersWithoutSite, dN: washSitesGroup};
	});
var $elm$core$Process$sleep = _Process_sleep;
var $andrewMacmurray$elm_delay$Delay$after = F2(
	function (time, msg) {
		return A2(
			$elm$core$Task$perform,
			$elm$core$Basics$always(msg),
			$elm$core$Process$sleep(time));
	});
var $author$project$Helpers$doAfter = F2(
	function (timeInMs, msg) {
		return A2($andrewMacmurray$elm_delay$Delay$after, timeInMs, msg);
	});
var $author$project$Components$UserNavigator$fetchActiveCountsBySite = function (serverContext) {
	return A2(
		$elm$core$Task$map,
		function (activeTasklistCountsBySite) {
			return {aG: activeTasklistCountsBySite, dw: _List_Nil, S: _List_Nil};
		},
		$author$project$Data$Tasklists$fetchActiveCountsBySite(serverContext));
};
var $author$project$Types$User$fullName = function (user) {
	return $author$project$Types$User$getBasicInfo(user).hz;
};
var $author$project$Types$User$isSiteManagerUser = function (user) {
	if ((user.$ === 3) && (!user.a.$)) {
		var _v1 = user.a;
		return true;
	} else {
		return false;
	}
};
var $author$project$Components$UserNavigator$sortWashSiteUsers = function (washSiteUsers) {
	return A2(
		$elm$core$List$sortBy,
		function (user) {
			return $author$project$Types$User$isSiteManagerUser(user) ? ('A' + $author$project$Types$User$fullName(user)) : ('B' + $author$project$Types$User$fullName(user));
		},
		washSiteUsers);
};
var $author$project$Components$UserNavigator$sortNavigationGroupUsers = function (navigationGroup) {
	switch (navigationGroup.$) {
		case 0:
			var washSites = navigationGroup.a;
			return $elm$core$List$concat(
				A2(
					$elm$core$List$map,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.S;
						},
						$author$project$Components$UserNavigator$sortWashSiteUsers),
					A2(
						$elm$core$List$sortBy,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.dv;
							},
							function ($) {
								return $.mD;
							}),
						washSites)));
		case 1:
			var mobileSites = navigationGroup.a;
			return $elm$core$List$concat(
				A2(
					$elm$core$List$map,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.S;
						},
						$elm$core$List$sortBy($author$project$Types$User$fullName)),
					A2(
						$elm$core$List$sortBy,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.dv;
							},
							function ($) {
								return $.mD;
							}),
						mobileSites)));
		default:
			var usersWithoutSite = navigationGroup.a;
			return A2($elm$core$List$sortBy, $author$project$Types$User$fullName, usersWithoutSite);
	}
};
var $author$project$Components$UserNavigator$getFirstUser = function (navigationGroup) {
	return $elm$core$List$head(
		$author$project$Components$UserNavigator$sortNavigationGroupUsers(navigationGroup));
};
var $author$project$Components$UserNavigator$OutGotAuthError = {$: 2};
var $author$project$Components$UserNavigator$handleAuthError = F2(
	function (model, loadedModel) {
		return _Utils_Tuple3(
			_Utils_update(
				model,
				{
					_: $author$project$Components$UserNavigator$Loaded(loadedModel)
				}),
			$elm$core$Platform$Cmd$none,
			$author$project$Components$UserNavigator$OutGotAuthError);
	});
var $author$project$Components$UserNavigator$outSelectUser = A2(
	$elm$core$Basics$composeR,
	$elm$core$Maybe$map($author$project$Components$UserNavigator$OutSelectUser),
	$elm$core$Maybe$withDefault($author$project$Components$UserNavigator$OutNoOp));
var $author$project$Components$UserNavigator$update_Loaded = F4(
	function (serverContext, model, loadedMsg, loadedModel) {
		switch (loadedMsg.$) {
			case 0:
				if (!loadedMsg.a.$) {
					var data = loadedMsg.a.a;
					var navigation = A3($author$project$Components$UserNavigator$buildNavigation, model, data.dw, data.S);
					var modelWithRefreshedData = _Utils_update(
						loadedModel,
						{aG: data.aG, da: false, eB: navigation});
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								_: $author$project$Components$UserNavigator$Loaded(modelWithRefreshedData)
							}),
						A2(
							$elm$core$Platform$Cmd$map,
							$author$project$Components$UserNavigator$Msg_Loaded,
							A2($author$project$Helpers$doAfter, serverContext.fx, $author$project$Components$UserNavigator$RequestDataRefresh)),
						$author$project$Components$UserNavigator$OutNoOp);
				} else {
					if ((loadedMsg.a.a.$ === 3) && (loadedMsg.a.a.a === 401)) {
						return A2($author$project$Components$UserNavigator$handleAuthError, model, loadedModel);
					} else {
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									_: $author$project$Components$UserNavigator$Loaded(loadedModel)
								}),
							A2(
								$elm$core$Platform$Cmd$map,
								$author$project$Components$UserNavigator$Msg_Loaded,
								A2($author$project$Helpers$doAfter, 2 * serverContext.fx, $author$project$Components$UserNavigator$RequestDataRefresh)),
							$author$project$Components$UserNavigator$OutNoOp);
					}
				}
			case 1:
				return loadedModel.da ? _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Components$UserNavigator$OutNoOp) : _Utils_Tuple3(
					_Utils_update(
						model,
						{
							_: $author$project$Components$UserNavigator$Loaded(
								_Utils_update(
									loadedModel,
									{da: true}))
						}),
					A2(
						$elm$core$Platform$Cmd$map,
						$author$project$Components$UserNavigator$Msg_Loaded,
						A2(
							$elm$core$Task$attempt,
							$author$project$Components$UserNavigator$GotDataRefresh,
							$author$project$Components$UserNavigator$fetchActiveCountsBySite(serverContext))),
					$author$project$Components$UserNavigator$OutNoOp);
			case 2:
				var selectedUserGroup = loadedMsg.a;
				var firstUserInGroup = $author$project$Components$UserNavigator$getFirstUser(selectedUserGroup);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							_: $author$project$Components$UserNavigator$Loaded(loadedModel)
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Components$UserNavigator$outSelectUser(firstUserInGroup));
			case 3:
				var selectedUser = loadedMsg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					$author$project$Components$UserNavigator$OutSelectUser(selectedUser));
			default:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Components$UserNavigator$OutExit);
		}
	});
var $author$project$Components$UserNavigator$Error = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$UserNavigator$init_Error = function (model) {
	return _Utils_Tuple3(
		_Utils_update(
			model,
			{
				_: $author$project$Components$UserNavigator$Error(
					{})
			}),
		$elm$core$Platform$Cmd$none,
		$author$project$Components$UserNavigator$OutNoOp);
};
var $author$project$Components$UserNavigator$init_Loaded = F3(
	function (serverContext, model, _v0) {
		var activeTasklistCountsBySite = _v0.aG;
		var sites = _v0.dw;
		var users = _v0.S;
		var navigation = A3($author$project$Components$UserNavigator$buildNavigation, model, sites, users);
		var loadedStateModel = {aG: activeTasklistCountsBySite, da: false, eB: navigation};
		var defaultSelectedUser = $author$project$Components$UserNavigator$getFirstUser(navigation.dN);
		return _Utils_Tuple3(
			_Utils_update(
				model,
				{
					_: $author$project$Components$UserNavigator$Loaded(loadedStateModel)
				}),
			A2(
				$elm$core$Platform$Cmd$map,
				$author$project$Components$UserNavigator$Msg_Loaded,
				A2($author$project$Helpers$doAfter, serverContext.fx, $author$project$Components$UserNavigator$RequestDataRefresh)),
			$author$project$Components$UserNavigator$outSelectUser(defaultSelectedUser));
	});
var $author$project$Components$UserNavigator$update_Loading = F4(
	function (serverContext, model, loadingMsg, _v0) {
		if (!loadingMsg.$) {
			var data = loadingMsg.a;
			return A3($author$project$Components$UserNavigator$init_Loaded, serverContext, model, data);
		} else {
			if ((loadingMsg.a.$ === 3) && (loadingMsg.a.a === 401)) {
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Components$UserNavigator$OutGotAuthError);
			} else {
				return $author$project$Components$UserNavigator$init_Error(model);
			}
		}
	});
var $author$project$Components$UserNavigator$update = F3(
	function (serverContext, msg, model) {
		var _v0 = _Utils_Tuple2(msg, model._);
		_v0$3:
		while (true) {
			switch (_v0.a.$) {
				case 0:
					if (!_v0.b.$) {
						var loadingMsg = _v0.a.a;
						var loadingModel = _v0.b.a;
						return A4($author$project$Components$UserNavigator$update_Loading, serverContext, model, loadingMsg, loadingModel);
					} else {
						break _v0$3;
					}
				case 1:
					if (_v0.b.$ === 1) {
						var loadedMsg = _v0.a.a;
						var loadedModel = _v0.b.a;
						return A4($author$project$Components$UserNavigator$update_Loaded, serverContext, model, loadedMsg, loadedModel);
					} else {
						break _v0$3;
					}
				default:
					if (_v0.b.$ === 2) {
						var errorMsg = _v0.a.a;
						var errorModel = _v0.b.a;
						return A3($author$project$Components$UserNavigator$update_Error, model, errorMsg, errorModel);
					} else {
						break _v0$3;
					}
			}
		}
		return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Components$UserNavigator$OutNoOp);
	});
var $author$project$Pages$Holidays$State_Loaded$CanEdit = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Holidays$State_Loaded$CannotEditAnything = {$: 0};
var $author$project$Pages$Holidays$State_Loaded$Collapsed = 0;
var $author$project$Pages$Holidays$State_Loaded$OutNoOp = {$: 2};
var $author$project$Types$VacationState$VacationConfirmed = 1;
var $author$project$Types$Worker$isActive = function (_v0) {
	var deletedAt = _v0.k9;
	return _Utils_eq(deletedAt, $elm$core$Maybe$Nothing);
};
var $author$project$Types$WorkerVacationWeek$blank = F2(
	function (workerId, week) {
		return {
			hw: A3($author$project$Types$VacationDay$workingDayForWeekday, workerId, week, 4),
			h7: A3($author$project$Types$VacationDay$workingDayForWeekday, workerId, week, 0),
			iS: A3($author$project$Types$VacationDay$workingDayForWeekday, workerId, week, 5),
			ji: A3($author$project$Types$VacationDay$workingDayForWeekday, workerId, week, 3),
			jq: A3($author$project$Types$VacationDay$workingDayForWeekday, workerId, week, 1),
			ju: A3($author$project$Types$VacationDay$workingDayForWeekday, workerId, week, 2),
			ao: week,
			jy: workerId
		};
	});
var $elm$core$List$maximum = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(
			A3($elm$core$List$foldl, $elm$core$Basics$max, x, xs));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$Basics$min = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) < 0) ? x : y;
	});
var $elm$core$List$minimum = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(
			A3($elm$core$List$foldl, $elm$core$Basics$min, x, xs));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$Dict$values = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, valueList) {
				return A2($elm$core$List$cons, value, valueList);
			}),
		_List_Nil,
		dict);
};
var $author$project$Pages$Holidays$State_Loaded$vacationWeeksForWorker = F3(
	function (selectedYear, allVacationWeeksByWorker, worker) {
		var yearsWithVacationDays = $elm$core$List$sort(
			$elm_community$list_extra$List$Extra$unique(
				A2(
					$elm$core$List$map,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.ao;
						},
						function ($) {
							return $.fi;
						}),
					$elm$core$List$concat(
						$elm$core$Dict$values(allVacationWeeksByWorker)))));
		var startYear = A2(
			$elm$core$Maybe$withDefault,
			selectedYear,
			$elm$core$List$minimum(
				A2($elm$core$List$cons, selectedYear, yearsWithVacationDays)));
		var existingWorkerVacationWeeks = $elm$core$Dict$fromList(
			A2(
				$elm$core$List$map,
				function (workerVacationWeek) {
					return _Utils_Tuple2(
						$author$project$Types$Week$toString(workerVacationWeek.ao),
						workerVacationWeek);
				},
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2($elm$core$Dict$get, worker.er, allVacationWeeksByWorker))));
		var endYear = A2(
			$elm$core$Maybe$withDefault,
			selectedYear,
			$elm$core$List$maximum(
				A2($elm$core$List$cons, selectedYear, yearsWithVacationDays)));
		var weeks = A2(
			$elm$core$List$sortBy,
			$author$project$Types$Week$toString,
			$elm$core$List$concat(
				A2(
					$elm$core$List$map,
					$author$project$Types$Week$forYear,
					A2($elm$core$List$range, startYear, endYear))));
		return _Utils_Tuple2(
			worker.er,
			A2(
				$elm$core$List$map,
				function (week) {
					return A2(
						$elm$core$Maybe$withDefault,
						A2($author$project$Types$WorkerVacationWeek$blank, worker.er, week),
						A2(
							$elm$core$Dict$get,
							$author$project$Types$Week$toString(week),
							existingWorkerVacationWeeks));
				},
				weeks));
	});
var $author$project$Pages$Holidays$State_Loaded$init = function (input) {
	var weeksInSelectedYear = $author$project$Types$Week$forYear(input.bG);
	var visibleWorkers = A2(
		$elm$core$List$filter,
		function ($) {
			return $.lF;
		},
		A2($elm$core$List$filter, $author$project$Types$Worker$isActive, input.jA));
	var vacationBudgetsByWorker = $elm$core$Dict$fromList(
		A2(
			$elm$core$List$map,
			function (worker) {
				return _Utils_Tuple2(
					worker.er,
					A2(
						$elm$core$List$filter,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.jy;
							},
							$elm$core$Basics$eq(worker.er)),
						input.oW));
			},
			visibleWorkers));
	var allVacationWeeksByWorker = $elm$core$Dict$fromList(
		A2(
			$elm$core$List$map,
			function (worker) {
				return _Utils_Tuple2(
					worker.er,
					A2(
						$elm$core$List$filter,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.jy;
							},
							$elm$core$Basics$eq(worker.er)),
						input.jV));
			},
			visibleWorkers));
	var initialModel = {
		aW: $elm$core$Maybe$Nothing,
		b1: $elm$core$Dict$fromList(
			A2(
				$elm$core$List$map,
				function (week) {
					return _Utils_Tuple2(week.o$, 0);
				},
				weeksInSelectedYear)),
		aw: input.kn ? $author$project$Pages$Holidays$State_Loaded$CanEdit(
			{cn: $elm$core$Maybe$Nothing}) : $author$project$Pages$Holidays$State_Loaded$CannotEditAnything,
		c6: $elm$core$Maybe$Nothing,
		a4: false,
		bG: input.bG,
		bP: vacationBudgetsByWorker,
		C: 1,
		al: $elm$core$Dict$fromList(
			A2(
				$elm$core$List$map,
				A2($author$project$Pages$Holidays$State_Loaded$vacationWeeksForWorker, input.bG, allVacationWeeksByWorker),
				visibleWorkers)),
		ff: weeksInSelectedYear,
		jA: visibleWorkers
	};
	return _Utils_Tuple3(initialModel, $elm$core$Platform$Cmd$none, $author$project$Pages$Holidays$State_Loaded$OutNoOp);
};
var $author$project$Pages$Holidays$Loaded = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Holidays$update_loadedState = A3(
	$author$project$Utils$Modules$convertSubToMain,
	$author$project$Pages$Holidays$Loaded,
	$author$project$Pages$Holidays$Msg_Loaded,
	function (subOutMsg2) {
		switch (subOutMsg2.$) {
			case 1:
				return $author$project$Pages$Holidays$OutGotAuthError;
			case 0:
				var notification = subOutMsg2.a;
				return $author$project$Pages$Holidays$OutNotification(notification);
			default:
				return $author$project$Pages$Holidays$OutNoOp;
		}
	});
var $author$project$Pages$Holidays$maybeTransition_loadingToLoaded = function (subOutMsg) {
	if (!subOutMsg.$) {
		var data = subOutMsg.a;
		return $elm$core$Maybe$Just(
			$author$project$Pages$Holidays$update_loadedState(
				$author$project$Pages$Holidays$State_Loaded$init(data)));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Pages$Holidays$State_Loaded$OutGotAuthError = {$: 1};
var $author$project$Pages$Holidays$State_Loaded$OutNotification = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Pages$Holidays$State_Loaded$EditAllowed = 2;
var $author$project$Pages$Holidays$State_Loaded$EditAllowedIfGivesPin = 1;
var $author$project$Pages$Holidays$State_Loaded$EditNotAllowed = 0;
var $author$project$Pages$Holidays$State_Loaded$checkEditPermission = F2(
	function (editPermissions, vacationStateMode) {
		var _v0 = _Utils_Tuple2(editPermissions, vacationStateMode);
		if (!_v0.a.$) {
			var _v1 = _v0.a;
			return 0;
		} else {
			if (!_v0.b) {
				var _v2 = _v0.b;
				return 2;
			} else {
				var pinValidForMs = _v0.a.a.cn;
				var _v3 = _v0.b;
				return A2(
					$elm$core$Maybe$withDefault,
					1,
					A2(
						$elm$core$Maybe$map,
						function (ms) {
							return (ms > 0) ? 2 : 1;
						},
						pinValidForMs));
			}
		}
	});
var $elm$core$String$concat = function (strings) {
	return A2($elm$core$String$join, '', strings);
};
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $author$project$Components$PinDialog$featureUsableNotification = function (featureDescription) {
	return A2(
		$author$project$Components$Notification$Notification,
		'PIN OK. ' + (featureDescription + (' käytettävissä ' + ($elm$core$String$fromInt($author$project$Components$PinDialog$pinValidForMinutes) + ' minuuttia.'))),
		1);
};
var $author$project$Pages$Holidays$State_Loaded$gotoYear = F2(
	function (targetYear, model) {
		return _Utils_update(
			model,
			{
				bG: targetYear,
				al: $elm$core$Dict$fromList(
					A2(
						$elm$core$List$map,
						A2($author$project$Pages$Holidays$State_Loaded$vacationWeeksForWorker, targetYear, model.al),
						model.jA)),
				ff: $author$project$Types$Week$forYear(targetYear)
			});
	});
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $author$project$Types$WorkerVacationWeek$vacationWeekdayAt = F2(
	function (workerVacationWeek, weekdayNumber) {
		return (weekdayNumber === 1) ? $elm$core$Maybe$Just(workerVacationWeek.h7) : ((weekdayNumber === 2) ? $elm$core$Maybe$Just(workerVacationWeek.jq) : ((weekdayNumber === 3) ? $elm$core$Maybe$Just(workerVacationWeek.ju) : ((weekdayNumber === 4) ? $elm$core$Maybe$Just(workerVacationWeek.ji) : ((weekdayNumber === 5) ? $elm$core$Maybe$Just(workerVacationWeek.hw) : ((weekdayNumber === 6) ? $elm$core$Maybe$Just(workerVacationWeek.iS) : $elm$core$Maybe$Nothing)))));
	});
var $author$project$Types$WorkerVacationWeek$isDayOff = F3(
	function (vacationState, workerVacationWeek, weekday) {
		return A2(
			$elm$core$Maybe$withDefault,
			false,
			A2(
				$elm$core$Maybe$map,
				function (vacationWeekday) {
					if (vacationState === 1) {
						return vacationWeekday.ek;
					} else {
						return vacationWeekday.el;
					}
				},
				A2(
					$author$project$Types$WorkerVacationWeek$vacationWeekdayAt,
					workerVacationWeek,
					$justinmimbs$date$Date$weekdayToNumber(weekday))));
	});
var $author$project$Types$WorkerVacationWeek$possibleVacationWeekdays = _List_fromArray(
	[0, 1, 2, 3, 4, 5]);
var $author$project$Types$WorkerVacationWeek$hasDaysOff = F2(
	function (vacationState, workerVacationWeek) {
		return A2(
			$elm$core$List$any,
			A2($author$project$Types$WorkerVacationWeek$isDayOff, vacationState, workerVacationWeek),
			$author$project$Types$WorkerVacationWeek$possibleVacationWeekdays);
	});
var $justinmimbs$date$Date$weekday = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$weekdayNumber, $justinmimbs$date$Date$numberToWeekday);
var $author$project$Language$Abbreviation = 1;
var $author$project$Language$weekdayNameFinnish = F2(
	function (format, weekday) {
		var longName = function () {
			switch (weekday) {
				case 0:
					return 'Maanantai';
				case 1:
					return 'Tiistai';
				case 2:
					return 'Keskiviikko';
				case 3:
					return 'Torstai';
				case 4:
					return 'Perjantai';
				case 5:
					return 'Lauantai';
				default:
					return 'Sunnuntai';
			}
		}();
		if (!format) {
			return longName;
		} else {
			return A2($elm$core$String$left, 2, longName);
		}
	});
var $author$project$Language$weekdayToFinAbbreviation = $author$project$Language$weekdayNameFinnish(1);
var $author$project$Language$dateToWeekdayFinAbbreviation = function (date) {
	return $author$project$Language$weekdayToFinAbbreviation(
		$justinmimbs$date$Date$weekday(date));
};
var $justinmimbs$date$Date$monthToNumber = function (m) {
	switch (m) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $justinmimbs$date$Date$toCalendarDateHelp = F3(
	function (y, m, d) {
		toCalendarDateHelp:
		while (true) {
			var monthDays = A2($justinmimbs$date$Date$daysInMonth, y, m);
			var mn = $justinmimbs$date$Date$monthToNumber(m);
			if ((mn < 12) && (_Utils_cmp(d, monthDays) > 0)) {
				var $temp$y = y,
					$temp$m = $justinmimbs$date$Date$numberToMonth(mn + 1),
					$temp$d = d - monthDays;
				y = $temp$y;
				m = $temp$m;
				d = $temp$d;
				continue toCalendarDateHelp;
			} else {
				return {d5: d, mw: m, fi: y};
			}
		}
	});
var $justinmimbs$date$Date$toOrdinalDate = function (_v0) {
	var rd = _v0;
	var y = $justinmimbs$date$Date$year(rd);
	return {
		f5: rd - $justinmimbs$date$Date$daysBeforeYear(y),
		fi: y
	};
};
var $justinmimbs$date$Date$toCalendarDate = function (_v0) {
	var rd = _v0;
	var date = $justinmimbs$date$Date$toOrdinalDate(rd);
	return A3($justinmimbs$date$Date$toCalendarDateHelp, date.fi, 0, date.f5);
};
var $justinmimbs$date$Date$day = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toCalendarDate,
	function ($) {
		return $.d5;
	});
var $justinmimbs$date$Date$month = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toCalendarDate,
	function ($) {
		return $.mw;
	});
var $justinmimbs$date$Date$monthNumber = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$month, $justinmimbs$date$Date$monthToNumber);
var $justinmimbs$date$Date$ordinalDay = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toOrdinalDate,
	function ($) {
		return $.f5;
	});
var $elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var $justinmimbs$date$Date$padSignedInt = F2(
	function (length, _int) {
		return _Utils_ap(
			(_int < 0) ? '-' : '',
			A3(
				$elm$core$String$padLeft,
				length,
				'0',
				$elm$core$String$fromInt(
					$elm$core$Basics$abs(_int))));
	});
var $justinmimbs$date$Date$monthToQuarter = function (m) {
	return (($justinmimbs$date$Date$monthToNumber(m) + 2) / 3) | 0;
};
var $justinmimbs$date$Date$quarter = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$month, $justinmimbs$date$Date$monthToQuarter);
var $elm$core$String$right = F2(
	function (n, string) {
		return (n < 1) ? '' : A3(
			$elm$core$String$slice,
			-n,
			$elm$core$String$length(string),
			string);
	});
var $justinmimbs$date$Date$ordinalSuffix = function (n) {
	var nn = A2($elm$core$Basics$modBy, 100, n);
	var _v0 = A2(
		$elm$core$Basics$min,
		(nn < 20) ? nn : A2($elm$core$Basics$modBy, 10, nn),
		4);
	switch (_v0) {
		case 1:
			return 'st';
		case 2:
			return 'nd';
		case 3:
			return 'rd';
		default:
			return 'th';
	}
};
var $justinmimbs$date$Date$withOrdinalSuffix = function (n) {
	return _Utils_ap(
		$elm$core$String$fromInt(n),
		$justinmimbs$date$Date$ordinalSuffix(n));
};
var $justinmimbs$date$Date$formatField = F4(
	function (language, _char, length, date) {
		switch (_char) {
			case 'y':
				if (length === 2) {
					return A2(
						$elm$core$String$right,
						2,
						A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$year(date))));
				} else {
					return A2(
						$justinmimbs$date$Date$padSignedInt,
						length,
						$justinmimbs$date$Date$year(date));
				}
			case 'Y':
				if (length === 2) {
					return A2(
						$elm$core$String$right,
						2,
						A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$weekYear(date))));
				} else {
					return A2(
						$justinmimbs$date$Date$padSignedInt,
						length,
						$justinmimbs$date$Date$weekYear(date));
				}
			case 'Q':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 2:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 3:
						return 'Q' + $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 4:
						return $justinmimbs$date$Date$withOrdinalSuffix(
							$justinmimbs$date$Date$quarter(date));
					case 5:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					default:
						return '';
				}
			case 'M':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$monthNumber(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$monthNumber(date)));
					case 3:
						return language.my(
							$justinmimbs$date$Date$month(date));
					case 4:
						return language.mx(
							$justinmimbs$date$Date$month(date));
					case 5:
						return A2(
							$elm$core$String$left,
							1,
							language.my(
								$justinmimbs$date$Date$month(date)));
					default:
						return '';
				}
			case 'w':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekNumber(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$weekNumber(date)));
					default:
						return '';
				}
			case 'd':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$day(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$day(date)));
					case 3:
						return language.k0(
							$justinmimbs$date$Date$day(date));
					default:
						return '';
				}
			case 'D':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$ordinalDay(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$ordinalDay(date)));
					case 3:
						return A3(
							$elm$core$String$padLeft,
							3,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$ordinalDay(date)));
					default:
						return '';
				}
			case 'E':
				switch (length) {
					case 1:
						return language.o2(
							$justinmimbs$date$Date$weekday(date));
					case 2:
						return language.o2(
							$justinmimbs$date$Date$weekday(date));
					case 3:
						return language.o2(
							$justinmimbs$date$Date$weekday(date));
					case 4:
						return language.o1(
							$justinmimbs$date$Date$weekday(date));
					case 5:
						return A2(
							$elm$core$String$left,
							1,
							language.o2(
								$justinmimbs$date$Date$weekday(date)));
					case 6:
						return A2(
							$elm$core$String$left,
							2,
							language.o2(
								$justinmimbs$date$Date$weekday(date)));
					default:
						return '';
				}
			case 'e':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekdayNumber(date));
					case 2:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekdayNumber(date));
					default:
						return A4($justinmimbs$date$Date$formatField, language, 'E', length, date);
				}
			default:
				return '';
		}
	});
var $justinmimbs$date$Date$formatWithTokens = F3(
	function (language, tokens, date) {
		return A3(
			$elm$core$List$foldl,
			F2(
				function (token, formatted) {
					if (!token.$) {
						var _char = token.a;
						var length = token.b;
						return _Utils_ap(
							A4($justinmimbs$date$Date$formatField, language, _char, length, date),
							formatted);
					} else {
						var str = token.a;
						return _Utils_ap(str, formatted);
					}
				}),
			'',
			tokens);
	});
var $justinmimbs$date$Pattern$Literal = function (a) {
	return {$: 1, a: a};
};
var $justinmimbs$date$Pattern$escapedQuote = A2(
	$elm$parser$Parser$ignorer,
	$elm$parser$Parser$succeed(
		$justinmimbs$date$Pattern$Literal('\'')),
	$elm$parser$Parser$token('\'\''));
var $justinmimbs$date$Pattern$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$getOffset = function (s) {
	return A3($elm$parser$Parser$Advanced$Good, false, s.f1, s);
};
var $elm$parser$Parser$getOffset = $elm$parser$Parser$Advanced$getOffset;
var $justinmimbs$date$Pattern$fieldRepeats = function (str) {
	var _v0 = $elm$core$String$toList(str);
	if (_v0.b && (!_v0.b.b)) {
		var _char = _v0.a;
		return A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				$elm$parser$Parser$succeed(
					F2(
						function (x, y) {
							return A2($justinmimbs$date$Pattern$Field, _char, 1 + (y - x));
						})),
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$getOffset,
					$elm$parser$Parser$chompWhile(
						$elm$core$Basics$eq(_char)))),
			$elm$parser$Parser$getOffset);
	} else {
		return $elm$parser$Parser$problem('expected exactly one char');
	}
};
var $justinmimbs$date$Pattern$field = A2(
	$elm$parser$Parser$andThen,
	$justinmimbs$date$Pattern$fieldRepeats,
	$elm$parser$Parser$getChompedString(
		$elm$parser$Parser$chompIf($elm$core$Char$isAlpha)));
var $justinmimbs$date$Pattern$finalize = A2(
	$elm$core$List$foldl,
	F2(
		function (token, tokens) {
			var _v0 = _Utils_Tuple2(token, tokens);
			if (((_v0.a.$ === 1) && _v0.b.b) && (_v0.b.a.$ === 1)) {
				var x = _v0.a.a;
				var _v1 = _v0.b;
				var y = _v1.a.a;
				var rest = _v1.b;
				return A2(
					$elm$core$List$cons,
					$justinmimbs$date$Pattern$Literal(
						_Utils_ap(x, y)),
					rest);
			} else {
				return A2($elm$core$List$cons, token, tokens);
			}
		}),
	_List_Nil);
var $elm$parser$Parser$Advanced$lazy = function (thunk) {
	return function (s) {
		var _v0 = thunk(0);
		var parse = _v0;
		return parse(s);
	};
};
var $elm$parser$Parser$lazy = $elm$parser$Parser$Advanced$lazy;
var $justinmimbs$date$Pattern$isLiteralChar = function (_char) {
	return (_char !== '\'') && (!$elm$core$Char$isAlpha(_char));
};
var $justinmimbs$date$Pattern$literal = A2(
	$elm$parser$Parser$map,
	$justinmimbs$date$Pattern$Literal,
	$elm$parser$Parser$getChompedString(
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed(0),
				$elm$parser$Parser$chompIf($justinmimbs$date$Pattern$isLiteralChar)),
			$elm$parser$Parser$chompWhile($justinmimbs$date$Pattern$isLiteralChar))));
var $justinmimbs$date$Pattern$quotedHelp = function (result) {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$andThen,
				function (str) {
					return $justinmimbs$date$Pattern$quotedHelp(
						_Utils_ap(result, str));
				},
				$elm$parser$Parser$getChompedString(
					A2(
						$elm$parser$Parser$ignorer,
						A2(
							$elm$parser$Parser$ignorer,
							$elm$parser$Parser$succeed(0),
							$elm$parser$Parser$chompIf(
								$elm$core$Basics$neq('\''))),
						$elm$parser$Parser$chompWhile(
							$elm$core$Basics$neq('\''))))),
				A2(
				$elm$parser$Parser$andThen,
				function (_v0) {
					return $justinmimbs$date$Pattern$quotedHelp(result + '\'');
				},
				$elm$parser$Parser$token('\'\'')),
				$elm$parser$Parser$succeed(result)
			]));
};
var $justinmimbs$date$Pattern$quoted = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$succeed($justinmimbs$date$Pattern$Literal),
		$elm$parser$Parser$chompIf(
			$elm$core$Basics$eq('\''))),
	A2(
		$elm$parser$Parser$ignorer,
		$justinmimbs$date$Pattern$quotedHelp(''),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					$elm$parser$Parser$chompIf(
					$elm$core$Basics$eq('\'')),
					$elm$parser$Parser$end
				]))));
var $justinmimbs$date$Pattern$patternHelp = function (tokens) {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$andThen,
				function (token) {
					return $justinmimbs$date$Pattern$patternHelp(
						A2($elm$core$List$cons, token, tokens));
				},
				$elm$parser$Parser$oneOf(
					_List_fromArray(
						[$justinmimbs$date$Pattern$field, $justinmimbs$date$Pattern$literal, $justinmimbs$date$Pattern$escapedQuote, $justinmimbs$date$Pattern$quoted]))),
				$elm$parser$Parser$lazy(
				function (_v0) {
					return $elm$parser$Parser$succeed(
						$justinmimbs$date$Pattern$finalize(tokens));
				})
			]));
};
var $elm$core$Result$withDefault = F2(
	function (def, result) {
		if (!result.$) {
			var a = result.a;
			return a;
		} else {
			return def;
		}
	});
var $justinmimbs$date$Pattern$fromString = function (str) {
	return A2(
		$elm$core$Result$withDefault,
		_List_fromArray(
			[
				$justinmimbs$date$Pattern$Literal(str)
			]),
		A2(
			$elm$parser$Parser$run,
			$justinmimbs$date$Pattern$patternHelp(_List_Nil),
			str));
};
var $justinmimbs$date$Date$formatWithLanguage = F2(
	function (language, pattern) {
		var tokens = $elm$core$List$reverse(
			$justinmimbs$date$Pattern$fromString(pattern));
		return A2($justinmimbs$date$Date$formatWithTokens, language, tokens);
	});
var $justinmimbs$date$Date$monthToName = function (m) {
	switch (m) {
		case 0:
			return 'January';
		case 1:
			return 'February';
		case 2:
			return 'March';
		case 3:
			return 'April';
		case 4:
			return 'May';
		case 5:
			return 'June';
		case 6:
			return 'July';
		case 7:
			return 'August';
		case 8:
			return 'September';
		case 9:
			return 'October';
		case 10:
			return 'November';
		default:
			return 'December';
	}
};
var $justinmimbs$date$Date$weekdayToName = function (wd) {
	switch (wd) {
		case 0:
			return 'Monday';
		case 1:
			return 'Tuesday';
		case 2:
			return 'Wednesday';
		case 3:
			return 'Thursday';
		case 4:
			return 'Friday';
		case 5:
			return 'Saturday';
		default:
			return 'Sunday';
	}
};
var $justinmimbs$date$Date$language_en = {
	k0: $justinmimbs$date$Date$withOrdinalSuffix,
	mx: $justinmimbs$date$Date$monthToName,
	my: A2(
		$elm$core$Basics$composeR,
		$justinmimbs$date$Date$monthToName,
		$elm$core$String$left(3)),
	o1: $justinmimbs$date$Date$weekdayToName,
	o2: A2(
		$elm$core$Basics$composeR,
		$justinmimbs$date$Date$weekdayToName,
		$elm$core$String$left(3))
};
var $justinmimbs$date$Date$format = function (pattern) {
	return A2($justinmimbs$date$Date$formatWithLanguage, $justinmimbs$date$Date$language_en, pattern);
};
var $author$project$Pages$Holidays$State_Loaded$updateOperationNotification = F2(
	function (workers, updateOperationType) {
		var _v0 = function () {
			if (!updateOperationType.$) {
				var workerVacationWeek_ = updateOperationType.a;
				var vacationState_ = updateOperationType.b;
				var maybeWeekday_ = updateOperationType.c;
				return _Utils_Tuple3(workerVacationWeek_, maybeWeekday_, vacationState_);
			} else {
				var workerVacationWeek_ = updateOperationType.a;
				var vacationState_ = updateOperationType.b;
				var maybeWeekday_ = updateOperationType.c;
				return _Utils_Tuple3(workerVacationWeek_, maybeWeekday_, vacationState_);
			}
		}();
		var workerVacationWeek = _v0.a;
		var maybeWeekday = _v0.b;
		var vacationStateMode = _v0.c;
		var timePeriod = function () {
			if (!maybeWeekday.$) {
				var weekday = maybeWeekday.a;
				var date = A2($author$project$Types$Week$dateAtWeekday, workerVacationWeek.ao, weekday);
				return $author$project$Language$dateToWeekdayFinAbbreviation(date) + (' ' + A2($justinmimbs$date$Date$format, 'd.M.y', date));
			} else {
				return 'viikko ' + $author$project$Types$Week$toString(workerVacationWeek.ao);
			}
		}();
		var workerName = A2(
			$elm$core$Maybe$withDefault,
			'TUNTEMATON TYÖNTEKIJÄ',
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.mD;
				},
				A2(
					$author$project$Helpers$firstWhere,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.er;
						},
						$elm$core$Basics$eq(workerVacationWeek.jy)),
					workers)));
		return workerName + (' - ' + (timePeriod + (' - ' + (function () {
			if (vacationStateMode === 1) {
				return 'toteutunut loma';
			} else {
				return 'lomatoive';
			}
		}() + (' ' + function () {
			if (!updateOperationType.$) {
				return 'lisätty';
			} else {
				return 'peruttu';
			}
		}())))));
	});
var $author$project$Pages$Holidays$State_Loaded$NoOp = {$: 13};
var $author$project$Pages$Holidays$State_Loaded$update_askForPin = function (model) {
	return _Utils_Tuple3(
		_Utils_update(
			model,
			{
				aW: $elm$core$Maybe$Just(_List_Nil)
			}),
		A2(
			$elm$core$Task$attempt,
			function (_v0) {
				return $author$project$Pages$Holidays$State_Loaded$NoOp;
			},
			$elm$browser$Browser$Dom$focus('reporting-pin-input')),
		$author$project$Pages$Holidays$State_Loaded$OutNoOp);
};
var $author$project$Pages$Holidays$State_Loaded$GotVacationWeekUpdateReponse = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Pages$Holidays$State_Loaded$SetAsWorkingTime = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $author$project$Types$WorkerVacationWeek$dates = function (_v0) {
	var week = _v0.ao;
	return A2(
		$elm$core$List$map,
		$author$project$Types$Week$dateAtWeekday(week),
		$author$project$Types$WorkerVacationWeek$possibleVacationWeekdays);
};
var $author$project$Types$VacationState$jsonEncoder = function (vacationState) {
	return $elm$json$Json$Encode$string(
		function () {
			if (vacationState === 1) {
				return 'CONFIRMED';
			} else {
				return 'REQUEST';
			}
		}());
};
var $justinmimbs$date$Date$toIsoString = $justinmimbs$date$Date$format('yyyy-MM-dd');
var $author$project$Types$VacationDay$removeDatesCmd = F5(
	function (_v0, responseHandler, vacationState, workerId, dates) {
		var apiUrl = _v0.j0;
		var token = _v0.oK;
		return A4(
			$author$project$JwtHttp$post,
			token,
			apiUrl + ('/vacations' + '/remove'),
			$elm$http$Http$jsonBody(
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'workerId',
							$elm$json$Json$Encode$int(workerId)),
							_Utils_Tuple2(
							'state',
							$author$project$Types$VacationState$jsonEncoder(vacationState)),
							_Utils_Tuple2(
							'dates',
							A2(
								$elm$json$Json$Encode$list,
								$elm$json$Json$Encode$string,
								A2($elm$core$List$map, $justinmimbs$date$Date$toIsoString, dates)))
						]))),
			A2($elm$http$Http$expectJson, responseHandler, $author$project$Data$ServerResponse$serverResponseDecoder));
	});
var $author$project$Types$VacationDay$removeVacationConfirmation = function (vacationDay) {
	return _Utils_update(
		vacationDay,
		{ek: false});
};
var $author$project$Types$VacationDay$removeVacationRequest = function (vacationDay) {
	return _Utils_update(
		vacationDay,
		{el: false});
};
var $author$project$Types$VacationDay$removeVacation = F2(
	function (vacationState, vacationDay) {
		return function () {
			if (vacationState === 1) {
				return $author$project$Types$VacationDay$removeVacationConfirmation;
			} else {
				return $author$project$Types$VacationDay$removeVacationRequest;
			}
		}()(vacationDay);
	});
var $author$project$Types$WorkerVacationWeek$toFullWorkWeek = F2(
	function (vacationState, workerVacationWeek) {
		return _Utils_update(
			workerVacationWeek,
			{
				hw: A2($author$project$Types$VacationDay$removeVacation, vacationState, workerVacationWeek.hw),
				h7: A2($author$project$Types$VacationDay$removeVacation, vacationState, workerVacationWeek.h7),
				iS: A2($author$project$Types$VacationDay$removeVacation, vacationState, workerVacationWeek.iS),
				ji: A2($author$project$Types$VacationDay$removeVacation, vacationState, workerVacationWeek.ji),
				jq: A2($author$project$Types$VacationDay$removeVacation, vacationState, workerVacationWeek.jq),
				ju: A2($author$project$Types$VacationDay$removeVacation, vacationState, workerVacationWeek.ju)
			});
	});
var $author$project$Pages$Holidays$State_Loaded$update_removeVacationWeek = F4(
	function (serverContext, model, vacationState, workerVacationWeek) {
		return _Utils_Tuple3(
			_Utils_update(
				model,
				{a4: true}),
			A5(
				$author$project$Types$VacationDay$removeDatesCmd,
				serverContext,
				$author$project$Pages$Holidays$State_Loaded$GotVacationWeekUpdateReponse(
					A3(
						$author$project$Pages$Holidays$State_Loaded$SetAsWorkingTime,
						A2($author$project$Types$WorkerVacationWeek$toFullWorkWeek, model.C, workerVacationWeek),
						model.C,
						$elm$core$Maybe$Nothing)),
				vacationState,
				workerVacationWeek.jy,
				$author$project$Types$WorkerVacationWeek$dates(workerVacationWeek)),
			$author$project$Pages$Holidays$State_Loaded$OutNoOp);
	});
var $author$project$Pages$Holidays$State_Loaded$SetAsVacationTime = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $author$project$Types$VacationDay$createDatesCmd = F5(
	function (_v0, responseHandler, vacationState, workerId, dates) {
		var apiUrl = _v0.j0;
		var token = _v0.oK;
		return A4(
			$author$project$JwtHttp$post,
			token,
			apiUrl + ('/vacations' + '/create'),
			$elm$http$Http$jsonBody(
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'workerId',
							$elm$json$Json$Encode$int(workerId)),
							_Utils_Tuple2(
							'state',
							$author$project$Types$VacationState$jsonEncoder(vacationState)),
							_Utils_Tuple2(
							'dates',
							A2(
								$elm$json$Json$Encode$list,
								$elm$json$Json$Encode$string,
								A2($elm$core$List$map, $justinmimbs$date$Date$toIsoString, dates)))
						]))),
			A2($elm$http$Http$expectJson, responseHandler, $author$project$Data$ServerResponse$serverResponseDecoder));
	});
var $author$project$Types$VacationDay$addVacationConfirmation = function (vacationDay) {
	return _Utils_update(
		vacationDay,
		{ek: true});
};
var $author$project$Types$VacationDay$addVacationRequest = function (vacationDay) {
	return _Utils_update(
		vacationDay,
		{el: true});
};
var $author$project$Types$VacationDay$addVacation = F2(
	function (vacationState, vacationDay) {
		return function () {
			if (vacationState === 1) {
				return $author$project$Types$VacationDay$addVacationConfirmation;
			} else {
				return $author$project$Types$VacationDay$addVacationRequest;
			}
		}()(vacationDay);
	});
var $author$project$Types$WorkerVacationWeek$setAsVacationDays = F3(
	function (weekdays, vacationState, workerVacationWeek) {
		return A3(
			$elm$core$List$foldl,
			F2(
				function (weekday, workerVacationWeek_) {
					switch (weekday) {
						case 0:
							return _Utils_update(
								workerVacationWeek_,
								{
									h7: A2($author$project$Types$VacationDay$addVacation, vacationState, workerVacationWeek_.h7)
								});
						case 1:
							return _Utils_update(
								workerVacationWeek_,
								{
									jq: A2($author$project$Types$VacationDay$addVacation, vacationState, workerVacationWeek_.jq)
								});
						case 2:
							return _Utils_update(
								workerVacationWeek_,
								{
									ju: A2($author$project$Types$VacationDay$addVacation, vacationState, workerVacationWeek_.ju)
								});
						case 3:
							return _Utils_update(
								workerVacationWeek_,
								{
									ji: A2($author$project$Types$VacationDay$addVacation, vacationState, workerVacationWeek_.ji)
								});
						case 4:
							return _Utils_update(
								workerVacationWeek_,
								{
									hw: A2($author$project$Types$VacationDay$addVacation, vacationState, workerVacationWeek_.hw)
								});
						case 5:
							return _Utils_update(
								workerVacationWeek_,
								{
									iS: A2($author$project$Types$VacationDay$addVacation, vacationState, workerVacationWeek_.iS)
								});
						default:
							return workerVacationWeek;
					}
				}),
			workerVacationWeek,
			weekdays);
	});
var $author$project$Types$WorkerVacationWeek$toFullVacationWeek = F2(
	function (vacationState, workerVacationWeek) {
		return A3(
			$author$project$Types$WorkerVacationWeek$setAsVacationDays,
			_List_fromArray(
				[0, 1, 2, 3, 4, 5]),
			vacationState,
			workerVacationWeek);
	});
var $author$project$Pages$Holidays$State_Loaded$update_setWeekAsVacation = F4(
	function (serverContext, model, vacationState, workerVacationWeek) {
		return _Utils_Tuple3(
			_Utils_update(
				model,
				{a4: true}),
			A5(
				$author$project$Types$VacationDay$createDatesCmd,
				serverContext,
				$author$project$Pages$Holidays$State_Loaded$GotVacationWeekUpdateReponse(
					A3(
						$author$project$Pages$Holidays$State_Loaded$SetAsVacationTime,
						A2($author$project$Types$WorkerVacationWeek$toFullVacationWeek, vacationState, workerVacationWeek),
						vacationState,
						$elm$core$Maybe$Nothing)),
				vacationState,
				workerVacationWeek.jy,
				$author$project$Types$WorkerVacationWeek$dates(workerVacationWeek)),
			$author$project$Pages$Holidays$State_Loaded$OutNoOp);
	});
var $author$project$Types$WorkerVacationWeek$toggleDayOff = F3(
	function (vacationState, workerVacationWeek, weekday) {
		var toggle = F2(
			function (vacationState_, vacationDay) {
				if (vacationState_ === 1) {
					return _Utils_update(
						vacationDay,
						{ek: !vacationDay.ek});
				} else {
					return _Utils_update(
						vacationDay,
						{el: !vacationDay.el});
				}
			});
		switch (weekday) {
			case 0:
				return _Utils_update(
					workerVacationWeek,
					{
						h7: A2(toggle, vacationState, workerVacationWeek.h7)
					});
			case 1:
				return _Utils_update(
					workerVacationWeek,
					{
						jq: A2(toggle, vacationState, workerVacationWeek.jq)
					});
			case 2:
				return _Utils_update(
					workerVacationWeek,
					{
						ju: A2(toggle, vacationState, workerVacationWeek.ju)
					});
			case 3:
				return _Utils_update(
					workerVacationWeek,
					{
						ji: A2(toggle, vacationState, workerVacationWeek.ji)
					});
			case 4:
				return _Utils_update(
					workerVacationWeek,
					{
						hw: A2(toggle, vacationState, workerVacationWeek.hw)
					});
			case 5:
				return _Utils_update(
					workerVacationWeek,
					{
						iS: A2(toggle, vacationState, workerVacationWeek.iS)
					});
			default:
				return workerVacationWeek;
		}
	});
var $author$project$Pages$Holidays$State_Loaded$update_toggleVacationDay = F5(
	function (serverContext, model, vacationState, workerVacationWeek, weekday) {
		var workerId = workerVacationWeek.jy;
		var clickedDate = A2($author$project$Types$Week$dateAtWeekday, workerVacationWeek.ao, weekday);
		var persistCmd = A3(
			A3($author$project$Types$WorkerVacationWeek$isDayOff, vacationState, workerVacationWeek, weekday) ? A2(
				$author$project$Types$VacationDay$removeDatesCmd,
				serverContext,
				$author$project$Pages$Holidays$State_Loaded$GotVacationWeekUpdateReponse(
					A3(
						$author$project$Pages$Holidays$State_Loaded$SetAsWorkingTime,
						A3($author$project$Types$WorkerVacationWeek$toggleDayOff, vacationState, workerVacationWeek, weekday),
						vacationState,
						$elm$core$Maybe$Just(weekday)))) : A2(
				$author$project$Types$VacationDay$createDatesCmd,
				serverContext,
				$author$project$Pages$Holidays$State_Loaded$GotVacationWeekUpdateReponse(
					A3(
						$author$project$Pages$Holidays$State_Loaded$SetAsVacationTime,
						A3($author$project$Types$WorkerVacationWeek$toggleDayOff, vacationState, workerVacationWeek, weekday),
						vacationState,
						$elm$core$Maybe$Just(weekday)))),
			vacationState,
			workerId,
			_List_fromArray(
				[clickedDate]));
		return _Utils_Tuple3(
			_Utils_update(
				model,
				{a4: true}),
			persistCmd,
			$author$project$Pages$Holidays$State_Loaded$OutNoOp);
	});
var $author$project$Pages$Holidays$State_Loaded$Expanded = 1;
var $author$project$Pages$Holidays$State_Loaded$toggleColumnWidth = F2(
	function (columnStates, week) {
		var columnState_ = function (previousState) {
			if (!previousState) {
				return 1;
			} else {
				return 0;
			}
		}(
			A2(
				$elm$core$Maybe$withDefault,
				0,
				A2($elm$core$Dict$get, week.o$, columnStates)));
		return A3($elm$core$Dict$insert, week.o$, columnState_, columnStates);
	});
var $author$project$Pages$Holidays$State_Loaded$update_toggleWeekColumnWidth = F2(
	function (model, week) {
		return _Utils_Tuple3(
			_Utils_update(
				model,
				{
					b1: A2($author$project$Pages$Holidays$State_Loaded$toggleColumnWidth, model.b1, week)
				}),
			$elm$core$Platform$Cmd$none,
			$author$project$Pages$Holidays$State_Loaded$OutNoOp);
	});
var $author$project$Pages$Holidays$State_Loaded$update = F3(
	function (_v0, msg, model) {
		var globalPin = _v0.lA;
		var serverContext = _v0.n1;
		switch (msg.$) {
			case 12:
				var passedMs = msg.a;
				var editPermissions_ = function () {
					var _v2 = model.aw;
					if (!_v2.$) {
						return $author$project$Pages$Holidays$State_Loaded$CannotEditAnything;
					} else {
						var pinValidForMs = _v2.a.cn;
						return function (pinValidForMs_) {
							return $author$project$Pages$Holidays$State_Loaded$CanEdit(
								{cn: pinValidForMs_});
						}(
							A2(
								$elm$core$Maybe$andThen,
								function (remainingMs) {
									return (remainingMs > 0) ? $elm$core$Maybe$Just(remainingMs) : $elm$core$Maybe$Nothing;
								},
								A2(
									$elm$core$Maybe$map,
									function (ms) {
										return ms - passedMs;
									},
									pinValidForMs)));
					}
				}();
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aw: editPermissions_}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Holidays$State_Loaded$OutNoOp);
			case 9:
				var currentPin = msg.a;
				var updatedPin = $elm$core$List$reverse(
					A2(
						$elm$core$List$drop,
						1,
						$elm$core$List$reverse(currentPin)));
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							aW: $elm$core$Maybe$Just(updatedPin)
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Holidays$State_Loaded$OutNoOp);
			case 10:
				var currentPin = msg.a;
				var newNumber = msg.b;
				var updatedPin = _Utils_ap(
					currentPin,
					_List_fromArray(
						[newNumber]));
				var currentPinString = $elm$core$String$concat(
					A2($elm$core$List$map, $elm$core$String$fromInt, updatedPin));
				return _Utils_eq(currentPinString, globalPin) ? _Utils_Tuple3(
					_Utils_update(
						model,
						{
							aW: $elm$core$Maybe$Nothing,
							aw: $author$project$Pages$Holidays$State_Loaded$CanEdit(
								{
									cn: $elm$core$Maybe$Just($author$project$Components$PinDialog$pinValidForMs)
								})
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Holidays$State_Loaded$OutNotification(
						$author$project$Components$PinDialog$featureUsableNotification('Toteutuneiden lomien muokkaus'))) : _Utils_Tuple3(
					_Utils_update(
						model,
						{
							aW: $elm$core$Maybe$Just(updatedPin)
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Holidays$State_Loaded$OutNoOp);
			case 11:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aW: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Holidays$State_Loaded$OutNoOp);
			case 4:
				var vacationState = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{C: vacationState}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Holidays$State_Loaded$OutNoOp);
			case 2:
				var workerVacationWeek = msg.a;
				var _v3 = _Utils_Tuple2(
					A2($author$project$Types$WorkerVacationWeek$hasDaysOff, model.C, workerVacationWeek),
					A2($author$project$Pages$Holidays$State_Loaded$checkEditPermission, model.aw, model.C));
				if (_v3.a) {
					return A2($author$project$Pages$Holidays$State_Loaded$update_toggleWeekColumnWidth, model, workerVacationWeek.ao);
				} else {
					switch (_v3.b) {
						case 0:
							var _v4 = _v3.b;
							return A2($author$project$Pages$Holidays$State_Loaded$update_toggleWeekColumnWidth, model, workerVacationWeek.ao);
						case 1:
							var _v5 = _v3.b;
							return $author$project$Pages$Holidays$State_Loaded$update_askForPin(model);
						default:
							var _v6 = _v3.b;
							return A4($author$project$Pages$Holidays$State_Loaded$update_setWeekAsVacation, serverContext, model, model.C, workerVacationWeek);
					}
				}
			case 1:
				var workerVacationWeek = msg.a;
				var _v7 = A2($author$project$Pages$Holidays$State_Loaded$checkEditPermission, model.aw, model.C);
				switch (_v7) {
					case 0:
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$Holidays$State_Loaded$OutNoOp);
					case 1:
						return $author$project$Pages$Holidays$State_Loaded$update_askForPin(model);
					default:
						return A4($author$project$Pages$Holidays$State_Loaded$update_removeVacationWeek, serverContext, model, model.C, workerVacationWeek);
				}
			case 3:
				var targetYear = msg.a;
				return _Utils_Tuple3(
					A2($author$project$Pages$Holidays$State_Loaded$gotoYear, targetYear, model),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Holidays$State_Loaded$OutNoOp);
			case 6:
				var workerVacationWeek = msg.a;
				var weekday = msg.b;
				var _v8 = A2($author$project$Pages$Holidays$State_Loaded$checkEditPermission, model.aw, model.C);
				switch (_v8) {
					case 0:
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$Holidays$State_Loaded$OutNoOp);
					case 1:
						return $author$project$Pages$Holidays$State_Loaded$update_askForPin(model);
					default:
						return A5($author$project$Pages$Holidays$State_Loaded$update_toggleVacationDay, serverContext, model, model.C, workerVacationWeek, weekday);
				}
			case 0:
				if (!msg.b.$) {
					var updateOperationType = msg.a;
					var serverResponse = msg.b.a;
					if (serverResponse.ok) {
						var workerVacationWeek = function () {
							if (!updateOperationType.$) {
								var vacationWeek_ = updateOperationType.a;
								return vacationWeek_;
							} else {
								var vacationWeek_ = updateOperationType.a;
								return vacationWeek_;
							}
						}();
						var clickedWorkerVacationWeeks_ = A2(
							$elm$core$List$map,
							function (existingVacationWeek) {
								return _Utils_eq(existingVacationWeek.ao, workerVacationWeek.ao) ? _Utils_update(
									workerVacationWeek,
									{hw: workerVacationWeek.hw, h7: workerVacationWeek.h7, iS: workerVacationWeek.iS, ji: workerVacationWeek.ji, jq: workerVacationWeek.jq, ju: workerVacationWeek.ju}) : existingVacationWeek;
							},
							A2(
								$elm$core$Maybe$withDefault,
								_List_Nil,
								A2($elm$core$Dict$get, workerVacationWeek.jy, model.al)));
						var vacationWeeksByWorker_ = A3($elm$core$Dict$insert, workerVacationWeek.jy, clickedWorkerVacationWeeks_, model.al);
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{a4: false, al: vacationWeeksByWorker_}),
							$elm$core$Platform$Cmd$none,
							$author$project$Pages$Holidays$State_Loaded$OutNotification(
								A2(
									$author$project$Components$Notification$Notification,
									A2($author$project$Pages$Holidays$State_Loaded$updateOperationNotification, model.jA, updateOperationType),
									1)));
					} else {
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{a4: false}),
							$elm$core$Platform$Cmd$none,
							$author$project$Pages$Holidays$State_Loaded$OutNotification(
								A2($author$project$Components$Notification$Notification, 'Virhe muokattaessa lomia.', 1)));
					}
				} else {
					var errorMessage = msg.b.a;
					if ((errorMessage.$ === 3) && (errorMessage.a === 401)) {
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$Holidays$State_Loaded$OutGotAuthError);
					} else {
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{a4: false}),
							$elm$core$Platform$Cmd$none,
							$author$project$Pages$Holidays$State_Loaded$OutNotification(
								A2($author$project$Components$Notification$Notification, 'Virhe muokattaessa lomia.', 1)));
					}
				}
			case 5:
				var week = msg.a;
				return A2($author$project$Pages$Holidays$State_Loaded$update_toggleWeekColumnWidth, model, week);
			case 7:
				var hoveredWeek = msg.a;
				var hoveredWorkerId = msg.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							c6: $elm$core$Maybe$Just(
								_Utils_Tuple2(hoveredWeek, hoveredWorkerId))
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Holidays$State_Loaded$OutNoOp);
			case 8:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{c6: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Holidays$State_Loaded$OutNoOp);
			default:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$Holidays$State_Loaded$OutNoOp);
		}
	});
var $author$project$Pages$Holidays$State_Loading$OutGotAuthError = {$: 1};
var $author$project$Pages$Holidays$State_Loading$OutGotData = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Holidays$State_Loading$OutNotification = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Holidays$State_Loading$update = F2(
	function (msg, model) {
		if (!msg.$) {
			var data = msg.a;
			return _Utils_Tuple3(
				model,
				$elm$core$Platform$Cmd$none,
				$author$project$Pages$Holidays$State_Loading$OutGotData(data));
		} else {
			var errorMessage = msg.a;
			if ((errorMessage.$ === 3) && (errorMessage.a === 401)) {
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$Holidays$State_Loading$OutGotAuthError);
			} else {
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Holidays$State_Loading$OutNotification(
						A2($author$project$Components$Notification$Notification, 'Virhe ladattaessa tietoja.', 1)));
			}
		}
	});
var $author$project$Pages$State_NoAccess$update = F2(
	function (msg, model) {
		return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, 0);
	});
var $author$project$Pages$Holidays$update = F4(
	function (appContext, _v0, msg, model) {
		var _v1 = _Utils_Tuple2(msg, model._);
		_v1$3:
		while (true) {
			switch (_v1.a.$) {
				case 0:
					if (!_v1.b.$) {
						var msg_Loading = _v1.a.a;
						var model_Loading = _v1.b.a;
						return function (_v2) {
							var subModel = _v2.a;
							var subCmd = _v2.b;
							var subOutMsg = _v2.c;
							return A2(
								$elm$core$Maybe$withDefault,
								$author$project$Pages$Holidays$update_loadingState(
									_Utils_Tuple3(subModel, subCmd, subOutMsg)),
								$author$project$Pages$Holidays$maybeTransition_loadingToLoaded(subOutMsg));
						}(
							A2($author$project$Pages$Holidays$State_Loading$update, msg_Loading, model_Loading));
					} else {
						break _v1$3;
					}
				case 1:
					if (_v1.b.$ === 1) {
						var msg_Loaded = _v1.a.a;
						var model_Loaded = _v1.b.a;
						return $author$project$Pages$Holidays$update_loadedState(
							A3($author$project$Pages$Holidays$State_Loaded$update, appContext, msg_Loaded, model_Loaded));
					} else {
						break _v1$3;
					}
				default:
					if (_v1.b.$ === 2) {
						var subMsg = _v1.a.a;
						var subModel = _v1.b.a;
						return $author$project$Pages$Holidays$update_noAccessState(
							A2($author$project$Pages$State_NoAccess$update, subMsg, subModel));
					} else {
						break _v1$3;
					}
			}
		}
		return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$Holidays$OutNoOp);
	});
var $author$project$Pages$Invoicing$ChangeDeliveryDatesDialog = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Invoicing$ChangeInvoiceDatesDialog = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Invoicing$GotDeliveryDatePickerMsg = function (a) {
	return {$: 15, a: a};
};
var $author$project$Pages$Invoicing$GotInvoiceDatePickerMsg = function (a) {
	return {$: 16, a: a};
};
var $author$project$Pages$Invoicing$NoOp = {$: 14};
var $author$project$Pages$Invoicing$OutGotAuthError = {$: 1};
var $author$project$Pages$Invoicing$OutNoOp = {$: 2};
var $author$project$Pages$Invoicing$OutNotification = function (a) {
	return {$: 0, a: a};
};
var $author$project$Helpers$entityListToDict = function (entityList) {
	return $elm$core$Dict$fromList(
		A2(
			$elm$core$List$map,
			function (entity) {
				return _Utils_Tuple2(entity.er, entity);
			},
			entityList));
};
var $elm$core$Dict$filter = F2(
	function (isGood, dict) {
		return A3(
			$elm$core$Dict$foldl,
			F3(
				function (k, v, d) {
					return A2(isGood, k, v) ? A3($elm$core$Dict$insert, k, v, d) : d;
				}),
			$elm$core$Dict$empty,
			dict);
	});
var $author$project$Pages$Invoicing$handleHttpError = F2(
	function (model, httpError) {
		if ((httpError.$ === 3) && (httpError.a === 401)) {
			return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$Invoicing$OutGotAuthError);
		} else {
			return _Utils_Tuple3(
				model,
				$elm$core$Platform$Cmd$none,
				$author$project$Pages$Invoicing$OutNotification(
					A2($author$project$Components$Notification$Notification, 'Palvelinvirhe', 1)));
		}
	});
var $author$project$Components$DatePicker$NoOp = {$: 4};
var $author$project$Components$DatePicker$SetToday = function (a) {
	return {$: 3, a: a};
};
var $fabhof$elm_ui_datepicker$DatePicker$Model = $elm$core$Basics$identity;
var $justinmimbs$date$Date$fromOrdinalDate = F2(
	function (y, od) {
		var daysInY = $justinmimbs$date$Date$isLeapYear(y) ? 366 : 365;
		return $justinmimbs$date$Date$daysBeforeYear(y) + A3($elm$core$Basics$clamp, 1, daysInY, od);
	});
var $fabhof$elm_ui_datepicker$DatePicker$init = {
	nh: false,
	dE: A2($justinmimbs$date$Date$fromOrdinalDate, 1, 1),
	an: A2($justinmimbs$date$Date$fromOrdinalDate, 1, 1)
};
var $fabhof$elm_ui_datepicker$DatePicker$open = function (_v0) {
	var picker = _v0;
	return _Utils_update(
		picker,
		{nh: true});
};
var $author$project$Components$DatePicker$init = F4(
	function (labelText, selectedDate, pickerOnly, allowingSelectingNothing) {
		return _Utils_Tuple2(
			{
				fl: allowingSelectingNothing,
				b4: selectedDate,
				cU: '',
				fV: labelText,
				bD: $fabhof$elm_ui_datepicker$DatePicker$open($fabhof$elm_ui_datepicker$DatePicker$init),
				eN: pickerOnly
			},
			$elm$core$Platform$Cmd$batch(
				A2(
					$elm$core$List$cons,
					A2($elm$core$Task$perform, $author$project$Components$DatePicker$SetToday, $justinmimbs$date$Date$today),
					(!pickerOnly) ? _List_fromArray(
						[
							A2(
							$elm$core$Task$attempt,
							function (_v0) {
								return $author$project$Components$DatePicker$NoOp;
							},
							$elm$browser$Browser$Dom$focus('date-input'))
						]) : _List_Nil)));
	});
var $author$project$Pages$Invoicing$initDatePicker = A4($author$project$Components$DatePicker$init, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, true, false);
var $author$project$Pages$Invoicing$GotInvoiceQueuedTasklists = function (a) {
	return {$: 7, a: a};
};
var $author$project$Encode$idListEncoder = function (ids) {
	return A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$int, ids);
};
var $author$project$Pages$Invoicing$invoiceTasklistsCmd = F2(
	function (_v0, tasklistIds) {
		var apiUrl = _v0.j0;
		var token = _v0.oK;
		var body = $elm$http$Http$jsonBody(
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tasklistIds',
						$author$project$Encode$idListEncoder(tasklistIds))
					])));
		return A4(
			$author$project$JwtHttp$post,
			token,
			apiUrl + '/tasklists/invoice-queue',
			body,
			A2(
				$elm$http$Http$expectJson,
				$author$project$Pages$Invoicing$GotInvoiceQueuedTasklists,
				$elm$json$Json$Decode$list($author$project$Data$Tasklists$tasklistDecoder)));
	});
var $elm$core$Dict$isEmpty = function (dict) {
	if (dict.$ === -2) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Pages$Invoicing$TasklistReopened = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Invoicing$reopenTasklistCmd = F2(
	function (_v0, tasklist) {
		var apiUrl = _v0.j0;
		var token = _v0.oK;
		return A4(
			$author$project$JwtHttp$post,
			token,
			apiUrl + ('/tasklists/' + ($elm$core$String$fromInt(tasklist.er) + '/reopen')),
			$elm$http$Http$emptyBody,
			A2($elm$http$Http$expectJson, $author$project$Pages$Invoicing$TasklistReopened, $author$project$Data$Tasklists$tasklistDecoder));
	});
var $elm$core$Dict$sizeHelp = F2(
	function (n, dict) {
		sizeHelp:
		while (true) {
			if (dict.$ === -2) {
				return n;
			} else {
				var left = dict.d;
				var right = dict.e;
				var $temp$n = A2($elm$core$Dict$sizeHelp, n + 1, right),
					$temp$dict = left;
				n = $temp$n;
				dict = $temp$dict;
				continue sizeHelp;
			}
		}
	});
var $elm$core$Dict$size = function (dict) {
	return A2($elm$core$Dict$sizeHelp, 0, dict);
};
var $author$project$Components$DatePicker$OutCancel = {$: 2};
var $author$project$Components$DatePicker$OutDateSelected = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$DatePicker$OutNoOp = {$: 0};
var $fabhof$elm_ui_datepicker$DatePicker$setToday = F2(
	function (today, _v0) {
		var picker = _v0;
		return _Utils_update(
			picker,
			{dE: today, an: today});
	});
var $fabhof$elm_ui_datepicker$DatePicker$close = function (_v0) {
	var picker = _v0;
	return _Utils_update(
		picker,
		{nh: false});
};
var $fabhof$elm_ui_datepicker$DatePicker$update = F2(
	function (msg, _v0) {
		var picker = _v0;
		switch (msg.$) {
			case 0:
				var month = msg.a;
				return _Utils_update(
					picker,
					{an: month});
			case 1:
				return $fabhof$elm_ui_datepicker$DatePicker$open(picker);
			case 2:
				return $fabhof$elm_ui_datepicker$DatePicker$close(picker);
			default:
				return picker;
		}
	});
var $author$project$Components$DatePicker$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var changeEvent = msg.a;
				switch (changeEvent.$) {
					case 0:
						var date = changeEvent.a;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									b4: $elm$core$Maybe$Just(date),
									cU: $justinmimbs$date$Date$toIsoString(date)
								}),
							$elm$core$Platform$Cmd$none,
							$author$project$Components$DatePicker$OutDateSelected(
								$elm$core$Maybe$Just(date)));
					case 1:
						var text = changeEvent.a;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									b4: function () {
										var _v2 = $justinmimbs$date$Date$fromIsoString(text);
										if (!_v2.$) {
											var newDate = _v2.a;
											return $elm$core$Maybe$Just(newDate);
										} else {
											return model.b4;
										}
									}(),
									cU: text
								}),
							$elm$core$Platform$Cmd$none,
							$author$project$Components$DatePicker$OutNoOp);
					default:
						var subMsg = changeEvent.a;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									bD: A2($fabhof$elm_ui_datepicker$DatePicker$update, subMsg, model.bD)
								}),
							$elm$core$Platform$Cmd$none,
							$author$project$Components$DatePicker$OutNoOp);
				}
			case 1:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					$author$project$Components$DatePicker$OutDateSelected($elm$core$Maybe$Nothing));
			case 3:
				var today = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							bD: A2($fabhof$elm_ui_datepicker$DatePicker$setToday, today, model.bD)
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Components$DatePicker$OutNoOp);
			case 2:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Components$DatePicker$OutCancel);
			default:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Components$DatePicker$OutNoOp);
		}
	});
var $author$project$Pages$Invoicing$GotClosedTasklists = function (a) {
	return {$: 6, a: a};
};
var $author$project$Encode$dateEncoder = function (maybeDate) {
	if (!maybeDate.$) {
		var date = maybeDate.a;
		return $elm$json$Json$Encode$string(
			$justinmimbs$date$Date$toIsoString(date));
	} else {
		return $elm$json$Json$Encode$null;
	}
};
var $author$project$Pages$Invoicing$updateDeliveryDatesCmd = F3(
	function (_v0, date, tasklistIds) {
		var apiUrl = _v0.j0;
		var token = _v0.oK;
		var body = $elm$http$Http$jsonBody(
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'deliveryOn',
						$author$project$Encode$dateEncoder(
							$elm$core$Maybe$Just(date))),
						_Utils_Tuple2(
						'tasklistIds',
						$author$project$Encode$idListEncoder(tasklistIds))
					])));
		return A4(
			$author$project$JwtHttp$post,
			token,
			apiUrl + '/tasklists/set-delivery-dates',
			body,
			A2(
				$elm$http$Http$expectJson,
				$author$project$Pages$Invoicing$GotClosedTasklists,
				$elm$json$Json$Decode$list($author$project$Data$Tasklists$tasklistDecoder)));
	});
var $author$project$Pages$Invoicing$updateInvoiceDatesCmd = F3(
	function (_v0, date, tasklistIds) {
		var apiUrl = _v0.j0;
		var token = _v0.oK;
		var body = $elm$http$Http$jsonBody(
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'invoiceOn',
						$author$project$Encode$dateEncoder(
							$elm$core$Maybe$Just(date))),
						_Utils_Tuple2(
						'tasklistIds',
						$author$project$Encode$idListEncoder(tasklistIds))
					])));
		return A4(
			$author$project$JwtHttp$post,
			token,
			apiUrl + '/tasklists/set-invoice-dates',
			body,
			A2(
				$elm$http$Http$expectJson,
				$author$project$Pages$Invoicing$GotClosedTasklists,
				$elm$json$Json$Decode$list($author$project$Data$Tasklists$tasklistDecoder)));
	});
var $author$project$Pages$Invoicing$update = F3(
	function (appContext, msg, model) {
		switch (msg.$) {
			case 0:
				var tab = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{cS: tab}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Invoicing$OutNoOp);
			case 6:
				if (!msg.a.$) {
					var tasklists = msg.a.a;
					var tasklistDict = $elm$core$Dict$fromList(
						A2(
							$elm$core$List$map,
							function (tl) {
								return _Utils_Tuple2(tl.er, tl);
							},
							tasklists));
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{r: tasklistDict}),
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Invoicing$OutNoOp);
				} else {
					var httpError = msg.a.a;
					return A2($author$project$Pages$Invoicing$handleHttpError, model, httpError);
				}
			case 7:
				if (!msg.a.$) {
					var invoiceQueuedTasklists = msg.a.a;
					var newInvoiceQueued = $elm$core$Dict$fromList(
						A2(
							$elm$core$List$map,
							function (tl) {
								return _Utils_Tuple2(tl.er, tl);
							},
							invoiceQueuedTasklists));
					var newSelected = A2(
						$elm$core$Dict$filter,
						F2(
							function (id, _v2) {
								return !A2($elm$core$Dict$member, id, newInvoiceQueued);
							}),
						model.w);
					var newClosed = A2(
						$elm$core$Dict$filter,
						F2(
							function (id, _v1) {
								return !A2($elm$core$Dict$member, id, newInvoiceQueued);
							}),
						model.r);
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{r: newClosed, U: newInvoiceQueued, w: newSelected}),
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Invoicing$OutNoOp);
				} else {
					var httpError = msg.a.a;
					return A2($author$project$Pages$Invoicing$handleHttpError, model, httpError);
				}
			case 5:
				if (!msg.a.$) {
					var data = msg.a.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								r: $author$project$Helpers$entityListToDict(data.r),
								ap: data.ap,
								U: $author$project$Helpers$entityListToDict(data.U),
								ae: $author$project$Helpers$entityListToDict(data.ae),
								iA: $author$project$Helpers$entityListToDict(data.iA),
								jA: $author$project$Helpers$entityListToDict(data.jA)
							}),
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Invoicing$OutNoOp);
				} else {
					var errMsg = msg.a.a;
					if ((errMsg.$ === 3) && (errMsg.a === 401)) {
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$Invoicing$OutGotAuthError);
					} else {
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									ea: $elm$core$Maybe$Just('Palvelinvirhe')
								}),
							$elm$core$Platform$Cmd$none,
							$author$project$Pages$Invoicing$OutNoOp);
					}
				}
			case 4:
				if (!msg.a.$) {
					var reopenedTasklist = msg.a.a;
					var newClosedTasklists = A2($elm$core$Dict$remove, reopenedTasklist.er, model.r);
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{r: newClosedTasklists}),
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Invoicing$OutNoOp);
				} else {
					var httpError = msg.a.a;
					return A2($author$project$Pages$Invoicing$handleHttpError, model, httpError);
				}
			case 1:
				var tasklist = msg.a;
				var bool = msg.b;
				var newSelectedTasklists = bool ? A3($elm$core$Dict$insert, tasklist.er, tasklist, model.w) : A2($elm$core$Dict$remove, tasklist.er, model.w);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{w: newSelectedTasklists}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Invoicing$OutNoOp);
			case 2:
				var areAllSelected = _Utils_eq(
					$elm$core$Dict$size(model.w),
					$elm$core$Dict$size(model.r));
				var newSelectedTasklists = areAllSelected ? $elm$core$Dict$empty : model.r;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{w: newSelectedTasklists}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Invoicing$OutNoOp);
			case 3:
				var tasklist = msg.a;
				return _Utils_Tuple3(
					model,
					A2($author$project$Pages$Invoicing$reopenTasklistCmd, appContext, tasklist),
					$author$project$Pages$Invoicing$OutNoOp);
			case 8:
				if ($elm$core$Dict$isEmpty(model.w)) {
					return _Utils_Tuple3(
						model,
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Invoicing$OutNotification(
							A2($author$project$Components$Notification$Notification, 'Ei valittuja lomakkeita', 1)));
				} else {
					var selectedTasklistIds = A2(
						$elm$core$List$map,
						function ($) {
							return $.er;
						},
						$elm$core$Dict$values(model.w));
					return _Utils_Tuple3(
						model,
						A2($author$project$Pages$Invoicing$invoiceTasklistsCmd, appContext, selectedTasklistIds),
						$author$project$Pages$Invoicing$OutNoOp);
				}
			case 9:
				var date = msg.a;
				if ($elm$core$Dict$isEmpty(model.w)) {
					return _Utils_Tuple3(
						model,
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Invoicing$OutNotification(
							A2($author$project$Components$Notification$Notification, 'Ei valittuja lomakkeita', 1)));
				} else {
					var selectedTasklistIds = A2(
						$elm$core$List$map,
						function ($) {
							return $.er;
						},
						$elm$core$Dict$values(model.w));
					return _Utils_Tuple3(
						model,
						A3($author$project$Pages$Invoicing$updateDeliveryDatesCmd, appContext, date, selectedTasklistIds),
						$author$project$Pages$Invoicing$OutNoOp);
				}
			case 10:
				var tasklist = msg.a;
				return _Utils_Tuple3(
					model,
					A2(
						$author$project$Pages$Invoicing$invoiceTasklistsCmd,
						appContext,
						_List_fromArray(
							[tasklist.er])),
					$author$project$Pages$Invoicing$OutNoOp);
			case 11:
				if ($elm$core$Dict$isEmpty(model.w)) {
					return _Utils_Tuple3(
						model,
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Invoicing$OutNotification(
							A2($author$project$Components$Notification$Notification, 'Ei valittuja lomakkeita', 1)));
				} else {
					var _v4 = $author$project$Pages$Invoicing$initDatePicker;
					var datePickerModel = _v4.a;
					var datePickerCmd = _v4.b;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								dj: $author$project$Pages$Invoicing$ChangeDeliveryDatesDialog(datePickerModel)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2(
									$elm$core$Task$attempt,
									function (_v5) {
										return $author$project$Pages$Invoicing$NoOp;
									},
									$author$project$Components$CloseButton$focusOnMe),
									A2($elm$core$Platform$Cmd$map, $author$project$Pages$Invoicing$GotDeliveryDatePickerMsg, datePickerCmd)
								])),
						$author$project$Pages$Invoicing$OutNoOp);
				}
			case 12:
				if ($elm$core$Dict$isEmpty(model.w)) {
					return _Utils_Tuple3(
						model,
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Invoicing$OutNotification(
							A2($author$project$Components$Notification$Notification, 'Ei valittuja lomakkeita', 1)));
				} else {
					var _v6 = $author$project$Pages$Invoicing$initDatePicker;
					var datePickerModel = _v6.a;
					var datePickerCmd = _v6.b;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								dj: $author$project$Pages$Invoicing$ChangeInvoiceDatesDialog(datePickerModel)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2(
									$elm$core$Task$attempt,
									function (_v7) {
										return $author$project$Pages$Invoicing$NoOp;
									},
									$author$project$Components$CloseButton$focusOnMe),
									A2($elm$core$Platform$Cmd$map, $author$project$Pages$Invoicing$GotInvoiceDatePickerMsg, datePickerCmd)
								])),
						$author$project$Pages$Invoicing$OutNoOp);
				}
			case 13:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{dj: $author$project$Pages$Invoicing$NoDialog}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Invoicing$OutNoOp);
			case 14:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$Invoicing$OutNoOp);
			case 15:
				var datePickerMsg = msg.a;
				var _v8 = model.dj;
				if (_v8.$ === 1) {
					var datePickerModel = _v8.a;
					var _v9 = A2($author$project$Components$DatePicker$update, datePickerMsg, datePickerModel);
					var newDatePickerModel = _v9.a;
					var datePickerCmd = _v9.b;
					var outMsg = _v9.c;
					switch (outMsg.$) {
						case 0:
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{
										dj: $author$project$Pages$Invoicing$ChangeDeliveryDatesDialog(newDatePickerModel)
									}),
								A2($elm$core$Platform$Cmd$map, $author$project$Pages$Invoicing$GotDeliveryDatePickerMsg, datePickerCmd),
								$author$project$Pages$Invoicing$OutNoOp);
						case 1:
							var maybeDate = outMsg.a;
							return A2(
								$elm$core$Maybe$withDefault,
								_Utils_Tuple3(
									_Utils_update(
										model,
										{dj: $author$project$Pages$Invoicing$NoDialog}),
									$elm$core$Platform$Cmd$none,
									$author$project$Pages$Invoicing$OutNoOp),
								A2(
									$elm$core$Maybe$map,
									function (date) {
										return _Utils_Tuple3(
											_Utils_update(
												model,
												{dj: $author$project$Pages$Invoicing$NoDialog}),
											$elm$core$Platform$Cmd$batch(
												_List_fromArray(
													[
														A2($elm$core$Platform$Cmd$map, $author$project$Pages$Invoicing$GotDeliveryDatePickerMsg, datePickerCmd),
														A3(
														$author$project$Pages$Invoicing$updateDeliveryDatesCmd,
														appContext,
														date,
														$elm$core$Dict$keys(model.w))
													])),
											$author$project$Pages$Invoicing$OutNoOp);
									},
									maybeDate));
						default:
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{dj: $author$project$Pages$Invoicing$NoDialog}),
								A2($elm$core$Platform$Cmd$map, $author$project$Pages$Invoicing$GotDeliveryDatePickerMsg, datePickerCmd),
								$author$project$Pages$Invoicing$OutNoOp);
					}
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$Invoicing$OutNoOp);
				}
			default:
				var datePickerMsg = msg.a;
				var _v11 = model.dj;
				if (_v11.$ === 2) {
					var datePickerModel = _v11.a;
					var _v12 = A2($author$project$Components$DatePicker$update, datePickerMsg, datePickerModel);
					var newDatePickerModel = _v12.a;
					var datePickerCmd = _v12.b;
					var outMsg = _v12.c;
					switch (outMsg.$) {
						case 0:
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{
										dj: $author$project$Pages$Invoicing$ChangeInvoiceDatesDialog(newDatePickerModel)
									}),
								A2($elm$core$Platform$Cmd$map, $author$project$Pages$Invoicing$GotInvoiceDatePickerMsg, datePickerCmd),
								$author$project$Pages$Invoicing$OutNoOp);
						case 1:
							var maybeDate = outMsg.a;
							return A2(
								$elm$core$Maybe$withDefault,
								_Utils_Tuple3(
									_Utils_update(
										model,
										{dj: $author$project$Pages$Invoicing$NoDialog}),
									$elm$core$Platform$Cmd$none,
									$author$project$Pages$Invoicing$OutNoOp),
								A2(
									$elm$core$Maybe$map,
									function (date) {
										return _Utils_Tuple3(
											_Utils_update(
												model,
												{dj: $author$project$Pages$Invoicing$NoDialog}),
											$elm$core$Platform$Cmd$batch(
												_List_fromArray(
													[
														A2($elm$core$Platform$Cmd$map, $author$project$Pages$Invoicing$GotInvoiceDatePickerMsg, datePickerCmd),
														A3(
														$author$project$Pages$Invoicing$updateInvoiceDatesCmd,
														appContext,
														date,
														$elm$core$Dict$keys(model.w))
													])),
											$author$project$Pages$Invoicing$OutNoOp);
									},
									maybeDate));
						default:
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{dj: $author$project$Pages$Invoicing$NoDialog}),
								A2($elm$core$Platform$Cmd$map, $author$project$Pages$Invoicing$GotInvoiceDatePickerMsg, datePickerCmd),
								$author$project$Pages$Invoicing$OutNoOp);
					}
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$Invoicing$OutNoOp);
				}
		}
	});
var $author$project$Pages$Reporting$OutNoOp = {$: 0};
var $author$project$Pages$Reporting$OutNotification = function (a) {
	return {$: 1, a: a};
};
var $elm$core$String$dropRight = F2(
	function (n, string) {
		return (n < 1) ? string : A3($elm$core$String$slice, 0, -n, string);
	});
var $author$project$Pages$Reporting$update_AskingPin = F4(
	function (appContext, selectedUser, msg, model) {
		switch (msg.$) {
			case 0:
				var updatedPin = A2($elm$core$String$dropRight, 1, model.bn);
				return _Utils_Tuple3(
					{
						_: $author$project$Pages$Reporting$AskingPin(
							_Utils_update(
								model,
								{bn: updatedPin}))
					},
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Reporting$OutNoOp);
			case 1:
				var number = msg.a;
				var updatedPin = _Utils_ap(
					model.bn,
					$elm$core$String$fromInt(number));
				if (_Utils_eq(updatedPin, appContext.lA)) {
					var _v1 = A2($author$project$Pages$Reporting$init_PinOk, appContext, selectedUser);
					var updatedModel = _v1.a;
					var cmd = _v1.b;
					return _Utils_Tuple3(
						updatedModel,
						cmd,
						$author$project$Pages$Reporting$OutNotification(
							$author$project$Components$PinDialog$featureUsableNotification('Raportointisivu')));
				} else {
					return _Utils_Tuple3(
						{
							_: $author$project$Pages$Reporting$AskingPin(
								_Utils_update(
									model,
									{bn: updatedPin}))
						},
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Reporting$OutNoOp);
				}
			default:
				return _Utils_Tuple3(
					{
						_: $author$project$Pages$Reporting$AskingPin(model)
					},
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Reporting$OutNoOp);
		}
	});
var $author$project$Pages$Reporting$GotExcelFile = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$PinDialog$enterPinAgainNotification = A2($author$project$Components$Notification$Notification, 'Syötä PIN uudelleen.', 1);
var $author$project$Pages$Reporting$askPinAgain = F2(
	function (appContext, user) {
		var _v0 = A2($author$project$Pages$Reporting$init, appContext, user);
		var model_init = _v0.a;
		var cmd_init = _v0.b;
		return _Utils_Tuple3(
			model_init,
			cmd_init,
			$author$project$Pages$Reporting$OutNotification($author$project$Components$PinDialog$enterPinAgainNotification));
	});
var $author$project$Pages$Reporting$doNothing = function (state) {
	return _Utils_Tuple3(
		{_: state},
		$elm$core$Platform$Cmd$none,
		$author$project$Pages$Reporting$OutNoOp);
};
var $elm$http$Http$expectBytesResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'arraybuffer',
			_Http_toDataView,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $author$project$Types$ExcelFile$parseFilenameFromHttpHeaders = function (headers) {
	return A3(
		$elm$core$String$replace,
		'%C3%B6',
		'ö',
		A3(
			$elm$core$String$replace,
			'%C3%A4',
			'ä',
			A2(
				$elm$core$Maybe$withDefault,
				'tuntematon.xlsx',
				A2(
					$elm$core$Maybe$andThen,
					A2(
						$elm$core$Basics$composeR,
						$elm$core$String$split('='),
						A2(
							$elm$core$Basics$composeR,
							$elm$core$List$drop(1),
							$elm$core$List$head)),
					A2(
						$elm$core$Maybe$andThen,
						A2(
							$elm$core$Basics$composeR,
							$elm$core$String$split(';'),
							$author$project$Helpers$firstWhere(
								A2(
									$elm$core$Basics$composeR,
									$elm$core$String$trim,
									$elm$core$String$startsWith('filename=')))),
						A2($elm$core$Dict$get, 'content-disposition', headers))))));
};
var $author$project$Types$ExcelFile$resolveExcelFileResponse = F2(
	function (toResult, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 3:
				var metadata = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.od));
			default:
				var metadata = response.a;
				var body = response.b;
				return A2(
					$elm$core$Result$mapError,
					$elm$http$Http$BadBody,
					toResult(
						{
							fv: body,
							ed: $author$project$Types$ExcelFile$parseFilenameFromHttpHeaders(metadata.hF)
						}));
		}
	});
var $author$project$Types$ExcelFile$fetchCmd = F3(
	function (_v0, path, onGotExcelFileFromServer) {
		var apiUrl = _v0.j0;
		var token = _v0.oK;
		return A3(
			$author$project$JwtHttp$get,
			token,
			_Utils_ap(apiUrl, path),
			A2(
				$elm$http$Http$expectBytesResponse,
				onGotExcelFileFromServer,
				$author$project$Types$ExcelFile$resolveExcelFileResponse($elm$core$Result$Ok)));
	});
var $author$project$Pages$Reporting$OutGotAuthError = {$: 2};
var $author$project$Pages$Reporting$handleAuthError = F2(
	function (model, errMsg) {
		if ((errMsg.$ === 3) && (errMsg.a === 401)) {
			return _Utils_Tuple3(
				{
					_: $author$project$Pages$Reporting$PinOk(model)
				},
				$elm$core$Platform$Cmd$none,
				$author$project$Pages$Reporting$OutGotAuthError);
		} else {
			return _Utils_Tuple3(
				{
					_: $author$project$Pages$Reporting$PinOk(
						_Utils_update(
							model,
							{
								fG: $elm$core$Maybe$Just('Palvelinvirhe')
							}))
				},
				$elm$core$Platform$Cmd$none,
				$author$project$Pages$Reporting$OutNoOp);
		}
	});
var $author$project$Types$Site$isActive = function (site) {
	return $author$project$Types$Site$getSiteData(site).bt;
};
var $elm$core$Result$map = F2(
	function (func, ra) {
		if (!ra.$) {
			var a = ra.a;
			return $elm$core$Result$Ok(
				func(a));
		} else {
			var e = ra.a;
			return $elm$core$Result$Err(e);
		}
	});
var $author$project$Types$Site$name = function (site) {
	return $author$project$Types$Site$getSiteData(site).mD;
};
var $author$project$Types$HalfYear$create = F2(
	function (year, half) {
		return {lE: half, fi: year};
	});
var $author$project$Types$HalfYear$forPreviousYear = F2(
	function (half, halfYear) {
		return A2($author$project$Types$HalfYear$create, halfYear.fi - 1, half);
	});
var $author$project$Types$HalfYear$forSameYear = F2(
	function (half, halfYear) {
		return A2($author$project$Types$HalfYear$create, halfYear.fi, half);
	});
var $author$project$Types$HalfYear$isBeforeYear = F2(
	function (year, halfYear) {
		return function (y) {
			return _Utils_cmp(y, year) < 0;
		}(halfYear.fi);
	});
var $author$project$Pages$Reporting$nextHalfYearForSiteReport = F2(
	function (thisYear, halfYear) {
		var stopAfterYear = F2(
			function (year, halfyear2) {
				return A2($author$project$Types$HalfYear$isBeforeYear, year, halfyear2) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(halfyear2);
			});
		return A2(
			stopAfterYear,
			thisYear - 1,
			function () {
				var _v0 = halfYear.lE;
				if (_v0 === 1) {
					return A2($author$project$Types$HalfYear$forSameYear, 0, halfYear);
				} else {
					return A2($author$project$Types$HalfYear$forPreviousYear, 1, halfYear);
				}
			}());
	});
var $elm$file$File$Download$bytes = F3(
	function (name, mime, content) {
		return A2(
			$elm$core$Task$perform,
			$elm$core$Basics$never,
			A3(
				_File_download,
				name,
				mime,
				_File_makeBytesSafeForInternetExplorer(content)));
	});
var $author$project$Types$ExcelFile$saveToDiskCmd = function (excelFile) {
	return A3($elm$file$File$Download$bytes, excelFile.ed, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', excelFile.fv);
};
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $hecrj$html_parser$Html$Parser$Element = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $elm$core$Tuple$mapSecond = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			x,
			func(y));
	});
var $author$project$Utils$HtmlUtil$mapHrefAttribute = F2(
	function (func, x) {
		return (x.a === 'href') ? A2($elm$core$Tuple$mapSecond, func, x) : x;
	});
var $author$project$Utils$HtmlUtil$mapAnchorElement = F2(
	function (func, node) {
		switch (node.$) {
			case 2:
				return node;
			case 0:
				return node;
			default:
				if (node.a === 'a') {
					var attributes = node.b;
					var child_nodes = node.c;
					var mapped = A2(
						$elm$core$List$map,
						$author$project$Utils$HtmlUtil$mapHrefAttribute(func),
						attributes);
					return A3($hecrj$html_parser$Html$Parser$Element, 'a', mapped, child_nodes);
				} else {
					var n = node.a;
					var attributes = node.b;
					var child_nodes = node.c;
					return A3(
						$hecrj$html_parser$Html$Parser$Element,
						n,
						attributes,
						A2(
							$elm$core$List$map,
							$author$project$Utils$HtmlUtil$mapAnchorElement(func),
							child_nodes));
				}
		}
	});
var $hecrj$html_parser$Html$Parser$chompOneOrMore = function (fn) {
	return A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$chompIf(fn),
		$elm$parser$Parser$chompWhile(fn));
};
var $hecrj$html_parser$Html$Parser$isSpaceCharacter = function (c) {
	return (c === ' ') || ((c === '\t') || ((c === '\n') || ((c === '\u000D') || ((c === '\u000C') || (c === '\u00A0')))));
};
var $elm$core$String$toLower = _String_toLower;
var $hecrj$html_parser$Html$Parser$closingTag = function (name) {
	var chompName = A2(
		$elm$parser$Parser$andThen,
		function (closingName) {
			return _Utils_eq(
				$elm$core$String$toLower(closingName),
				name) ? $elm$parser$Parser$succeed(0) : $elm$parser$Parser$problem('closing tag does not match opening tag: ' + name);
		},
		$elm$parser$Parser$getChompedString(
			$hecrj$html_parser$Html$Parser$chompOneOrMore(
				function (c) {
					return (!$hecrj$html_parser$Html$Parser$isSpaceCharacter(c)) && (c !== '>');
				})));
	return A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('<')),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('/'))),
				chompName),
			$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter)),
		$elm$parser$Parser$chompIf(
			$elm$core$Basics$eq('>')));
};
var $hecrj$html_parser$Html$Parser$Comment = function (a) {
	return {$: 2, a: a};
};
var $elm$parser$Parser$Advanced$findSubString = _Parser_findSubString;
var $elm$parser$Parser$Advanced$fromInfo = F4(
	function (row, col, x, context) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, row, col, x, context));
	});
var $elm$parser$Parser$Advanced$chompUntil = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$findSubString, str, s.f1, s.iP, s.ft, s.oc);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A4($elm$parser$Parser$Advanced$fromInfo, newRow, newCol, expecting, s.j)) : A3(
			$elm$parser$Parser$Advanced$Good,
			_Utils_cmp(s.f1, newOffset) < 0,
			0,
			{ft: newCol, j: s.j, l: s.l, f1: newOffset, iP: newRow, oc: s.oc});
	};
};
var $elm$parser$Parser$chompUntil = function (str) {
	return $elm$parser$Parser$Advanced$chompUntil(
		$elm$parser$Parser$toToken(str));
};
var $hecrj$html_parser$Html$Parser$commentString = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			$elm$parser$Parser$token('<!')),
		$elm$parser$Parser$token('--')),
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$getChompedString(
			$elm$parser$Parser$chompUntil('-->')),
		$elm$parser$Parser$token('-->')));
var $hecrj$html_parser$Html$Parser$comment = A2($elm$parser$Parser$map, $hecrj$html_parser$Html$Parser$Comment, $hecrj$html_parser$Html$Parser$commentString);
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $hecrj$html_parser$Html$Parser$voidElements = _List_fromArray(
	['area', 'base', 'br', 'col', 'embed', 'hr', 'img', 'input', 'link', 'meta', 'param', 'source', 'track', 'wbr']);
var $hecrj$html_parser$Html$Parser$isVoidElement = function (name) {
	return A2($elm$core$List$member, name, $hecrj$html_parser$Html$Parser$voidElements);
};
var $hecrj$html_parser$Html$Parser$many = function (parser_) {
	return A2(
		$elm$parser$Parser$loop,
		_List_Nil,
		function (list) {
			return $elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$parser$Parser$map,
						function (_new) {
							return $elm$parser$Parser$Loop(
								A2($elm$core$List$cons, _new, list));
						},
						parser_),
						$elm$parser$Parser$succeed(
						$elm$parser$Parser$Done(
							$elm$core$List$reverse(list)))
					]));
		});
};
var $hecrj$html_parser$Html$Parser$isTagAttributeCharacter = function (c) {
	return (!$hecrj$html_parser$Html$Parser$isSpaceCharacter(c)) && ((c !== '\"') && ((c !== '\'') && ((c !== '>') && ((c !== '/') && (c !== '=')))));
};
var $hecrj$html_parser$Html$Parser$tagAttributeName = A2(
	$elm$parser$Parser$map,
	$elm$core$String$toLower,
	$elm$parser$Parser$getChompedString(
		$hecrj$html_parser$Html$Parser$chompOneOrMore($hecrj$html_parser$Html$Parser$isTagAttributeCharacter)));
var $hecrj$html_parser$Html$Parser$chompSemicolon = $elm$parser$Parser$chompIf(
	$elm$core$Basics$eq(';'));
var $hecrj$html_parser$Html$Parser$NamedCharacterReferences$dict = $elm$core$Dict$fromList(
	_List_fromArray(
		[
			_Utils_Tuple2('Aacute', 'Á'),
			_Utils_Tuple2('aacute', 'á'),
			_Utils_Tuple2('Abreve', 'Ă'),
			_Utils_Tuple2('abreve', 'ă'),
			_Utils_Tuple2('ac', '∾'),
			_Utils_Tuple2('acd', '∿'),
			_Utils_Tuple2('acE', '∾̳'),
			_Utils_Tuple2('Acirc', 'Â'),
			_Utils_Tuple2('acirc', 'â'),
			_Utils_Tuple2('acute', '´'),
			_Utils_Tuple2('Acy', 'А'),
			_Utils_Tuple2('acy', 'а'),
			_Utils_Tuple2('AElig', 'Æ'),
			_Utils_Tuple2('aelig', 'æ'),
			_Utils_Tuple2('af', '\u2061'),
			_Utils_Tuple2('Afr', '\uD835\uDD04'),
			_Utils_Tuple2('afr', '\uD835\uDD1E'),
			_Utils_Tuple2('Agrave', 'À'),
			_Utils_Tuple2('agrave', 'à'),
			_Utils_Tuple2('alefsym', 'ℵ'),
			_Utils_Tuple2('aleph', 'ℵ'),
			_Utils_Tuple2('Alpha', 'Α'),
			_Utils_Tuple2('alpha', 'α'),
			_Utils_Tuple2('Amacr', 'Ā'),
			_Utils_Tuple2('amacr', 'ā'),
			_Utils_Tuple2('amalg', '⨿'),
			_Utils_Tuple2('amp', '&'),
			_Utils_Tuple2('AMP', '&'),
			_Utils_Tuple2('andand', '⩕'),
			_Utils_Tuple2('And', '⩓'),
			_Utils_Tuple2('and', '∧'),
			_Utils_Tuple2('andd', '⩜'),
			_Utils_Tuple2('andslope', '⩘'),
			_Utils_Tuple2('andv', '⩚'),
			_Utils_Tuple2('ang', '∠'),
			_Utils_Tuple2('ange', '⦤'),
			_Utils_Tuple2('angle', '∠'),
			_Utils_Tuple2('angmsdaa', '⦨'),
			_Utils_Tuple2('angmsdab', '⦩'),
			_Utils_Tuple2('angmsdac', '⦪'),
			_Utils_Tuple2('angmsdad', '⦫'),
			_Utils_Tuple2('angmsdae', '⦬'),
			_Utils_Tuple2('angmsdaf', '⦭'),
			_Utils_Tuple2('angmsdag', '⦮'),
			_Utils_Tuple2('angmsdah', '⦯'),
			_Utils_Tuple2('angmsd', '∡'),
			_Utils_Tuple2('angrt', '∟'),
			_Utils_Tuple2('angrtvb', '⊾'),
			_Utils_Tuple2('angrtvbd', '⦝'),
			_Utils_Tuple2('angsph', '∢'),
			_Utils_Tuple2('angst', 'Å'),
			_Utils_Tuple2('angzarr', '⍼'),
			_Utils_Tuple2('Aogon', 'Ą'),
			_Utils_Tuple2('aogon', 'ą'),
			_Utils_Tuple2('Aopf', '\uD835\uDD38'),
			_Utils_Tuple2('aopf', '\uD835\uDD52'),
			_Utils_Tuple2('apacir', '⩯'),
			_Utils_Tuple2('ap', '≈'),
			_Utils_Tuple2('apE', '⩰'),
			_Utils_Tuple2('ape', '≊'),
			_Utils_Tuple2('apid', '≋'),
			_Utils_Tuple2('apos', '\''),
			_Utils_Tuple2('ApplyFunction', '\u2061'),
			_Utils_Tuple2('approx', '≈'),
			_Utils_Tuple2('approxeq', '≊'),
			_Utils_Tuple2('Aring', 'Å'),
			_Utils_Tuple2('aring', 'å'),
			_Utils_Tuple2('Ascr', '\uD835\uDC9C'),
			_Utils_Tuple2('ascr', '\uD835\uDCB6'),
			_Utils_Tuple2('Assign', '≔'),
			_Utils_Tuple2('ast', '*'),
			_Utils_Tuple2('asymp', '≈'),
			_Utils_Tuple2('asympeq', '≍'),
			_Utils_Tuple2('Atilde', 'Ã'),
			_Utils_Tuple2('atilde', 'ã'),
			_Utils_Tuple2('Auml', 'Ä'),
			_Utils_Tuple2('auml', 'ä'),
			_Utils_Tuple2('awconint', '∳'),
			_Utils_Tuple2('awint', '⨑'),
			_Utils_Tuple2('backcong', '≌'),
			_Utils_Tuple2('backepsilon', '϶'),
			_Utils_Tuple2('backprime', '‵'),
			_Utils_Tuple2('backsim', '∽'),
			_Utils_Tuple2('backsimeq', '⋍'),
			_Utils_Tuple2('Backslash', '∖'),
			_Utils_Tuple2('Barv', '⫧'),
			_Utils_Tuple2('barvee', '⊽'),
			_Utils_Tuple2('barwed', '⌅'),
			_Utils_Tuple2('Barwed', '⌆'),
			_Utils_Tuple2('barwedge', '⌅'),
			_Utils_Tuple2('bbrk', '⎵'),
			_Utils_Tuple2('bbrktbrk', '⎶'),
			_Utils_Tuple2('bcong', '≌'),
			_Utils_Tuple2('Bcy', 'Б'),
			_Utils_Tuple2('bcy', 'б'),
			_Utils_Tuple2('bdquo', '„'),
			_Utils_Tuple2('becaus', '∵'),
			_Utils_Tuple2('because', '∵'),
			_Utils_Tuple2('Because', '∵'),
			_Utils_Tuple2('bemptyv', '⦰'),
			_Utils_Tuple2('bepsi', '϶'),
			_Utils_Tuple2('bernou', 'ℬ'),
			_Utils_Tuple2('Bernoullis', 'ℬ'),
			_Utils_Tuple2('Beta', 'Β'),
			_Utils_Tuple2('beta', 'β'),
			_Utils_Tuple2('beth', 'ℶ'),
			_Utils_Tuple2('between', '≬'),
			_Utils_Tuple2('Bfr', '\uD835\uDD05'),
			_Utils_Tuple2('bfr', '\uD835\uDD1F'),
			_Utils_Tuple2('bigcap', '⋂'),
			_Utils_Tuple2('bigcirc', '◯'),
			_Utils_Tuple2('bigcup', '⋃'),
			_Utils_Tuple2('bigodot', '⨀'),
			_Utils_Tuple2('bigoplus', '⨁'),
			_Utils_Tuple2('bigotimes', '⨂'),
			_Utils_Tuple2('bigsqcup', '⨆'),
			_Utils_Tuple2('bigstar', '★'),
			_Utils_Tuple2('bigtriangledown', '▽'),
			_Utils_Tuple2('bigtriangleup', '△'),
			_Utils_Tuple2('biguplus', '⨄'),
			_Utils_Tuple2('bigvee', '⋁'),
			_Utils_Tuple2('bigwedge', '⋀'),
			_Utils_Tuple2('bkarow', '⤍'),
			_Utils_Tuple2('blacklozenge', '⧫'),
			_Utils_Tuple2('blacksquare', '▪'),
			_Utils_Tuple2('blacktriangle', '▴'),
			_Utils_Tuple2('blacktriangledown', '▾'),
			_Utils_Tuple2('blacktriangleleft', '◂'),
			_Utils_Tuple2('blacktriangleright', '▸'),
			_Utils_Tuple2('blank', '␣'),
			_Utils_Tuple2('blk12', '▒'),
			_Utils_Tuple2('blk14', '░'),
			_Utils_Tuple2('blk34', '▓'),
			_Utils_Tuple2('block', '█'),
			_Utils_Tuple2('bne', '=⃥'),
			_Utils_Tuple2('bnequiv', '≡⃥'),
			_Utils_Tuple2('bNot', '⫭'),
			_Utils_Tuple2('bnot', '⌐'),
			_Utils_Tuple2('Bopf', '\uD835\uDD39'),
			_Utils_Tuple2('bopf', '\uD835\uDD53'),
			_Utils_Tuple2('bot', '⊥'),
			_Utils_Tuple2('bottom', '⊥'),
			_Utils_Tuple2('bowtie', '⋈'),
			_Utils_Tuple2('boxbox', '⧉'),
			_Utils_Tuple2('boxdl', '┐'),
			_Utils_Tuple2('boxdL', '╕'),
			_Utils_Tuple2('boxDl', '╖'),
			_Utils_Tuple2('boxDL', '╗'),
			_Utils_Tuple2('boxdr', '┌'),
			_Utils_Tuple2('boxdR', '╒'),
			_Utils_Tuple2('boxDr', '╓'),
			_Utils_Tuple2('boxDR', '╔'),
			_Utils_Tuple2('boxh', '─'),
			_Utils_Tuple2('boxH', '═'),
			_Utils_Tuple2('boxhd', '┬'),
			_Utils_Tuple2('boxHd', '╤'),
			_Utils_Tuple2('boxhD', '╥'),
			_Utils_Tuple2('boxHD', '╦'),
			_Utils_Tuple2('boxhu', '┴'),
			_Utils_Tuple2('boxHu', '╧'),
			_Utils_Tuple2('boxhU', '╨'),
			_Utils_Tuple2('boxHU', '╩'),
			_Utils_Tuple2('boxminus', '⊟'),
			_Utils_Tuple2('boxplus', '⊞'),
			_Utils_Tuple2('boxtimes', '⊠'),
			_Utils_Tuple2('boxul', '┘'),
			_Utils_Tuple2('boxuL', '╛'),
			_Utils_Tuple2('boxUl', '╜'),
			_Utils_Tuple2('boxUL', '╝'),
			_Utils_Tuple2('boxur', '└'),
			_Utils_Tuple2('boxuR', '╘'),
			_Utils_Tuple2('boxUr', '╙'),
			_Utils_Tuple2('boxUR', '╚'),
			_Utils_Tuple2('boxv', '│'),
			_Utils_Tuple2('boxV', '║'),
			_Utils_Tuple2('boxvh', '┼'),
			_Utils_Tuple2('boxvH', '╪'),
			_Utils_Tuple2('boxVh', '╫'),
			_Utils_Tuple2('boxVH', '╬'),
			_Utils_Tuple2('boxvl', '┤'),
			_Utils_Tuple2('boxvL', '╡'),
			_Utils_Tuple2('boxVl', '╢'),
			_Utils_Tuple2('boxVL', '╣'),
			_Utils_Tuple2('boxvr', '├'),
			_Utils_Tuple2('boxvR', '╞'),
			_Utils_Tuple2('boxVr', '╟'),
			_Utils_Tuple2('boxVR', '╠'),
			_Utils_Tuple2('bprime', '‵'),
			_Utils_Tuple2('breve', '˘'),
			_Utils_Tuple2('Breve', '˘'),
			_Utils_Tuple2('brvbar', '¦'),
			_Utils_Tuple2('bscr', '\uD835\uDCB7'),
			_Utils_Tuple2('Bscr', 'ℬ'),
			_Utils_Tuple2('bsemi', '⁏'),
			_Utils_Tuple2('bsim', '∽'),
			_Utils_Tuple2('bsime', '⋍'),
			_Utils_Tuple2('bsolb', '⧅'),
			_Utils_Tuple2('bsol', '\\'),
			_Utils_Tuple2('bsolhsub', '⟈'),
			_Utils_Tuple2('bull', '•'),
			_Utils_Tuple2('bullet', '•'),
			_Utils_Tuple2('bump', '≎'),
			_Utils_Tuple2('bumpE', '⪮'),
			_Utils_Tuple2('bumpe', '≏'),
			_Utils_Tuple2('Bumpeq', '≎'),
			_Utils_Tuple2('bumpeq', '≏'),
			_Utils_Tuple2('Cacute', 'Ć'),
			_Utils_Tuple2('cacute', 'ć'),
			_Utils_Tuple2('capand', '⩄'),
			_Utils_Tuple2('capbrcup', '⩉'),
			_Utils_Tuple2('capcap', '⩋'),
			_Utils_Tuple2('cap', '∩'),
			_Utils_Tuple2('Cap', '⋒'),
			_Utils_Tuple2('capcup', '⩇'),
			_Utils_Tuple2('capdot', '⩀'),
			_Utils_Tuple2('CapitalDifferentialD', 'ⅅ'),
			_Utils_Tuple2('caps', '∩︀'),
			_Utils_Tuple2('caret', '⁁'),
			_Utils_Tuple2('caron', 'ˇ'),
			_Utils_Tuple2('Cayleys', 'ℭ'),
			_Utils_Tuple2('ccaps', '⩍'),
			_Utils_Tuple2('Ccaron', 'Č'),
			_Utils_Tuple2('ccaron', 'č'),
			_Utils_Tuple2('Ccedil', 'Ç'),
			_Utils_Tuple2('ccedil', 'ç'),
			_Utils_Tuple2('Ccirc', 'Ĉ'),
			_Utils_Tuple2('ccirc', 'ĉ'),
			_Utils_Tuple2('Cconint', '∰'),
			_Utils_Tuple2('ccups', '⩌'),
			_Utils_Tuple2('ccupssm', '⩐'),
			_Utils_Tuple2('Cdot', 'Ċ'),
			_Utils_Tuple2('cdot', 'ċ'),
			_Utils_Tuple2('cedil', '¸'),
			_Utils_Tuple2('Cedilla', '¸'),
			_Utils_Tuple2('cemptyv', '⦲'),
			_Utils_Tuple2('cent', '¢'),
			_Utils_Tuple2('centerdot', '·'),
			_Utils_Tuple2('CenterDot', '·'),
			_Utils_Tuple2('cfr', '\uD835\uDD20'),
			_Utils_Tuple2('Cfr', 'ℭ'),
			_Utils_Tuple2('CHcy', 'Ч'),
			_Utils_Tuple2('chcy', 'ч'),
			_Utils_Tuple2('check', '✓'),
			_Utils_Tuple2('checkmark', '✓'),
			_Utils_Tuple2('Chi', 'Χ'),
			_Utils_Tuple2('chi', 'χ'),
			_Utils_Tuple2('circ', 'ˆ'),
			_Utils_Tuple2('circeq', '≗'),
			_Utils_Tuple2('circlearrowleft', '↺'),
			_Utils_Tuple2('circlearrowright', '↻'),
			_Utils_Tuple2('circledast', '⊛'),
			_Utils_Tuple2('circledcirc', '⊚'),
			_Utils_Tuple2('circleddash', '⊝'),
			_Utils_Tuple2('CircleDot', '⊙'),
			_Utils_Tuple2('circledR', '®'),
			_Utils_Tuple2('circledS', 'Ⓢ'),
			_Utils_Tuple2('CircleMinus', '⊖'),
			_Utils_Tuple2('CirclePlus', '⊕'),
			_Utils_Tuple2('CircleTimes', '⊗'),
			_Utils_Tuple2('cir', '○'),
			_Utils_Tuple2('cirE', '⧃'),
			_Utils_Tuple2('cire', '≗'),
			_Utils_Tuple2('cirfnint', '⨐'),
			_Utils_Tuple2('cirmid', '⫯'),
			_Utils_Tuple2('cirscir', '⧂'),
			_Utils_Tuple2('ClockwiseContourIntegral', '∲'),
			_Utils_Tuple2('CloseCurlyDoubleQuote', '”'),
			_Utils_Tuple2('CloseCurlyQuote', '’'),
			_Utils_Tuple2('clubs', '♣'),
			_Utils_Tuple2('clubsuit', '♣'),
			_Utils_Tuple2('colon', ':'),
			_Utils_Tuple2('Colon', '∷'),
			_Utils_Tuple2('Colone', '⩴'),
			_Utils_Tuple2('colone', '≔'),
			_Utils_Tuple2('coloneq', '≔'),
			_Utils_Tuple2('comma', ','),
			_Utils_Tuple2('commat', '@'),
			_Utils_Tuple2('comp', '∁'),
			_Utils_Tuple2('compfn', '∘'),
			_Utils_Tuple2('complement', '∁'),
			_Utils_Tuple2('complexes', 'ℂ'),
			_Utils_Tuple2('cong', '≅'),
			_Utils_Tuple2('congdot', '⩭'),
			_Utils_Tuple2('Congruent', '≡'),
			_Utils_Tuple2('conint', '∮'),
			_Utils_Tuple2('Conint', '∯'),
			_Utils_Tuple2('ContourIntegral', '∮'),
			_Utils_Tuple2('copf', '\uD835\uDD54'),
			_Utils_Tuple2('Copf', 'ℂ'),
			_Utils_Tuple2('coprod', '∐'),
			_Utils_Tuple2('Coproduct', '∐'),
			_Utils_Tuple2('copy', '©'),
			_Utils_Tuple2('COPY', '©'),
			_Utils_Tuple2('copysr', '℗'),
			_Utils_Tuple2('CounterClockwiseContourIntegral', '∳'),
			_Utils_Tuple2('crarr', '↵'),
			_Utils_Tuple2('cross', '✗'),
			_Utils_Tuple2('Cross', '⨯'),
			_Utils_Tuple2('Cscr', '\uD835\uDC9E'),
			_Utils_Tuple2('cscr', '\uD835\uDCB8'),
			_Utils_Tuple2('csub', '⫏'),
			_Utils_Tuple2('csube', '⫑'),
			_Utils_Tuple2('csup', '⫐'),
			_Utils_Tuple2('csupe', '⫒'),
			_Utils_Tuple2('ctdot', '⋯'),
			_Utils_Tuple2('cudarrl', '⤸'),
			_Utils_Tuple2('cudarrr', '⤵'),
			_Utils_Tuple2('cuepr', '⋞'),
			_Utils_Tuple2('cuesc', '⋟'),
			_Utils_Tuple2('cularr', '↶'),
			_Utils_Tuple2('cularrp', '⤽'),
			_Utils_Tuple2('cupbrcap', '⩈'),
			_Utils_Tuple2('cupcap', '⩆'),
			_Utils_Tuple2('CupCap', '≍'),
			_Utils_Tuple2('cup', '∪'),
			_Utils_Tuple2('Cup', '⋓'),
			_Utils_Tuple2('cupcup', '⩊'),
			_Utils_Tuple2('cupdot', '⊍'),
			_Utils_Tuple2('cupor', '⩅'),
			_Utils_Tuple2('cups', '∪︀'),
			_Utils_Tuple2('curarr', '↷'),
			_Utils_Tuple2('curarrm', '⤼'),
			_Utils_Tuple2('curlyeqprec', '⋞'),
			_Utils_Tuple2('curlyeqsucc', '⋟'),
			_Utils_Tuple2('curlyvee', '⋎'),
			_Utils_Tuple2('curlywedge', '⋏'),
			_Utils_Tuple2('curren', '¤'),
			_Utils_Tuple2('curvearrowleft', '↶'),
			_Utils_Tuple2('curvearrowright', '↷'),
			_Utils_Tuple2('cuvee', '⋎'),
			_Utils_Tuple2('cuwed', '⋏'),
			_Utils_Tuple2('cwconint', '∲'),
			_Utils_Tuple2('cwint', '∱'),
			_Utils_Tuple2('cylcty', '⌭'),
			_Utils_Tuple2('dagger', '†'),
			_Utils_Tuple2('Dagger', '‡'),
			_Utils_Tuple2('daleth', 'ℸ'),
			_Utils_Tuple2('darr', '↓'),
			_Utils_Tuple2('Darr', '↡'),
			_Utils_Tuple2('dArr', '⇓'),
			_Utils_Tuple2('dash', '‐'),
			_Utils_Tuple2('Dashv', '⫤'),
			_Utils_Tuple2('dashv', '⊣'),
			_Utils_Tuple2('dbkarow', '⤏'),
			_Utils_Tuple2('dblac', '˝'),
			_Utils_Tuple2('Dcaron', 'Ď'),
			_Utils_Tuple2('dcaron', 'ď'),
			_Utils_Tuple2('Dcy', 'Д'),
			_Utils_Tuple2('dcy', 'д'),
			_Utils_Tuple2('ddagger', '‡'),
			_Utils_Tuple2('ddarr', '⇊'),
			_Utils_Tuple2('DD', 'ⅅ'),
			_Utils_Tuple2('dd', 'ⅆ'),
			_Utils_Tuple2('DDotrahd', '⤑'),
			_Utils_Tuple2('ddotseq', '⩷'),
			_Utils_Tuple2('deg', '°'),
			_Utils_Tuple2('Del', '∇'),
			_Utils_Tuple2('Delta', 'Δ'),
			_Utils_Tuple2('delta', 'δ'),
			_Utils_Tuple2('demptyv', '⦱'),
			_Utils_Tuple2('dfisht', '⥿'),
			_Utils_Tuple2('Dfr', '\uD835\uDD07'),
			_Utils_Tuple2('dfr', '\uD835\uDD21'),
			_Utils_Tuple2('dHar', '⥥'),
			_Utils_Tuple2('dharl', '⇃'),
			_Utils_Tuple2('dharr', '⇂'),
			_Utils_Tuple2('DiacriticalAcute', '´'),
			_Utils_Tuple2('DiacriticalDot', '˙'),
			_Utils_Tuple2('DiacriticalDoubleAcute', '˝'),
			_Utils_Tuple2('DiacriticalGrave', '`'),
			_Utils_Tuple2('DiacriticalTilde', '˜'),
			_Utils_Tuple2('diam', '⋄'),
			_Utils_Tuple2('diamond', '⋄'),
			_Utils_Tuple2('Diamond', '⋄'),
			_Utils_Tuple2('diamondsuit', '♦'),
			_Utils_Tuple2('diams', '♦'),
			_Utils_Tuple2('die', '¨'),
			_Utils_Tuple2('DifferentialD', 'ⅆ'),
			_Utils_Tuple2('digamma', 'ϝ'),
			_Utils_Tuple2('disin', '⋲'),
			_Utils_Tuple2('div', '÷'),
			_Utils_Tuple2('divide', '÷'),
			_Utils_Tuple2('divideontimes', '⋇'),
			_Utils_Tuple2('divonx', '⋇'),
			_Utils_Tuple2('DJcy', 'Ђ'),
			_Utils_Tuple2('djcy', 'ђ'),
			_Utils_Tuple2('dlcorn', '⌞'),
			_Utils_Tuple2('dlcrop', '⌍'),
			_Utils_Tuple2('dollar', '$'),
			_Utils_Tuple2('Dopf', '\uD835\uDD3B'),
			_Utils_Tuple2('dopf', '\uD835\uDD55'),
			_Utils_Tuple2('Dot', '¨'),
			_Utils_Tuple2('dot', '˙'),
			_Utils_Tuple2('DotDot', '⃜'),
			_Utils_Tuple2('doteq', '≐'),
			_Utils_Tuple2('doteqdot', '≑'),
			_Utils_Tuple2('DotEqual', '≐'),
			_Utils_Tuple2('dotminus', '∸'),
			_Utils_Tuple2('dotplus', '∔'),
			_Utils_Tuple2('dotsquare', '⊡'),
			_Utils_Tuple2('doublebarwedge', '⌆'),
			_Utils_Tuple2('DoubleContourIntegral', '∯'),
			_Utils_Tuple2('DoubleDot', '¨'),
			_Utils_Tuple2('DoubleDownArrow', '⇓'),
			_Utils_Tuple2('DoubleLeftArrow', '⇐'),
			_Utils_Tuple2('DoubleLeftRightArrow', '⇔'),
			_Utils_Tuple2('DoubleLeftTee', '⫤'),
			_Utils_Tuple2('DoubleLongLeftArrow', '⟸'),
			_Utils_Tuple2('DoubleLongLeftRightArrow', '⟺'),
			_Utils_Tuple2('DoubleLongRightArrow', '⟹'),
			_Utils_Tuple2('DoubleRightArrow', '⇒'),
			_Utils_Tuple2('DoubleRightTee', '⊨'),
			_Utils_Tuple2('DoubleUpArrow', '⇑'),
			_Utils_Tuple2('DoubleUpDownArrow', '⇕'),
			_Utils_Tuple2('DoubleVerticalBar', '∥'),
			_Utils_Tuple2('DownArrowBar', '⤓'),
			_Utils_Tuple2('downarrow', '↓'),
			_Utils_Tuple2('DownArrow', '↓'),
			_Utils_Tuple2('Downarrow', '⇓'),
			_Utils_Tuple2('DownArrowUpArrow', '⇵'),
			_Utils_Tuple2('DownBreve', '̑'),
			_Utils_Tuple2('downdownarrows', '⇊'),
			_Utils_Tuple2('downharpoonleft', '⇃'),
			_Utils_Tuple2('downharpoonright', '⇂'),
			_Utils_Tuple2('DownLeftRightVector', '⥐'),
			_Utils_Tuple2('DownLeftTeeVector', '⥞'),
			_Utils_Tuple2('DownLeftVectorBar', '⥖'),
			_Utils_Tuple2('DownLeftVector', '↽'),
			_Utils_Tuple2('DownRightTeeVector', '⥟'),
			_Utils_Tuple2('DownRightVectorBar', '⥗'),
			_Utils_Tuple2('DownRightVector', '⇁'),
			_Utils_Tuple2('DownTeeArrow', '↧'),
			_Utils_Tuple2('DownTee', '⊤'),
			_Utils_Tuple2('drbkarow', '⤐'),
			_Utils_Tuple2('drcorn', '⌟'),
			_Utils_Tuple2('drcrop', '⌌'),
			_Utils_Tuple2('Dscr', '\uD835\uDC9F'),
			_Utils_Tuple2('dscr', '\uD835\uDCB9'),
			_Utils_Tuple2('DScy', 'Ѕ'),
			_Utils_Tuple2('dscy', 'ѕ'),
			_Utils_Tuple2('dsol', '⧶'),
			_Utils_Tuple2('Dstrok', 'Đ'),
			_Utils_Tuple2('dstrok', 'đ'),
			_Utils_Tuple2('dtdot', '⋱'),
			_Utils_Tuple2('dtri', '▿'),
			_Utils_Tuple2('dtrif', '▾'),
			_Utils_Tuple2('duarr', '⇵'),
			_Utils_Tuple2('duhar', '⥯'),
			_Utils_Tuple2('dwangle', '⦦'),
			_Utils_Tuple2('DZcy', 'Џ'),
			_Utils_Tuple2('dzcy', 'џ'),
			_Utils_Tuple2('dzigrarr', '⟿'),
			_Utils_Tuple2('Eacute', 'É'),
			_Utils_Tuple2('eacute', 'é'),
			_Utils_Tuple2('easter', '⩮'),
			_Utils_Tuple2('Ecaron', 'Ě'),
			_Utils_Tuple2('ecaron', 'ě'),
			_Utils_Tuple2('Ecirc', 'Ê'),
			_Utils_Tuple2('ecirc', 'ê'),
			_Utils_Tuple2('ecir', '≖'),
			_Utils_Tuple2('ecolon', '≕'),
			_Utils_Tuple2('Ecy', 'Э'),
			_Utils_Tuple2('ecy', 'э'),
			_Utils_Tuple2('eDDot', '⩷'),
			_Utils_Tuple2('Edot', 'Ė'),
			_Utils_Tuple2('edot', 'ė'),
			_Utils_Tuple2('eDot', '≑'),
			_Utils_Tuple2('ee', 'ⅇ'),
			_Utils_Tuple2('efDot', '≒'),
			_Utils_Tuple2('Efr', '\uD835\uDD08'),
			_Utils_Tuple2('efr', '\uD835\uDD22'),
			_Utils_Tuple2('eg', '⪚'),
			_Utils_Tuple2('Egrave', 'È'),
			_Utils_Tuple2('egrave', 'è'),
			_Utils_Tuple2('egs', '⪖'),
			_Utils_Tuple2('egsdot', '⪘'),
			_Utils_Tuple2('el', '⪙'),
			_Utils_Tuple2('Element', '∈'),
			_Utils_Tuple2('elinters', '⏧'),
			_Utils_Tuple2('ell', 'ℓ'),
			_Utils_Tuple2('els', '⪕'),
			_Utils_Tuple2('elsdot', '⪗'),
			_Utils_Tuple2('Emacr', 'Ē'),
			_Utils_Tuple2('emacr', 'ē'),
			_Utils_Tuple2('empty', '∅'),
			_Utils_Tuple2('emptyset', '∅'),
			_Utils_Tuple2('EmptySmallSquare', '◻'),
			_Utils_Tuple2('emptyv', '∅'),
			_Utils_Tuple2('EmptyVerySmallSquare', '▫'),
			_Utils_Tuple2('emsp13', '\u2004'),
			_Utils_Tuple2('emsp14', '\u2005'),
			_Utils_Tuple2('emsp', '\u2003'),
			_Utils_Tuple2('ENG', 'Ŋ'),
			_Utils_Tuple2('eng', 'ŋ'),
			_Utils_Tuple2('ensp', '\u2002'),
			_Utils_Tuple2('Eogon', 'Ę'),
			_Utils_Tuple2('eogon', 'ę'),
			_Utils_Tuple2('Eopf', '\uD835\uDD3C'),
			_Utils_Tuple2('eopf', '\uD835\uDD56'),
			_Utils_Tuple2('epar', '⋕'),
			_Utils_Tuple2('eparsl', '⧣'),
			_Utils_Tuple2('eplus', '⩱'),
			_Utils_Tuple2('epsi', 'ε'),
			_Utils_Tuple2('Epsilon', 'Ε'),
			_Utils_Tuple2('epsilon', 'ε'),
			_Utils_Tuple2('epsiv', 'ϵ'),
			_Utils_Tuple2('eqcirc', '≖'),
			_Utils_Tuple2('eqcolon', '≕'),
			_Utils_Tuple2('eqsim', '≂'),
			_Utils_Tuple2('eqslantgtr', '⪖'),
			_Utils_Tuple2('eqslantless', '⪕'),
			_Utils_Tuple2('Equal', '⩵'),
			_Utils_Tuple2('equals', '='),
			_Utils_Tuple2('EqualTilde', '≂'),
			_Utils_Tuple2('equest', '≟'),
			_Utils_Tuple2('Equilibrium', '⇌'),
			_Utils_Tuple2('equiv', '≡'),
			_Utils_Tuple2('equivDD', '⩸'),
			_Utils_Tuple2('eqvparsl', '⧥'),
			_Utils_Tuple2('erarr', '⥱'),
			_Utils_Tuple2('erDot', '≓'),
			_Utils_Tuple2('escr', 'ℯ'),
			_Utils_Tuple2('Escr', 'ℰ'),
			_Utils_Tuple2('esdot', '≐'),
			_Utils_Tuple2('Esim', '⩳'),
			_Utils_Tuple2('esim', '≂'),
			_Utils_Tuple2('Eta', 'Η'),
			_Utils_Tuple2('eta', 'η'),
			_Utils_Tuple2('ETH', 'Ð'),
			_Utils_Tuple2('eth', 'ð'),
			_Utils_Tuple2('Euml', 'Ë'),
			_Utils_Tuple2('euml', 'ë'),
			_Utils_Tuple2('euro', '€'),
			_Utils_Tuple2('excl', '!'),
			_Utils_Tuple2('exist', '∃'),
			_Utils_Tuple2('Exists', '∃'),
			_Utils_Tuple2('expectation', 'ℰ'),
			_Utils_Tuple2('exponentiale', 'ⅇ'),
			_Utils_Tuple2('ExponentialE', 'ⅇ'),
			_Utils_Tuple2('fallingdotseq', '≒'),
			_Utils_Tuple2('Fcy', 'Ф'),
			_Utils_Tuple2('fcy', 'ф'),
			_Utils_Tuple2('female', '♀'),
			_Utils_Tuple2('ffilig', 'ﬃ'),
			_Utils_Tuple2('fflig', 'ﬀ'),
			_Utils_Tuple2('ffllig', 'ﬄ'),
			_Utils_Tuple2('Ffr', '\uD835\uDD09'),
			_Utils_Tuple2('ffr', '\uD835\uDD23'),
			_Utils_Tuple2('filig', 'ﬁ'),
			_Utils_Tuple2('FilledSmallSquare', '◼'),
			_Utils_Tuple2('FilledVerySmallSquare', '▪'),
			_Utils_Tuple2('fjlig', 'fj'),
			_Utils_Tuple2('flat', '♭'),
			_Utils_Tuple2('fllig', 'ﬂ'),
			_Utils_Tuple2('fltns', '▱'),
			_Utils_Tuple2('fnof', 'ƒ'),
			_Utils_Tuple2('Fopf', '\uD835\uDD3D'),
			_Utils_Tuple2('fopf', '\uD835\uDD57'),
			_Utils_Tuple2('forall', '∀'),
			_Utils_Tuple2('ForAll', '∀'),
			_Utils_Tuple2('fork', '⋔'),
			_Utils_Tuple2('forkv', '⫙'),
			_Utils_Tuple2('Fouriertrf', 'ℱ'),
			_Utils_Tuple2('fpartint', '⨍'),
			_Utils_Tuple2('frac12', '½'),
			_Utils_Tuple2('frac13', '⅓'),
			_Utils_Tuple2('frac14', '¼'),
			_Utils_Tuple2('frac15', '⅕'),
			_Utils_Tuple2('frac16', '⅙'),
			_Utils_Tuple2('frac18', '⅛'),
			_Utils_Tuple2('frac23', '⅔'),
			_Utils_Tuple2('frac25', '⅖'),
			_Utils_Tuple2('frac34', '¾'),
			_Utils_Tuple2('frac35', '⅗'),
			_Utils_Tuple2('frac38', '⅜'),
			_Utils_Tuple2('frac45', '⅘'),
			_Utils_Tuple2('frac56', '⅚'),
			_Utils_Tuple2('frac58', '⅝'),
			_Utils_Tuple2('frac78', '⅞'),
			_Utils_Tuple2('frasl', '⁄'),
			_Utils_Tuple2('frown', '⌢'),
			_Utils_Tuple2('fscr', '\uD835\uDCBB'),
			_Utils_Tuple2('Fscr', 'ℱ'),
			_Utils_Tuple2('gacute', 'ǵ'),
			_Utils_Tuple2('Gamma', 'Γ'),
			_Utils_Tuple2('gamma', 'γ'),
			_Utils_Tuple2('Gammad', 'Ϝ'),
			_Utils_Tuple2('gammad', 'ϝ'),
			_Utils_Tuple2('gap', '⪆'),
			_Utils_Tuple2('Gbreve', 'Ğ'),
			_Utils_Tuple2('gbreve', 'ğ'),
			_Utils_Tuple2('Gcedil', 'Ģ'),
			_Utils_Tuple2('Gcirc', 'Ĝ'),
			_Utils_Tuple2('gcirc', 'ĝ'),
			_Utils_Tuple2('Gcy', 'Г'),
			_Utils_Tuple2('gcy', 'г'),
			_Utils_Tuple2('Gdot', 'Ġ'),
			_Utils_Tuple2('gdot', 'ġ'),
			_Utils_Tuple2('ge', '≥'),
			_Utils_Tuple2('gE', '≧'),
			_Utils_Tuple2('gEl', '⪌'),
			_Utils_Tuple2('gel', '⋛'),
			_Utils_Tuple2('geq', '≥'),
			_Utils_Tuple2('geqq', '≧'),
			_Utils_Tuple2('geqslant', '⩾'),
			_Utils_Tuple2('gescc', '⪩'),
			_Utils_Tuple2('ges', '⩾'),
			_Utils_Tuple2('gesdot', '⪀'),
			_Utils_Tuple2('gesdoto', '⪂'),
			_Utils_Tuple2('gesdotol', '⪄'),
			_Utils_Tuple2('gesl', '⋛︀'),
			_Utils_Tuple2('gesles', '⪔'),
			_Utils_Tuple2('Gfr', '\uD835\uDD0A'),
			_Utils_Tuple2('gfr', '\uD835\uDD24'),
			_Utils_Tuple2('gg', '≫'),
			_Utils_Tuple2('Gg', '⋙'),
			_Utils_Tuple2('ggg', '⋙'),
			_Utils_Tuple2('gimel', 'ℷ'),
			_Utils_Tuple2('GJcy', 'Ѓ'),
			_Utils_Tuple2('gjcy', 'ѓ'),
			_Utils_Tuple2('gla', '⪥'),
			_Utils_Tuple2('gl', '≷'),
			_Utils_Tuple2('glE', '⪒'),
			_Utils_Tuple2('glj', '⪤'),
			_Utils_Tuple2('gnap', '⪊'),
			_Utils_Tuple2('gnapprox', '⪊'),
			_Utils_Tuple2('gne', '⪈'),
			_Utils_Tuple2('gnE', '≩'),
			_Utils_Tuple2('gneq', '⪈'),
			_Utils_Tuple2('gneqq', '≩'),
			_Utils_Tuple2('gnsim', '⋧'),
			_Utils_Tuple2('Gopf', '\uD835\uDD3E'),
			_Utils_Tuple2('gopf', '\uD835\uDD58'),
			_Utils_Tuple2('grave', '`'),
			_Utils_Tuple2('GreaterEqual', '≥'),
			_Utils_Tuple2('GreaterEqualLess', '⋛'),
			_Utils_Tuple2('GreaterFullEqual', '≧'),
			_Utils_Tuple2('GreaterGreater', '⪢'),
			_Utils_Tuple2('GreaterLess', '≷'),
			_Utils_Tuple2('GreaterSlantEqual', '⩾'),
			_Utils_Tuple2('GreaterTilde', '≳'),
			_Utils_Tuple2('Gscr', '\uD835\uDCA2'),
			_Utils_Tuple2('gscr', 'ℊ'),
			_Utils_Tuple2('gsim', '≳'),
			_Utils_Tuple2('gsime', '⪎'),
			_Utils_Tuple2('gsiml', '⪐'),
			_Utils_Tuple2('gtcc', '⪧'),
			_Utils_Tuple2('gtcir', '⩺'),
			_Utils_Tuple2('gt', '>'),
			_Utils_Tuple2('GT', '>'),
			_Utils_Tuple2('Gt', '≫'),
			_Utils_Tuple2('gtdot', '⋗'),
			_Utils_Tuple2('gtlPar', '⦕'),
			_Utils_Tuple2('gtquest', '⩼'),
			_Utils_Tuple2('gtrapprox', '⪆'),
			_Utils_Tuple2('gtrarr', '⥸'),
			_Utils_Tuple2('gtrdot', '⋗'),
			_Utils_Tuple2('gtreqless', '⋛'),
			_Utils_Tuple2('gtreqqless', '⪌'),
			_Utils_Tuple2('gtrless', '≷'),
			_Utils_Tuple2('gtrsim', '≳'),
			_Utils_Tuple2('gvertneqq', '≩︀'),
			_Utils_Tuple2('gvnE', '≩︀'),
			_Utils_Tuple2('Hacek', 'ˇ'),
			_Utils_Tuple2('hairsp', '\u200A'),
			_Utils_Tuple2('half', '½'),
			_Utils_Tuple2('hamilt', 'ℋ'),
			_Utils_Tuple2('HARDcy', 'Ъ'),
			_Utils_Tuple2('hardcy', 'ъ'),
			_Utils_Tuple2('harrcir', '⥈'),
			_Utils_Tuple2('harr', '↔'),
			_Utils_Tuple2('hArr', '⇔'),
			_Utils_Tuple2('harrw', '↭'),
			_Utils_Tuple2('Hat', '^'),
			_Utils_Tuple2('hbar', 'ℏ'),
			_Utils_Tuple2('Hcirc', 'Ĥ'),
			_Utils_Tuple2('hcirc', 'ĥ'),
			_Utils_Tuple2('hearts', '♥'),
			_Utils_Tuple2('heartsuit', '♥'),
			_Utils_Tuple2('hellip', '…'),
			_Utils_Tuple2('hercon', '⊹'),
			_Utils_Tuple2('hfr', '\uD835\uDD25'),
			_Utils_Tuple2('Hfr', 'ℌ'),
			_Utils_Tuple2('HilbertSpace', 'ℋ'),
			_Utils_Tuple2('hksearow', '⤥'),
			_Utils_Tuple2('hkswarow', '⤦'),
			_Utils_Tuple2('hoarr', '⇿'),
			_Utils_Tuple2('homtht', '∻'),
			_Utils_Tuple2('hookleftarrow', '↩'),
			_Utils_Tuple2('hookrightarrow', '↪'),
			_Utils_Tuple2('hopf', '\uD835\uDD59'),
			_Utils_Tuple2('Hopf', 'ℍ'),
			_Utils_Tuple2('horbar', '―'),
			_Utils_Tuple2('HorizontalLine', '─'),
			_Utils_Tuple2('hscr', '\uD835\uDCBD'),
			_Utils_Tuple2('Hscr', 'ℋ'),
			_Utils_Tuple2('hslash', 'ℏ'),
			_Utils_Tuple2('Hstrok', 'Ħ'),
			_Utils_Tuple2('hstrok', 'ħ'),
			_Utils_Tuple2('HumpDownHump', '≎'),
			_Utils_Tuple2('HumpEqual', '≏'),
			_Utils_Tuple2('hybull', '⁃'),
			_Utils_Tuple2('hyphen', '‐'),
			_Utils_Tuple2('Iacute', 'Í'),
			_Utils_Tuple2('iacute', 'í'),
			_Utils_Tuple2('ic', '\u2063'),
			_Utils_Tuple2('Icirc', 'Î'),
			_Utils_Tuple2('icirc', 'î'),
			_Utils_Tuple2('Icy', 'И'),
			_Utils_Tuple2('icy', 'и'),
			_Utils_Tuple2('Idot', 'İ'),
			_Utils_Tuple2('IEcy', 'Е'),
			_Utils_Tuple2('iecy', 'е'),
			_Utils_Tuple2('iexcl', '¡'),
			_Utils_Tuple2('iff', '⇔'),
			_Utils_Tuple2('ifr', '\uD835\uDD26'),
			_Utils_Tuple2('Ifr', 'ℑ'),
			_Utils_Tuple2('Igrave', 'Ì'),
			_Utils_Tuple2('igrave', 'ì'),
			_Utils_Tuple2('ii', 'ⅈ'),
			_Utils_Tuple2('iiiint', '⨌'),
			_Utils_Tuple2('iiint', '∭'),
			_Utils_Tuple2('iinfin', '⧜'),
			_Utils_Tuple2('iiota', '℩'),
			_Utils_Tuple2('IJlig', 'Ĳ'),
			_Utils_Tuple2('ijlig', 'ĳ'),
			_Utils_Tuple2('Imacr', 'Ī'),
			_Utils_Tuple2('imacr', 'ī'),
			_Utils_Tuple2('image', 'ℑ'),
			_Utils_Tuple2('ImaginaryI', 'ⅈ'),
			_Utils_Tuple2('imagline', 'ℐ'),
			_Utils_Tuple2('imagpart', 'ℑ'),
			_Utils_Tuple2('imath', 'ı'),
			_Utils_Tuple2('Im', 'ℑ'),
			_Utils_Tuple2('imof', '⊷'),
			_Utils_Tuple2('imped', 'Ƶ'),
			_Utils_Tuple2('Implies', '⇒'),
			_Utils_Tuple2('incare', '℅'),
			_Utils_Tuple2('in', '∈'),
			_Utils_Tuple2('infin', '∞'),
			_Utils_Tuple2('infintie', '⧝'),
			_Utils_Tuple2('inodot', 'ı'),
			_Utils_Tuple2('intcal', '⊺'),
			_Utils_Tuple2('int', '∫'),
			_Utils_Tuple2('Int', '∬'),
			_Utils_Tuple2('integers', 'ℤ'),
			_Utils_Tuple2('Integral', '∫'),
			_Utils_Tuple2('intercal', '⊺'),
			_Utils_Tuple2('Intersection', '⋂'),
			_Utils_Tuple2('intlarhk', '⨗'),
			_Utils_Tuple2('intprod', '⨼'),
			_Utils_Tuple2('InvisibleComma', '\u2063'),
			_Utils_Tuple2('InvisibleTimes', '\u2062'),
			_Utils_Tuple2('IOcy', 'Ё'),
			_Utils_Tuple2('iocy', 'ё'),
			_Utils_Tuple2('Iogon', 'Į'),
			_Utils_Tuple2('iogon', 'į'),
			_Utils_Tuple2('Iopf', '\uD835\uDD40'),
			_Utils_Tuple2('iopf', '\uD835\uDD5A'),
			_Utils_Tuple2('Iota', 'Ι'),
			_Utils_Tuple2('iota', 'ι'),
			_Utils_Tuple2('iprod', '⨼'),
			_Utils_Tuple2('iquest', '¿'),
			_Utils_Tuple2('iscr', '\uD835\uDCBE'),
			_Utils_Tuple2('Iscr', 'ℐ'),
			_Utils_Tuple2('isin', '∈'),
			_Utils_Tuple2('isindot', '⋵'),
			_Utils_Tuple2('isinE', '⋹'),
			_Utils_Tuple2('isins', '⋴'),
			_Utils_Tuple2('isinsv', '⋳'),
			_Utils_Tuple2('isinv', '∈'),
			_Utils_Tuple2('it', '\u2062'),
			_Utils_Tuple2('Itilde', 'Ĩ'),
			_Utils_Tuple2('itilde', 'ĩ'),
			_Utils_Tuple2('Iukcy', 'І'),
			_Utils_Tuple2('iukcy', 'і'),
			_Utils_Tuple2('Iuml', 'Ï'),
			_Utils_Tuple2('iuml', 'ï'),
			_Utils_Tuple2('Jcirc', 'Ĵ'),
			_Utils_Tuple2('jcirc', 'ĵ'),
			_Utils_Tuple2('Jcy', 'Й'),
			_Utils_Tuple2('jcy', 'й'),
			_Utils_Tuple2('Jfr', '\uD835\uDD0D'),
			_Utils_Tuple2('jfr', '\uD835\uDD27'),
			_Utils_Tuple2('jmath', 'ȷ'),
			_Utils_Tuple2('Jopf', '\uD835\uDD41'),
			_Utils_Tuple2('jopf', '\uD835\uDD5B'),
			_Utils_Tuple2('Jscr', '\uD835\uDCA5'),
			_Utils_Tuple2('jscr', '\uD835\uDCBF'),
			_Utils_Tuple2('Jsercy', 'Ј'),
			_Utils_Tuple2('jsercy', 'ј'),
			_Utils_Tuple2('Jukcy', 'Є'),
			_Utils_Tuple2('jukcy', 'є'),
			_Utils_Tuple2('Kappa', 'Κ'),
			_Utils_Tuple2('kappa', 'κ'),
			_Utils_Tuple2('kappav', 'ϰ'),
			_Utils_Tuple2('Kcedil', 'Ķ'),
			_Utils_Tuple2('kcedil', 'ķ'),
			_Utils_Tuple2('Kcy', 'К'),
			_Utils_Tuple2('kcy', 'к'),
			_Utils_Tuple2('Kfr', '\uD835\uDD0E'),
			_Utils_Tuple2('kfr', '\uD835\uDD28'),
			_Utils_Tuple2('kgreen', 'ĸ'),
			_Utils_Tuple2('KHcy', 'Х'),
			_Utils_Tuple2('khcy', 'х'),
			_Utils_Tuple2('KJcy', 'Ќ'),
			_Utils_Tuple2('kjcy', 'ќ'),
			_Utils_Tuple2('Kopf', '\uD835\uDD42'),
			_Utils_Tuple2('kopf', '\uD835\uDD5C'),
			_Utils_Tuple2('Kscr', '\uD835\uDCA6'),
			_Utils_Tuple2('kscr', '\uD835\uDCC0'),
			_Utils_Tuple2('lAarr', '⇚'),
			_Utils_Tuple2('Lacute', 'Ĺ'),
			_Utils_Tuple2('lacute', 'ĺ'),
			_Utils_Tuple2('laemptyv', '⦴'),
			_Utils_Tuple2('lagran', 'ℒ'),
			_Utils_Tuple2('Lambda', 'Λ'),
			_Utils_Tuple2('lambda', 'λ'),
			_Utils_Tuple2('lang', '⟨'),
			_Utils_Tuple2('Lang', '⟪'),
			_Utils_Tuple2('langd', '⦑'),
			_Utils_Tuple2('langle', '⟨'),
			_Utils_Tuple2('lap', '⪅'),
			_Utils_Tuple2('Laplacetrf', 'ℒ'),
			_Utils_Tuple2('laquo', '«'),
			_Utils_Tuple2('larrb', '⇤'),
			_Utils_Tuple2('larrbfs', '⤟'),
			_Utils_Tuple2('larr', '←'),
			_Utils_Tuple2('Larr', '↞'),
			_Utils_Tuple2('lArr', '⇐'),
			_Utils_Tuple2('larrfs', '⤝'),
			_Utils_Tuple2('larrhk', '↩'),
			_Utils_Tuple2('larrlp', '↫'),
			_Utils_Tuple2('larrpl', '⤹'),
			_Utils_Tuple2('larrsim', '⥳'),
			_Utils_Tuple2('larrtl', '↢'),
			_Utils_Tuple2('latail', '⤙'),
			_Utils_Tuple2('lAtail', '⤛'),
			_Utils_Tuple2('lat', '⪫'),
			_Utils_Tuple2('late', '⪭'),
			_Utils_Tuple2('lates', '⪭︀'),
			_Utils_Tuple2('lbarr', '⤌'),
			_Utils_Tuple2('lBarr', '⤎'),
			_Utils_Tuple2('lbbrk', '❲'),
			_Utils_Tuple2('lbrace', '{'),
			_Utils_Tuple2('lbrack', '['),
			_Utils_Tuple2('lbrke', '⦋'),
			_Utils_Tuple2('lbrksld', '⦏'),
			_Utils_Tuple2('lbrkslu', '⦍'),
			_Utils_Tuple2('Lcaron', 'Ľ'),
			_Utils_Tuple2('lcaron', 'ľ'),
			_Utils_Tuple2('Lcedil', 'Ļ'),
			_Utils_Tuple2('lcedil', 'ļ'),
			_Utils_Tuple2('lceil', '⌈'),
			_Utils_Tuple2('lcub', '{'),
			_Utils_Tuple2('Lcy', 'Л'),
			_Utils_Tuple2('lcy', 'л'),
			_Utils_Tuple2('ldca', '⤶'),
			_Utils_Tuple2('ldquo', '“'),
			_Utils_Tuple2('ldquor', '„'),
			_Utils_Tuple2('ldrdhar', '⥧'),
			_Utils_Tuple2('ldrushar', '⥋'),
			_Utils_Tuple2('ldsh', '↲'),
			_Utils_Tuple2('le', '≤'),
			_Utils_Tuple2('lE', '≦'),
			_Utils_Tuple2('LeftAngleBracket', '⟨'),
			_Utils_Tuple2('LeftArrowBar', '⇤'),
			_Utils_Tuple2('leftarrow', '←'),
			_Utils_Tuple2('LeftArrow', '←'),
			_Utils_Tuple2('Leftarrow', '⇐'),
			_Utils_Tuple2('LeftArrowRightArrow', '⇆'),
			_Utils_Tuple2('leftarrowtail', '↢'),
			_Utils_Tuple2('LeftCeiling', '⌈'),
			_Utils_Tuple2('LeftDoubleBracket', '⟦'),
			_Utils_Tuple2('LeftDownTeeVector', '⥡'),
			_Utils_Tuple2('LeftDownVectorBar', '⥙'),
			_Utils_Tuple2('LeftDownVector', '⇃'),
			_Utils_Tuple2('LeftFloor', '⌊'),
			_Utils_Tuple2('leftharpoondown', '↽'),
			_Utils_Tuple2('leftharpoonup', '↼'),
			_Utils_Tuple2('leftleftarrows', '⇇'),
			_Utils_Tuple2('leftrightarrow', '↔'),
			_Utils_Tuple2('LeftRightArrow', '↔'),
			_Utils_Tuple2('Leftrightarrow', '⇔'),
			_Utils_Tuple2('leftrightarrows', '⇆'),
			_Utils_Tuple2('leftrightharpoons', '⇋'),
			_Utils_Tuple2('leftrightsquigarrow', '↭'),
			_Utils_Tuple2('LeftRightVector', '⥎'),
			_Utils_Tuple2('LeftTeeArrow', '↤'),
			_Utils_Tuple2('LeftTee', '⊣'),
			_Utils_Tuple2('LeftTeeVector', '⥚'),
			_Utils_Tuple2('leftthreetimes', '⋋'),
			_Utils_Tuple2('LeftTriangleBar', '⧏'),
			_Utils_Tuple2('LeftTriangle', '⊲'),
			_Utils_Tuple2('LeftTriangleEqual', '⊴'),
			_Utils_Tuple2('LeftUpDownVector', '⥑'),
			_Utils_Tuple2('LeftUpTeeVector', '⥠'),
			_Utils_Tuple2('LeftUpVectorBar', '⥘'),
			_Utils_Tuple2('LeftUpVector', '↿'),
			_Utils_Tuple2('LeftVectorBar', '⥒'),
			_Utils_Tuple2('LeftVector', '↼'),
			_Utils_Tuple2('lEg', '⪋'),
			_Utils_Tuple2('leg', '⋚'),
			_Utils_Tuple2('leq', '≤'),
			_Utils_Tuple2('leqq', '≦'),
			_Utils_Tuple2('leqslant', '⩽'),
			_Utils_Tuple2('lescc', '⪨'),
			_Utils_Tuple2('les', '⩽'),
			_Utils_Tuple2('lesdot', '⩿'),
			_Utils_Tuple2('lesdoto', '⪁'),
			_Utils_Tuple2('lesdotor', '⪃'),
			_Utils_Tuple2('lesg', '⋚︀'),
			_Utils_Tuple2('lesges', '⪓'),
			_Utils_Tuple2('lessapprox', '⪅'),
			_Utils_Tuple2('lessdot', '⋖'),
			_Utils_Tuple2('lesseqgtr', '⋚'),
			_Utils_Tuple2('lesseqqgtr', '⪋'),
			_Utils_Tuple2('LessEqualGreater', '⋚'),
			_Utils_Tuple2('LessFullEqual', '≦'),
			_Utils_Tuple2('LessGreater', '≶'),
			_Utils_Tuple2('lessgtr', '≶'),
			_Utils_Tuple2('LessLess', '⪡'),
			_Utils_Tuple2('lesssim', '≲'),
			_Utils_Tuple2('LessSlantEqual', '⩽'),
			_Utils_Tuple2('LessTilde', '≲'),
			_Utils_Tuple2('lfisht', '⥼'),
			_Utils_Tuple2('lfloor', '⌊'),
			_Utils_Tuple2('Lfr', '\uD835\uDD0F'),
			_Utils_Tuple2('lfr', '\uD835\uDD29'),
			_Utils_Tuple2('lg', '≶'),
			_Utils_Tuple2('lgE', '⪑'),
			_Utils_Tuple2('lHar', '⥢'),
			_Utils_Tuple2('lhard', '↽'),
			_Utils_Tuple2('lharu', '↼'),
			_Utils_Tuple2('lharul', '⥪'),
			_Utils_Tuple2('lhblk', '▄'),
			_Utils_Tuple2('LJcy', 'Љ'),
			_Utils_Tuple2('ljcy', 'љ'),
			_Utils_Tuple2('llarr', '⇇'),
			_Utils_Tuple2('ll', '≪'),
			_Utils_Tuple2('Ll', '⋘'),
			_Utils_Tuple2('llcorner', '⌞'),
			_Utils_Tuple2('Lleftarrow', '⇚'),
			_Utils_Tuple2('llhard', '⥫'),
			_Utils_Tuple2('lltri', '◺'),
			_Utils_Tuple2('Lmidot', 'Ŀ'),
			_Utils_Tuple2('lmidot', 'ŀ'),
			_Utils_Tuple2('lmoustache', '⎰'),
			_Utils_Tuple2('lmoust', '⎰'),
			_Utils_Tuple2('lnap', '⪉'),
			_Utils_Tuple2('lnapprox', '⪉'),
			_Utils_Tuple2('lne', '⪇'),
			_Utils_Tuple2('lnE', '≨'),
			_Utils_Tuple2('lneq', '⪇'),
			_Utils_Tuple2('lneqq', '≨'),
			_Utils_Tuple2('lnsim', '⋦'),
			_Utils_Tuple2('loang', '⟬'),
			_Utils_Tuple2('loarr', '⇽'),
			_Utils_Tuple2('lobrk', '⟦'),
			_Utils_Tuple2('longleftarrow', '⟵'),
			_Utils_Tuple2('LongLeftArrow', '⟵'),
			_Utils_Tuple2('Longleftarrow', '⟸'),
			_Utils_Tuple2('longleftrightarrow', '⟷'),
			_Utils_Tuple2('LongLeftRightArrow', '⟷'),
			_Utils_Tuple2('Longleftrightarrow', '⟺'),
			_Utils_Tuple2('longmapsto', '⟼'),
			_Utils_Tuple2('longrightarrow', '⟶'),
			_Utils_Tuple2('LongRightArrow', '⟶'),
			_Utils_Tuple2('Longrightarrow', '⟹'),
			_Utils_Tuple2('looparrowleft', '↫'),
			_Utils_Tuple2('looparrowright', '↬'),
			_Utils_Tuple2('lopar', '⦅'),
			_Utils_Tuple2('Lopf', '\uD835\uDD43'),
			_Utils_Tuple2('lopf', '\uD835\uDD5D'),
			_Utils_Tuple2('loplus', '⨭'),
			_Utils_Tuple2('lotimes', '⨴'),
			_Utils_Tuple2('lowast', '∗'),
			_Utils_Tuple2('lowbar', '_'),
			_Utils_Tuple2('LowerLeftArrow', '↙'),
			_Utils_Tuple2('LowerRightArrow', '↘'),
			_Utils_Tuple2('loz', '◊'),
			_Utils_Tuple2('lozenge', '◊'),
			_Utils_Tuple2('lozf', '⧫'),
			_Utils_Tuple2('lpar', '('),
			_Utils_Tuple2('lparlt', '⦓'),
			_Utils_Tuple2('lrarr', '⇆'),
			_Utils_Tuple2('lrcorner', '⌟'),
			_Utils_Tuple2('lrhar', '⇋'),
			_Utils_Tuple2('lrhard', '⥭'),
			_Utils_Tuple2('lrm', '\u200E'),
			_Utils_Tuple2('lrtri', '⊿'),
			_Utils_Tuple2('lsaquo', '‹'),
			_Utils_Tuple2('lscr', '\uD835\uDCC1'),
			_Utils_Tuple2('Lscr', 'ℒ'),
			_Utils_Tuple2('lsh', '↰'),
			_Utils_Tuple2('Lsh', '↰'),
			_Utils_Tuple2('lsim', '≲'),
			_Utils_Tuple2('lsime', '⪍'),
			_Utils_Tuple2('lsimg', '⪏'),
			_Utils_Tuple2('lsqb', '['),
			_Utils_Tuple2('lsquo', '‘'),
			_Utils_Tuple2('lsquor', '‚'),
			_Utils_Tuple2('Lstrok', 'Ł'),
			_Utils_Tuple2('lstrok', 'ł'),
			_Utils_Tuple2('ltcc', '⪦'),
			_Utils_Tuple2('ltcir', '⩹'),
			_Utils_Tuple2('lt', '<'),
			_Utils_Tuple2('LT', '<'),
			_Utils_Tuple2('Lt', '≪'),
			_Utils_Tuple2('ltdot', '⋖'),
			_Utils_Tuple2('lthree', '⋋'),
			_Utils_Tuple2('ltimes', '⋉'),
			_Utils_Tuple2('ltlarr', '⥶'),
			_Utils_Tuple2('ltquest', '⩻'),
			_Utils_Tuple2('ltri', '◃'),
			_Utils_Tuple2('ltrie', '⊴'),
			_Utils_Tuple2('ltrif', '◂'),
			_Utils_Tuple2('ltrPar', '⦖'),
			_Utils_Tuple2('lurdshar', '⥊'),
			_Utils_Tuple2('luruhar', '⥦'),
			_Utils_Tuple2('lvertneqq', '≨︀'),
			_Utils_Tuple2('lvnE', '≨︀'),
			_Utils_Tuple2('macr', '¯'),
			_Utils_Tuple2('male', '♂'),
			_Utils_Tuple2('malt', '✠'),
			_Utils_Tuple2('maltese', '✠'),
			_Utils_Tuple2('Map', '⤅'),
			_Utils_Tuple2('map', '↦'),
			_Utils_Tuple2('mapsto', '↦'),
			_Utils_Tuple2('mapstodown', '↧'),
			_Utils_Tuple2('mapstoleft', '↤'),
			_Utils_Tuple2('mapstoup', '↥'),
			_Utils_Tuple2('marker', '▮'),
			_Utils_Tuple2('mcomma', '⨩'),
			_Utils_Tuple2('Mcy', 'М'),
			_Utils_Tuple2('mcy', 'м'),
			_Utils_Tuple2('mdash', '—'),
			_Utils_Tuple2('mDDot', '∺'),
			_Utils_Tuple2('measuredangle', '∡'),
			_Utils_Tuple2('MediumSpace', '\u205F'),
			_Utils_Tuple2('Mellintrf', 'ℳ'),
			_Utils_Tuple2('Mfr', '\uD835\uDD10'),
			_Utils_Tuple2('mfr', '\uD835\uDD2A'),
			_Utils_Tuple2('mho', '℧'),
			_Utils_Tuple2('micro', 'µ'),
			_Utils_Tuple2('midast', '*'),
			_Utils_Tuple2('midcir', '⫰'),
			_Utils_Tuple2('mid', '∣'),
			_Utils_Tuple2('middot', '·'),
			_Utils_Tuple2('minusb', '⊟'),
			_Utils_Tuple2('minus', '−'),
			_Utils_Tuple2('minusd', '∸'),
			_Utils_Tuple2('minusdu', '⨪'),
			_Utils_Tuple2('MinusPlus', '∓'),
			_Utils_Tuple2('mlcp', '⫛'),
			_Utils_Tuple2('mldr', '…'),
			_Utils_Tuple2('mnplus', '∓'),
			_Utils_Tuple2('models', '⊧'),
			_Utils_Tuple2('Mopf', '\uD835\uDD44'),
			_Utils_Tuple2('mopf', '\uD835\uDD5E'),
			_Utils_Tuple2('mp', '∓'),
			_Utils_Tuple2('mscr', '\uD835\uDCC2'),
			_Utils_Tuple2('Mscr', 'ℳ'),
			_Utils_Tuple2('mstpos', '∾'),
			_Utils_Tuple2('Mu', 'Μ'),
			_Utils_Tuple2('mu', 'μ'),
			_Utils_Tuple2('multimap', '⊸'),
			_Utils_Tuple2('mumap', '⊸'),
			_Utils_Tuple2('nabla', '∇'),
			_Utils_Tuple2('Nacute', 'Ń'),
			_Utils_Tuple2('nacute', 'ń'),
			_Utils_Tuple2('nang', '∠⃒'),
			_Utils_Tuple2('nap', '≉'),
			_Utils_Tuple2('napE', '⩰̸'),
			_Utils_Tuple2('napid', '≋̸'),
			_Utils_Tuple2('napos', 'ŉ'),
			_Utils_Tuple2('napprox', '≉'),
			_Utils_Tuple2('natural', '♮'),
			_Utils_Tuple2('naturals', 'ℕ'),
			_Utils_Tuple2('natur', '♮'),
			_Utils_Tuple2('nbsp', '\u00A0'),
			_Utils_Tuple2('nbump', '≎̸'),
			_Utils_Tuple2('nbumpe', '≏̸'),
			_Utils_Tuple2('ncap', '⩃'),
			_Utils_Tuple2('Ncaron', 'Ň'),
			_Utils_Tuple2('ncaron', 'ň'),
			_Utils_Tuple2('Ncedil', 'Ņ'),
			_Utils_Tuple2('ncedil', 'ņ'),
			_Utils_Tuple2('ncong', '≇'),
			_Utils_Tuple2('ncongdot', '⩭̸'),
			_Utils_Tuple2('ncup', '⩂'),
			_Utils_Tuple2('Ncy', 'Н'),
			_Utils_Tuple2('ncy', 'н'),
			_Utils_Tuple2('ndash', '–'),
			_Utils_Tuple2('nearhk', '⤤'),
			_Utils_Tuple2('nearr', '↗'),
			_Utils_Tuple2('neArr', '⇗'),
			_Utils_Tuple2('nearrow', '↗'),
			_Utils_Tuple2('ne', '≠'),
			_Utils_Tuple2('nedot', '≐̸'),
			_Utils_Tuple2('NegativeMediumSpace', '\u200B'),
			_Utils_Tuple2('NegativeThickSpace', '\u200B'),
			_Utils_Tuple2('NegativeThinSpace', '\u200B'),
			_Utils_Tuple2('NegativeVeryThinSpace', '\u200B'),
			_Utils_Tuple2('nequiv', '≢'),
			_Utils_Tuple2('nesear', '⤨'),
			_Utils_Tuple2('nesim', '≂̸'),
			_Utils_Tuple2('NestedGreaterGreater', '≫'),
			_Utils_Tuple2('NestedLessLess', '≪'),
			_Utils_Tuple2('NewLine', '\n'),
			_Utils_Tuple2('nexist', '∄'),
			_Utils_Tuple2('nexists', '∄'),
			_Utils_Tuple2('Nfr', '\uD835\uDD11'),
			_Utils_Tuple2('nfr', '\uD835\uDD2B'),
			_Utils_Tuple2('ngE', '≧̸'),
			_Utils_Tuple2('nge', '≱'),
			_Utils_Tuple2('ngeq', '≱'),
			_Utils_Tuple2('ngeqq', '≧̸'),
			_Utils_Tuple2('ngeqslant', '⩾̸'),
			_Utils_Tuple2('nges', '⩾̸'),
			_Utils_Tuple2('nGg', '⋙̸'),
			_Utils_Tuple2('ngsim', '≵'),
			_Utils_Tuple2('nGt', '≫⃒'),
			_Utils_Tuple2('ngt', '≯'),
			_Utils_Tuple2('ngtr', '≯'),
			_Utils_Tuple2('nGtv', '≫̸'),
			_Utils_Tuple2('nharr', '↮'),
			_Utils_Tuple2('nhArr', '⇎'),
			_Utils_Tuple2('nhpar', '⫲'),
			_Utils_Tuple2('ni', '∋'),
			_Utils_Tuple2('nis', '⋼'),
			_Utils_Tuple2('nisd', '⋺'),
			_Utils_Tuple2('niv', '∋'),
			_Utils_Tuple2('NJcy', 'Њ'),
			_Utils_Tuple2('njcy', 'њ'),
			_Utils_Tuple2('nlarr', '↚'),
			_Utils_Tuple2('nlArr', '⇍'),
			_Utils_Tuple2('nldr', '‥'),
			_Utils_Tuple2('nlE', '≦̸'),
			_Utils_Tuple2('nle', '≰'),
			_Utils_Tuple2('nleftarrow', '↚'),
			_Utils_Tuple2('nLeftarrow', '⇍'),
			_Utils_Tuple2('nleftrightarrow', '↮'),
			_Utils_Tuple2('nLeftrightarrow', '⇎'),
			_Utils_Tuple2('nleq', '≰'),
			_Utils_Tuple2('nleqq', '≦̸'),
			_Utils_Tuple2('nleqslant', '⩽̸'),
			_Utils_Tuple2('nles', '⩽̸'),
			_Utils_Tuple2('nless', '≮'),
			_Utils_Tuple2('nLl', '⋘̸'),
			_Utils_Tuple2('nlsim', '≴'),
			_Utils_Tuple2('nLt', '≪⃒'),
			_Utils_Tuple2('nlt', '≮'),
			_Utils_Tuple2('nltri', '⋪'),
			_Utils_Tuple2('nltrie', '⋬'),
			_Utils_Tuple2('nLtv', '≪̸'),
			_Utils_Tuple2('nmid', '∤'),
			_Utils_Tuple2('NoBreak', '\u2060'),
			_Utils_Tuple2('NonBreakingSpace', '\u00A0'),
			_Utils_Tuple2('nopf', '\uD835\uDD5F'),
			_Utils_Tuple2('Nopf', 'ℕ'),
			_Utils_Tuple2('Not', '⫬'),
			_Utils_Tuple2('not', '¬'),
			_Utils_Tuple2('NotCongruent', '≢'),
			_Utils_Tuple2('NotCupCap', '≭'),
			_Utils_Tuple2('NotDoubleVerticalBar', '∦'),
			_Utils_Tuple2('NotElement', '∉'),
			_Utils_Tuple2('NotEqual', '≠'),
			_Utils_Tuple2('NotEqualTilde', '≂̸'),
			_Utils_Tuple2('NotExists', '∄'),
			_Utils_Tuple2('NotGreater', '≯'),
			_Utils_Tuple2('NotGreaterEqual', '≱'),
			_Utils_Tuple2('NotGreaterFullEqual', '≧̸'),
			_Utils_Tuple2('NotGreaterGreater', '≫̸'),
			_Utils_Tuple2('NotGreaterLess', '≹'),
			_Utils_Tuple2('NotGreaterSlantEqual', '⩾̸'),
			_Utils_Tuple2('NotGreaterTilde', '≵'),
			_Utils_Tuple2('NotHumpDownHump', '≎̸'),
			_Utils_Tuple2('NotHumpEqual', '≏̸'),
			_Utils_Tuple2('notin', '∉'),
			_Utils_Tuple2('notindot', '⋵̸'),
			_Utils_Tuple2('notinE', '⋹̸'),
			_Utils_Tuple2('notinva', '∉'),
			_Utils_Tuple2('notinvb', '⋷'),
			_Utils_Tuple2('notinvc', '⋶'),
			_Utils_Tuple2('NotLeftTriangleBar', '⧏̸'),
			_Utils_Tuple2('NotLeftTriangle', '⋪'),
			_Utils_Tuple2('NotLeftTriangleEqual', '⋬'),
			_Utils_Tuple2('NotLess', '≮'),
			_Utils_Tuple2('NotLessEqual', '≰'),
			_Utils_Tuple2('NotLessGreater', '≸'),
			_Utils_Tuple2('NotLessLess', '≪̸'),
			_Utils_Tuple2('NotLessSlantEqual', '⩽̸'),
			_Utils_Tuple2('NotLessTilde', '≴'),
			_Utils_Tuple2('NotNestedGreaterGreater', '⪢̸'),
			_Utils_Tuple2('NotNestedLessLess', '⪡̸'),
			_Utils_Tuple2('notni', '∌'),
			_Utils_Tuple2('notniva', '∌'),
			_Utils_Tuple2('notnivb', '⋾'),
			_Utils_Tuple2('notnivc', '⋽'),
			_Utils_Tuple2('NotPrecedes', '⊀'),
			_Utils_Tuple2('NotPrecedesEqual', '⪯̸'),
			_Utils_Tuple2('NotPrecedesSlantEqual', '⋠'),
			_Utils_Tuple2('NotReverseElement', '∌'),
			_Utils_Tuple2('NotRightTriangleBar', '⧐̸'),
			_Utils_Tuple2('NotRightTriangle', '⋫'),
			_Utils_Tuple2('NotRightTriangleEqual', '⋭'),
			_Utils_Tuple2('NotSquareSubset', '⊏̸'),
			_Utils_Tuple2('NotSquareSubsetEqual', '⋢'),
			_Utils_Tuple2('NotSquareSuperset', '⊐̸'),
			_Utils_Tuple2('NotSquareSupersetEqual', '⋣'),
			_Utils_Tuple2('NotSubset', '⊂⃒'),
			_Utils_Tuple2('NotSubsetEqual', '⊈'),
			_Utils_Tuple2('NotSucceeds', '⊁'),
			_Utils_Tuple2('NotSucceedsEqual', '⪰̸'),
			_Utils_Tuple2('NotSucceedsSlantEqual', '⋡'),
			_Utils_Tuple2('NotSucceedsTilde', '≿̸'),
			_Utils_Tuple2('NotSuperset', '⊃⃒'),
			_Utils_Tuple2('NotSupersetEqual', '⊉'),
			_Utils_Tuple2('NotTilde', '≁'),
			_Utils_Tuple2('NotTildeEqual', '≄'),
			_Utils_Tuple2('NotTildeFullEqual', '≇'),
			_Utils_Tuple2('NotTildeTilde', '≉'),
			_Utils_Tuple2('NotVerticalBar', '∤'),
			_Utils_Tuple2('nparallel', '∦'),
			_Utils_Tuple2('npar', '∦'),
			_Utils_Tuple2('nparsl', '⫽⃥'),
			_Utils_Tuple2('npart', '∂̸'),
			_Utils_Tuple2('npolint', '⨔'),
			_Utils_Tuple2('npr', '⊀'),
			_Utils_Tuple2('nprcue', '⋠'),
			_Utils_Tuple2('nprec', '⊀'),
			_Utils_Tuple2('npreceq', '⪯̸'),
			_Utils_Tuple2('npre', '⪯̸'),
			_Utils_Tuple2('nrarrc', '⤳̸'),
			_Utils_Tuple2('nrarr', '↛'),
			_Utils_Tuple2('nrArr', '⇏'),
			_Utils_Tuple2('nrarrw', '↝̸'),
			_Utils_Tuple2('nrightarrow', '↛'),
			_Utils_Tuple2('nRightarrow', '⇏'),
			_Utils_Tuple2('nrtri', '⋫'),
			_Utils_Tuple2('nrtrie', '⋭'),
			_Utils_Tuple2('nsc', '⊁'),
			_Utils_Tuple2('nsccue', '⋡'),
			_Utils_Tuple2('nsce', '⪰̸'),
			_Utils_Tuple2('Nscr', '\uD835\uDCA9'),
			_Utils_Tuple2('nscr', '\uD835\uDCC3'),
			_Utils_Tuple2('nshortmid', '∤'),
			_Utils_Tuple2('nshortparallel', '∦'),
			_Utils_Tuple2('nsim', '≁'),
			_Utils_Tuple2('nsime', '≄'),
			_Utils_Tuple2('nsimeq', '≄'),
			_Utils_Tuple2('nsmid', '∤'),
			_Utils_Tuple2('nspar', '∦'),
			_Utils_Tuple2('nsqsube', '⋢'),
			_Utils_Tuple2('nsqsupe', '⋣'),
			_Utils_Tuple2('nsub', '⊄'),
			_Utils_Tuple2('nsubE', '⫅̸'),
			_Utils_Tuple2('nsube', '⊈'),
			_Utils_Tuple2('nsubset', '⊂⃒'),
			_Utils_Tuple2('nsubseteq', '⊈'),
			_Utils_Tuple2('nsubseteqq', '⫅̸'),
			_Utils_Tuple2('nsucc', '⊁'),
			_Utils_Tuple2('nsucceq', '⪰̸'),
			_Utils_Tuple2('nsup', '⊅'),
			_Utils_Tuple2('nsupE', '⫆̸'),
			_Utils_Tuple2('nsupe', '⊉'),
			_Utils_Tuple2('nsupset', '⊃⃒'),
			_Utils_Tuple2('nsupseteq', '⊉'),
			_Utils_Tuple2('nsupseteqq', '⫆̸'),
			_Utils_Tuple2('ntgl', '≹'),
			_Utils_Tuple2('Ntilde', 'Ñ'),
			_Utils_Tuple2('ntilde', 'ñ'),
			_Utils_Tuple2('ntlg', '≸'),
			_Utils_Tuple2('ntriangleleft', '⋪'),
			_Utils_Tuple2('ntrianglelefteq', '⋬'),
			_Utils_Tuple2('ntriangleright', '⋫'),
			_Utils_Tuple2('ntrianglerighteq', '⋭'),
			_Utils_Tuple2('Nu', 'Ν'),
			_Utils_Tuple2('nu', 'ν'),
			_Utils_Tuple2('num', '#'),
			_Utils_Tuple2('numero', '№'),
			_Utils_Tuple2('numsp', '\u2007'),
			_Utils_Tuple2('nvap', '≍⃒'),
			_Utils_Tuple2('nvdash', '⊬'),
			_Utils_Tuple2('nvDash', '⊭'),
			_Utils_Tuple2('nVdash', '⊮'),
			_Utils_Tuple2('nVDash', '⊯'),
			_Utils_Tuple2('nvge', '≥⃒'),
			_Utils_Tuple2('nvgt', '>⃒'),
			_Utils_Tuple2('nvHarr', '⤄'),
			_Utils_Tuple2('nvinfin', '⧞'),
			_Utils_Tuple2('nvlArr', '⤂'),
			_Utils_Tuple2('nvle', '≤⃒'),
			_Utils_Tuple2('nvlt', '<⃒'),
			_Utils_Tuple2('nvltrie', '⊴⃒'),
			_Utils_Tuple2('nvrArr', '⤃'),
			_Utils_Tuple2('nvrtrie', '⊵⃒'),
			_Utils_Tuple2('nvsim', '∼⃒'),
			_Utils_Tuple2('nwarhk', '⤣'),
			_Utils_Tuple2('nwarr', '↖'),
			_Utils_Tuple2('nwArr', '⇖'),
			_Utils_Tuple2('nwarrow', '↖'),
			_Utils_Tuple2('nwnear', '⤧'),
			_Utils_Tuple2('Oacute', 'Ó'),
			_Utils_Tuple2('oacute', 'ó'),
			_Utils_Tuple2('oast', '⊛'),
			_Utils_Tuple2('Ocirc', 'Ô'),
			_Utils_Tuple2('ocirc', 'ô'),
			_Utils_Tuple2('ocir', '⊚'),
			_Utils_Tuple2('Ocy', 'О'),
			_Utils_Tuple2('ocy', 'о'),
			_Utils_Tuple2('odash', '⊝'),
			_Utils_Tuple2('Odblac', 'Ő'),
			_Utils_Tuple2('odblac', 'ő'),
			_Utils_Tuple2('odiv', '⨸'),
			_Utils_Tuple2('odot', '⊙'),
			_Utils_Tuple2('odsold', '⦼'),
			_Utils_Tuple2('OElig', 'Œ'),
			_Utils_Tuple2('oelig', 'œ'),
			_Utils_Tuple2('ofcir', '⦿'),
			_Utils_Tuple2('Ofr', '\uD835\uDD12'),
			_Utils_Tuple2('ofr', '\uD835\uDD2C'),
			_Utils_Tuple2('ogon', '˛'),
			_Utils_Tuple2('Ograve', 'Ò'),
			_Utils_Tuple2('ograve', 'ò'),
			_Utils_Tuple2('ogt', '⧁'),
			_Utils_Tuple2('ohbar', '⦵'),
			_Utils_Tuple2('ohm', 'Ω'),
			_Utils_Tuple2('oint', '∮'),
			_Utils_Tuple2('olarr', '↺'),
			_Utils_Tuple2('olcir', '⦾'),
			_Utils_Tuple2('olcross', '⦻'),
			_Utils_Tuple2('oline', '‾'),
			_Utils_Tuple2('olt', '⧀'),
			_Utils_Tuple2('Omacr', 'Ō'),
			_Utils_Tuple2('omacr', 'ō'),
			_Utils_Tuple2('Omega', 'Ω'),
			_Utils_Tuple2('omega', 'ω'),
			_Utils_Tuple2('Omicron', 'Ο'),
			_Utils_Tuple2('omicron', 'ο'),
			_Utils_Tuple2('omid', '⦶'),
			_Utils_Tuple2('ominus', '⊖'),
			_Utils_Tuple2('Oopf', '\uD835\uDD46'),
			_Utils_Tuple2('oopf', '\uD835\uDD60'),
			_Utils_Tuple2('opar', '⦷'),
			_Utils_Tuple2('OpenCurlyDoubleQuote', '“'),
			_Utils_Tuple2('OpenCurlyQuote', '‘'),
			_Utils_Tuple2('operp', '⦹'),
			_Utils_Tuple2('oplus', '⊕'),
			_Utils_Tuple2('orarr', '↻'),
			_Utils_Tuple2('Or', '⩔'),
			_Utils_Tuple2('or', '∨'),
			_Utils_Tuple2('ord', '⩝'),
			_Utils_Tuple2('order', 'ℴ'),
			_Utils_Tuple2('orderof', 'ℴ'),
			_Utils_Tuple2('ordf', 'ª'),
			_Utils_Tuple2('ordm', 'º'),
			_Utils_Tuple2('origof', '⊶'),
			_Utils_Tuple2('oror', '⩖'),
			_Utils_Tuple2('orslope', '⩗'),
			_Utils_Tuple2('orv', '⩛'),
			_Utils_Tuple2('oS', 'Ⓢ'),
			_Utils_Tuple2('Oscr', '\uD835\uDCAA'),
			_Utils_Tuple2('oscr', 'ℴ'),
			_Utils_Tuple2('Oslash', 'Ø'),
			_Utils_Tuple2('oslash', 'ø'),
			_Utils_Tuple2('osol', '⊘'),
			_Utils_Tuple2('Otilde', 'Õ'),
			_Utils_Tuple2('otilde', 'õ'),
			_Utils_Tuple2('otimesas', '⨶'),
			_Utils_Tuple2('Otimes', '⨷'),
			_Utils_Tuple2('otimes', '⊗'),
			_Utils_Tuple2('Ouml', 'Ö'),
			_Utils_Tuple2('ouml', 'ö'),
			_Utils_Tuple2('ovbar', '⌽'),
			_Utils_Tuple2('OverBar', '‾'),
			_Utils_Tuple2('OverBrace', '⏞'),
			_Utils_Tuple2('OverBracket', '⎴'),
			_Utils_Tuple2('OverParenthesis', '⏜'),
			_Utils_Tuple2('para', '¶'),
			_Utils_Tuple2('parallel', '∥'),
			_Utils_Tuple2('par', '∥'),
			_Utils_Tuple2('parsim', '⫳'),
			_Utils_Tuple2('parsl', '⫽'),
			_Utils_Tuple2('part', '∂'),
			_Utils_Tuple2('PartialD', '∂'),
			_Utils_Tuple2('Pcy', 'П'),
			_Utils_Tuple2('pcy', 'п'),
			_Utils_Tuple2('percnt', '%'),
			_Utils_Tuple2('period', '.'),
			_Utils_Tuple2('permil', '‰'),
			_Utils_Tuple2('perp', '⊥'),
			_Utils_Tuple2('pertenk', '‱'),
			_Utils_Tuple2('Pfr', '\uD835\uDD13'),
			_Utils_Tuple2('pfr', '\uD835\uDD2D'),
			_Utils_Tuple2('Phi', 'Φ'),
			_Utils_Tuple2('phi', 'φ'),
			_Utils_Tuple2('phiv', 'ϕ'),
			_Utils_Tuple2('phmmat', 'ℳ'),
			_Utils_Tuple2('phone', '☎'),
			_Utils_Tuple2('Pi', 'Π'),
			_Utils_Tuple2('pi', 'π'),
			_Utils_Tuple2('pitchfork', '⋔'),
			_Utils_Tuple2('piv', 'ϖ'),
			_Utils_Tuple2('planck', 'ℏ'),
			_Utils_Tuple2('planckh', 'ℎ'),
			_Utils_Tuple2('plankv', 'ℏ'),
			_Utils_Tuple2('plusacir', '⨣'),
			_Utils_Tuple2('plusb', '⊞'),
			_Utils_Tuple2('pluscir', '⨢'),
			_Utils_Tuple2('plus', '+'),
			_Utils_Tuple2('plusdo', '∔'),
			_Utils_Tuple2('plusdu', '⨥'),
			_Utils_Tuple2('pluse', '⩲'),
			_Utils_Tuple2('PlusMinus', '±'),
			_Utils_Tuple2('plusmn', '±'),
			_Utils_Tuple2('plussim', '⨦'),
			_Utils_Tuple2('plustwo', '⨧'),
			_Utils_Tuple2('pm', '±'),
			_Utils_Tuple2('Poincareplane', 'ℌ'),
			_Utils_Tuple2('pointint', '⨕'),
			_Utils_Tuple2('popf', '\uD835\uDD61'),
			_Utils_Tuple2('Popf', 'ℙ'),
			_Utils_Tuple2('pound', '£'),
			_Utils_Tuple2('prap', '⪷'),
			_Utils_Tuple2('Pr', '⪻'),
			_Utils_Tuple2('pr', '≺'),
			_Utils_Tuple2('prcue', '≼'),
			_Utils_Tuple2('precapprox', '⪷'),
			_Utils_Tuple2('prec', '≺'),
			_Utils_Tuple2('preccurlyeq', '≼'),
			_Utils_Tuple2('Precedes', '≺'),
			_Utils_Tuple2('PrecedesEqual', '⪯'),
			_Utils_Tuple2('PrecedesSlantEqual', '≼'),
			_Utils_Tuple2('PrecedesTilde', '≾'),
			_Utils_Tuple2('preceq', '⪯'),
			_Utils_Tuple2('precnapprox', '⪹'),
			_Utils_Tuple2('precneqq', '⪵'),
			_Utils_Tuple2('precnsim', '⋨'),
			_Utils_Tuple2('pre', '⪯'),
			_Utils_Tuple2('prE', '⪳'),
			_Utils_Tuple2('precsim', '≾'),
			_Utils_Tuple2('prime', '′'),
			_Utils_Tuple2('Prime', '″'),
			_Utils_Tuple2('primes', 'ℙ'),
			_Utils_Tuple2('prnap', '⪹'),
			_Utils_Tuple2('prnE', '⪵'),
			_Utils_Tuple2('prnsim', '⋨'),
			_Utils_Tuple2('prod', '∏'),
			_Utils_Tuple2('Product', '∏'),
			_Utils_Tuple2('profalar', '⌮'),
			_Utils_Tuple2('profline', '⌒'),
			_Utils_Tuple2('profsurf', '⌓'),
			_Utils_Tuple2('prop', '∝'),
			_Utils_Tuple2('Proportional', '∝'),
			_Utils_Tuple2('Proportion', '∷'),
			_Utils_Tuple2('propto', '∝'),
			_Utils_Tuple2('prsim', '≾'),
			_Utils_Tuple2('prurel', '⊰'),
			_Utils_Tuple2('Pscr', '\uD835\uDCAB'),
			_Utils_Tuple2('pscr', '\uD835\uDCC5'),
			_Utils_Tuple2('Psi', 'Ψ'),
			_Utils_Tuple2('psi', 'ψ'),
			_Utils_Tuple2('puncsp', '\u2008'),
			_Utils_Tuple2('Qfr', '\uD835\uDD14'),
			_Utils_Tuple2('qfr', '\uD835\uDD2E'),
			_Utils_Tuple2('qint', '⨌'),
			_Utils_Tuple2('qopf', '\uD835\uDD62'),
			_Utils_Tuple2('Qopf', 'ℚ'),
			_Utils_Tuple2('qprime', '⁗'),
			_Utils_Tuple2('Qscr', '\uD835\uDCAC'),
			_Utils_Tuple2('qscr', '\uD835\uDCC6'),
			_Utils_Tuple2('quaternions', 'ℍ'),
			_Utils_Tuple2('quatint', '⨖'),
			_Utils_Tuple2('quest', '?'),
			_Utils_Tuple2('questeq', '≟'),
			_Utils_Tuple2('quot', '\"'),
			_Utils_Tuple2('QUOT', '\"'),
			_Utils_Tuple2('rAarr', '⇛'),
			_Utils_Tuple2('race', '∽̱'),
			_Utils_Tuple2('Racute', 'Ŕ'),
			_Utils_Tuple2('racute', 'ŕ'),
			_Utils_Tuple2('radic', '√'),
			_Utils_Tuple2('raemptyv', '⦳'),
			_Utils_Tuple2('rang', '⟩'),
			_Utils_Tuple2('Rang', '⟫'),
			_Utils_Tuple2('rangd', '⦒'),
			_Utils_Tuple2('range', '⦥'),
			_Utils_Tuple2('rangle', '⟩'),
			_Utils_Tuple2('raquo', '»'),
			_Utils_Tuple2('rarrap', '⥵'),
			_Utils_Tuple2('rarrb', '⇥'),
			_Utils_Tuple2('rarrbfs', '⤠'),
			_Utils_Tuple2('rarrc', '⤳'),
			_Utils_Tuple2('rarr', '→'),
			_Utils_Tuple2('Rarr', '↠'),
			_Utils_Tuple2('rArr', '⇒'),
			_Utils_Tuple2('rarrfs', '⤞'),
			_Utils_Tuple2('rarrhk', '↪'),
			_Utils_Tuple2('rarrlp', '↬'),
			_Utils_Tuple2('rarrpl', '⥅'),
			_Utils_Tuple2('rarrsim', '⥴'),
			_Utils_Tuple2('Rarrtl', '⤖'),
			_Utils_Tuple2('rarrtl', '↣'),
			_Utils_Tuple2('rarrw', '↝'),
			_Utils_Tuple2('ratail', '⤚'),
			_Utils_Tuple2('rAtail', '⤜'),
			_Utils_Tuple2('ratio', '∶'),
			_Utils_Tuple2('rationals', 'ℚ'),
			_Utils_Tuple2('rbarr', '⤍'),
			_Utils_Tuple2('rBarr', '⤏'),
			_Utils_Tuple2('RBarr', '⤐'),
			_Utils_Tuple2('rbbrk', '❳'),
			_Utils_Tuple2('rbrace', '}'),
			_Utils_Tuple2('rbrack', ']'),
			_Utils_Tuple2('rbrke', '⦌'),
			_Utils_Tuple2('rbrksld', '⦎'),
			_Utils_Tuple2('rbrkslu', '⦐'),
			_Utils_Tuple2('Rcaron', 'Ř'),
			_Utils_Tuple2('rcaron', 'ř'),
			_Utils_Tuple2('Rcedil', 'Ŗ'),
			_Utils_Tuple2('rcedil', 'ŗ'),
			_Utils_Tuple2('rceil', '⌉'),
			_Utils_Tuple2('rcub', '}'),
			_Utils_Tuple2('Rcy', 'Р'),
			_Utils_Tuple2('rcy', 'р'),
			_Utils_Tuple2('rdca', '⤷'),
			_Utils_Tuple2('rdldhar', '⥩'),
			_Utils_Tuple2('rdquo', '”'),
			_Utils_Tuple2('rdquor', '”'),
			_Utils_Tuple2('rdsh', '↳'),
			_Utils_Tuple2('real', 'ℜ'),
			_Utils_Tuple2('realine', 'ℛ'),
			_Utils_Tuple2('realpart', 'ℜ'),
			_Utils_Tuple2('reals', 'ℝ'),
			_Utils_Tuple2('Re', 'ℜ'),
			_Utils_Tuple2('rect', '▭'),
			_Utils_Tuple2('reg', '®'),
			_Utils_Tuple2('REG', '®'),
			_Utils_Tuple2('ReverseElement', '∋'),
			_Utils_Tuple2('ReverseEquilibrium', '⇋'),
			_Utils_Tuple2('ReverseUpEquilibrium', '⥯'),
			_Utils_Tuple2('rfisht', '⥽'),
			_Utils_Tuple2('rfloor', '⌋'),
			_Utils_Tuple2('rfr', '\uD835\uDD2F'),
			_Utils_Tuple2('Rfr', 'ℜ'),
			_Utils_Tuple2('rHar', '⥤'),
			_Utils_Tuple2('rhard', '⇁'),
			_Utils_Tuple2('rharu', '⇀'),
			_Utils_Tuple2('rharul', '⥬'),
			_Utils_Tuple2('Rho', 'Ρ'),
			_Utils_Tuple2('rho', 'ρ'),
			_Utils_Tuple2('rhov', 'ϱ'),
			_Utils_Tuple2('RightAngleBracket', '⟩'),
			_Utils_Tuple2('RightArrowBar', '⇥'),
			_Utils_Tuple2('rightarrow', '→'),
			_Utils_Tuple2('RightArrow', '→'),
			_Utils_Tuple2('Rightarrow', '⇒'),
			_Utils_Tuple2('RightArrowLeftArrow', '⇄'),
			_Utils_Tuple2('rightarrowtail', '↣'),
			_Utils_Tuple2('RightCeiling', '⌉'),
			_Utils_Tuple2('RightDoubleBracket', '⟧'),
			_Utils_Tuple2('RightDownTeeVector', '⥝'),
			_Utils_Tuple2('RightDownVectorBar', '⥕'),
			_Utils_Tuple2('RightDownVector', '⇂'),
			_Utils_Tuple2('RightFloor', '⌋'),
			_Utils_Tuple2('rightharpoondown', '⇁'),
			_Utils_Tuple2('rightharpoonup', '⇀'),
			_Utils_Tuple2('rightleftarrows', '⇄'),
			_Utils_Tuple2('rightleftharpoons', '⇌'),
			_Utils_Tuple2('rightrightarrows', '⇉'),
			_Utils_Tuple2('rightsquigarrow', '↝'),
			_Utils_Tuple2('RightTeeArrow', '↦'),
			_Utils_Tuple2('RightTee', '⊢'),
			_Utils_Tuple2('RightTeeVector', '⥛'),
			_Utils_Tuple2('rightthreetimes', '⋌'),
			_Utils_Tuple2('RightTriangleBar', '⧐'),
			_Utils_Tuple2('RightTriangle', '⊳'),
			_Utils_Tuple2('RightTriangleEqual', '⊵'),
			_Utils_Tuple2('RightUpDownVector', '⥏'),
			_Utils_Tuple2('RightUpTeeVector', '⥜'),
			_Utils_Tuple2('RightUpVectorBar', '⥔'),
			_Utils_Tuple2('RightUpVector', '↾'),
			_Utils_Tuple2('RightVectorBar', '⥓'),
			_Utils_Tuple2('RightVector', '⇀'),
			_Utils_Tuple2('ring', '˚'),
			_Utils_Tuple2('risingdotseq', '≓'),
			_Utils_Tuple2('rlarr', '⇄'),
			_Utils_Tuple2('rlhar', '⇌'),
			_Utils_Tuple2('rlm', '\u200F'),
			_Utils_Tuple2('rmoustache', '⎱'),
			_Utils_Tuple2('rmoust', '⎱'),
			_Utils_Tuple2('rnmid', '⫮'),
			_Utils_Tuple2('roang', '⟭'),
			_Utils_Tuple2('roarr', '⇾'),
			_Utils_Tuple2('robrk', '⟧'),
			_Utils_Tuple2('ropar', '⦆'),
			_Utils_Tuple2('ropf', '\uD835\uDD63'),
			_Utils_Tuple2('Ropf', 'ℝ'),
			_Utils_Tuple2('roplus', '⨮'),
			_Utils_Tuple2('rotimes', '⨵'),
			_Utils_Tuple2('RoundImplies', '⥰'),
			_Utils_Tuple2('rpar', ')'),
			_Utils_Tuple2('rpargt', '⦔'),
			_Utils_Tuple2('rppolint', '⨒'),
			_Utils_Tuple2('rrarr', '⇉'),
			_Utils_Tuple2('Rrightarrow', '⇛'),
			_Utils_Tuple2('rsaquo', '›'),
			_Utils_Tuple2('rscr', '\uD835\uDCC7'),
			_Utils_Tuple2('Rscr', 'ℛ'),
			_Utils_Tuple2('rsh', '↱'),
			_Utils_Tuple2('Rsh', '↱'),
			_Utils_Tuple2('rsqb', ']'),
			_Utils_Tuple2('rsquo', '’'),
			_Utils_Tuple2('rsquor', '’'),
			_Utils_Tuple2('rthree', '⋌'),
			_Utils_Tuple2('rtimes', '⋊'),
			_Utils_Tuple2('rtri', '▹'),
			_Utils_Tuple2('rtrie', '⊵'),
			_Utils_Tuple2('rtrif', '▸'),
			_Utils_Tuple2('rtriltri', '⧎'),
			_Utils_Tuple2('RuleDelayed', '⧴'),
			_Utils_Tuple2('ruluhar', '⥨'),
			_Utils_Tuple2('rx', '℞'),
			_Utils_Tuple2('Sacute', 'Ś'),
			_Utils_Tuple2('sacute', 'ś'),
			_Utils_Tuple2('sbquo', '‚'),
			_Utils_Tuple2('scap', '⪸'),
			_Utils_Tuple2('Scaron', 'Š'),
			_Utils_Tuple2('scaron', 'š'),
			_Utils_Tuple2('Sc', '⪼'),
			_Utils_Tuple2('sc', '≻'),
			_Utils_Tuple2('sccue', '≽'),
			_Utils_Tuple2('sce', '⪰'),
			_Utils_Tuple2('scE', '⪴'),
			_Utils_Tuple2('Scedil', 'Ş'),
			_Utils_Tuple2('scedil', 'ş'),
			_Utils_Tuple2('Scirc', 'Ŝ'),
			_Utils_Tuple2('scirc', 'ŝ'),
			_Utils_Tuple2('scnap', '⪺'),
			_Utils_Tuple2('scnE', '⪶'),
			_Utils_Tuple2('scnsim', '⋩'),
			_Utils_Tuple2('scpolint', '⨓'),
			_Utils_Tuple2('scsim', '≿'),
			_Utils_Tuple2('Scy', 'С'),
			_Utils_Tuple2('scy', 'с'),
			_Utils_Tuple2('sdotb', '⊡'),
			_Utils_Tuple2('sdot', '⋅'),
			_Utils_Tuple2('sdote', '⩦'),
			_Utils_Tuple2('searhk', '⤥'),
			_Utils_Tuple2('searr', '↘'),
			_Utils_Tuple2('seArr', '⇘'),
			_Utils_Tuple2('searrow', '↘'),
			_Utils_Tuple2('sect', '§'),
			_Utils_Tuple2('semi', ';'),
			_Utils_Tuple2('seswar', '⤩'),
			_Utils_Tuple2('setminus', '∖'),
			_Utils_Tuple2('setmn', '∖'),
			_Utils_Tuple2('sext', '✶'),
			_Utils_Tuple2('Sfr', '\uD835\uDD16'),
			_Utils_Tuple2('sfr', '\uD835\uDD30'),
			_Utils_Tuple2('sfrown', '⌢'),
			_Utils_Tuple2('sharp', '♯'),
			_Utils_Tuple2('SHCHcy', 'Щ'),
			_Utils_Tuple2('shchcy', 'щ'),
			_Utils_Tuple2('SHcy', 'Ш'),
			_Utils_Tuple2('shcy', 'ш'),
			_Utils_Tuple2('ShortDownArrow', '↓'),
			_Utils_Tuple2('ShortLeftArrow', '←'),
			_Utils_Tuple2('shortmid', '∣'),
			_Utils_Tuple2('shortparallel', '∥'),
			_Utils_Tuple2('ShortRightArrow', '→'),
			_Utils_Tuple2('ShortUpArrow', '↑'),
			_Utils_Tuple2('shy', '\u00AD'),
			_Utils_Tuple2('Sigma', 'Σ'),
			_Utils_Tuple2('sigma', 'σ'),
			_Utils_Tuple2('sigmaf', 'ς'),
			_Utils_Tuple2('sigmav', 'ς'),
			_Utils_Tuple2('sim', '∼'),
			_Utils_Tuple2('simdot', '⩪'),
			_Utils_Tuple2('sime', '≃'),
			_Utils_Tuple2('simeq', '≃'),
			_Utils_Tuple2('simg', '⪞'),
			_Utils_Tuple2('simgE', '⪠'),
			_Utils_Tuple2('siml', '⪝'),
			_Utils_Tuple2('simlE', '⪟'),
			_Utils_Tuple2('simne', '≆'),
			_Utils_Tuple2('simplus', '⨤'),
			_Utils_Tuple2('simrarr', '⥲'),
			_Utils_Tuple2('slarr', '←'),
			_Utils_Tuple2('SmallCircle', '∘'),
			_Utils_Tuple2('smallsetminus', '∖'),
			_Utils_Tuple2('smashp', '⨳'),
			_Utils_Tuple2('smeparsl', '⧤'),
			_Utils_Tuple2('smid', '∣'),
			_Utils_Tuple2('smile', '⌣'),
			_Utils_Tuple2('smt', '⪪'),
			_Utils_Tuple2('smte', '⪬'),
			_Utils_Tuple2('smtes', '⪬︀'),
			_Utils_Tuple2('SOFTcy', 'Ь'),
			_Utils_Tuple2('softcy', 'ь'),
			_Utils_Tuple2('solbar', '⌿'),
			_Utils_Tuple2('solb', '⧄'),
			_Utils_Tuple2('sol', '/'),
			_Utils_Tuple2('Sopf', '\uD835\uDD4A'),
			_Utils_Tuple2('sopf', '\uD835\uDD64'),
			_Utils_Tuple2('spades', '♠'),
			_Utils_Tuple2('spadesuit', '♠'),
			_Utils_Tuple2('spar', '∥'),
			_Utils_Tuple2('sqcap', '⊓'),
			_Utils_Tuple2('sqcaps', '⊓︀'),
			_Utils_Tuple2('sqcup', '⊔'),
			_Utils_Tuple2('sqcups', '⊔︀'),
			_Utils_Tuple2('Sqrt', '√'),
			_Utils_Tuple2('sqsub', '⊏'),
			_Utils_Tuple2('sqsube', '⊑'),
			_Utils_Tuple2('sqsubset', '⊏'),
			_Utils_Tuple2('sqsubseteq', '⊑'),
			_Utils_Tuple2('sqsup', '⊐'),
			_Utils_Tuple2('sqsupe', '⊒'),
			_Utils_Tuple2('sqsupset', '⊐'),
			_Utils_Tuple2('sqsupseteq', '⊒'),
			_Utils_Tuple2('square', '□'),
			_Utils_Tuple2('Square', '□'),
			_Utils_Tuple2('SquareIntersection', '⊓'),
			_Utils_Tuple2('SquareSubset', '⊏'),
			_Utils_Tuple2('SquareSubsetEqual', '⊑'),
			_Utils_Tuple2('SquareSuperset', '⊐'),
			_Utils_Tuple2('SquareSupersetEqual', '⊒'),
			_Utils_Tuple2('SquareUnion', '⊔'),
			_Utils_Tuple2('squarf', '▪'),
			_Utils_Tuple2('squ', '□'),
			_Utils_Tuple2('squf', '▪'),
			_Utils_Tuple2('srarr', '→'),
			_Utils_Tuple2('Sscr', '\uD835\uDCAE'),
			_Utils_Tuple2('sscr', '\uD835\uDCC8'),
			_Utils_Tuple2('ssetmn', '∖'),
			_Utils_Tuple2('ssmile', '⌣'),
			_Utils_Tuple2('sstarf', '⋆'),
			_Utils_Tuple2('Star', '⋆'),
			_Utils_Tuple2('star', '☆'),
			_Utils_Tuple2('starf', '★'),
			_Utils_Tuple2('straightepsilon', 'ϵ'),
			_Utils_Tuple2('straightphi', 'ϕ'),
			_Utils_Tuple2('strns', '¯'),
			_Utils_Tuple2('sub', '⊂'),
			_Utils_Tuple2('Sub', '⋐'),
			_Utils_Tuple2('subdot', '⪽'),
			_Utils_Tuple2('subE', '⫅'),
			_Utils_Tuple2('sube', '⊆'),
			_Utils_Tuple2('subedot', '⫃'),
			_Utils_Tuple2('submult', '⫁'),
			_Utils_Tuple2('subnE', '⫋'),
			_Utils_Tuple2('subne', '⊊'),
			_Utils_Tuple2('subplus', '⪿'),
			_Utils_Tuple2('subrarr', '⥹'),
			_Utils_Tuple2('subset', '⊂'),
			_Utils_Tuple2('Subset', '⋐'),
			_Utils_Tuple2('subseteq', '⊆'),
			_Utils_Tuple2('subseteqq', '⫅'),
			_Utils_Tuple2('SubsetEqual', '⊆'),
			_Utils_Tuple2('subsetneq', '⊊'),
			_Utils_Tuple2('subsetneqq', '⫋'),
			_Utils_Tuple2('subsim', '⫇'),
			_Utils_Tuple2('subsub', '⫕'),
			_Utils_Tuple2('subsup', '⫓'),
			_Utils_Tuple2('succapprox', '⪸'),
			_Utils_Tuple2('succ', '≻'),
			_Utils_Tuple2('succcurlyeq', '≽'),
			_Utils_Tuple2('Succeeds', '≻'),
			_Utils_Tuple2('SucceedsEqual', '⪰'),
			_Utils_Tuple2('SucceedsSlantEqual', '≽'),
			_Utils_Tuple2('SucceedsTilde', '≿'),
			_Utils_Tuple2('succeq', '⪰'),
			_Utils_Tuple2('succnapprox', '⪺'),
			_Utils_Tuple2('succneqq', '⪶'),
			_Utils_Tuple2('succnsim', '⋩'),
			_Utils_Tuple2('succsim', '≿'),
			_Utils_Tuple2('SuchThat', '∋'),
			_Utils_Tuple2('sum', '∑'),
			_Utils_Tuple2('Sum', '∑'),
			_Utils_Tuple2('sung', '♪'),
			_Utils_Tuple2('sup1', '¹'),
			_Utils_Tuple2('sup2', '²'),
			_Utils_Tuple2('sup3', '³'),
			_Utils_Tuple2('sup', '⊃'),
			_Utils_Tuple2('Sup', '⋑'),
			_Utils_Tuple2('supdot', '⪾'),
			_Utils_Tuple2('supdsub', '⫘'),
			_Utils_Tuple2('supE', '⫆'),
			_Utils_Tuple2('supe', '⊇'),
			_Utils_Tuple2('supedot', '⫄'),
			_Utils_Tuple2('Superset', '⊃'),
			_Utils_Tuple2('SupersetEqual', '⊇'),
			_Utils_Tuple2('suphsol', '⟉'),
			_Utils_Tuple2('suphsub', '⫗'),
			_Utils_Tuple2('suplarr', '⥻'),
			_Utils_Tuple2('supmult', '⫂'),
			_Utils_Tuple2('supnE', '⫌'),
			_Utils_Tuple2('supne', '⊋'),
			_Utils_Tuple2('supplus', '⫀'),
			_Utils_Tuple2('supset', '⊃'),
			_Utils_Tuple2('Supset', '⋑'),
			_Utils_Tuple2('supseteq', '⊇'),
			_Utils_Tuple2('supseteqq', '⫆'),
			_Utils_Tuple2('supsetneq', '⊋'),
			_Utils_Tuple2('supsetneqq', '⫌'),
			_Utils_Tuple2('supsim', '⫈'),
			_Utils_Tuple2('supsub', '⫔'),
			_Utils_Tuple2('supsup', '⫖'),
			_Utils_Tuple2('swarhk', '⤦'),
			_Utils_Tuple2('swarr', '↙'),
			_Utils_Tuple2('swArr', '⇙'),
			_Utils_Tuple2('swarrow', '↙'),
			_Utils_Tuple2('swnwar', '⤪'),
			_Utils_Tuple2('szlig', 'ß'),
			_Utils_Tuple2('Tab', '\t'),
			_Utils_Tuple2('target', '⌖'),
			_Utils_Tuple2('Tau', 'Τ'),
			_Utils_Tuple2('tau', 'τ'),
			_Utils_Tuple2('tbrk', '⎴'),
			_Utils_Tuple2('Tcaron', 'Ť'),
			_Utils_Tuple2('tcaron', 'ť'),
			_Utils_Tuple2('Tcedil', 'Ţ'),
			_Utils_Tuple2('tcedil', 'ţ'),
			_Utils_Tuple2('Tcy', 'Т'),
			_Utils_Tuple2('tcy', 'т'),
			_Utils_Tuple2('tdot', '⃛'),
			_Utils_Tuple2('telrec', '⌕'),
			_Utils_Tuple2('Tfr', '\uD835\uDD17'),
			_Utils_Tuple2('tfr', '\uD835\uDD31'),
			_Utils_Tuple2('there4', '∴'),
			_Utils_Tuple2('therefore', '∴'),
			_Utils_Tuple2('Therefore', '∴'),
			_Utils_Tuple2('Theta', 'Θ'),
			_Utils_Tuple2('theta', 'θ'),
			_Utils_Tuple2('thetasym', 'ϑ'),
			_Utils_Tuple2('thetav', 'ϑ'),
			_Utils_Tuple2('thickapprox', '≈'),
			_Utils_Tuple2('thicksim', '∼'),
			_Utils_Tuple2('ThickSpace', '\u205F\u200A'),
			_Utils_Tuple2('ThinSpace', '\u2009'),
			_Utils_Tuple2('thinsp', '\u2009'),
			_Utils_Tuple2('thkap', '≈'),
			_Utils_Tuple2('thksim', '∼'),
			_Utils_Tuple2('THORN', 'Þ'),
			_Utils_Tuple2('thorn', 'þ'),
			_Utils_Tuple2('tilde', '˜'),
			_Utils_Tuple2('Tilde', '∼'),
			_Utils_Tuple2('TildeEqual', '≃'),
			_Utils_Tuple2('TildeFullEqual', '≅'),
			_Utils_Tuple2('TildeTilde', '≈'),
			_Utils_Tuple2('timesbar', '⨱'),
			_Utils_Tuple2('timesb', '⊠'),
			_Utils_Tuple2('times', '×'),
			_Utils_Tuple2('timesd', '⨰'),
			_Utils_Tuple2('tint', '∭'),
			_Utils_Tuple2('toea', '⤨'),
			_Utils_Tuple2('topbot', '⌶'),
			_Utils_Tuple2('topcir', '⫱'),
			_Utils_Tuple2('top', '⊤'),
			_Utils_Tuple2('Topf', '\uD835\uDD4B'),
			_Utils_Tuple2('topf', '\uD835\uDD65'),
			_Utils_Tuple2('topfork', '⫚'),
			_Utils_Tuple2('tosa', '⤩'),
			_Utils_Tuple2('tprime', '‴'),
			_Utils_Tuple2('trade', '™'),
			_Utils_Tuple2('TRADE', '™'),
			_Utils_Tuple2('triangle', '▵'),
			_Utils_Tuple2('triangledown', '▿'),
			_Utils_Tuple2('triangleleft', '◃'),
			_Utils_Tuple2('trianglelefteq', '⊴'),
			_Utils_Tuple2('triangleq', '≜'),
			_Utils_Tuple2('triangleright', '▹'),
			_Utils_Tuple2('trianglerighteq', '⊵'),
			_Utils_Tuple2('tridot', '◬'),
			_Utils_Tuple2('trie', '≜'),
			_Utils_Tuple2('triminus', '⨺'),
			_Utils_Tuple2('TripleDot', '⃛'),
			_Utils_Tuple2('triplus', '⨹'),
			_Utils_Tuple2('trisb', '⧍'),
			_Utils_Tuple2('tritime', '⨻'),
			_Utils_Tuple2('trpezium', '⏢'),
			_Utils_Tuple2('Tscr', '\uD835\uDCAF'),
			_Utils_Tuple2('tscr', '\uD835\uDCC9'),
			_Utils_Tuple2('TScy', 'Ц'),
			_Utils_Tuple2('tscy', 'ц'),
			_Utils_Tuple2('TSHcy', 'Ћ'),
			_Utils_Tuple2('tshcy', 'ћ'),
			_Utils_Tuple2('Tstrok', 'Ŧ'),
			_Utils_Tuple2('tstrok', 'ŧ'),
			_Utils_Tuple2('twixt', '≬'),
			_Utils_Tuple2('twoheadleftarrow', '↞'),
			_Utils_Tuple2('twoheadrightarrow', '↠'),
			_Utils_Tuple2('Uacute', 'Ú'),
			_Utils_Tuple2('uacute', 'ú'),
			_Utils_Tuple2('uarr', '↑'),
			_Utils_Tuple2('Uarr', '↟'),
			_Utils_Tuple2('uArr', '⇑'),
			_Utils_Tuple2('Uarrocir', '⥉'),
			_Utils_Tuple2('Ubrcy', 'Ў'),
			_Utils_Tuple2('ubrcy', 'ў'),
			_Utils_Tuple2('Ubreve', 'Ŭ'),
			_Utils_Tuple2('ubreve', 'ŭ'),
			_Utils_Tuple2('Ucirc', 'Û'),
			_Utils_Tuple2('ucirc', 'û'),
			_Utils_Tuple2('Ucy', 'У'),
			_Utils_Tuple2('ucy', 'у'),
			_Utils_Tuple2('udarr', '⇅'),
			_Utils_Tuple2('Udblac', 'Ű'),
			_Utils_Tuple2('udblac', 'ű'),
			_Utils_Tuple2('udhar', '⥮'),
			_Utils_Tuple2('ufisht', '⥾'),
			_Utils_Tuple2('Ufr', '\uD835\uDD18'),
			_Utils_Tuple2('ufr', '\uD835\uDD32'),
			_Utils_Tuple2('Ugrave', 'Ù'),
			_Utils_Tuple2('ugrave', 'ù'),
			_Utils_Tuple2('uHar', '⥣'),
			_Utils_Tuple2('uharl', '↿'),
			_Utils_Tuple2('uharr', '↾'),
			_Utils_Tuple2('uhblk', '▀'),
			_Utils_Tuple2('ulcorn', '⌜'),
			_Utils_Tuple2('ulcorner', '⌜'),
			_Utils_Tuple2('ulcrop', '⌏'),
			_Utils_Tuple2('ultri', '◸'),
			_Utils_Tuple2('Umacr', 'Ū'),
			_Utils_Tuple2('umacr', 'ū'),
			_Utils_Tuple2('uml', '¨'),
			_Utils_Tuple2('UnderBar', '_'),
			_Utils_Tuple2('UnderBrace', '⏟'),
			_Utils_Tuple2('UnderBracket', '⎵'),
			_Utils_Tuple2('UnderParenthesis', '⏝'),
			_Utils_Tuple2('Union', '⋃'),
			_Utils_Tuple2('UnionPlus', '⊎'),
			_Utils_Tuple2('Uogon', 'Ų'),
			_Utils_Tuple2('uogon', 'ų'),
			_Utils_Tuple2('Uopf', '\uD835\uDD4C'),
			_Utils_Tuple2('uopf', '\uD835\uDD66'),
			_Utils_Tuple2('UpArrowBar', '⤒'),
			_Utils_Tuple2('uparrow', '↑'),
			_Utils_Tuple2('UpArrow', '↑'),
			_Utils_Tuple2('Uparrow', '⇑'),
			_Utils_Tuple2('UpArrowDownArrow', '⇅'),
			_Utils_Tuple2('updownarrow', '↕'),
			_Utils_Tuple2('UpDownArrow', '↕'),
			_Utils_Tuple2('Updownarrow', '⇕'),
			_Utils_Tuple2('UpEquilibrium', '⥮'),
			_Utils_Tuple2('upharpoonleft', '↿'),
			_Utils_Tuple2('upharpoonright', '↾'),
			_Utils_Tuple2('uplus', '⊎'),
			_Utils_Tuple2('UpperLeftArrow', '↖'),
			_Utils_Tuple2('UpperRightArrow', '↗'),
			_Utils_Tuple2('upsi', 'υ'),
			_Utils_Tuple2('Upsi', 'ϒ'),
			_Utils_Tuple2('upsih', 'ϒ'),
			_Utils_Tuple2('Upsilon', 'Υ'),
			_Utils_Tuple2('upsilon', 'υ'),
			_Utils_Tuple2('UpTeeArrow', '↥'),
			_Utils_Tuple2('UpTee', '⊥'),
			_Utils_Tuple2('upuparrows', '⇈'),
			_Utils_Tuple2('urcorn', '⌝'),
			_Utils_Tuple2('urcorner', '⌝'),
			_Utils_Tuple2('urcrop', '⌎'),
			_Utils_Tuple2('Uring', 'Ů'),
			_Utils_Tuple2('uring', 'ů'),
			_Utils_Tuple2('urtri', '◹'),
			_Utils_Tuple2('Uscr', '\uD835\uDCB0'),
			_Utils_Tuple2('uscr', '\uD835\uDCCA'),
			_Utils_Tuple2('utdot', '⋰'),
			_Utils_Tuple2('Utilde', 'Ũ'),
			_Utils_Tuple2('utilde', 'ũ'),
			_Utils_Tuple2('utri', '▵'),
			_Utils_Tuple2('utrif', '▴'),
			_Utils_Tuple2('uuarr', '⇈'),
			_Utils_Tuple2('Uuml', 'Ü'),
			_Utils_Tuple2('uuml', 'ü'),
			_Utils_Tuple2('uwangle', '⦧'),
			_Utils_Tuple2('vangrt', '⦜'),
			_Utils_Tuple2('varepsilon', 'ϵ'),
			_Utils_Tuple2('varkappa', 'ϰ'),
			_Utils_Tuple2('varnothing', '∅'),
			_Utils_Tuple2('varphi', 'ϕ'),
			_Utils_Tuple2('varpi', 'ϖ'),
			_Utils_Tuple2('varpropto', '∝'),
			_Utils_Tuple2('varr', '↕'),
			_Utils_Tuple2('vArr', '⇕'),
			_Utils_Tuple2('varrho', 'ϱ'),
			_Utils_Tuple2('varsigma', 'ς'),
			_Utils_Tuple2('varsubsetneq', '⊊︀'),
			_Utils_Tuple2('varsubsetneqq', '⫋︀'),
			_Utils_Tuple2('varsupsetneq', '⊋︀'),
			_Utils_Tuple2('varsupsetneqq', '⫌︀'),
			_Utils_Tuple2('vartheta', 'ϑ'),
			_Utils_Tuple2('vartriangleleft', '⊲'),
			_Utils_Tuple2('vartriangleright', '⊳'),
			_Utils_Tuple2('vBar', '⫨'),
			_Utils_Tuple2('Vbar', '⫫'),
			_Utils_Tuple2('vBarv', '⫩'),
			_Utils_Tuple2('Vcy', 'В'),
			_Utils_Tuple2('vcy', 'в'),
			_Utils_Tuple2('vdash', '⊢'),
			_Utils_Tuple2('vDash', '⊨'),
			_Utils_Tuple2('Vdash', '⊩'),
			_Utils_Tuple2('VDash', '⊫'),
			_Utils_Tuple2('Vdashl', '⫦'),
			_Utils_Tuple2('veebar', '⊻'),
			_Utils_Tuple2('vee', '∨'),
			_Utils_Tuple2('Vee', '⋁'),
			_Utils_Tuple2('veeeq', '≚'),
			_Utils_Tuple2('vellip', '⋮'),
			_Utils_Tuple2('verbar', '|'),
			_Utils_Tuple2('Verbar', '‖'),
			_Utils_Tuple2('vert', '|'),
			_Utils_Tuple2('Vert', '‖'),
			_Utils_Tuple2('VerticalBar', '∣'),
			_Utils_Tuple2('VerticalLine', '|'),
			_Utils_Tuple2('VerticalSeparator', '❘'),
			_Utils_Tuple2('VerticalTilde', '≀'),
			_Utils_Tuple2('VeryThinSpace', '\u200A'),
			_Utils_Tuple2('Vfr', '\uD835\uDD19'),
			_Utils_Tuple2('vfr', '\uD835\uDD33'),
			_Utils_Tuple2('vltri', '⊲'),
			_Utils_Tuple2('vnsub', '⊂⃒'),
			_Utils_Tuple2('vnsup', '⊃⃒'),
			_Utils_Tuple2('Vopf', '\uD835\uDD4D'),
			_Utils_Tuple2('vopf', '\uD835\uDD67'),
			_Utils_Tuple2('vprop', '∝'),
			_Utils_Tuple2('vrtri', '⊳'),
			_Utils_Tuple2('Vscr', '\uD835\uDCB1'),
			_Utils_Tuple2('vscr', '\uD835\uDCCB'),
			_Utils_Tuple2('vsubnE', '⫋︀'),
			_Utils_Tuple2('vsubne', '⊊︀'),
			_Utils_Tuple2('vsupnE', '⫌︀'),
			_Utils_Tuple2('vsupne', '⊋︀'),
			_Utils_Tuple2('Vvdash', '⊪'),
			_Utils_Tuple2('vzigzag', '⦚'),
			_Utils_Tuple2('Wcirc', 'Ŵ'),
			_Utils_Tuple2('wcirc', 'ŵ'),
			_Utils_Tuple2('wedbar', '⩟'),
			_Utils_Tuple2('wedge', '∧'),
			_Utils_Tuple2('Wedge', '⋀'),
			_Utils_Tuple2('wedgeq', '≙'),
			_Utils_Tuple2('weierp', '℘'),
			_Utils_Tuple2('Wfr', '\uD835\uDD1A'),
			_Utils_Tuple2('wfr', '\uD835\uDD34'),
			_Utils_Tuple2('Wopf', '\uD835\uDD4E'),
			_Utils_Tuple2('wopf', '\uD835\uDD68'),
			_Utils_Tuple2('wp', '℘'),
			_Utils_Tuple2('wr', '≀'),
			_Utils_Tuple2('wreath', '≀'),
			_Utils_Tuple2('Wscr', '\uD835\uDCB2'),
			_Utils_Tuple2('wscr', '\uD835\uDCCC'),
			_Utils_Tuple2('xcap', '⋂'),
			_Utils_Tuple2('xcirc', '◯'),
			_Utils_Tuple2('xcup', '⋃'),
			_Utils_Tuple2('xdtri', '▽'),
			_Utils_Tuple2('Xfr', '\uD835\uDD1B'),
			_Utils_Tuple2('xfr', '\uD835\uDD35'),
			_Utils_Tuple2('xharr', '⟷'),
			_Utils_Tuple2('xhArr', '⟺'),
			_Utils_Tuple2('Xi', 'Ξ'),
			_Utils_Tuple2('xi', 'ξ'),
			_Utils_Tuple2('xlarr', '⟵'),
			_Utils_Tuple2('xlArr', '⟸'),
			_Utils_Tuple2('xmap', '⟼'),
			_Utils_Tuple2('xnis', '⋻'),
			_Utils_Tuple2('xodot', '⨀'),
			_Utils_Tuple2('Xopf', '\uD835\uDD4F'),
			_Utils_Tuple2('xopf', '\uD835\uDD69'),
			_Utils_Tuple2('xoplus', '⨁'),
			_Utils_Tuple2('xotime', '⨂'),
			_Utils_Tuple2('xrarr', '⟶'),
			_Utils_Tuple2('xrArr', '⟹'),
			_Utils_Tuple2('Xscr', '\uD835\uDCB3'),
			_Utils_Tuple2('xscr', '\uD835\uDCCD'),
			_Utils_Tuple2('xsqcup', '⨆'),
			_Utils_Tuple2('xuplus', '⨄'),
			_Utils_Tuple2('xutri', '△'),
			_Utils_Tuple2('xvee', '⋁'),
			_Utils_Tuple2('xwedge', '⋀'),
			_Utils_Tuple2('Yacute', 'Ý'),
			_Utils_Tuple2('yacute', 'ý'),
			_Utils_Tuple2('YAcy', 'Я'),
			_Utils_Tuple2('yacy', 'я'),
			_Utils_Tuple2('Ycirc', 'Ŷ'),
			_Utils_Tuple2('ycirc', 'ŷ'),
			_Utils_Tuple2('Ycy', 'Ы'),
			_Utils_Tuple2('ycy', 'ы'),
			_Utils_Tuple2('yen', '¥'),
			_Utils_Tuple2('Yfr', '\uD835\uDD1C'),
			_Utils_Tuple2('yfr', '\uD835\uDD36'),
			_Utils_Tuple2('YIcy', 'Ї'),
			_Utils_Tuple2('yicy', 'ї'),
			_Utils_Tuple2('Yopf', '\uD835\uDD50'),
			_Utils_Tuple2('yopf', '\uD835\uDD6A'),
			_Utils_Tuple2('Yscr', '\uD835\uDCB4'),
			_Utils_Tuple2('yscr', '\uD835\uDCCE'),
			_Utils_Tuple2('YUcy', 'Ю'),
			_Utils_Tuple2('yucy', 'ю'),
			_Utils_Tuple2('yuml', 'ÿ'),
			_Utils_Tuple2('Yuml', 'Ÿ'),
			_Utils_Tuple2('Zacute', 'Ź'),
			_Utils_Tuple2('zacute', 'ź'),
			_Utils_Tuple2('Zcaron', 'Ž'),
			_Utils_Tuple2('zcaron', 'ž'),
			_Utils_Tuple2('Zcy', 'З'),
			_Utils_Tuple2('zcy', 'з'),
			_Utils_Tuple2('Zdot', 'Ż'),
			_Utils_Tuple2('zdot', 'ż'),
			_Utils_Tuple2('zeetrf', 'ℨ'),
			_Utils_Tuple2('ZeroWidthSpace', '\u200B'),
			_Utils_Tuple2('Zeta', 'Ζ'),
			_Utils_Tuple2('zeta', 'ζ'),
			_Utils_Tuple2('zfr', '\uD835\uDD37'),
			_Utils_Tuple2('Zfr', 'ℨ'),
			_Utils_Tuple2('ZHcy', 'Ж'),
			_Utils_Tuple2('zhcy', 'ж'),
			_Utils_Tuple2('zigrarr', '⇝'),
			_Utils_Tuple2('zopf', '\uD835\uDD6B'),
			_Utils_Tuple2('Zopf', 'ℤ'),
			_Utils_Tuple2('Zscr', '\uD835\uDCB5'),
			_Utils_Tuple2('zscr', '\uD835\uDCCF'),
			_Utils_Tuple2('zwj', '\u200D'),
			_Utils_Tuple2('zwnj', '\u200C')
		]));
var $hecrj$html_parser$Html$Parser$namedCharacterReference = A2(
	$elm$parser$Parser$map,
	function (reference) {
		return A2(
			$elm$core$Maybe$withDefault,
			'&' + (reference + ';'),
			A2($elm$core$Dict$get, reference, $hecrj$html_parser$Html$Parser$NamedCharacterReferences$dict));
	},
	$elm$parser$Parser$getChompedString(
		$hecrj$html_parser$Html$Parser$chompOneOrMore($elm$core$Char$isAlpha)));
var $elm$core$Char$fromCode = _Char_fromCode;
var $elm$core$Basics$pow = _Basics_pow;
var $rtfeldman$elm_hex$Hex$fromStringHelp = F3(
	function (position, chars, accumulated) {
		fromStringHelp:
		while (true) {
			if (!chars.b) {
				return $elm$core$Result$Ok(accumulated);
			} else {
				var _char = chars.a;
				var rest = chars.b;
				switch (_char) {
					case '0':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated;
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '1':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + A2($elm$core$Basics$pow, 16, position);
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '2':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (2 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '3':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (3 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '4':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (4 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '5':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (5 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '6':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (6 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '7':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (7 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '8':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (8 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '9':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (9 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'a':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (10 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'b':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (11 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'c':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (12 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'd':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (13 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'e':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (14 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'f':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (15 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					default:
						var nonHex = _char;
						return $elm$core$Result$Err(
							$elm$core$String$fromChar(nonHex) + ' is not a valid hexadecimal character.');
				}
			}
		}
	});
var $elm$core$List$tail = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(xs);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $rtfeldman$elm_hex$Hex$fromString = function (str) {
	if ($elm$core$String$isEmpty(str)) {
		return $elm$core$Result$Err('Empty strings are not valid hexadecimal strings.');
	} else {
		var result = function () {
			if (A2($elm$core$String$startsWith, '-', str)) {
				var list = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$elm$core$List$tail(
						$elm$core$String$toList(str)));
				return A2(
					$elm$core$Result$map,
					$elm$core$Basics$negate,
					A3(
						$rtfeldman$elm_hex$Hex$fromStringHelp,
						$elm$core$List$length(list) - 1,
						list,
						0));
			} else {
				return A3(
					$rtfeldman$elm_hex$Hex$fromStringHelp,
					$elm$core$String$length(str) - 1,
					$elm$core$String$toList(str),
					0);
			}
		}();
		var formatError = function (err) {
			return A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					['\"' + (str + '\"'), 'is not a valid hexadecimal string because', err]));
		};
		return A2($elm$core$Result$mapError, formatError, result);
	}
};
var $elm$core$Char$isHexDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return ((48 <= code) && (code <= 57)) || (((65 <= code) && (code <= 70)) || ((97 <= code) && (code <= 102)));
};
var $hecrj$html_parser$Html$Parser$hexadecimal = A2(
	$elm$parser$Parser$andThen,
	function (hex) {
		var _v0 = $rtfeldman$elm_hex$Hex$fromString(
			$elm$core$String$toLower(hex));
		if (!_v0.$) {
			var value = _v0.a;
			return $elm$parser$Parser$succeed(value);
		} else {
			var error = _v0.a;
			return $elm$parser$Parser$problem(error);
		}
	},
	$elm$parser$Parser$getChompedString(
		$hecrj$html_parser$Html$Parser$chompOneOrMore($elm$core$Char$isHexDigit)));
var $elm$parser$Parser$ExpectingInt = {$: 1};
var $elm$parser$Parser$Advanced$consumeBase = _Parser_consumeBase;
var $elm$parser$Parser$Advanced$consumeBase16 = _Parser_consumeBase16;
var $elm$parser$Parser$Advanced$bumpOffset = F2(
	function (newOffset, s) {
		return {ft: s.ft + (newOffset - s.f1), j: s.j, l: s.l, f1: newOffset, iP: s.iP, oc: s.oc};
	});
var $elm$parser$Parser$Advanced$chompBase10 = _Parser_chompBase10;
var $elm$parser$Parser$Advanced$isAsciiCode = _Parser_isAsciiCode;
var $elm$parser$Parser$Advanced$consumeExp = F2(
	function (offset, src) {
		if (A3($elm$parser$Parser$Advanced$isAsciiCode, 101, offset, src) || A3($elm$parser$Parser$Advanced$isAsciiCode, 69, offset, src)) {
			var eOffset = offset + 1;
			var expOffset = (A3($elm$parser$Parser$Advanced$isAsciiCode, 43, eOffset, src) || A3($elm$parser$Parser$Advanced$isAsciiCode, 45, eOffset, src)) ? (eOffset + 1) : eOffset;
			var newOffset = A2($elm$parser$Parser$Advanced$chompBase10, expOffset, src);
			return _Utils_eq(expOffset, newOffset) ? (-newOffset) : newOffset;
		} else {
			return offset;
		}
	});
var $elm$parser$Parser$Advanced$consumeDotAndExp = F2(
	function (offset, src) {
		return A3($elm$parser$Parser$Advanced$isAsciiCode, 46, offset, src) ? A2(
			$elm$parser$Parser$Advanced$consumeExp,
			A2($elm$parser$Parser$Advanced$chompBase10, offset + 1, src),
			src) : A2($elm$parser$Parser$Advanced$consumeExp, offset, src);
	});
var $elm$parser$Parser$Advanced$finalizeInt = F5(
	function (invalid, handler, startOffset, _v0, s) {
		var endOffset = _v0.a;
		var n = _v0.b;
		if (handler.$ === 1) {
			var x = handler.a;
			return A2(
				$elm$parser$Parser$Advanced$Bad,
				true,
				A2($elm$parser$Parser$Advanced$fromState, s, x));
		} else {
			var toValue = handler.a;
			return _Utils_eq(startOffset, endOffset) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				_Utils_cmp(s.f1, startOffset) < 0,
				A2($elm$parser$Parser$Advanced$fromState, s, invalid)) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				toValue(n),
				A2($elm$parser$Parser$Advanced$bumpOffset, endOffset, s));
		}
	});
var $elm$parser$Parser$Advanced$finalizeFloat = F6(
	function (invalid, expecting, intSettings, floatSettings, intPair, s) {
		var intOffset = intPair.a;
		var floatOffset = A2($elm$parser$Parser$Advanced$consumeDotAndExp, intOffset, s.oc);
		if (floatOffset < 0) {
			return A2(
				$elm$parser$Parser$Advanced$Bad,
				true,
				A4($elm$parser$Parser$Advanced$fromInfo, s.iP, s.ft - (floatOffset + s.f1), invalid, s.j));
		} else {
			if (_Utils_eq(s.f1, floatOffset)) {
				return A2(
					$elm$parser$Parser$Advanced$Bad,
					false,
					A2($elm$parser$Parser$Advanced$fromState, s, expecting));
			} else {
				if (_Utils_eq(intOffset, floatOffset)) {
					return A5($elm$parser$Parser$Advanced$finalizeInt, invalid, intSettings, s.f1, intPair, s);
				} else {
					if (floatSettings.$ === 1) {
						var x = floatSettings.a;
						return A2(
							$elm$parser$Parser$Advanced$Bad,
							true,
							A2($elm$parser$Parser$Advanced$fromState, s, invalid));
					} else {
						var toValue = floatSettings.a;
						var _v1 = $elm$core$String$toFloat(
							A3($elm$core$String$slice, s.f1, floatOffset, s.oc));
						if (_v1.$ === 1) {
							return A2(
								$elm$parser$Parser$Advanced$Bad,
								true,
								A2($elm$parser$Parser$Advanced$fromState, s, invalid));
						} else {
							var n = _v1.a;
							return A3(
								$elm$parser$Parser$Advanced$Good,
								true,
								toValue(n),
								A2($elm$parser$Parser$Advanced$bumpOffset, floatOffset, s));
						}
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$number = function (c) {
	return function (s) {
		if (A3($elm$parser$Parser$Advanced$isAsciiCode, 48, s.f1, s.oc)) {
			var zeroOffset = s.f1 + 1;
			var baseOffset = zeroOffset + 1;
			return A3($elm$parser$Parser$Advanced$isAsciiCode, 120, zeroOffset, s.oc) ? A5(
				$elm$parser$Parser$Advanced$finalizeInt,
				c.lZ,
				c.lI,
				baseOffset,
				A2($elm$parser$Parser$Advanced$consumeBase16, baseOffset, s.oc),
				s) : (A3($elm$parser$Parser$Advanced$isAsciiCode, 111, zeroOffset, s.oc) ? A5(
				$elm$parser$Parser$Advanced$finalizeInt,
				c.lZ,
				c.id,
				baseOffset,
				A3($elm$parser$Parser$Advanced$consumeBase, 8, baseOffset, s.oc),
				s) : (A3($elm$parser$Parser$Advanced$isAsciiCode, 98, zeroOffset, s.oc) ? A5(
				$elm$parser$Parser$Advanced$finalizeInt,
				c.lZ,
				c.gY,
				baseOffset,
				A3($elm$parser$Parser$Advanced$consumeBase, 2, baseOffset, s.oc),
				s) : A6(
				$elm$parser$Parser$Advanced$finalizeFloat,
				c.lZ,
				c.hq,
				c.hV,
				c.ht,
				_Utils_Tuple2(zeroOffset, 0),
				s)));
		} else {
			return A6(
				$elm$parser$Parser$Advanced$finalizeFloat,
				c.lZ,
				c.hq,
				c.hV,
				c.ht,
				A3($elm$parser$Parser$Advanced$consumeBase, 10, s.f1, s.oc),
				s);
		}
	};
};
var $elm$parser$Parser$Advanced$int = F2(
	function (expecting, invalid) {
		return $elm$parser$Parser$Advanced$number(
			{
				gY: $elm$core$Result$Err(invalid),
				hq: expecting,
				ht: $elm$core$Result$Err(invalid),
				lI: $elm$core$Result$Err(invalid),
				hV: $elm$core$Result$Ok($elm$core$Basics$identity),
				lZ: invalid,
				id: $elm$core$Result$Err(invalid)
			});
	});
var $elm$parser$Parser$int = A2($elm$parser$Parser$Advanced$int, $elm$parser$Parser$ExpectingInt, $elm$parser$Parser$ExpectingInt);
var $hecrj$html_parser$Html$Parser$numericCharacterReference = function () {
	var codepoint = $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$chompIf(
						function (c) {
							return (c === 'x') || (c === 'X');
						})),
				$hecrj$html_parser$Html$Parser$hexadecimal),
				A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$chompWhile(
						$elm$core$Basics$eq('0'))),
				$elm$parser$Parser$int)
			]));
	return A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			$elm$parser$Parser$chompIf(
				$elm$core$Basics$eq('#'))),
		A2(
			$elm$parser$Parser$map,
			A2($elm$core$Basics$composeR, $elm$core$Char$fromCode, $elm$core$String$fromChar),
			codepoint));
}();
var $hecrj$html_parser$Html$Parser$characterReference = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$succeed($elm$core$Basics$identity),
		$elm$parser$Parser$chompIf(
			$elm$core$Basics$eq('&'))),
	$elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$backtrackable($hecrj$html_parser$Html$Parser$namedCharacterReference),
				$hecrj$html_parser$Html$Parser$chompSemicolon),
				A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$backtrackable($hecrj$html_parser$Html$Parser$numericCharacterReference),
				$hecrj$html_parser$Html$Parser$chompSemicolon),
				$elm$parser$Parser$succeed('&')
			])));
var $hecrj$html_parser$Html$Parser$tagAttributeQuotedValue = function (quote) {
	var isQuotedValueChar = function (c) {
		return (!_Utils_eq(c, quote)) && (c !== '&');
	};
	return A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			$elm$parser$Parser$chompIf(
				$elm$core$Basics$eq(quote))),
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$map,
				$elm$core$String$join(''),
				$hecrj$html_parser$Html$Parser$many(
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								$elm$parser$Parser$getChompedString(
								$hecrj$html_parser$Html$Parser$chompOneOrMore(isQuotedValueChar)),
								$hecrj$html_parser$Html$Parser$characterReference
							])))),
			$elm$parser$Parser$chompIf(
				$elm$core$Basics$eq(quote))));
};
var $hecrj$html_parser$Html$Parser$oneOrMore = F2(
	function (type_, parser_) {
		return A2(
			$elm$parser$Parser$loop,
			_List_Nil,
			function (list) {
				return $elm$parser$Parser$oneOf(
					_List_fromArray(
						[
							A2(
							$elm$parser$Parser$map,
							function (_new) {
								return $elm$parser$Parser$Loop(
									A2($elm$core$List$cons, _new, list));
							},
							parser_),
							$elm$core$List$isEmpty(list) ? $elm$parser$Parser$problem('expecting at least one ' + type_) : $elm$parser$Parser$succeed(
							$elm$parser$Parser$Done(
								$elm$core$List$reverse(list)))
						]));
			});
	});
var $hecrj$html_parser$Html$Parser$tagAttributeUnquotedValue = function () {
	var isUnquotedValueChar = function (c) {
		return (!$hecrj$html_parser$Html$Parser$isSpaceCharacter(c)) && ((c !== '\"') && ((c !== '\'') && ((c !== '=') && ((c !== '<') && ((c !== '>') && ((c !== '`') && (c !== '&')))))));
	};
	return A2(
		$elm$parser$Parser$map,
		$elm$core$String$join(''),
		A2(
			$hecrj$html_parser$Html$Parser$oneOrMore,
			'attribute value',
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$elm$parser$Parser$getChompedString(
						$hecrj$html_parser$Html$Parser$chompOneOrMore(isUnquotedValueChar)),
						$hecrj$html_parser$Html$Parser$characterReference
					]))));
}();
var $hecrj$html_parser$Html$Parser$tagAttributeValue = $elm$parser$Parser$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('='))),
				$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter)),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$hecrj$html_parser$Html$Parser$tagAttributeUnquotedValue,
						$hecrj$html_parser$Html$Parser$tagAttributeQuotedValue('\"'),
						$hecrj$html_parser$Html$Parser$tagAttributeQuotedValue('\'')
					]))),
			$elm$parser$Parser$succeed('')
		]));
var $hecrj$html_parser$Html$Parser$tagAttribute = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$keeper,
		$elm$parser$Parser$succeed($elm$core$Tuple$pair),
		A2(
			$elm$parser$Parser$ignorer,
			$hecrj$html_parser$Html$Parser$tagAttributeName,
			$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter))),
	A2(
		$elm$parser$Parser$ignorer,
		$hecrj$html_parser$Html$Parser$tagAttributeValue,
		$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter)));
var $hecrj$html_parser$Html$Parser$tagAttributes = $hecrj$html_parser$Html$Parser$many($hecrj$html_parser$Html$Parser$tagAttribute);
var $hecrj$html_parser$Html$Parser$tagName = A2(
	$elm$parser$Parser$map,
	$elm$core$String$toLower,
	$elm$parser$Parser$getChompedString(
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$chompIf($elm$core$Char$isAlphaNum),
			$elm$parser$Parser$chompWhile(
				function (c) {
					return $elm$core$Char$isAlphaNum(c) || (c === '-');
				}))));
var $hecrj$html_parser$Html$Parser$Text = function (a) {
	return {$: 0, a: a};
};
var $hecrj$html_parser$Html$Parser$text = A2(
	$elm$parser$Parser$map,
	A2(
		$elm$core$Basics$composeR,
		$elm$core$String$join(''),
		$hecrj$html_parser$Html$Parser$Text),
	A2(
		$hecrj$html_parser$Html$Parser$oneOrMore,
		'text element',
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					$elm$parser$Parser$getChompedString(
					$hecrj$html_parser$Html$Parser$chompOneOrMore(
						function (c) {
							return (c !== '<') && (c !== '&');
						})),
					$hecrj$html_parser$Html$Parser$characterReference
				]))));
function $hecrj$html_parser$Html$Parser$cyclic$node() {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				$hecrj$html_parser$Html$Parser$text,
				$hecrj$html_parser$Html$Parser$comment,
				$hecrj$html_parser$Html$Parser$cyclic$element()
			]));
}
function $hecrj$html_parser$Html$Parser$cyclic$element() {
	return A2(
		$elm$parser$Parser$andThen,
		function (_v0) {
			var name = _v0.a;
			var attributes = _v0.b;
			return $hecrj$html_parser$Html$Parser$isVoidElement(name) ? A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A3($hecrj$html_parser$Html$Parser$Element, name, attributes, _List_Nil)),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								$elm$parser$Parser$chompIf(
								$elm$core$Basics$eq('/')),
								$elm$parser$Parser$succeed(0)
							]))),
				$elm$parser$Parser$chompIf(
					$elm$core$Basics$eq('>'))) : A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A2($hecrj$html_parser$Html$Parser$Element, name, attributes)),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('>'))),
				A2(
					$elm$parser$Parser$ignorer,
					$hecrj$html_parser$Html$Parser$many(
						$elm$parser$Parser$backtrackable(
							$hecrj$html_parser$Html$Parser$cyclic$node())),
					$hecrj$html_parser$Html$Parser$closingTag(name)));
		},
		A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Tuple$pair),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('<'))),
				A2(
					$elm$parser$Parser$ignorer,
					$hecrj$html_parser$Html$Parser$tagName,
					$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter))),
			$hecrj$html_parser$Html$Parser$tagAttributes));
}
var $hecrj$html_parser$Html$Parser$node = $hecrj$html_parser$Html$Parser$cyclic$node();
$hecrj$html_parser$Html$Parser$cyclic$node = function () {
	return $hecrj$html_parser$Html$Parser$node;
};
var $hecrj$html_parser$Html$Parser$element = $hecrj$html_parser$Html$Parser$cyclic$element();
$hecrj$html_parser$Html$Parser$cyclic$element = function () {
	return $hecrj$html_parser$Html$Parser$element;
};
var $hecrj$html_parser$Html$Parser$run = function (str) {
	return $elm$core$String$isEmpty(str) ? $elm$core$Result$Ok(_List_Nil) : A2(
		$elm$parser$Parser$run,
		A2($hecrj$html_parser$Html$Parser$oneOrMore, 'node', $hecrj$html_parser$Html$Parser$node),
		str);
};
var $elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$node = $elm$virtual_dom$VirtualDom$node;
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $hecrj$html_parser$Html$Parser$Util$toAttribute = function (_v0) {
	var name = _v0.a;
	var value = _v0.b;
	return A2($elm$html$Html$Attributes$attribute, name, value);
};
var $hecrj$html_parser$Html$Parser$Util$toVirtualDom = function (nodes) {
	return A2($elm$core$List$map, $hecrj$html_parser$Html$Parser$Util$toVirtualDomEach, nodes);
};
var $hecrj$html_parser$Html$Parser$Util$toVirtualDomEach = function (node) {
	switch (node.$) {
		case 1:
			var name = node.a;
			var attrs = node.b;
			var children = node.c;
			return A3(
				$elm$html$Html$node,
				name,
				A2($elm$core$List$map, $hecrj$html_parser$Html$Parser$Util$toAttribute, attrs),
				$hecrj$html_parser$Html$Parser$Util$toVirtualDom(children));
		case 0:
			var s = node.a;
			return $elm$html$Html$text(s);
		default:
			return $elm$html$Html$text('');
	}
};
var $author$project$Utils$HtmlUtil$toHtml = F2(
	function (mf, html) {
		var _v0 = $hecrj$html_parser$Html$Parser$run(html);
		if (!_v0.$) {
			var nodes = _v0.a;
			if (!mf.$) {
				var func = mf.a;
				return $elm$core$Result$Ok(
					$hecrj$html_parser$Html$Parser$Util$toVirtualDom(
						A2(
							$elm$core$List$map,
							$author$project$Utils$HtmlUtil$mapAnchorElement(func),
							nodes)));
			} else {
				return $elm$core$Result$Ok(
					$hecrj$html_parser$Html$Parser$Util$toVirtualDom(nodes));
			}
		} else {
			return $elm$core$Result$Err('Invalid Html');
		}
	});
var $author$project$Pages$Reporting$update_PinOk = F4(
	function (appContext, selectedUser, msg, model) {
		var serverContext = appContext.n1;
		switch (msg.$) {
			case 7:
				var _v1 = $author$project$Types$User$reportingPageAccess(selectedUser);
				if (_v1 === 1) {
					return $author$project$Pages$Reporting$doNothing(
						$author$project$Pages$Reporting$PinOk(model));
				} else {
					return A2($author$project$Pages$Reporting$askPinAgain, appContext, selectedUser);
				}
			case 4:
				var tab = msg.a;
				var loadReportsForTabCmd = function () {
					if (tab.$ === 1) {
						var site = tab.a;
						return A3(
							$author$project$Pages$Reporting$fetchWeeklySiteReportHtmlTable,
							serverContext,
							site,
							$author$project$Types$HalfYear$forDate(appContext.dE));
					} else {
						return $elm$core$Platform$Cmd$none;
					}
				}();
				var updatedModel = _Utils_update(
					model,
					{
						af: !_Utils_eq(loadReportsForTabCmd, $elm$core$Platform$Cmd$none),
						gg: tab,
						cJ: _List_Nil
					});
				return _Utils_Tuple3(
					{
						_: $author$project$Pages$Reporting$PinOk(updatedModel)
					},
					A2($elm$core$Platform$Cmd$map, $author$project$Pages$Reporting$Msg_PinOk, loadReportsForTabCmd),
					$author$project$Pages$Reporting$OutNoOp);
			case 6:
				return $author$project$Pages$Reporting$doNothing(
					$author$project$Pages$Reporting$PinOk(model));
			case 2:
				if (!msg.c.$) {
					var site = msg.a;
					var halfYear = msg.b;
					var htmlString = msg.c.a;
					var _v3 = model.gg;
					if (!_v3.$) {
						return $author$project$Pages$Reporting$doNothing(
							$author$project$Pages$Reporting$PinOk(model));
					} else {
						var selectedSite = _v3.a;
						if (!_Utils_eq(site, selectedSite)) {
							return $author$project$Pages$Reporting$doNothing(
								$author$project$Pages$Reporting$PinOk(model));
						} else {
							var parseErrorHtml = $elm$html$Html$text('ERROR PARSING HTML');
							var parsedHtml = A2(
								$elm$core$Result$withDefault,
								parseErrorHtml,
								A2(
									$elm$core$Result$map,
									$elm$core$Maybe$withDefault(parseErrorHtml),
									A2(
										$elm$core$Result$map,
										$elm$core$List$head,
										A2($author$project$Utils$HtmlUtil$toHtml, $elm$core$Maybe$Nothing, htmlString))));
							var newSiteReportHtmlTable = {hD: halfYear, fS: parsedHtml};
							var updatedSiteReportHtmlTables = _Utils_ap(
								model.cJ,
								_List_fromArray(
									[newSiteReportHtmlTable]));
							var fetchNextReportCmd = A2(
								$elm$core$Maybe$withDefault,
								$elm$core$Platform$Cmd$none,
								A2(
									$elm$core$Maybe$map,
									A2($author$project$Pages$Reporting$fetchWeeklySiteReportHtmlTable, serverContext, site),
									A2(
										$author$project$Pages$Reporting$nextHalfYearForSiteReport,
										$justinmimbs$date$Date$year(appContext.dE),
										halfYear)));
							var updatedModel = _Utils_update(
								model,
								{
									af: !_Utils_eq(fetchNextReportCmd, $elm$core$Platform$Cmd$none),
									cJ: updatedSiteReportHtmlTables
								});
							return _Utils_Tuple3(
								{
									_: $author$project$Pages$Reporting$PinOk(updatedModel)
								},
								A2($elm$core$Platform$Cmd$map, $author$project$Pages$Reporting$Msg_PinOk, fetchNextReportCmd),
								$author$project$Pages$Reporting$OutNoOp);
						}
					}
				} else {
					return _Utils_Tuple3(
						{
							_: $author$project$Pages$Reporting$PinOk(model)
						},
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Reporting$OutNotification(
							A2($author$project$Components$Notification$Notification, 'Virhe ladattaessa raportteja', 1)));
				}
			case 5:
				var reportLink = msg.a;
				return _Utils_Tuple3(
					{
						_: $author$project$Pages$Reporting$PinOk(
							_Utils_update(
								model,
								{af: true}))
					},
					A2(
						$elm$core$Platform$Cmd$map,
						$author$project$Pages$Reporting$Msg_PinOk,
						A3($author$project$Types$ExcelFile$fetchCmd, serverContext, reportLink.hj, $author$project$Pages$Reporting$GotExcelFile)),
					$author$project$Pages$Reporting$OutNoOp);
			case 3:
				if (!msg.a.$) {
					var sites = msg.a.a;
					var updatedModel = _Utils_update(
						model,
						{
							dR: A2(
								$elm$core$List$sortBy,
								$author$project$Types$Site$name,
								A2($elm$core$List$filter, $author$project$Types$Site$isActive, sites))
						});
					return _Utils_Tuple3(
						{
							_: $author$project$Pages$Reporting$PinOk(updatedModel)
						},
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Reporting$OutNoOp);
				} else {
					var errMsg = msg.a.a;
					return A2(
						$author$project$Pages$Reporting$handleAuthError,
						_Utils_update(
							model,
							{af: false}),
						errMsg);
				}
			case 1:
				if (!msg.a.$) {
					var excelFile = msg.a.a;
					return _Utils_Tuple3(
						{
							_: $author$project$Pages$Reporting$PinOk(
								_Utils_update(
									model,
									{af: false}))
						},
						A2(
							$elm$core$Platform$Cmd$map,
							$author$project$Pages$Reporting$Msg_PinOk,
							$author$project$Types$ExcelFile$saveToDiskCmd(excelFile)),
						$author$project$Pages$Reporting$OutNoOp);
				} else {
					var errMsg = msg.a.a;
					return A2(
						$author$project$Pages$Reporting$handleAuthError,
						_Utils_update(
							model,
							{af: false}),
						errMsg);
				}
			case 0:
				if (!msg.a.$) {
					var reportLinks = msg.a.a;
					var weeklySalesReportLinks = A2(
						$elm$core$List$filter,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.cp;
							},
							$elm$core$Basics$eq(0)),
						reportLinks);
					var siteReportLinks = A2(
						$elm$core$List$filter,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.cp;
							},
							$elm$core$Basics$eq(3)),
						reportLinks);
					var pivotSourceReportLinks = A2(
						$elm$core$List$filter,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.cp;
							},
							$elm$core$Basics$eq(2)),
						reportLinks);
					var monthlySalesReportLinks = A2(
						$elm$core$List$filter,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.cp;
							},
							$elm$core$Basics$eq(1)),
						reportLinks);
					var updatedModel = _Utils_update(
						model,
						{eA: monthlySalesReportLinks, eO: pivotSourceReportLinks, gk: siteReportLinks, fe: weeklySalesReportLinks});
					return _Utils_Tuple3(
						{
							_: $author$project$Pages$Reporting$PinOk(updatedModel)
						},
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Reporting$OutNoOp);
				} else {
					var errMsg = msg.a.a;
					return A2($author$project$Pages$Reporting$handleAuthError, model, errMsg);
				}
			default:
				return $author$project$Pages$Reporting$doNothing(
					$author$project$Pages$Reporting$PinOk(model));
		}
	});
var $author$project$Pages$Reporting$update = F4(
	function (appContext, selectedUser, msg, model) {
		var _v0 = _Utils_Tuple2(msg, model._);
		_v0$2:
		while (true) {
			switch (_v0.a.$) {
				case 0:
					if (!_v0.b.$) {
						var msg_AskingPin = _v0.a.a;
						var model_AskingPin = _v0.b.a;
						return A4($author$project$Pages$Reporting$update_AskingPin, appContext, selectedUser, msg_AskingPin, model_AskingPin);
					} else {
						break _v0$2;
					}
				case 1:
					if (_v0.b.$ === 1) {
						var msg_PinOk = _v0.a.a;
						var model_PinOk = _v0.b.a;
						return A4($author$project$Pages$Reporting$update_PinOk, appContext, selectedUser, msg_PinOk, model_PinOk);
					} else {
						break _v0$2;
					}
				default:
					break _v0$2;
			}
		}
		return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$Reporting$OutNoOp);
	});
var $author$project$Pages$Tasklist$OutNoOp = {$: 2};
var $author$project$Pages$Tasklist$Errored = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Tasklist$update_Errored = F2(
	function (erroredMsg, erroredModel) {
		return _Utils_Tuple3(
			{
				_: $author$project$Pages$Tasklist$Errored(erroredModel)
			},
			$elm$core$Platform$Cmd$none,
			$author$project$Pages$Tasklist$OutNoOp);
	});
var $author$project$Pages$Tasklist$CloseButtonClickedOnce = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Tasklist$CloseConfirmationDialogOpen = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Tasklist$ClosingProcessIdle = {$: 0};
var $author$project$Pages$Tasklist$TaskEditor$CreateMode = 0;
var $author$project$Pages$Tasklist$DraggingQueuedTask = F4(
	function (a, b, c, d) {
		return {$: 1, a: a, b: b, c: c, d: d};
	});
var $author$project$Pages$Tasklist$TaskEditor$EditMode = 1;
var $author$project$Pages$Tasklist$EditingExistingTask = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $author$project$Pages$Tasklist$EditingNewTask = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Pages$Tasklist$EditingOneTimeCustomerContactInfo = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Pages$Tasklist$EditingOneTimeCustomerName = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Pages$Tasklist$GotDataRefresh = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Tasklist$GotOneTimeCustomerContactInfo = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pages$Tasklist$GotOneTimeCustomerName = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Tasklist$GotTaskEditorMsg = function (a) {
	return {$: 27, a: a};
};
var $author$project$Pages$Tasklist$Loaded = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Tasklist$NoOp = {$: 26};
var $author$project$Pages$Tasklist$NotDragging = {$: 0};
var $author$project$Pages$Tasklist$NotEditing = {$: 0};
var $author$project$Pages$Tasklist$NotEditingOneTimeCustomer = {$: 0};
var $author$project$Pages$Tasklist$OutNotification = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Tasklist$RequestDataRefresh = {$: 7};
var $author$project$Pages$Tasklist$ShowCloseTasklistConfirmationDialog = {$: 18};
var $author$project$Pages$Tasklist$TaskQueue$areUpdateButtonsDisabled = function (model) {
	var _v0 = model._;
	switch (_v0.$) {
		case 0:
			return false;
		case 1:
			var loadedModel = _v0.a;
			return loadedModel.t;
		default:
			return false;
	}
};
var $author$project$Pages$Tasklist$areUpdateButtonsDisabled = function (loadedModel) {
	return loadedModel.t || $author$project$Pages$Tasklist$TaskQueue$areUpdateButtonsDisabled(loadedModel.N);
};
var $author$project$Pages$Tasklist$buildTasklistGroups = function (tasklists) {
	return $elm$core$Dict$fromList(
		A2(
			$elm$core$List$map,
			function (groupName) {
				return _Utils_Tuple2(
					groupName,
					{
						mD: groupName,
						e: A2(
							$elm$core$List$filter,
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.fN;
								},
								$elm$core$Basics$eq(groupName)),
							tasklists)
					});
			},
			$elm_community$list_extra$List$Extra$unique(
				A2(
					$elm$core$List$map,
					function ($) {
						return $.fN;
					},
					tasklists))));
};
var $author$project$Pages$Tasklist$TasklistCloseRequestSent = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$Tasklist$TasklistClosed = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Tasklist$closeTasklistCmd = F2(
	function (_v0, tasklist) {
		var apiUrl = _v0.j0;
		var token = _v0.oK;
		return A4(
			$author$project$JwtHttp$post,
			token,
			apiUrl + ('/tasklists/' + ($elm$core$String$fromInt(tasklist.er) + '/close')),
			$elm$http$Http$emptyBody,
			A2($elm$http$Http$expectJson, $author$project$Pages$Tasklist$TasklistClosed, $author$project$Data$Tasklists$tasklistDecoder));
	});
var $author$project$Types$Tasklist$TasklistEmptyError = 0;
var $author$project$Types$Tasklist$TasklistInvalidTasksError = 1;
var $author$project$Types$TasklistTask$hasValidDate = function (date) {
	return !_Utils_eq(date, $elm$core$Maybe$Nothing);
};
var $author$project$Types$TasklistTask$isValidProduct = function (product) {
	return !_Utils_eq(product.iv, $elm$core$Maybe$Nothing);
};
var $author$project$Types$TasklistTask$hasValidProducts = function (products) {
	var validProductsCount = $elm$core$List$length(
		A2($elm$core$List$filter, $author$project$Types$TasklistTask$isValidProduct, products));
	var productCount = $elm$core$List$length(products);
	return (productCount > 0) && _Utils_eq(validProductsCount, productCount);
};
var $author$project$Types$Vehicle$LicenseNumber$isEmpty = A2($elm$core$Basics$composeR, $author$project$Types$Vehicle$LicenseNumber$toString, $elm$core$String$isEmpty);
var $author$project$Types$TasklistTask$hasValidVehicle = function (vehicle) {
	return (!$author$project$Types$Vehicle$LicenseNumber$isEmpty(vehicle.de)) && (!(vehicle.mi === ''));
};
var $author$project$Types$TasklistTask$hasValidWorkers = function (workerIds) {
	return $elm$core$List$length(workerIds) > 0;
};
var $author$project$Types$TasklistTask$isValid = function (task) {
	return $author$project$Types$TasklistTask$hasValidVehicle(task.oX) && ($author$project$Types$TasklistTask$hasValidDate(task.b4) && ($author$project$Types$TasklistTask$hasValidProducts(task.iA) && $author$project$Types$TasklistTask$hasValidWorkers(task.jz)));
};
var $author$project$Types$Tasklist$validate_tasks = function (tasks) {
	var validTaskCount = $elm$core$List$length(
		A2($elm$core$List$filter, $author$project$Types$TasklistTask$isValid, tasks));
	var taskCount = $elm$core$List$length(tasks);
	return (!taskCount) ? $elm$core$Maybe$Just(0) : ((!_Utils_eq(taskCount, validTaskCount)) ? $elm$core$Maybe$Just(1) : $elm$core$Maybe$Nothing);
};
var $author$project$Pages$Tasklist$validateCloseTasklistAction = function (tasklist) {
	return A2(
		$elm$core$Maybe$map,
		function (validationError) {
			if (!validationError) {
				return 'Tyhjää lomaketta ei voi sulkea';
			} else {
				return 'Lomaketta ei voi sulkea, koska sillä on keskeneräisiä töitä.';
			}
		},
		$author$project$Types$Tasklist$validate_tasks(tasklist.aj));
};
var $author$project$Pages$Tasklist$doCloseTasklistAction = F3(
	function (serverContext, model, tasklist) {
		var _v0 = function () {
			var _v1 = $author$project$Pages$Tasklist$validateCloseTasklistAction(tasklist);
			if (!_v1.$) {
				var errorString = _v1.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{R: $author$project$Pages$Tasklist$ClosingProcessIdle}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Tasklist$OutNotification(
						A2($author$project$Components$Notification$Notification, errorString, 1)));
			} else {
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							t: true,
							R: $author$project$Pages$Tasklist$TasklistCloseRequestSent(tasklist)
						}),
					A2($author$project$Pages$Tasklist$closeTasklistCmd, serverContext, tasklist),
					$author$project$Pages$Tasklist$OutNoOp);
			}
		}();
		var updatedModel = _v0.a;
		var cmd = _v0.b;
		var outMsg = _v0.c;
		return _Utils_Tuple3(
			{
				_: $author$project$Pages$Tasklist$Loaded(updatedModel)
			},
			A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$Msg_Loaded, cmd),
			outMsg);
	});
var $author$project$Types$Vehicle$LicenseNumberField$empty = $author$project$Types$Vehicle$LicenseNumberField$create('');
var $author$project$Types$TaskEditableFields$empty = F2(
	function (defaultMake, maybeDate) {
		return {kH: '', b4: maybeDate, mf: $author$project$Types$Vehicle$LicenseNumberField$empty, gf: _List_Nil, fd: defaultMake, oY: '', jz: _List_Nil};
	});
var $elm$core$Set$filter = F2(
	function (isGood, _v0) {
		var dict = _v0;
		return A2(
			$elm$core$Dict$filter,
			F2(
				function (key, _v1) {
					return isGood(key);
				}),
			dict);
	});
var $elm$core$Set$fromList = function (list) {
	return A3($elm$core$List$foldl, $elm$core$Set$insert, $elm$core$Set$empty, list);
};
var $author$project$Types$Vehicle$LicenseNumberField$fromLicenseNumber = A2($elm$core$Basics$composeR, $author$project$Types$Vehicle$LicenseNumber$toString, $author$project$Types$Vehicle$LicenseNumberField$create);
var $author$project$Types$TaskEditableFields$fromTask = function (_v0) {
	var vehicle = _v0.oX;
	var date = _v0.b4;
	var products = _v0.iA;
	var workerIds = _v0.jz;
	var comment = _v0.kH;
	return {
		kH: comment,
		b4: date,
		mf: $author$project$Types$Vehicle$LicenseNumberField$fromLicenseNumber(vehicle.de),
		gf: products,
		fd: vehicle.mi,
		oY: vehicle.mv,
		jz: workerIds
	};
};
var $author$project$Pages$Tasklist$TaskQueue$Types$TaskQueue$getTasks = function (_v0) {
	var tq = _v0;
	return tq.aj;
};
var $author$project$Pages$Tasklist$TaskQueue$getTasks = $author$project$Pages$Tasklist$TaskQueue$Types$TaskQueue$getTasks;
var $author$project$Pages$Tasklist$OutGotAuthError = {$: 1};
var $author$project$Pages$Tasklist$handleAuthError = function (loadedModel) {
	return _Utils_Tuple3(
		{
			_: $author$project$Pages$Tasklist$Loaded(loadedModel)
		},
		$elm$core$Platform$Cmd$none,
		$author$project$Pages$Tasklist$OutGotAuthError);
};
var $author$project$Pages$Tasklist$TaskQueue$Loaded = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskDragStatus$NotDragging = {$: 0};
var $author$project$Pages$Tasklist$TaskQueue$setNotDragging = function (model) {
	var _v0 = model._;
	switch (_v0.$) {
		case 0:
			return model;
		case 1:
			var loadedModel = _v0.a;
			var updatedLoadedModel = _Utils_update(
				loadedModel,
				{O: $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskDragStatus$NotDragging});
			return {
				_: $author$project$Pages$Tasklist$TaskQueue$Loaded(updatedLoadedModel)
			};
		default:
			return model;
	}
};
var $author$project$Pages$Tasklist$handleNotDraggedCase = F2(
	function (loadedModel, cmd) {
		var updatedTaskQueueModel = $author$project$Pages$Tasklist$TaskQueue$setNotDragging(loadedModel.N);
		return _Utils_Tuple3(
			{
				_: $author$project$Pages$Tasklist$Loaded(
					_Utils_update(
						loadedModel,
						{O: $author$project$Pages$Tasklist$NotDragging, N: updatedTaskQueueModel}))
			},
			cmd,
			$author$project$Pages$Tasklist$OutNoOp);
	});
var $author$project$Pages$Tasklist$QueuedTaskDraggedToTasklist = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $elm$core$List$all = F2(
	function (isOkay, list) {
		return !A2(
			$elm$core$List$any,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, isOkay),
			list);
	});
var $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTask$areAllWorkersInAnotherTask = F2(
	function (thisTask, anotherTask) {
		return A2(
			$elm$core$List$all,
			function (thisTaskWorkerId) {
				return A2($elm$core$List$member, thisTaskWorkerId, anotherTask.hs.jz);
			},
			thisTask.hs.jz);
	});
var $author$project$Types$User$canEditTaskQueues = function (siteUser) {
	switch (siteUser.$) {
		case 2:
			return true;
		case 0:
			return true;
		default:
			return false;
	}
};
var $author$project$Pages$Tasklist$TaskQueue$Types$TaskQueue$getIndex = F2(
	function (_v0, queuedTask) {
		var tq = _v0;
		return $elm$core$List$head(
			A2(
				$elm$core$List$filterMap,
				function (_v1) {
					var index = _v1.a;
					var task = _v1.b;
					return _Utils_eq(task.er, queuedTask.er) ? $elm$core$Maybe$Just(index) : $elm$core$Maybe$Nothing;
				},
				A2(
					$elm$core$List$indexedMap,
					F2(
						function (index, task) {
							return _Utils_Tuple2(index, task);
						}),
					tq.aj)));
	});
var $author$project$Pages$Tasklist$TaskQueue$Types$TaskQueue$getTasksBeforeIndex = F2(
	function (index, taskQueue) {
		return A2(
			$elm$core$List$map,
			$elm$core$Tuple$second,
			A2(
				$elm$core$List$filter,
				function (_v0) {
					var i = _v0.a;
					return _Utils_cmp(i, index) < 0;
				},
				A2(
					$elm$core$List$indexedMap,
					F2(
						function (i, task) {
							return _Utils_Tuple2(i, task);
						}),
					$author$project$Pages$Tasklist$TaskQueue$Types$TaskQueue$getTasks(taskQueue))));
	});
var $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTask$isAssigned = function (queuedTask) {
	return A3($elm$core$Basics$composeL, $elm$core$Basics$not, $elm$core$List$isEmpty, queuedTask.hs.jz);
};
var $author$project$Types$Site$usesQueueSkipNotice = function (site) {
	return $author$project$Types$Site$getSiteData(site).bN;
};
var $author$project$Components$QueueSkipNotice$checkIfNeeded = F3(
	function (user, taskQueue, selectedQueuedTask) {
		var siteUsesQueueSkipNotices = $author$project$Types$Site$usesQueueSkipNotice(
			$author$project$Types$User$site(user));
		if (!siteUsesQueueSkipNotices) {
			return false;
		} else {
			if ($author$project$Types$User$canEditTaskQueues(user)) {
				return false;
			} else {
				if ($author$project$Pages$Tasklist$TaskQueue$Types$QueuedTask$isAssigned(selectedQueuedTask)) {
					var thisTaskIndex = A2(
						$elm$core$Maybe$withDefault,
						0,
						A2($author$project$Pages$Tasklist$TaskQueue$Types$TaskQueue$getIndex, taskQueue, selectedQueuedTask));
					return A2(
						$elm$core$Maybe$withDefault,
						false,
						A2(
							$elm$core$Maybe$map,
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.er;
								},
								$elm$core$Basics$neq(selectedQueuedTask.er)),
							A2(
								$author$project$Helpers$firstWhere,
								$author$project$Pages$Tasklist$TaskQueue$Types$QueuedTask$areAllWorkersInAnotherTask(selectedQueuedTask),
								A2($author$project$Pages$Tasklist$TaskQueue$Types$TaskQueue$getTasksBeforeIndex, thisTaskIndex, taskQueue))));
				} else {
					var thisTaskIndex = A2($author$project$Pages$Tasklist$TaskQueue$Types$TaskQueue$getIndex, taskQueue, selectedQueuedTask);
					var firstFreeTaskIndex = $elm$core$List$head(
						A2(
							$elm$core$List$filterMap,
							function (_v3) {
								var index = _v3.a;
								var task = _v3.b;
								var isFreeQueuedTask = $elm$core$List$isEmpty(task.hs.jz);
								return isFreeQueuedTask ? $elm$core$Maybe$Just(index) : $elm$core$Maybe$Nothing;
							},
							A2(
								$elm$core$List$indexedMap,
								F2(
									function (index, task) {
										return _Utils_Tuple2(index, task);
									}),
								$author$project$Pages$Tasklist$TaskQueue$Types$TaskQueue$getTasks(taskQueue))));
					var _v0 = _Utils_Tuple2(firstFreeTaskIndex, thisTaskIndex);
					if (_v0.a.$ === 1) {
						var _v1 = _v0.a;
						return false;
					} else {
						if (!_v0.b.$) {
							var firstFreeIndex = _v0.a.a;
							var thisIndex = _v0.b.a;
							return $elm$core$List$isEmpty(selectedQueuedTask.hs.jz) && (_Utils_cmp(thisIndex, firstFreeIndex) > 0);
						} else {
							var _v2 = _v0.b;
							return false;
						}
					}
				}
			}
		}
	});
var $author$project$Pages$Tasklist$TaskQueue$Requests$QueuedTaskMovedToTasklistResponse = F2(
	function (updatedTaskQueue, targetTasklist) {
		return {on: targetTasklist, oT: updatedTaskQueue};
	});
var $elm$json$Json$Encode$bool = _Json_wrap;
var $author$project$Pages$Tasklist$TaskQueue$Requests$queuedTaskSiteUrl = F2(
	function (apiUrl, siteId) {
		return apiUrl + ('/sites/' + ($elm$core$String$fromInt(siteId) + '/queued-tasks/'));
	});
var $author$project$Pages$Tasklist$TaskQueue$Types$TaskQueue$TaskQueue = $elm$core$Basics$identity;
var $author$project$Pages$Tasklist$TaskQueue$Types$TaskQueue$create = F2(
	function (siteId, queuedTasks) {
		return {
			cs: siteId,
			aj: A2(
				$elm$core$List$sortBy,
				function ($) {
					return $.eQ;
				},
				queuedTasks)
		};
	});
var $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTask$JsonData = function (id) {
	return function (siteId) {
		return function (priority) {
			return function (createdAt) {
				return function (updatedAt) {
					return function (comment) {
						return function (instructions) {
							return function (products) {
								return function (targetDate) {
									return function (vehicle) {
										return function (workerIds) {
											return {kH: comment, d4: createdAt, er: id, lY: instructions, eQ: priority, iA: products, cs: siteId, e2: targetDate, fa: updatedAt, oX: vehicle, jz: workerIds};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTask$QueuedTaskId = $elm$core$Basics$identity;
var $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTask$queuedTaskIdDecoder = A2(
	$elm$json$Json$Decode$andThen,
	A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$succeed),
	$elm$json$Json$Decode$int);
var $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTask$jsonDataDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'workerIds',
	$elm$json$Json$Decode$list($elm$json$Json$Decode$int),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'vehicle',
		$author$project$Decode$vehicleDecoder,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'targetDate',
			$elm$json$Json$Decode$maybe($author$project$Types$Date$jsonDecoder),
			$elm$core$Maybe$Nothing,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'products',
				$elm$json$Json$Decode$list($author$project$Types$SelectedProduct$selectedProductDecoder),
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'instructions',
					$elm$json$Json$Decode$string,
					'',
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'comment',
						$elm$json$Json$Decode$string,
						'',
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'updatedAt',
							$author$project$Decode$isoTimeDecoder,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'createdAt',
								$author$project$Decode$isoTimeDecoder,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'priority',
									$elm$json$Json$Decode$int,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'siteId',
										$elm$json$Json$Decode$int,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'id',
											$author$project$Pages$Tasklist$TaskQueue$Types$QueuedTask$queuedTaskIdDecoder,
											$elm$json$Json$Decode$succeed($author$project$Pages$Tasklist$TaskQueue$Types$QueuedTask$JsonData))))))))))));
var $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTask$jsonDataToQueuedTask = function (jsonData) {
	var queuedTaskFields = {
		kH: jsonData.kH,
		lY: jsonData.lY,
		mf: $author$project$Types$Vehicle$LicenseNumberField$fromLicenseNumber(jsonData.oX.de),
		iA: jsonData.iA,
		e2: jsonData.e2,
		fd: jsonData.oX.mi,
		oY: jsonData.oX.mv,
		jz: jsonData.jz
	};
	return {d4: jsonData.d4, hs: queuedTaskFields, er: jsonData.er, eQ: jsonData.eQ, cs: jsonData.cs, fa: jsonData.fa};
};
var $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTask$queuedTaskDecoder = A2($elm$json$Json$Decode$map, $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTask$jsonDataToQueuedTask, $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTask$jsonDataDecoder);
var $author$project$Pages$Tasklist$TaskQueue$Types$TaskQueue$taskQueueDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Pages$Tasklist$TaskQueue$Types$TaskQueue$create,
	A2($elm$json$Json$Decode$field, 'siteId', $elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$field,
		'tasks',
		$elm$json$Json$Decode$list($author$project$Pages$Tasklist$TaskQueue$Types$QueuedTask$queuedTaskDecoder)));
var $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTask$unwrapQueuedTaskId = function (_v0) {
	var id = _v0;
	return id;
};
var $author$project$Pages$Tasklist$TaskQueue$Requests$moveQueuedTaskToTasklistCmd = F5(
	function (_v0, queuedTask, tasklist, includeQueueSkipNotice, onResponse) {
		var apiUrl = _v0.j0;
		var token = _v0.oK;
		var url = A2($author$project$Pages$Tasklist$TaskQueue$Requests$queuedTaskSiteUrl, apiUrl, queuedTask.cs) + ($elm$core$String$fromInt(
			$author$project$Pages$Tasklist$TaskQueue$Types$QueuedTask$unwrapQueuedTaskId(queuedTask.er)) + ('/move-to-tasklist/' + $elm$core$String$fromInt(tasklist.er)));
		var responseDecoder = A3(
			$elm$json$Json$Decode$map2,
			$author$project$Pages$Tasklist$TaskQueue$Requests$QueuedTaskMovedToTasklistResponse,
			A2($elm$json$Json$Decode$field, 'updatedTaskQueue', $author$project$Pages$Tasklist$TaskQueue$Types$TaskQueue$taskQueueDecoder),
			A2($elm$json$Json$Decode$field, 'targetTasklist', $author$project$Data$Tasklists$tasklistDecoder));
		var body = $elm$http$Http$jsonBody(
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'skippedQueue',
						$elm$json$Json$Encode$bool(includeQueueSkipNotice))
					])));
		return A4(
			$author$project$JwtHttp$post,
			token,
			url,
			body,
			A2($elm$http$Http$expectJson, onResponse, responseDecoder));
	});
var $author$project$Pages$Tasklist$handleQueuedTaskDropped = F6(
	function (serverContext, loadedModel, cmd, taskQueue, queuedTask, maybeHoveredTasklist) {
		if (maybeHoveredTasklist.$ === 1) {
			return A2($author$project$Pages$Tasklist$handleNotDraggedCase, loadedModel, cmd);
		} else {
			var hoveredTasklist = maybeHoveredTasklist.a;
			var doesTaskGetQueueSkipNotice = A3($author$project$Components$QueueSkipNotice$checkIfNeeded, loadedModel.Y, taskQueue, queuedTask);
			return _Utils_Tuple3(
				{
					_: $author$project$Pages$Tasklist$Loaded(
						_Utils_update(
							loadedModel,
							{t: true}))
				},
				$elm$core$Platform$Cmd$batch(
					_List_fromArray(
						[
							cmd,
							A2(
							$elm$core$Platform$Cmd$map,
							$author$project$Pages$Tasklist$Msg_Loaded,
							A5(
								$author$project$Pages$Tasklist$TaskQueue$Requests$moveQueuedTaskToTasklistCmd,
								serverContext,
								queuedTask,
								hoveredTasklist,
								doesTaskGetQueueSkipNotice,
								$author$project$Pages$Tasklist$QueuedTaskDraggedToTasklist(queuedTask)))
						])),
				$author$project$Pages$Tasklist$OutNoOp);
		}
	});
var $author$project$Pages$Tasklist$TaskQueue$GotLayoutResizeInfo = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$QueuedTaskCardList$domId = 'queued-task-cards-list';
var $elm$browser$Browser$Dom$getElement = _Browser_getElement;
var $author$project$Pages$Tasklist$TaskQueue$requestDomElementHeights = A3(
	$elm$core$Task$map2,
	F2(
		function (listElement, listContainerElement) {
			return {gr: listContainerElement.hm.en, gs: listElement.hm.en};
		}),
	$elm$browser$Browser$Dom$getElement($author$project$Pages$Tasklist$TaskQueue$QueuedTaskCardList$domId),
	$elm$browser$Browser$Dom$getElement('queued-task-cards-list-container'));
var $author$project$Pages$Tasklist$TaskQueue$requestLayoutResize = A2(
	$elm$core$Platform$Cmd$map,
	$author$project$Pages$Tasklist$TaskQueue$Msg_Loaded,
	A2($elm$core$Task$attempt, $author$project$Pages$Tasklist$TaskQueue$GotLayoutResizeInfo, $author$project$Pages$Tasklist$TaskQueue$requestDomElementHeights));
var $author$project$Pages$Tasklist$TaskQueue$Types$TaskQueue$setTasks = F2(
	function (queuedTasks, _v0) {
		var tq = _v0;
		return A2($author$project$Pages$Tasklist$TaskQueue$Types$TaskQueue$create, tq.cs, queuedTasks);
	});
var $author$project$Pages$Tasklist$TaskQueue$setQueuedTasks = F2(
	function (queuedTasks, model) {
		var _v0 = model._;
		switch (_v0.$) {
			case 0:
				return model;
			case 1:
				var loadedModel = _v0.a;
				var updatedTaskQueue = A2($author$project$Pages$Tasklist$TaskQueue$Types$TaskQueue$setTasks, queuedTasks, loadedModel.k_.z);
				return function (a) {
					return {
						_: $author$project$Pages$Tasklist$TaskQueue$Loaded(a)
					};
				}(
					_Utils_update(
						loadedModel,
						{
							k_: function (a) {
								return _Utils_update(
									a,
									{z: updatedTaskQueue});
							}(loadedModel.k_)
						}));
			default:
				return model;
		}
	});
var $author$project$Pages$Tasklist$TaskQueue$handleTaskDraggedFromQueue = F2(
	function (updatedQueuedTasks, model) {
		var updatedModel = $author$project$Pages$Tasklist$TaskQueue$setNotDragging(
			A2($author$project$Pages$Tasklist$TaskQueue$setQueuedTasks, updatedQueuedTasks, model));
		return _Utils_Tuple2(updatedModel, $author$project$Pages$Tasklist$TaskQueue$requestLayoutResize);
	});
var $author$project$Pages$Tasklist$TaskCreated = function (a) {
	return {$: 1, a: a};
};
var $elm$regex$Regex$replace = _Regex_replaceAtMost(_Regex_infinity);
var $author$project$Helpers$replaceWith = F3(
	function (replaced, replacement, regexString) {
		return A2(
			$elm$regex$Regex$replace,
			A2(
				$elm$core$Maybe$withDefault,
				$elm$regex$Regex$never,
				$elm$regex$Regex$fromString(regexString)),
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.mj;
				},
				A2($elm$core$String$replace, replaced, replacement)));
	});
var $author$project$Types$Vehicle$LicenseNumberField$finalize = function (licenseNumberField) {
	return $author$project$Types$Vehicle$LicenseNumberField$create(
		A4(
			$author$project$Helpers$replaceWith,
			'SHO-WROOM',
			'SHOWROOM',
			'.*',
			A4(
				$author$project$Helpers$replaceWith,
				'HAL-LI',
				'HALLI',
				'.*',
				A4(
					$author$project$Helpers$replaceWith,
					' -',
					'-',
					'^[A-Z]{3} -[0-9]{3}$',
					A4(
						$author$project$Helpers$replaceWith,
						'- ',
						'-',
						'^[A-Z]{3}- [0-9]{3}$',
						A4(
							$author$project$Helpers$replaceWith,
							' ',
							'-',
							'^[A-Z]{3} [0-9]{3}$',
							$elm$core$String$trim(
								$author$project$Types$Vehicle$LicenseNumberField$toString(licenseNumberField))))))));
};
var $author$project$Types$Vehicle$LicenseNumberField$toLicenseNumber = A2(
	$elm$core$Basics$composeR,
	$author$project$Types$Vehicle$LicenseNumberField$finalize,
	A2($elm$core$Basics$composeR, $author$project$Types$Vehicle$LicenseNumberField$toString, $author$project$Types$Vehicle$LicenseNumber$create));
var $author$project$Types$TaskEditableFields$extractVehicle = function (_v0) {
	var licenseNumberField = _v0.mf;
	var vehicleMake = _v0.fd;
	var vehicleModel = _v0.oY;
	return A3(
		$author$project$Types$Vehicle$Vehicle$Vehicle,
		$author$project$Types$Vehicle$LicenseNumberField$toLicenseNumber(licenseNumberField),
		$elm$core$String$trim(vehicleMake),
		$elm$core$String$trim(vehicleModel));
};
var $elm$json$Json$Encode$float = _Json_wrap;
var $author$project$Encode$selectedProductEncoder = function (selectedProduct) {
	var price = function () {
		var _v0 = selectedProduct.iv;
		if (!_v0.$) {
			var _float = _v0.a;
			return $elm$json$Json$Encode$float(_float);
		} else {
			return $elm$json$Json$Encode$null;
		}
	}();
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'productId',
				$elm$json$Json$Encode$int(selectedProduct.iz)),
				_Utils_Tuple2('price', price)
			]));
};
var $author$project$Types$Vehicle$LicenseNumber$licenseNumberEncoder = A2($elm$core$Basics$composeR, $author$project$Types$Vehicle$LicenseNumber$toString, $elm$json$Json$Encode$string);
var $author$project$Encode$vehicleEncoder = function (vehicle) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'licenseNo',
				$author$project$Types$Vehicle$LicenseNumber$licenseNumberEncoder(vehicle.de)),
				_Utils_Tuple2(
				'make',
				$elm$json$Json$Encode$string(vehicle.mi)),
				_Utils_Tuple2(
				'model',
				$elm$json$Json$Encode$string(vehicle.mv))
			]));
};
var $author$project$Types$TaskEditableFields$encoder = function (fields) {
	var vehicle = $author$project$Types$TaskEditableFields$extractVehicle(fields);
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'workerIds',
				A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$int, fields.jz)),
				_Utils_Tuple2(
				'vehicle',
				$author$project$Encode$vehicleEncoder(vehicle)),
				_Utils_Tuple2(
				'workDate',
				$author$project$Encode$dateEncoder(fields.b4)),
				_Utils_Tuple2(
				'selectedProducts',
				A2($elm$json$Json$Encode$list, $author$project$Encode$selectedProductEncoder, fields.gf)),
				_Utils_Tuple2(
				'comment',
				$elm$json$Json$Encode$string(fields.kH))
			]));
};
var $author$project$Types$TaskCreateRequestData$encoder = function (data) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'tasklistId',
				$elm$json$Json$Encode$int(data.e3)),
				_Utils_Tuple2(
				'fields',
				$author$project$Types$TaskEditableFields$encoder(data.hs)),
				_Utils_Tuple2(
				'siteUserId',
				$elm$json$Json$Encode$int(
					$author$project$Types$User$id(
						$author$project$Types$User$toUser(data.Y))))
			]));
};
var $author$project$Pages$Tasklist$createTaskCmd = F2(
	function (_v0, data) {
		var apiUrl = _v0.j0;
		var token = _v0.oK;
		var url = apiUrl + ('/tasklists/' + ($elm$core$String$fromInt(data.e3) + '/tasks/'));
		var body = $elm$http$Http$jsonBody(
			$author$project$Types$TaskCreateRequestData$encoder(data));
		return A4(
			$author$project$JwtHttp$post,
			token,
			url,
			body,
			A2($elm$http$Http$expectJson, $author$project$Pages$Tasklist$TaskCreated, $author$project$Data$Tasklists$tasklistDecoder));
	});
var $author$project$Pages$Tasklist$TaskDeleted = function (a) {
	return {$: 3, a: a};
};
var $author$project$JwtHttp$Delete = 3;
var $author$project$JwtHttp$delete = F4(
	function (token, url, body, expect) {
		return A5($author$project$JwtHttp$request, token, 3, url, body, expect);
	});
var $author$project$Pages$Tasklist$deleteTaskCmd = F3(
	function (_v0, tasklistId, taskId) {
		var apiUrl = _v0.j0;
		var token = _v0.oK;
		return A4(
			$author$project$JwtHttp$delete,
			token,
			apiUrl + ('/tasklists/' + ($elm$core$String$fromInt(tasklistId) + ('/tasks/' + $elm$core$String$fromInt(taskId)))),
			$elm$http$Http$emptyBody,
			A2($elm$http$Http$expectJson, $author$project$Pages$Tasklist$TaskDeleted, $author$project$Data$Tasklists$tasklistDecoder));
	});
var $author$project$Pages$Tasklist$TaskUpdated = function (a) {
	return {$: 2, a: a};
};
var $author$project$Types$TaskUpdateRequestData$encoder = function (data) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'tasklistId',
				$elm$json$Json$Encode$int(data.e3)),
				_Utils_Tuple2(
				'fields',
				$author$project$Types$TaskEditableFields$encoder(data.hs)),
				_Utils_Tuple2(
				'taskId',
				$elm$json$Json$Encode$int(data.jd)),
				_Utils_Tuple2(
				'siteUserId',
				$elm$json$Json$Encode$int(
					$author$project$Types$User$id(
						$author$project$Types$User$toUser(data.Y))))
			]));
};
var $author$project$JwtHttp$Put = 2;
var $author$project$JwtHttp$put = F4(
	function (token, url, body, expect) {
		return A5($author$project$JwtHttp$request, token, 2, url, body, expect);
	});
var $author$project$Pages$Tasklist$updateTaskCmd = F2(
	function (_v0, data) {
		var apiUrl = _v0.j0;
		var token = _v0.oK;
		var url = apiUrl + ('/tasklists/' + ($elm$core$String$fromInt(data.e3) + ('/tasks/' + $elm$core$String$fromInt(data.jd))));
		var body = $elm$http$Http$jsonBody(
			$author$project$Types$TaskUpdateRequestData$encoder(data));
		return A4(
			$author$project$JwtHttp$put,
			token,
			url,
			body,
			A2($elm$http$Http$expectJson, $author$project$Pages$Tasklist$TaskUpdated, $author$project$Data$Tasklists$tasklistDecoder));
	});
var $author$project$Pages$Tasklist$handleTaskEditorOutMsg = F5(
	function (serverContext, model, newTaskEditorStatus, taskEditorCmd, outMsg) {
		switch (outMsg.$) {
			case 0:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{M: newTaskEditorStatus}),
					A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$GotTaskEditorMsg, taskEditorCmd),
					$elm$core$Maybe$Nothing);
			case 3:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{M: $author$project$Pages$Tasklist$NotEditing}),
					A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$GotTaskEditorMsg, taskEditorCmd),
					$elm$core$Maybe$Nothing);
			case 1:
				var taskEditableFields = outMsg.a;
				var persistCmd = function () {
					switch (newTaskEditorStatus.$) {
						case 1:
							var tasklist = newTaskEditorStatus.b;
							var task = newTaskEditorStatus.c;
							var requestData = {hs: taskEditableFields, Y: model.Y, jd: task.er, e3: tasklist.er};
							return A2($author$project$Pages$Tasklist$updateTaskCmd, serverContext, requestData);
						case 2:
							var tasklist = newTaskEditorStatus.b;
							var requestData = {hs: taskEditableFields, Y: model.Y, e3: tasklist.er};
							return A2($author$project$Pages$Tasklist$createTaskCmd, serverContext, requestData);
						default:
							return $elm$core$Platform$Cmd$none;
					}
				}();
				var isPendingServerUpdateRequest = !_Utils_eq(persistCmd, $elm$core$Platform$Cmd$none);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{t: isPendingServerUpdateRequest, M: newTaskEditorStatus}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$GotTaskEditorMsg, taskEditorCmd),
								persistCmd
							])),
					$elm$core$Maybe$Nothing);
			case 2:
				var persistCmd = function () {
					if (newTaskEditorStatus.$ === 1) {
						var tasklist = newTaskEditorStatus.b;
						var task = newTaskEditorStatus.c;
						return A3($author$project$Pages$Tasklist$deleteTaskCmd, serverContext, tasklist.er, task.er);
					} else {
						return $elm$core$Platform$Cmd$none;
					}
				}();
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{t: true, M: newTaskEditorStatus}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$GotTaskEditorMsg, taskEditorCmd),
								persistCmd
							])),
					$elm$core$Maybe$Nothing);
			default:
				var notification = outMsg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{M: newTaskEditorStatus}),
					A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$GotTaskEditorMsg, taskEditorCmd),
					$elm$core$Maybe$Just(notification));
		}
	});
var $author$project$Pages$Tasklist$handleTasklistAlreadyClosedError = F2(
	function (loadedModel, notificationText) {
		return _Utils_Tuple3(
			{
				_: $author$project$Pages$Tasklist$Loaded(
					_Utils_update(
						loadedModel,
						{t: false}))
			},
			$elm$core$Platform$Cmd$none,
			$author$project$Pages$Tasklist$OutNotification(
				A2($author$project$Components$Notification$Notification, notificationText, 1)));
	});
var $author$project$Pages$Tasklist$handleTasklistCloseError = function (model) {
	return _Utils_Tuple3(
		{
			_: $author$project$Pages$Tasklist$Loaded(
				_Utils_update(
					model,
					{t: false, R: $author$project$Pages$Tasklist$ClosingProcessIdle}))
		},
		$elm$core$Platform$Cmd$none,
		$author$project$Pages$Tasklist$OutNotification(
			A2($author$project$Components$Notification$Notification, 'Lomakkeen sulkeminen epäonnistui', 1)));
};
var $author$project$Pages$Tasklist$TaskEditor$GotDatePickerMsg = function (a) {
	return {$: 19, a: a};
};
var $author$project$Pages$Tasklist$TaskEditor$GotMakeDropdownMsg = function (a) {
	return {$: 20, a: a};
};
var $author$project$Pages$Tasklist$TaskEditor$NoDialog = 0;
var $author$project$Pages$Tasklist$TaskEditor$NoOp = {$: 18};
var $author$project$Pages$Tasklist$TaskEditor$OutNoOp = {$: 0};
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $author$project$Types$Site$defaultWorkers = function (siteWorkers) {
	return ($elm$core$List$length(siteWorkers) === 1) ? A2($elm$core$List$take, 1, siteWorkers) : A2(
		$elm$core$List$filter,
		function ($) {
			return $.l4;
		},
		siteWorkers);
};
var $mdgriffith$elm_ui$Internal$Model$Attr = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Element$htmlAttribute = $mdgriffith$elm_ui$Internal$Model$Attr;
var $mdgriffith$elm_ui$Element$Input$Placeholder = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $mdgriffith$elm_ui$Element$Input$placeholder = $mdgriffith$elm_ui$Element$Input$Placeholder;
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $mdgriffith$elm_ui$Internal$Model$Text = function (a) {
	return {$: 2, a: a};
};
var $mdgriffith$elm_ui$Element$text = function (content) {
	return $mdgriffith$elm_ui$Internal$Model$Text(content);
};
var $author$project$Components$Dropdown$init = F4(
	function (id, placeholder, options, initialInputText) {
		var initialModel = {
			bT: $elm$core$List$sort(options),
			er: id,
			K: $elm$core$String$toUpper(
				A2($elm$core$Maybe$withDefault, '', initialInputText)),
			dl: false,
			nt: A2(
				$elm$core$Maybe$map,
				function (string) {
					return A2(
						$mdgriffith$elm_ui$Element$Input$placeholder,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$htmlAttribute(
								A2($elm$html$Html$Attributes$style, 'text-align', 'left'))
							]),
						$mdgriffith$elm_ui$Element$text(string));
				},
				placeholder),
			cB: false,
			x: _Utils_Tuple2(_List_Nil, $elm$core$Maybe$Nothing)
		};
		return _Utils_Tuple2(initialModel, $elm$core$Platform$Cmd$none);
	});
var $author$project$Pages$Tasklist$TaskEditor$initDatePicker = function (date) {
	return A4($author$project$Components$DatePicker$init, $elm$core$Maybe$Nothing, date, true, false);
};
var $elm$core$String$fromFloat = _String_fromNumber;
var $author$project$Pages$Tasklist$TaskEditor$price_to_string = function (price) {
	if (!price.$) {
		var _float = price.a;
		return $elm$core$String$fromFloat(_float);
	} else {
		return '';
	}
};
var $author$project$Helpers$roundCurrency = function (_float) {
	return $elm$core$Basics$round(_float * 100) / 100;
};
var $author$project$Pages$Tasklist$TaskEditor$initProductPriceStrings = F2(
	function (taskFields, priceInputMode) {
		return $elm$core$Dict$fromList(
			A2(
				$elm$core$List$map,
				function (_v0) {
					var productId = _v0.iz;
					var price = _v0.iv;
					var priceAccordingToVatMode = function () {
						var _v1 = _Utils_Tuple2(priceInputMode, price);
						if (_v1.a === 1) {
							var _v2 = _v1.a;
							var maybeVatlessPrice = _v1.b;
							return maybeVatlessPrice;
						} else {
							if (!_v1.b.$) {
								var _v3 = _v1.a;
								var vatlessPrice = _v1.b.a;
								return $elm$core$Maybe$Just(
									$author$project$Helpers$roundCurrency(vatlessPrice * 1.255));
							} else {
								var _v4 = _v1.a;
								var _v5 = _v1.b;
								return $elm$core$Maybe$Nothing;
							}
						}
					}();
					return _Utils_Tuple2(
						productId,
						$author$project$Pages$Tasklist$TaskEditor$price_to_string(priceAccordingToVatMode));
				},
				taskFields.gf));
	});
var $author$project$Utils$Custom$UseDefault = {$: 1};
var $author$project$Components$WorkerSelector$NoDialog = 1;
var $author$project$Helpers$isInSet = F2(
	function (set, a) {
		return A2($elm$core$Set$member, a, set);
	});
var $author$project$Components$WorkerSelector$init = function (_v0) {
	var allWorkers = _v0.bU;
	var quickAccessWorkerIds = _v0.iD;
	var selectedWorkerIds = _v0.ah;
	var styles = _v0.dx;
	return {
		bU: allWorkers,
		dj: 1,
		ga: A2(
			$elm$core$List$filter,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.er;
				},
				$author$project$Helpers$isInSet(quickAccessWorkerIds)),
			allWorkers),
		ah: selectedWorkerIds,
		dx: styles
	};
};
var $author$project$Helpers$isNotInList = F2(
	function (list, item) {
		return _Utils_eq(
			$elm$core$Maybe$Nothing,
			A2(
				$elm_community$list_extra$List$Extra$find,
				$elm$core$Basics$eq(item),
				list));
	});
var $author$project$Helpers$allInList = F3(
	function (listOfA, aToB, b) {
		return A2(
			$elm$core$List$filter,
			A2(
				$elm$core$Basics$composeR,
				aToB,
				$elm$core$Basics$eq(b)),
			listOfA);
	});
var $author$project$Helpers$firstInList = F3(
	function (listOfA, aToB, b) {
		return $elm$core$List$head(
			A3($author$project$Helpers$allInList, listOfA, aToB, b));
	});
var $author$project$Helpers$pickFromList = F3(
	function (listOfA, aToB, listOfB) {
		return A2(
			$elm$core$List$filterMap,
			A2($author$project$Helpers$firstInList, listOfA, aToB),
			listOfB);
	});
var $author$project$Pages$Tasklist$TaskEditor$initWorkerSelector = F4(
	function (allWorkers, siteWorkers, fields, tasklistOtherTasks) {
		var selectedWorkers = A3(
			$author$project$Helpers$pickFromList,
			allWorkers,
			function ($) {
				return $.er;
			},
			fields.jz);
		var selectedOffSiteWorkersOnThisTask = A2(
			$elm$core$List$filter,
			$author$project$Helpers$isNotInList(siteWorkers),
			selectedWorkers);
		var selectedOffSiteWorkersOnTasklistOtherTasks = A2(
			$elm$core$List$filter,
			$author$project$Helpers$isNotInList(selectedOffSiteWorkersOnThisTask),
			A2(
				$elm$core$List$filter,
				$author$project$Helpers$isNotInList(siteWorkers),
				A3(
					$author$project$Helpers$pickFromList,
					allWorkers,
					function ($) {
						return $.er;
					},
					$elm_community$list_extra$List$Extra$unique(
						$elm$core$List$concat(
							A2(
								$elm$core$List$map,
								function ($) {
									return $.jz;
								},
								tasklistOtherTasks))))));
		return $author$project$Components$WorkerSelector$init(
			{
				bU: allWorkers,
				iD: $elm$core$Set$fromList(
					A2(
						$elm$core$List$map,
						function ($) {
							return $.er;
						},
						_Utils_ap(
							siteWorkers,
							_Utils_ap(selectedOffSiteWorkersOnThisTask, selectedOffSiteWorkersOnTasklistOtherTasks)))),
				ah: $elm$core$Set$fromList(
					A2(
						$elm$core$List$map,
						function ($) {
							return $.er;
						},
						selectedWorkers)),
				dx: {g2: $author$project$Utils$Custom$UseDefault}
			});
	});
var $author$project$Types$User$getSiteUserInfo = function (siteUser) {
	switch (siteUser.$) {
		case 2:
			var siteuserInfo = siteUser.b;
			return siteuserInfo;
		case 0:
			var siteuserInfo = siteUser.b;
			return siteuserInfo;
		default:
			var siteuserInfo = siteUser.b;
			return siteuserInfo;
	}
};
var $author$project$Types$User$priceInputMode = function (siteUser) {
	return $author$project$Types$User$getSiteUserInfo(siteUser).nC;
};
var $author$project$Pages$Tasklist$TaskEditor$init = function (mode) {
	return function (user) {
		return function (customers) {
			return function (allAvailableProducts) {
				return function (userAvailableProducts) {
					return function (vehicleMakes) {
						return function (tasklist) {
							return function (tasklistOtherTasks) {
								return function (siteWorkers) {
									return function (allWorkers) {
										return function (taskFields) {
											var selectedWorkerIds = function () {
												if (!mode) {
													return A2(
														$elm$core$List$map,
														function ($) {
															return $.er;
														},
														$author$project$Types$Site$defaultWorkers(siteWorkers));
												} else {
													return taskFields.jz;
												}
											}();
											var priceInputMode = $author$project$Types$User$priceInputMode(user);
											var fields = _Utils_update(
												taskFields,
												{jz: selectedWorkerIds});
											var defaultPricesDict = $elm$core$Dict$fromList(
												A2(
													$elm$core$List$map,
													function (defaultPrice) {
														return _Utils_Tuple2(defaultPrice.iz, defaultPrice.k6);
													},
													tasklist.k7));
											var _v0 = A4(
												$author$project$Components$Dropdown$init,
												'vehicle-make',
												$elm$core$Maybe$Just('Merkki'),
												A2(
													$elm$core$List$map,
													$elm$core$String$toUpper,
													A2(
														$elm$core$List$map,
														function ($) {
															return $.mD;
														},
														vehicleMakes)),
												$elm$core$Maybe$Just(taskFields.fd));
											var makeDropdownModel = _v0.a;
											var makeDropdownCmd = _v0.b;
											var _v1 = $author$project$Pages$Tasklist$TaskEditor$initDatePicker($elm$core$Maybe$Nothing);
											var datePickerModel = _v1.a;
											var datePickerCmd = _v1.b;
											var initialModel = {
												T: allAvailableProducts,
												bU: allWorkers,
												ap: customers,
												bm: datePickerModel,
												fz: defaultPricesDict,
												hs: fields,
												ax: makeDropdownModel,
												ez: mode,
												nf: tasklist.nf,
												dj: 0,
												aQ: A2($author$project$Pages$Tasklist$TaskEditor$initProductPriceStrings, fields, priceInputMode),
												as: siteWorkers,
												cx: A4($author$project$Pages$Tasklist$TaskEditor$initWorkerSelector, allWorkers, siteWorkers, fields, tasklistOtherTasks),
												gt: tasklist.kX,
												e3: tasklist.er,
												jf: tasklist.mD,
												gu: tasklistOtherTasks,
												gv: tasklist.nE,
												gA: user,
												aT: userAvailableProducts,
												am: vehicleMakes
											};
											return _Utils_Tuple3(
												initialModel,
												$elm$core$Platform$Cmd$batch(
													_List_fromArray(
														[
															A2(
															$elm$core$Task$attempt,
															function (_v2) {
																return $author$project$Pages$Tasklist$TaskEditor$NoOp;
															},
															$elm$browser$Browser$Dom$focus('vehicle-license-number')),
															A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$TaskEditor$GotDatePickerMsg, datePickerCmd),
															A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$TaskEditor$GotMakeDropdownMsg, makeDropdownCmd)
														])),
												$author$project$Pages$Tasklist$TaskEditor$OutNoOp);
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Pages$Tasklist$init_Errored = function (errorDetails) {
	return _Utils_Tuple3(
		{
			_: $author$project$Pages$Tasklist$Errored(
				{eb: errorDetails})
		},
		$elm$core$Platform$Cmd$none,
		$author$project$Pages$Tasklist$OutNoOp);
};
var $author$project$Utils$MiscUtils$list_replaceBy = F3(
	function (idFunction, replacer, list) {
		return A2(
			$elm$core$List$map,
			function (item) {
				return _Utils_eq(
					idFunction(item),
					idFunction(replacer)) ? replacer : item;
			},
			list);
	});
var $elm$core$Set$remove = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$remove, key, dict);
	});
var $author$project$Pages$Tasklist$TaskQueue$Types$TaskQueue$removeTask = F2(
	function (taskToRemove, _v0) {
		var tq = _v0;
		return A2(
			$author$project$Pages$Tasklist$TaskQueue$Types$TaskQueue$create,
			tq.cs,
			A2(
				$elm$core$List$filter,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.er;
					},
					$elm$core$Basics$neq(taskToRemove.er)),
				tq.aj));
	});
var $author$project$Pages$Tasklist$TaskQueue$removeTaskFromQueue = F2(
	function (queuedTask, model) {
		var _v0 = model._;
		switch (_v0.$) {
			case 0:
				return model;
			case 1:
				var loadedModel = _v0.a;
				var updatedTaskQueue = A2($author$project$Pages$Tasklist$TaskQueue$Types$TaskQueue$removeTask, queuedTask, loadedModel.k_.z);
				return function (a) {
					return {
						_: $author$project$Pages$Tasklist$TaskQueue$Loaded(a)
					};
				}(
					_Utils_update(
						loadedModel,
						{
							k_: function (a) {
								return _Utils_update(
									a,
									{z: updatedTaskQueue});
							}(loadedModel.k_)
						}));
			default:
				return model;
		}
	});
var $author$project$Pages$Tasklist$WorkerTaskOperationNoticeAcknowledged = function (a) {
	return {$: 22, a: a};
};
var $author$project$Pages$Tasklist$WorkerTaskOperationNoticeUnacknowledged = function (a) {
	return {$: 23, a: a};
};
var $author$project$Pages$Tasklist$toggleWorkerTaskOperationAcknowledgementCmd = F3(
	function (_v0, tasklist, task) {
		var apiUrl = _v0.j0;
		var token = _v0.oK;
		var _v1 = task.o4;
		if (!_v1.$) {
			var isAcknowledged = _v1.a.l$;
			var taskEndpointUrl = apiUrl + ('/tasklists/' + ($elm$core$String$fromInt(tasklist.er) + ('/tasks/' + $elm$core$String$fromInt(task.er))));
			return isAcknowledged ? A4(
				$author$project$JwtHttp$post,
				token,
				taskEndpointUrl + '/unacknowledge-worker-operation-notice',
				$elm$http$Http$emptyBody,
				A2($elm$http$Http$expectJson, $author$project$Pages$Tasklist$WorkerTaskOperationNoticeUnacknowledged, $author$project$Data$Tasklists$tasklistDecoder)) : A4(
				$author$project$JwtHttp$post,
				token,
				taskEndpointUrl + '/acknowledge-worker-operation-notice',
				$elm$http$Http$emptyBody,
				A2($elm$http$Http$expectJson, $author$project$Pages$Tasklist$WorkerTaskOperationNoticeAcknowledged, $author$project$Data$Tasklists$tasklistDecoder));
		} else {
			return $elm$core$Platform$Cmd$none;
		}
	});
var $author$project$Pages$Tasklist$TaskEditor$GotSubModuleMsg_WorkerSelector = function (a) {
	return {$: 21, a: a};
};
var $author$project$Pages$Tasklist$TaskEditor$HideSubDialog = function (a) {
	return {$: 9, a: a};
};
var $author$project$Pages$Tasklist$TaskEditor$OutCancel = {$: 3};
var $author$project$Pages$Tasklist$TaskEditor$OutDeleteTask = {$: 2};
var $author$project$Pages$Tasklist$TaskEditor$OutNotification = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Tasklist$TaskEditor$OutSaveTask = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Tasklist$TaskEditor$SelectVehicleMake = function (a) {
	return {$: 13, a: a};
};
var $author$project$Pages$Tasklist$TaskEditor$VehicleMakeDialog = 1;
var $author$project$Pages$Tasklist$TaskEditor$WorkDateDialog = 2;
var $author$project$Pages$Tasklist$TaskEditor$cannotTouchProductNotification = function (availableProduct) {
	return A2($author$project$Components$Notification$Notification, 'Sinulla ei ole oikeuksia valita/muuttaa työtä ' + (availableProduct.ha + (' (' + (availableProduct.mD + ')'))), 0);
};
var $justinmimbs$date$Date$Days = 3;
var $justinmimbs$date$Date$Months = 1;
var $justinmimbs$date$Date$add = F3(
	function (unit, n, _v0) {
		var rd = _v0;
		switch (unit) {
			case 0:
				return A3($justinmimbs$date$Date$add, 1, 12 * n, rd);
			case 1:
				var date = $justinmimbs$date$Date$toCalendarDate(rd);
				var wholeMonths = ((12 * (date.fi - 1)) + ($justinmimbs$date$Date$monthToNumber(date.mw) - 1)) + n;
				var m = $justinmimbs$date$Date$numberToMonth(
					A2($elm$core$Basics$modBy, 12, wholeMonths) + 1);
				var y = A2($justinmimbs$date$Date$floorDiv, wholeMonths, 12) + 1;
				return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + A2(
					$elm$core$Basics$min,
					date.d5,
					A2($justinmimbs$date$Date$daysInMonth, y, m));
			case 2:
				return rd + (7 * n);
			default:
				return rd + n;
		}
	});
var $author$project$Types$TaskEditableFields$updateDate = F2(
	function (fields, value) {
		return _Utils_update(
			fields,
			{b4: value});
	});
var $author$project$Types$TaskEditableFields$decreaseDate = function (fields) {
	var updatedDate = A2(
		$elm$core$Maybe$map,
		A2($justinmimbs$date$Date$add, 3, -1),
		fields.b4);
	return A2($author$project$Types$TaskEditableFields$updateDate, fields, updatedDate);
};
var $author$project$Utils$Modules$NoParentMsg = {$: 1};
var $author$project$Utils$Modules$ParentMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$Utils$Modules$handleSubModuleUpdate = F7(
	function (parentUpdate, parentModel, setSubModelInParentModel, gotSubModuleMsg, subUpdate, subModel, subMsg) {
		var _v0 = A2(subUpdate, subMsg, subModel);
		var updatedSubModel = _v0.a;
		var subCmd = _v0.b;
		var maybeParentMsg = _v0.c;
		var updatedParentModel = A2(setSubModelInParentModel, updatedSubModel, parentModel);
		if (maybeParentMsg.$ === 1) {
			return _Utils_Tuple3(
				updatedParentModel,
				A2($elm$core$Platform$Cmd$map, gotSubModuleMsg, subCmd),
				$author$project$Utils$Modules$NoParentMsg);
		} else {
			var parentMsg = maybeParentMsg.a;
			var _v2 = A2(parentUpdate, parentMsg, updatedParentModel);
			var updatedParentModel2 = _v2.a;
			var parentCmd = _v2.b;
			var parentOutMsg = _v2.c;
			return _Utils_Tuple3(
				updatedParentModel2,
				$elm$core$Platform$Cmd$batch(
					_List_fromArray(
						[
							A2($elm$core$Platform$Cmd$map, gotSubModuleMsg, subCmd),
							parentCmd
						])),
				$author$project$Utils$Modules$ParentMsg(parentOutMsg));
		}
	});
var $author$project$Types$TaskEditableFields$increaseDate = function (fields) {
	var updatedDate = A2(
		$elm$core$Maybe$map,
		A2($justinmimbs$date$Date$add, 3, 1),
		fields.b4);
	return A2($author$project$Types$TaskEditableFields$updateDate, fields, updatedDate);
};
var $author$project$Pages$Tasklist$TaskEditor$productToggleButtonHtmlId = function (availableProduct) {
	return 'product-toggle-' + $elm$core$String$fromInt(availableProduct.er);
};
var $author$project$Types$TaskEditableFields$updateProducts = F2(
	function (fields, value) {
		return _Utils_update(
			fields,
			{gf: value});
	});
var $author$project$Types$TaskEditableFields$selectProduct = F3(
	function (fields, productId, defaultPrice) {
		var updatedSelectedProducts = A2(
			$elm$core$List$cons,
			{iv: defaultPrice, iz: productId},
			fields.gf);
		return A2($author$project$Types$TaskEditableFields$updateProducts, fields, updatedSelectedProducts);
	});
var $author$project$Types$TaskEditableFields$updateWorkerIds = F2(
	function (fields, workerIds) {
		return _Utils_update(
			fields,
			{
				jz: $elm_community$list_extra$List$Extra$unique(workerIds)
			});
	});
var $author$project$Types$TaskEditableFields$selectWorker = F2(
	function (fields, workerId) {
		var updatedWorkerIds = $elm_community$list_extra$List$Extra$unique(
			A2($elm$core$List$cons, workerId, fields.jz));
		return A2($author$project$Types$TaskEditableFields$updateWorkerIds, fields, updatedWorkerIds);
	});
var $elm$parser$Parser$ExpectingFloat = {$: 5};
var $elm$parser$Parser$Advanced$float = F2(
	function (expecting, invalid) {
		return $elm$parser$Parser$Advanced$number(
			{
				gY: $elm$core$Result$Err(invalid),
				hq: expecting,
				ht: $elm$core$Result$Ok($elm$core$Basics$identity),
				lI: $elm$core$Result$Err(invalid),
				hV: $elm$core$Result$Ok($elm$core$Basics$toFloat),
				lZ: invalid,
				id: $elm$core$Result$Err(invalid)
			});
	});
var $elm$parser$Parser$float = A2($elm$parser$Parser$Advanced$float, $elm$parser$Parser$ExpectingFloat, $elm$parser$Parser$ExpectingFloat);
var $elm$core$Result$toMaybe = function (result) {
	if (!result.$) {
		var v = result.a;
		return $elm$core$Maybe$Just(v);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Helpers$string_to_float = function (string) {
	return $elm$core$Result$toMaybe(
		A2($elm$parser$Parser$run, $elm$parser$Parser$float, string));
};
var $author$project$Types$TaskEditableFields$unselectProduct = F2(
	function (fields, productId) {
		var updatedSelectedProducts = A2(
			$elm$core$List$filter,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.iz;
				},
				$elm$core$Basics$neq(productId)),
			fields.gf);
		return A2($author$project$Types$TaskEditableFields$updateProducts, fields, updatedSelectedProducts);
	});
var $author$project$Types$TaskEditableFields$unselectWorker = F2(
	function (fields, workerId) {
		var updatedWorkerIds = A2(
			$elm$core$List$filter,
			$elm$core$Basics$neq(workerId),
			fields.jz);
		return A2($author$project$Types$TaskEditableFields$updateWorkerIds, fields, updatedWorkerIds);
	});
var $author$project$Components$Dropdown$NoOp = {$: 12};
var $author$project$Components$Dropdown$OutNoOp = {$: 0};
var $author$project$Components$Dropdown$OutOptionSelected = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Dropdown$SelectOption = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Dropdown$WholeComponentLosingFocus = {$: 4};
var $author$project$Components$Dropdown$focusHereCmd = function (_v0) {
	var id = _v0.er;
	return $elm$browser$Browser$Dom$focus(id);
};
var $elm_community$list_extra$List$Extra$getAt = F2(
	function (idx, xs) {
		return (idx < 0) ? $elm$core$Maybe$Nothing : $elm$core$List$head(
			A2($elm$core$List$drop, idx, xs));
	});
var $author$project$Components$Dropdown$getFocusedOption = function (model) {
	var _v0 = model.x;
	var visibleOptionsList = _v0.a;
	var maybeIndex = _v0.b;
	return A2(
		$elm$core$Maybe$andThen,
		function (index) {
			return A2($elm_community$list_extra$List$Extra$getAt, index, visibleOptionsList);
		},
		maybeIndex);
};
var $author$project$Components$Dropdown$optionStartsWith = F2(
	function (inputText, option) {
		return $elm$core$String$isEmpty(inputText) ? false : A2(
			$elm$core$String$startsWith,
			$elm$core$String$toUpper(inputText),
			$elm$core$String$toUpper(option));
	});
var $author$project$Components$Dropdown$getSuggestedOption = function (model) {
	var _v0 = model.x;
	var _v1 = $author$project$Components$Dropdown$getFocusedOption(model);
	if (!_v1.$) {
		var option = _v1.a;
		return $elm$core$Maybe$Just(option);
	} else {
		return $elm$core$List$head(
			$elm$core$List$sort(
				A2(
					$elm$core$List$filter,
					$author$project$Components$Dropdown$optionStartsWith(model.K),
					model.bT)));
	}
};
var $author$project$Components$Dropdown$update = F2(
	function (msg, model) {
		update:
		while (true) {
			switch (msg.$) {
				case 1:
					var option = msg.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								K: option,
								x: _Utils_Tuple2(_List_Nil, $elm$core$Maybe$Nothing)
							}),
						A2(
							$elm$core$Task$attempt,
							function (_v1) {
								return $author$project$Components$Dropdown$NoOp;
							},
							$author$project$Components$Dropdown$focusHereCmd(model)),
						$author$project$Components$Dropdown$OutOptionSelected(
							$elm$core$Maybe$Just(option)));
				case 4:
					var maybeSelectedOption = A2(
						$author$project$Helpers$firstWhere,
						$elm$core$Basics$eq(model.K),
						model.bT);
					if (!maybeSelectedOption.$) {
						var selectedOption = maybeSelectedOption.a;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									K: selectedOption,
									x: _Utils_Tuple2(_List_Nil, $elm$core$Maybe$Nothing)
								}),
							$elm$core$Platform$Cmd$none,
							$author$project$Components$Dropdown$OutOptionSelected(
								$elm$core$Maybe$Just(selectedOption)));
					} else {
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									K: '',
									cB: false,
									x: _Utils_Tuple2(_List_Nil, $elm$core$Maybe$Nothing)
								}),
							$elm$core$Platform$Cmd$none,
							$author$project$Components$Dropdown$OutOptionSelected($elm$core$Maybe$Nothing));
					}
				case 2:
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{cB: true}),
						$elm$core$Platform$Cmd$none,
						$author$project$Components$Dropdown$OutNoOp);
				case 3:
					var newmodel = _Utils_update(
						model,
						{cB: false});
					if (model.dl) {
						return _Utils_Tuple3(newmodel, $elm$core$Platform$Cmd$none, $author$project$Components$Dropdown$OutNoOp);
					} else {
						var $temp$msg = $author$project$Components$Dropdown$WholeComponentLosingFocus,
							$temp$model = newmodel;
						msg = $temp$msg;
						model = $temp$model;
						continue update;
					}
				case 5:
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{dl: true}),
						$elm$core$Platform$Cmd$none,
						$author$project$Components$Dropdown$OutNoOp);
				case 6:
					if (model.cB) {
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{dl: false}),
							$elm$core$Platform$Cmd$none,
							$author$project$Components$Dropdown$OutNoOp);
					} else {
						var $temp$msg = $author$project$Components$Dropdown$WholeComponentLosingFocus,
							$temp$model = model;
						msg = $temp$msg;
						model = $temp$model;
						continue update;
					}
				case 0:
					var inputText = msg.a;
					var newInputText = $elm$core$String$toUpper(inputText);
					var newVisibleOptions = $elm$core$List$sort(
						A2(
							$elm$core$List$filter,
							$author$project$Components$Dropdown$optionStartsWith(newInputText),
							model.bT));
					var newFocusedOptionIndex = $elm$core$List$isEmpty(newVisibleOptions) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(0);
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								K: newInputText,
								x: _Utils_Tuple2(newVisibleOptions, newFocusedOptionIndex)
							}),
						$elm$core$Platform$Cmd$none,
						$author$project$Components$Dropdown$OutNoOp);
				case 7:
					var _v3 = $author$project$Components$Dropdown$getFocusedOption(model);
					if (!_v3.$) {
						var focusedOption = _v3.a;
						var $temp$msg = $author$project$Components$Dropdown$SelectOption(focusedOption),
							$temp$model = model;
						msg = $temp$msg;
						model = $temp$model;
						continue update;
					} else {
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Components$Dropdown$OutNoOp);
					}
				case 8:
					var _v4 = model.x;
					if (_v4.b.$ === 1) {
						var _v5 = _v4.b;
						var suggestedOption = $author$project$Components$Dropdown$getSuggestedOption(model);
						var newInputText = function () {
							if (!suggestedOption.$) {
								var option = suggestedOption.a;
								return option;
							} else {
								return model.K;
							}
						}();
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									K: newInputText,
									x: _Utils_Tuple2(_List_Nil, $elm$core$Maybe$Nothing)
								}),
							$elm$core$Platform$Cmd$none,
							$author$project$Components$Dropdown$OutOptionSelected(suggestedOption));
					} else {
						var visibleOptions = _v4.a;
						var focusedOptionIndex = _v4.b.a;
						var focusedOption = A2(
							$elm$core$Maybe$withDefault,
							'',
							A2($elm_community$list_extra$List$Extra$getAt, focusedOptionIndex, visibleOptions));
						var _v7 = A2(
							$author$project$Components$Dropdown$update,
							$author$project$Components$Dropdown$SelectOption(focusedOption),
							model);
						var newModel = _v7.a;
						var selectOptionCmd = _v7.b;
						var selectOptionOutMsg = _v7.c;
						return _Utils_Tuple3(newModel, selectOptionCmd, selectOptionOutMsg);
					}
				case 9:
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								K: '',
								x: _Utils_Tuple2(_List_Nil, $elm$core$Maybe$Nothing)
							}),
						$elm$core$Platform$Cmd$none,
						$author$project$Components$Dropdown$OutNoOp);
				case 10:
					var newFocusedOptionIndex = function () {
						var _v8 = model.x;
						if (_v8.b.$ === 1) {
							var visibleOptions = _v8.a;
							var _v9 = _v8.b;
							return $elm$core$Maybe$Just(
								$elm$core$List$length(visibleOptions) - 1);
						} else {
							var visibleOptions = _v8.a;
							var oldFocusedOptionIndex = _v8.b.a;
							return (!oldFocusedOptionIndex) ? $elm$core$Maybe$Just(
								$elm$core$List$length(visibleOptions) - 1) : $elm$core$Maybe$Just(oldFocusedOptionIndex - 1);
						}
					}();
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								x: _Utils_Tuple2(model.x.a, newFocusedOptionIndex)
							}),
						$elm$core$Platform$Cmd$none,
						$author$project$Components$Dropdown$OutNoOp);
				case 11:
					var newFocusedOptionIndex = function () {
						var _v10 = model.x;
						if (_v10.b.$ === 1) {
							var _v11 = _v10.b;
							return $elm$core$Maybe$Just(0);
						} else {
							var visibleOptions = _v10.a;
							var oldFocusedOptionIndex = _v10.b.a;
							return _Utils_eq(
								oldFocusedOptionIndex,
								$elm$core$List$length(visibleOptions) - 1) ? $elm$core$Maybe$Just(0) : $elm$core$Maybe$Just(oldFocusedOptionIndex + 1);
						}
					}();
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								x: _Utils_Tuple2(model.x.a, newFocusedOptionIndex)
							}),
						$elm$core$Platform$Cmd$none,
						$author$project$Components$Dropdown$OutNoOp);
				default:
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Components$Dropdown$OutNoOp);
			}
		}
	});
var $author$project$Components$WorkerSelector$AllWorkersDialog = 0;
var $author$project$Components$WorkerSelector$NoOp = {$: 4};
var $author$project$Components$WorkerSelector$openAllWorkersDialogButtonId = 'open-workers-dialog-button';
var $author$project$Components$WorkerSelector$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var worker = msg.a;
				var parentMsg = msg.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							ah: A2($elm$core$Set$remove, worker.er, model.ah)
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Utils$Modules$ParentMsg(parentMsg));
			case 1:
				var worker = msg.a;
				var parentMsg = msg.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							ah: A2($elm$core$Set$insert, worker.er, model.ah)
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Utils$Modules$ParentMsg(parentMsg));
			case 2:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{dj: 1}),
					A2(
						$elm$core$Task$attempt,
						function (_v1) {
							return $author$project$Components$WorkerSelector$NoOp;
						},
						$author$project$Components$CloseButton$focusOnMe),
					$author$project$Utils$Modules$NoParentMsg);
			case 3:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{dj: 0}),
					A2(
						$elm$core$Task$attempt,
						function (_v2) {
							return $author$project$Components$WorkerSelector$NoOp;
						},
						$elm$browser$Browser$Dom$focus($author$project$Components$WorkerSelector$openAllWorkersDialogButtonId)),
					$author$project$Utils$Modules$NoParentMsg);
			default:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Utils$Modules$NoParentMsg);
		}
	});
var $author$project$Types$TaskEditableFields$updateComment = F2(
	function (fields, value) {
		return _Utils_update(
			fields,
			{kH: value});
	});
var $author$project$Types$TaskEditableFields$updateLicenseNumber = F2(
	function (fields, licenseNumberString) {
		var existingLicenseNumber = fields.mf;
		return _Utils_update(
			fields,
			{
				mf: A2(
					$author$project$Types$Vehicle$LicenseNumberField$addDashAfterThreeLettersTyped,
					existingLicenseNumber,
					$author$project$Types$Vehicle$LicenseNumberField$create(licenseNumberString))
			});
	});
var $author$project$Helpers$limitDecimalsTo = F2(
	function (maxDecimals, _float) {
		var decimalCount = A2(
			$elm$core$Maybe$withDefault,
			0,
			A2(
				$elm$core$Maybe$map,
				$elm$core$String$length,
				A2(
					$elm_community$list_extra$List$Extra$getAt,
					1,
					A2(
						$elm$core$String$split,
						'.',
						$elm$core$String$fromFloat(_float)))));
		return (_Utils_cmp(decimalCount, maxDecimals) > 0) ? ($elm$core$Basics$floor(_float * 100) / 100) : _float;
	});
var $author$project$Types$TaskEditableFields$updateProductPrice = F3(
	function (fields, selectedProduct, maybePrice) {
		var validatedMaybePrice = A2(
			$elm$core$Maybe$map,
			$author$project$Helpers$limitDecimalsTo(2),
			A2(
				$elm$core$Maybe$map,
				$elm$core$Basics$min(9999.99),
				maybePrice));
		var updatedProducts = A2(
			$elm$core$List$map,
			function (p) {
				return _Utils_eq(p.iz, selectedProduct.iz) ? _Utils_update(
					p,
					{iv: validatedMaybePrice}) : p;
			},
			fields.gf);
		return A2($author$project$Types$TaskEditableFields$updateProducts, fields, updatedProducts);
	});
var $author$project$Types$TaskEditableFields$updateVehicleMake = F2(
	function (fields, value) {
		return _Utils_update(
			fields,
			{fd: value});
	});
var $author$project$Types$TaskEditableFields$updateVehicleModel = F2(
	function (fields, value) {
		return _Utils_update(
			fields,
			{oY: value});
	});
var $author$project$Pages$Tasklist$TaskEditor$update = F2(
	function (msg, model) {
		update:
		while (true) {
			switch (msg.$) {
				case 17:
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$Tasklist$TaskEditor$OutCancel);
				case 15:
					var taskEditableFields = msg.a;
					return _Utils_Tuple3(
						model,
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Tasklist$TaskEditor$OutSaveTask(taskEditableFields));
				case 16:
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$Tasklist$TaskEditor$OutDeleteTask);
				case 0:
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								hs: $author$project$Types$TaskEditableFields$decreaseDate(model.hs)
							}),
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Tasklist$TaskEditor$OutNoOp);
				case 1:
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								hs: $author$project$Types$TaskEditableFields$increaseDate(model.hs)
							}),
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Tasklist$TaskEditor$OutNoOp);
				case 2:
					var licenseNumberString = msg.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								hs: A2($author$project$Types$TaskEditableFields$updateLicenseNumber, model.hs, licenseNumberString)
							}),
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Tasklist$TaskEditor$OutNoOp);
				case 13:
					var value = msg.a;
					var previousMakeDropdownModel = model.ax;
					var updatedMakeDropdownModel = _Utils_update(
						previousMakeDropdownModel,
						{
							K: $elm$core$String$toUpper(value)
						});
					var newModel = _Utils_update(
						model,
						{
							hs: A2($author$project$Types$TaskEditableFields$updateVehicleMake, model.hs, value),
							ax: updatedMakeDropdownModel
						});
					var $temp$msg = $author$project$Pages$Tasklist$TaskEditor$HideSubDialog($elm$core$Maybe$Nothing),
						$temp$model = newModel;
					msg = $temp$msg;
					model = $temp$model;
					continue update;
				case 3:
					var value = msg.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								hs: A2($author$project$Types$TaskEditableFields$updateVehicleModel, model.hs, value)
							}),
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Tasklist$TaskEditor$OutNoOp);
				case 10:
					var worker = msg.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								hs: A2($author$project$Types$TaskEditableFields$unselectWorker, model.hs, worker.er)
							}),
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Tasklist$TaskEditor$OutNoOp);
				case 11:
					var worker = msg.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								hs: A2($author$project$Types$TaskEditableFields$selectWorker, model.hs, worker.er)
							}),
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Tasklist$TaskEditor$OutNoOp);
				case 6:
					var availableProduct = msg.a;
					return _Utils_Tuple3(
						model,
						A2(
							$author$project$Helpers$focusOn,
							$author$project$Pages$Tasklist$TaskEditor$productToggleButtonHtmlId(availableProduct),
							$author$project$Pages$Tasklist$TaskEditor$NoOp),
						$author$project$Pages$Tasklist$TaskEditor$OutNotification(
							$author$project$Pages$Tasklist$TaskEditor$cannotTouchProductNotification(availableProduct)));
				case 4:
					var productId = msg.a;
					var defaultPrice = msg.b;
					var updatedProductPriceStrings = A3(
						$elm$core$Dict$insert,
						productId,
						$author$project$Pages$Tasklist$TaskEditor$price_to_string(defaultPrice),
						model.aQ);
					var focusCmd = function () {
						if (!defaultPrice.$) {
							return $elm$core$Platform$Cmd$none;
						} else {
							return A2(
								$author$project$Helpers$focusOn,
								'price-input-' + $elm$core$String$fromInt(productId),
								$author$project$Pages$Tasklist$TaskEditor$NoOp);
						}
					}();
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								hs: A3($author$project$Types$TaskEditableFields$selectProduct, model.hs, productId, defaultPrice),
								aQ: updatedProductPriceStrings
							}),
						focusCmd,
						$author$project$Pages$Tasklist$TaskEditor$OutNoOp);
				case 5:
					var productId = msg.a;
					var updatedProductPriceStrings = A2($elm$core$Dict$remove, productId, model.aQ);
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								hs: A2($author$project$Types$TaskEditableFields$unselectProduct, model.hs, productId),
								aQ: updatedProductPriceStrings
							}),
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Tasklist$TaskEditor$OutNoOp);
				case 7:
					var value = msg.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								hs: A2($author$project$Types$TaskEditableFields$updateComment, model.hs, value)
							}),
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Tasklist$TaskEditor$OutNoOp);
				case 8:
					var selectedProduct = msg.a;
					var priceString = msg.b;
					var priceInputMode = $author$project$Types$User$priceInputMode(model.gA);
					var newProductPriceInputs = A3($elm$core$Dict$insert, selectedProduct.iz, priceString, model.aQ);
					var maybePrice = function () {
						if (priceInputMode === 1) {
							return $author$project$Helpers$string_to_float(priceString);
						} else {
							return A2(
								$elm$core$Maybe$map,
								function (price) {
									return $author$project$Helpers$roundCurrency(price / 1.255);
								},
								$author$project$Helpers$string_to_float(priceString));
						}
					}();
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								hs: A3($author$project$Types$TaskEditableFields$updateProductPrice, model.hs, selectedProduct, maybePrice),
								aQ: newProductPriceInputs
							}),
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Tasklist$TaskEditor$OutNoOp);
				case 12:
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{dj: 1}),
						A2(
							$elm$core$Task$attempt,
							function (_v4) {
								return $author$project$Pages$Tasklist$TaskEditor$NoOp;
							},
							$author$project$Components$CloseButton$focusOnMe),
						$author$project$Pages$Tasklist$TaskEditor$OutNoOp);
				case 14:
					var _v5 = $author$project$Pages$Tasklist$TaskEditor$initDatePicker(model.hs.b4);
					var datePickerModel = _v5.a;
					var datePickerCmd = _v5.b;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{bm: datePickerModel, dj: 2}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2(
									$elm$core$Task$attempt,
									function (_v6) {
										return $author$project$Pages$Tasklist$TaskEditor$NoOp;
									},
									$author$project$Components$CloseButton$focusOnMe),
									A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$TaskEditor$GotDatePickerMsg, datePickerCmd)
								])),
						$author$project$Pages$Tasklist$TaskEditor$OutNoOp);
				case 9:
					var maybeFocusAfterCloseId = msg.a;
					if (!maybeFocusAfterCloseId.$) {
						var elementId = maybeFocusAfterCloseId.a;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{dj: 0}),
							A2(
								$elm$core$Task$attempt,
								function (_v8) {
									return $author$project$Pages$Tasklist$TaskEditor$NoOp;
								},
								$elm$browser$Browser$Dom$focus(elementId)),
							$author$project$Pages$Tasklist$TaskEditor$OutNoOp);
					} else {
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{dj: 0}),
							$elm$core$Platform$Cmd$none,
							$author$project$Pages$Tasklist$TaskEditor$OutNoOp);
					}
				case 18:
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$Tasklist$TaskEditor$OutNoOp);
				case 19:
					var datePickerMsg = msg.a;
					var _v9 = A2($author$project$Components$DatePicker$update, datePickerMsg, model.bm);
					var newDatePickerModel = _v9.a;
					var datePickerCmd = _v9.b;
					var outMsg = _v9.c;
					switch (outMsg.$) {
						case 0:
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{bm: newDatePickerModel}),
								A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$TaskEditor$GotDatePickerMsg, datePickerCmd),
								$author$project$Pages$Tasklist$TaskEditor$OutNoOp);
						case 1:
							var date = outMsg.a;
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{
										bm: newDatePickerModel,
										hs: A2($author$project$Types$TaskEditableFields$updateDate, model.hs, date),
										dj: 0
									}),
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$TaskEditor$GotDatePickerMsg, datePickerCmd),
											A2(
											$elm$core$Task$attempt,
											function (_v11) {
												return $author$project$Pages$Tasklist$TaskEditor$NoOp;
											},
											$elm$browser$Browser$Dom$focus('open-work-date-dialog-button'))
										])),
								$author$project$Pages$Tasklist$TaskEditor$OutNoOp);
						default:
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{dj: 0}),
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$TaskEditor$GotDatePickerMsg, datePickerCmd),
											A2(
											$elm$core$Task$attempt,
											function (_v12) {
												return $author$project$Pages$Tasklist$TaskEditor$NoOp;
											},
											$elm$browser$Browser$Dom$focus('open-work-date-dialog-button'))
										])),
								$author$project$Pages$Tasklist$TaskEditor$OutNoOp);
					}
				case 20:
					var dropdownMsg = msg.a;
					var _v13 = A2($author$project$Components$Dropdown$update, dropdownMsg, model.ax);
					var newMakeDropdownModel = _v13.a;
					var makeDropdownCmd = _v13.b;
					var outMsg = _v13.c;
					if (!outMsg.$) {
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{ax: newMakeDropdownModel}),
							A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$TaskEditor$GotMakeDropdownMsg, makeDropdownCmd),
							$author$project$Pages$Tasklist$TaskEditor$OutNoOp);
					} else {
						var maybeMake = outMsg.a;
						var makeString = function () {
							if (!maybeMake.$) {
								var make = maybeMake.a;
								return make;
							} else {
								return '';
							}
						}();
						var _v15 = A2(
							$author$project$Pages$Tasklist$TaskEditor$update,
							$author$project$Pages$Tasklist$TaskEditor$SelectVehicleMake(makeString),
							_Utils_update(
								model,
								{ax: newMakeDropdownModel}));
						var newModel = _v15.a;
						var cmd = _v15.b;
						var outMsg2 = _v15.c;
						return _Utils_Tuple3(
							_Utils_update(
								newModel,
								{ax: newMakeDropdownModel}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$TaskEditor$GotMakeDropdownMsg, makeDropdownCmd),
										cmd
									])),
							outMsg2);
					}
				default:
					var subMsg = msg.a;
					return A2($author$project$Pages$Tasklist$TaskEditor$updateSubModule_WorkerSelector, model, subMsg);
			}
		}
	});
var $author$project$Pages$Tasklist$TaskEditor$updateSubModule_WorkerSelector = F2(
	function (model, subMsg) {
		var _v0 = A7(
			$author$project$Utils$Modules$handleSubModuleUpdate,
			$author$project$Pages$Tasklist$TaskEditor$update,
			model,
			F2(
				function (subModel, parentModel) {
					return _Utils_update(
						parentModel,
						{cx: subModel});
				}),
			$author$project$Pages$Tasklist$TaskEditor$GotSubModuleMsg_WorkerSelector,
			$author$project$Components$WorkerSelector$update,
			model.cx,
			subMsg);
		var model_ = _v0.a;
		var cmd = _v0.b;
		return _Utils_Tuple3(model_, cmd, $author$project$Pages$Tasklist$TaskEditor$OutNoOp);
	});
var $author$project$Pages$Tasklist$TaskQueue$OutNoOp = {$: 5};
var $author$project$Pages$Tasklist$TaskQueue$Errored = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$update_Errored = F3(
	function (_v0, msg, model) {
		return _Utils_Tuple3(
			{
				_: $author$project$Pages$Tasklist$TaskQueue$Errored(model)
			},
			$elm$core$Platform$Cmd$none,
			$author$project$Pages$Tasklist$TaskQueue$OutNoOp);
	});
var $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskDragStatus$DraggingOutsideTheQueue = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskDragStatus$DraggingOverAnotherQueuedTask = F3(
	function (a, b, c) {
		return {$: 2, a: a, b: b, c: c};
	});
var $author$project$Pages$Tasklist$TaskQueue$EditorDialog = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Pages$Tasklist$TaskQueue$GotDataRefresh = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$GotEditQueuedTaskResponse = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$GotEditorMsg = function (a) {
	return {$: 20, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$GotTaskCreatorMsg = function (a) {
	return {$: 19, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$NoDialog = {$: 0};
var $author$project$Pages$Tasklist$TaskQueue$OutDraggableDropped = {$: 3};
var $author$project$Pages$Tasklist$TaskQueue$OutGotAuthError = {$: 1};
var $author$project$Pages$Tasklist$TaskQueue$OutNotification = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$QueueScrollButtonsEnabled = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$RequestDataRefresh = {$: 9};
var $author$project$Pages$Tasklist$TaskQueue$TaskCreatorCollapsed = {$: 1};
var $author$project$Pages$Tasklist$TaskQueue$TaskCreatorExpanded = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskDragStatus$DraggedDown = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskDragStatus$DraggedUp = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskDragStatus$calculateDragDirectionInQueue = F3(
	function (taskQueue, draggedQueuedTask, targetQueuedTask) {
		var indexInQueue = function (task) {
			return A2(
				$elm$core$Maybe$withDefault,
				-1,
				$elm$core$List$head(
					A2(
						$elm$core$List$filterMap,
						$elm$core$Maybe$map($elm$core$Basics$identity),
						A2(
							$elm$core$List$indexedMap,
							F2(
								function (index, queuedTask) {
									return _Utils_eq(queuedTask.er, task.er) ? $elm$core$Maybe$Just(index) : $elm$core$Maybe$Nothing;
								}),
							$author$project$Pages$Tasklist$TaskQueue$Types$TaskQueue$getTasks(taskQueue)))));
		};
		var targetIndex = indexInQueue(targetQueuedTask);
		var draggedIndex = indexInQueue(draggedQueuedTask);
		return (_Utils_cmp(draggedIndex, targetIndex) > 0) ? A2($author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskDragStatus$DraggedUp, draggedIndex, targetIndex) : A2($author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskDragStatus$DraggedDown, draggedIndex, targetIndex);
	});
var $author$project$Helpers$constrainBetween = F3(
	function (limit1, limit2, value) {
		var floor = A2($elm$core$Basics$min, limit1, limit2);
		var ceil = A2($elm$core$Basics$max, limit1, limit2);
		return A2(
			$elm$core$Basics$min,
			A2($elm$core$Basics$max, floor, value),
			ceil);
	});
var $author$project$Pages$Tasklist$TaskQueue$Requests$decreaseQueuedTaskPriorityCmd = F3(
	function (_v0, queuedTask, onResponse) {
		var apiUrl = _v0.j0;
		var token = _v0.oK;
		var url = A2($author$project$Pages$Tasklist$TaskQueue$Requests$queuedTaskSiteUrl, apiUrl, queuedTask.cs) + ($elm$core$String$fromInt(
			$author$project$Pages$Tasklist$TaskQueue$Types$QueuedTask$unwrapQueuedTaskId(queuedTask.er)) + '/decrease-priority');
		return A4(
			$author$project$JwtHttp$put,
			token,
			url,
			$elm$http$Http$emptyBody,
			A2($elm$http$Http$expectJson, onResponse, $author$project$Pages$Tasklist$TaskQueue$Types$TaskQueue$taskQueueDecoder));
	});
var $author$project$Types$Site$fetchProducts = F2(
	function (_v0, site) {
		var apiUrl = _v0.j0;
		var token = _v0.oK;
		return $author$project$JwtHttp$task(
			{
				kc: $elm$http$Http$emptyBody,
				mr: 'GET',
				nJ: $elm$http$Http$stringResolver(
					$author$project$JwtHttp$handleJsonResponse(
						$elm$json$Json$Decode$list($author$project$Data$Products$availableProductDecoder))),
				oH: $elm$core$Maybe$Nothing,
				oK: token,
				oU: apiUrl + ('/sites/' + ($elm$core$String$fromInt(
					$author$project$Types$Site$id(site)) + '/products'))
			});
	});
var $author$project$Types$ServerContext$queuedTasksUrl = F2(
	function (_v0, siteId) {
		var apiUrl = _v0.j0;
		return apiUrl + ('/sites/' + ($elm$core$String$fromInt(siteId) + '/queued-tasks/'));
	});
var $author$project$Pages$Tasklist$TaskQueue$Requests$fetchTaskQueue = F2(
	function (serverContext, site) {
		var siteId = $author$project$Types$Site$id(site);
		return $author$project$JwtHttp$task(
			{
				kc: $elm$http$Http$emptyBody,
				mr: 'GET',
				nJ: $elm$http$Http$stringResolver(
					$author$project$JwtHttp$handleJsonResponse($author$project$Pages$Tasklist$TaskQueue$Types$TaskQueue$taskQueueDecoder)),
				oH: $elm$core$Maybe$Nothing,
				oK: serverContext.oK,
				oU: A2($author$project$Types$ServerContext$queuedTasksUrl, serverContext, siteId)
			});
	});
var $elm$core$Task$map5 = F6(
	function (func, taskA, taskB, taskC, taskD, taskE) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return A2(
							$elm$core$Task$andThen,
							function (c) {
								return A2(
									$elm$core$Task$andThen,
									function (d) {
										return A2(
											$elm$core$Task$andThen,
											function (e) {
												return $elm$core$Task$succeed(
													A5(func, a, b, c, d, e));
											},
											taskE);
									},
									taskD);
							},
							taskC);
					},
					taskB);
			},
			taskA);
	});
var $author$project$Pages$Tasklist$TaskQueue$fetchData = F2(
	function (serverContext, user) {
		var site = $author$project$Types$User$site(user);
		return A6(
			$elm$core$Task$map5,
			F5(
				function (taskQueue, siteProducts, siteWorkers, vehicleMakes, allWorkers) {
					return {bU: allWorkers, ct: siteProducts, as: siteWorkers, z: taskQueue, am: vehicleMakes};
				}),
			A2($author$project$Pages$Tasklist$TaskQueue$Requests$fetchTaskQueue, serverContext, site),
			A2($author$project$Types$Site$fetchProducts, serverContext, site),
			A2($author$project$Types$Site$fetchWorkers, serverContext, site),
			$author$project$Data$VehicleMakes$fetch(serverContext),
			$author$project$Types$Worker$fetchAll(serverContext));
	});
var $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskDragStatus$DroppedOnAnotherQueuedTask = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskDragStatus$DroppedOutsideTheQueue = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$GotRearrangeQueueByDraggingResponse = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$OutInitDrag = F4(
	function (a, b, c, d) {
		return {$: 2, a: a, b: b, c: c, d: d};
	});
var $author$project$Pages$Tasklist$TaskQueue$Requests$moveQueuedTaskBeforeAnotherCmd = F4(
	function (_v0, queuedTask1, queuedTask2, onResponse) {
		var apiUrl = _v0.j0;
		var token = _v0.oK;
		var url = A2($author$project$Pages$Tasklist$TaskQueue$Requests$queuedTaskSiteUrl, apiUrl, queuedTask1.cs) + ($elm$core$String$fromInt(
			$author$project$Pages$Tasklist$TaskQueue$Types$QueuedTask$unwrapQueuedTaskId(queuedTask1.er)) + ('/move-before/' + $elm$core$String$fromInt(
			$author$project$Pages$Tasklist$TaskQueue$Types$QueuedTask$unwrapQueuedTaskId(queuedTask2.er))));
		return A4(
			$author$project$JwtHttp$put,
			token,
			url,
			$elm$http$Http$emptyBody,
			A2($elm$http$Http$expectJson, onResponse, $author$project$Pages$Tasklist$TaskQueue$Types$TaskQueue$taskQueueDecoder));
	});
var $author$project$Components$Drag$Dragging = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Drag$NotDragging = {$: 0};
var $author$project$Components$Drag$OutDragStarted = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Drag$OutDragging = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Drag$OutDropped = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Drag$OutNoOp = {$: 3};
var $author$project$Components$Drag$dragAndDropDebug = _Platform_outgoingPort('dragAndDropDebug', $elm$json$Json$Encode$string);
var $author$project$Components$Drag$getDraggedBeacon = F2(
	function (cursorCoordinates, beacons) {
		var isInsideBeacon = F2(
			function (cursor, beacon) {
				return (_Utils_cmp(cursor.D, beacon.D) > -1) && ((_Utils_cmp(cursor.D, beacon.D + beacon.fg) < 1) && ((_Utils_cmp(cursor.E, beacon.E) > -1) && (_Utils_cmp(cursor.E, beacon.E + beacon.en) < 1)));
			});
		return A2(
			$author$project$Helpers$firstWhere,
			isInsideBeacon(cursorCoordinates),
			beacons);
	});
var $author$project$Components$Drag$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var event = msg.a;
				var cursor = msg.b;
				var beacons = msg.c;
				var _v1 = _Utils_Tuple2(model.cw, event);
				_v1$3:
				while (true) {
					if (!_v1.a.$) {
						if (!_v1.b) {
							var _v2 = _v1.a;
							var _v3 = _v1.b;
							return A2(
								$elm$core$Maybe$withDefault,
								_Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Components$Drag$OutNoOp),
								A2(
									$elm$core$Maybe$map,
									function (draggedBeacon) {
										var draggableEntity = A2($elm$core$Dict$get, draggedBeacon.er, model.d8);
										if (!draggableEntity.$) {
											var a = draggableEntity.a;
											var draggedDomElement = {en: draggedBeacon.en, er: draggedBeacon.er, fg: draggedBeacon.fg};
											var cursorOffset = {D: cursor.D - draggedBeacon.D, E: cursor.E - draggedBeacon.E};
											var dragInfo = {b2: cursor, fw: cursorOffset, hk: draggedDomElement, fE: a, e_: cursor};
											return _Utils_Tuple3(
												_Utils_update(
													model,
													{
														cw: $author$project$Components$Drag$Dragging(dragInfo)
													}),
												$elm$core$Platform$Cmd$none,
												$author$project$Components$Drag$OutDragStarted(dragInfo));
										} else {
											return _Utils_Tuple3(
												model,
												$author$project$Components$Drag$dragAndDropDebug('Did not find draggable with id \'' + (draggedBeacon.er + '\'')),
												$author$project$Components$Drag$OutNoOp);
										}
									},
									A2($author$project$Components$Drag$getDraggedBeacon, cursor, beacons)));
						} else {
							break _v1$3;
						}
					} else {
						switch (_v1.b) {
							case 1:
								var dragInfo = _v1.a.a;
								var _v5 = _v1.b;
								var newDraggingData = function (a) {
									return _Utils_update(
										a,
										{b2: cursor});
								}(dragInfo);
								return _Utils_Tuple3(
									_Utils_update(
										model,
										{
											cw: $author$project$Components$Drag$Dragging(newDraggingData)
										}),
									$elm$core$Platform$Cmd$none,
									$author$project$Components$Drag$OutDragging(newDraggingData));
							case 2:
								var dragInfo = _v1.a.a;
								var _v6 = _v1.b;
								var newDraggingData = function (a) {
									return _Utils_update(
										a,
										{b2: cursor});
								}(dragInfo);
								return _Utils_Tuple3(
									_Utils_update(
										model,
										{cw: $author$project$Components$Drag$NotDragging}),
									$elm$core$Platform$Cmd$none,
									$author$project$Components$Drag$OutDropped(newDraggingData));
							default:
								break _v1$3;
						}
					}
				}
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Components$Drag$OutNoOp);
			case 1:
				var error = msg.a;
				return _Utils_Tuple3(
					model,
					$author$project$Components$Drag$dragAndDropDebug(
						'Error decoding drag events' + ($elm$json$Json$Decode$errorToString(error) + '\'')),
					$author$project$Components$Drag$OutNoOp);
			default:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Components$Drag$OutNoOp);
		}
	});
var $author$project$Pages$Tasklist$TaskQueue$handleDragMsg = F3(
	function (dragMsg, appContext, loadedModel) {
		var _v0 = A2($author$project$Components$Drag$update, dragMsg, loadedModel.a$);
		var updatedDragModel = _v0.a;
		var dragCmd = _v0.b;
		var dragOutMsg = _v0.c;
		var loadedModel2 = _Utils_update(
			loadedModel,
			{a$: updatedDragModel});
		switch (dragOutMsg.$) {
			case 0:
				var dragInfo = dragOutMsg.a;
				return _Utils_Tuple3(
					{
						_: $author$project$Pages$Tasklist$TaskQueue$Loaded(
							_Utils_update(
								loadedModel2,
								{
									O: $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskDragStatus$DraggingOutsideTheQueue(dragInfo)
								}))
					},
					A2(
						$elm$core$Platform$Cmd$map,
						$author$project$Pages$Tasklist$TaskQueue$Msg_Loaded,
						A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$TaskQueue$GotDragMsg, dragCmd)),
					A4($author$project$Pages$Tasklist$TaskQueue$OutInitDrag, loadedModel.k_.z, dragInfo.fE, dragInfo.hk, dragInfo.b2));
			case 1:
				var dragInfo = dragOutMsg.a;
				var updatedDragStatus = function () {
					var _v2 = loadedModel2.O;
					switch (_v2.$) {
						case 0:
							return $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskDragStatus$NotDragging;
						case 1:
							return $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskDragStatus$DraggingOutsideTheQueue(dragInfo);
						case 3:
							return $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskDragStatus$DroppedOutsideTheQueue(dragInfo);
						case 2:
							var targetQueuedTask = _v2.b;
							var dragDirection = _v2.c;
							return A3($author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskDragStatus$DraggingOverAnotherQueuedTask, dragInfo, targetQueuedTask, dragDirection);
						default:
							var targetQueuedTask = _v2.b;
							var dragDirection = _v2.c;
							return A3($author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskDragStatus$DroppedOnAnotherQueuedTask, dragInfo, targetQueuedTask, dragDirection);
					}
				}();
				return _Utils_Tuple3(
					{
						_: $author$project$Pages$Tasklist$TaskQueue$Loaded(
							_Utils_update(
								loadedModel2,
								{O: updatedDragStatus}))
					},
					A2(
						$elm$core$Platform$Cmd$map,
						$author$project$Pages$Tasklist$TaskQueue$Msg_Loaded,
						A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$TaskQueue$GotDragMsg, dragCmd)),
					$author$project$Pages$Tasklist$TaskQueue$OutNoOp);
			case 2:
				var _v3 = function () {
					var _v4 = loadedModel.O;
					switch (_v4.$) {
						case 1:
							var dragInfo = _v4.a;
							return _Utils_Tuple3(
								_Utils_update(
									loadedModel2,
									{
										O: $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskDragStatus$DroppedOutsideTheQueue(dragInfo)
									}),
								$elm$core$Platform$Cmd$none,
								$author$project$Pages$Tasklist$TaskQueue$OutDraggableDropped);
						case 2:
							var dragInfo = _v4.a;
							var targetTask = _v4.b;
							var dragDirection = _v4.c;
							return _Utils_Tuple3(
								_Utils_update(
									loadedModel2,
									{
										O: A3($author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskDragStatus$DroppedOnAnotherQueuedTask, dragInfo, targetTask, dragDirection)
									}),
								A4($author$project$Pages$Tasklist$TaskQueue$Requests$moveQueuedTaskBeforeAnotherCmd, appContext.n1, dragInfo.fE, targetTask, $author$project$Pages$Tasklist$TaskQueue$GotRearrangeQueueByDraggingResponse),
								$author$project$Pages$Tasklist$TaskQueue$OutNoOp);
						case 3:
							return _Utils_Tuple3(loadedModel2, $elm$core$Platform$Cmd$none, $author$project$Pages$Tasklist$TaskQueue$OutNoOp);
						case 4:
							return _Utils_Tuple3(loadedModel2, $elm$core$Platform$Cmd$none, $author$project$Pages$Tasklist$TaskQueue$OutNoOp);
						default:
							return _Utils_Tuple3(loadedModel2, $elm$core$Platform$Cmd$none, $author$project$Pages$Tasklist$TaskQueue$OutNoOp);
					}
				}();
				var loadedModel3 = _v3.a;
				var dropCmd = _v3.b;
				var dragOutMsg2 = _v3.c;
				return _Utils_Tuple3(
					{
						_: $author$project$Pages$Tasklist$TaskQueue$Loaded(loadedModel3)
					},
					A2(
						$elm$core$Platform$Cmd$map,
						$author$project$Pages$Tasklist$TaskQueue$Msg_Loaded,
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$TaskQueue$GotDragMsg, dragCmd),
									dropCmd
								]))),
					dragOutMsg2);
			default:
				return _Utils_Tuple3(
					{
						_: $author$project$Pages$Tasklist$TaskQueue$Loaded(loadedModel2)
					},
					A2(
						$elm$core$Platform$Cmd$map,
						$author$project$Pages$Tasklist$TaskQueue$Msg_Loaded,
						A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$TaskQueue$GotDragMsg, dragCmd)),
					$author$project$Pages$Tasklist$TaskQueue$OutNoOp);
		}
	});
var $author$project$Pages$Tasklist$TaskQueue$OutErrored = {$: 4};
var $author$project$Pages$Tasklist$TaskQueue$handleErrorResponse = function (errMsg) {
	if ((errMsg.$ === 3) && (errMsg.a === 401)) {
		return _Utils_Tuple3(
			{
				_: $author$project$Pages$Tasklist$TaskQueue$Errored(
					{})
			},
			$elm$core$Platform$Cmd$none,
			$author$project$Pages$Tasklist$TaskQueue$OutGotAuthError);
	} else {
		return _Utils_Tuple3(
			{
				_: $author$project$Pages$Tasklist$TaskQueue$Errored(
					{})
			},
			$elm$core$Platform$Cmd$none,
			$author$project$Pages$Tasklist$TaskQueue$OutErrored);
	}
};
var $author$project$Pages$Tasklist$TaskQueue$GotCreateQueuedTaskResponse = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$extractVehicle = function (_v0) {
	var licenseNumberField = _v0.mf;
	var vehicleMake = _v0.fd;
	var vehicleModel = _v0.oY;
	return A3(
		$author$project$Types$Vehicle$Vehicle$Vehicle,
		$author$project$Types$Vehicle$LicenseNumberField$toLicenseNumber(licenseNumberField),
		$elm$core$String$trim(vehicleMake),
		$elm$core$String$trim(vehicleModel));
};
var $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTask$queuedTaskFieldsEncoder = function (queuedTaskFields) {
	var vehicle = $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$extractVehicle(queuedTaskFields);
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'workerIds',
				A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$int, queuedTaskFields.jz)),
				_Utils_Tuple2(
				'vehicle',
				$author$project$Encode$vehicleEncoder(vehicle)),
				_Utils_Tuple2(
				'targetDate',
				$author$project$Encode$dateEncoder(queuedTaskFields.e2)),
				_Utils_Tuple2(
				'products',
				A2($elm$json$Json$Encode$list, $author$project$Encode$selectedProductEncoder, queuedTaskFields.iA)),
				_Utils_Tuple2(
				'instructions',
				$elm$json$Json$Encode$string(queuedTaskFields.lY)),
				_Utils_Tuple2(
				'comment',
				$elm$json$Json$Encode$string(queuedTaskFields.kH))
			]));
};
var $author$project$Pages$Tasklist$TaskQueue$Requests$createQueuedTaskCmd = F4(
	function (serverContext, site, queuedTaskFields, onResponse) {
		var url = A2(
			$author$project$Types$ServerContext$queuedTasksUrl,
			serverContext,
			$author$project$Types$Site$id(site));
		var body = $elm$http$Http$jsonBody(
			$author$project$Pages$Tasklist$TaskQueue$Types$QueuedTask$queuedTaskFieldsEncoder(queuedTaskFields));
		return A4(
			$author$project$JwtHttp$post,
			serverContext.oK,
			url,
			body,
			A2($elm$http$Http$expectJson, onResponse, $author$project$Pages$Tasklist$TaskQueue$Types$TaskQueue$taskQueueDecoder));
	});
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$CreateButtonClicked = {$: 7};
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$GotMakeDropdownMsg = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$GotProductSelectorsMsg = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$GotSubModuleMsg_WorkerSelector = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$NoDialog = 0;
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$NoOp = {$: 13};
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$OutCollapse = {$: 1};
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$OutCreateQueuedTask = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$OutNoOp = {$: 3};
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$OutNotification = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$VehicleMakeDialog = 1;
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$GotDropdownMsg = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$NoDialog = {$: 1};
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$NoOp = {$: 3};
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$OutNoOp = {$: 1};
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$OutSelectionChanged = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$SelectProductDialog = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$getSelectedProducts = function (productSelectorDict) {
	return A2(
		$elm$core$List$filterMap,
		function ($) {
			return $.aP;
		},
		$elm$core$Dict$values(productSelectorDict));
};
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$openSelectProductDialogButtonId = function (productSelectorKey) {
	return 'open-queued-task-product-dialog-button--' + $elm$core$String$fromInt(productSelectorKey);
};
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$productCodeToProduct = F2(
	function (availableProducts, productCode) {
		return A2(
			$author$project$Helpers$firstWhere,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.ha;
				},
				$elm$core$Basics$eq(productCode)),
			availableProducts);
	});
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$getUnselectedProducts = F2(
	function (allAvailableProducts, productSelectors) {
		var selectedProducts = $author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$getSelectedProducts(productSelectors);
		return A2(
			$elm$core$List$filter,
			function (availableProduct) {
				return $elm$core$List$isEmpty(
					A2(
						$elm$core$List$filter,
						$elm$core$Basics$eq(availableProduct),
						selectedProducts));
			},
			allAvailableProducts);
	});
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$initProductDropDown = F2(
	function (availableProducts, productSelectorKey) {
		var productSelectorKeyString = $elm$core$String$fromInt(productSelectorKey);
		var _v0 = A4(
			$author$project$Components$Dropdown$init,
			'product-' + productSelectorKeyString,
			$elm$core$Maybe$Just('Tuote ' + productSelectorKeyString),
			A2(
				$elm$core$List$map,
				function ($) {
					return $.ha;
				},
				availableProducts),
			$elm$core$Maybe$Just(''));
		var dropdownModel = _v0.a;
		return dropdownModel;
	});
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$syncAvailableProducts = F2(
	function (allAvailableProducts, productSelectors) {
		var availableUnselectedProducts = A2($author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$getUnselectedProducts, allAvailableProducts, productSelectors);
		var productSelectorsWithSelectedProducts = $elm$core$Dict$fromList(
			A2(
				$elm$core$List$indexedMap,
				F2(
					function (i, _v0) {
						var product = _v0.a;
						var dropdownModel = _v0.b;
						var productSelectorKey = i + 1;
						var availableOptions = $elm$core$List$sort(
							A2(
								$elm$core$List$map,
								function ($) {
									return $.ha;
								},
								A2($elm$core$List$cons, product, availableUnselectedProducts)));
						return _Utils_Tuple2(
							productSelectorKey,
							{
								lk: _Utils_update(
									dropdownModel,
									{bT: availableOptions}),
								l7: productSelectorKey,
								aP: $elm$core$Maybe$Just(product)
							});
					}),
				A2(
					$elm$core$List$filterMap,
					function (productSelector) {
						return A2(
							$elm$core$Maybe$andThen,
							function (product) {
								return $elm$core$Maybe$Just(
									_Utils_Tuple2(product, productSelector.lk));
							},
							A2($author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$productCodeToProduct, allAvailableProducts, productSelector.lk.K));
					},
					$elm$core$Dict$values(productSelectors))));
		var selectedProductsCount = $elm$core$Dict$size(productSelectorsWithSelectedProducts);
		var blankProductSelectorKey = selectedProductsCount + 1;
		var blankProductSelector = {
			lk: A2($author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$initProductDropDown, availableUnselectedProducts, blankProductSelectorKey),
			l7: blankProductSelectorKey,
			aP: $elm$core$Maybe$Nothing
		};
		return A3($elm$core$Dict$insert, blankProductSelectorKey, blankProductSelector, productSelectorsWithSelectedProducts);
	});
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 1:
				var productSelector = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							c1: $author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$SelectProductDialog(productSelector)
						}),
					A2(
						$elm$core$Task$attempt,
						function (_v1) {
							return $author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$NoOp;
						},
						$author$project$Components$CloseButton$focusOnMe),
					$author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$OutNoOp);
			case 2:
				var productSelector = msg.a;
				var selectedProduct = msg.b;
				var updatedProductSelector = _Utils_update(
					productSelector,
					{
						lk: function (a) {
							return _Utils_update(
								a,
								{
									K: A2(
										$elm$core$Maybe$withDefault,
										'',
										A2(
											$elm$core$Maybe$map,
											function ($) {
												return $.ha;
											},
											selectedProduct))
								});
						}(productSelector.lk),
						aP: selectedProduct
					});
				var updatedProductSelectorDict = A2(
					$author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$syncAvailableProducts,
					model.T,
					A3($elm$core$Dict$insert, productSelector.l7, updatedProductSelector, model.bc));
				var focusOnDialogOpenButton = $elm$browser$Browser$Dom$focus(
					$author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$openSelectProductDialogButtonId(productSelector.l7));
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{c1: $author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$NoDialog, bc: updatedProductSelectorDict}),
					A2(
						$elm$core$Task$attempt,
						function (_v2) {
							return $author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$NoOp;
						},
						focusOnDialogOpenButton),
					$author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$OutSelectionChanged(
						$author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$getSelectedProducts(updatedProductSelectorDict)));
			case 0:
				var productSelector = msg.a;
				var dropdownMsg = msg.b;
				var _v3 = A2($author$project$Components$Dropdown$update, dropdownMsg, productSelector.lk);
				var updatedDropdownModel = _v3.a;
				var dropdownCmd = _v3.b;
				var dropdownOutMsg = _v3.c;
				var selectedProduct = function () {
					if (dropdownOutMsg.$ === 1) {
						if (!dropdownOutMsg.a.$) {
							var productCode = dropdownOutMsg.a.a;
							return A2($author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$productCodeToProduct, model.T, productCode);
						} else {
							var _v7 = dropdownOutMsg.a;
							return $elm$core$Maybe$Nothing;
						}
					} else {
						return productSelector.aP;
					}
				}();
				var updatedProductSelector = _Utils_update(
					productSelector,
					{lk: updatedDropdownModel, aP: selectedProduct});
				var updatedProductSelectorDict = A3($elm$core$Dict$insert, productSelector.l7, updatedProductSelector, model.bc);
				var _v4 = function () {
					if (dropdownOutMsg.$ === 1) {
						return _Utils_Tuple2(
							A2($author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$syncAvailableProducts, model.T, updatedProductSelectorDict),
							$author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$OutSelectionChanged(
								$author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$getSelectedProducts(updatedProductSelectorDict)));
					} else {
						return _Utils_Tuple2(updatedProductSelectorDict, $author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$OutNoOp);
					}
				}();
				var updatedProductSelectorDict2 = _v4.a;
				var outMsg = _v4.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{bc: updatedProductSelectorDict2}),
					A2(
						$elm$core$Platform$Cmd$map,
						$author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$GotDropdownMsg(updatedProductSelector),
						dropdownCmd),
					outMsg);
			default:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$OutNoOp);
		}
	});
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$handleProductSelectorsMsg = F2(
	function (subMsg, subMobel) {
		var _v0 = A2($author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$update, subMsg, subMobel);
		var updatedSubModel = _v0.a;
		var subCmd = _v0.b;
		var subOutMsg = _v0.c;
		if (!subOutMsg.$) {
			var products = subOutMsg.a;
			return _Utils_Tuple3(
				$elm$core$Maybe$Just(products),
				updatedSubModel,
				subCmd);
		} else {
			return _Utils_Tuple3($elm$core$Maybe$Nothing, updatedSubModel, subCmd);
		}
	});
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$hideSubDialog = F2(
	function (model, focusOnElementId) {
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{dj: 0}),
			A2(
				$elm$core$Task$attempt,
				function (_v0) {
					return $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$NoOp;
				},
				$elm$browser$Browser$Dom$focus(focusOnElementId)));
	});
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$initMakeDropDown = F2(
	function (vehicleMakes, selectedVehicleMake) {
		var _v0 = A4(
			$author$project$Components$Dropdown$init,
			'vehicle-make',
			$elm$core$Maybe$Just('Merkki'),
			A2(
				$elm$core$List$map,
				$elm$core$String$toUpper,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.mD;
					},
					vehicleMakes)),
			selectedVehicleMake);
		var dropdownModel = _v0.a;
		return dropdownModel;
	});
var $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$empty = {kH: '', lY: '', mf: $author$project$Types$Vehicle$LicenseNumberField$empty, iA: _List_Nil, e2: $elm$core$Maybe$Nothing, fd: '', oY: '', jz: _List_Nil};
var $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$isEmpty = function (fields) {
	return _Utils_eq(
		fields,
		_Utils_update(
			$author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$empty,
			{e2: fields.e2}));
};
var $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$selectWorker = F2(
	function (fields, workerId) {
		return _Utils_update(
			fields,
			{
				jz: A2($elm$core$List$cons, workerId, fields.jz)
			});
	});
var $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$unselectWorker = F2(
	function (fields, workerId) {
		var updatedWorkerIds = A2(
			$elm$core$List$filter,
			$elm$core$Basics$neq(workerId),
			fields.jz);
		return _Utils_update(
			fields,
			{jz: updatedWorkerIds});
	});
var $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$updateInstructions = F2(
	function (fields, value) {
		return _Utils_update(
			fields,
			{lY: value});
	});
var $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$updateLicenseNumber = F2(
	function (fields, licenseNumberString) {
		var existingLicenseNumber = fields.mf;
		return _Utils_update(
			fields,
			{
				mf: A2(
					$author$project$Types$Vehicle$LicenseNumberField$addDashAfterThreeLettersTyped,
					existingLicenseNumber,
					$author$project$Types$Vehicle$LicenseNumberField$create(licenseNumberString))
			});
	});
var $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$updateProducts = F2(
	function (fields, value) {
		return _Utils_update(
			fields,
			{iA: value});
	});
var $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$updateVehicleMake = F2(
	function (fields, value) {
		return _Utils_update(
			fields,
			{fd: value});
	});
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$update = F3(
	function (appContext, msg, model) {
		switch (msg.$) {
			case 0:
				var licenseNumberString = msg.a;
				var updatedQueuedTaskFields = A2($author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$updateLicenseNumber, model.hs, licenseNumberString);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{hs: updatedQueuedTaskFields}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$OutNoOp);
			case 1:
				var value = msg.a;
				var updatedQueuedTaskFields = A2($author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$updateInstructions, model.hs, value);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{hs: updatedQueuedTaskFields}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$OutNoOp);
			case 7:
				if ($author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$isEmpty(model.hs)) {
					return _Utils_Tuple3(
						model,
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$OutNotification(
							A2($author$project$Components$Notification$Notification, 'Tyhjää työtä ei voi lisätä jonoon', 1)));
				} else {
					var fieldsWithTargetDateToday = function (a) {
						return _Utils_update(
							a,
							{
								e2: $elm$core$Maybe$Just(appContext.dE)
							});
					}(model.hs);
					return _Utils_Tuple3(
						model,
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$OutCreateQueuedTask(fieldsWithTargetDateToday));
				}
			case 4:
				var vehicleMake = msg.a;
				var updatedMakeDrodownModel = A2(
					$author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$initMakeDropDown,
					model.am,
					$elm$core$Maybe$Just(vehicleMake));
				var updatedFields = A2($author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$updateVehicleMake, model.hs, vehicleMake);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{hs: updatedFields, dj: 0, cH: updatedMakeDrodownModel}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$OutNoOp);
			case 8:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$OutCollapse);
			case 5:
				var worker = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							hs: A2($author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$unselectWorker, model.hs, worker.er)
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$OutNoOp);
			case 6:
				var worker = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							hs: A2($author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$selectWorker, model.hs, worker.er)
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$OutNoOp);
			case 10:
				var dropdownMsg = msg.a;
				var _v2 = A2($author$project$Components$Dropdown$update, dropdownMsg, model.cH);
				var updatedMakeDrodownModel = _v2.a;
				var makedropdownCmd = _v2.b;
				var makeDropdownOutMsg = _v2.c;
				var updatedFields = function () {
					if (makeDropdownOutMsg.$ === 1) {
						var maybeOption = makeDropdownOutMsg.a;
						return A2(
							$author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$updateVehicleMake,
							model.hs,
							A2($elm$core$Maybe$withDefault, '', maybeOption));
					} else {
						return model.hs;
					}
				}();
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{hs: updatedFields, cH: updatedMakeDrodownModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$GotMakeDropdownMsg, makedropdownCmd),
					$author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$OutNoOp);
			case 11:
				var subMsg = msg.a;
				var _v4 = A2($author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$handleProductSelectorsMsg, subMsg, model.dr);
				var changedProducts = _v4.a;
				var updatedProductSelectorsModel = _v4.b;
				var productSelectorsCmd = _v4.c;
				var updatedFields = function () {
					if (!changedProducts.$) {
						var products = changedProducts.a;
						var selectedProducts = A2(
							$elm$core$List$map,
							function (product) {
								return {iv: $elm$core$Maybe$Nothing, iz: product.er};
							},
							products);
						return A2($author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$updateProducts, model.hs, selectedProducts);
					} else {
						return model.hs;
					}
				}();
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{hs: updatedFields, dr: updatedProductSelectorsModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$GotProductSelectorsMsg, productSelectorsCmd),
					$author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$OutNoOp);
			case 12:
				var subMsg = msg.a;
				return A3($author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$updateSubModule_WorkerSelector, appContext, model, subMsg);
			case 9:
				return _Utils_Tuple3(
					model,
					A2(
						$elm$core$Task$attempt,
						function (_v6) {
							return $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$CreateButtonClicked;
						},
						$elm$browser$Browser$Dom$focus('create-queued-task-button')),
					$author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$OutNoOp);
			case 2:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{dj: 1}),
					A2(
						$elm$core$Task$attempt,
						function (_v7) {
							return $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$NoOp;
						},
						$author$project$Components$CloseButton$focusOnMe),
					$author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$OutNoOp);
			case 3:
				var focusAfterCloseId = msg.a;
				var _v8 = A2($author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$hideSubDialog, model, focusAfterCloseId);
				var updatedModel = _v8.a;
				var cmd = _v8.b;
				return _Utils_Tuple3(updatedModel, cmd, $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$OutNoOp);
			default:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$OutNoOp);
		}
	});
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$updateSubModule_WorkerSelector = F3(
	function (appContext, model, subMsg) {
		var _v0 = A7(
			$author$project$Utils$Modules$handleSubModuleUpdate,
			$author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$update(appContext),
			model,
			F2(
				function (subModel, parentModel) {
					return _Utils_update(
						parentModel,
						{cx: subModel});
				}),
			$author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$GotSubModuleMsg_WorkerSelector,
			$author$project$Components$WorkerSelector$update,
			model.cx,
			subMsg);
		var model_ = _v0.a;
		var cmd = _v0.b;
		return _Utils_Tuple3(model_, cmd, $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$OutNoOp);
	});
var $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$updateWorkerIds = F2(
	function (fields, value) {
		return _Utils_update(
			fields,
			{jz: value});
	});
var $author$project$Types$User$workers = F2(
	function (allWorkers, siteUser) {
		var siteWorkerIds = $author$project$Types$User$getSiteUserInfo(siteUser).jz;
		return A2(
			$elm$core$List$filter,
			function (worker) {
				return A2($elm$core$List$member, worker.er, siteWorkerIds);
			},
			allWorkers);
	});
var $author$project$Pages$Tasklist$TaskQueue$handleTaskCreatorMsg = F3(
	function (appContext, taskCreatorMsg, loadedModel) {
		var _v0 = function () {
			var _v1 = loadedModel.ai;
			if (_v1.$ === 1) {
				return _Utils_Tuple3(loadedModel, $elm$core$Platform$Cmd$none, $author$project$Pages$Tasklist$TaskQueue$OutNoOp);
			} else {
				var taskCreatorModel = _v1.a;
				var _v2 = A3($author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$update, appContext, taskCreatorMsg, taskCreatorModel);
				var updatedTaskCreatorModel = _v2.a;
				var taskCreatorCmd = _v2.b;
				var taskCreatorOutMsg = _v2.c;
				var updatedTaskCretorState = $author$project$Pages$Tasklist$TaskQueue$TaskCreatorExpanded(updatedTaskCreatorModel);
				switch (taskCreatorOutMsg.$) {
					case 0:
						var queuedTaskFields = taskCreatorOutMsg.a;
						var site = $author$project$Types$User$site(loadedModel.gA);
						var queuedTaskFields_ = function () {
							var _v4 = queuedTaskFields.jz;
							if (!_v4.b) {
								var defaultWorkerIds = A2(
									$elm$core$List$map,
									function ($) {
										return $.er;
									},
									$author$project$Types$Site$defaultWorkers(
										A2($author$project$Types$User$workers, loadedModel.k_.bU, loadedModel.gA)));
								return A2($author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$updateWorkerIds, queuedTaskFields, defaultWorkerIds);
							} else {
								return queuedTaskFields;
							}
						}();
						return _Utils_Tuple3(
							_Utils_update(
								loadedModel,
								{t: true, ai: updatedTaskCretorState}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A4($author$project$Pages$Tasklist$TaskQueue$Requests$createQueuedTaskCmd, appContext.n1, site, queuedTaskFields_, $author$project$Pages$Tasklist$TaskQueue$GotCreateQueuedTaskResponse),
										A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$TaskQueue$GotTaskCreatorMsg, taskCreatorCmd)
									])),
							$author$project$Pages$Tasklist$TaskQueue$OutNoOp);
					case 2:
						var notification = taskCreatorOutMsg.a;
						return _Utils_Tuple3(
							_Utils_update(
								loadedModel,
								{ai: updatedTaskCretorState}),
							A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$TaskQueue$GotTaskCreatorMsg, taskCreatorCmd),
							$author$project$Pages$Tasklist$TaskQueue$OutNotification(notification));
					case 1:
						return _Utils_Tuple3(
							_Utils_update(
								loadedModel,
								{ai: $author$project$Pages$Tasklist$TaskQueue$TaskCreatorCollapsed}),
							A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$TaskQueue$GotTaskCreatorMsg, taskCreatorCmd),
							$author$project$Pages$Tasklist$TaskQueue$OutNoOp);
					default:
						return _Utils_Tuple3(
							_Utils_update(
								loadedModel,
								{ai: updatedTaskCretorState}),
							A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$TaskQueue$GotTaskCreatorMsg, taskCreatorCmd),
							$author$project$Pages$Tasklist$TaskQueue$OutNoOp);
				}
			}
		}();
		var loadedModel2 = _v0.a;
		var cmd = _v0.b;
		var outMsg = _v0.c;
		return _Utils_Tuple3(
			{
				_: $author$project$Pages$Tasklist$TaskQueue$Loaded(loadedModel2)
			},
			A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$TaskQueue$Msg_Loaded, cmd),
			outMsg);
	});
var $author$project$Pages$Tasklist$TaskQueue$Requests$deleteQueuedTaskCmd = F3(
	function (_v0, queuedTask, onResponse) {
		var apiUrl = _v0.j0;
		var token = _v0.oK;
		var url = _Utils_ap(
			A2($author$project$Pages$Tasklist$TaskQueue$Requests$queuedTaskSiteUrl, apiUrl, queuedTask.cs),
			$elm$core$String$fromInt(
				$author$project$Pages$Tasklist$TaskQueue$Types$QueuedTask$unwrapQueuedTaskId(queuedTask.er)));
		return A4(
			$author$project$JwtHttp$delete,
			token,
			url,
			$elm$http$Http$emptyBody,
			A2($elm$http$Http$expectJson, onResponse, $author$project$Pages$Tasklist$TaskQueue$Types$TaskQueue$taskQueueDecoder));
	});
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$GotDatePickerMsg = function (a) {
	return {$: 21, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$GotMakeDropdownMsg = function (a) {
	return {$: 22, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$GotSubModuleMsg_WorkerSelector = function (a) {
	return {$: 23, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$NoDialog = 0;
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$NoOp = {$: 20};
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$OutCancel = {$: 3};
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$OutDeleteTask = {$: 2};
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$OutNoOp = {$: 0};
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$OutSaveTask = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$VehicleMakeDialog = 1;
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$WorkDateDialog = 2;
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$hideSubDialog = F2(
	function (model, focusOnElementId) {
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{dj: 0}),
			A2(
				$elm$core$Task$attempt,
				function (_v0) {
					return $author$project$Pages$Tasklist$TaskQueue$TaskEditor$NoOp;
				},
				$elm$browser$Browser$Dom$focus(focusOnElementId)));
	});
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$initDatePicker = function (date) {
	return A4($author$project$Components$DatePicker$init, $elm$core$Maybe$Nothing, date, true, true);
};
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$initVehicleMakeDropdown = F2(
	function (vehicleMakes, currentValue) {
		return A4(
			$author$project$Components$Dropdown$init,
			'vehicle-make',
			$elm$core$Maybe$Just('Merkki'),
			A2(
				$elm$core$List$map,
				$elm$core$String$toUpper,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.mD;
					},
					vehicleMakes)),
			$elm$core$Maybe$Just(currentValue));
	});
var $author$project$Components$Vehicles$MakeSelector$openVehicleMakeDialogButtonId = 'open-vehicle-make-dialog-button';
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$price_to_string = function (price) {
	if (!price.$) {
		var _float = price.a;
		return $elm$core$String$fromFloat(_float);
	} else {
		return '';
	}
};
var $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$unselectProduct = F2(
	function (fields, productId) {
		var updatedProducts = A2(
			$elm$core$List$filter,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.iz;
				},
				$elm$core$Basics$neq(productId)),
			fields.iA);
		return _Utils_update(
			fields,
			{iA: updatedProducts});
	});
var $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$selectProduct = F3(
	function (fields, productId, price) {
		var validatedPrice = A2(
			$elm$core$Maybe$map,
			$author$project$Helpers$limitDecimalsTo(2),
			A2(
				$elm$core$Maybe$map,
				$elm$core$Basics$min(9999.99),
				price));
		var updatedProduct = {iv: validatedPrice, iz: productId};
		var otherProducts = A2($author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$unselectProduct, fields, productId).iA;
		return _Utils_update(
			fields,
			{
				iA: A2($elm$core$List$cons, updatedProduct, otherProducts)
			});
	});
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$selectProduct = F3(
	function (model, productId, defaultPrice) {
		var updatedFields = A3($author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$selectProduct, model.hs, productId, defaultPrice);
		return _Utils_update(
			model,
			{hs: updatedFields});
	});
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$unselectProduct = F2(
	function (model, productId) {
		var updatedFields = A2($author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$unselectProduct, model.hs, productId);
		return _Utils_update(
			model,
			{hs: updatedFields});
	});
var $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$updateComment = F2(
	function (fields, value) {
		return _Utils_update(
			fields,
			{kH: value});
	});
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$updateProductPrice = F3(
	function (model, selectedProduct, price) {
		var updatedFields = A3($author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$selectProduct, model.hs, selectedProduct.iz, price);
		return _Utils_update(
			model,
			{hs: updatedFields});
	});
var $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$updateTargetDate = F2(
	function (fields, value) {
		return _Utils_update(
			fields,
			{e2: value});
	});
var $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$updateVehicleModel = F2(
	function (fields, value) {
		return _Utils_update(
			fields,
			{oY: value});
	});
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 19:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$Tasklist$TaskQueue$TaskEditor$OutCancel);
			case 17:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Tasklist$TaskQueue$TaskEditor$OutSaveTask(model.hs));
			case 18:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$Tasklist$TaskQueue$TaskEditor$OutDeleteTask);
			case 1:
				var newTargetDate = A2(
					$elm$core$Maybe$withDefault,
					model.dE,
					A2(
						$elm$core$Maybe$map,
						A2(
							$elm$core$Basics$composeR,
							A2($justinmimbs$date$Date$add, 3, -1),
							$elm$core$Maybe$Just),
						model.hs.e2));
				var updatedFields = A2($author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$updateTargetDate, model.hs, newTargetDate);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{hs: updatedFields}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Tasklist$TaskQueue$TaskEditor$OutNoOp);
			case 2:
				var newTargetDate = A2(
					$elm$core$Maybe$withDefault,
					model.dE,
					A2(
						$elm$core$Maybe$map,
						A2(
							$elm$core$Basics$composeR,
							A2($justinmimbs$date$Date$add, 3, 1),
							$elm$core$Maybe$Just),
						model.hs.e2));
				var updatedFields = A2($author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$updateTargetDate, model.hs, newTargetDate);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{hs: updatedFields}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Tasklist$TaskQueue$TaskEditor$OutNoOp);
			case 3:
				var updatedFields = A2($author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$updateTargetDate, model.hs, $elm$core$Maybe$Nothing);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{hs: updatedFields}),
					A2(
						$elm$core$Task$attempt,
						function (_v2) {
							return $author$project$Pages$Tasklist$TaskQueue$TaskEditor$NoOp;
						},
						$elm$browser$Browser$Dom$focus('open-work-date-dialog-button')),
					$author$project$Pages$Tasklist$TaskQueue$TaskEditor$OutNoOp);
			case 4:
				var value = msg.a;
				var updatedFields = A2($author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$updateLicenseNumber, model.hs, value);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{hs: updatedFields}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Tasklist$TaskQueue$TaskEditor$OutNoOp);
			case 5:
				var value = msg.a;
				var updatedFields = A2($author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$updateVehicleModel, model.hs, value);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{hs: updatedFields}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Tasklist$TaskQueue$TaskEditor$OutNoOp);
			case 6:
				var productId = msg.a;
				var defaultPrice = msg.b;
				var newProductPriceStrings = A3(
					$elm$core$Dict$insert,
					productId,
					$author$project$Pages$Tasklist$TaskQueue$TaskEditor$price_to_string(defaultPrice),
					model.aQ);
				var newModel = A3(
					$author$project$Pages$Tasklist$TaskQueue$TaskEditor$selectProduct,
					_Utils_update(
						model,
						{aQ: newProductPriceStrings}),
					productId,
					defaultPrice);
				var focusCmd = function () {
					if (!defaultPrice.$) {
						return $elm$core$Platform$Cmd$none;
					} else {
						return A2(
							$author$project$Helpers$focusOn,
							'price-input-' + $elm$core$String$fromInt(productId),
							$author$project$Pages$Tasklist$TaskQueue$TaskEditor$NoOp);
					}
				}();
				return _Utils_Tuple3(newModel, focusCmd, $author$project$Pages$Tasklist$TaskQueue$TaskEditor$OutNoOp);
			case 7:
				var productId = msg.a;
				var newProductPriceStrings = A2($elm$core$Dict$remove, productId, model.aQ);
				var newModel = _Utils_update(
					model,
					{aQ: newProductPriceStrings});
				return _Utils_Tuple3(
					A2($author$project$Pages$Tasklist$TaskQueue$TaskEditor$unselectProduct, newModel, productId),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Tasklist$TaskQueue$TaskEditor$OutNoOp);
			case 14:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{dj: 1}),
					A2(
						$elm$core$Task$attempt,
						function (_v4) {
							return $author$project$Pages$Tasklist$TaskQueue$TaskEditor$NoOp;
						},
						$author$project$Components$CloseButton$focusOnMe),
					$author$project$Pages$Tasklist$TaskQueue$TaskEditor$OutNoOp);
			case 15:
				var selectedMake = msg.a;
				var updatedFields = A2($author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$updateVehicleMake, model.hs, selectedMake);
				var _v5 = A2($author$project$Pages$Tasklist$TaskQueue$TaskEditor$initVehicleMakeDropdown, model.am, updatedFields.fd);
				var updatedMakeDropdownModel = _v5.a;
				var makeDropdownCmd = _v5.b;
				var _v6 = A2($author$project$Pages$Tasklist$TaskQueue$TaskEditor$hideSubDialog, model, $author$project$Components$Vehicles$MakeSelector$openVehicleMakeDialogButtonId);
				var modelAfterDialogClose = _v6.a;
				var dialogCmd = _v6.b;
				return _Utils_Tuple3(
					_Utils_update(
						modelAfterDialogClose,
						{hs: updatedFields, ax: updatedMakeDropdownModel}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								dialogCmd,
								A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$TaskQueue$TaskEditor$GotMakeDropdownMsg, makeDropdownCmd)
							])),
					$author$project$Pages$Tasklist$TaskQueue$TaskEditor$OutNoOp);
			case 16:
				var _v7 = $author$project$Pages$Tasklist$TaskQueue$TaskEditor$initDatePicker(model.hs.e2);
				var datePickerModel = _v7.a;
				var datePickerCmd = _v7.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{bm: datePickerModel, dj: 2}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$elm$core$Task$attempt,
								function (_v8) {
									return $author$project$Pages$Tasklist$TaskQueue$TaskEditor$NoOp;
								},
								$author$project$Components$CloseButton$focusOnMe),
								A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$TaskQueue$TaskEditor$GotDatePickerMsg, datePickerCmd)
							])),
					$author$project$Pages$Tasklist$TaskQueue$TaskEditor$OutNoOp);
			case 11:
				var focusAfterCloseId = msg.a;
				var _v9 = A2($author$project$Pages$Tasklist$TaskQueue$TaskEditor$hideSubDialog, model, focusAfterCloseId);
				var updatedModel = _v9.a;
				var cmd = _v9.b;
				return _Utils_Tuple3(updatedModel, cmd, $author$project$Pages$Tasklist$TaskQueue$TaskEditor$OutNoOp);
			case 8:
				var value = msg.a;
				var updatedFields = A2($author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$updateInstructions, model.hs, value);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{hs: updatedFields}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Tasklist$TaskQueue$TaskEditor$OutNoOp);
			case 9:
				var value = msg.a;
				var updatedFields = A2($author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$updateComment, model.hs, value);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{hs: updatedFields}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Tasklist$TaskQueue$TaskEditor$OutNoOp);
			case 10:
				var selectedProduct = msg.a;
				var priceString = msg.b;
				var newProductPriceInputs = A3($elm$core$Dict$insert, selectedProduct.iz, priceString, model.aQ);
				var newModel = _Utils_update(
					model,
					{aQ: newProductPriceInputs});
				var maybePrice = function () {
					var _v10 = model.nC;
					if (_v10 === 1) {
						return $author$project$Helpers$string_to_float(priceString);
					} else {
						return A2(
							$elm$core$Maybe$map,
							function (price) {
								return $author$project$Helpers$roundCurrency(price / 1.255);
							},
							$author$project$Helpers$string_to_float(priceString));
					}
				}();
				return _Utils_Tuple3(
					A3($author$project$Pages$Tasklist$TaskQueue$TaskEditor$updateProductPrice, newModel, selectedProduct, maybePrice),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Tasklist$TaskQueue$TaskEditor$OutNoOp);
			case 0:
				var today = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							dE: $elm$core$Maybe$Just(today)
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Tasklist$TaskQueue$TaskEditor$OutNoOp);
			case 12:
				var worker = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							hs: A2($author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$unselectWorker, model.hs, worker.er)
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Tasklist$TaskQueue$TaskEditor$OutNoOp);
			case 13:
				var worker = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							hs: A2($author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$selectWorker, model.hs, worker.er)
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Tasklist$TaskQueue$TaskEditor$OutNoOp);
			case 20:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$Tasklist$TaskQueue$TaskEditor$OutNoOp);
			case 21:
				var datePickerMsg = msg.a;
				var _v11 = A2($author$project$Components$DatePicker$update, datePickerMsg, model.bm);
				var newDatePickerModel = _v11.a;
				var datePickerCmd = _v11.b;
				var outMsg = _v11.c;
				switch (outMsg.$) {
					case 0:
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{bm: newDatePickerModel}),
							A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$TaskQueue$TaskEditor$GotDatePickerMsg, datePickerCmd),
							$author$project$Pages$Tasklist$TaskQueue$TaskEditor$OutNoOp);
					case 1:
						var date = outMsg.a;
						var updatedFields = A2($author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$updateTargetDate, model.hs, date);
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{bm: newDatePickerModel, hs: updatedFields, dj: 0}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$TaskQueue$TaskEditor$GotDatePickerMsg, datePickerCmd),
										A2(
										$elm$core$Task$attempt,
										function (_v13) {
											return $author$project$Pages$Tasklist$TaskQueue$TaskEditor$NoOp;
										},
										$elm$browser$Browser$Dom$focus('open-work-date-dialog-button'))
									])),
							$author$project$Pages$Tasklist$TaskQueue$TaskEditor$OutNoOp);
					default:
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{dj: 0}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$TaskQueue$TaskEditor$GotDatePickerMsg, datePickerCmd),
										A2(
										$elm$core$Task$attempt,
										function (_v14) {
											return $author$project$Pages$Tasklist$TaskQueue$TaskEditor$NoOp;
										},
										$elm$browser$Browser$Dom$focus('open-work-date-dialog-button'))
									])),
							$author$project$Pages$Tasklist$TaskQueue$TaskEditor$OutNoOp);
				}
			case 22:
				var dropdownMsg = msg.a;
				var _v15 = A2($author$project$Components$Dropdown$update, dropdownMsg, model.ax);
				var updatedDropdownModel = _v15.a;
				var dropdownCmd = _v15.b;
				var outMsg = _v15.c;
				var updatedFields = function () {
					if (outMsg.$ === 1) {
						var maybeOption = outMsg.a;
						return A2(
							$author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$updateVehicleMake,
							model.hs,
							A2($elm$core$Maybe$withDefault, '', maybeOption));
					} else {
						return model.hs;
					}
				}();
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{hs: updatedFields, ax: updatedDropdownModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$TaskQueue$TaskEditor$GotMakeDropdownMsg, dropdownCmd),
					$author$project$Pages$Tasklist$TaskQueue$TaskEditor$OutNoOp);
			default:
				var subMsg = msg.a;
				return A2($author$project$Pages$Tasklist$TaskQueue$TaskEditor$updateSubModule_WorkerSelector, model, subMsg);
		}
	});
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$updateSubModule_WorkerSelector = F2(
	function (model, subMsg) {
		var _v0 = A7(
			$author$project$Utils$Modules$handleSubModuleUpdate,
			$author$project$Pages$Tasklist$TaskQueue$TaskEditor$update,
			model,
			F2(
				function (subModel, parentModel) {
					return _Utils_update(
						parentModel,
						{cx: subModel});
				}),
			$author$project$Pages$Tasklist$TaskQueue$TaskEditor$GotSubModuleMsg_WorkerSelector,
			$author$project$Components$WorkerSelector$update,
			model.cx,
			subMsg);
		var model_ = _v0.a;
		var cmd = _v0.b;
		return _Utils_Tuple3(model_, cmd, $author$project$Pages$Tasklist$TaskQueue$TaskEditor$OutNoOp);
	});
var $author$project$Pages$Tasklist$TaskQueue$Requests$updateQueuedTaskCmd = F4(
	function (_v0, queuedTask, fields, onResponse) {
		var apiUrl = _v0.j0;
		var token = _v0.oK;
		var url = _Utils_ap(
			A2($author$project$Pages$Tasklist$TaskQueue$Requests$queuedTaskSiteUrl, apiUrl, queuedTask.cs),
			$elm$core$String$fromInt(
				$author$project$Pages$Tasklist$TaskQueue$Types$QueuedTask$unwrapQueuedTaskId(queuedTask.er)));
		var body = $elm$http$Http$jsonBody(
			$author$project$Pages$Tasklist$TaskQueue$Types$QueuedTask$queuedTaskFieldsEncoder(fields));
		return A4(
			$author$project$JwtHttp$put,
			token,
			url,
			body,
			A2($elm$http$Http$expectJson, onResponse, $author$project$Pages$Tasklist$TaskQueue$Types$TaskQueue$taskQueueDecoder));
	});
var $author$project$Pages$Tasklist$TaskQueue$handleTaskEditorMsg = F3(
	function (appContext, editorMsg, loadedModel) {
		var _v0 = function () {
			var _v1 = loadedModel.dj;
			if (!_v1.$) {
				return _Utils_Tuple3(loadedModel, $elm$core$Platform$Cmd$none, $author$project$Pages$Tasklist$TaskQueue$OutNoOp);
			} else {
				var queuedTask = _v1.a;
				var editorModel = _v1.b;
				var _v2 = A2($author$project$Pages$Tasklist$TaskQueue$TaskEditor$update, editorMsg, editorModel);
				var updatedEditorModel = _v2.a;
				var editorCmd = _v2.b;
				var editorOutMsg = _v2.c;
				var cmd = A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$TaskQueue$GotEditorMsg, editorCmd);
				var newModel = _Utils_update(
					loadedModel,
					{
						dj: A2($author$project$Pages$Tasklist$TaskQueue$EditorDialog, queuedTask, updatedEditorModel)
					});
				switch (editorOutMsg.$) {
					case 3:
						return _Utils_Tuple3(
							_Utils_update(
								newModel,
								{dj: $author$project$Pages$Tasklist$TaskQueue$NoDialog}),
							cmd,
							$author$project$Pages$Tasklist$TaskQueue$OutNoOp);
					case 1:
						var taskFields = editorOutMsg.a;
						return _Utils_Tuple3(
							_Utils_update(
								newModel,
								{t: true}),
							A4($author$project$Pages$Tasklist$TaskQueue$Requests$updateQueuedTaskCmd, appContext.n1, queuedTask, taskFields, $author$project$Pages$Tasklist$TaskQueue$GotEditQueuedTaskResponse),
							$author$project$Pages$Tasklist$TaskQueue$OutNoOp);
					case 2:
						return _Utils_Tuple3(
							_Utils_update(
								newModel,
								{t: true}),
							A3($author$project$Pages$Tasklist$TaskQueue$Requests$deleteQueuedTaskCmd, appContext.n1, queuedTask, $author$project$Pages$Tasklist$TaskQueue$GotEditQueuedTaskResponse),
							$author$project$Pages$Tasklist$TaskQueue$OutNotification(
								A2($author$project$Components$Notification$Notification, 'Työ poistettu jonosta', 1)));
					default:
						return _Utils_Tuple3(newModel, cmd, $author$project$Pages$Tasklist$TaskQueue$OutNoOp);
				}
			}
		}();
		var loadedModel2 = _v0.a;
		var commands = _v0.b;
		var outMsg = _v0.c;
		return _Utils_Tuple3(
			{
				_: $author$project$Pages$Tasklist$TaskQueue$Loaded(loadedModel2)
			},
			A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$TaskQueue$Msg_Loaded, commands),
			outMsg);
	});
var $author$project$Pages$Tasklist$TaskQueue$Requests$increaseQueuedTaskPriorityCmd = F3(
	function (_v0, queuedTask, onResponse) {
		var apiUrl = _v0.j0;
		var token = _v0.oK;
		var url = A2($author$project$Pages$Tasklist$TaskQueue$Requests$queuedTaskSiteUrl, apiUrl, queuedTask.cs) + ($elm$core$String$fromInt(
			$author$project$Pages$Tasklist$TaskQueue$Types$QueuedTask$unwrapQueuedTaskId(queuedTask.er)) + '/increase-priority');
		return A4(
			$author$project$JwtHttp$put,
			token,
			url,
			$elm$http$Http$emptyBody,
			A2($elm$http$Http$expectJson, onResponse, $author$project$Pages$Tasklist$TaskQueue$Types$TaskQueue$taskQueueDecoder));
	});
var $author$project$Utils$Custom$Custom = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$init = function (availableProducts) {
	return {
		T: availableProducts,
		c1: $author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$NoDialog,
		bc: A2($author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$syncAvailableProducts, availableProducts, $elm$core$Dict$empty)
	};
};
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$licenseNumberInputId = 'new-queued-task--license-number';
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$init = F4(
	function (allWorkers, siteWorkers, availableProducts, vehicleMakes) {
		var initialModel = {
			j5: availableProducts,
			hs: $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$empty,
			dj: 0,
			dr: $author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$init(availableProducts),
			cx: $author$project$Components$WorkerSelector$init(
				{
					bU: allWorkers,
					iD: $elm$core$Set$fromList(
						A2(
							$elm$core$List$map,
							function ($) {
								return $.er;
							},
							siteWorkers)),
					ah: $elm$core$Set$empty,
					dx: {
						g2: $author$project$Utils$Custom$Custom(12)
					}
				}),
			cH: A2($author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$initMakeDropDown, vehicleMakes, $elm$core$Maybe$Nothing),
			am: vehicleMakes
		};
		return _Utils_Tuple2(
			initialModel,
			A2(
				$elm$core$Task$attempt,
				$elm$core$Basics$always($author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$NoOp),
				$elm$browser$Browser$Dom$focus($author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$licenseNumberInputId)));
	});
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$SetToday = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$init = F6(
	function (priceInputMode, availableProducts, siteWorkers, vehicleMakes, allWorkers, queuedTaskFields) {
		var _v0 = A2($author$project$Pages$Tasklist$TaskQueue$TaskEditor$initVehicleMakeDropdown, vehicleMakes, queuedTaskFields.fd);
		var makeDropdownModel = _v0.a;
		var makeDropdownCmd = _v0.b;
		var _v1 = $author$project$Pages$Tasklist$TaskQueue$TaskEditor$initDatePicker($elm$core$Maybe$Nothing);
		var datePickerModel = _v1.a;
		var datePickerCmd = _v1.b;
		var initialModel = {
			bU: allWorkers,
			j5: availableProducts,
			bm: datePickerModel,
			hs: queuedTaskFields,
			ax: makeDropdownModel,
			dj: 0,
			nC: priceInputMode,
			aQ: $elm$core$Dict$fromList(
				A2(
					$elm$core$List$map,
					function (_v3) {
						var productId = _v3.iz;
						var price = _v3.iv;
						var priceAccordingToVatMode = function () {
							var _v4 = _Utils_Tuple2(priceInputMode, price);
							if (_v4.a === 1) {
								var _v5 = _v4.a;
								var maybeVatlessPrice = _v4.b;
								return maybeVatlessPrice;
							} else {
								if (!_v4.b.$) {
									var _v6 = _v4.a;
									var vatlessPrice = _v4.b.a;
									return $elm$core$Maybe$Just(
										$author$project$Helpers$roundCurrency(vatlessPrice * 1.255));
								} else {
									var _v7 = _v4.a;
									var _v8 = _v4.b;
									return $elm$core$Maybe$Nothing;
								}
							}
						}();
						return _Utils_Tuple2(
							productId,
							$author$project$Pages$Tasklist$TaskQueue$TaskEditor$price_to_string(priceAccordingToVatMode));
					},
					queuedTaskFields.iA)),
			as: siteWorkers,
			cx: $author$project$Components$WorkerSelector$init(
				{
					bU: allWorkers,
					iD: $elm$core$Set$fromList(
						A2(
							$elm$core$List$map,
							function ($) {
								return $.er;
							},
							siteWorkers)),
					ah: $elm$core$Set$fromList(queuedTaskFields.jz),
					dx: {g2: $author$project$Utils$Custom$UseDefault}
				}),
			dE: $elm$core$Maybe$Nothing,
			am: vehicleMakes
		};
		return _Utils_Tuple2(
			initialModel,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Tasklist$TaskQueue$TaskEditor$SetToday, $justinmimbs$date$Date$today),
						A2(
						$elm$core$Task$attempt,
						function (_v2) {
							return $author$project$Pages$Tasklist$TaskQueue$TaskEditor$NoOp;
						},
						$elm$browser$Browser$Dom$focus('instructions-field')),
						A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$TaskQueue$TaskEditor$GotDatePickerMsg, datePickerCmd),
						A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$TaskQueue$TaskEditor$GotMakeDropdownMsg, makeDropdownCmd)
					])));
	});
var $author$project$Pages$Tasklist$TaskQueue$Types$TaskQueue$length = function (_v0) {
	var tq = _v0;
	return $elm$core$List$length(tq.aj);
};
var $author$project$Pages$Tasklist$TaskQueue$buildDraggableDict = function (tasks) {
	return $elm$core$Dict$fromList(
		A2(
			$elm$core$List$map,
			function (queuedTask) {
				var id = 'queued-task-' + $elm$core$String$fromInt(
					$author$project$Pages$Tasklist$TaskQueue$Types$QueuedTask$unwrapQueuedTaskId(queuedTask.er));
				return _Utils_Tuple2(id, queuedTask);
			},
			tasks));
};
var $author$project$Components$Drag$setDraggables = F2(
	function (draggablesByDomId, model) {
		return _Utils_update(
			model,
			{d8: draggablesByDomId});
	});
var $author$project$Pages$Tasklist$TaskQueue$refreshDragModel = F2(
	function (tasks, dragModel) {
		var draggableDict = $author$project$Pages$Tasklist$TaskQueue$buildDraggableDict(tasks);
		return A2($author$project$Components$Drag$setDraggables, draggableDict, dragModel);
	});
var $author$project$Pages$Tasklist$TaskQueue$updateModelData = F2(
	function (updatedData, model) {
		var queuedTasks = $author$project$Pages$Tasklist$TaskQueue$Types$TaskQueue$getTasks(updatedData.z);
		var dragModelWithUpdatedTasks = A2($author$project$Pages$Tasklist$TaskQueue$refreshDragModel, queuedTasks, model.a$);
		return _Utils_update(
			model,
			{k_: updatedData, a$: dragModelWithUpdatedTasks});
	});
var $author$project$Pages$Tasklist$TaskQueue$QueueScrollButtonsDisabled = {$: 0};
var $author$project$Pages$Tasklist$TaskQueue$updateQueueScrollButtons = F2(
	function (_v0, currentQueueScrollButtons) {
		var taskCardListHeight = _v0.gs;
		var taskCardListContainerHeight = _v0.gr;
		var taskCardsFitInContainer = _Utils_cmp(taskCardListContainerHeight, taskCardListHeight) > -1;
		var _v1 = _Utils_Tuple2(taskCardsFitInContainer, currentQueueScrollButtons);
		if (_v1.a) {
			return $author$project$Pages$Tasklist$TaskQueue$QueueScrollButtonsDisabled;
		} else {
			if (!_v1.b.$) {
				var _v2 = _v1.b;
				return $author$project$Pages$Tasklist$TaskQueue$QueueScrollButtonsEnabled(0);
			} else {
				var scrollPosition = _v1.b.a;
				return $author$project$Pages$Tasklist$TaskQueue$QueueScrollButtonsEnabled(scrollPosition);
			}
		}
	});
var $author$project$Pages$Tasklist$TaskQueue$update_Loaded = F3(
	function (appContext, msg, model) {
		var serverContext = appContext.n1;
		switch (msg.$) {
			case 9:
				return (!(model.da || model.t)) ? _Utils_Tuple3(
					{
						_: $author$project$Pages$Tasklist$TaskQueue$Loaded(
							_Utils_update(
								model,
								{da: true}))
					},
					A2(
						$elm$core$Platform$Cmd$map,
						$author$project$Pages$Tasklist$TaskQueue$Msg_Loaded,
						A2(
							$elm$core$Task$attempt,
							$author$project$Pages$Tasklist$TaskQueue$GotDataRefresh,
							A2($author$project$Pages$Tasklist$TaskQueue$fetchData, serverContext, model.gA))),
					$author$project$Pages$Tasklist$TaskQueue$OutNoOp) : _Utils_Tuple3(
					{
						_: $author$project$Pages$Tasklist$TaskQueue$Loaded(model)
					},
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Tasklist$TaskQueue$OutNoOp);
			case 10:
				if (!msg.a.$) {
					var data = msg.a.a;
					var modelWithRefreshedData = A2(
						$author$project$Pages$Tasklist$TaskQueue$updateModelData,
						data,
						_Utils_update(
							model,
							{da: false}));
					return _Utils_Tuple3(
						{
							_: $author$project$Pages$Tasklist$TaskQueue$Loaded(modelWithRefreshedData)
						},
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2(
									$elm$core$Platform$Cmd$map,
									$author$project$Pages$Tasklist$TaskQueue$Msg_Loaded,
									A2($author$project$Helpers$doAfter, serverContext.fx, $author$project$Pages$Tasklist$TaskQueue$RequestDataRefresh)),
									$author$project$Pages$Tasklist$TaskQueue$requestLayoutResize
								])),
						$author$project$Pages$Tasklist$TaskQueue$OutNoOp);
				} else {
					if ((msg.a.a.$ === 3) && (msg.a.a.a === 401)) {
						return _Utils_Tuple3(
							{
								_: $author$project$Pages$Tasklist$TaskQueue$Loaded(model)
							},
							$elm$core$Platform$Cmd$none,
							$author$project$Pages$Tasklist$TaskQueue$OutGotAuthError);
					} else {
						return _Utils_Tuple3(
							{
								_: $author$project$Pages$Tasklist$TaskQueue$Loaded(model)
							},
							A2(
								$elm$core$Platform$Cmd$map,
								$author$project$Pages$Tasklist$TaskQueue$Msg_Loaded,
								A2($author$project$Helpers$doAfter, 2 * serverContext.fx, $author$project$Pages$Tasklist$TaskQueue$RequestDataRefresh)),
							$author$project$Pages$Tasklist$TaskQueue$OutNoOp);
					}
				}
			case 0:
				var _v1 = A4($author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$init, model.k_.bU, model.k_.as, model.k_.ct, model.k_.am);
				var taskCreatorModel = _v1.a;
				var taskCreatorCmd = _v1.b;
				return _Utils_Tuple3(
					{
						_: $author$project$Pages$Tasklist$TaskQueue$Loaded(
							_Utils_update(
								model,
								{
									ai: $author$project$Pages$Tasklist$TaskQueue$TaskCreatorExpanded(taskCreatorModel)
								}))
					},
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$elm$core$Platform$Cmd$map,
								$author$project$Pages$Tasklist$TaskQueue$Msg_Loaded,
								A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$TaskQueue$GotTaskCreatorMsg, taskCreatorCmd)),
								$author$project$Pages$Tasklist$TaskQueue$requestLayoutResize
							])),
					$author$project$Pages$Tasklist$TaskQueue$OutNoOp);
			case 1:
				return _Utils_Tuple3(
					{
						_: $author$project$Pages$Tasklist$TaskQueue$Loaded(
							_Utils_update(
								model,
								{ai: $author$project$Pages$Tasklist$TaskQueue$TaskCreatorCollapsed}))
					},
					$author$project$Pages$Tasklist$TaskQueue$requestLayoutResize,
					$author$project$Pages$Tasklist$TaskQueue$OutNoOp);
			case 2:
				var queuedTask = msg.a;
				var _v2 = A6(
					$author$project$Pages$Tasklist$TaskQueue$TaskEditor$init,
					$author$project$Types$User$priceInputMode(model.gA),
					model.k_.ct,
					model.k_.as,
					model.k_.am,
					model.k_.bU,
					queuedTask.hs);
				var editorModel = _v2.a;
				var editorCmd = _v2.b;
				return _Utils_Tuple3(
					{
						_: $author$project$Pages$Tasklist$TaskQueue$Loaded(
							_Utils_update(
								model,
								{
									dj: A2($author$project$Pages$Tasklist$TaskQueue$EditorDialog, queuedTask, editorModel)
								}))
					},
					A2(
						$elm$core$Platform$Cmd$map,
						$author$project$Pages$Tasklist$TaskQueue$Msg_Loaded,
						A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$TaskQueue$GotEditorMsg, editorCmd)),
					$author$project$Pages$Tasklist$TaskQueue$OutNoOp);
			case 3:
				var queuedTask = msg.a;
				return _Utils_Tuple3(
					{
						_: $author$project$Pages$Tasklist$TaskQueue$Loaded(
							_Utils_update(
								model,
								{t: true}))
					},
					A2(
						$elm$core$Platform$Cmd$map,
						$author$project$Pages$Tasklist$TaskQueue$Msg_Loaded,
						A3($author$project$Pages$Tasklist$TaskQueue$Requests$increaseQueuedTaskPriorityCmd, serverContext, queuedTask, $author$project$Pages$Tasklist$TaskQueue$GotEditQueuedTaskResponse)),
					$author$project$Pages$Tasklist$TaskQueue$OutNoOp);
			case 4:
				var queuedTask = msg.a;
				return _Utils_Tuple3(
					{
						_: $author$project$Pages$Tasklist$TaskQueue$Loaded(
							_Utils_update(
								model,
								{t: true}))
					},
					A2(
						$elm$core$Platform$Cmd$map,
						$author$project$Pages$Tasklist$TaskQueue$Msg_Loaded,
						A3($author$project$Pages$Tasklist$TaskQueue$Requests$decreaseQueuedTaskPriorityCmd, serverContext, queuedTask, $author$project$Pages$Tasklist$TaskQueue$GotEditQueuedTaskResponse)),
					$author$project$Pages$Tasklist$TaskQueue$OutNoOp);
			case 5:
				return _Utils_Tuple3(
					{
						_: $author$project$Pages$Tasklist$TaskQueue$Loaded(
							_Utils_update(
								model,
								{dj: $author$project$Pages$Tasklist$TaskQueue$NoDialog}))
					},
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Tasklist$TaskQueue$OutNoOp);
			case 7:
				if (!msg.a.$) {
					var taskQueue = msg.a.a;
					var updatedData = function (a) {
						return _Utils_update(
							a,
							{z: taskQueue});
					}(model.k_);
					var _v3 = A4($author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$init, model.k_.bU, model.k_.as, model.k_.ct, model.k_.am);
					var taskCreatorModel = _v3.a;
					var taskCreatorCmd = _v3.b;
					return _Utils_Tuple3(
						{
							_: $author$project$Pages$Tasklist$TaskQueue$Loaded(
								A2(
									$author$project$Pages$Tasklist$TaskQueue$updateModelData,
									updatedData,
									_Utils_update(
										model,
										{
											t: false,
											ai: $author$project$Pages$Tasklist$TaskQueue$TaskCreatorExpanded(taskCreatorModel)
										})))
						},
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2(
									$elm$core$Platform$Cmd$map,
									$author$project$Pages$Tasklist$TaskQueue$Msg_Loaded,
									A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$TaskQueue$GotTaskCreatorMsg, taskCreatorCmd)),
									$author$project$Pages$Tasklist$TaskQueue$requestLayoutResize
								])),
						$author$project$Pages$Tasklist$TaskQueue$OutNotification(
							A2($author$project$Components$Notification$Notification, 'Työ lisätty jonoon', 1)));
				} else {
					var errMsg = msg.a.a;
					return $author$project$Pages$Tasklist$TaskQueue$handleErrorResponse(errMsg);
				}
			case 6:
				if (!msg.a.$) {
					var taskQueue = msg.a.a;
					var updatedData = function (a) {
						return _Utils_update(
							a,
							{z: taskQueue});
					}(model.k_);
					return _Utils_Tuple3(
						{
							_: $author$project$Pages$Tasklist$TaskQueue$Loaded(
								A2(
									$author$project$Pages$Tasklist$TaskQueue$updateModelData,
									updatedData,
									_Utils_update(
										model,
										{t: false, dj: $author$project$Pages$Tasklist$TaskQueue$NoDialog})))
						},
						$author$project$Pages$Tasklist$TaskQueue$requestLayoutResize,
						$author$project$Pages$Tasklist$TaskQueue$OutNoOp);
				} else {
					var errMsg = msg.a.a;
					return $author$project$Pages$Tasklist$TaskQueue$handleErrorResponse(errMsg);
				}
			case 8:
				if (!msg.a.$) {
					var taskQueue = msg.a.a;
					var updatedData = function (a) {
						return _Utils_update(
							a,
							{z: taskQueue});
					}(model.k_);
					return _Utils_Tuple3(
						{
							_: $author$project$Pages$Tasklist$TaskQueue$Loaded(
								A2(
									$author$project$Pages$Tasklist$TaskQueue$updateModelData,
									updatedData,
									_Utils_update(
										model,
										{t: false})))
						},
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Tasklist$TaskQueue$OutDraggableDropped);
				} else {
					var errMsg = msg.a.a;
					return $author$project$Pages$Tasklist$TaskQueue$handleErrorResponse(errMsg);
				}
			case 13:
				var scrollDirection = msg.a;
				var _v4 = model.be;
				if (!_v4.$) {
					return _Utils_Tuple3(
						{
							_: $author$project$Pages$Tasklist$TaskQueue$Loaded(model)
						},
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Tasklist$TaskQueue$OutNoOp);
				} else {
					var scrollPosition = _v4.a;
					var maxScrollPosition = A2(
						$elm$core$Basics$max,
						0,
						$author$project$Pages$Tasklist$TaskQueue$Types$TaskQueue$length(model.k_.z) - 1);
					var updatedScrollPosition = A3(
						$author$project$Helpers$constrainBetween,
						0,
						maxScrollPosition,
						function () {
							if (!scrollDirection) {
								return -1;
							} else {
								return 1;
							}
						}() + scrollPosition);
					return _Utils_Tuple3(
						{
							_: $author$project$Pages$Tasklist$TaskQueue$Loaded(
								_Utils_update(
									model,
									{
										be: $author$project$Pages$Tasklist$TaskQueue$QueueScrollButtonsEnabled(updatedScrollPosition)
									}))
						},
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Tasklist$TaskQueue$OutNoOp);
				}
			case 11:
				return _Utils_Tuple3(
					{
						_: $author$project$Pages$Tasklist$TaskQueue$Loaded(model)
					},
					$author$project$Pages$Tasklist$TaskQueue$requestLayoutResize,
					$author$project$Pages$Tasklist$TaskQueue$OutNoOp);
			case 12:
				if (!msg.a.$) {
					var heights = msg.a.a;
					var updatedQueueScrollButtons = A2($author$project$Pages$Tasklist$TaskQueue$updateQueueScrollButtons, heights, model.be);
					return _Utils_Tuple3(
						{
							_: $author$project$Pages$Tasklist$TaskQueue$Loaded(
								_Utils_update(
									model,
									{be: updatedQueueScrollButtons}))
						},
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Tasklist$TaskQueue$OutNoOp);
				} else {
					return _Utils_Tuple3(
						{
							_: $author$project$Pages$Tasklist$TaskQueue$Loaded(model)
						},
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Tasklist$TaskQueue$OutNoOp);
				}
			case 14:
				return _Utils_Tuple3(
					{
						_: $author$project$Pages$Tasklist$TaskQueue$Loaded(model)
					},
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Tasklist$TaskQueue$OutNoOp);
			case 19:
				var taskCreatorMsg = msg.a;
				return A3($author$project$Pages$Tasklist$TaskQueue$handleTaskCreatorMsg, appContext, taskCreatorMsg, model);
			case 20:
				var editorMsg = msg.a;
				return A3($author$project$Pages$Tasklist$TaskQueue$handleTaskEditorMsg, appContext, editorMsg, model);
			case 21:
				var dragMsg = msg.a;
				return A3($author$project$Pages$Tasklist$TaskQueue$handleDragMsg, dragMsg, appContext, model);
			case 15:
				var hoveredTask = msg.a;
				var maybeDraggedQueuedTask = function () {
					var _v8 = model.O;
					switch (_v8.$) {
						case 1:
							var dragInfo = _v8.a;
							return $elm$core$Maybe$Just(dragInfo.fE);
						case 2:
							var dragInfo = _v8.a;
							return $elm$core$Maybe$Just(dragInfo.fE);
						default:
							return $elm$core$Maybe$Nothing;
					}
				}();
				if (!maybeDraggedQueuedTask.$) {
					var draggedTask = maybeDraggedQueuedTask.a;
					var dragDirection = A3($author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskDragStatus$calculateDragDirectionInQueue, model.k_.z, draggedTask, hoveredTask);
					var updatedModel = function () {
						var _v7 = model.O;
						switch (_v7.$) {
							case 1:
								var dragInfo = _v7.a;
								return _Utils_update(
									model,
									{
										O: A3($author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskDragStatus$DraggingOverAnotherQueuedTask, dragInfo, hoveredTask, dragDirection)
									});
							case 2:
								var dragInfo = _v7.a;
								return _Utils_update(
									model,
									{
										O: A3($author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskDragStatus$DraggingOverAnotherQueuedTask, dragInfo, hoveredTask, dragDirection)
									});
							default:
								return model;
						}
					}();
					return _Utils_Tuple3(
						{
							_: $author$project$Pages$Tasklist$TaskQueue$Loaded(updatedModel)
						},
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Tasklist$TaskQueue$OutNoOp);
				} else {
					return _Utils_Tuple3(
						{
							_: $author$project$Pages$Tasklist$TaskQueue$Loaded(model)
						},
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Tasklist$TaskQueue$OutNoOp);
				}
			case 16:
				var exHoveredQueuedTask = msg.a;
				var updatedModel = function () {
					var _v9 = model.O;
					switch (_v9.$) {
						case 1:
							var dragInfo = _v9.a;
							return _Utils_update(
								model,
								{
									O: $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskDragStatus$DraggingOutsideTheQueue(dragInfo)
								});
						case 2:
							var dragInfo = _v9.a;
							var draggedQueuedTask = dragInfo.fE;
							return _Utils_eq(exHoveredQueuedTask.er, draggedQueuedTask.er) ? _Utils_update(
								model,
								{
									O: $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskDragStatus$DraggingOutsideTheQueue(dragInfo)
								}) : model;
						default:
							return model;
					}
				}();
				return _Utils_Tuple3(
					{
						_: $author$project$Pages$Tasklist$TaskQueue$Loaded(updatedModel)
					},
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Tasklist$TaskQueue$OutNoOp);
			case 17:
				var updatedModel = function () {
					var _v10 = model.O;
					switch (_v10.$) {
						case 1:
							var dragInfo = _v10.a;
							return _Utils_update(
								model,
								{
									O: $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskDragStatus$DraggingOutsideTheQueue(dragInfo)
								});
						case 2:
							var dragInfo = _v10.a;
							return _Utils_update(
								model,
								{
									O: $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskDragStatus$DraggingOutsideTheQueue(dragInfo)
								});
						default:
							return model;
					}
				}();
				return _Utils_Tuple3(
					{
						_: $author$project$Pages$Tasklist$TaskQueue$Loaded(updatedModel)
					},
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Tasklist$TaskQueue$OutNoOp);
			default:
				var lastQueuedTask = msg.a;
				var updatedModel = function () {
					var _v11 = model.O;
					switch (_v11.$) {
						case 1:
							var dragInfo = _v11.a;
							var dragDirection = A3($author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskDragStatus$calculateDragDirectionInQueue, model.k_.z, dragInfo.fE, lastQueuedTask);
							return _Utils_update(
								model,
								{
									O: A3($author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskDragStatus$DraggingOverAnotherQueuedTask, dragInfo, lastQueuedTask, dragDirection)
								});
						case 2:
							var dragInfo = _v11.a;
							var dragDirection = A3($author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskDragStatus$calculateDragDirectionInQueue, model.k_.z, dragInfo.fE, lastQueuedTask);
							return _Utils_update(
								model,
								{
									O: A3($author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskDragStatus$DraggingOverAnotherQueuedTask, dragInfo, lastQueuedTask, dragDirection)
								});
						default:
							return model;
					}
				}();
				return _Utils_Tuple3(
					{
						_: $author$project$Pages$Tasklist$TaskQueue$Loaded(updatedModel)
					},
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Tasklist$TaskQueue$OutNoOp);
		}
	});
var $author$project$Components$Drag$init = function (draggablesByDomId) {
	var initialModel = {d8: draggablesByDomId, cw: $author$project$Components$Drag$NotDragging};
	return initialModel;
};
var $author$project$Pages$Tasklist$TaskQueue$init_Loaded = F3(
	function (appContext, user, data) {
		var initialModel = {
			k_: data,
			a$: $author$project$Components$Drag$init(
				$author$project$Pages$Tasklist$TaskQueue$buildDraggableDict(
					$author$project$Pages$Tasklist$TaskQueue$Types$TaskQueue$getTasks(data.z))),
			O: $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskDragStatus$NotDragging,
			da: false,
			t: false,
			dj: $author$project$Pages$Tasklist$TaskQueue$NoDialog,
			be: $author$project$Pages$Tasklist$TaskQueue$QueueScrollButtonsDisabled,
			ai: $author$project$Pages$Tasklist$TaskQueue$TaskCreatorCollapsed,
			gA: user
		};
		return _Utils_Tuple2(
			{
				_: $author$project$Pages$Tasklist$TaskQueue$Loaded(initialModel)
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2(
						$elm$core$Platform$Cmd$map,
						$author$project$Pages$Tasklist$TaskQueue$Msg_Loaded,
						A2($author$project$Helpers$doAfter, appContext.n1.fx, $author$project$Pages$Tasklist$TaskQueue$RequestDataRefresh)),
						$author$project$Pages$Tasklist$TaskQueue$requestLayoutResize
					])));
	});
var $author$project$Pages$Tasklist$TaskQueue$update_Loading = F3(
	function (appContext, msg, model) {
		if (!msg.$) {
			var data = msg.a;
			var _v1 = A3($author$project$Pages$Tasklist$TaskQueue$init_Loaded, appContext, model.gA, data);
			var model2 = _v1.a;
			var cmd = _v1.b;
			return _Utils_Tuple3(model2, cmd, $author$project$Pages$Tasklist$TaskQueue$OutNoOp);
		} else {
			var errMsg = msg.a;
			return $author$project$Pages$Tasklist$TaskQueue$handleErrorResponse(errMsg);
		}
	});
var $author$project$Pages$Tasklist$TaskQueue$update = F3(
	function (appContext, msg, model) {
		var _v0 = _Utils_Tuple2(msg, model._);
		_v0$3:
		while (true) {
			switch (_v0.a.$) {
				case 0:
					if (!_v0.b.$) {
						var loadingMsg = _v0.a.a;
						var loadingModel = _v0.b.a;
						return A3($author$project$Pages$Tasklist$TaskQueue$update_Loading, appContext, loadingMsg, loadingModel);
					} else {
						break _v0$3;
					}
				case 1:
					if (_v0.b.$ === 1) {
						var loadedMsg = _v0.a.a;
						var loadedModel = _v0.b.a;
						return A3($author$project$Pages$Tasklist$TaskQueue$update_Loaded, appContext, loadedMsg, loadedModel);
					} else {
						break _v0$3;
					}
				default:
					if (_v0.b.$ === 2) {
						var erroredMsg = _v0.a.a;
						var erroredModel = _v0.b.a;
						return A3($author$project$Pages$Tasklist$TaskQueue$update_Errored, appContext, erroredMsg, erroredModel);
					} else {
						break _v0$3;
					}
			}
		}
		return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$Tasklist$TaskQueue$OutNoOp);
	});
var $author$project$Data$Tasklists$updateOneTimeCustomerContactInfo = F2(
	function (_v0, tasklist) {
		var apiUrl = _v0.j0;
		var token = _v0.oK;
		return $author$project$JwtHttp$task(
			{
				kc: $elm$http$Http$jsonBody(
					$elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'oneTimeCustomerContactInfo',
								$elm$json$Json$Encode$string(tasklist.ne))
							]))),
				mr: 'POST',
				nJ: $elm$http$Http$stringResolver(
					$author$project$JwtHttp$handleJsonResponse($author$project$Data$Tasklists$tasklistDecoder)),
				oH: $elm$core$Maybe$Nothing,
				oK: token,
				oU: apiUrl + ('/tasklists/' + ($elm$core$String$fromInt(tasklist.er) + '/one-time-customer/contact-info'))
			});
	});
var $author$project$Data$Tasklists$updateOneTimeCustomerName = F2(
	function (_v0, tasklist) {
		var apiUrl = _v0.j0;
		var token = _v0.oK;
		return $author$project$JwtHttp$task(
			{
				kc: $elm$http$Http$jsonBody(
					$elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'oneTimeCustomerName',
								$elm$json$Json$Encode$string(tasklist.nf))
							]))),
				mr: 'POST',
				nJ: $elm$http$Http$stringResolver(
					$author$project$JwtHttp$handleJsonResponse($author$project$Data$Tasklists$tasklistDecoder)),
				oH: $elm$core$Maybe$Nothing,
				oK: token,
				oU: apiUrl + ('/tasklists/' + ($elm$core$String$fromInt(tasklist.er) + '/one-time-customer/name'))
			});
	});
var $author$project$Pages$Tasklist$update_Loaded = F3(
	function (appContext, msg, model) {
		var serverContext = appContext.n1;
		switch (msg.$) {
			case 7:
				return (!(model.da || $author$project$Pages$Tasklist$areUpdateButtonsDisabled(model))) ? _Utils_Tuple3(
					{
						_: $author$project$Pages$Tasklist$Loaded(
							_Utils_update(
								model,
								{da: true}))
					},
					A2(
						$elm$core$Platform$Cmd$map,
						$author$project$Pages$Tasklist$Msg_Loaded,
						A2(
							$elm$core$Task$attempt,
							$author$project$Pages$Tasklist$GotDataRefresh,
							A2($author$project$Pages$Tasklist$fetchData, serverContext, model.Y))),
					$author$project$Pages$Tasklist$OutNoOp) : _Utils_Tuple3(
					{
						_: $author$project$Pages$Tasklist$Loaded(model)
					},
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Tasklist$OutNoOp);
			case 8:
				if (!msg.a.$) {
					var data = msg.a.a;
					var refreshTasklistButPreserveTypedInCustomerInfo = function (tasklistFromServer) {
						var _v1 = model.bz;
						switch (_v1.$) {
							case 1:
								var tasklistId = _v1.a;
								var typedInCustomerName = _v1.b;
								return _Utils_eq(tasklistId, tasklistFromServer.er) ? _Utils_update(
									tasklistFromServer,
									{nf: typedInCustomerName}) : tasklistFromServer;
							case 2:
								var tasklistId = _v1.a;
								var typedInContactInfo = _v1.b;
								return _Utils_eq(tasklistId, tasklistFromServer.er) ? _Utils_update(
									tasklistFromServer,
									{ne: typedInContactInfo}) : tasklistFromServer;
							default:
								return tasklistFromServer;
						}
					};
					var refreshedTasklists = A2($elm$core$List$map, refreshTasklistButPreserveTypedInCustomerInfo, data.e);
					var availableTasklistGroups = $author$project$Pages$Tasklist$buildTasklistGroups(data.e);
					var allTasklistGroupNames = $elm$core$Set$fromList(
						$elm$core$Dict$keys(availableTasklistGroups));
					var selectedTasklistGroups = A2(
						$elm$core$Set$filter,
						$author$project$Helpers$isInSet(allTasklistGroupNames),
						model.bf);
					var modelWithRefreshedData = _Utils_update(
						model,
						{T: data.T, bU: data.bU, dS: availableTasklistGroups, da: false, bf: selectedTasklistGroups, X: data.X, as: data.as, e: refreshedTasklists, am: data.am});
					return _Utils_Tuple3(
						{
							_: $author$project$Pages$Tasklist$Loaded(modelWithRefreshedData)
						},
						A2(
							$elm$core$Platform$Cmd$map,
							$author$project$Pages$Tasklist$Msg_Loaded,
							A2($author$project$Helpers$doAfter, serverContext.fx, $author$project$Pages$Tasklist$RequestDataRefresh)),
						$author$project$Pages$Tasklist$OutNoOp);
				} else {
					if ((msg.a.a.$ === 3) && (msg.a.a.a === 401)) {
						return $author$project$Pages$Tasklist$handleAuthError(model);
					} else {
						return _Utils_Tuple3(
							{
								_: $author$project$Pages$Tasklist$Loaded(model)
							},
							A2(
								$elm$core$Platform$Cmd$map,
								$author$project$Pages$Tasklist$Msg_Loaded,
								A2($author$project$Helpers$doAfter, 2 * serverContext.fx, $author$project$Pages$Tasklist$RequestDataRefresh)),
							$author$project$Pages$Tasklist$OutNoOp);
					}
				}
			case 24:
				var tasklist = msg.a;
				var _v2 = model.O;
				if (!_v2.$) {
					return _Utils_Tuple3(
						{
							_: $author$project$Pages$Tasklist$Loaded(model)
						},
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Tasklist$OutNoOp);
				} else {
					var taskQueue = _v2.a;
					var queuedTask = _v2.b;
					var draggedDomElement = _v2.c;
					return _Utils_Tuple3(
						{
							_: $author$project$Pages$Tasklist$Loaded(
								_Utils_update(
									model,
									{
										O: A4(
											$author$project$Pages$Tasklist$DraggingQueuedTask,
											taskQueue,
											queuedTask,
											draggedDomElement,
											$elm$core$Maybe$Just(tasklist))
									}))
						},
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Tasklist$OutNoOp);
				}
			case 25:
				var exHoveredTasklist = msg.a;
				var _v3 = model.O;
				if (!_v3.$) {
					return _Utils_Tuple3(
						{
							_: $author$project$Pages$Tasklist$Loaded(model)
						},
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Tasklist$OutNoOp);
				} else {
					var taskQueue = _v3.a;
					var queuedTask = _v3.b;
					var draggedDomElement = _v3.c;
					var maybeDraggedOverTasklist = _v3.d;
					var isQueuedTaskDraggedAwayFromTasklist = function () {
						if (!maybeDraggedOverTasklist.$) {
							var draggedOverTasklist = maybeDraggedOverTasklist.a;
							return _Utils_eq(draggedOverTasklist.er, exHoveredTasklist.er);
						} else {
							return false;
						}
					}();
					return isQueuedTaskDraggedAwayFromTasklist ? _Utils_Tuple3(
						{
							_: $author$project$Pages$Tasklist$Loaded(
								_Utils_update(
									model,
									{
										O: A4($author$project$Pages$Tasklist$DraggingQueuedTask, taskQueue, queuedTask, draggedDomElement, $elm$core$Maybe$Nothing)
									}))
						},
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Tasklist$OutNoOp) : _Utils_Tuple3(
						{
							_: $author$project$Pages$Tasklist$Loaded(model)
						},
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Tasklist$OutNoOp);
				}
			case 4:
				if (!msg.a.$) {
					var updatedTasklist = msg.a.a;
					return _Utils_Tuple3(
						{
							_: $author$project$Pages$Tasklist$Loaded(
								_Utils_update(
									model,
									{
										bz: $author$project$Pages$Tasklist$NotEditingOneTimeCustomer,
										e: A3(
											$author$project$Utils$MiscUtils$list_replaceBy,
											function ($) {
												return $.er;
											},
											updatedTasklist,
											model.e)
									}))
						},
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Tasklist$OutNoOp);
				} else {
					var errMsg = msg.a.a;
					_v5$2:
					while (true) {
						if (errMsg.$ === 3) {
							switch (errMsg.a) {
								case 401:
									return $author$project$Pages$Tasklist$handleAuthError(model);
								case 403:
									return A2($author$project$Pages$Tasklist$handleTasklistAlreadyClosedError, model, 'Asiakkaan nimeä ei voida muuttaa, koska lomake on jo suljettu');
								default:
									break _v5$2;
							}
						} else {
							break _v5$2;
						}
					}
					return $author$project$Pages$Tasklist$init_Errored($elm$core$Maybe$Nothing);
				}
			case 5:
				if (!msg.a.$) {
					var updatedTasklist = msg.a.a;
					return _Utils_Tuple3(
						{
							_: $author$project$Pages$Tasklist$Loaded(
								_Utils_update(
									model,
									{
										bz: $author$project$Pages$Tasklist$NotEditingOneTimeCustomer,
										e: A3(
											$author$project$Utils$MiscUtils$list_replaceBy,
											function ($) {
												return $.er;
											},
											updatedTasklist,
											model.e)
									}))
						},
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Tasklist$OutNoOp);
				} else {
					var errMsg = msg.a.a;
					_v6$2:
					while (true) {
						if (errMsg.$ === 3) {
							switch (errMsg.a) {
								case 401:
									return $author$project$Pages$Tasklist$handleAuthError(model);
								case 403:
									return A2($author$project$Pages$Tasklist$handleTasklistAlreadyClosedError, model, 'Asiakkaan yhteystietoa ei voida muuttaa, koska lomake on jo suljettu');
								default:
									break _v6$2;
							}
						} else {
							break _v6$2;
						}
					}
					return $author$project$Pages$Tasklist$init_Errored($elm$core$Maybe$Nothing);
				}
			case 6:
				if (!msg.b.$) {
					var result = msg.b.a;
					var updatedQueueTasks = $author$project$Pages$Tasklist$TaskQueue$getTasks(result.oT);
					var tasklistWithNewTask = result.on;
					var _v7 = A2($author$project$Pages$Tasklist$TaskQueue$handleTaskDraggedFromQueue, updatedQueueTasks, model.N);
					var updatedTaskQueueModel = _v7.a;
					var taskQueueCmd = _v7.b;
					return _Utils_Tuple3(
						{
							_: $author$project$Pages$Tasklist$Loaded(
								_Utils_update(
									model,
									{
										O: $author$project$Pages$Tasklist$NotDragging,
										t: false,
										N: updatedTaskQueueModel,
										e: A3(
											$author$project$Utils$MiscUtils$list_replaceBy,
											function ($) {
												return $.er;
											},
											tasklistWithNewTask,
											model.e)
									}))
						},
						A2(
							$elm$core$Platform$Cmd$map,
							$author$project$Pages$Tasklist$Msg_Loaded,
							A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$GotTaskQueueMsg, taskQueueCmd)),
						$author$project$Pages$Tasklist$OutNoOp);
				} else {
					var queuedTask = msg.a;
					var errMsg = msg.b.a;
					_v8$3:
					while (true) {
						if (errMsg.$ === 3) {
							switch (errMsg.a) {
								case 401:
									return $author$project$Pages$Tasklist$handleAuthError(model);
								case 403:
									return A2(
										$author$project$Pages$Tasklist$handleTasklistAlreadyClosedError,
										_Utils_update(
											model,
											{O: $author$project$Pages$Tasklist$NotDragging}),
										'Työtä ei voida siirtää lomakkeelle, koska se on jo suljettu');
								case 404:
									var fixedTaskQueueModel = A2($author$project$Pages$Tasklist$TaskQueue$removeTaskFromQueue, queuedTask, model.N);
									return _Utils_Tuple3(
										{
											_: $author$project$Pages$Tasklist$Loaded(
												_Utils_update(
													model,
													{O: $author$project$Pages$Tasklist$NotDragging, t: false, N: fixedTaskQueueModel}))
										},
										A2(
											$elm$core$Platform$Cmd$map,
											$author$project$Pages$Tasklist$Msg_Loaded,
											A2(
												$elm$core$Task$attempt,
												$author$project$Pages$Tasklist$GotDataRefresh,
												A2($author$project$Pages$Tasklist$fetchData, serverContext, model.Y))),
										$author$project$Pages$Tasklist$OutNotification(
											A2($author$project$Components$Notification$Notification, 'Työ oli jo poistunut jonosta', 1)));
								default:
									break _v8$3;
							}
						} else {
							break _v8$3;
						}
					}
					var updatedTaskQueueModel = $author$project$Pages$Tasklist$TaskQueue$setNotDragging(model.N);
					return _Utils_Tuple3(
						{
							_: $author$project$Pages$Tasklist$Loaded(
								_Utils_update(
									model,
									{O: $author$project$Pages$Tasklist$NotDragging, t: false, N: updatedTaskQueueModel}))
						},
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Tasklist$OutNotification(
							A2($author$project$Components$Notification$Notification, 'Virhe siirrettäessä työtä lomakkeelle', 1)));
				}
			case 28:
				var taskQueueMsg = msg.a;
				var _v9 = A3($author$project$Pages$Tasklist$TaskQueue$update, appContext, taskQueueMsg, model.N);
				var updatedTaskQueueModel = _v9.a;
				var taskQueueCmd = _v9.b;
				var taskQueueOutMsg = _v9.c;
				var cmd = A2(
					$elm$core$Platform$Cmd$map,
					$author$project$Pages$Tasklist$Msg_Loaded,
					A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$GotTaskQueueMsg, taskQueueCmd));
				var updatedModel = _Utils_update(
					model,
					{N: updatedTaskQueueModel});
				switch (taskQueueOutMsg.$) {
					case 0:
						var notification = taskQueueOutMsg.a;
						return _Utils_Tuple3(
							{
								_: $author$project$Pages$Tasklist$Loaded(updatedModel)
							},
							cmd,
							$author$project$Pages$Tasklist$OutNotification(notification));
					case 1:
						return $author$project$Pages$Tasklist$handleAuthError(updatedModel);
					case 2:
						var taskQueue = taskQueueOutMsg.a;
						var queuedTask = taskQueueOutMsg.b;
						var draggedDomElement = taskQueueOutMsg.c;
						return _Utils_Tuple3(
							{
								_: $author$project$Pages$Tasklist$Loaded(
									_Utils_update(
										updatedModel,
										{
											O: A4($author$project$Pages$Tasklist$DraggingQueuedTask, taskQueue, queuedTask, draggedDomElement, $elm$core$Maybe$Nothing)
										}))
							},
							$elm$core$Platform$Cmd$none,
							$author$project$Pages$Tasklist$OutNoOp);
					case 3:
						var _v11 = model.O;
						if (!_v11.$) {
							return A2($author$project$Pages$Tasklist$handleNotDraggedCase, updatedModel, cmd);
						} else {
							var taskQueue = _v11.a;
							var queuedTask = _v11.b;
							var hoveredTasklist = _v11.d;
							return A6($author$project$Pages$Tasklist$handleQueuedTaskDropped, serverContext, updatedModel, cmd, taskQueue, queuedTask, hoveredTasklist);
						}
					case 4:
						return _Utils_Tuple3(
							{
								_: $author$project$Pages$Tasklist$Errored(
									{
										eb: $elm$core$Maybe$Just('Virhe työjonossa.')
									})
							},
							cmd,
							$author$project$Pages$Tasklist$OutNoOp);
					default:
						return _Utils_Tuple3(
							{
								_: $author$project$Pages$Tasklist$Loaded(updatedModel)
							},
							cmd,
							$author$project$Pages$Tasklist$OutNoOp);
				}
			case 27:
				var taskEditorMsg = msg.a;
				var _v12 = model.M;
				switch (_v12.$) {
					case 0:
						return _Utils_Tuple3(
							{
								_: $author$project$Pages$Tasklist$Loaded(model)
							},
							$elm$core$Platform$Cmd$none,
							$author$project$Pages$Tasklist$OutNoOp);
					case 1:
						var editorModel = _v12.a;
						var tasklist = _v12.b;
						var task = _v12.c;
						var _v13 = A2($author$project$Pages$Tasklist$TaskEditor$update, taskEditorMsg, editorModel);
						var newTaskEditorModel = _v13.a;
						var taskEditorCmd = _v13.b;
						var outMsg = _v13.c;
						var updatedTaskEditorStatus = A3($author$project$Pages$Tasklist$EditingExistingTask, newTaskEditorModel, tasklist, task);
						var _v14 = A5($author$project$Pages$Tasklist$handleTaskEditorOutMsg, serverContext, model, updatedTaskEditorStatus, taskEditorCmd, outMsg);
						var model2 = _v14.a;
						var cmd = _v14.b;
						var taskEditorNotification = _v14.c;
						return _Utils_Tuple3(
							{
								_: $author$project$Pages$Tasklist$Loaded(model2)
							},
							A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$Msg_Loaded, cmd),
							A2(
								$elm$core$Maybe$withDefault,
								$author$project$Pages$Tasklist$OutNoOp,
								A2($elm$core$Maybe$map, $author$project$Pages$Tasklist$OutNotification, taskEditorNotification)));
					default:
						var editorModel = _v12.a;
						var tasklist = _v12.b;
						var _v15 = A2($author$project$Pages$Tasklist$TaskEditor$update, taskEditorMsg, editorModel);
						var newTaskEditorModel = _v15.a;
						var taskEditorCmd = _v15.b;
						var outMsg = _v15.c;
						var newTaskEditorStatus = A2($author$project$Pages$Tasklist$EditingNewTask, newTaskEditorModel, tasklist);
						var _v16 = A5($author$project$Pages$Tasklist$handleTaskEditorOutMsg, serverContext, model, newTaskEditorStatus, taskEditorCmd, outMsg);
						var model2 = _v16.a;
						var cmd = _v16.b;
						var taskEditorNotification = _v16.c;
						return _Utils_Tuple3(
							{
								_: $author$project$Pages$Tasklist$Loaded(model2)
							},
							A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$Msg_Loaded, cmd),
							A2(
								$elm$core$Maybe$withDefault,
								$author$project$Pages$Tasklist$OutNoOp,
								A2($elm$core$Maybe$map, $author$project$Pages$Tasklist$OutNotification, taskEditorNotification)));
				}
			case 0:
				if (!msg.a.$) {
					var newTasklist = msg.a.a;
					var _v17 = model.R;
					if (_v17.$ === 3) {
						var closedTasklist = _v17.a;
						var updatedTasklists = A2(
							$elm$core$List$map,
							function (existingTasklist) {
								return _Utils_eq(existingTasklist, closedTasklist) ? newTasklist : existingTasklist;
							},
							model.e);
						return _Utils_Tuple3(
							{
								_: $author$project$Pages$Tasklist$Loaded(
									_Utils_update(
										model,
										{t: false, R: $author$project$Pages$Tasklist$ClosingProcessIdle, e: updatedTasklists}))
							},
							$elm$core$Platform$Cmd$none,
							$author$project$Pages$Tasklist$OutNotification(
								A2(
									$author$project$Components$Notification$Notification,
									'Lomake #' + ($elm$core$String$fromInt(closedTasklist.er) + ' suljettu'),
									1)));
					} else {
						return $author$project$Pages$Tasklist$handleTasklistCloseError(model);
					}
				} else {
					var httpError = msg.a.a;
					_v18$2:
					while (true) {
						if (httpError.$ === 3) {
							switch (httpError.a) {
								case 401:
									return $author$project$Pages$Tasklist$handleAuthError(model);
								case 409:
									return _Utils_Tuple3(
										{
											_: $author$project$Pages$Tasklist$Loaded(
												_Utils_update(
													model,
													{t: false}))
										},
										$elm$core$Platform$Cmd$none,
										$author$project$Pages$Tasklist$OutNoOp);
								default:
									break _v18$2;
							}
						} else {
							break _v18$2;
						}
					}
					return $author$project$Pages$Tasklist$handleTasklistCloseError(model);
				}
			case 1:
				if (!msg.a.$) {
					var updatedTasklist = msg.a.a;
					return _Utils_Tuple3(
						{
							_: $author$project$Pages$Tasklist$Loaded(
								_Utils_update(
									model,
									{
										t: false,
										M: $author$project$Pages$Tasklist$NotEditing,
										e: A3(
											$author$project$Utils$MiscUtils$list_replaceBy,
											function ($) {
												return $.er;
											},
											updatedTasklist,
											model.e)
									}))
						},
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Tasklist$OutNoOp);
				} else {
					var httpError = msg.a.a;
					_v19$2:
					while (true) {
						if (httpError.$ === 3) {
							switch (httpError.a) {
								case 401:
									return $author$project$Pages$Tasklist$handleAuthError(model);
								case 403:
									return A2($author$project$Pages$Tasklist$handleTasklistAlreadyClosedError, model, 'Työtä ei voida lisätä, koska lomake on jo suljettu');
								default:
									break _v19$2;
							}
						} else {
							break _v19$2;
						}
					}
					return $author$project$Pages$Tasklist$init_Errored(
						$elm$core$Maybe$Just('Uuden työn lisääminen epäonnistui'));
				}
			case 3:
				if (!msg.a.$) {
					var updatedTasklist = msg.a.a;
					return _Utils_Tuple3(
						{
							_: $author$project$Pages$Tasklist$Loaded(
								_Utils_update(
									model,
									{
										t: false,
										M: $author$project$Pages$Tasklist$NotEditing,
										e: A3(
											$author$project$Utils$MiscUtils$list_replaceBy,
											function ($) {
												return $.er;
											},
											updatedTasklist,
											model.e)
									}))
						},
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Tasklist$OutNotification(
							A2($author$project$Components$Notification$Notification, 'Työ poistettu', 1)));
				} else {
					var httpError = msg.a.a;
					_v20$2:
					while (true) {
						if (httpError.$ === 3) {
							switch (httpError.a) {
								case 401:
									return $author$project$Pages$Tasklist$handleAuthError(model);
								case 403:
									return A2($author$project$Pages$Tasklist$handleTasklistAlreadyClosedError, model, 'Työtä ei voida poistaa, koska lomake on jo suljettu');
								default:
									break _v20$2;
							}
						} else {
							break _v20$2;
						}
					}
					return $author$project$Pages$Tasklist$init_Errored(
						$elm$core$Maybe$Just('Työn poistaminen epäonnistui'));
				}
			case 2:
				if (!msg.a.$) {
					var updatedTasklist = msg.a.a;
					return _Utils_Tuple3(
						{
							_: $author$project$Pages$Tasklist$Loaded(
								_Utils_update(
									model,
									{
										t: false,
										M: $author$project$Pages$Tasklist$NotEditing,
										e: A3(
											$author$project$Utils$MiscUtils$list_replaceBy,
											function ($) {
												return $.er;
											},
											updatedTasklist,
											model.e)
									}))
						},
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Tasklist$OutNoOp);
				} else {
					var httpError = msg.a.a;
					_v21$2:
					while (true) {
						if (httpError.$ === 3) {
							switch (httpError.a) {
								case 401:
									return $author$project$Pages$Tasklist$handleAuthError(model);
								case 403:
									return A2($author$project$Pages$Tasklist$handleTasklistAlreadyClosedError, model, 'Työtä ei voida muuttaa, koska lomake on jo suljettu');
								default:
									break _v21$2;
							}
						} else {
							break _v21$2;
						}
					}
					return $author$project$Pages$Tasklist$init_Errored(
						$elm$core$Maybe$Just('Työn muokkaaminen epäonnistui'));
				}
			case 12:
				var tasklist = msg.a;
				return _Utils_Tuple3(
					{
						_: $author$project$Pages$Tasklist$Loaded(model)
					},
					A2(
						$elm$core$Platform$Cmd$map,
						$author$project$Pages$Tasklist$Msg_Loaded,
						A2(
							$elm$core$Task$attempt,
							$author$project$Pages$Tasklist$GotOneTimeCustomerName,
							A2($author$project$Data$Tasklists$updateOneTimeCustomerName, serverContext, tasklist))),
					$author$project$Pages$Tasklist$OutNoOp);
			case 13:
				var tasklist = msg.a;
				return _Utils_Tuple3(
					{
						_: $author$project$Pages$Tasklist$Loaded(model)
					},
					A2(
						$elm$core$Platform$Cmd$map,
						$author$project$Pages$Tasklist$Msg_Loaded,
						A2(
							$elm$core$Task$attempt,
							$author$project$Pages$Tasklist$GotOneTimeCustomerContactInfo,
							A2($author$project$Data$Tasklists$updateOneTimeCustomerContactInfo, serverContext, tasklist))),
					$author$project$Pages$Tasklist$OutNoOp);
			case 9:
				var tasklistGroup = msg.a;
				var toActive = msg.b;
				var newSelectedGroups = (toActive ? $elm$core$Set$insert(tasklistGroup.mD) : $elm$core$Set$remove(tasklistGroup.mD))(model.bf);
				return _Utils_Tuple3(
					{
						_: $author$project$Pages$Tasklist$Loaded(
							_Utils_update(
								model,
								{bf: newSelectedGroups}))
					},
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Tasklist$OutNoOp);
			case 10:
				var tasklist = msg.a;
				var updatedName = msg.b;
				var updatedTasklist = _Utils_update(
					tasklist,
					{nf: updatedName});
				return _Utils_Tuple3(
					{
						_: $author$project$Pages$Tasklist$Loaded(
							_Utils_update(
								model,
								{
									bz: A2($author$project$Pages$Tasklist$EditingOneTimeCustomerName, tasklist.er, updatedName),
									e: A3(
										$author$project$Utils$MiscUtils$list_replaceBy,
										function ($) {
											return $.er;
										},
										updatedTasklist,
										model.e)
								}))
					},
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Tasklist$OutNoOp);
			case 11:
				var tasklist = msg.a;
				var updatedContactInfo = msg.b;
				var updatedTasklist = _Utils_update(
					tasklist,
					{ne: updatedContactInfo});
				return _Utils_Tuple3(
					{
						_: $author$project$Pages$Tasklist$Loaded(
							_Utils_update(
								model,
								{
									bz: A2($author$project$Pages$Tasklist$EditingOneTimeCustomerContactInfo, tasklist.er, updatedContactInfo),
									e: A3(
										$author$project$Utils$MiscUtils$list_replaceBy,
										function ($) {
											return $.er;
										},
										updatedTasklist,
										model.e)
								}))
					},
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Tasklist$OutNoOp);
			case 16:
				var tasklist = msg.a;
				var isControlKeyPressed = msg.b;
				var validationError = $author$project$Pages$Tasklist$validateCloseTasklistAction(tasklist);
				var _v22 = _Utils_Tuple2(validationError, isControlKeyPressed);
				if (!_v22.a.$) {
					var errorString = _v22.a.a;
					return _Utils_Tuple3(
						{
							_: $author$project$Pages$Tasklist$Loaded(model)
						},
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Tasklist$OutNotification(
							A2($author$project$Components$Notification$Notification, errorString, 1)));
				} else {
					if (_v22.b) {
						var _v23 = _v22.a;
						return A3($author$project$Pages$Tasklist$doCloseTasklistAction, serverContext, model, tasklist);
					} else {
						var _v24 = _v22.a;
						var showConfirmationDialogAfter200MsCmd = A2($andrewMacmurray$elm_delay$Delay$after, 200, $author$project$Pages$Tasklist$ShowCloseTasklistConfirmationDialog);
						var _v25 = model.R;
						switch (_v25.$) {
							case 0:
								return _Utils_Tuple3(
									{
										_: $author$project$Pages$Tasklist$Loaded(
											_Utils_update(
												model,
												{
													R: $author$project$Pages$Tasklist$CloseButtonClickedOnce(tasklist)
												}))
									},
									A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$Msg_Loaded, showConfirmationDialogAfter200MsCmd),
									$author$project$Pages$Tasklist$OutNoOp);
							case 1:
								var tasklistAlreadyClicked = _v25.a;
								return _Utils_eq(tasklist, tasklistAlreadyClicked) ? A3($author$project$Pages$Tasklist$doCloseTasklistAction, serverContext, model, tasklist) : _Utils_Tuple3(
									{
										_: $author$project$Pages$Tasklist$Loaded(
											_Utils_update(
												model,
												{
													R: $author$project$Pages$Tasklist$CloseButtonClickedOnce(tasklist)
												}))
									},
									A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$Msg_Loaded, showConfirmationDialogAfter200MsCmd),
									$author$project$Pages$Tasklist$OutNoOp);
							default:
								return _Utils_Tuple3(
									{
										_: $author$project$Pages$Tasklist$Loaded(model)
									},
									$elm$core$Platform$Cmd$none,
									$author$project$Pages$Tasklist$OutNoOp);
						}
					}
				}
			case 18:
				var _v26 = model.R;
				if (_v26.$ === 1) {
					var tasklistToClose = _v26.a;
					return _Utils_Tuple3(
						{
							_: $author$project$Pages$Tasklist$Loaded(
								_Utils_update(
									model,
									{
										R: $author$project$Pages$Tasklist$CloseConfirmationDialogOpen(tasklistToClose)
									}))
						},
						A2(
							$elm$core$Platform$Cmd$map,
							$author$project$Pages$Tasklist$Msg_Loaded,
							A2(
								$elm$core$Task$attempt,
								function (_v27) {
									return $author$project$Pages$Tasklist$NoOp;
								},
								$elm$browser$Browser$Dom$focus('confirm-tasklist-close-button'))),
						$author$project$Pages$Tasklist$OutNoOp);
				} else {
					return _Utils_Tuple3(
						{
							_: $author$project$Pages$Tasklist$Loaded(model)
						},
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Tasklist$OutNoOp);
				}
			case 19:
				return _Utils_Tuple3(
					{
						_: $author$project$Pages$Tasklist$Loaded(
							_Utils_update(
								model,
								{R: $author$project$Pages$Tasklist$ClosingProcessIdle}))
					},
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Tasklist$OutNoOp);
			case 17:
				var tasklist = msg.a;
				return A3($author$project$Pages$Tasklist$doCloseTasklistAction, serverContext, model, tasklist);
			case 14:
				var tasklist = msg.a;
				var defaultMake = function () {
					var _v29 = tasklist.k8;
					if (!_v29.$) {
						var vehicleMakeId = _v29.a;
						return A2(
							$elm$core$Maybe$withDefault,
							'',
							A2(
								$elm$core$Maybe$map,
								function ($) {
									return $.jC;
								},
								A2(
									$author$project$Helpers$firstWhere,
									A2(
										$elm$core$Basics$composeR,
										function ($) {
											return $.er;
										},
										$elm$core$Basics$eq(vehicleMakeId)),
									model.am)));
					} else {
						return '';
					}
				}();
				var _v28 = function () {
					var emptyFields = A2(
						$author$project$Types$TaskEditableFields$empty,
						defaultMake,
						$elm$core$Maybe$Just(appContext.dE));
					return $author$project$Pages$Tasklist$TaskEditor$init(0)(model.Y)(model.X)(model.T)(model.aT)(model.am)(tasklist)(tasklist.aj)(model.as)(model.bU)(emptyFields);
				}();
				var editorModel = _v28.a;
				var taskEditorCmd = _v28.b;
				return _Utils_Tuple3(
					{
						_: $author$project$Pages$Tasklist$Loaded(
							_Utils_update(
								model,
								{
									M: A2($author$project$Pages$Tasklist$EditingNewTask, editorModel, tasklist)
								}))
					},
					A2(
						$elm$core$Platform$Cmd$map,
						$author$project$Pages$Tasklist$Msg_Loaded,
						A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$GotTaskEditorMsg, taskEditorCmd)),
					$author$project$Pages$Tasklist$OutNoOp);
			case 15:
				var tasklist = msg.a;
				var clickedTask = msg.b;
				var _v30 = function () {
					var fields = $author$project$Types$TaskEditableFields$fromTask(clickedTask);
					return $author$project$Pages$Tasklist$TaskEditor$init(1)(model.Y)(model.X)(model.T)(model.aT)(model.am)(tasklist)(
						A2(
							$elm$core$List$filter,
							$elm$core$Basics$neq(clickedTask),
							tasklist.aj))(model.as)(model.bU)(fields);
				}();
				var editorModel = _v30.a;
				var taskEditorCmd = _v30.b;
				return _Utils_Tuple3(
					{
						_: $author$project$Pages$Tasklist$Loaded(
							_Utils_update(
								model,
								{
									M: A3($author$project$Pages$Tasklist$EditingExistingTask, editorModel, tasklist, clickedTask)
								}))
					},
					A2(
						$elm$core$Platform$Cmd$map,
						$author$project$Pages$Tasklist$Msg_Loaded,
						A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$GotTaskEditorMsg, taskEditorCmd)),
					$author$project$Pages$Tasklist$OutNoOp);
			case 21:
				var tasklist = msg.a;
				var task = msg.b;
				return _Utils_Tuple3(
					{
						_: $author$project$Pages$Tasklist$Loaded(
							_Utils_update(
								model,
								{t: true}))
					},
					A2(
						$elm$core$Platform$Cmd$map,
						$author$project$Pages$Tasklist$Msg_Loaded,
						A3($author$project$Pages$Tasklist$toggleWorkerTaskOperationAcknowledgementCmd, serverContext, tasklist, task)),
					$author$project$Pages$Tasklist$OutNoOp);
			case 22:
				if (!msg.a.$) {
					var updatedTasklist = msg.a.a;
					return _Utils_Tuple3(
						{
							_: $author$project$Pages$Tasklist$Loaded(
								_Utils_update(
									model,
									{
										t: false,
										e: A3(
											$author$project$Utils$MiscUtils$list_replaceBy,
											function ($) {
												return $.er;
											},
											updatedTasklist,
											model.e)
									}))
						},
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Tasklist$OutNotification(
							A2($author$project$Components$Notification$Notification, 'Työntekijän muutokset kuitattu', 1)));
				} else {
					var httpError = msg.a.a;
					_v31$2:
					while (true) {
						if (httpError.$ === 3) {
							switch (httpError.a) {
								case 401:
									return $author$project$Pages$Tasklist$handleAuthError(model);
								case 403:
									return A2($author$project$Pages$Tasklist$handleTasklistAlreadyClosedError, model, 'Kuittausta ei voida muuttaa, koska lomake on jo suljettu');
								default:
									break _v31$2;
							}
						} else {
							break _v31$2;
						}
					}
					return $author$project$Pages$Tasklist$init_Errored(
						$elm$core$Maybe$Just('Työntekijän muutosten kuittaaminen epäonnistui'));
				}
			case 23:
				if (!msg.a.$) {
					var updatedTasklist = msg.a.a;
					return _Utils_Tuple3(
						{
							_: $author$project$Pages$Tasklist$Loaded(
								_Utils_update(
									model,
									{
										t: false,
										e: A3(
											$author$project$Utils$MiscUtils$list_replaceBy,
											function ($) {
												return $.er;
											},
											updatedTasklist,
											model.e)
									}))
						},
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$Tasklist$OutNotification(
							A2($author$project$Components$Notification$Notification, 'Työntekijän muutosten kuittaus poistettu', 1)));
				} else {
					var httpError = msg.a.a;
					_v32$2:
					while (true) {
						if (httpError.$ === 3) {
							switch (httpError.a) {
								case 401:
									return $author$project$Pages$Tasklist$handleAuthError(model);
								case 403:
									return A2($author$project$Pages$Tasklist$handleTasklistAlreadyClosedError, model, 'Kuittausta ei voida muuttaa, koska lomake on jo suljettu');
								default:
									break _v32$2;
							}
						} else {
							break _v32$2;
						}
					}
					return $author$project$Pages$Tasklist$init_Errored(
						$elm$core$Maybe$Just('Työntekijän muutosten kuittauksen poisto epäonnistui'));
				}
			case 20:
				return _Utils_Tuple3(
					{
						_: $author$project$Pages$Tasklist$Loaded(
							_Utils_update(
								model,
								{M: $author$project$Pages$Tasklist$NotEditing}))
					},
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Tasklist$OutNoOp);
			default:
				return _Utils_Tuple3(
					{
						_: $author$project$Pages$Tasklist$Loaded(model)
					},
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Tasklist$OutNoOp);
		}
	});
var $author$project$Pages$Tasklist$TaskQueue$GotData = $elm$core$Basics$identity;
var $author$project$Pages$Tasklist$TaskQueue$Loading = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$Msg_Loading = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$init_Loading = F2(
	function (appContext, user) {
		return _Utils_Tuple2(
			{
				_: $author$project$Pages$Tasklist$TaskQueue$Loading(
					{gA: user})
			},
			A2(
				$elm$core$Platform$Cmd$map,
				$author$project$Pages$Tasklist$TaskQueue$Msg_Loading,
				A2(
					$elm$core$Task$attempt,
					$elm$core$Basics$identity,
					A2($author$project$Pages$Tasklist$TaskQueue$fetchData, appContext.n1, user))));
	});
var $author$project$Pages$Tasklist$TaskQueue$init = F2(
	function (appContext, siteUser) {
		return A2($author$project$Pages$Tasklist$TaskQueue$init_Loading, appContext, siteUser);
	});
var $author$project$Pages$Tasklist$init_Loaded = F3(
	function (appContext, user, data) {
		var _v0 = A2($author$project$Pages$Tasklist$TaskQueue$init, appContext, user);
		var taskQueueModel = _v0.a;
		var taskQueueCmd = _v0.b;
		var initialModel = {
			T: data.T,
			bU: data.bU,
			dS: $author$project$Pages$Tasklist$buildTasklistGroups(data.e),
			O: $author$project$Pages$Tasklist$NotDragging,
			da: false,
			t: false,
			bz: $author$project$Pages$Tasklist$NotEditingOneTimeCustomer,
			bf: $elm$core$Set$empty,
			X: data.X,
			Y: user,
			as: data.as,
			M: $author$project$Pages$Tasklist$NotEditing,
			N: taskQueueModel,
			je: $elm$core$Maybe$Nothing,
			R: $author$project$Pages$Tasklist$ClosingProcessIdle,
			e: data.e,
			aT: data.aT,
			am: data.am
		};
		return _Utils_Tuple2(
			{
				_: $author$project$Pages$Tasklist$Loaded(initialModel)
			},
			A2(
				$elm$core$Platform$Cmd$map,
				$author$project$Pages$Tasklist$Msg_Loaded,
				$elm$core$Platform$Cmd$batch(
					_List_fromArray(
						[
							A2($author$project$Helpers$doAfter, appContext.n1.fx, $author$project$Pages$Tasklist$RequestDataRefresh),
							A2($elm$core$Platform$Cmd$map, $author$project$Pages$Tasklist$GotTaskQueueMsg, taskQueueCmd)
						]))));
	});
var $author$project$Pages$Tasklist$update_Loading = F3(
	function (appContext, msg, model) {
		if (!msg.$) {
			var data = msg.a;
			var _v1 = A3($author$project$Pages$Tasklist$init_Loaded, appContext, model.gA, data);
			var model2 = _v1.a;
			var cmd = _v1.b;
			return _Utils_Tuple3(model2, cmd, $author$project$Pages$Tasklist$OutNoOp);
		} else {
			if ((msg.a.$ === 3) && (msg.a.a === 401)) {
				return _Utils_Tuple3(
					{
						_: $author$project$Pages$Tasklist$Loading(model)
					},
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Tasklist$OutGotAuthError);
			} else {
				return $author$project$Pages$Tasklist$init_Errored(
					$elm$core$Maybe$Just('Virhe ladattaessa tietoja palvelimelta.'));
			}
		}
	});
var $author$project$Pages$Tasklist$update = F3(
	function (appContext, msg, model) {
		var _v0 = _Utils_Tuple2(msg, model._);
		_v0$3:
		while (true) {
			switch (_v0.a.$) {
				case 0:
					if (!_v0.b.$) {
						var loadingMsg = _v0.a.a;
						var loadingModel = _v0.b.a;
						return A3($author$project$Pages$Tasklist$update_Loading, appContext, loadingMsg, loadingModel);
					} else {
						break _v0$3;
					}
				case 1:
					if (_v0.b.$ === 1) {
						var loadedMsg = _v0.a.a;
						var loadedModel = _v0.b.a;
						return A3($author$project$Pages$Tasklist$update_Loaded, appContext, loadedMsg, loadedModel);
					} else {
						break _v0$3;
					}
				default:
					if (_v0.b.$ === 2) {
						var erroredMsg = _v0.a.a;
						var erroredModel = _v0.b.a;
						return A2($author$project$Pages$Tasklist$update_Errored, erroredMsg, erroredModel);
					} else {
						break _v0$3;
					}
			}
		}
		return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$Tasklist$OutNoOp);
	});
var $author$project$Pages$WorkTimeTracking$askPinAgain = F2(
	function (appContext, user) {
		var _v0 = A2($author$project$Pages$WorkTimeTracking$init, appContext, user);
		var model_init = _v0.a;
		var cmd_init = _v0.b;
		return _Utils_Tuple3(
			model_init,
			cmd_init,
			$author$project$Pages$WorkTimeTracking$OutNotification($author$project$Components$PinDialog$enterPinAgainNotification));
	});
var $author$project$Pages$WorkTimeTracking$doNothing = function (model) {
	return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$OutNoOp);
};
var $author$project$Pages$WorkTimeTracking$maybeTransitionFrom_askingPin = F2(
	function (serverContext, subOutMsg) {
		if (!subOutMsg) {
			return $elm$core$Maybe$Just(
				$author$project$Pages$WorkTimeTracking$update_loadingState(
					$author$project$Pages$WorkTimeTracking$State_Loading$init(serverContext)));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Pages$WorkTimeTracking$State_Main$NoDialog = {$: 2};
var $author$project$Pages$WorkTimeTracking$State_Main$OutNoOp = {$: 3};
var $author$project$Pages$WorkTimeTracking$State_Main$Entry_Clicked_MarkAsPaid = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$WorkTimeTracking$State_Main$Entry_Clicked_Unsign = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$WorkTimeTracking$State_Main$Export_WorkTimeEntries = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$WorkTimeTracking$State_Main$SubMsg_PaymentQueueTabbedTableMsg = function (a) {
	return {$: 14, a: a};
};
var $author$project$Types$Value$StringValue = function (a) {
	return {$: 3, a: a};
};
var $author$project$Types$Worker$compensationTypeToString = function (workerCompensationType) {
	switch (workerCompensationType) {
		case 0:
			return 'tuntityö';
		case 1:
			return 'kk-palkka';
		case 2:
			return '-';
		default:
			return 'provisio';
	}
};
var $author$project$Types$Worker$findFromList = F2(
	function (workers, workerId) {
		return A2(
			$author$project$Helpers$firstWhere,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.er;
				},
				$elm$core$Basics$eq(workerId)),
			workers);
	});
var $author$project$Types$WorkTimeEntry$aggregates_compensationType = function (workers) {
	return {
		jH: function (workTimeEntries_) {
			return $author$project$Types$Value$StringValue(
				A2(
					$elm$core$String$join,
					', ',
					$elm$core$List$sort(
						$elm_community$list_extra$List$Extra$unique(
							A2(
								$elm$core$List$map,
								A2(
									$elm$core$Basics$composeR,
									function ($) {
										return $.jy;
									},
									A2(
										$elm$core$Basics$composeR,
										$author$project$Types$Worker$findFromList(workers),
										A2(
											$elm$core$Basics$composeR,
											$elm$core$Maybe$map(
												A2(
													$elm$core$Basics$composeR,
													function ($) {
														return $.kI;
													},
													$author$project$Types$Worker$compensationTypeToString)),
											$elm$core$Maybe$withDefault('')))),
								workTimeEntries_)))));
		},
		mD: 'Sopimustyyppi'
	};
};
var $author$project$Components$TabbedTable$TabbedTableApi$TabbedTable = $elm$core$Basics$identity;
var $author$project$Components$TabbedTable$TabbedTableApi$allItemsTab = F2(
	function (allItemsTab_, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{
				jT: $elm$core$Maybe$Just(allItemsTab_)
			});
	});
var $author$project$Components$Button$Button = $elm$core$Basics$identity;
var $author$project$Components$Button$breakTextToMultipleLines = function (_v0) {
	var options = _v0;
	return _Utils_update(
		options,
		{dV: true});
};
var $author$project$Components$Button$Active = {$: 0};
var $author$project$Components$Button$IconLeft = 0;
var $author$project$Components$Button$Plain = 0;
var $author$project$Components$Button$TextCenter = 0;
var $author$project$Styles$fontSizesInPixels = {mc: 16, ml: 14, i5: 12, o6: 18, jB: 10, o7: 20};
var $author$project$Components$Button$defaultOptions = function () {
	var defaultFontSize = $author$project$Styles$fontSizesInPixels.o6;
	return {dV: false, b3: _List_Nil, en: $elm$core$Maybe$Nothing, eo: $elm$core$Maybe$Nothing, cc: $elm$core$Maybe$Nothing, c7: $elm$core$Maybe$Nothing, eq: 0, c8: defaultFontSize, er: $elm$core$Maybe$Nothing, c9: false, bu: true, bx: 0, cj: $elm$core$Maybe$Nothing, eF: $elm$core$Maybe$Nothing, eH: $elm$core$Maybe$Nothing, cw: $author$project$Components$Button$Active, e0: $elm$core$Maybe$Nothing, e4: $elm$core$Maybe$Nothing, bJ: $elm$core$Maybe$Nothing, dA: $elm$core$Maybe$Nothing, dB: 0, dC: defaultFontSize, e9: $elm$core$Maybe$Nothing, fg: $elm$core$Maybe$Nothing};
}();
var $author$project$Components$Button$button = $author$project$Components$Button$defaultOptions;
var $author$project$Components$Table$ColumnApi$Column = $elm$core$Basics$identity;
var $author$project$Components$Table$ColumnApi$buttonFunctions = F2(
	function (buttonsFunctions_, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{dW: buttonsFunctions_});
	});
var $author$project$Components$Button$Disabled = {$: 1};
var $author$project$Components$Button$disabled = F2(
	function (disable, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{
				cw: disable ? $author$project$Components$Button$Disabled : $author$project$Components$Button$Active
			});
	});
var $author$project$Components$Table$ColumnApi$defaultColumnOptions = {dW: _List_Nil, cT: $elm$core$Maybe$Nothing, ei: $elm$core$Maybe$Nothing, oI: $elm$core$Maybe$Nothing, dK: $elm$core$Maybe$Nothing, fg: 200};
var $author$project$Components$Table$ColumnApi$init = $author$project$Components$Table$ColumnApi$defaultColumnOptions;
var $author$project$Types$WorkTimeEntry$isPaid = function (entry) {
	return !_Utils_eq(entry.cm, $elm$core$Maybe$Nothing);
};
var $author$project$Components$Button$onClick = F2(
	function (msg, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{
				cj: $elm$core$Maybe$Just(msg)
			});
	});
var $author$project$Components$Button$Primary = 1;
var $author$project$Components$Button$primary = function (_v0) {
	var options = _v0;
	return _Utils_update(
		options,
		{bx: 1});
};
var $author$project$Components$Button$text = F2(
	function (buttonText, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{
				bJ: $elm$core$Maybe$Just(buttonText),
				dB: 0
			});
	});
var $author$project$Components$Button$textSize = F2(
	function (size, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{dC: size});
	});
var $author$project$Components$Table$ColumnApi$title = F2(
	function (title_, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{
				oI: $elm$core$Maybe$Just(title_)
			});
	});
var $author$project$Components$Table$ColumnApi$width = F2(
	function (width_, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{fg: width_});
	});
var $author$project$Components$Table$Columns$WorkTimeEntryColumns$button_mark_as_paid = function (onClick) {
	return A2(
		$author$project$Components$Table$ColumnApi$width,
		90,
		A2(
			$author$project$Components$Table$ColumnApi$buttonFunctions,
			_List_fromArray(
				[
					function (workTimeEntry) {
					return A2(
						$author$project$Components$Button$disabled,
						$author$project$Types$WorkTimeEntry$isPaid(workTimeEntry),
						$author$project$Components$Button$breakTextToMultipleLines(
							A2(
								$author$project$Components$Button$textSize,
								15,
								$author$project$Components$Button$primary(
									A2(
										$author$project$Components$Button$onClick,
										onClick(workTimeEntry),
										A2($author$project$Components$Button$text, 'Kuittaa maksetuksi', $author$project$Components$Button$button))))));
				}
				]),
			A2($author$project$Components$Table$ColumnApi$title, 'Maksaminen', $author$project$Components$Table$ColumnApi$init)));
};
var $author$project$Components$Table$TableApi$Table = $elm$core$Basics$identity;
var $author$project$Components$Table$TableApi$columns = F2(
	function (columns_, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{cP: columns_});
	});
var $author$project$Components$TabbedTable$TabbedTableApi$columns = F2(
	function (columns_, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{
				ol: A2(
					$elm$core$List$map,
					$author$project$Components$Table$TableApi$columns(columns_),
					options.ol)
			});
	});
var $author$project$Components$Table$Internals$TableInternals$RowSelectionEnabled = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Components$Table$Internals$TableInternals$RowSelectionMenuClosed = 0;
var $author$project$Components$Table$TableApi$enableRowSelection = function (_v0) {
	var options = _v0;
	return _Utils_update(
		options,
		{
			gd: A2($author$project$Components$Table$Internals$TableInternals$RowSelectionEnabled, 0, _List_Nil)
		});
};
var $author$project$Components$TabbedTable$TabbedTableApi$enableRowSelection = function (_v0) {
	var options = _v0;
	return _Utils_update(
		options,
		{
			ol: A2($elm$core$List$map, $author$project$Components$Table$TableApi$enableRowSelection, options.ol)
		});
};
var $author$project$Components$Table$ColumnApi$AlwaysCellValue = function (a) {
	return {$: 1, a: a};
};
var $author$project$Types$Value$FloatValue = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$core$List$sum = function (numbers) {
	return A3($elm$core$List$foldl, $elm$core$Basics$add, 0, numbers);
};
var $author$project$Types$WorkTimeEntry$aggregates_balanceEffectSum = {
	jH: A2(
		$elm$core$Basics$composeR,
		$elm$core$List$map(
			function ($) {
				return $.gX;
			}),
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$sum,
			$author$project$Types$Value$FloatValue(1))),
	mD: 'Tunnit yhteensä'
};
var $mdgriffith$elm_ui$Internal$Model$AlignX = function (a) {
	return {$: 6, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$CenterX = 1;
var $mdgriffith$elm_ui$Element$centerX = $mdgriffith$elm_ui$Internal$Model$AlignX(1);
var $author$project$Components$Table$ColumnApi$customRenderer = F2(
	function (renderer, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{
				cT: $elm$core$Maybe$Just(renderer)
			});
	});
var $mdgriffith$elm_ui$Internal$Model$Unkeyed = function (a) {
	return {$: 0, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$AsEl = 2;
var $mdgriffith$elm_ui$Internal$Model$asEl = 2;
var $mdgriffith$elm_ui$Internal$Model$Generic = {$: 0};
var $mdgriffith$elm_ui$Internal$Model$div = $mdgriffith$elm_ui$Internal$Model$Generic;
var $mdgriffith$elm_ui$Internal$Model$NoNearbyChildren = {$: 0};
var $mdgriffith$elm_ui$Internal$Style$classes = {jD: 'a', bS: 'atv', jJ: 'ab', jK: 'cx', jL: 'cy', jM: 'acb', jN: 'accx', jO: 'accy', jP: 'acr', gO: 'al', gP: 'ar', jR: 'at', fj: 'ah', fk: 'av', j$: 's', j8: 'bh', j9: 'b', kd: 'w7', kf: 'bd', kg: 'bdt', dT: 'bn', kh: 'bs', dX: 'cpe', kB: 'cp', kC: 'cpx', kD: 'cpy', aZ: 'c', hc: 'ctr', d0: 'cb', d1: 'ccx', a_: 'ccy', cQ: 'cl', d2: 'cr', kN: 'ct', kU: 'cptr', kV: 'ctxt', ly: 'fcs', hu: 'focus-within', lz: 'fs', lD: 'g', fO: 'hbh', fQ: 'hc', hG: 'he', fR: 'hf', hH: 'hfp', lK: 'hv', lO: 'ic', lQ: 'fr', et: 'lbl', lU: 'iml', lV: 'imlf', lW: 'imlp', lX: 'implw', K: 'it', l5: 'i', h3: 'lnk', ci: 'nb', ic: 'notxt', mW: 'ol', m3: 'or', bA: 'oq', nl: 'oh', im: 'pg', $9: 'p', np: 'ppe', nM: 'ui', iP: 'r', nU: 'sb', nV: 'sbx', nW: 'sby', nX: 'sbt', n6: 'e', n7: 'cap', oa: 'sev', oh: 'sk', bJ: 't', oq: 'tc', or: 'w8', os: 'w2', ot: 'w9', ou: 'tj', e5: 'tja', ov: 'tl', ow: 'w3', ox: 'w5', oy: 'w4', oz: 'tr', oA: 'w6', oB: 'w1', oC: 'tun', jp: 'ts', bM: 'clr', oP: 'u', gD: 'wc', jw: 'we', gE: 'wf', jx: 'wfp', gF: 'wrp'};
var $mdgriffith$elm_ui$Internal$Model$columnClass = $mdgriffith$elm_ui$Internal$Style$classes.j$ + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.aZ);
var $mdgriffith$elm_ui$Internal$Model$gridClass = $mdgriffith$elm_ui$Internal$Style$classes.j$ + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.lD);
var $mdgriffith$elm_ui$Internal$Model$pageClass = $mdgriffith$elm_ui$Internal$Style$classes.j$ + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.im);
var $mdgriffith$elm_ui$Internal$Model$paragraphClass = $mdgriffith$elm_ui$Internal$Style$classes.j$ + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.$9);
var $mdgriffith$elm_ui$Internal$Model$rowClass = $mdgriffith$elm_ui$Internal$Style$classes.j$ + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.iP);
var $mdgriffith$elm_ui$Internal$Model$singleClass = $mdgriffith$elm_ui$Internal$Style$classes.j$ + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.n6);
var $mdgriffith$elm_ui$Internal$Model$contextClasses = function (context) {
	switch (context) {
		case 0:
			return $mdgriffith$elm_ui$Internal$Model$rowClass;
		case 1:
			return $mdgriffith$elm_ui$Internal$Model$columnClass;
		case 2:
			return $mdgriffith$elm_ui$Internal$Model$singleClass;
		case 3:
			return $mdgriffith$elm_ui$Internal$Model$gridClass;
		case 4:
			return $mdgriffith$elm_ui$Internal$Model$paragraphClass;
		default:
			return $mdgriffith$elm_ui$Internal$Model$pageClass;
	}
};
var $mdgriffith$elm_ui$Internal$Model$Keyed = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$NoStyleSheet = {$: 0};
var $mdgriffith$elm_ui$Internal$Model$Styled = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$Unstyled = function (a) {
	return {$: 0, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$addChildren = F2(
	function (existing, nearbyChildren) {
		switch (nearbyChildren.$) {
			case 0:
				return existing;
			case 1:
				var behind = nearbyChildren.a;
				return _Utils_ap(behind, existing);
			case 2:
				var inFront = nearbyChildren.a;
				return _Utils_ap(existing, inFront);
			default:
				var behind = nearbyChildren.a;
				var inFront = nearbyChildren.b;
				return _Utils_ap(
					behind,
					_Utils_ap(existing, inFront));
		}
	});
var $mdgriffith$elm_ui$Internal$Model$addKeyedChildren = F3(
	function (key, existing, nearbyChildren) {
		switch (nearbyChildren.$) {
			case 0:
				return existing;
			case 1:
				var behind = nearbyChildren.a;
				return _Utils_ap(
					A2(
						$elm$core$List$map,
						function (x) {
							return _Utils_Tuple2(key, x);
						},
						behind),
					existing);
			case 2:
				var inFront = nearbyChildren.a;
				return _Utils_ap(
					existing,
					A2(
						$elm$core$List$map,
						function (x) {
							return _Utils_Tuple2(key, x);
						},
						inFront));
			default:
				var behind = nearbyChildren.a;
				var inFront = nearbyChildren.b;
				return _Utils_ap(
					A2(
						$elm$core$List$map,
						function (x) {
							return _Utils_Tuple2(key, x);
						},
						behind),
					_Utils_ap(
						existing,
						A2(
							$elm$core$List$map,
							function (x) {
								return _Utils_Tuple2(key, x);
							},
							inFront)));
		}
	});
var $mdgriffith$elm_ui$Internal$Model$AsParagraph = 4;
var $mdgriffith$elm_ui$Internal$Model$asParagraph = 4;
var $mdgriffith$elm_ui$Internal$Flag$Flag = function (a) {
	return {$: 0, a: a};
};
var $mdgriffith$elm_ui$Internal$Flag$Second = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Flag$flag = function (i) {
	return (i > 31) ? $mdgriffith$elm_ui$Internal$Flag$Second(1 << (i - 32)) : $mdgriffith$elm_ui$Internal$Flag$Flag(1 << i);
};
var $mdgriffith$elm_ui$Internal$Flag$alignBottom = $mdgriffith$elm_ui$Internal$Flag$flag(41);
var $mdgriffith$elm_ui$Internal$Flag$alignRight = $mdgriffith$elm_ui$Internal$Flag$flag(40);
var $mdgriffith$elm_ui$Internal$Flag$centerX = $mdgriffith$elm_ui$Internal$Flag$flag(42);
var $mdgriffith$elm_ui$Internal$Flag$centerY = $mdgriffith$elm_ui$Internal$Flag$flag(43);
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$div = _VirtualDom_node('div');
var $mdgriffith$elm_ui$Internal$Model$lengthClassName = function (x) {
	switch (x.$) {
		case 0:
			var px = x.a;
			return $elm$core$String$fromInt(px) + 'px';
		case 1:
			return 'auto';
		case 2:
			var i = x.a;
			return $elm$core$String$fromInt(i) + 'fr';
		case 3:
			var min = x.a;
			var len = x.b;
			return 'min' + ($elm$core$String$fromInt(min) + $mdgriffith$elm_ui$Internal$Model$lengthClassName(len));
		default:
			var max = x.a;
			var len = x.b;
			return 'max' + ($elm$core$String$fromInt(max) + $mdgriffith$elm_ui$Internal$Model$lengthClassName(len));
	}
};
var $mdgriffith$elm_ui$Internal$Model$floatClass = function (x) {
	return $elm$core$String$fromInt(
		$elm$core$Basics$round(x * 255));
};
var $mdgriffith$elm_ui$Internal$Model$transformClass = function (transform) {
	switch (transform.$) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			var _v1 = transform.a;
			var x = _v1.a;
			var y = _v1.b;
			var z = _v1.c;
			return $elm$core$Maybe$Just(
				'mv-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(x) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(y) + ('-' + $mdgriffith$elm_ui$Internal$Model$floatClass(z))))));
		default:
			var _v2 = transform.a;
			var tx = _v2.a;
			var ty = _v2.b;
			var tz = _v2.c;
			var _v3 = transform.b;
			var sx = _v3.a;
			var sy = _v3.b;
			var sz = _v3.c;
			var _v4 = transform.c;
			var ox = _v4.a;
			var oy = _v4.b;
			var oz = _v4.c;
			var angle = transform.d;
			return $elm$core$Maybe$Just(
				'tfrm-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(tx) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(ty) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(tz) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(sx) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(sy) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(sz) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(ox) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(oy) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(oz) + ('-' + $mdgriffith$elm_ui$Internal$Model$floatClass(angle))))))))))))))))))));
	}
};
var $mdgriffith$elm_ui$Internal$Model$getStyleName = function (style) {
	switch (style.$) {
		case 13:
			var name = style.a;
			return name;
		case 12:
			var name = style.a;
			var o = style.b;
			return name;
		case 0:
			var _class = style.a;
			return _class;
		case 1:
			var name = style.a;
			return name;
		case 2:
			var i = style.a;
			return 'font-size-' + $elm$core$String$fromInt(i);
		case 3:
			var _class = style.a;
			return _class;
		case 4:
			var _class = style.a;
			return _class;
		case 5:
			var cls = style.a;
			var x = style.b;
			var y = style.c;
			return cls;
		case 7:
			var cls = style.a;
			var top = style.b;
			var right = style.c;
			var bottom = style.d;
			var left = style.e;
			return cls;
		case 6:
			var cls = style.a;
			var top = style.b;
			var right = style.c;
			var bottom = style.d;
			var left = style.e;
			return cls;
		case 8:
			var template = style.a;
			return 'grid-rows-' + (A2(
				$elm$core$String$join,
				'-',
				A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$lengthClassName, template.nP)) + ('-cols-' + (A2(
				$elm$core$String$join,
				'-',
				A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$lengthClassName, template.cP)) + ('-space-x-' + ($mdgriffith$elm_ui$Internal$Model$lengthClassName(template.gn.a) + ('-space-y-' + $mdgriffith$elm_ui$Internal$Model$lengthClassName(template.gn.b)))))));
		case 9:
			var pos = style.a;
			return 'gp grid-pos-' + ($elm$core$String$fromInt(pos.iP) + ('-' + ($elm$core$String$fromInt(pos.ft) + ('-' + ($elm$core$String$fromInt(pos.fg) + ('-' + $elm$core$String$fromInt(pos.en)))))));
		case 11:
			var selector = style.a;
			var subStyle = style.b;
			var name = function () {
				switch (selector) {
					case 0:
						return 'fs';
					case 1:
						return 'hv';
					default:
						return 'act';
				}
			}();
			return A2(
				$elm$core$String$join,
				' ',
				A2(
					$elm$core$List$map,
					function (sty) {
						var _v1 = $mdgriffith$elm_ui$Internal$Model$getStyleName(sty);
						if (_v1 === '') {
							return '';
						} else {
							var styleName = _v1;
							return styleName + ('-' + name);
						}
					},
					subStyle));
		default:
			var x = style.a;
			return A2(
				$elm$core$Maybe$withDefault,
				'',
				$mdgriffith$elm_ui$Internal$Model$transformClass(x));
	}
};
var $mdgriffith$elm_ui$Internal$Model$reduceStyles = F2(
	function (style, nevermind) {
		var cache = nevermind.a;
		var existing = nevermind.b;
		var styleName = $mdgriffith$elm_ui$Internal$Model$getStyleName(style);
		return A2($elm$core$Set$member, styleName, cache) ? nevermind : _Utils_Tuple2(
			A2($elm$core$Set$insert, styleName, cache),
			A2($elm$core$List$cons, style, existing));
	});
var $mdgriffith$elm_ui$Internal$Model$Property = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$Style = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$dot = function (c) {
	return '.' + c;
};
var $mdgriffith$elm_ui$Internal$Model$formatColor = function (_v0) {
	var red = _v0.a;
	var green = _v0.b;
	var blue = _v0.c;
	var alpha = _v0.d;
	return 'rgba(' + ($elm$core$String$fromInt(
		$elm$core$Basics$round(red * 255)) + ((',' + $elm$core$String$fromInt(
		$elm$core$Basics$round(green * 255))) + ((',' + $elm$core$String$fromInt(
		$elm$core$Basics$round(blue * 255))) + (',' + ($elm$core$String$fromFloat(alpha) + ')')))));
};
var $mdgriffith$elm_ui$Internal$Model$formatBoxShadow = function (shadow) {
	return A2(
		$elm$core$String$join,
		' ',
		A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					shadow.hU ? $elm$core$Maybe$Just('inset') : $elm$core$Maybe$Nothing,
					$elm$core$Maybe$Just(
					$elm$core$String$fromFloat(shadow.f1.a) + 'px'),
					$elm$core$Maybe$Just(
					$elm$core$String$fromFloat(shadow.f1.b) + 'px'),
					$elm$core$Maybe$Just(
					$elm$core$String$fromFloat(shadow.fp) + 'px'),
					$elm$core$Maybe$Just(
					$elm$core$String$fromFloat(shadow.gl) + 'px'),
					$elm$core$Maybe$Just(
					$mdgriffith$elm_ui$Internal$Model$formatColor(shadow.fu))
				])));
};
var $elm$core$Tuple$mapFirst = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			func(x),
			y);
	});
var $mdgriffith$elm_ui$Internal$Model$renderFocusStyle = function (focus) {
	return _List_fromArray(
		[
			A2(
			$mdgriffith$elm_ui$Internal$Model$Style,
			$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hu) + ':focus-within',
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						A2(
						$elm$core$Maybe$map,
						function (color) {
							return A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'border-color',
								$mdgriffith$elm_ui$Internal$Model$formatColor(color));
						},
						focus.ke),
						A2(
						$elm$core$Maybe$map,
						function (color) {
							return A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'background-color',
								$mdgriffith$elm_ui$Internal$Model$formatColor(color));
						},
						focus.j6),
						A2(
						$elm$core$Maybe$map,
						function (shadow) {
							return A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'box-shadow',
								$mdgriffith$elm_ui$Internal$Model$formatBoxShadow(
									{
										fp: shadow.fp,
										fu: shadow.fu,
										hU: false,
										f1: A2(
											$elm$core$Tuple$mapSecond,
											$elm$core$Basics$toFloat,
											A2($elm$core$Tuple$mapFirst, $elm$core$Basics$toFloat, shadow.f1)),
										gl: shadow.gl
									}));
						},
						focus.n3),
						$elm$core$Maybe$Just(
						A2($mdgriffith$elm_ui$Internal$Model$Property, 'outline', 'none'))
					]))),
			A2(
			$mdgriffith$elm_ui$Internal$Model$Style,
			($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j$) + ':focus .focusable, ') + (($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j$) + '.focusable:focus, ') + ('.ui-slide-bar:focus + ' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j$) + ' .focusable-thumb'))),
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						A2(
						$elm$core$Maybe$map,
						function (color) {
							return A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'border-color',
								$mdgriffith$elm_ui$Internal$Model$formatColor(color));
						},
						focus.ke),
						A2(
						$elm$core$Maybe$map,
						function (color) {
							return A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'background-color',
								$mdgriffith$elm_ui$Internal$Model$formatColor(color));
						},
						focus.j6),
						A2(
						$elm$core$Maybe$map,
						function (shadow) {
							return A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'box-shadow',
								$mdgriffith$elm_ui$Internal$Model$formatBoxShadow(
									{
										fp: shadow.fp,
										fu: shadow.fu,
										hU: false,
										f1: A2(
											$elm$core$Tuple$mapSecond,
											$elm$core$Basics$toFloat,
											A2($elm$core$Tuple$mapFirst, $elm$core$Basics$toFloat, shadow.f1)),
										gl: shadow.gl
									}));
						},
						focus.n3),
						$elm$core$Maybe$Just(
						A2($mdgriffith$elm_ui$Internal$Model$Property, 'outline', 'none'))
					])))
		]);
};
var $elm$virtual_dom$VirtualDom$property = F2(
	function (key, value) {
		return A2(
			_VirtualDom_property,
			_VirtualDom_noInnerHtmlOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $mdgriffith$elm_ui$Internal$Style$AllChildren = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Batch = function (a) {
	return {$: 6, a: a};
};
var $mdgriffith$elm_ui$Internal$Style$Child = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Class = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Descriptor = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Left = 3;
var $mdgriffith$elm_ui$Internal$Style$Prop = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Right = 2;
var $mdgriffith$elm_ui$Internal$Style$Self = $elm$core$Basics$identity;
var $mdgriffith$elm_ui$Internal$Style$Supports = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Content = $elm$core$Basics$identity;
var $mdgriffith$elm_ui$Internal$Style$Bottom = 1;
var $mdgriffith$elm_ui$Internal$Style$CenterX = 4;
var $mdgriffith$elm_ui$Internal$Style$CenterY = 5;
var $mdgriffith$elm_ui$Internal$Style$Top = 0;
var $mdgriffith$elm_ui$Internal$Style$alignments = _List_fromArray(
	[0, 1, 2, 3, 4, 5]);
var $mdgriffith$elm_ui$Internal$Style$contentName = function (desc) {
	switch (desc) {
		case 0:
			var _v1 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.kN);
		case 1:
			var _v2 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.d0);
		case 2:
			var _v3 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.d2);
		case 3:
			var _v4 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cQ);
		case 4:
			var _v5 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.d1);
		default:
			var _v6 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.a_);
	}
};
var $mdgriffith$elm_ui$Internal$Style$selfName = function (desc) {
	switch (desc) {
		case 0:
			var _v1 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jR);
		case 1:
			var _v2 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jJ);
		case 2:
			var _v3 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gP);
		case 3:
			var _v4 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gO);
		case 4:
			var _v5 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jK);
		default:
			var _v6 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jL);
	}
};
var $mdgriffith$elm_ui$Internal$Style$describeAlignment = function (values) {
	var createDescription = function (alignment) {
		var _v0 = values(alignment);
		var content = _v0.a;
		var indiv = _v0.b;
		return _List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$contentName(alignment),
				content),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Child,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j$),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$selfName(alignment),
						indiv)
					]))
			]);
	};
	return $mdgriffith$elm_ui$Internal$Style$Batch(
		A2($elm$core$List$concatMap, createDescription, $mdgriffith$elm_ui$Internal$Style$alignments));
};
var $mdgriffith$elm_ui$Internal$Style$elDescription = _List_fromArray(
	[
		A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
		A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'column'),
		A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'pre'),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Descriptor,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fO),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '0'),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Child,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j8),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '-1')
					]))
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Descriptor,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.nX),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Internal$Style$Child,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.bJ),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fR),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gE),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'auto !important')
							]))
					]))
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Child,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fQ),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', 'auto')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Child,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fR),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '100000')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Child,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gE),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Child,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jx),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Child,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gD),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
			])),
		$mdgriffith$elm_ui$Internal$Style$describeAlignment(
		function (alignment) {
			switch (alignment) {
				case 0:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-start')
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', '0 !important')
							]));
				case 1:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-end')
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', '0 !important')
							]));
				case 2:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-end')
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-end')
							]));
				case 3:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-start')
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
							]));
				case 4:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'center')
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'center')
							]));
				default:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j$),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto')
									]))
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important')
							]));
			}
		})
	]);
var $mdgriffith$elm_ui$Internal$Style$gridAlignments = function (values) {
	var createDescription = function (alignment) {
		return _List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Internal$Style$Child,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j$),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$selfName(alignment),
						values(alignment))
					]))
			]);
	};
	return $mdgriffith$elm_ui$Internal$Style$Batch(
		A2($elm$core$List$concatMap, createDescription, $mdgriffith$elm_ui$Internal$Style$alignments));
};
var $mdgriffith$elm_ui$Internal$Style$Above = 0;
var $mdgriffith$elm_ui$Internal$Style$Behind = 5;
var $mdgriffith$elm_ui$Internal$Style$Below = 1;
var $mdgriffith$elm_ui$Internal$Style$OnLeft = 3;
var $mdgriffith$elm_ui$Internal$Style$OnRight = 2;
var $mdgriffith$elm_ui$Internal$Style$Within = 4;
var $mdgriffith$elm_ui$Internal$Style$locations = function () {
	var loc = 0;
	var _v0 = function () {
		switch (loc) {
			case 0:
				return 0;
			case 1:
				return 0;
			case 2:
				return 0;
			case 3:
				return 0;
			case 4:
				return 0;
			default:
				return 0;
		}
	}();
	return _List_fromArray(
		[0, 1, 2, 3, 4, 5]);
}();
var $mdgriffith$elm_ui$Internal$Style$baseSheet = _List_fromArray(
	[
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		'html,body',
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'padding', '0'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		_Utils_ap(
			$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j$),
			_Utils_ap(
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.n6),
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.lO))),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'block'),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fR),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'img',
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'max-height', '100%'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'object-fit', 'cover')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gE),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'img',
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'max-width', '100%'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'object-fit', 'cover')
							]))
					]))
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j$) + ':focus',
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'outline', 'none')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.nM),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', 'auto'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'min-height', '100%'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '0'),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				_Utils_ap(
					$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j$),
					$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fR)),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fR),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Child,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.lQ),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ci),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'fixed'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '20')
							]))
					]))
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ci),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'relative'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border', 'none'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'row'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto'),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.n6),
				$mdgriffith$elm_ui$Internal$Style$elDescription),
				$mdgriffith$elm_ui$Internal$Style$Batch(
				function (fn) {
					return A2($elm$core$List$map, fn, $mdgriffith$elm_ui$Internal$Style$locations);
				}(
					function (loc) {
						switch (loc) {
							case 0:
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jD),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'bottom', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '20'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fR),
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', 'auto')
												])),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gE),
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
												])),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
							case 1:
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j9),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'bottom', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '20'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												])),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fR),
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', 'auto')
												]))
										]));
							case 2:
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.m3),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '20'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
							case 3:
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.mW),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'right', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '20'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
							case 4:
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.lQ),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
							default:
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j8),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
						}
					}))
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j$),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'relative'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border', 'none'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '0'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'row'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'resize', 'none'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-feature-settings', 'inherit'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'box-sizing', 'border-box'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'padding', '0'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-width', '0'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-style', 'solid'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-size', 'inherit'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'color', 'inherit'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-family', 'inherit'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'line-height', '1'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', 'inherit'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration', 'none'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-style', 'inherit'),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gF),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-wrap', 'wrap')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ic),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, '-moz-user-select', 'none'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, '-webkit-user-select', 'none'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, '-ms-user-select', 'none'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'user-select', 'none')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.kU),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'cursor', 'pointer')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.kV),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'cursor', 'text')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.np),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none !important')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dX),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto !important')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.bM),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '0')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.bA),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '1')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.lK, $mdgriffith$elm_ui$Internal$Style$classes.bM)) + ':hover',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '0')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.lK, $mdgriffith$elm_ui$Internal$Style$classes.bA)) + ':hover',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '1')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.ly, $mdgriffith$elm_ui$Internal$Style$classes.bM)) + ':focus',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '0')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.ly, $mdgriffith$elm_ui$Internal$Style$classes.bA)) + ':focus',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '1')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.bS, $mdgriffith$elm_ui$Internal$Style$classes.bM)) + ':active',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '0')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.bS, $mdgriffith$elm_ui$Internal$Style$classes.bA)) + ':active',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '1')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jp),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Prop,
						'transition',
						A2(
							$elm$core$String$join,
							', ',
							A2(
								$elm$core$List$map,
								function (x) {
									return x + ' 160ms';
								},
								_List_fromArray(
									['transform', 'opacity', 'filter', 'background-color', 'color', 'font-size']))))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.nU),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow', 'auto'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '1')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.nV),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow-x', 'auto'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.iP),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '1')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.nW),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow-y', 'auto'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.aZ),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '1')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.n6),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '1')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.kB),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow', 'hidden')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.kC),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow-x', 'hidden')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.kD),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow-y', 'hidden')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gD),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', 'auto')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dT),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-width', '0')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.kf),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-style', 'dashed')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.kg),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-style', 'dotted')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.kh),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-style', 'solid')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.bJ),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'pre'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline-block')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.K),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'line-height', '1.05'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'background', 'transparent'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'inherit')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.n6),
				$mdgriffith$elm_ui$Internal$Style$elDescription),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.iP),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'row'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j$),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', '0%'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jw),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.h3),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fR),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'stretch !important')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hH),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'stretch !important')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gE),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '100000')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hc),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'stretch')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'u:first-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.jP,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:first-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.jN,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jK),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-left', 'auto !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:last-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.jN,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jK),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-right', 'auto !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:only-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.jN,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jL),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:last-of-type.' + ($mdgriffith$elm_ui$Internal$Style$classes.jN + ' ~ u'),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'u:first-of-type.' + ($mdgriffith$elm_ui$Internal$Style$classes.jP + (' ~ s.' + $mdgriffith$elm_ui$Internal$Style$classes.jN)),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						$mdgriffith$elm_ui$Internal$Style$describeAlignment(
						function (alignment) {
							switch (alignment) {
								case 0:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-start')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
											]));
								case 1:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-end')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-end')
											]));
								case 2:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-end')
											]),
										_List_Nil);
								case 3:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-start')
											]),
										_List_Nil);
								case 4:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'center')
											]),
										_List_Nil);
								default:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'center')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'center')
											]));
							}
						}),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.oa),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'space-between')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.et),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'baseline')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.aZ),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'column'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j$),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', '0px'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'min-height', 'min-content'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hG),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fR),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '100000')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gE),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jx),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gD),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'u:first-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.jM,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:first-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.jO,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jL),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', '0 !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:last-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.jO,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jL),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', '0 !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:only-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.jO,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jL),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:last-of-type.' + ($mdgriffith$elm_ui$Internal$Style$classes.jO + ' ~ u'),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'u:first-of-type.' + ($mdgriffith$elm_ui$Internal$Style$classes.jM + (' ~ s.' + $mdgriffith$elm_ui$Internal$Style$classes.jO)),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						$mdgriffith$elm_ui$Internal$Style$describeAlignment(
						function (alignment) {
							switch (alignment) {
								case 0:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-start')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto')
											]));
								case 1:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-end')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto')
											]));
								case 2:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-end')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-end')
											]));
								case 3:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-start')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
											]));
								case 4:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'center')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'center')
											]));
								default:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'center')
											]),
										_List_Nil);
							}
						}),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hc),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'stretch !important')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.oa),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'space-between')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.lD),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', '-ms-grid'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'.gp',
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j$),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Supports,
						_Utils_Tuple2('display', 'grid'),
						_List_fromArray(
							[
								_Utils_Tuple2('display', 'grid')
							])),
						$mdgriffith$elm_ui$Internal$Style$gridAlignments(
						function (alignment) {
							switch (alignment) {
								case 0:
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-start')
										]);
								case 1:
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-end')
										]);
								case 2:
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-end')
										]);
								case 3:
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-start')
										]);
								case 4:
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'center')
										]);
								default:
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'center')
										]);
							}
						})
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.im),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'block'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j$ + ':first-child'),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot(
							$mdgriffith$elm_ui$Internal$Style$classes.j$ + ($mdgriffith$elm_ui$Internal$Style$selfName(3) + (':first-child + .' + $mdgriffith$elm_ui$Internal$Style$classes.j$))),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot(
							$mdgriffith$elm_ui$Internal$Style$classes.j$ + ($mdgriffith$elm_ui$Internal$Style$selfName(2) + (':first-child + .' + $mdgriffith$elm_ui$Internal$Style$classes.j$))),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important')
							])),
						$mdgriffith$elm_ui$Internal$Style$describeAlignment(
						function (alignment) {
							switch (alignment) {
								case 0:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								case 1:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								case 2:
									return _Utils_Tuple2(
										_List_Nil,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'float', 'right'),
												A2(
												$mdgriffith$elm_ui$Internal$Style$Descriptor,
												'::after',
												_List_fromArray(
													[
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'content', '\"\"'),
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'table'),
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'clear', 'both')
													]))
											]));
								case 3:
									return _Utils_Tuple2(
										_List_Nil,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'float', 'left'),
												A2(
												$mdgriffith$elm_ui$Internal$Style$Descriptor,
												'::after',
												_List_fromArray(
													[
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'content', '\"\"'),
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'table'),
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'clear', 'both')
													]))
											]));
								case 4:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								default:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
							}
						})
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.lU),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'pre-wrap !important'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'background-color', 'transparent')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.lX),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.n6),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.lW),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'pre-wrap !important'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'cursor', 'text'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.lV),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'pre-wrap !important'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'color', 'transparent')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.$9),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'block'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'normal'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow-wrap', 'break-word'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fO),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '0'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j8),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '-1')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$AllChildren,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.bJ),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'normal')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$AllChildren,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.$9),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								'::after',
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'content', 'none')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								'::before',
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'content', 'none')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$AllChildren,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.n6),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'normal'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jw),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline-block')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.lQ),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j8),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jD),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j9),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.m3),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.mW),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.bJ),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'normal')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.iP),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.aZ),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline-flex')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.lD),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline-grid')
							])),
						$mdgriffith$elm_ui$Internal$Style$describeAlignment(
						function (alignment) {
							switch (alignment) {
								case 0:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								case 1:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								case 2:
									return _Utils_Tuple2(
										_List_Nil,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'float', 'right')
											]));
								case 3:
									return _Utils_Tuple2(
										_List_Nil,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'float', 'left')
											]));
								case 4:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								default:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
							}
						})
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				'.hidden',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'none')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.oB),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '100')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.os),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '200')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ow),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '300')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.oy),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '400')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ox),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '500')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.oA),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '600')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.kd),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '700')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.or),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '800')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ot),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '900')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.l5),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-style', 'italic')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.oh),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration', 'line-through')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.oP),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration', 'underline'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration-skip-ink', 'auto'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration-skip', 'ink')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				_Utils_ap(
					$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.oP),
					$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.oh)),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration', 'line-through underline'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration-skip-ink', 'auto'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration-skip', 'ink')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.oC),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-style', 'normal')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ou),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'justify')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e5),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'justify-all')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.oq),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'center')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.oz),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'right')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ov),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'left')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				'.modal',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'fixed'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none')
					]))
			]))
	]);
var $mdgriffith$elm_ui$Internal$Style$fontVariant = function (_var) {
	return _List_fromArray(
		[
			A2(
			$mdgriffith$elm_ui$Internal$Style$Class,
			'.v-' + _var,
			_List_fromArray(
				[
					A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-feature-settings', '\"' + (_var + '\"'))
				])),
			A2(
			$mdgriffith$elm_ui$Internal$Style$Class,
			'.v-' + (_var + '-off'),
			_List_fromArray(
				[
					A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-feature-settings', '\"' + (_var + '\" 0'))
				]))
		]);
};
var $mdgriffith$elm_ui$Internal$Style$commonValues = $elm$core$List$concat(
	_List_fromArray(
		[
			A2(
			$elm$core$List$map,
			function (x) {
				return A2(
					$mdgriffith$elm_ui$Internal$Style$Class,
					'.border-' + $elm$core$String$fromInt(x),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Style$Prop,
							'border-width',
							$elm$core$String$fromInt(x) + 'px')
						]));
			},
			A2($elm$core$List$range, 0, 6)),
			A2(
			$elm$core$List$map,
			function (i) {
				return A2(
					$mdgriffith$elm_ui$Internal$Style$Class,
					'.font-size-' + $elm$core$String$fromInt(i),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Style$Prop,
							'font-size',
							$elm$core$String$fromInt(i) + 'px')
						]));
			},
			A2($elm$core$List$range, 8, 32)),
			A2(
			$elm$core$List$map,
			function (i) {
				return A2(
					$mdgriffith$elm_ui$Internal$Style$Class,
					'.p-' + $elm$core$String$fromInt(i),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Style$Prop,
							'padding',
							$elm$core$String$fromInt(i) + 'px')
						]));
			},
			A2($elm$core$List$range, 0, 24)),
			_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Internal$Style$Class,
				'.v-smcp',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-variant', 'small-caps')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Class,
				'.v-smcp-off',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-variant', 'normal')
					]))
			]),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('zero'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('onum'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('liga'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('dlig'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('ordn'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('tnum'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('afrc'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('frac')
		]));
var $mdgriffith$elm_ui$Internal$Style$explainer = '\n.explain {\n    border: 6px solid rgb(174, 121, 15) !important;\n}\n.explain > .' + ($mdgriffith$elm_ui$Internal$Style$classes.j$ + (' {\n    border: 4px dashed rgb(0, 151, 167) !important;\n}\n\n.ctr {\n    border: none !important;\n}\n.explain > .ctr > .' + ($mdgriffith$elm_ui$Internal$Style$classes.j$ + ' {\n    border: 4px dashed rgb(0, 151, 167) !important;\n}\n\n')));
var $mdgriffith$elm_ui$Internal$Style$inputTextReset = '\ninput[type="search"],\ninput[type="search"]::-webkit-search-decoration,\ninput[type="search"]::-webkit-search-cancel-button,\ninput[type="search"]::-webkit-search-results-button,\ninput[type="search"]::-webkit-search-results-decoration {\n  -webkit-appearance:none;\n}\n';
var $mdgriffith$elm_ui$Internal$Style$sliderReset = '\ninput[type=range] {\n  -webkit-appearance: none; \n  background: transparent;\n  position:absolute;\n  left:0;\n  top:0;\n  z-index:10;\n  width: 100%;\n  outline: dashed 1px;\n  height: 100%;\n  opacity: 0;\n}\n';
var $mdgriffith$elm_ui$Internal$Style$thumbReset = '\ninput[type=range]::-webkit-slider-thumb {\n    -webkit-appearance: none;\n    opacity: 0.5;\n    width: 80px;\n    height: 80px;\n    background-color: black;\n    border:none;\n    border-radius: 5px;\n}\ninput[type=range]::-moz-range-thumb {\n    opacity: 0.5;\n    width: 80px;\n    height: 80px;\n    background-color: black;\n    border:none;\n    border-radius: 5px;\n}\ninput[type=range]::-ms-thumb {\n    opacity: 0.5;\n    width: 80px;\n    height: 80px;\n    background-color: black;\n    border:none;\n    border-radius: 5px;\n}\ninput[type=range][orient=vertical]{\n    writing-mode: bt-lr; /* IE */\n    -webkit-appearance: slider-vertical;  /* WebKit */\n}\n';
var $mdgriffith$elm_ui$Internal$Style$trackReset = '\ninput[type=range]::-moz-range-track {\n    background: transparent;\n    cursor: pointer;\n}\ninput[type=range]::-ms-track {\n    background: transparent;\n    cursor: pointer;\n}\ninput[type=range]::-webkit-slider-runnable-track {\n    background: transparent;\n    cursor: pointer;\n}\n';
var $mdgriffith$elm_ui$Internal$Style$overrides = '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j$) + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.iP) + (' > ' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j$) + (' { flex-basis: auto !important; } ' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j$) + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.iP) + (' > ' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j$) + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hc) + (' { flex-basis: auto !important; }}' + ($mdgriffith$elm_ui$Internal$Style$inputTextReset + ($mdgriffith$elm_ui$Internal$Style$sliderReset + ($mdgriffith$elm_ui$Internal$Style$trackReset + ($mdgriffith$elm_ui$Internal$Style$thumbReset + $mdgriffith$elm_ui$Internal$Style$explainer)))))))))))))));
var $mdgriffith$elm_ui$Internal$Style$Intermediate = $elm$core$Basics$identity;
var $mdgriffith$elm_ui$Internal$Style$emptyIntermediate = F2(
	function (selector, closing) {
		return {d$: closing, H: _List_Nil, bd: _List_Nil, aC: selector};
	});
var $mdgriffith$elm_ui$Internal$Style$renderRules = F2(
	function (_v0, rulesToRender) {
		var parent = _v0;
		var generateIntermediates = F2(
			function (rule, rendered) {
				switch (rule.$) {
					case 0:
						var name = rule.a;
						var val = rule.b;
						return _Utils_update(
							rendered,
							{
								bd: A2(
									$elm$core$List$cons,
									_Utils_Tuple2(name, val),
									rendered.bd)
							});
					case 3:
						var _v2 = rule.a;
						var prop = _v2.a;
						var value = _v2.b;
						var props = rule.b;
						return _Utils_update(
							rendered,
							{
								H: A2(
									$elm$core$List$cons,
									{d$: '\n}', H: _List_Nil, bd: props, aC: '@supports (' + (prop + (':' + (value + (') {' + parent.aC))))},
									rendered.H)
							});
					case 5:
						var selector = rule.a;
						var adjRules = rule.b;
						return _Utils_update(
							rendered,
							{
								H: A2(
									$elm$core$List$cons,
									A2(
										$mdgriffith$elm_ui$Internal$Style$renderRules,
										A2($mdgriffith$elm_ui$Internal$Style$emptyIntermediate, parent.aC + (' + ' + selector), ''),
										adjRules),
									rendered.H)
							});
					case 1:
						var child = rule.a;
						var childRules = rule.b;
						return _Utils_update(
							rendered,
							{
								H: A2(
									$elm$core$List$cons,
									A2(
										$mdgriffith$elm_ui$Internal$Style$renderRules,
										A2($mdgriffith$elm_ui$Internal$Style$emptyIntermediate, parent.aC + (' > ' + child), ''),
										childRules),
									rendered.H)
							});
					case 2:
						var child = rule.a;
						var childRules = rule.b;
						return _Utils_update(
							rendered,
							{
								H: A2(
									$elm$core$List$cons,
									A2(
										$mdgriffith$elm_ui$Internal$Style$renderRules,
										A2($mdgriffith$elm_ui$Internal$Style$emptyIntermediate, parent.aC + (' ' + child), ''),
										childRules),
									rendered.H)
							});
					case 4:
						var descriptor = rule.a;
						var descriptorRules = rule.b;
						return _Utils_update(
							rendered,
							{
								H: A2(
									$elm$core$List$cons,
									A2(
										$mdgriffith$elm_ui$Internal$Style$renderRules,
										A2(
											$mdgriffith$elm_ui$Internal$Style$emptyIntermediate,
											_Utils_ap(parent.aC, descriptor),
											''),
										descriptorRules),
									rendered.H)
							});
					default:
						var batched = rule.a;
						return _Utils_update(
							rendered,
							{
								H: A2(
									$elm$core$List$cons,
									A2(
										$mdgriffith$elm_ui$Internal$Style$renderRules,
										A2($mdgriffith$elm_ui$Internal$Style$emptyIntermediate, parent.aC, ''),
										batched),
									rendered.H)
							});
				}
			});
		return A3($elm$core$List$foldr, generateIntermediates, parent, rulesToRender);
	});
var $mdgriffith$elm_ui$Internal$Style$renderCompact = function (styleClasses) {
	var renderValues = function (values) {
		return $elm$core$String$concat(
			A2(
				$elm$core$List$map,
				function (_v3) {
					var x = _v3.a;
					var y = _v3.b;
					return x + (':' + (y + ';'));
				},
				values));
	};
	var renderClass = function (rule) {
		var _v2 = rule.bd;
		if (!_v2.b) {
			return '';
		} else {
			return rule.aC + ('{' + (renderValues(rule.bd) + (rule.d$ + '}')));
		}
	};
	var renderIntermediate = function (_v0) {
		var rule = _v0;
		return _Utils_ap(
			renderClass(rule),
			$elm$core$String$concat(
				A2($elm$core$List$map, renderIntermediate, rule.H)));
	};
	return $elm$core$String$concat(
		A2(
			$elm$core$List$map,
			renderIntermediate,
			A3(
				$elm$core$List$foldr,
				F2(
					function (_v1, existing) {
						var name = _v1.a;
						var styleRules = _v1.b;
						return A2(
							$elm$core$List$cons,
							A2(
								$mdgriffith$elm_ui$Internal$Style$renderRules,
								A2($mdgriffith$elm_ui$Internal$Style$emptyIntermediate, name, ''),
								styleRules),
							existing);
					}),
				_List_Nil,
				styleClasses)));
};
var $mdgriffith$elm_ui$Internal$Style$rules = _Utils_ap(
	$mdgriffith$elm_ui$Internal$Style$overrides,
	$mdgriffith$elm_ui$Internal$Style$renderCompact(
		_Utils_ap($mdgriffith$elm_ui$Internal$Style$baseSheet, $mdgriffith$elm_ui$Internal$Style$commonValues)));
var $mdgriffith$elm_ui$Internal$Model$staticRoot = function (opts) {
	var _v0 = opts.ez;
	switch (_v0) {
		case 0:
			return A3(
				$elm$virtual_dom$VirtualDom$node,
				'div',
				_List_Nil,
				_List_fromArray(
					[
						A3(
						$elm$virtual_dom$VirtualDom$node,
						'style',
						_List_Nil,
						_List_fromArray(
							[
								$elm$virtual_dom$VirtualDom$text($mdgriffith$elm_ui$Internal$Style$rules)
							]))
					]));
		case 1:
			return $elm$virtual_dom$VirtualDom$text('');
		default:
			return A3(
				$elm$virtual_dom$VirtualDom$node,
				'elm-ui-static-rules',
				_List_fromArray(
					[
						A2(
						$elm$virtual_dom$VirtualDom$property,
						'rules',
						$elm$json$Json$Encode$string($mdgriffith$elm_ui$Internal$Style$rules))
					]),
				_List_Nil);
	}
};
var $mdgriffith$elm_ui$Internal$Model$fontName = function (font) {
	switch (font.$) {
		case 0:
			return 'serif';
		case 1:
			return 'sans-serif';
		case 2:
			return 'monospace';
		case 3:
			var name = font.a;
			return '\"' + (name + '\"');
		case 4:
			var name = font.a;
			var url = font.b;
			return '\"' + (name + '\"');
		default:
			var name = font.a.mD;
			return '\"' + (name + '\"');
	}
};
var $mdgriffith$elm_ui$Internal$Model$isSmallCaps = function (_var) {
	switch (_var.$) {
		case 0:
			var name = _var.a;
			return name === 'smcp';
		case 1:
			var name = _var.a;
			return false;
		default:
			var name = _var.a;
			var index = _var.b;
			return (name === 'smcp') && (index === 1);
	}
};
var $mdgriffith$elm_ui$Internal$Model$hasSmallCaps = function (typeface) {
	if (typeface.$ === 5) {
		var font = typeface.a;
		return A2($elm$core$List$any, $mdgriffith$elm_ui$Internal$Model$isSmallCaps, font.js);
	} else {
		return false;
	}
};
var $mdgriffith$elm_ui$Internal$Model$renderProps = F3(
	function (force, _v0, existing) {
		var key = _v0.a;
		var val = _v0.b;
		return force ? (existing + ('\n  ' + (key + (': ' + (val + ' !important;'))))) : (existing + ('\n  ' + (key + (': ' + (val + ';')))));
	});
var $mdgriffith$elm_ui$Internal$Model$renderStyle = F4(
	function (options, maybePseudo, selector, props) {
		if (maybePseudo.$ === 1) {
			return _List_fromArray(
				[
					selector + ('{' + (A3(
					$elm$core$List$foldl,
					$mdgriffith$elm_ui$Internal$Model$renderProps(false),
					'',
					props) + '\n}'))
				]);
		} else {
			var pseudo = maybePseudo.a;
			switch (pseudo) {
				case 1:
					var _v2 = options.lK;
					switch (_v2) {
						case 0:
							return _List_Nil;
						case 2:
							return _List_fromArray(
								[
									selector + ('-hv {' + (A3(
									$elm$core$List$foldl,
									$mdgriffith$elm_ui$Internal$Model$renderProps(true),
									'',
									props) + '\n}'))
								]);
						default:
							return _List_fromArray(
								[
									selector + ('-hv:hover {' + (A3(
									$elm$core$List$foldl,
									$mdgriffith$elm_ui$Internal$Model$renderProps(false),
									'',
									props) + '\n}'))
								]);
					}
				case 0:
					var renderedProps = A3(
						$elm$core$List$foldl,
						$mdgriffith$elm_ui$Internal$Model$renderProps(false),
						'',
						props);
					return _List_fromArray(
						[
							selector + ('-fs:focus {' + (renderedProps + '\n}')),
							('.' + ($mdgriffith$elm_ui$Internal$Style$classes.j$ + (':focus ' + (selector + '-fs  {')))) + (renderedProps + '\n}'),
							(selector + '-fs:focus-within {') + (renderedProps + '\n}'),
							('.ui-slide-bar:focus + ' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j$) + (' .focusable-thumb' + (selector + '-fs {')))) + (renderedProps + '\n}')
						]);
				default:
					return _List_fromArray(
						[
							selector + ('-act:active {' + (A3(
							$elm$core$List$foldl,
							$mdgriffith$elm_ui$Internal$Model$renderProps(false),
							'',
							props) + '\n}'))
						]);
			}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$renderVariant = function (_var) {
	switch (_var.$) {
		case 0:
			var name = _var.a;
			return '\"' + (name + '\"');
		case 1:
			var name = _var.a;
			return '\"' + (name + '\" 0');
		default:
			var name = _var.a;
			var index = _var.b;
			return '\"' + (name + ('\" ' + $elm$core$String$fromInt(index)));
	}
};
var $mdgriffith$elm_ui$Internal$Model$renderVariants = function (typeface) {
	if (typeface.$ === 5) {
		var font = typeface.a;
		return $elm$core$Maybe$Just(
			A2(
				$elm$core$String$join,
				', ',
				A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$renderVariant, font.js)));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $mdgriffith$elm_ui$Internal$Model$transformValue = function (transform) {
	switch (transform.$) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			var _v1 = transform.a;
			var x = _v1.a;
			var y = _v1.b;
			var z = _v1.c;
			return $elm$core$Maybe$Just(
				'translate3d(' + ($elm$core$String$fromFloat(x) + ('px, ' + ($elm$core$String$fromFloat(y) + ('px, ' + ($elm$core$String$fromFloat(z) + 'px)'))))));
		default:
			var _v2 = transform.a;
			var tx = _v2.a;
			var ty = _v2.b;
			var tz = _v2.c;
			var _v3 = transform.b;
			var sx = _v3.a;
			var sy = _v3.b;
			var sz = _v3.c;
			var _v4 = transform.c;
			var ox = _v4.a;
			var oy = _v4.b;
			var oz = _v4.c;
			var angle = transform.d;
			var translate = 'translate3d(' + ($elm$core$String$fromFloat(tx) + ('px, ' + ($elm$core$String$fromFloat(ty) + ('px, ' + ($elm$core$String$fromFloat(tz) + 'px)')))));
			var scale = 'scale3d(' + ($elm$core$String$fromFloat(sx) + (', ' + ($elm$core$String$fromFloat(sy) + (', ' + ($elm$core$String$fromFloat(sz) + ')')))));
			var rotate = 'rotate3d(' + ($elm$core$String$fromFloat(ox) + (', ' + ($elm$core$String$fromFloat(oy) + (', ' + ($elm$core$String$fromFloat(oz) + (', ' + ($elm$core$String$fromFloat(angle) + 'rad)')))))));
			return $elm$core$Maybe$Just(translate + (' ' + (scale + (' ' + rotate))));
	}
};
var $mdgriffith$elm_ui$Internal$Model$renderStyleRule = F3(
	function (options, rule, maybePseudo) {
		switch (rule.$) {
			case 0:
				var selector = rule.a;
				var props = rule.b;
				return A4($mdgriffith$elm_ui$Internal$Model$renderStyle, options, maybePseudo, selector, props);
			case 13:
				var name = rule.a;
				var prop = rule.b;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					'.' + name,
					_List_fromArray(
						[
							A2($mdgriffith$elm_ui$Internal$Model$Property, 'box-shadow', prop)
						]));
			case 12:
				var name = rule.a;
				var transparency = rule.b;
				var opacity = A2(
					$elm$core$Basics$max,
					0,
					A2($elm$core$Basics$min, 1, 1 - transparency));
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					'.' + name,
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Model$Property,
							'opacity',
							$elm$core$String$fromFloat(opacity))
						]));
			case 2:
				var i = rule.a;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					'.font-size-' + $elm$core$String$fromInt(i),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Model$Property,
							'font-size',
							$elm$core$String$fromInt(i) + 'px')
						]));
			case 1:
				var name = rule.a;
				var typefaces = rule.b;
				var features = A2(
					$elm$core$String$join,
					', ',
					A2($elm$core$List$filterMap, $mdgriffith$elm_ui$Internal$Model$renderVariants, typefaces));
				var families = _List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Model$Property,
						'font-family',
						A2(
							$elm$core$String$join,
							', ',
							A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$fontName, typefaces))),
						A2($mdgriffith$elm_ui$Internal$Model$Property, 'font-feature-settings', features),
						A2(
						$mdgriffith$elm_ui$Internal$Model$Property,
						'font-variant',
						A2($elm$core$List$any, $mdgriffith$elm_ui$Internal$Model$hasSmallCaps, typefaces) ? 'small-caps' : 'normal')
					]);
				return A4($mdgriffith$elm_ui$Internal$Model$renderStyle, options, maybePseudo, '.' + name, families);
			case 3:
				var _class = rule.a;
				var prop = rule.b;
				var val = rule.c;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					'.' + _class,
					_List_fromArray(
						[
							A2($mdgriffith$elm_ui$Internal$Model$Property, prop, val)
						]));
			case 4:
				var _class = rule.a;
				var prop = rule.b;
				var color = rule.c;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					'.' + _class,
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Model$Property,
							prop,
							$mdgriffith$elm_ui$Internal$Model$formatColor(color))
						]));
			case 5:
				var cls = rule.a;
				var x = rule.b;
				var y = rule.c;
				var yPx = $elm$core$String$fromInt(y) + 'px';
				var xPx = $elm$core$String$fromInt(x) + 'px';
				var single = '.' + $mdgriffith$elm_ui$Internal$Style$classes.n6;
				var row = '.' + $mdgriffith$elm_ui$Internal$Style$classes.iP;
				var wrappedRow = '.' + ($mdgriffith$elm_ui$Internal$Style$classes.gF + row);
				var right = '.' + $mdgriffith$elm_ui$Internal$Style$classes.gP;
				var paragraph = '.' + $mdgriffith$elm_ui$Internal$Style$classes.$9;
				var page = '.' + $mdgriffith$elm_ui$Internal$Style$classes.im;
				var left = '.' + $mdgriffith$elm_ui$Internal$Style$classes.gO;
				var halfY = $elm$core$String$fromFloat(y / 2) + 'px';
				var halfX = $elm$core$String$fromFloat(x / 2) + 'px';
				var column = '.' + $mdgriffith$elm_ui$Internal$Style$classes.aZ;
				var _class = '.' + cls;
				var any = '.' + $mdgriffith$elm_ui$Internal$Style$classes.j$;
				return $elm$core$List$concat(
					_List_fromArray(
						[
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (row + (' > ' + (any + (' + ' + any)))),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-left', xPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (wrappedRow + (' > ' + any)),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin', halfY + (' ' + halfX))
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (column + (' > ' + (any + (' + ' + any)))),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-top', yPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (page + (' > ' + (any + (' + ' + any)))),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-top', yPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (page + (' > ' + left)),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-right', xPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (page + (' > ' + right)),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-left', xPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_Utils_ap(_class, paragraph),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Internal$Model$Property,
									'line-height',
									'calc(1em + ' + ($elm$core$String$fromInt(y) + 'px)'))
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							'textarea' + (any + _class),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Internal$Model$Property,
									'line-height',
									'calc(1em + ' + ($elm$core$String$fromInt(y) + 'px)')),
									A2(
									$mdgriffith$elm_ui$Internal$Model$Property,
									'height',
									'calc(100% + ' + ($elm$core$String$fromInt(y) + 'px)'))
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (paragraph + (' > ' + left)),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-right', xPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (paragraph + (' > ' + right)),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-left', xPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (paragraph + '::after'),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'content', '\'\''),
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'display', 'block'),
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'height', '0'),
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'width', '0'),
									A2(
									$mdgriffith$elm_ui$Internal$Model$Property,
									'margin-top',
									$elm$core$String$fromInt((-1) * ((y / 2) | 0)) + 'px')
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (paragraph + '::before'),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'content', '\'\''),
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'display', 'block'),
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'height', '0'),
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'width', '0'),
									A2(
									$mdgriffith$elm_ui$Internal$Model$Property,
									'margin-bottom',
									$elm$core$String$fromInt((-1) * ((y / 2) | 0)) + 'px')
								]))
						]));
			case 7:
				var cls = rule.a;
				var top = rule.b;
				var right = rule.c;
				var bottom = rule.d;
				var left = rule.e;
				var _class = '.' + cls;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					_class,
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Model$Property,
							'padding',
							$elm$core$String$fromFloat(top) + ('px ' + ($elm$core$String$fromFloat(right) + ('px ' + ($elm$core$String$fromFloat(bottom) + ('px ' + ($elm$core$String$fromFloat(left) + 'px')))))))
						]));
			case 6:
				var cls = rule.a;
				var top = rule.b;
				var right = rule.c;
				var bottom = rule.d;
				var left = rule.e;
				var _class = '.' + cls;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					_class,
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Model$Property,
							'border-width',
							$elm$core$String$fromInt(top) + ('px ' + ($elm$core$String$fromInt(right) + ('px ' + ($elm$core$String$fromInt(bottom) + ('px ' + ($elm$core$String$fromInt(left) + 'px')))))))
						]));
			case 8:
				var template = rule.a;
				var toGridLengthHelper = F3(
					function (minimum, maximum, x) {
						toGridLengthHelper:
						while (true) {
							switch (x.$) {
								case 0:
									var px = x.a;
									return $elm$core$String$fromInt(px) + 'px';
								case 1:
									var _v2 = _Utils_Tuple2(minimum, maximum);
									if (_v2.a.$ === 1) {
										if (_v2.b.$ === 1) {
											var _v3 = _v2.a;
											var _v4 = _v2.b;
											return 'max-content';
										} else {
											var _v6 = _v2.a;
											var maxSize = _v2.b.a;
											return 'minmax(max-content, ' + ($elm$core$String$fromInt(maxSize) + 'px)');
										}
									} else {
										if (_v2.b.$ === 1) {
											var minSize = _v2.a.a;
											var _v5 = _v2.b;
											return 'minmax(' + ($elm$core$String$fromInt(minSize) + ('px, ' + 'max-content)'));
										} else {
											var minSize = _v2.a.a;
											var maxSize = _v2.b.a;
											return 'minmax(' + ($elm$core$String$fromInt(minSize) + ('px, ' + ($elm$core$String$fromInt(maxSize) + 'px)')));
										}
									}
								case 2:
									var i = x.a;
									var _v7 = _Utils_Tuple2(minimum, maximum);
									if (_v7.a.$ === 1) {
										if (_v7.b.$ === 1) {
											var _v8 = _v7.a;
											var _v9 = _v7.b;
											return $elm$core$String$fromInt(i) + 'fr';
										} else {
											var _v11 = _v7.a;
											var maxSize = _v7.b.a;
											return 'minmax(max-content, ' + ($elm$core$String$fromInt(maxSize) + 'px)');
										}
									} else {
										if (_v7.b.$ === 1) {
											var minSize = _v7.a.a;
											var _v10 = _v7.b;
											return 'minmax(' + ($elm$core$String$fromInt(minSize) + ('px, ' + ($elm$core$String$fromInt(i) + ('fr' + 'fr)'))));
										} else {
											var minSize = _v7.a.a;
											var maxSize = _v7.b.a;
											return 'minmax(' + ($elm$core$String$fromInt(minSize) + ('px, ' + ($elm$core$String$fromInt(maxSize) + 'px)')));
										}
									}
								case 3:
									var m = x.a;
									var len = x.b;
									var $temp$minimum = $elm$core$Maybe$Just(m),
										$temp$maximum = maximum,
										$temp$x = len;
									minimum = $temp$minimum;
									maximum = $temp$maximum;
									x = $temp$x;
									continue toGridLengthHelper;
								default:
									var m = x.a;
									var len = x.b;
									var $temp$minimum = minimum,
										$temp$maximum = $elm$core$Maybe$Just(m),
										$temp$x = len;
									minimum = $temp$minimum;
									maximum = $temp$maximum;
									x = $temp$x;
									continue toGridLengthHelper;
							}
						}
					});
				var toGridLength = function (x) {
					return A3(toGridLengthHelper, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, x);
				};
				var xSpacing = toGridLength(template.gn.a);
				var ySpacing = toGridLength(template.gn.b);
				var rows = function (x) {
					return 'grid-template-rows: ' + (x + ';');
				}(
					A2(
						$elm$core$String$join,
						' ',
						A2($elm$core$List$map, toGridLength, template.nP)));
				var msRows = function (x) {
					return '-ms-grid-rows: ' + (x + ';');
				}(
					A2(
						$elm$core$String$join,
						ySpacing,
						A2($elm$core$List$map, toGridLength, template.cP)));
				var msColumns = function (x) {
					return '-ms-grid-columns: ' + (x + ';');
				}(
					A2(
						$elm$core$String$join,
						ySpacing,
						A2($elm$core$List$map, toGridLength, template.cP)));
				var gapY = 'grid-row-gap:' + (toGridLength(template.gn.b) + ';');
				var gapX = 'grid-column-gap:' + (toGridLength(template.gn.a) + ';');
				var columns = function (x) {
					return 'grid-template-columns: ' + (x + ';');
				}(
					A2(
						$elm$core$String$join,
						' ',
						A2($elm$core$List$map, toGridLength, template.cP)));
				var _class = '.grid-rows-' + (A2(
					$elm$core$String$join,
					'-',
					A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$lengthClassName, template.nP)) + ('-cols-' + (A2(
					$elm$core$String$join,
					'-',
					A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$lengthClassName, template.cP)) + ('-space-x-' + ($mdgriffith$elm_ui$Internal$Model$lengthClassName(template.gn.a) + ('-space-y-' + $mdgriffith$elm_ui$Internal$Model$lengthClassName(template.gn.b)))))));
				var modernGrid = _class + ('{' + (columns + (rows + (gapX + (gapY + '}')))));
				var supports = '@supports (display:grid) {' + (modernGrid + '}');
				var base = _class + ('{' + (msColumns + (msRows + '}')));
				return _List_fromArray(
					[base, supports]);
			case 9:
				var position = rule.a;
				var msPosition = A2(
					$elm$core$String$join,
					' ',
					_List_fromArray(
						[
							'-ms-grid-row: ' + ($elm$core$String$fromInt(position.iP) + ';'),
							'-ms-grid-row-span: ' + ($elm$core$String$fromInt(position.en) + ';'),
							'-ms-grid-column: ' + ($elm$core$String$fromInt(position.ft) + ';'),
							'-ms-grid-column-span: ' + ($elm$core$String$fromInt(position.fg) + ';')
						]));
				var modernPosition = A2(
					$elm$core$String$join,
					' ',
					_List_fromArray(
						[
							'grid-row: ' + ($elm$core$String$fromInt(position.iP) + (' / ' + ($elm$core$String$fromInt(position.iP + position.en) + ';'))),
							'grid-column: ' + ($elm$core$String$fromInt(position.ft) + (' / ' + ($elm$core$String$fromInt(position.ft + position.fg) + ';')))
						]));
				var _class = '.grid-pos-' + ($elm$core$String$fromInt(position.iP) + ('-' + ($elm$core$String$fromInt(position.ft) + ('-' + ($elm$core$String$fromInt(position.fg) + ('-' + $elm$core$String$fromInt(position.en)))))));
				var modernGrid = _class + ('{' + (modernPosition + '}'));
				var supports = '@supports (display:grid) {' + (modernGrid + '}');
				var base = _class + ('{' + (msPosition + '}'));
				return _List_fromArray(
					[base, supports]);
			case 11:
				var _class = rule.a;
				var styles = rule.b;
				var renderPseudoRule = function (style) {
					return A3(
						$mdgriffith$elm_ui$Internal$Model$renderStyleRule,
						options,
						style,
						$elm$core$Maybe$Just(_class));
				};
				return A2($elm$core$List$concatMap, renderPseudoRule, styles);
			default:
				var transform = rule.a;
				var val = $mdgriffith$elm_ui$Internal$Model$transformValue(transform);
				var _class = $mdgriffith$elm_ui$Internal$Model$transformClass(transform);
				var _v12 = _Utils_Tuple2(_class, val);
				if ((!_v12.a.$) && (!_v12.b.$)) {
					var cls = _v12.a.a;
					var v = _v12.b.a;
					return A4(
						$mdgriffith$elm_ui$Internal$Model$renderStyle,
						options,
						maybePseudo,
						'.' + cls,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Model$Property, 'transform', v)
							]));
				} else {
					return _List_Nil;
				}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$encodeStyles = F2(
	function (options, stylesheet) {
		return $elm$json$Json$Encode$object(
			A2(
				$elm$core$List$map,
				function (style) {
					var styled = A3($mdgriffith$elm_ui$Internal$Model$renderStyleRule, options, style, $elm$core$Maybe$Nothing);
					return _Utils_Tuple2(
						$mdgriffith$elm_ui$Internal$Model$getStyleName(style),
						A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, styled));
				},
				stylesheet));
	});
var $mdgriffith$elm_ui$Internal$Model$bracket = F2(
	function (selector, rules) {
		var renderPair = function (_v0) {
			var name = _v0.a;
			var val = _v0.b;
			return name + (': ' + (val + ';'));
		};
		return selector + (' {' + (A2(
			$elm$core$String$join,
			'',
			A2($elm$core$List$map, renderPair, rules)) + '}'));
	});
var $mdgriffith$elm_ui$Internal$Model$fontRule = F3(
	function (name, modifier, _v0) {
		var parentAdj = _v0.a;
		var textAdjustment = _v0.b;
		return _List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Model$bracket, '.' + (name + ('.' + (modifier + (', ' + ('.' + (name + (' .' + modifier))))))), parentAdj),
				A2($mdgriffith$elm_ui$Internal$Model$bracket, '.' + (name + ('.' + (modifier + ('> .' + ($mdgriffith$elm_ui$Internal$Style$classes.bJ + (', .' + (name + (' .' + (modifier + (' > .' + $mdgriffith$elm_ui$Internal$Style$classes.bJ)))))))))), textAdjustment)
			]);
	});
var $mdgriffith$elm_ui$Internal$Model$renderFontAdjustmentRule = F3(
	function (fontToAdjust, _v0, otherFontName) {
		var full = _v0.a;
		var capital = _v0.b;
		var name = _Utils_eq(fontToAdjust, otherFontName) ? fontToAdjust : (otherFontName + (' .' + fontToAdjust));
		return A2(
			$elm$core$String$join,
			' ',
			_Utils_ap(
				A3($mdgriffith$elm_ui$Internal$Model$fontRule, name, $mdgriffith$elm_ui$Internal$Style$classes.n7, capital),
				A3($mdgriffith$elm_ui$Internal$Model$fontRule, name, $mdgriffith$elm_ui$Internal$Style$classes.lz, full)));
	});
var $mdgriffith$elm_ui$Internal$Model$renderNullAdjustmentRule = F2(
	function (fontToAdjust, otherFontName) {
		var name = _Utils_eq(fontToAdjust, otherFontName) ? fontToAdjust : (otherFontName + (' .' + fontToAdjust));
		return A2(
			$elm$core$String$join,
			' ',
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Internal$Model$bracket,
					'.' + (name + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.n7 + (', ' + ('.' + (name + (' .' + $mdgriffith$elm_ui$Internal$Style$classes.n7))))))),
					_List_fromArray(
						[
							_Utils_Tuple2('line-height', '1')
						])),
					A2(
					$mdgriffith$elm_ui$Internal$Model$bracket,
					'.' + (name + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.n7 + ('> .' + ($mdgriffith$elm_ui$Internal$Style$classes.bJ + (', .' + (name + (' .' + ($mdgriffith$elm_ui$Internal$Style$classes.n7 + (' > .' + $mdgriffith$elm_ui$Internal$Style$classes.bJ)))))))))),
					_List_fromArray(
						[
							_Utils_Tuple2('vertical-align', '0'),
							_Utils_Tuple2('line-height', '1')
						]))
				]));
	});
var $mdgriffith$elm_ui$Internal$Model$adjust = F3(
	function (size, height, vertical) {
		return {en: height / size, gl: size, jt: vertical};
	});
var $mdgriffith$elm_ui$Internal$Model$convertAdjustment = function (adjustment) {
	var lines = _List_fromArray(
		[adjustment.kq, adjustment.j7, adjustment.ld, adjustment.mh]);
	var lineHeight = 1.5;
	var normalDescender = (lineHeight - 1) / 2;
	var oldMiddle = lineHeight / 2;
	var descender = A2(
		$elm$core$Maybe$withDefault,
		adjustment.ld,
		$elm$core$List$minimum(lines));
	var newBaseline = A2(
		$elm$core$Maybe$withDefault,
		adjustment.j7,
		$elm$core$List$minimum(
			A2(
				$elm$core$List$filter,
				function (x) {
					return !_Utils_eq(x, descender);
				},
				lines)));
	var base = lineHeight;
	var ascender = A2(
		$elm$core$Maybe$withDefault,
		adjustment.kq,
		$elm$core$List$maximum(lines));
	var capitalSize = 1 / (ascender - newBaseline);
	var capitalVertical = 1 - ascender;
	var fullSize = 1 / (ascender - descender);
	var fullVertical = 1 - ascender;
	var newCapitalMiddle = ((ascender - newBaseline) / 2) + newBaseline;
	var newFullMiddle = ((ascender - descender) / 2) + descender;
	return {
		kq: A3($mdgriffith$elm_ui$Internal$Model$adjust, capitalSize, ascender - newBaseline, capitalVertical),
		hy: A3($mdgriffith$elm_ui$Internal$Model$adjust, fullSize, ascender - descender, fullVertical)
	};
};
var $mdgriffith$elm_ui$Internal$Model$fontAdjustmentRules = function (converted) {
	return _Utils_Tuple2(
		_List_fromArray(
			[
				_Utils_Tuple2('display', 'block')
			]),
		_List_fromArray(
			[
				_Utils_Tuple2('display', 'inline-block'),
				_Utils_Tuple2(
				'line-height',
				$elm$core$String$fromFloat(converted.en)),
				_Utils_Tuple2(
				'vertical-align',
				$elm$core$String$fromFloat(converted.jt) + 'em'),
				_Utils_Tuple2(
				'font-size',
				$elm$core$String$fromFloat(converted.gl) + 'em')
			]));
};
var $mdgriffith$elm_ui$Internal$Model$typefaceAdjustment = function (typefaces) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (face, found) {
				if (found.$ === 1) {
					if (face.$ === 5) {
						var _with = face.a;
						var _v2 = _with.jG;
						if (_v2.$ === 1) {
							return found;
						} else {
							var adjustment = _v2.a;
							return $elm$core$Maybe$Just(
								_Utils_Tuple2(
									$mdgriffith$elm_ui$Internal$Model$fontAdjustmentRules(
										function ($) {
											return $.hy;
										}(
											$mdgriffith$elm_ui$Internal$Model$convertAdjustment(adjustment))),
									$mdgriffith$elm_ui$Internal$Model$fontAdjustmentRules(
										function ($) {
											return $.kq;
										}(
											$mdgriffith$elm_ui$Internal$Model$convertAdjustment(adjustment)))));
						}
					} else {
						return found;
					}
				} else {
					return found;
				}
			}),
		$elm$core$Maybe$Nothing,
		typefaces);
};
var $mdgriffith$elm_ui$Internal$Model$renderTopLevelValues = function (rules) {
	var withImport = function (font) {
		if (font.$ === 4) {
			var url = font.b;
			return $elm$core$Maybe$Just('@import url(\'' + (url + '\');'));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	};
	var fontImports = function (_v2) {
		var name = _v2.a;
		var typefaces = _v2.b;
		var imports = A2(
			$elm$core$String$join,
			'\n',
			A2($elm$core$List$filterMap, withImport, typefaces));
		return imports;
	};
	var allNames = A2($elm$core$List$map, $elm$core$Tuple$first, rules);
	var fontAdjustments = function (_v1) {
		var name = _v1.a;
		var typefaces = _v1.b;
		var _v0 = $mdgriffith$elm_ui$Internal$Model$typefaceAdjustment(typefaces);
		if (_v0.$ === 1) {
			return A2(
				$elm$core$String$join,
				'',
				A2(
					$elm$core$List$map,
					$mdgriffith$elm_ui$Internal$Model$renderNullAdjustmentRule(name),
					allNames));
		} else {
			var adjustment = _v0.a;
			return A2(
				$elm$core$String$join,
				'',
				A2(
					$elm$core$List$map,
					A2($mdgriffith$elm_ui$Internal$Model$renderFontAdjustmentRule, name, adjustment),
					allNames));
		}
	};
	return _Utils_ap(
		A2(
			$elm$core$String$join,
			'\n',
			A2($elm$core$List$map, fontImports, rules)),
		A2(
			$elm$core$String$join,
			'\n',
			A2($elm$core$List$map, fontAdjustments, rules)));
};
var $mdgriffith$elm_ui$Internal$Model$topLevelValue = function (rule) {
	if (rule.$ === 1) {
		var name = rule.a;
		var typefaces = rule.b;
		return $elm$core$Maybe$Just(
			_Utils_Tuple2(name, typefaces));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $mdgriffith$elm_ui$Internal$Model$toStyleSheetString = F2(
	function (options, stylesheet) {
		var combine = F2(
			function (style, rendered) {
				return {
					eU: _Utils_ap(
						rendered.eU,
						A3($mdgriffith$elm_ui$Internal$Model$renderStyleRule, options, style, $elm$core$Maybe$Nothing)),
					dF: function () {
						var _v1 = $mdgriffith$elm_ui$Internal$Model$topLevelValue(style);
						if (_v1.$ === 1) {
							return rendered.dF;
						} else {
							var topLevel = _v1.a;
							return A2($elm$core$List$cons, topLevel, rendered.dF);
						}
					}()
				};
			});
		var _v0 = A3(
			$elm$core$List$foldl,
			combine,
			{eU: _List_Nil, dF: _List_Nil},
			stylesheet);
		var topLevel = _v0.dF;
		var rules = _v0.eU;
		return _Utils_ap(
			$mdgriffith$elm_ui$Internal$Model$renderTopLevelValues(topLevel),
			$elm$core$String$concat(rules));
	});
var $mdgriffith$elm_ui$Internal$Model$toStyleSheet = F2(
	function (options, styleSheet) {
		var _v0 = options.ez;
		switch (_v0) {
			case 0:
				return A3(
					$elm$virtual_dom$VirtualDom$node,
					'div',
					_List_Nil,
					_List_fromArray(
						[
							A3(
							$elm$virtual_dom$VirtualDom$node,
							'style',
							_List_Nil,
							_List_fromArray(
								[
									$elm$virtual_dom$VirtualDom$text(
									A2($mdgriffith$elm_ui$Internal$Model$toStyleSheetString, options, styleSheet))
								]))
						]));
			case 1:
				return A3(
					$elm$virtual_dom$VirtualDom$node,
					'div',
					_List_Nil,
					_List_fromArray(
						[
							A3(
							$elm$virtual_dom$VirtualDom$node,
							'style',
							_List_Nil,
							_List_fromArray(
								[
									$elm$virtual_dom$VirtualDom$text(
									A2($mdgriffith$elm_ui$Internal$Model$toStyleSheetString, options, styleSheet))
								]))
						]));
			default:
				return A3(
					$elm$virtual_dom$VirtualDom$node,
					'elm-ui-rules',
					_List_fromArray(
						[
							A2(
							$elm$virtual_dom$VirtualDom$property,
							'rules',
							A2($mdgriffith$elm_ui$Internal$Model$encodeStyles, options, styleSheet))
						]),
					_List_Nil);
		}
	});
var $mdgriffith$elm_ui$Internal$Model$embedKeyed = F4(
	function (_static, opts, styles, children) {
		var dynamicStyleSheet = A2(
			$mdgriffith$elm_ui$Internal$Model$toStyleSheet,
			opts,
			A3(
				$elm$core$List$foldl,
				$mdgriffith$elm_ui$Internal$Model$reduceStyles,
				_Utils_Tuple2(
					$elm$core$Set$empty,
					$mdgriffith$elm_ui$Internal$Model$renderFocusStyle(opts.ly)),
				styles).b);
		return _static ? A2(
			$elm$core$List$cons,
			_Utils_Tuple2(
				'static-stylesheet',
				$mdgriffith$elm_ui$Internal$Model$staticRoot(opts)),
			A2(
				$elm$core$List$cons,
				_Utils_Tuple2('dynamic-stylesheet', dynamicStyleSheet),
				children)) : A2(
			$elm$core$List$cons,
			_Utils_Tuple2('dynamic-stylesheet', dynamicStyleSheet),
			children);
	});
var $mdgriffith$elm_ui$Internal$Model$embedWith = F4(
	function (_static, opts, styles, children) {
		var dynamicStyleSheet = A2(
			$mdgriffith$elm_ui$Internal$Model$toStyleSheet,
			opts,
			A3(
				$elm$core$List$foldl,
				$mdgriffith$elm_ui$Internal$Model$reduceStyles,
				_Utils_Tuple2(
					$elm$core$Set$empty,
					$mdgriffith$elm_ui$Internal$Model$renderFocusStyle(opts.ly)),
				styles).b);
		return _static ? A2(
			$elm$core$List$cons,
			$mdgriffith$elm_ui$Internal$Model$staticRoot(opts),
			A2($elm$core$List$cons, dynamicStyleSheet, children)) : A2($elm$core$List$cons, dynamicStyleSheet, children);
	});
var $mdgriffith$elm_ui$Internal$Flag$heightBetween = $mdgriffith$elm_ui$Internal$Flag$flag(45);
var $mdgriffith$elm_ui$Internal$Flag$heightFill = $mdgriffith$elm_ui$Internal$Flag$flag(37);
var $elm$virtual_dom$VirtualDom$keyedNode = function (tag) {
	return _VirtualDom_keyedNode(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$p = _VirtualDom_node('p');
var $mdgriffith$elm_ui$Internal$Flag$present = F2(
	function (myFlag, _v0) {
		var fieldOne = _v0.a;
		var fieldTwo = _v0.b;
		if (!myFlag.$) {
			var first = myFlag.a;
			return _Utils_eq(first & fieldOne, first);
		} else {
			var second = myFlag.a;
			return _Utils_eq(second & fieldTwo, second);
		}
	});
var $elm$html$Html$s = _VirtualDom_node('s');
var $elm$html$Html$u = _VirtualDom_node('u');
var $mdgriffith$elm_ui$Internal$Flag$widthBetween = $mdgriffith$elm_ui$Internal$Flag$flag(44);
var $mdgriffith$elm_ui$Internal$Flag$widthFill = $mdgriffith$elm_ui$Internal$Flag$flag(39);
var $mdgriffith$elm_ui$Internal$Model$finalizeNode = F6(
	function (has, node, attributes, children, embedMode, parentContext) {
		var createNode = F2(
			function (nodeName, attrs) {
				if (children.$ === 1) {
					var keyed = children.a;
					return A3(
						$elm$virtual_dom$VirtualDom$keyedNode,
						nodeName,
						attrs,
						function () {
							switch (embedMode.$) {
								case 0:
									return keyed;
								case 2:
									var opts = embedMode.a;
									var styles = embedMode.b;
									return A4($mdgriffith$elm_ui$Internal$Model$embedKeyed, false, opts, styles, keyed);
								default:
									var opts = embedMode.a;
									var styles = embedMode.b;
									return A4($mdgriffith$elm_ui$Internal$Model$embedKeyed, true, opts, styles, keyed);
							}
						}());
				} else {
					var unkeyed = children.a;
					return A2(
						function () {
							switch (nodeName) {
								case 'div':
									return $elm$html$Html$div;
								case 'p':
									return $elm$html$Html$p;
								default:
									return $elm$virtual_dom$VirtualDom$node(nodeName);
							}
						}(),
						attrs,
						function () {
							switch (embedMode.$) {
								case 0:
									return unkeyed;
								case 2:
									var opts = embedMode.a;
									var styles = embedMode.b;
									return A4($mdgriffith$elm_ui$Internal$Model$embedWith, false, opts, styles, unkeyed);
								default:
									var opts = embedMode.a;
									var styles = embedMode.b;
									return A4($mdgriffith$elm_ui$Internal$Model$embedWith, true, opts, styles, unkeyed);
							}
						}());
				}
			});
		var html = function () {
			switch (node.$) {
				case 0:
					return A2(createNode, 'div', attributes);
				case 1:
					var nodeName = node.a;
					return A2(createNode, nodeName, attributes);
				default:
					var nodeName = node.a;
					var internal = node.b;
					return A3(
						$elm$virtual_dom$VirtualDom$node,
						nodeName,
						attributes,
						_List_fromArray(
							[
								A2(
								createNode,
								internal,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class($mdgriffith$elm_ui$Internal$Style$classes.j$ + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.n6))
									]))
							]));
			}
		}();
		switch (parentContext) {
			case 0:
				return (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$widthFill, has) && (!A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$widthBetween, has))) ? html : (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$alignRight, has) ? A2(
					$elm$html$Html$u,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(
							A2(
								$elm$core$String$join,
								' ',
								_List_fromArray(
									[$mdgriffith$elm_ui$Internal$Style$classes.j$, $mdgriffith$elm_ui$Internal$Style$classes.n6, $mdgriffith$elm_ui$Internal$Style$classes.hc, $mdgriffith$elm_ui$Internal$Style$classes.a_, $mdgriffith$elm_ui$Internal$Style$classes.jP])))
						]),
					_List_fromArray(
						[html])) : (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$centerX, has) ? A2(
					$elm$html$Html$s,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(
							A2(
								$elm$core$String$join,
								' ',
								_List_fromArray(
									[$mdgriffith$elm_ui$Internal$Style$classes.j$, $mdgriffith$elm_ui$Internal$Style$classes.n6, $mdgriffith$elm_ui$Internal$Style$classes.hc, $mdgriffith$elm_ui$Internal$Style$classes.a_, $mdgriffith$elm_ui$Internal$Style$classes.jN])))
						]),
					_List_fromArray(
						[html])) : html));
			case 1:
				return (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$heightFill, has) && (!A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$heightBetween, has))) ? html : (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$centerY, has) ? A2(
					$elm$html$Html$s,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(
							A2(
								$elm$core$String$join,
								' ',
								_List_fromArray(
									[$mdgriffith$elm_ui$Internal$Style$classes.j$, $mdgriffith$elm_ui$Internal$Style$classes.n6, $mdgriffith$elm_ui$Internal$Style$classes.hc, $mdgriffith$elm_ui$Internal$Style$classes.jO])))
						]),
					_List_fromArray(
						[html])) : (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$alignBottom, has) ? A2(
					$elm$html$Html$u,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(
							A2(
								$elm$core$String$join,
								' ',
								_List_fromArray(
									[$mdgriffith$elm_ui$Internal$Style$classes.j$, $mdgriffith$elm_ui$Internal$Style$classes.n6, $mdgriffith$elm_ui$Internal$Style$classes.hc, $mdgriffith$elm_ui$Internal$Style$classes.jM])))
						]),
					_List_fromArray(
						[html])) : html));
			default:
				return html;
		}
	});
var $mdgriffith$elm_ui$Internal$Model$textElementClasses = $mdgriffith$elm_ui$Internal$Style$classes.j$ + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.bJ + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.gD + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.fQ)))));
var $mdgriffith$elm_ui$Internal$Model$textElement = function (str) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class($mdgriffith$elm_ui$Internal$Model$textElementClasses)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(str)
			]));
};
var $mdgriffith$elm_ui$Internal$Model$textElementFillClasses = $mdgriffith$elm_ui$Internal$Style$classes.j$ + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.bJ + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.gE + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.fR)))));
var $mdgriffith$elm_ui$Internal$Model$textElementFill = function (str) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class($mdgriffith$elm_ui$Internal$Model$textElementFillClasses)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(str)
			]));
};
var $mdgriffith$elm_ui$Internal$Model$createElement = F3(
	function (context, children, rendered) {
		var gatherKeyed = F2(
			function (_v8, _v9) {
				var key = _v8.a;
				var child = _v8.b;
				var htmls = _v9.a;
				var existingStyles = _v9.b;
				switch (child.$) {
					case 0:
						var html = child.a;
						return _Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asParagraph) ? _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									key,
									html(context)),
								htmls),
							existingStyles) : _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									key,
									html(context)),
								htmls),
							existingStyles);
					case 1:
						var styled = child.a;
						return _Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asParagraph) ? _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									key,
									A2(styled.lM, $mdgriffith$elm_ui$Internal$Model$NoStyleSheet, context)),
								htmls),
							$elm$core$List$isEmpty(existingStyles) ? styled.dx : _Utils_ap(styled.dx, existingStyles)) : _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									key,
									A2(styled.lM, $mdgriffith$elm_ui$Internal$Model$NoStyleSheet, context)),
								htmls),
							$elm$core$List$isEmpty(existingStyles) ? styled.dx : _Utils_ap(styled.dx, existingStyles));
					case 2:
						var str = child.a;
						return _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									key,
									_Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asEl) ? $mdgriffith$elm_ui$Internal$Model$textElementFill(str) : $mdgriffith$elm_ui$Internal$Model$textElement(str)),
								htmls),
							existingStyles);
					default:
						return _Utils_Tuple2(htmls, existingStyles);
				}
			});
		var gather = F2(
			function (child, _v6) {
				var htmls = _v6.a;
				var existingStyles = _v6.b;
				switch (child.$) {
					case 0:
						var html = child.a;
						return _Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asParagraph) ? _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								html(context),
								htmls),
							existingStyles) : _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								html(context),
								htmls),
							existingStyles);
					case 1:
						var styled = child.a;
						return _Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asParagraph) ? _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								A2(styled.lM, $mdgriffith$elm_ui$Internal$Model$NoStyleSheet, context),
								htmls),
							$elm$core$List$isEmpty(existingStyles) ? styled.dx : _Utils_ap(styled.dx, existingStyles)) : _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								A2(styled.lM, $mdgriffith$elm_ui$Internal$Model$NoStyleSheet, context),
								htmls),
							$elm$core$List$isEmpty(existingStyles) ? styled.dx : _Utils_ap(styled.dx, existingStyles));
					case 2:
						var str = child.a;
						return _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asEl) ? $mdgriffith$elm_ui$Internal$Model$textElementFill(str) : $mdgriffith$elm_ui$Internal$Model$textElement(str),
								htmls),
							existingStyles);
					default:
						return _Utils_Tuple2(htmls, existingStyles);
				}
			});
		if (children.$ === 1) {
			var keyedChildren = children.a;
			var _v1 = A3(
				$elm$core$List$foldr,
				gatherKeyed,
				_Utils_Tuple2(_List_Nil, _List_Nil),
				keyedChildren);
			var keyed = _v1.a;
			var styles = _v1.b;
			var newStyles = $elm$core$List$isEmpty(styles) ? rendered.dx : _Utils_ap(rendered.dx, styles);
			if (!newStyles.b) {
				return $mdgriffith$elm_ui$Internal$Model$Unstyled(
					A5(
						$mdgriffith$elm_ui$Internal$Model$finalizeNode,
						rendered.br,
						rendered.by,
						rendered.bk,
						$mdgriffith$elm_ui$Internal$Model$Keyed(
							A3($mdgriffith$elm_ui$Internal$Model$addKeyedChildren, 'nearby-element-pls', keyed, rendered.bl)),
						$mdgriffith$elm_ui$Internal$Model$NoStyleSheet));
			} else {
				var allStyles = newStyles;
				return $mdgriffith$elm_ui$Internal$Model$Styled(
					{
						lM: A4(
							$mdgriffith$elm_ui$Internal$Model$finalizeNode,
							rendered.br,
							rendered.by,
							rendered.bk,
							$mdgriffith$elm_ui$Internal$Model$Keyed(
								A3($mdgriffith$elm_ui$Internal$Model$addKeyedChildren, 'nearby-element-pls', keyed, rendered.bl))),
						dx: allStyles
					});
			}
		} else {
			var unkeyedChildren = children.a;
			var _v3 = A3(
				$elm$core$List$foldr,
				gather,
				_Utils_Tuple2(_List_Nil, _List_Nil),
				unkeyedChildren);
			var unkeyed = _v3.a;
			var styles = _v3.b;
			var newStyles = $elm$core$List$isEmpty(styles) ? rendered.dx : _Utils_ap(rendered.dx, styles);
			if (!newStyles.b) {
				return $mdgriffith$elm_ui$Internal$Model$Unstyled(
					A5(
						$mdgriffith$elm_ui$Internal$Model$finalizeNode,
						rendered.br,
						rendered.by,
						rendered.bk,
						$mdgriffith$elm_ui$Internal$Model$Unkeyed(
							A2($mdgriffith$elm_ui$Internal$Model$addChildren, unkeyed, rendered.bl)),
						$mdgriffith$elm_ui$Internal$Model$NoStyleSheet));
			} else {
				var allStyles = newStyles;
				return $mdgriffith$elm_ui$Internal$Model$Styled(
					{
						lM: A4(
							$mdgriffith$elm_ui$Internal$Model$finalizeNode,
							rendered.br,
							rendered.by,
							rendered.bk,
							$mdgriffith$elm_ui$Internal$Model$Unkeyed(
								A2($mdgriffith$elm_ui$Internal$Model$addChildren, unkeyed, rendered.bl))),
						dx: allStyles
					});
			}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$Single = F3(
	function (a, b, c) {
		return {$: 3, a: a, b: b, c: c};
	});
var $mdgriffith$elm_ui$Internal$Model$Transform = function (a) {
	return {$: 10, a: a};
};
var $mdgriffith$elm_ui$Internal$Flag$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$core$Bitwise$or = _Bitwise_or;
var $mdgriffith$elm_ui$Internal$Flag$add = F2(
	function (myFlag, _v0) {
		var one = _v0.a;
		var two = _v0.b;
		if (!myFlag.$) {
			var first = myFlag.a;
			return A2($mdgriffith$elm_ui$Internal$Flag$Field, first | one, two);
		} else {
			var second = myFlag.a;
			return A2($mdgriffith$elm_ui$Internal$Flag$Field, one, second | two);
		}
	});
var $mdgriffith$elm_ui$Internal$Model$ChildrenBehind = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$ChildrenInFront = function (a) {
	return {$: 2, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$nearbyElement = F2(
	function (location, elem) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class(
					function () {
						switch (location) {
							case 0:
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.ci, $mdgriffith$elm_ui$Internal$Style$classes.n6, $mdgriffith$elm_ui$Internal$Style$classes.jD]));
							case 1:
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.ci, $mdgriffith$elm_ui$Internal$Style$classes.n6, $mdgriffith$elm_ui$Internal$Style$classes.j9]));
							case 2:
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.ci, $mdgriffith$elm_ui$Internal$Style$classes.n6, $mdgriffith$elm_ui$Internal$Style$classes.m3]));
							case 3:
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.ci, $mdgriffith$elm_ui$Internal$Style$classes.n6, $mdgriffith$elm_ui$Internal$Style$classes.mW]));
							case 4:
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.ci, $mdgriffith$elm_ui$Internal$Style$classes.n6, $mdgriffith$elm_ui$Internal$Style$classes.lQ]));
							default:
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.ci, $mdgriffith$elm_ui$Internal$Style$classes.n6, $mdgriffith$elm_ui$Internal$Style$classes.j8]));
						}
					}())
				]),
			_List_fromArray(
				[
					function () {
					switch (elem.$) {
						case 3:
							return $elm$virtual_dom$VirtualDom$text('');
						case 2:
							var str = elem.a;
							return $mdgriffith$elm_ui$Internal$Model$textElement(str);
						case 0:
							var html = elem.a;
							return html($mdgriffith$elm_ui$Internal$Model$asEl);
						default:
							var styled = elem.a;
							return A2(styled.lM, $mdgriffith$elm_ui$Internal$Model$NoStyleSheet, $mdgriffith$elm_ui$Internal$Model$asEl);
					}
				}()
				]));
	});
var $mdgriffith$elm_ui$Internal$Model$addNearbyElement = F3(
	function (location, elem, existing) {
		var nearby = A2($mdgriffith$elm_ui$Internal$Model$nearbyElement, location, elem);
		switch (existing.$) {
			case 0:
				if (location === 5) {
					return $mdgriffith$elm_ui$Internal$Model$ChildrenBehind(
						_List_fromArray(
							[nearby]));
				} else {
					return $mdgriffith$elm_ui$Internal$Model$ChildrenInFront(
						_List_fromArray(
							[nearby]));
				}
			case 1:
				var existingBehind = existing.a;
				if (location === 5) {
					return $mdgriffith$elm_ui$Internal$Model$ChildrenBehind(
						A2($elm$core$List$cons, nearby, existingBehind));
				} else {
					return A2(
						$mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront,
						existingBehind,
						_List_fromArray(
							[nearby]));
				}
			case 2:
				var existingInFront = existing.a;
				if (location === 5) {
					return A2(
						$mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront,
						_List_fromArray(
							[nearby]),
						existingInFront);
				} else {
					return $mdgriffith$elm_ui$Internal$Model$ChildrenInFront(
						A2($elm$core$List$cons, nearby, existingInFront));
				}
			default:
				var existingBehind = existing.a;
				var existingInFront = existing.b;
				if (location === 5) {
					return A2(
						$mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront,
						A2($elm$core$List$cons, nearby, existingBehind),
						existingInFront);
				} else {
					return A2(
						$mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront,
						existingBehind,
						A2($elm$core$List$cons, nearby, existingInFront));
				}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$Embedded = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$NodeName = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$addNodeName = F2(
	function (newNode, old) {
		switch (old.$) {
			case 0:
				return $mdgriffith$elm_ui$Internal$Model$NodeName(newNode);
			case 1:
				var name = old.a;
				return A2($mdgriffith$elm_ui$Internal$Model$Embedded, name, newNode);
			default:
				var x = old.a;
				var y = old.b;
				return A2($mdgriffith$elm_ui$Internal$Model$Embedded, x, y);
		}
	});
var $mdgriffith$elm_ui$Internal$Model$alignXName = function (align) {
	switch (align) {
		case 0:
			return $mdgriffith$elm_ui$Internal$Style$classes.fj + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.gO);
		case 2:
			return $mdgriffith$elm_ui$Internal$Style$classes.fj + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.gP);
		default:
			return $mdgriffith$elm_ui$Internal$Style$classes.fj + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.jK);
	}
};
var $mdgriffith$elm_ui$Internal$Model$alignYName = function (align) {
	switch (align) {
		case 0:
			return $mdgriffith$elm_ui$Internal$Style$classes.fk + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.jR);
		case 2:
			return $mdgriffith$elm_ui$Internal$Style$classes.fk + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.jJ);
		default:
			return $mdgriffith$elm_ui$Internal$Style$classes.fk + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.jL);
	}
};
var $mdgriffith$elm_ui$Internal$Model$FullTransform = F4(
	function (a, b, c, d) {
		return {$: 2, a: a, b: b, c: c, d: d};
	});
var $mdgriffith$elm_ui$Internal$Model$Moved = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$composeTransformation = F2(
	function (transform, component) {
		switch (transform.$) {
			case 0:
				switch (component.$) {
					case 0:
						var x = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(x, 0, 0));
					case 1:
						var y = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(0, y, 0));
					case 2:
						var z = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(0, 0, z));
					case 3:
						var xyz = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(xyz);
					case 4:
						var xyz = component.a;
						var angle = component.b;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(0, 0, 0),
							_Utils_Tuple3(1, 1, 1),
							xyz,
							angle);
					default:
						var xyz = component.a;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(0, 0, 0),
							xyz,
							_Utils_Tuple3(0, 0, 1),
							0);
				}
			case 1:
				var moved = transform.a;
				var x = moved.a;
				var y = moved.b;
				var z = moved.c;
				switch (component.$) {
					case 0:
						var newX = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(newX, y, z));
					case 1:
						var newY = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(x, newY, z));
					case 2:
						var newZ = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(x, y, newZ));
					case 3:
						var xyz = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(xyz);
					case 4:
						var xyz = component.a;
						var angle = component.b;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							moved,
							_Utils_Tuple3(1, 1, 1),
							xyz,
							angle);
					default:
						var scale = component.a;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							moved,
							scale,
							_Utils_Tuple3(0, 0, 1),
							0);
				}
			default:
				var moved = transform.a;
				var x = moved.a;
				var y = moved.b;
				var z = moved.c;
				var scaled = transform.b;
				var origin = transform.c;
				var angle = transform.d;
				switch (component.$) {
					case 0:
						var newX = component.a;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(newX, y, z),
							scaled,
							origin,
							angle);
					case 1:
						var newY = component.a;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(x, newY, z),
							scaled,
							origin,
							angle);
					case 2:
						var newZ = component.a;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(x, y, newZ),
							scaled,
							origin,
							angle);
					case 3:
						var newMove = component.a;
						return A4($mdgriffith$elm_ui$Internal$Model$FullTransform, newMove, scaled, origin, angle);
					case 4:
						var newOrigin = component.a;
						var newAngle = component.b;
						return A4($mdgriffith$elm_ui$Internal$Model$FullTransform, moved, scaled, newOrigin, newAngle);
					default:
						var newScale = component.a;
						return A4($mdgriffith$elm_ui$Internal$Model$FullTransform, moved, newScale, origin, angle);
				}
		}
	});
var $mdgriffith$elm_ui$Internal$Flag$height = $mdgriffith$elm_ui$Internal$Flag$flag(7);
var $mdgriffith$elm_ui$Internal$Flag$heightContent = $mdgriffith$elm_ui$Internal$Flag$flag(36);
var $mdgriffith$elm_ui$Internal$Flag$merge = F2(
	function (_v0, _v1) {
		var one = _v0.a;
		var two = _v0.b;
		var three = _v1.a;
		var four = _v1.b;
		return A2($mdgriffith$elm_ui$Internal$Flag$Field, one | three, two | four);
	});
var $mdgriffith$elm_ui$Internal$Flag$none = A2($mdgriffith$elm_ui$Internal$Flag$Field, 0, 0);
var $mdgriffith$elm_ui$Internal$Model$renderHeight = function (h) {
	switch (h.$) {
		case 0:
			var px = h.a;
			var val = $elm$core$String$fromInt(px);
			var name = 'height-px-' + val;
			return _Utils_Tuple3(
				$mdgriffith$elm_ui$Internal$Flag$none,
				$mdgriffith$elm_ui$Internal$Style$classes.hG + (' ' + name),
				_List_fromArray(
					[
						A3($mdgriffith$elm_ui$Internal$Model$Single, name, 'height', val + 'px')
					]));
		case 1:
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$heightContent, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.fQ,
				_List_Nil);
		case 2:
			var portion = h.a;
			return (portion === 1) ? _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$heightFill, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.fR,
				_List_Nil) : _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$heightFill, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.hH + (' height-fill-' + $elm$core$String$fromInt(portion)),
				_List_fromArray(
					[
						A3(
						$mdgriffith$elm_ui$Internal$Model$Single,
						$mdgriffith$elm_ui$Internal$Style$classes.j$ + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.aZ + (' > ' + $mdgriffith$elm_ui$Internal$Style$dot(
							'height-fill-' + $elm$core$String$fromInt(portion))))),
						'flex-grow',
						$elm$core$String$fromInt(portion * 100000))
					]));
		case 3:
			var minSize = h.a;
			var len = h.b;
			var cls = 'min-height-' + $elm$core$String$fromInt(minSize);
			var style = A3(
				$mdgriffith$elm_ui$Internal$Model$Single,
				cls,
				'min-height',
				$elm$core$String$fromInt(minSize) + 'px !important');
			var _v1 = $mdgriffith$elm_ui$Internal$Model$renderHeight(len);
			var newFlag = _v1.a;
			var newAttrs = _v1.b;
			var newStyle = _v1.c;
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$heightBetween, newFlag),
				cls + (' ' + newAttrs),
				A2($elm$core$List$cons, style, newStyle));
		default:
			var maxSize = h.a;
			var len = h.b;
			var cls = 'max-height-' + $elm$core$String$fromInt(maxSize);
			var style = A3(
				$mdgriffith$elm_ui$Internal$Model$Single,
				cls,
				'max-height',
				$elm$core$String$fromInt(maxSize) + 'px');
			var _v2 = $mdgriffith$elm_ui$Internal$Model$renderHeight(len);
			var newFlag = _v2.a;
			var newAttrs = _v2.b;
			var newStyle = _v2.c;
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$heightBetween, newFlag),
				cls + (' ' + newAttrs),
				A2($elm$core$List$cons, style, newStyle));
	}
};
var $mdgriffith$elm_ui$Internal$Flag$widthContent = $mdgriffith$elm_ui$Internal$Flag$flag(38);
var $mdgriffith$elm_ui$Internal$Model$renderWidth = function (w) {
	switch (w.$) {
		case 0:
			var px = w.a;
			return _Utils_Tuple3(
				$mdgriffith$elm_ui$Internal$Flag$none,
				$mdgriffith$elm_ui$Internal$Style$classes.jw + (' width-px-' + $elm$core$String$fromInt(px)),
				_List_fromArray(
					[
						A3(
						$mdgriffith$elm_ui$Internal$Model$Single,
						'width-px-' + $elm$core$String$fromInt(px),
						'width',
						$elm$core$String$fromInt(px) + 'px')
					]));
		case 1:
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$widthContent, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.gD,
				_List_Nil);
		case 2:
			var portion = w.a;
			return (portion === 1) ? _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$widthFill, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.gE,
				_List_Nil) : _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$widthFill, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.jx + (' width-fill-' + $elm$core$String$fromInt(portion)),
				_List_fromArray(
					[
						A3(
						$mdgriffith$elm_ui$Internal$Model$Single,
						$mdgriffith$elm_ui$Internal$Style$classes.j$ + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.iP + (' > ' + $mdgriffith$elm_ui$Internal$Style$dot(
							'width-fill-' + $elm$core$String$fromInt(portion))))),
						'flex-grow',
						$elm$core$String$fromInt(portion * 100000))
					]));
		case 3:
			var minSize = w.a;
			var len = w.b;
			var cls = 'min-width-' + $elm$core$String$fromInt(minSize);
			var style = A3(
				$mdgriffith$elm_ui$Internal$Model$Single,
				cls,
				'min-width',
				$elm$core$String$fromInt(minSize) + 'px');
			var _v1 = $mdgriffith$elm_ui$Internal$Model$renderWidth(len);
			var newFlag = _v1.a;
			var newAttrs = _v1.b;
			var newStyle = _v1.c;
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$widthBetween, newFlag),
				cls + (' ' + newAttrs),
				A2($elm$core$List$cons, style, newStyle));
		default:
			var maxSize = w.a;
			var len = w.b;
			var cls = 'max-width-' + $elm$core$String$fromInt(maxSize);
			var style = A3(
				$mdgriffith$elm_ui$Internal$Model$Single,
				cls,
				'max-width',
				$elm$core$String$fromInt(maxSize) + 'px');
			var _v2 = $mdgriffith$elm_ui$Internal$Model$renderWidth(len);
			var newFlag = _v2.a;
			var newAttrs = _v2.b;
			var newStyle = _v2.c;
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$widthBetween, newFlag),
				cls + (' ' + newAttrs),
				A2($elm$core$List$cons, style, newStyle));
	}
};
var $mdgriffith$elm_ui$Internal$Flag$borderWidth = $mdgriffith$elm_ui$Internal$Flag$flag(27);
var $mdgriffith$elm_ui$Internal$Model$skippable = F2(
	function (flag, style) {
		if (_Utils_eq(flag, $mdgriffith$elm_ui$Internal$Flag$borderWidth)) {
			if (style.$ === 3) {
				var val = style.c;
				switch (val) {
					case '0px':
						return true;
					case '1px':
						return true;
					case '2px':
						return true;
					case '3px':
						return true;
					case '4px':
						return true;
					case '5px':
						return true;
					case '6px':
						return true;
					default:
						return false;
				}
			} else {
				return false;
			}
		} else {
			switch (style.$) {
				case 2:
					var i = style.a;
					return (i >= 8) && (i <= 32);
				case 7:
					var name = style.a;
					var t = style.b;
					var r = style.c;
					var b = style.d;
					var l = style.e;
					return _Utils_eq(t, b) && (_Utils_eq(t, r) && (_Utils_eq(t, l) && ((t >= 0) && (t <= 24))));
				default:
					return false;
			}
		}
	});
var $mdgriffith$elm_ui$Internal$Flag$width = $mdgriffith$elm_ui$Internal$Flag$flag(6);
var $mdgriffith$elm_ui$Internal$Flag$xAlign = $mdgriffith$elm_ui$Internal$Flag$flag(30);
var $mdgriffith$elm_ui$Internal$Flag$yAlign = $mdgriffith$elm_ui$Internal$Flag$flag(29);
var $mdgriffith$elm_ui$Internal$Model$gatherAttrRecursive = F8(
	function (classes, node, has, transform, styles, attrs, children, elementAttrs) {
		gatherAttrRecursive:
		while (true) {
			if (!elementAttrs.b) {
				var _v1 = $mdgriffith$elm_ui$Internal$Model$transformClass(transform);
				if (_v1.$ === 1) {
					return {
						bk: A2(
							$elm$core$List$cons,
							$elm$html$Html$Attributes$class(classes),
							attrs),
						bl: children,
						br: has,
						by: node,
						dx: styles
					};
				} else {
					var _class = _v1.a;
					return {
						bk: A2(
							$elm$core$List$cons,
							$elm$html$Html$Attributes$class(classes + (' ' + _class)),
							attrs),
						bl: children,
						br: has,
						by: node,
						dx: A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Internal$Model$Transform(transform),
							styles)
					};
				}
			} else {
				var attribute = elementAttrs.a;
				var remaining = elementAttrs.b;
				switch (attribute.$) {
					case 0:
						var $temp$classes = classes,
							$temp$node = node,
							$temp$has = has,
							$temp$transform = transform,
							$temp$styles = styles,
							$temp$attrs = attrs,
							$temp$children = children,
							$temp$elementAttrs = remaining;
						classes = $temp$classes;
						node = $temp$node;
						has = $temp$has;
						transform = $temp$transform;
						styles = $temp$styles;
						attrs = $temp$attrs;
						children = $temp$children;
						elementAttrs = $temp$elementAttrs;
						continue gatherAttrRecursive;
					case 3:
						var flag = attribute.a;
						var exactClassName = attribute.b;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, flag, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							var $temp$classes = exactClassName + (' ' + classes),
								$temp$node = node,
								$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, flag, has),
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						}
					case 1:
						var actualAttribute = attribute.a;
						var $temp$classes = classes,
							$temp$node = node,
							$temp$has = has,
							$temp$transform = transform,
							$temp$styles = styles,
							$temp$attrs = A2($elm$core$List$cons, actualAttribute, attrs),
							$temp$children = children,
							$temp$elementAttrs = remaining;
						classes = $temp$classes;
						node = $temp$node;
						has = $temp$has;
						transform = $temp$transform;
						styles = $temp$styles;
						attrs = $temp$attrs;
						children = $temp$children;
						elementAttrs = $temp$elementAttrs;
						continue gatherAttrRecursive;
					case 4:
						var flag = attribute.a;
						var style = attribute.b;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, flag, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							if (A2($mdgriffith$elm_ui$Internal$Model$skippable, flag, style)) {
								var $temp$classes = $mdgriffith$elm_ui$Internal$Model$getStyleName(style) + (' ' + classes),
									$temp$node = node,
									$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, flag, has),
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							} else {
								var $temp$classes = $mdgriffith$elm_ui$Internal$Model$getStyleName(style) + (' ' + classes),
									$temp$node = node,
									$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, flag, has),
									$temp$transform = transform,
									$temp$styles = A2($elm$core$List$cons, style, styles),
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							}
						}
					case 10:
						var flag = attribute.a;
						var component = attribute.b;
						var $temp$classes = classes,
							$temp$node = node,
							$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, flag, has),
							$temp$transform = A2($mdgriffith$elm_ui$Internal$Model$composeTransformation, transform, component),
							$temp$styles = styles,
							$temp$attrs = attrs,
							$temp$children = children,
							$temp$elementAttrs = remaining;
						classes = $temp$classes;
						node = $temp$node;
						has = $temp$has;
						transform = $temp$transform;
						styles = $temp$styles;
						attrs = $temp$attrs;
						children = $temp$children;
						elementAttrs = $temp$elementAttrs;
						continue gatherAttrRecursive;
					case 7:
						var width = attribute.a;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$width, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							switch (width.$) {
								case 0:
									var px = width.a;
									var $temp$classes = ($mdgriffith$elm_ui$Internal$Style$classes.jw + (' width-px-' + $elm$core$String$fromInt(px))) + (' ' + classes),
										$temp$node = node,
										$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$width, has),
										$temp$transform = transform,
										$temp$styles = A2(
										$elm$core$List$cons,
										A3(
											$mdgriffith$elm_ui$Internal$Model$Single,
											'width-px-' + $elm$core$String$fromInt(px),
											'width',
											$elm$core$String$fromInt(px) + 'px'),
										styles),
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								case 1:
									var $temp$classes = classes + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.gD),
										$temp$node = node,
										$temp$has = A2(
										$mdgriffith$elm_ui$Internal$Flag$add,
										$mdgriffith$elm_ui$Internal$Flag$widthContent,
										A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$width, has)),
										$temp$transform = transform,
										$temp$styles = styles,
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								case 2:
									var portion = width.a;
									if (portion === 1) {
										var $temp$classes = classes + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.gE),
											$temp$node = node,
											$temp$has = A2(
											$mdgriffith$elm_ui$Internal$Flag$add,
											$mdgriffith$elm_ui$Internal$Flag$widthFill,
											A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$width, has)),
											$temp$transform = transform,
											$temp$styles = styles,
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									} else {
										var $temp$classes = classes + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.jx + (' width-fill-' + $elm$core$String$fromInt(portion)))),
											$temp$node = node,
											$temp$has = A2(
											$mdgriffith$elm_ui$Internal$Flag$add,
											$mdgriffith$elm_ui$Internal$Flag$widthFill,
											A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$width, has)),
											$temp$transform = transform,
											$temp$styles = A2(
											$elm$core$List$cons,
											A3(
												$mdgriffith$elm_ui$Internal$Model$Single,
												$mdgriffith$elm_ui$Internal$Style$classes.j$ + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.iP + (' > ' + $mdgriffith$elm_ui$Internal$Style$dot(
													'width-fill-' + $elm$core$String$fromInt(portion))))),
												'flex-grow',
												$elm$core$String$fromInt(portion * 100000)),
											styles),
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									}
								default:
									var _v4 = $mdgriffith$elm_ui$Internal$Model$renderWidth(width);
									var addToFlags = _v4.a;
									var newClass = _v4.b;
									var newStyles = _v4.c;
									var $temp$classes = classes + (' ' + newClass),
										$temp$node = node,
										$temp$has = A2(
										$mdgriffith$elm_ui$Internal$Flag$merge,
										addToFlags,
										A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$width, has)),
										$temp$transform = transform,
										$temp$styles = _Utils_ap(newStyles, styles),
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
							}
						}
					case 8:
						var height = attribute.a;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$height, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							switch (height.$) {
								case 0:
									var px = height.a;
									var val = $elm$core$String$fromInt(px) + 'px';
									var name = 'height-px-' + val;
									var $temp$classes = $mdgriffith$elm_ui$Internal$Style$classes.hG + (' ' + (name + (' ' + classes))),
										$temp$node = node,
										$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$height, has),
										$temp$transform = transform,
										$temp$styles = A2(
										$elm$core$List$cons,
										A3($mdgriffith$elm_ui$Internal$Model$Single, name, 'height ', val),
										styles),
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								case 1:
									var $temp$classes = $mdgriffith$elm_ui$Internal$Style$classes.fQ + (' ' + classes),
										$temp$node = node,
										$temp$has = A2(
										$mdgriffith$elm_ui$Internal$Flag$add,
										$mdgriffith$elm_ui$Internal$Flag$heightContent,
										A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$height, has)),
										$temp$transform = transform,
										$temp$styles = styles,
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								case 2:
									var portion = height.a;
									if (portion === 1) {
										var $temp$classes = $mdgriffith$elm_ui$Internal$Style$classes.fR + (' ' + classes),
											$temp$node = node,
											$temp$has = A2(
											$mdgriffith$elm_ui$Internal$Flag$add,
											$mdgriffith$elm_ui$Internal$Flag$heightFill,
											A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$height, has)),
											$temp$transform = transform,
											$temp$styles = styles,
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									} else {
										var $temp$classes = classes + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.hH + (' height-fill-' + $elm$core$String$fromInt(portion)))),
											$temp$node = node,
											$temp$has = A2(
											$mdgriffith$elm_ui$Internal$Flag$add,
											$mdgriffith$elm_ui$Internal$Flag$heightFill,
											A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$height, has)),
											$temp$transform = transform,
											$temp$styles = A2(
											$elm$core$List$cons,
											A3(
												$mdgriffith$elm_ui$Internal$Model$Single,
												$mdgriffith$elm_ui$Internal$Style$classes.j$ + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.aZ + (' > ' + $mdgriffith$elm_ui$Internal$Style$dot(
													'height-fill-' + $elm$core$String$fromInt(portion))))),
												'flex-grow',
												$elm$core$String$fromInt(portion * 100000)),
											styles),
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									}
								default:
									var _v6 = $mdgriffith$elm_ui$Internal$Model$renderHeight(height);
									var addToFlags = _v6.a;
									var newClass = _v6.b;
									var newStyles = _v6.c;
									var $temp$classes = classes + (' ' + newClass),
										$temp$node = node,
										$temp$has = A2(
										$mdgriffith$elm_ui$Internal$Flag$merge,
										addToFlags,
										A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$height, has)),
										$temp$transform = transform,
										$temp$styles = _Utils_ap(newStyles, styles),
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
							}
						}
					case 2:
						var description = attribute.a;
						switch (description.$) {
							case 0:
								var $temp$classes = classes,
									$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'main', node),
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 1:
								var $temp$classes = classes,
									$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'nav', node),
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 2:
								var $temp$classes = classes,
									$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'footer', node),
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 3:
								var $temp$classes = classes,
									$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'aside', node),
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 4:
								var i = description.a;
								if (i <= 1) {
									var $temp$classes = classes,
										$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'h1', node),
										$temp$has = has,
										$temp$transform = transform,
										$temp$styles = styles,
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								} else {
									if (i < 7) {
										var $temp$classes = classes,
											$temp$node = A2(
											$mdgriffith$elm_ui$Internal$Model$addNodeName,
											'h' + $elm$core$String$fromInt(i),
											node),
											$temp$has = has,
											$temp$transform = transform,
											$temp$styles = styles,
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									} else {
										var $temp$classes = classes,
											$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'h6', node),
											$temp$has = has,
											$temp$transform = transform,
											$temp$styles = styles,
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									}
								}
							case 9:
								var $temp$classes = classes,
									$temp$node = node,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 8:
								var $temp$classes = classes,
									$temp$node = node,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = A2(
									$elm$core$List$cons,
									A2($elm$virtual_dom$VirtualDom$attribute, 'role', 'button'),
									attrs),
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 5:
								var label = description.a;
								var $temp$classes = classes,
									$temp$node = node,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = A2(
									$elm$core$List$cons,
									A2($elm$virtual_dom$VirtualDom$attribute, 'aria-label', label),
									attrs),
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 6:
								var $temp$classes = classes,
									$temp$node = node,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = A2(
									$elm$core$List$cons,
									A2($elm$virtual_dom$VirtualDom$attribute, 'aria-live', 'polite'),
									attrs),
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							default:
								var $temp$classes = classes,
									$temp$node = node,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = A2(
									$elm$core$List$cons,
									A2($elm$virtual_dom$VirtualDom$attribute, 'aria-live', 'assertive'),
									attrs),
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
						}
					case 9:
						var location = attribute.a;
						var elem = attribute.b;
						var newStyles = function () {
							switch (elem.$) {
								case 3:
									return styles;
								case 2:
									var str = elem.a;
									return styles;
								case 0:
									var html = elem.a;
									return styles;
								default:
									var styled = elem.a;
									return _Utils_ap(styles, styled.dx);
							}
						}();
						var $temp$classes = classes,
							$temp$node = node,
							$temp$has = has,
							$temp$transform = transform,
							$temp$styles = newStyles,
							$temp$attrs = attrs,
							$temp$children = A3($mdgriffith$elm_ui$Internal$Model$addNearbyElement, location, elem, children),
							$temp$elementAttrs = remaining;
						classes = $temp$classes;
						node = $temp$node;
						has = $temp$has;
						transform = $temp$transform;
						styles = $temp$styles;
						attrs = $temp$attrs;
						children = $temp$children;
						elementAttrs = $temp$elementAttrs;
						continue gatherAttrRecursive;
					case 6:
						var x = attribute.a;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$xAlign, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							var $temp$classes = $mdgriffith$elm_ui$Internal$Model$alignXName(x) + (' ' + classes),
								$temp$node = node,
								$temp$has = function (flags) {
								switch (x) {
									case 1:
										return A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$centerX, flags);
									case 2:
										return A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$alignRight, flags);
									default:
										return flags;
								}
							}(
								A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$xAlign, has)),
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						}
					default:
						var y = attribute.a;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$yAlign, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							var $temp$classes = $mdgriffith$elm_ui$Internal$Model$alignYName(y) + (' ' + classes),
								$temp$node = node,
								$temp$has = function (flags) {
								switch (y) {
									case 1:
										return A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$centerY, flags);
									case 2:
										return A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$alignBottom, flags);
									default:
										return flags;
								}
							}(
								A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$yAlign, has)),
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						}
				}
			}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$Untransformed = {$: 0};
var $mdgriffith$elm_ui$Internal$Model$untransformed = $mdgriffith$elm_ui$Internal$Model$Untransformed;
var $mdgriffith$elm_ui$Internal$Model$element = F4(
	function (context, node, attributes, children) {
		return A3(
			$mdgriffith$elm_ui$Internal$Model$createElement,
			context,
			children,
			A8(
				$mdgriffith$elm_ui$Internal$Model$gatherAttrRecursive,
				$mdgriffith$elm_ui$Internal$Model$contextClasses(context),
				node,
				$mdgriffith$elm_ui$Internal$Flag$none,
				$mdgriffith$elm_ui$Internal$Model$untransformed,
				_List_Nil,
				_List_Nil,
				$mdgriffith$elm_ui$Internal$Model$NoNearbyChildren,
				$elm$core$List$reverse(attributes)));
	});
var $mdgriffith$elm_ui$Internal$Model$Height = function (a) {
	return {$: 8, a: a};
};
var $mdgriffith$elm_ui$Element$height = $mdgriffith$elm_ui$Internal$Model$Height;
var $mdgriffith$elm_ui$Internal$Model$Content = {$: 1};
var $mdgriffith$elm_ui$Element$shrink = $mdgriffith$elm_ui$Internal$Model$Content;
var $mdgriffith$elm_ui$Internal$Model$Width = function (a) {
	return {$: 7, a: a};
};
var $mdgriffith$elm_ui$Element$width = $mdgriffith$elm_ui$Internal$Model$Width;
var $mdgriffith$elm_ui$Element$el = F2(
	function (attrs, child) {
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asEl,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
					attrs)),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(
				_List_fromArray(
					[child])));
	});
var $author$project$Components$Table$ColumnApi$footer = F2(
	function (footer_, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{
				ei: $elm$core$Maybe$Just(footer_)
			});
	});
var $mdgriffith$elm_ui$Internal$Model$Colored = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $mdgriffith$elm_ui$Internal$Model$StyleClass = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Flag$fontColor = $mdgriffith$elm_ui$Internal$Flag$flag(14);
var $mdgriffith$elm_ui$Internal$Model$formatColorClass = function (_v0) {
	var red = _v0.a;
	var green = _v0.b;
	var blue = _v0.c;
	var alpha = _v0.d;
	return $mdgriffith$elm_ui$Internal$Model$floatClass(red) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(green) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(blue) + ('-' + $mdgriffith$elm_ui$Internal$Model$floatClass(alpha))))));
};
var $mdgriffith$elm_ui$Element$Font$color = function (fontColor) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$fontColor,
		A3(
			$mdgriffith$elm_ui$Internal$Model$Colored,
			'fc-' + $mdgriffith$elm_ui$Internal$Model$formatColorClass(fontColor),
			'color',
			fontColor));
};
var $cuducos$elm_format_number$FormatNumber$Locales$Exact = function (a) {
	return {$: 2, a: a};
};
var $author$project$Helpers$finnishNumberLocale = function (decimals) {
	return {
		k1: ',',
		k2: $cuducos$elm_format_number$FormatNumber$Locales$Exact(decimals),
		mE: '-',
		mF: '',
		ny: '',
		nz: '',
		oD: ' ',
		o8: '',
		o9: ''
	};
};
var $cuducos$elm_format_number$FormatNumber$Parser$FormattedNumber = F5(
	function (original, integers, decimals, prefix, suffix) {
		return {k2: decimals, hW: integers, ik: original, eP: prefix, e$: suffix};
	});
var $cuducos$elm_format_number$FormatNumber$Parser$Negative = 2;
var $cuducos$elm_format_number$FormatNumber$Parser$Positive = 0;
var $cuducos$elm_format_number$FormatNumber$Parser$Zero = 1;
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $cuducos$elm_format_number$FormatNumber$Parser$classify = function (formatted) {
	var onlyZeros = A2(
		$elm$core$String$all,
		function (_char) {
			return _char === '0';
		},
		$elm$core$String$concat(
			A2(
				$elm$core$List$append,
				formatted.hW,
				$elm$core$List$singleton(formatted.k2))));
	return onlyZeros ? 1 : ((formatted.ik < 0) ? 2 : 0);
};
var $elm$core$String$filter = _String_filter;
var $cuducos$elm_format_number$FormatNumber$Parser$addZerosToFit = F2(
	function (desiredLength, value) {
		var length = $elm$core$String$length(value);
		var missing = (_Utils_cmp(length, desiredLength) < 0) ? $elm$core$Basics$abs(desiredLength - length) : 0;
		return _Utils_ap(
			value,
			A2($elm$core$String$repeat, missing, '0'));
	});
var $cuducos$elm_format_number$FormatNumber$Parser$removeZeros = function (decimals) {
	return (A2($elm$core$String$right, 1, decimals) !== '0') ? decimals : $cuducos$elm_format_number$FormatNumber$Parser$removeZeros(
		A2($elm$core$String$dropRight, 1, decimals));
};
var $cuducos$elm_format_number$FormatNumber$Parser$getDecimals = F2(
	function (locale, digits) {
		var _v0 = locale.k2;
		switch (_v0.$) {
			case 1:
				return $cuducos$elm_format_number$FormatNumber$Parser$removeZeros(digits);
			case 2:
				return digits;
			default:
				var min = _v0.a;
				return A2($cuducos$elm_format_number$FormatNumber$Parser$addZerosToFit, min, digits);
		}
	});
var $myrho$elm_round$Round$addSign = F2(
	function (signed, str) {
		var isNotZero = A2(
			$elm$core$List$any,
			function (c) {
				return (c !== '0') && (c !== '.');
			},
			$elm$core$String$toList(str));
		return _Utils_ap(
			(signed && isNotZero) ? '-' : '',
			str);
	});
var $myrho$elm_round$Round$increaseNum = function (_v0) {
	var head = _v0.a;
	var tail = _v0.b;
	if (head === '9') {
		var _v1 = $elm$core$String$uncons(tail);
		if (_v1.$ === 1) {
			return '01';
		} else {
			var headtail = _v1.a;
			return A2(
				$elm$core$String$cons,
				'0',
				$myrho$elm_round$Round$increaseNum(headtail));
		}
	} else {
		var c = $elm$core$Char$toCode(head);
		return ((c >= 48) && (c < 57)) ? A2(
			$elm$core$String$cons,
			$elm$core$Char$fromCode(c + 1),
			tail) : '0';
	}
};
var $elm$core$Basics$isInfinite = _Basics_isInfinite;
var $elm$core$Basics$isNaN = _Basics_isNaN;
var $elm$core$String$padRight = F3(
	function (n, _char, string) {
		return _Utils_ap(
			string,
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)));
	});
var $elm$core$String$reverse = _String_reverse;
var $myrho$elm_round$Round$splitComma = function (str) {
	var _v0 = A2($elm$core$String$split, '.', str);
	if (_v0.b) {
		if (_v0.b.b) {
			var before = _v0.a;
			var _v1 = _v0.b;
			var after = _v1.a;
			return _Utils_Tuple2(before, after);
		} else {
			var before = _v0.a;
			return _Utils_Tuple2(before, '0');
		}
	} else {
		return _Utils_Tuple2('0', '0');
	}
};
var $myrho$elm_round$Round$toDecimal = function (fl) {
	var _v0 = A2(
		$elm$core$String$split,
		'e',
		$elm$core$String$fromFloat(
			$elm$core$Basics$abs(fl)));
	if (_v0.b) {
		if (_v0.b.b) {
			var num = _v0.a;
			var _v1 = _v0.b;
			var exp = _v1.a;
			var e = A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(
					A2($elm$core$String$startsWith, '+', exp) ? A2($elm$core$String$dropLeft, 1, exp) : exp));
			var _v2 = $myrho$elm_round$Round$splitComma(num);
			var before = _v2.a;
			var after = _v2.b;
			var total = _Utils_ap(before, after);
			var zeroed = (e < 0) ? A2(
				$elm$core$Maybe$withDefault,
				'0',
				A2(
					$elm$core$Maybe$map,
					function (_v3) {
						var a = _v3.a;
						var b = _v3.b;
						return a + ('.' + b);
					},
					A2(
						$elm$core$Maybe$map,
						$elm$core$Tuple$mapFirst($elm$core$String$fromChar),
						$elm$core$String$uncons(
							_Utils_ap(
								A2(
									$elm$core$String$repeat,
									$elm$core$Basics$abs(e),
									'0'),
								total))))) : A3($elm$core$String$padRight, e + 1, '0', total);
			return _Utils_ap(
				(fl < 0) ? '-' : '',
				zeroed);
		} else {
			var num = _v0.a;
			return _Utils_ap(
				(fl < 0) ? '-' : '',
				num);
		}
	} else {
		return '';
	}
};
var $myrho$elm_round$Round$roundFun = F3(
	function (functor, s, fl) {
		if ($elm$core$Basics$isInfinite(fl) || $elm$core$Basics$isNaN(fl)) {
			return $elm$core$String$fromFloat(fl);
		} else {
			var signed = fl < 0;
			var _v0 = $myrho$elm_round$Round$splitComma(
				$myrho$elm_round$Round$toDecimal(
					$elm$core$Basics$abs(fl)));
			var before = _v0.a;
			var after = _v0.b;
			var r = $elm$core$String$length(before) + s;
			var normalized = _Utils_ap(
				A2($elm$core$String$repeat, (-r) + 1, '0'),
				A3(
					$elm$core$String$padRight,
					r,
					'0',
					_Utils_ap(before, after)));
			var totalLen = $elm$core$String$length(normalized);
			var roundDigitIndex = A2($elm$core$Basics$max, 1, r);
			var increase = A2(
				functor,
				signed,
				A3($elm$core$String$slice, roundDigitIndex, totalLen, normalized));
			var remains = A3($elm$core$String$slice, 0, roundDigitIndex, normalized);
			var num = increase ? $elm$core$String$reverse(
				A2(
					$elm$core$Maybe$withDefault,
					'1',
					A2(
						$elm$core$Maybe$map,
						$myrho$elm_round$Round$increaseNum,
						$elm$core$String$uncons(
							$elm$core$String$reverse(remains))))) : remains;
			var numLen = $elm$core$String$length(num);
			var numZeroed = (num === '0') ? num : ((s <= 0) ? _Utils_ap(
				num,
				A2(
					$elm$core$String$repeat,
					$elm$core$Basics$abs(s),
					'0')) : ((_Utils_cmp(
				s,
				$elm$core$String$length(after)) < 0) ? (A3($elm$core$String$slice, 0, numLen - s, num) + ('.' + A3($elm$core$String$slice, numLen - s, numLen, num))) : _Utils_ap(
				before + '.',
				A3($elm$core$String$padRight, s, '0', after))));
			return A2($myrho$elm_round$Round$addSign, signed, numZeroed);
		}
	});
var $myrho$elm_round$Round$round = $myrho$elm_round$Round$roundFun(
	F2(
		function (signed, str) {
			var _v0 = $elm$core$String$uncons(str);
			if (_v0.$ === 1) {
				return false;
			} else {
				if ('5' === _v0.a.a) {
					if (_v0.a.b === '') {
						var _v1 = _v0.a;
						return !signed;
					} else {
						var _v2 = _v0.a;
						return true;
					}
				} else {
					var _v3 = _v0.a;
					var _int = _v3.a;
					return function (i) {
						return ((i > 53) && signed) || ((i >= 53) && (!signed));
					}(
						$elm$core$Char$toCode(_int));
				}
			}
		}));
var $cuducos$elm_format_number$FormatNumber$Parser$splitInParts = F2(
	function (locale, value) {
		var toString = function () {
			var _v1 = locale.k2;
			switch (_v1.$) {
				case 1:
					var max = _v1.a;
					return $myrho$elm_round$Round$round(max);
				case 0:
					return $elm$core$String$fromFloat;
				default:
					var exact = _v1.a;
					return $myrho$elm_round$Round$round(exact);
			}
		}();
		var asList = A2(
			$elm$core$String$split,
			'.',
			toString(value));
		var decimals = function () {
			var _v0 = $elm$core$List$tail(asList);
			if (!_v0.$) {
				var values = _v0.a;
				return A2(
					$elm$core$Maybe$withDefault,
					'',
					$elm$core$List$head(values));
			} else {
				return '';
			}
		}();
		var integers = A2(
			$elm$core$Maybe$withDefault,
			'',
			$elm$core$List$head(asList));
		return _Utils_Tuple2(integers, decimals);
	});
var $cuducos$elm_format_number$FormatNumber$Parser$splitThousands = function (integers) {
	var reversedSplitThousands = function (value) {
		return ($elm$core$String$length(value) > 3) ? A2(
			$elm$core$List$cons,
			A2($elm$core$String$right, 3, value),
			reversedSplitThousands(
				A2($elm$core$String$dropRight, 3, value))) : _List_fromArray(
			[value]);
	};
	return $elm$core$List$reverse(
		reversedSplitThousands(integers));
};
var $cuducos$elm_format_number$FormatNumber$Parser$parse = F2(
	function (locale, original) {
		var parts = A2($cuducos$elm_format_number$FormatNumber$Parser$splitInParts, locale, original);
		var integers = $cuducos$elm_format_number$FormatNumber$Parser$splitThousands(
			A2($elm$core$String$filter, $elm$core$Char$isDigit, parts.a));
		var decimals = A2($cuducos$elm_format_number$FormatNumber$Parser$getDecimals, locale, parts.b);
		var partial = A5($cuducos$elm_format_number$FormatNumber$Parser$FormattedNumber, original, integers, decimals, '', '');
		var _v0 = $cuducos$elm_format_number$FormatNumber$Parser$classify(partial);
		switch (_v0) {
			case 2:
				return _Utils_update(
					partial,
					{eP: locale.mE, e$: locale.mF});
			case 0:
				return _Utils_update(
					partial,
					{eP: locale.ny, e$: locale.nz});
			default:
				return _Utils_update(
					partial,
					{eP: locale.o8, e$: locale.o9});
		}
	});
var $cuducos$elm_format_number$FormatNumber$Stringfy$formatDecimals = F2(
	function (locale, decimals) {
		return (decimals === '') ? '' : _Utils_ap(locale.k1, decimals);
	});
var $cuducos$elm_format_number$FormatNumber$Stringfy$stringfy = F2(
	function (locale, formatted) {
		var stringfyDecimals = $cuducos$elm_format_number$FormatNumber$Stringfy$formatDecimals(locale);
		var integers = A2($elm$core$String$join, locale.oD, formatted.hW);
		var decimals = stringfyDecimals(formatted.k2);
		return $elm$core$String$concat(
			_List_fromArray(
				[formatted.eP, integers, decimals, formatted.e$]));
	});
var $cuducos$elm_format_number$FormatNumber$format = F2(
	function (locale, number_) {
		return A2(
			$cuducos$elm_format_number$FormatNumber$Stringfy$stringfy,
			locale,
			A2($cuducos$elm_format_number$FormatNumber$Parser$parse, locale, number_));
	});
var $author$project$Helpers$floatToString = F2(
	function (decimals, _float) {
		return A2(
			$cuducos$elm_format_number$FormatNumber$format,
			$author$project$Helpers$finnishNumberLocale(decimals),
			_float);
	});
var $mdgriffith$elm_ui$Internal$Model$Rgba = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $mdgriffith$elm_ui$Element$rgb255 = F3(
	function (red, green, blue) {
		return A4($mdgriffith$elm_ui$Internal$Model$Rgba, red / 255, green / 255, blue / 255, 1);
	});
var $author$project$Colors$green = A3($mdgriffith$elm_ui$Element$rgb255, 0, 132, 80);
var $mdgriffith$elm_ui$Element$rgba = $mdgriffith$elm_ui$Internal$Model$Rgba;
var $mdgriffith$elm_ui$Element$toRgb = function (_v0) {
	var r = _v0.a;
	var g = _v0.b;
	var b = _v0.c;
	var a = _v0.d;
	return {jX: a, g$: b, hB: g, iG: r};
};
var $author$project$Colors$add_highlight = F2(
	function (adjustment, color) {
		var parts = $mdgriffith$elm_ui$Element$toRgb(color);
		var r = parts.iG + (adjustment * (1 - parts.iG));
		var g = parts.hB + (adjustment * (1 - parts.hB));
		var b = parts.g$ + (adjustment * (1 - parts.g$));
		return A4($mdgriffith$elm_ui$Element$rgba, r, g, b, parts.jX);
	});
var $author$project$Colors$level = F2(
	function (levelNo, color) {
		var adjustment = levelNo / 9;
		return A2($author$project$Colors$add_highlight, adjustment, color);
	});
var $author$project$Colors$onSurface = function (levelNo) {
	return A2(
		$author$project$Colors$level,
		levelNo,
		A3($mdgriffith$elm_ui$Element$rgb255, 125, 125, 125));
};
var $author$project$Colors$red = A3($mdgriffith$elm_ui$Element$rgb255, 184, 29, 19);
var $author$project$Types$WorkTimeEntry$renderBalanceEffect = function (_v0) {
	var entryType = _v0.ln;
	var balanceEffect = _v0.gX;
	var textColor = function () {
		switch (entryType) {
			case 0:
				return $author$project$Colors$onSurface(5);
			case 1:
				return $author$project$Colors$green;
			case 2:
				return $author$project$Colors$green;
			default:
				return $author$project$Colors$red;
		}
	}();
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Font$color(textColor)
			]),
		$mdgriffith$elm_ui$Element$text(
			function (numberString) {
				return (balanceEffect > 0) ? ('+' + numberString) : numberString;
			}(
				A2($author$project$Helpers$floatToString, 1, balanceEffect))));
};
var $author$project$Types$WorkTimeEntry$totalToString = function (total) {
	return function (totalString) {
		return _Utils_ap(
			(total > 0) ? '+' : '',
			totalString);
	}(
		A2($author$project$Helpers$floatToString, 1, total));
};
var $author$project$Types$WorkTimeEntry$renderSum = function (balanceEffectSum) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Font$color(
				(balanceEffectSum < 0) ? $author$project$Colors$red : ((balanceEffectSum > 0) ? $author$project$Colors$green : $author$project$Colors$onSurface(5)))
			]),
		$mdgriffith$elm_ui$Element$text(
			$author$project$Types$WorkTimeEntry$totalToString(balanceEffectSum)));
};
var $author$project$Components$Table$ColumnApi$valueFunction = F2(
	function (valueFunction_, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{
				dK: $elm$core$Maybe$Just(valueFunction_)
			});
	});
var $author$project$Components$Table$Columns$WorkTimeEntryColumns$entry_balanceEffect = A2(
	$author$project$Components$Table$ColumnApi$width,
	80,
	A2(
		$author$project$Components$Table$ColumnApi$footer,
		{
			jH: $author$project$Types$WorkTimeEntry$aggregates_balanceEffectSum,
			cT: $elm$core$Maybe$Just(
				A2(
					$elm$core$Basics$composeR,
					$elm$core$List$map(
						function ($) {
							return $.gX;
						}),
					A2(
						$elm$core$Basics$composeR,
						$elm$core$List$sum,
						A2(
							$elm$core$Basics$composeR,
							$author$project$Types$WorkTimeEntry$renderSum,
							$mdgriffith$elm_ui$Element$el(
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$centerX]))))))
		},
		A2(
			$author$project$Components$Table$ColumnApi$customRenderer,
			A2(
				$elm$core$Basics$composeR,
				$author$project$Types$WorkTimeEntry$renderBalanceEffect,
				$mdgriffith$elm_ui$Element$el(
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$centerX]))),
			A2(
				$author$project$Components$Table$ColumnApi$valueFunction,
				function (workTimeEntry) {
					return $author$project$Components$Table$ColumnApi$AlwaysCellValue(
						A2($author$project$Types$Value$FloatValue, 1, workTimeEntry.gX));
				},
				A2($author$project$Components$Table$ColumnApi$title, 'Tunnit', $author$project$Components$Table$ColumnApi$init)))));
var $author$project$Components$Table$ColumnApi$MaybeCellValue = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Table$Columns$WorkTimeEntryColumns$entry_comment = A2(
	$author$project$Components$Table$ColumnApi$width,
	300,
	A2(
		$author$project$Components$Table$ColumnApi$valueFunction,
		A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.kH;
			},
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Maybe$map($author$project$Types$Value$StringValue),
				$author$project$Components$Table$ColumnApi$MaybeCellValue)),
		A2($author$project$Components$Table$ColumnApi$title, 'Kommentti', $author$project$Components$Table$ColumnApi$init)));
var $author$project$Types$Value$DateValue = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Table$Columns$WorkTimeEntryColumns$entry_on = A2(
	$author$project$Components$Table$ColumnApi$width,
	110,
	A2(
		$author$project$Components$Table$ColumnApi$valueFunction,
		A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.mJ;
			},
			A2($elm$core$Basics$composeR, $author$project$Types$Value$DateValue, $author$project$Components$Table$ColumnApi$AlwaysCellValue)),
		A2($author$project$Components$Table$ColumnApi$title, 'Kirjauksen päivä', $author$project$Components$Table$ColumnApi$init)));
var $author$project$Types$Value$DateTimeValue = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$unstyled = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Unstyled, $elm$core$Basics$always);
var $mdgriffith$elm_ui$Element$html = $mdgriffith$elm_ui$Internal$Model$unstyled;
var $elm$html$Html$i = _VirtualDom_node('i');
var $author$project$Icons$fa_icon = function (iconName) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[$mdgriffith$elm_ui$Element$centerX]),
		$mdgriffith$elm_ui$Element$html(
			A2(
				$elm$html$Html$i,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class(iconName)
					]),
				_List_Nil)));
};
var $author$project$Icons$ban = $author$project$Icons$fa_icon('fas fa-ban');
var $author$project$Components$Button$icon = F2(
	function (iconElem, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{
				cc: $elm$core$Maybe$Just(iconElem)
			});
	});
var $author$project$Types$WorkTimeEntry$isSigned = function (_v0) {
	var signature = _v0.i2;
	return !_Utils_eq(signature, $elm$core$Maybe$Nothing);
};
var $author$project$Types$WorkTimeEntry$isNotSigned = A2($elm$core$Basics$composeL, $elm$core$Basics$not, $author$project$Types$WorkTimeEntry$isSigned);
var $author$project$Components$Button$tooltip = F2(
	function (tooltip_, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{
				e9: $elm$core$Maybe$Just(tooltip_)
			});
	});
var $author$project$Components$Button$Warning = 3;
var $author$project$Components$Button$warning = function (_v0) {
	var options = _v0;
	return _Utils_update(
		options,
		{bx: 3});
};
var $author$project$Components$Table$Columns$WorkTimeEntryColumns$entry_signature_at = function (onClickRemoveSignature) {
	return A2(
		$author$project$Components$Table$ColumnApi$width,
		170,
		A2(
			$author$project$Components$Table$ColumnApi$buttonFunctions,
			_List_fromArray(
				[
					function (workTimeEntry) {
					return A2(
						$author$project$Components$Button$disabled,
						$author$project$Types$WorkTimeEntry$isNotSigned(workTimeEntry),
						$author$project$Components$Button$warning(
							A2(
								$author$project$Components$Button$icon,
								$author$project$Icons$ban,
								A2(
									$author$project$Components$Button$onClick,
									onClickRemoveSignature(workTimeEntry),
									A2($author$project$Components$Button$tooltip, 'Poista hyväksyntä', $author$project$Components$Button$button)))));
				}
				]),
			A2(
				$author$project$Components$Table$ColumnApi$valueFunction,
				function (workTimeEntry) {
					return $author$project$Components$Table$ColumnApi$MaybeCellValue(
						A2(
							$elm$core$Maybe$map,
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.j4;
								},
								$author$project$Types$Value$DateTimeValue),
							workTimeEntry.i2));
				},
				A2($author$project$Components$Table$ColumnApi$title, 'Hyväksytty', $author$project$Components$Table$ColumnApi$init))));
};
var $author$project$Components$Table$Columns$WorkTimeEntryColumns$entry_signature_by = A2(
	$author$project$Components$Table$ColumnApi$width,
	120,
	A2(
		$author$project$Components$Table$ColumnApi$valueFunction,
		function (workTimeEntry) {
			return $author$project$Components$Table$ColumnApi$MaybeCellValue(
				A2(
					$elm$core$Maybe$map,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.kl;
						},
						$author$project$Types$Value$StringValue),
					workTimeEntry.i2));
		},
		A2($author$project$Components$Table$ColumnApi$title, 'Hyväksynyt', $author$project$Components$Table$ColumnApi$init)));
var $author$project$Types$WorkTimeEntry$typeToString = function (entryType) {
	switch (entryType) {
		case 0:
			return 'korjaus';
		case 1:
			return 'tuntityö';
		case 2:
			return 'ylityö';
		default:
			return 'poissaolo';
	}
};
var $author$project$Components$Table$Columns$WorkTimeEntryColumns$entry_type = A2(
	$author$project$Components$Table$ColumnApi$width,
	90,
	A2(
		$author$project$Components$Table$ColumnApi$valueFunction,
		A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.ln;
			},
			A2(
				$elm$core$Basics$composeR,
				$author$project$Types$WorkTimeEntry$typeToString,
				A2($elm$core$Basics$composeR, $author$project$Types$Value$StringValue, $author$project$Components$Table$ColumnApi$AlwaysCellValue))),
		A2($author$project$Components$Table$ColumnApi$title, 'Kirjaus-tyyppi', $author$project$Components$Table$ColumnApi$init)));
var $author$project$Icons$euro_symbol = $author$project$Icons$fa_icon('fas fa-euro-sign');
var $author$project$Icons$excel_file = $author$project$Icons$fa_icon('far fa-file-excel');
var $author$project$Components$TabbedTable$AllItemsTab$AllItemsTab = $elm$core$Basics$identity;
var $author$project$Components$TabbedTable$AllItemsTab$extraColumns = F2(
	function (columns, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{ec: columns});
	});
var $author$project$Components$Table$TableApi$aggregates = F2(
	function (aggregates_, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{jI: aggregates_});
	});
var $author$project$Components$TabbedTable$TabbedTableApi$groupTabAggregates = F2(
	function (aggregates_, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{
				ol: A2(
					$elm$core$List$map,
					$author$project$Components$Table$TableApi$aggregates(aggregates_),
					options.ol)
			});
	});
var $author$project$Components$TabbedTable$AllItemsTab$init = {ec: _List_Nil, es: true, mD: 'kaikki'};
var $author$project$Components$TabbedTable$Internals$TabbedTableInternals$SubModuleMsg_Table = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$TabbedTable$Internals$TabbedTableInternals$SubModuleMsg_Tabs = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Tabs$TabsInternals$Tab_TextLabel = function (a) {
	return {$: 0, a: a};
};
var $author$project$Types$IsoTime$toUtcString = function (t) {
	return $elm$core$String$fromInt(t.fi) + ('-' + (A3(
		$elm$core$String$padLeft,
		2,
		'0',
		$elm$core$String$fromInt(t.mw)) + ('-' + (A3(
		$elm$core$String$padLeft,
		2,
		'0',
		$elm$core$String$fromInt(t.d5)) + (' ' + (A3(
		$elm$core$String$padLeft,
		2,
		'0',
		$elm$core$String$fromInt(t.ep)) + (':' + (A3(
		$elm$core$String$padLeft,
		2,
		'0',
		$elm$core$String$fromInt(t.ex)) + (':' + A3(
		$elm$core$String$padLeft,
		2,
		'0',
		$elm$core$String$fromInt(t.eX)))))))))));
};
var $author$project$Types$Value$toString = function (value) {
	switch (value.$) {
		case 1:
			var isoTime = value.a;
			return $author$project$Types$IsoTime$toUtcString(isoTime);
		case 0:
			var date = value.a;
			return $justinmimbs$date$Date$toIsoString(date);
		case 2:
			var _float = value.b;
			return $elm$core$String$fromFloat(_float);
		default:
			var string = value.a;
			return string;
	}
};
var $author$project$Components$Table$Internals$TableInternals$cellValue_toString = function (cellValue) {
	return A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$map,
			$author$project$Types$Value$toString,
			function () {
				if (!cellValue.$) {
					var maybeValue = cellValue.a;
					return maybeValue;
				} else {
					var value = cellValue.a;
					return $elm$core$Maybe$Just(value);
				}
			}()));
};
var $author$project$Components$Table$ColumnApi$getValueFunction = function (_v0) {
	var options = _v0;
	return options.dK;
};
var $author$project$Components$Table$Internals$TableInternals$cellValueInColumn = F2(
	function (column, item) {
		return A3(
			$elm$core$Basics$apR,
			$author$project$Components$Table$ColumnApi$getValueFunction(column),
			$elm$core$Maybe$withDefault(
				$elm$core$Basics$always(
					$author$project$Components$Table$ColumnApi$MaybeCellValue($elm$core$Maybe$Nothing))),
			item);
	});
var $author$project$Components$Table$Internals$TableInternals$columnHasThisValue = F3(
	function (thisValue, column, item) {
		return _Utils_eq(
			A2($author$project$Components$Table$Internals$TableInternals$cellValueInColumn, column, item),
			thisValue);
	});
var $author$project$Components$Table$TableApi$hideTitle = function (_v0) {
	var options = _v0;
	return _Utils_update(
		options,
		{hI: true});
};
var $author$project$Helpers$Ascending = 0;
var $author$project$Components$Table$Internals$TableInternals$RowSelectionDisabled = {$: 0};
var $author$project$Components$Table$Internals$TableInternals$tableDefaultOptions = function (msgTransformer) {
	return {
		jI: _List_Nil,
		cP: _List_Nil,
		hI: false,
		dc: _List_Nil,
		f_: msgTransformer,
		gd: $author$project$Components$Table$Internals$TableInternals$RowSelectionDisabled,
		iY: _List_Nil,
		gj: false,
		i8: {kF: 0, n8: 0},
		oI: $elm$core$Maybe$Nothing
	};
};
var $author$project$Components$Table$TableApi$init = function (msgTransformer) {
	return $author$project$Components$Table$Internals$TableInternals$tableDefaultOptions(msgTransformer);
};
var $author$project$Components$Tabs$TabsApi$Tabs = $elm$core$Basics$identity;
var $mdgriffith$elm_ui$Internal$Model$Empty = {$: 3};
var $mdgriffith$elm_ui$Element$none = $mdgriffith$elm_ui$Internal$Model$Empty;
var $author$project$Components$Tabs$TabsInternals$defaultOptions = function (msgTransformer) {
	return {
		f_: msgTransformer,
		nB: _List_Nil,
		nK: _List_Nil,
		gg: {
			d3: $mdgriffith$elm_ui$Element$none,
			ma: $author$project$Components$Tabs$TabsInternals$Tab_TextLabel('-')
		}
	};
};
var $author$project$Components$Tabs$TabsApi$init = function (msgTransformer) {
	return $author$project$Components$Tabs$TabsInternals$defaultOptions(msgTransformer);
};
var $author$project$Components$Table$TableApi$rows = F2(
	function (rows_, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{dc: rows_});
	});
var $author$project$Components$Tabs$TabsApi$setTabs = F2(
	function (tabs_, _v0) {
		var options = _v0;
		if (tabs_.b) {
			var firstTab = tabs_.a;
			var rest = tabs_.b;
			return _Utils_update(
				options,
				{nB: _List_Nil, nK: rest, gg: firstTab});
		} else {
			return _Utils_update(
				options,
				{
					nB: _List_Nil,
					nK: _List_Nil,
					gg: {
						d3: $mdgriffith$elm_ui$Element$none,
						ma: $author$project$Components$Tabs$TabsInternals$Tab_TextLabel('-')
					}
				});
		}
	});
var $author$project$Components$Table$TableApi$title = F2(
	function (title_, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{
				oI: $elm$core$Maybe$Just(title_)
			});
	});
var $elm_community$list_extra$List$Extra$uniqueBy = F2(
	function (f, list) {
		return A4($elm_community$list_extra$List$Extra$uniqueHelp, f, $elm$core$Set$empty, list, _List_Nil);
	});
var $author$project$Components$Table$Internals$TableInternals$uniqueCellValuesInColumn = F2(
	function (column, items) {
		return A2(
			$elm_community$list_extra$List$Extra$uniqueBy,
			$author$project$Components$Table$Internals$TableInternals$cellValue_toString,
			A2(
				$elm$core$List$map,
				$author$project$Components$Table$Internals$TableInternals$cellValueInColumn(column),
				items));
	});
var $author$project$Components$TabbedTable$TabbedTableApi$init = function (_v0) {
	var allItems = _v0.jS;
	var groupByColumn = _v0.hC;
	var msgTransformer = _v0.f_;
	var tabs = A2(
		$elm$core$List$map,
		function (groupByColumnValue) {
			return {
				d3: $mdgriffith$elm_ui$Element$none,
				ma: $author$project$Components$Tabs$TabsInternals$Tab_TextLabel(
					$author$project$Components$Table$Internals$TableInternals$cellValue_toString(groupByColumnValue))
			};
		},
		A2($author$project$Components$Table$Internals$TableInternals$uniqueCellValuesInColumn, groupByColumn, allItems));
	var groupColumnValues = A2($author$project$Components$Table$Internals$TableInternals$uniqueCellValuesInColumn, groupByColumn, allItems);
	var tables = A2(
		$elm$core$List$map,
		function (groupedColumnCellValue) {
			return A2(
				$author$project$Components$Table$TableApi$rows,
				A2(
					$elm$core$List$filter,
					A2($author$project$Components$Table$Internals$TableInternals$columnHasThisValue, groupedColumnCellValue, groupByColumn),
					allItems),
				$author$project$Components$Table$TableApi$hideTitle(
					A2(
						$author$project$Components$Table$TableApi$title,
						$author$project$Components$Table$Internals$TableInternals$cellValue_toString(groupedColumnCellValue),
						$author$project$Components$Table$TableApi$init(
							A2($elm$core$Basics$composeR, $author$project$Components$TabbedTable$Internals$TabbedTableInternals$SubModuleMsg_Table, msgTransformer)))));
		},
		groupColumnValues);
	return {
		jS: allItems,
		jT: $elm$core$Maybe$Nothing,
		hC: groupByColumn,
		f_: msgTransformer,
		iZ: 0,
		ol: tables,
		om: A2(
			$author$project$Components$Tabs$TabsApi$setTabs,
			tabs,
			$author$project$Components$Tabs$TabsApi$init(
				A2($elm$core$Basics$composeR, $author$project$Components$TabbedTable$Internals$TabbedTableInternals$SubModuleMsg_Tabs, msgTransformer))),
		oI: $elm$core$Maybe$Nothing
	};
};
var $author$project$Types$WorkTimeEntry$isNotPaid = function (entry) {
	return _Utils_eq(entry.cm, $elm$core$Maybe$Nothing);
};
var $author$project$Types$WorkTimeEntry$isReflectInPay = function ($) {
	return $.bF;
};
var $author$project$Types$WorkTimeEntry$isInPaymentQueue = function (entry) {
	return $author$project$Types$WorkTimeEntry$isSigned(entry) && ($author$project$Types$WorkTimeEntry$isReflectInPay(entry) && $author$project$Types$WorkTimeEntry$isNotPaid(entry));
};
var $author$project$Components$TabbedTable$AllItemsTab$name = F2(
	function (name_, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{mD: name_});
	});
var $author$project$Components$Table$TableApi$selectedRowActions = F2(
	function (actions, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{iY: actions});
	});
var $author$project$Components$TabbedTable$TabbedTableApi$selectedRowActions = F2(
	function (actions, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{
				ol: A2(
					$elm$core$List$map,
					$author$project$Components$Table$TableApi$selectedRowActions(actions),
					options.ol)
			});
	});
var $author$project$Components$TabbedTable$TabbedTableApi$title = F2(
	function (title_, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{
				oI: $elm$core$Maybe$Just(title_)
			});
	});
var $author$project$Components$Table$Columns$WorkerColumns$compensationType_value = F2(
	function (workers, workerId) {
		return $author$project$Components$Table$ColumnApi$MaybeCellValue(
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.kI;
					},
					A2($elm$core$Basics$composeR, $author$project$Types$Worker$compensationTypeToString, $author$project$Types$Value$StringValue)),
				A2($author$project$Types$Worker$findFromList, workers, workerId)));
	});
var $author$project$Components$Table$Columns$WorkTimeEntryColumns$worker_compensationType = function (workers) {
	return A2(
		$author$project$Components$Table$ColumnApi$width,
		100,
		A2(
			$author$project$Components$Table$ColumnApi$valueFunction,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.jy;
				},
				$author$project$Components$Table$Columns$WorkerColumns$compensationType_value(workers)),
			A2($author$project$Components$Table$ColumnApi$title, 'Sopimus-tyyppi', $author$project$Components$Table$ColumnApi$init)));
};
var $author$project$Components$Table$Columns$WorkerColumns$name_value = F2(
	function (workers, workerId) {
		return $author$project$Components$Table$ColumnApi$MaybeCellValue(
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.mD;
					},
					$author$project$Types$Value$StringValue),
				A2($author$project$Types$Worker$findFromList, workers, workerId)));
	});
var $author$project$Components$Table$Columns$WorkTimeEntryColumns$worker_name = function (workers) {
	return A2(
		$author$project$Components$Table$ColumnApi$width,
		200,
		A2(
			$author$project$Components$Table$ColumnApi$valueFunction,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.jy;
				},
				$author$project$Components$Table$Columns$WorkerColumns$name_value(workers)),
			A2($author$project$Components$Table$ColumnApi$title, 'Työntekijä', $author$project$Components$Table$ColumnApi$init)));
};
var $author$project$Pages$WorkTimeTracking$State_Main$initPaymentQueueTabbedTable = F2(
	function (workTimeEntries, workers) {
		var paymentQueueEntries = A2($elm$core$List$filter, $author$project$Types$WorkTimeEntry$isInPaymentQueue, workTimeEntries);
		return A2(
			$author$project$Components$TabbedTable$TabbedTableApi$columns,
			_List_fromArray(
				[
					$author$project$Components$Table$Columns$WorkTimeEntryColumns$entry_on,
					$author$project$Components$Table$Columns$WorkTimeEntryColumns$entry_type,
					$author$project$Components$Table$Columns$WorkTimeEntryColumns$entry_balanceEffect,
					$author$project$Components$Table$Columns$WorkTimeEntryColumns$entry_comment,
					$author$project$Components$Table$Columns$WorkTimeEntryColumns$entry_signature_at($author$project$Pages$WorkTimeTracking$State_Main$Entry_Clicked_Unsign),
					$author$project$Components$Table$Columns$WorkTimeEntryColumns$entry_signature_by,
					$author$project$Components$Table$Columns$WorkTimeEntryColumns$button_mark_as_paid(
					A2($elm$core$Basics$composeR, $elm$core$List$singleton, $author$project$Pages$WorkTimeTracking$State_Main$Entry_Clicked_MarkAsPaid))
				]),
			A2(
				$author$project$Components$TabbedTable$TabbedTableApi$allItemsTab,
				A2(
					$author$project$Components$TabbedTable$AllItemsTab$extraColumns,
					_List_fromArray(
						[
							$author$project$Components$Table$Columns$WorkTimeEntryColumns$worker_compensationType(workers)
						]),
					A2($author$project$Components$TabbedTable$AllItemsTab$name, 'kaikki työntekijät', $author$project$Components$TabbedTable$AllItemsTab$init)),
				$author$project$Components$TabbedTable$TabbedTableApi$enableRowSelection(
					A2(
						$author$project$Components$TabbedTable$TabbedTableApi$groupTabAggregates,
						_List_fromArray(
							[
								$author$project$Types$WorkTimeEntry$aggregates_compensationType(workers)
							]),
						A2(
							$author$project$Components$TabbedTable$TabbedTableApi$selectedRowActions,
							_List_fromArray(
								[
									{
									cc: $author$project$Icons$excel_file,
									mD: 'Lataa Excel-tiedostoon',
									cj: $author$project$Pages$WorkTimeTracking$State_Main$Export_WorkTimeEntries,
									e9: $elm$core$Maybe$Just('Lataa (valitut) rivit Excel-tiedostoon')
								},
									{
									cc: $author$project$Icons$euro_symbol,
									mD: 'Kuittaa maksetuiksi',
									cj: $author$project$Pages$WorkTimeTracking$State_Main$Entry_Clicked_MarkAsPaid,
									e9: $elm$core$Maybe$Just('Kuittaa (valitut) rivit maksetuiksi')
								}
								]),
							A2(
								$author$project$Components$TabbedTable$TabbedTableApi$title,
								'Maksujono',
								$author$project$Components$TabbedTable$TabbedTableApi$init(
									{
										jS: paymentQueueEntries,
										hC: $author$project$Components$Table$Columns$WorkTimeEntryColumns$worker_name(workers),
										f_: $author$project$Pages$WorkTimeTracking$State_Main$SubMsg_PaymentQueueTabbedTableMsg
									})))))));
	});
var $author$project$Pages$WorkTimeTracking$State_Main$init = function (_v0) {
	var entries = _v0.lm;
	var payments = _v0.nr;
	var workers = _v0.jA;
	var initialModel = {
		c1: $author$project$Pages$WorkTimeTracking$State_Main$NoDialog,
		lm: entries,
		a9: A2($author$project$Pages$WorkTimeTracking$State_Main$initPaymentQueueTabbedTable, entries, workers),
		nr: payments,
		n$: $elm$core$Set$empty,
		eY: {fo: false},
		jA: workers
	};
	return _Utils_Tuple3(initialModel, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$State_Main$OutNoOp);
};
var $author$project$Pages$WorkTimeTracking$Main = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$WorkTimeTracking$Msg_Main = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$WorkTimeTracking$State_WorkerPage$NoDialog = {$: 3};
var $author$project$Pages$WorkTimeTracking$State_WorkerPage$OutNoOp = {$: 3};
var $author$project$Pages$WorkTimeTracking$State_WorkerPage$init = F5(
	function (user, worker, selectedYear, entries, payments) {
		var initialModel = {
			c1: $author$project$Pages$WorkTimeTracking$State_WorkerPage$NoDialog,
			lm: entries,
			nr: payments,
			bG: selectedYear,
			eY: {fo: false},
			gA: user,
			bR: worker
		};
		return _Utils_Tuple3(initialModel, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$State_WorkerPage$OutNoOp);
	});
var $author$project$Pages$WorkTimeTracking$Msg_WorkerPage = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$WorkTimeTracking$WorkerPage = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$WorkTimeTracking$update_workerPageState = A3(
	$author$project$Utils$Modules$convertSubToMain,
	$author$project$Pages$WorkTimeTracking$WorkerPage,
	$author$project$Pages$WorkTimeTracking$Msg_WorkerPage,
	function (subOutMsg2) {
		switch (subOutMsg2.$) {
			case 2:
				return $author$project$Pages$WorkTimeTracking$OutGotAuthError;
			case 1:
				return $author$project$Pages$WorkTimeTracking$OutNoOp;
			case 0:
				var notification = subOutMsg2.a;
				return $author$project$Pages$WorkTimeTracking$OutNotification(notification);
			default:
				return $author$project$Pages$WorkTimeTracking$OutNoOp;
		}
	});
var $author$project$Pages$WorkTimeTracking$update_mainState = F3(
	function (appContext, user, _v0) {
		var model = _v0.a;
		var msg = _v0.b;
		var outMsg = _v0.c;
		if (outMsg.$ === 2) {
			var worker = outMsg.a;
			return $author$project$Pages$WorkTimeTracking$update_workerPageState(
				A5(
					$author$project$Pages$WorkTimeTracking$State_WorkerPage$init,
					user,
					worker,
					$justinmimbs$date$Date$year(appContext.dE),
					A2(
						$elm$core$List$filter,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.jy;
							},
							$elm$core$Basics$eq(worker.er)),
						model.lm),
					A2(
						$elm$core$List$filter,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.jy;
							},
							$elm$core$Basics$eq(worker.er)),
						model.nr)));
		} else {
			return A4(
				$author$project$Utils$Modules$convertSubToMain,
				$author$project$Pages$WorkTimeTracking$Main,
				$author$project$Pages$WorkTimeTracking$Msg_Main,
				function (subOutMsg2) {
					switch (subOutMsg2.$) {
						case 1:
							return $author$project$Pages$WorkTimeTracking$OutGotAuthError;
						case 0:
							var notification = subOutMsg2.a;
							return $author$project$Pages$WorkTimeTracking$OutNotification(notification);
						case 2:
							return $author$project$Pages$WorkTimeTracking$OutNoOp;
						default:
							return $author$project$Pages$WorkTimeTracking$OutNoOp;
					}
				},
				_Utils_Tuple3(model, msg, outMsg));
		}
	});
var $author$project$Pages$WorkTimeTracking$maybeTransitionFrom_loading = F3(
	function (appContext, user, subOutMsg) {
		if (!subOutMsg.$) {
			var data = subOutMsg.a;
			return $elm$core$Maybe$Just(
				A3(
					$author$project$Pages$WorkTimeTracking$update_mainState,
					appContext,
					user,
					$author$project$Pages$WorkTimeTracking$State_Main$init(data)));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Pages$WorkTimeTracking$maybeTransitionFrom_workerPage = F2(
	function (serverContext, subOutMsg) {
		if (subOutMsg.$ === 1) {
			return $elm$core$Maybe$Just(
				$author$project$Pages$WorkTimeTracking$update_loadingState(
					$author$project$Pages$WorkTimeTracking$State_Loading$init(serverContext)));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Components$PinDialog$OutPinCorrect = 0;
var $author$project$Components$PinDialog$update = F3(
	function (_v0, msg, model) {
		var globalPin = _v0.lA;
		switch (msg.$) {
			case 0:
				var currentPin = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							cR: $elm$core$List$reverse(
								A2(
									$elm$core$List$drop,
									1,
									$elm$core$List$reverse(currentPin)))
						}),
					$elm$core$Platform$Cmd$none,
					1);
			case 1:
				var currentPin = msg.a;
				var newNumber = msg.b;
				var updatedPin = _Utils_ap(
					currentPin,
					_List_fromArray(
						[newNumber]));
				var isCorrectPin = _Utils_eq(
					globalPin,
					$elm$core$String$concat(
						A2($elm$core$List$map, $elm$core$String$fromInt, updatedPin)));
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{cR: updatedPin}),
					$elm$core$Platform$Cmd$none,
					isCorrectPin ? 0 : 1);
			default:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, 1);
		}
	});
var $author$project$Pages$WorkTimeTracking$State_Loading$OutGotAuthError = {$: 1};
var $author$project$Pages$WorkTimeTracking$State_Loading$OutGotData = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$WorkTimeTracking$State_Loading$OutNotification = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$WorkTimeTracking$State_Loading$update = F2(
	function (msg, model) {
		if (!msg.$) {
			var data = msg.a;
			return _Utils_Tuple3(
				model,
				$elm$core$Platform$Cmd$none,
				$author$project$Pages$WorkTimeTracking$State_Loading$OutGotData(data));
		} else {
			var errorMessage = msg.a;
			if ((errorMessage.$ === 3) && (errorMessage.a === 401)) {
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$State_Loading$OutGotAuthError);
			} else {
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$WorkTimeTracking$State_Loading$OutNotification(
						A2($author$project$Components$Notification$Notification, 'Virhe ladattaessa tietoja.', 1)));
			}
		}
	});
var $author$project$Pages$WorkTimeTracking$State_Main$Dialog_EditEntry = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$WorkTimeTracking$State_Main$Dialog_MarkEntryAsPaid = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$WorkTimeTracking$State_Main$Entry_GotResponse_MarkAsPaid = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Pages$WorkTimeTracking$State_Main$Entry_GotResponse_Sign = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$Pages$WorkTimeTracking$State_Main$Entry_GotResponse_Unsign = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $author$project$Pages$WorkTimeTracking$State_Main$GotExcelFile = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$WorkTimeTracking$State_Main$OutGotAuthError = {$: 1};
var $author$project$Pages$WorkTimeTracking$State_Main$OutNotification = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$WorkTimeTracking$State_Main$OutWorkerClicked = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$WorkTimeTracking$State_Main$SubMsg_EditEntryDialog = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$WorkTimeTracking$State_Main$SubMsg_MarkEntryAsPaidDialog = function (a) {
	return {$: 13, a: a};
};
var $author$project$Helpers$firstMatchInList = F2(
	function (isGood, list) {
		return $elm$core$List$head(
			A2($elm$core$List$filter, isGood, list));
	});
var $author$project$Types$WorkTimePayment$isForEntry = F2(
	function (workTimeEntryId, payment) {
		return A3(
			$elm$core$Basics$composeL,
			$elm$core$Basics$not,
			$elm$core$List$isEmpty,
			A2(
				$elm$core$List$filter,
				$elm$core$Basics$eq(workTimeEntryId),
				payment.ho));
	});
var $author$project$Types$WorkTimeEntry$applyPayments = F2(
	function (payments, entries) {
		return A2(
			$elm$core$List$map,
			function (entry) {
				return _Utils_update(
					entry,
					{
						cm: _Utils_eq(entry.cm, $elm$core$Maybe$Nothing) ? A2(
							$author$project$Helpers$firstMatchInList,
							$author$project$Types$WorkTimePayment$isForEntry(entry.er),
							payments) : entry.cm
					});
			},
			entries);
	});
var $author$project$Utils$ServerUtils$blockServerRequests = function (_v0) {
	var model = _v0.a;
	var cmd = _v0.b;
	var outMsg = _v0.c;
	return _Utils_Tuple3(
		_Utils_update(
			model,
			{
				eY: {fo: true}
			}),
		cmd,
		outMsg);
};
var $author$project$Types$ExcelFile$fetchWithParametersCmd = F4(
	function (_v0, path, httpBody, onGotExcelFileFromServer) {
		var apiUrl = _v0.j0;
		var token = _v0.oK;
		return A4(
			$author$project$JwtHttp$post,
			token,
			_Utils_ap(apiUrl, path),
			httpBody,
			A2(
				$elm$http$Http$expectBytesResponse,
				onGotExcelFileFromServer,
				$author$project$Types$ExcelFile$resolveExcelFileResponse($elm$core$Result$Ok)));
	});
var $author$project$Utils$ServerUtils$unblockServerRequests = function (_v0) {
	var model = _v0.a;
	var cmd = _v0.b;
	var outMsg = _v0.c;
	return _Utils_Tuple3(
		_Utils_update(
			model,
			{
				eY: {fo: false}
			}),
		cmd,
		outMsg);
};
var $author$project$Pages$WorkTimeTracking$State_Main$handleErrorResponse = F3(
	function (model, messageToUser, error) {
		if ((error.$ === 3) && (error.a === 401)) {
			return $author$project$Utils$ServerUtils$unblockServerRequests(
				_Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$State_Main$OutGotAuthError));
		} else {
			return $author$project$Utils$ServerUtils$unblockServerRequests(
				_Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$WorkTimeTracking$State_Main$OutNotification(
						A2($author$project$Components$Notification$Notification, messageToUser, 1))));
		}
	});
var $author$project$Pages$WorkTimeTracking$EditEntryDialog$NoDialog = 1;
var $author$project$Pages$WorkTimeTracking$EditEntryDialog$init = F3(
	function (user, worker, entry) {
		var initialModel = {
			c1: 1,
			s: entry,
			eY: {fo: false},
			gA: user,
			bR: worker
		};
		return initialModel;
	});
var $author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$NoDialog = {$: 1};
var $author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$init = F2(
	function (today, entries) {
		var initialModel = {c1: $author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$NoDialog, lm: entries, dp: '', bC: today};
		return initialModel;
	});
var $author$project$Types$Date$jsonEncoder = function (date) {
	return $elm$json$Json$Encode$string(
		A2($justinmimbs$date$Date$format, 'y-MM-dd', date));
};
var $author$project$Types$WorkTimeEntry$markAsPaidCmd = F4(
	function (_v0, responseHandler, user, _v1) {
		var apiUrl = _v0.j0;
		var token = _v0.oK;
		var entryIds = _v1.ho;
		var paymentComment = _v1.dp;
		var paymentOn = _v1.nq;
		return A4(
			$author$project$JwtHttp$post,
			token,
			apiUrl + '/work-time/entries/mark-as-paid',
			$elm$http$Http$jsonBody(
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'entryIds',
							A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, entryIds)),
							_Utils_Tuple2(
							'userId',
							$elm$json$Json$Encode$int(
								$author$project$Types$User$id(user))),
							_Utils_Tuple2(
							'paymentOn',
							$author$project$Types$Date$jsonEncoder(paymentOn)),
							_Utils_Tuple2(
							'paymentComment',
							$elm$json$Json$Encode$string(
								A2($elm$core$Maybe$withDefault, '', paymentComment)))
						]))),
			A2($elm$http$Http$expectJson, responseHandler, $author$project$Data$ServerResponse$serverResponseDecoder));
	});
var $author$project$Types$WorkTimeEntry$MarkAsPaidResponse = function (payments) {
	return {nr: payments};
};
var $author$project$Types$WorkTimeEntry$markAsPaidResponseJsonDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'payments',
	$elm$json$Json$Decode$list($author$project$Types$WorkTimePayment$jsonDecoder),
	$elm$json$Json$Decode$succeed($author$project$Types$WorkTimeEntry$MarkAsPaidResponse));
var $author$project$Types$WorkTimeEntry$signCmd = F4(
	function (_v0, responseHandler, userId, id) {
		var apiUrl = _v0.j0;
		var token = _v0.oK;
		return A4(
			$author$project$JwtHttp$post,
			token,
			apiUrl + '/work-time/entries/sign',
			$elm$http$Http$jsonBody(
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'id',
							$elm$json$Json$Encode$string(id)),
							_Utils_Tuple2(
							'userId',
							$elm$json$Json$Encode$int(userId))
						]))),
			A2($elm$http$Http$expectJson, responseHandler, $author$project$Data$ServerResponse$serverResponseDecoder));
	});
var $author$project$Types$WorkTimeEntry$SignResponse = function (signature) {
	return {i2: signature};
};
var $author$project$Types$WorkTimeEntry$signResponseJsonDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'signature',
	$author$project$Types$Signature$jsonDecoder,
	$elm$json$Json$Decode$succeed($author$project$Types$WorkTimeEntry$SignResponse));
var $author$project$Types$WorkTimeEntry$unsignCmd = F3(
	function (_v0, responseHandler, id) {
		var apiUrl = _v0.j0;
		var token = _v0.oK;
		return A4(
			$author$project$JwtHttp$post,
			token,
			apiUrl + '/work-time/entries/unsign',
			$elm$http$Http$jsonBody(
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'id',
							$elm$json$Json$Encode$string(id))
						]))),
			A2($elm$http$Http$expectJson, responseHandler, $author$project$Data$ServerResponse$serverResponseDecoder));
	});
var $author$project$Components$Table$TableApi$deselectRows = function (_v0) {
	var options = _v0;
	var _v1 = options.gd;
	if (_v1.$ === 1) {
		return _Utils_update(
			options,
			{
				gd: A2($author$project$Components$Table$Internals$TableInternals$RowSelectionEnabled, 0, _List_Nil)
			});
	} else {
		return options;
	}
};
var $author$project$Helpers$Descending = 1;
var $author$project$Helpers$switchOrder = function (direction) {
	if (!direction) {
		return 1;
	} else {
		return 0;
	}
};
var $author$project$Components$Table$Internals$TableInternals$RowSelectionMenuOpen = 1;
var $author$project$Components$Table$TableApi$toggleSelectedRowsActionMenu = function (options) {
	var _v0 = options.gd;
	if (!_v0.$) {
		return options;
	} else {
		var menuState = _v0.a;
		var selectedItems = _v0.b;
		return _Utils_update(
			options,
			{
				gd: A2(
					$author$project$Components$Table$Internals$TableInternals$RowSelectionEnabled,
					function () {
						if (menuState === 1) {
							return 0;
						} else {
							return 1;
						}
					}(),
					selectedItems)
			});
	}
};
var $author$project$Components$Table$TableApi$update = F2(
	function (msg, _v0) {
		var options = _v0;
		switch (msg.$) {
			case 0:
				var newColumnNumber = msg.a;
				return _Utils_update(
					options,
					{
						i8: {
							kF: newColumnNumber,
							n8: _Utils_eq(options.i8.kF, newColumnNumber) ? $author$project$Helpers$switchOrder(options.i8.n8) : 0
						}
					});
			case 4:
				var _v2 = options.gd;
				if (!_v2.$) {
					return options;
				} else {
					return _Utils_update(
						options,
						{
							gd: A2($author$project$Components$Table$Internals$TableInternals$RowSelectionEnabled, 0, options.dc)
						});
				}
			case 5:
				var _v3 = options.gd;
				if (!_v3.$) {
					return options;
				} else {
					return _Utils_update(
						options,
						{
							gd: A2($author$project$Components$Table$Internals$TableInternals$RowSelectionEnabled, 0, _List_Nil)
						});
				}
			case 1:
				var item = msg.a;
				var wasSelected = msg.b;
				var _v4 = options.gd;
				if (!_v4.$) {
					return options;
				} else {
					var selectedItems = _v4.b;
					return _Utils_update(
						options,
						{
							gd: A2(
								$author$project$Components$Table$Internals$TableInternals$RowSelectionEnabled,
								0,
								wasSelected ? A2($elm$core$List$cons, item, selectedItems) : A2(
									$elm$core$List$filter,
									$elm$core$Basics$neq(item),
									selectedItems))
						});
				}
			case 2:
				return $author$project$Components$Table$TableApi$toggleSelectedRowsActionMenu(options);
			case 3:
				return $author$project$Components$Table$TableApi$toggleSelectedRowsActionMenu(options);
			default:
				return options;
		}
	});
var $author$project$Components$Tabs$TabsApi$OutSwitchedToTab = $elm$core$Basics$identity;
var $author$project$Components$Tabs$TabsInternals$allTabs = function (_v0) {
	var previousTabs = _v0.nB;
	var selectedTab = _v0.gg;
	var restOfTabs = _v0.nK;
	return $elm$core$List$concat(
		_List_fromArray(
			[
				previousTabs,
				_List_fromArray(
				[selectedTab]),
				restOfTabs
			]));
};
var $author$project$Components$Tabs$TabsInternals$isSameTab = F2(
	function (tabA, tabB) {
		return _Utils_eq(tabA.ma, tabB.ma);
	});
var $elm_community$list_extra$List$Extra$takeWhile = function (predicate) {
	var takeWhileMemo = F2(
		function (memo, list) {
			takeWhileMemo:
			while (true) {
				if (!list.b) {
					return $elm$core$List$reverse(memo);
				} else {
					var x = list.a;
					var xs = list.b;
					if (predicate(x)) {
						var $temp$memo = A2($elm$core$List$cons, x, memo),
							$temp$list = xs;
						memo = $temp$memo;
						list = $temp$list;
						continue takeWhileMemo;
					} else {
						return $elm$core$List$reverse(memo);
					}
				}
			}
		});
	return takeWhileMemo(_List_Nil);
};
var $author$project$Components$Tabs$TabsInternals$switchToTab = F2(
	function (targetTab, options) {
		return _Utils_update(
			options,
			{
				nB: A2(
					$elm_community$list_extra$List$Extra$takeWhile,
					A2(
						$elm$core$Basics$composeL,
						$elm$core$Basics$not,
						$author$project$Components$Tabs$TabsInternals$isSameTab(targetTab)),
					$author$project$Components$Tabs$TabsInternals$allTabs(options)),
				nK: $elm$core$List$reverse(
					A2(
						$elm_community$list_extra$List$Extra$takeWhile,
						A2(
							$elm$core$Basics$composeL,
							$elm$core$Basics$not,
							$author$project$Components$Tabs$TabsInternals$isSameTab(targetTab)),
						$elm$core$List$reverse(
							$author$project$Components$Tabs$TabsInternals$allTabs(options)))),
				gg: targetTab
			});
	});
var $author$project$Components$Tabs$TabsApi$update = F2(
	function (_v0, msg) {
		var options = _v0;
		var targetTab = msg;
		return _Utils_Tuple2(
			A2($author$project$Components$Tabs$TabsInternals$switchToTab, targetTab, options),
			targetTab);
	});
var $author$project$Components$TabbedTable$TabbedTableApi$update = F2(
	function (_v0, msg) {
		var options = _v0;
		switch (msg.$) {
			case 2:
				var targetTabIndex = msg.a;
				return _Utils_update(
					options,
					{
						iZ: targetTabIndex,
						ol: A2($elm$core$List$map, $author$project$Components$Table$TableApi$deselectRows, options.ol)
					});
			case 1:
				var subMsg = msg.a;
				return _Utils_update(
					options,
					{
						ol: A2(
							$elm$core$List$map,
							$author$project$Components$Table$TableApi$update(subMsg),
							options.ol)
					});
			default:
				var subMsg = msg.a;
				var _v2 = A2($author$project$Components$Tabs$TabsApi$update, options.om, subMsg);
				var updatedTabs = _v2.a;
				var tabsOutMsg = _v2.b;
				return _Utils_update(
					options,
					{om: updatedTabs});
		}
	});
var $author$project$Pages$WorkTimeTracking$EditEntryDialog$GotResponse_DeleteEntry = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$WorkTimeTracking$EditEntryDialog$GotResponse_EditEntry = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$WorkTimeTracking$EditEntryDialog$HoursSelectionDialog = 0;
var $author$project$Pages$WorkTimeTracking$EditEntryDialog$OutEntryDeleted = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$WorkTimeTracking$EditEntryDialog$OutEntryEdited = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$WorkTimeTracking$EditEntryDialog$OutHide = {$: 2};
var $author$project$Pages$WorkTimeTracking$EditEntryDialog$OutNoOp = {$: 5};
var $author$project$Types$WorkTimeEntry$entryTypeJsonEncoder = function (entryType) {
	return $elm$json$Json$Encode$string(
		function () {
			switch (entryType) {
				case 0:
					return 'CORRECTION';
				case 1:
					return 'HOURLY_WORK';
				case 2:
					return 'OVERTIME';
				default:
					return 'TIME_OFF';
			}
		}());
};
var $author$project$Types$WorkTimeEntry$editCmd = F4(
	function (_v0, responseHandler, user, _v1) {
		var apiUrl = _v0.j0;
		var token = _v0.oK;
		var id = _v1.er;
		var workerId = _v1.jy;
		var balanceEffect = _v1.gX;
		var comment = _v1.kH;
		var entryType = _v1.ln;
		var on = _v1.mJ;
		var reflectInPay = _v1.bF;
		return A4(
			$author$project$JwtHttp$post,
			token,
			apiUrl + '/work-time/entries/edit',
			$elm$http$Http$jsonBody(
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'id',
							$elm$json$Json$Encode$string(id)),
							_Utils_Tuple2(
							'workerId',
							$elm$json$Json$Encode$int(workerId)),
							_Utils_Tuple2(
							'userId',
							$elm$json$Json$Encode$int(
								$author$project$Types$User$id(user))),
							_Utils_Tuple2(
							'balanceEffect',
							$elm$json$Json$Encode$float(balanceEffect)),
							_Utils_Tuple2(
							'comment',
							$elm$json$Json$Encode$string(
								A2($elm$core$Maybe$withDefault, '', comment))),
							_Utils_Tuple2(
							'entryType',
							$author$project$Types$WorkTimeEntry$entryTypeJsonEncoder(entryType)),
							_Utils_Tuple2(
							'on',
							$author$project$Types$Date$jsonEncoder(on)),
							_Utils_Tuple2(
							'reflectInPay',
							$elm$json$Json$Encode$bool(reflectInPay))
						]))),
			A2($elm$http$Http$expectJson, responseHandler, $author$project$Data$ServerResponse$serverResponseDecoder));
	});
var $author$project$Pages$WorkTimeTracking$EditEntryDialog$OutGotAuthError = {$: 1};
var $author$project$Pages$WorkTimeTracking$EditEntryDialog$OutNotification = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$WorkTimeTracking$EditEntryDialog$handleErrorResponse = F3(
	function (model, messageToUser, error) {
		if ((error.$ === 3) && (error.a === 401)) {
			return $author$project$Utils$ServerUtils$unblockServerRequests(
				_Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$EditEntryDialog$OutGotAuthError));
		} else {
			return $author$project$Utils$ServerUtils$unblockServerRequests(
				_Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$WorkTimeTracking$EditEntryDialog$OutNotification(
						A2($author$project$Components$Notification$Notification, messageToUser, 1))));
		}
	});
var $author$project$Types$WorkTimeEntry$removeCmd = F4(
	function (_v0, responseHandler, user, id) {
		var apiUrl = _v0.j0;
		var token = _v0.oK;
		return A4(
			$author$project$JwtHttp$post,
			token,
			apiUrl + '/work-time/entries/remove',
			$elm$http$Http$jsonBody(
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'id',
							$elm$json$Json$Encode$string(id)),
							_Utils_Tuple2(
							'userId',
							$elm$json$Json$Encode$int(
								$author$project$Types$User$id(user)))
						]))),
			A2($elm$http$Http$expectJson, responseHandler, $author$project$Data$ServerResponse$serverResponseDecoder));
	});
var $author$project$Pages$WorkTimeTracking$EditEntryDialog$update = F3(
	function (serverContext, msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$EditEntryDialog$OutHide);
			case 1:
				return $author$project$Utils$ServerUtils$blockServerRequests(
					_Utils_Tuple3(
						model,
						A4($author$project$Types$WorkTimeEntry$removeCmd, serverContext, $author$project$Pages$WorkTimeTracking$EditEntryDialog$GotResponse_DeleteEntry, model.gA, model.s.er),
						$author$project$Pages$WorkTimeTracking$EditEntryDialog$OutNoOp));
			case 2:
				return $author$project$Utils$ServerUtils$blockServerRequests(
					_Utils_Tuple3(
						model,
						A4($author$project$Types$WorkTimeEntry$editCmd, serverContext, $author$project$Pages$WorkTimeTracking$EditEntryDialog$GotResponse_EditEntry, model.gA, model.s),
						$author$project$Pages$WorkTimeTracking$EditEntryDialog$OutNoOp));
			case 8:
				var comment = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							s: function (entry) {
								return _Utils_update(
									entry,
									{
										kH: $elm$core$Maybe$Just(comment)
									});
							}(model.s)
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$WorkTimeTracking$EditEntryDialog$OutNoOp);
			case 9:
				var hours = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							c1: 1,
							s: function (entry) {
								return _Utils_update(
									entry,
									{
										gX: function () {
											var _v1 = model.s.ln;
											switch (_v1) {
												case 0:
													return hours;
												case 1:
													return hours;
												case 2:
													return $elm$core$Basics$abs(hours);
												default:
													return function (absHours) {
														return -absHours;
													}(
														$elm$core$Basics$abs(hours));
											}
										}()
									});
							}(model.s)
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$WorkTimeTracking$EditEntryDialog$OutNoOp);
			case 10:
				var reflectInPay = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							s: function (entry) {
								return _Utils_update(
									entry,
									{bF: reflectInPay});
							}(model.s)
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$WorkTimeTracking$EditEntryDialog$OutNoOp);
			case 11:
				var entryType = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							s: function (entry) {
								return _Utils_update(
									entry,
									{
										gX: function () {
											switch (entryType) {
												case 0:
													return model.s.gX;
												case 1:
													return model.s.gX;
												case 2:
													return $elm$core$Basics$abs(model.s.gX);
												default:
													return function (absBalanceEffect) {
														return -absBalanceEffect;
													}(
														$elm$core$Basics$abs(model.s.gX));
											}
										}(),
										ln: entryType
									});
							}(model.s)
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$WorkTimeTracking$EditEntryDialog$OutNoOp);
			case 3:
				if (!msg.a.$) {
					return $author$project$Utils$ServerUtils$unblockServerRequests(
						_Utils_Tuple3(
							model,
							$elm$core$Platform$Cmd$none,
							$author$project$Pages$WorkTimeTracking$EditEntryDialog$OutEntryDeleted(model.s)));
				} else {
					var error = msg.a.a;
					return A3($author$project$Pages$WorkTimeTracking$EditEntryDialog$handleErrorResponse, model, 'Virhe poistettaess kirjausta.', error);
				}
			case 4:
				if (!msg.a.$) {
					var response = msg.a.a;
					return $author$project$Utils$ServerUtils$unblockServerRequests(
						A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$EditEntryDialog$OutNoOp),
							A2(
								$elm$core$Maybe$map,
								function (editedEntry) {
									return _Utils_Tuple3(
										_Utils_update(
											model,
											{s: editedEntry}),
										$elm$core$Platform$Cmd$none,
										$author$project$Pages$WorkTimeTracking$EditEntryDialog$OutEntryEdited(editedEntry));
								},
								A2(
									$elm$core$Maybe$andThen,
									function (parseResult) {
										if (!parseResult.$) {
											var editedEntry = parseResult.a;
											return $elm$core$Maybe$Just(editedEntry);
										} else {
											return $elm$core$Maybe$Nothing;
										}
									},
									A2(
										$elm$core$Maybe$map,
										$elm$json$Json$Decode$decodeString($author$project$Types$WorkTimeEntry$jsonDecoder),
										response.k_)))));
				} else {
					var error = msg.a.a;
					return A3($author$project$Pages$WorkTimeTracking$EditEntryDialog$handleErrorResponse, model, 'Virhe kuitatessa kirjausta maksetuksi.', error);
				}
			case 5:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$EditEntryDialog$OutHide);
			case 6:
				var _v4 = model.c1;
				if (!_v4) {
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{c1: 1}),
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$WorkTimeTracking$EditEntryDialog$OutNoOp);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$EditEntryDialog$OutNoOp);
				}
			case 7:
				var _v5 = model.c1;
				if (!_v5) {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$EditEntryDialog$OutNoOp);
				} else {
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{c1: 0}),
						$elm$core$Platform$Cmd$none,
						$author$project$Pages$WorkTimeTracking$EditEntryDialog$OutNoOp);
				}
			default:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$EditEntryDialog$OutNoOp);
		}
	});
var $author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$DatePickerDialog = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$OutCancelClicked = {$: 1};
var $author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$OutNoOp = {$: 2};
var $author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$OutSaveClicked = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$SubMsg_DatePicker = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$initDatePicker = A4($author$project$Components$DatePicker$init, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, true, false);
var $author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$OutCancelClicked);
			case 1:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					A3(
						$author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$OutSaveClicked,
						model.lm,
						function () {
							var _v1 = model.dp;
							if (_v1 === '') {
								return $elm$core$Maybe$Nothing;
							} else {
								var nonEmptyString = _v1;
								return $elm$core$Maybe$Just(nonEmptyString);
							}
						}(),
						model.bC));
			case 3:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{c1: $author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$NoDialog}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$OutNoOp);
			case 2:
				var _v2 = $author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$initDatePicker;
				var datePickerModel = _v2.a;
				var datePickerCmd = _v2.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							c1: $author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$DatePickerDialog(datePickerModel)
						}),
					A2($elm$core$Platform$Cmd$map, $author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$SubMsg_DatePicker, datePickerCmd),
					$author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$OutNoOp);
			case 4:
				var comment = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{dp: comment}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$OutNoOp);
			case 5:
				var date = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{bC: date}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$OutNoOp);
			case 6:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$OutNoOp);
			default:
				var datePickerMsg = msg.a;
				var _v3 = model.c1;
				if (!_v3.$) {
					var datePickerModel = _v3.a;
					var _v4 = A2($author$project$Components$DatePicker$update, datePickerMsg, datePickerModel);
					var newDatePickerModel = _v4.a;
					var datePickerCmd = _v4.b;
					var outMsg = _v4.c;
					switch (outMsg.$) {
						case 0:
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{
										c1: $author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$DatePickerDialog(newDatePickerModel)
									}),
								A2($elm$core$Platform$Cmd$map, $author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$SubMsg_DatePicker, datePickerCmd),
								$author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$OutNoOp);
						case 1:
							var maybeDate = outMsg.a;
							return function (date) {
								return _Utils_Tuple3(
									_Utils_update(
										model,
										{c1: $author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$NoDialog, bC: date}),
									$elm$core$Platform$Cmd$none,
									$author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$OutNoOp);
							}(
								A2($elm$core$Maybe$withDefault, model.bC, maybeDate));
						default:
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{c1: $author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$NoDialog}),
								$elm$core$Platform$Cmd$none,
								$author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$OutNoOp);
					}
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$OutNoOp);
				}
		}
	});
var $author$project$Pages$WorkTimeTracking$State_Main$update = F4(
	function (appContext, user, msg, model) {
		switch (msg.$) {
			case 5:
				if (!msg.b.$) {
					var response = msg.b.a;
					return $author$project$Utils$ServerUtils$unblockServerRequests(
						A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$State_Main$OutNoOp),
							A2(
								$elm$core$Maybe$map,
								function (newPayments) {
									var entries_ = A2($author$project$Types$WorkTimeEntry$applyPayments, newPayments, model.lm);
									return _Utils_Tuple3(
										_Utils_update(
											model,
											{
												c1: $author$project$Pages$WorkTimeTracking$State_Main$NoDialog,
												lm: entries_,
												a9: A2($author$project$Pages$WorkTimeTracking$State_Main$initPaymentQueueTabbedTable, entries_, model.jA),
												nr: _Utils_ap(newPayments, model.nr)
											}),
										$elm$core$Platform$Cmd$none,
										$author$project$Pages$WorkTimeTracking$State_Main$OutNoOp);
								},
								A2(
									$elm$core$Maybe$andThen,
									function (parseResult) {
										if (!parseResult.$) {
											var payments = parseResult.a.nr;
											return $elm$core$Maybe$Just(payments);
										} else {
											return $elm$core$Maybe$Nothing;
										}
									},
									A2(
										$elm$core$Maybe$map,
										$elm$json$Json$Decode$decodeString($author$project$Types$WorkTimeEntry$markAsPaidResponseJsonDecoder),
										response.k_)))));
				} else {
					var error = msg.b.a;
					return A3($author$project$Pages$WorkTimeTracking$State_Main$handleErrorResponse, model, 'Virhe kuitatessa kirjausta maksetuksi.', error);
				}
			case 6:
				if (!msg.b.$) {
					var forEntry = msg.a;
					var response = msg.b.a;
					return $author$project$Utils$ServerUtils$unblockServerRequests(
						A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$State_Main$OutNoOp),
							A2(
								$elm$core$Maybe$map,
								function (signature) {
									var entries_ = A2(
										$elm$core$List$map,
										function (entry) {
											return _Utils_eq(entry.er, forEntry.er) ? _Utils_update(
												entry,
												{
													i2: $elm$core$Maybe$Just(signature)
												}) : entry;
										},
										model.lm);
									return _Utils_Tuple3(
										_Utils_update(
											model,
											{
												lm: entries_,
												a9: A2($author$project$Pages$WorkTimeTracking$State_Main$initPaymentQueueTabbedTable, entries_, model.jA)
											}),
										$elm$core$Platform$Cmd$none,
										$author$project$Pages$WorkTimeTracking$State_Main$OutNoOp);
								},
								A2(
									$elm$core$Maybe$andThen,
									function (parseResult) {
										if (!parseResult.$) {
											var signature = parseResult.a.i2;
											return $elm$core$Maybe$Just(signature);
										} else {
											return $elm$core$Maybe$Nothing;
										}
									},
									A2(
										$elm$core$Maybe$map,
										$elm$json$Json$Decode$decodeString($author$project$Types$WorkTimeEntry$signResponseJsonDecoder),
										response.k_)))));
				} else {
					var error = msg.b.a;
					return A3($author$project$Pages$WorkTimeTracking$State_Main$handleErrorResponse, model, 'Virhe kirjausta hyväksyttäessä.', error);
				}
			case 7:
				if (!msg.b.$) {
					var forEntry = msg.a;
					var entries_ = A2(
						$elm$core$List$map,
						function (entry) {
							return _Utils_eq(entry.er, forEntry.er) ? _Utils_update(
								entry,
								{i2: $elm$core$Maybe$Nothing}) : entry;
						},
						model.lm);
					return $author$project$Utils$ServerUtils$unblockServerRequests(
						_Utils_Tuple3(
							_Utils_update(
								model,
								{
									lm: entries_,
									a9: A2($author$project$Pages$WorkTimeTracking$State_Main$initPaymentQueueTabbedTable, entries_, model.jA)
								}),
							$elm$core$Platform$Cmd$none,
							$author$project$Pages$WorkTimeTracking$State_Main$OutNoOp));
				} else {
					var error = msg.b.a;
					return A3($author$project$Pages$WorkTimeTracking$State_Main$handleErrorResponse, model, 'Virhe hyväksyntää poistaessa', error);
				}
			case 3:
				var entry = msg.a;
				return $author$project$Utils$ServerUtils$blockServerRequests(
					_Utils_Tuple3(
						model,
						A4(
							$author$project$Types$WorkTimeEntry$signCmd,
							appContext.n1,
							$author$project$Pages$WorkTimeTracking$State_Main$Entry_GotResponse_Sign(entry),
							$author$project$Types$User$id(user),
							entry.er),
						$author$project$Pages$WorkTimeTracking$State_Main$OutNoOp));
			case 4:
				var entry = msg.a;
				return $author$project$Utils$ServerUtils$blockServerRequests(
					_Utils_Tuple3(
						model,
						A3(
							$author$project$Types$WorkTimeEntry$unsignCmd,
							appContext.n1,
							$author$project$Pages$WorkTimeTracking$State_Main$Entry_GotResponse_Unsign(entry),
							entry.er),
						$author$project$Pages$WorkTimeTracking$State_Main$OutNoOp));
			case 1:
				var entry = msg.a;
				var _v3 = model.c1;
				switch (_v3.$) {
					case 0:
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$State_Main$OutNoOp);
					case 1:
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$State_Main$OutNoOp);
					default:
						return A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$State_Main$OutNoOp),
							A2(
								$elm$core$Maybe$map,
								function (worker) {
									return _Utils_Tuple3(
										_Utils_update(
											model,
											{
												c1: $author$project$Pages$WorkTimeTracking$State_Main$Dialog_EditEntry(
													A3($author$project$Pages$WorkTimeTracking$EditEntryDialog$init, user, worker, entry))
											}),
										$elm$core$Platform$Cmd$none,
										$author$project$Pages$WorkTimeTracking$State_Main$OutNoOp);
								},
								A2(
									$author$project$Helpers$firstWhere,
									A2(
										$elm$core$Basics$composeR,
										function ($) {
											return $.er;
										},
										$elm$core$Basics$eq(entry.jy)),
									model.jA)));
				}
			case 2:
				var entries = msg.a;
				var _v4 = model.c1;
				switch (_v4.$) {
					case 0:
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$State_Main$OutNoOp);
					case 1:
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$State_Main$OutNoOp);
					default:
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									c1: $author$project$Pages$WorkTimeTracking$State_Main$Dialog_MarkEntryAsPaid(
										A2($author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$init, appContext.dE, entries))
								}),
							$elm$core$Platform$Cmd$none,
							$author$project$Pages$WorkTimeTracking$State_Main$OutNoOp);
				}
			case 0:
				var worker = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$WorkTimeTracking$State_Main$OutWorkerClicked(worker));
			case 8:
				var workTimeEntries = msg.a;
				return $author$project$Utils$ServerUtils$blockServerRequests(
					_Utils_Tuple3(
						model,
						A4(
							$author$project$Types$ExcelFile$fetchWithParametersCmd,
							appContext.n1,
							'/work-time/entries/excel-export',
							$elm$http$Http$jsonBody(
								$elm$json$Json$Encode$object(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'workTimeEntryIds',
											A2(
												$elm$json$Json$Encode$list,
												$elm$json$Json$Encode$string,
												A2(
													$elm$core$List$map,
													function ($) {
														return $.er;
													},
													workTimeEntries)))
										]))),
							$author$project$Pages$WorkTimeTracking$State_Main$GotExcelFile),
						$author$project$Pages$WorkTimeTracking$State_Main$OutNotification(
							A2($author$project$Components$Notification$Notification, 'Ladataan Excel-tiedostoa...', 1))));
			case 9:
				var idsToExport = function () {
					var _v5 = $elm$core$Set$toList(model.n$);
					if (!_v5.b) {
						return A2(
							$elm$core$List$map,
							function ($) {
								return $.er;
							},
							A2($elm$core$List$filter, $author$project$Types$WorkTimeEntry$isInPaymentQueue, model.lm));
					} else {
						var selectedIds = _v5;
						return selectedIds;
					}
				}();
				return $author$project$Utils$ServerUtils$blockServerRequests(
					_Utils_Tuple3(
						model,
						A4(
							$author$project$Types$ExcelFile$fetchWithParametersCmd,
							appContext.n1,
							'/work-time/entries/excel-export',
							$elm$http$Http$jsonBody(
								$elm$json$Json$Encode$object(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'workTimeEntryIds',
											A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, idsToExport))
										]))),
							$author$project$Pages$WorkTimeTracking$State_Main$GotExcelFile),
						$author$project$Pages$WorkTimeTracking$State_Main$OutNotification(
							A2($author$project$Components$Notification$Notification, 'Ladataan Excel-tiedostoa...', 1))));
			case 10:
				var states = msg.a;
				var newlySelectedWorkTimeEntryIds = A2(
					$elm$core$List$filterMap,
					function (_v7) {
						var workTimeEntryId = _v7.a;
						var isSelected = _v7.b;
						return isSelected ? $elm$core$Maybe$Just(workTimeEntryId) : $elm$core$Maybe$Nothing;
					},
					states);
				var newlyDeselectedWorkTimeEntryIds = $elm$core$Set$fromList(
					A2(
						$elm$core$List$filterMap,
						function (_v6) {
							var workTimeEntryId = _v6.a;
							var isSelected = _v6.b;
							return (!isSelected) ? $elm$core$Maybe$Just(workTimeEntryId) : $elm$core$Maybe$Nothing;
						},
						states));
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							n$: $elm$core$Set$fromList(
								A2(
									$elm$core$List$filter,
									function (selectedWorkTimeEntryId) {
										return !A2($elm$core$Set$member, selectedWorkTimeEntryId, newlyDeselectedWorkTimeEntryIds);
									},
									_Utils_ap(
										$elm$core$Set$toList(model.n$),
										newlySelectedWorkTimeEntryIds)))
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$WorkTimeTracking$State_Main$OutNoOp);
			case 11:
				if (!msg.a.$) {
					var excelFile = msg.a.a;
					return $author$project$Utils$ServerUtils$unblockServerRequests(
						_Utils_Tuple3(
							model,
							$author$project$Types$ExcelFile$saveToDiskCmd(excelFile),
							$author$project$Pages$WorkTimeTracking$State_Main$OutNoOp));
				} else {
					var error = msg.a.a;
					return A3($author$project$Pages$WorkTimeTracking$State_Main$handleErrorResponse, model, 'Virhe ladattaessa Excel-tiedostoa.', error);
				}
			case 12:
				var subMsg = msg.a;
				var _v8 = model.c1;
				if (!_v8.$) {
					var subModel = _v8.a;
					var _v9 = A3($author$project$Pages$WorkTimeTracking$EditEntryDialog$update, appContext.n1, subMsg, subModel);
					var updatedSubModel = _v9.a;
					var subCmd = _v9.b;
					var subOutMsg = _v9.c;
					var cmd = A2($elm$core$Platform$Cmd$map, $author$project$Pages$WorkTimeTracking$State_Main$SubMsg_EditEntryDialog, subCmd);
					var updatedModel = _Utils_update(
						model,
						{
							c1: $author$project$Pages$WorkTimeTracking$State_Main$Dialog_EditEntry(updatedSubModel)
						});
					switch (subOutMsg.$) {
						case 5:
							return _Utils_Tuple3(updatedModel, cmd, $author$project$Pages$WorkTimeTracking$State_Main$OutNoOp);
						case 2:
							return _Utils_Tuple3(
								_Utils_update(
									updatedModel,
									{c1: $author$project$Pages$WorkTimeTracking$State_Main$NoDialog}),
								cmd,
								$author$project$Pages$WorkTimeTracking$State_Main$OutNoOp);
						case 3:
							var deletedEntry = subOutMsg.a;
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{
										c1: $author$project$Pages$WorkTimeTracking$State_Main$NoDialog,
										lm: A2(
											$elm$core$List$filter,
											A2(
												$elm$core$Basics$composeR,
												function ($) {
													return $.er;
												},
												$elm$core$Basics$neq(deletedEntry.er)),
											model.lm)
									}),
								$elm$core$Platform$Cmd$none,
								$author$project$Pages$WorkTimeTracking$State_Main$OutNoOp);
						case 4:
							var editedEntry = subOutMsg.a;
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{
										c1: $author$project$Pages$WorkTimeTracking$State_Main$NoDialog,
										lm: A3(
											$author$project$Utils$MiscUtils$list_replaceBy,
											function ($) {
												return $.er;
											},
											editedEntry,
											model.lm)
									}),
								$elm$core$Platform$Cmd$none,
								$author$project$Pages$WorkTimeTracking$State_Main$OutNoOp);
						case 0:
							var notification = subOutMsg.a;
							return _Utils_Tuple3(
								updatedModel,
								cmd,
								$author$project$Pages$WorkTimeTracking$State_Main$OutNotification(notification));
						default:
							return _Utils_Tuple3(updatedModel, cmd, $author$project$Pages$WorkTimeTracking$State_Main$OutGotAuthError);
					}
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$State_Main$OutNoOp);
				}
			case 13:
				var subMsg = msg.a;
				var _v11 = model.c1;
				if (_v11.$ === 1) {
					var subModel = _v11.a;
					var _v12 = A2($author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$update, subMsg, subModel);
					var updatedSubModel = _v12.a;
					var subCmd = _v12.b;
					var subOutMsg = _v12.c;
					var cmd = A2($elm$core$Platform$Cmd$map, $author$project$Pages$WorkTimeTracking$State_Main$SubMsg_MarkEntryAsPaidDialog, subCmd);
					var updatedModel = _Utils_update(
						model,
						{
							c1: $author$project$Pages$WorkTimeTracking$State_Main$Dialog_MarkEntryAsPaid(updatedSubModel)
						});
					switch (subOutMsg.$) {
						case 2:
							return _Utils_Tuple3(updatedModel, cmd, $author$project$Pages$WorkTimeTracking$State_Main$OutNoOp);
						case 0:
							var entries = subOutMsg.a;
							var paymentComment = subOutMsg.b;
							var paymentDate = subOutMsg.c;
							return $author$project$Utils$ServerUtils$blockServerRequests(
								_Utils_Tuple3(
									updatedModel,
									A4(
										$author$project$Types$WorkTimeEntry$markAsPaidCmd,
										appContext.n1,
										$author$project$Pages$WorkTimeTracking$State_Main$Entry_GotResponse_MarkAsPaid(entries),
										user,
										{
											ho: A2(
												$elm$core$List$map,
												function ($) {
													return $.er;
												},
												entries),
											dp: paymentComment,
											nq: paymentDate
										}),
									$author$project$Pages$WorkTimeTracking$State_Main$OutNoOp));
						default:
							return _Utils_Tuple3(
								_Utils_update(
									updatedModel,
									{c1: $author$project$Pages$WorkTimeTracking$State_Main$NoDialog}),
								cmd,
								$author$project$Pages$WorkTimeTracking$State_Main$OutNoOp);
					}
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$State_Main$OutNoOp);
				}
			default:
				var subMsg = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							a9: A2($author$project$Components$TabbedTable$TabbedTableApi$update, model.a9, subMsg)
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$WorkTimeTracking$State_Main$OutNoOp);
		}
	});
var $author$project$Pages$WorkTimeTracking$State_WorkerPage$Dialog_Date = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$WorkTimeTracking$State_WorkerPage$Dialog_EditEntry = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$WorkTimeTracking$State_WorkerPage$Dialog_MarkEntryAsPaid = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$WorkTimeTracking$State_WorkerPage$Entry_GotResponse_MarkAsPaid = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $author$project$Pages$WorkTimeTracking$State_WorkerPage$Entry_GotResponse_Sign = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $author$project$Pages$WorkTimeTracking$State_WorkerPage$Entry_GotResponse_Unsign = F2(
	function (a, b) {
		return {$: 10, a: a, b: b};
	});
var $author$project$Pages$WorkTimeTracking$State_WorkerPage$OutExitClicked = {$: 1};
var $author$project$Pages$WorkTimeTracking$State_WorkerPage$OutGotAuthError = {$: 2};
var $author$project$Pages$WorkTimeTracking$State_WorkerPage$OutNotification = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$WorkTimeTracking$State_WorkerPage$SubMsg_DateDialog = function (a) {
	return {$: 13, a: a};
};
var $author$project$Pages$WorkTimeTracking$State_WorkerPage$SubMsg_EditEntryDialog = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$WorkTimeTracking$State_WorkerPage$SubMsg_MarkEntryAsPaidDialog = function (a) {
	return {$: 15, a: a};
};
var $author$project$Pages$WorkTimeTracking$State_WorkerPage$handleErrorResponse = F3(
	function (model, messageToUser, error) {
		if ((error.$ === 3) && (error.a === 401)) {
			return $author$project$Utils$ServerUtils$unblockServerRequests(
				_Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$State_WorkerPage$OutGotAuthError));
		} else {
			return $author$project$Utils$ServerUtils$unblockServerRequests(
				_Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$WorkTimeTracking$State_WorkerPage$OutNotification(
						A2($author$project$Components$Notification$Notification, messageToUser, 1))));
		}
	});
var $author$project$Pages$WorkTimeTracking$DateDialog$NoDialog = {$: 2};
var $author$project$Pages$WorkTimeTracking$DateDialog$DraftHourlyWork = 1;
var $author$project$Pages$WorkTimeTracking$DateDialog$emptyDraftEntry = function (workerCompensationType) {
	if (!workerCompensationType) {
		return {kH: '', ln: 1, bF: true, ar: 0};
	} else {
		return {kH: '', ln: 1, bF: false, ar: 0};
	}
};
var $author$project$Pages$WorkTimeTracking$DateDialog$init = F3(
	function (date, worker, entries) {
		var initialModel = {
			b4: date,
			c1: $author$project$Pages$WorkTimeTracking$DateDialog$NoDialog,
			I: $author$project$Pages$WorkTimeTracking$DateDialog$emptyDraftEntry(worker.kI),
			lm: A2(
				$elm$core$List$filter,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.mJ;
					},
					$elm$core$Basics$eq(date)),
				entries),
			eY: {fo: false},
			bR: worker
		};
		return initialModel;
	});
var $author$project$Pages$WorkTimeTracking$DateDialog$Dialog_EditEntry = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$WorkTimeTracking$DateDialog$DraftEntry_GotResponse_Create = function (a) {
	return {$: 9, a: a};
};
var $author$project$Pages$WorkTimeTracking$DateDialog$DraftEntry_HoursSelectionDialogOpen = {$: 1};
var $author$project$Pages$WorkTimeTracking$DateDialog$OutCreatedEntry = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$WorkTimeTracking$DateDialog$OutEntryDeleted = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$WorkTimeTracking$DateDialog$OutEntryEdited = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$WorkTimeTracking$DateDialog$OutExitClicked = {$: 4};
var $author$project$Pages$WorkTimeTracking$DateDialog$OutGotAuthError = {$: 1};
var $author$project$Pages$WorkTimeTracking$DateDialog$OutNoOp = {$: 6};
var $author$project$Pages$WorkTimeTracking$DateDialog$OutNotification = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pages$WorkTimeTracking$DateDialog$SubMsg_EditEntryDialog = function (a) {
	return {$: 13, a: a};
};
var $author$project$Types$WorkTimeEntry$createCmd = F4(
	function (_v0, responseHandler, user, _v1) {
		var apiUrl = _v0.j0;
		var token = _v0.oK;
		var workerId = _v1.jy;
		var balanceEffect = _v1.gX;
		var comment = _v1.kH;
		var entryType = _v1.ln;
		var on = _v1.mJ;
		var reflectInPay = _v1.bF;
		return A4(
			$author$project$JwtHttp$post,
			token,
			apiUrl + '/work-time/entries/create',
			$elm$http$Http$jsonBody(
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'workerId',
							$elm$json$Json$Encode$int(workerId)),
							_Utils_Tuple2(
							'userId',
							$elm$json$Json$Encode$int(
								$author$project$Types$User$id(user))),
							_Utils_Tuple2(
							'balanceEffect',
							$elm$json$Json$Encode$float(balanceEffect)),
							_Utils_Tuple2(
							'comment',
							$elm$json$Json$Encode$string(
								A2($elm$core$Maybe$withDefault, '', comment))),
							_Utils_Tuple2(
							'entryType',
							$author$project$Types$WorkTimeEntry$entryTypeJsonEncoder(entryType)),
							_Utils_Tuple2(
							'on',
							$author$project$Types$Date$jsonEncoder(on)),
							_Utils_Tuple2(
							'reflectInPay',
							$elm$json$Json$Encode$bool(reflectInPay))
						]))),
			A2($elm$http$Http$expectJson, responseHandler, $author$project$Data$ServerResponse$serverResponseDecoder));
	});
var $author$project$Pages$WorkTimeTracking$DateDialog$draftType_to_entryType = function (draftType) {
	switch (draftType) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $author$project$Pages$WorkTimeTracking$DateDialog$handleErrorResponse = F3(
	function (model, messageToUser, error) {
		if ((error.$ === 3) && (error.a === 401)) {
			return $author$project$Utils$ServerUtils$unblockServerRequests(
				_Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$DateDialog$OutGotAuthError));
		} else {
			return $author$project$Utils$ServerUtils$unblockServerRequests(
				_Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$WorkTimeTracking$DateDialog$OutNotification(
						A2($author$project$Components$Notification$Notification, messageToUser, 1))));
		}
	});
var $author$project$Pages$WorkTimeTracking$DateDialog$update = F4(
	function (appContext, user, msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$DateDialog$OutExitClicked);
			case 1:
				var entry = msg.a;
				var _v1 = model.c1;
				switch (_v1.$) {
					case 0:
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$DateDialog$OutNoOp);
					case 1:
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$DateDialog$OutNoOp);
					default:
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									c1: $author$project$Pages$WorkTimeTracking$DateDialog$Dialog_EditEntry(
										A3($author$project$Pages$WorkTimeTracking$EditEntryDialog$init, user, model.bR, entry))
								}),
							$elm$core$Platform$Cmd$none,
							$author$project$Pages$WorkTimeTracking$DateDialog$OutNoOp);
				}
			case 2:
				var _v2 = model.c1;
				switch (_v2.$) {
					case 0:
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{c1: $author$project$Pages$WorkTimeTracking$DateDialog$NoDialog}),
							$elm$core$Platform$Cmd$none,
							$author$project$Pages$WorkTimeTracking$DateDialog$OutNoOp);
					case 1:
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$DateDialog$OutNoOp);
					default:
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$DateDialog$OutNoOp);
				}
			case 3:
				var _v3 = model.c1;
				switch (_v3.$) {
					case 0:
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$DateDialog$OutNoOp);
					case 1:
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{c1: $author$project$Pages$WorkTimeTracking$DateDialog$NoDialog}),
							$elm$core$Platform$Cmd$none,
							$author$project$Pages$WorkTimeTracking$DateDialog$OutNoOp);
					default:
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$DateDialog$OutNoOp);
				}
			case 4:
				var _v4 = model.c1;
				switch (_v4.$) {
					case 0:
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$DateDialog$OutNoOp);
					case 1:
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$DateDialog$OutNoOp);
					default:
						var draftEntry = model.I;
						return $author$project$Utils$ServerUtils$blockServerRequests(
							_Utils_Tuple3(
								model,
								A4(
									$author$project$Types$WorkTimeEntry$createCmd,
									appContext.n1,
									$author$project$Pages$WorkTimeTracking$DateDialog$DraftEntry_GotResponse_Create,
									user,
									{
										gX: function () {
											var _v5 = draftEntry.ln;
											switch (_v5) {
												case 0:
													return draftEntry.ar;
												case 1:
													return draftEntry.ar;
												case 2:
													return draftEntry.ar;
												default:
													return -draftEntry.ar;
											}
										}(),
										kH: (draftEntry.kH === '') ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(draftEntry.kH),
										ln: $author$project$Pages$WorkTimeTracking$DateDialog$draftType_to_entryType(draftEntry.ln),
										mJ: model.b4,
										bF: draftEntry.bF,
										jy: model.bR.er
									}),
								$author$project$Pages$WorkTimeTracking$DateDialog$OutNoOp));
				}
			case 5:
				var comment = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							I: function (draftEntry) {
								return _Utils_update(
									draftEntry,
									{kH: comment});
							}(model.I)
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$WorkTimeTracking$DateDialog$OutNoOp);
			case 6:
				var hours = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							c1: $author$project$Pages$WorkTimeTracking$DateDialog$NoDialog,
							I: function (draftEntry) {
								return _Utils_update(
									draftEntry,
									{ar: hours});
							}(model.I)
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$WorkTimeTracking$DateDialog$OutNoOp);
			case 7:
				var reflectInPay = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							I: function (draftEntry) {
								return _Utils_update(
									draftEntry,
									{bF: reflectInPay});
							}(model.I)
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$WorkTimeTracking$DateDialog$OutNoOp);
			case 8:
				var entryType = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							I: function (draftEntry) {
								return _Utils_update(
									draftEntry,
									{ln: entryType});
							}(model.I)
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$WorkTimeTracking$DateDialog$OutNoOp);
			case 9:
				if (!msg.a.$) {
					var response = msg.a.a;
					return $author$project$Utils$ServerUtils$unblockServerRequests(
						A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$DateDialog$OutNoOp),
							A2(
								$elm$core$Maybe$map,
								function (createdEntry) {
									return _Utils_Tuple3(
										_Utils_update(
											model,
											{
												c1: $author$project$Pages$WorkTimeTracking$DateDialog$NoDialog,
												I: $author$project$Pages$WorkTimeTracking$DateDialog$emptyDraftEntry(model.bR.kI),
												lm: A2($elm$core$List$cons, createdEntry, model.lm)
											}),
										$elm$core$Platform$Cmd$none,
										$author$project$Pages$WorkTimeTracking$DateDialog$OutCreatedEntry(createdEntry));
								},
								A2(
									$elm$core$Maybe$andThen,
									function (maybeResult) {
										if (!maybeResult.$) {
											var _new = maybeResult.a;
											return $elm$core$Maybe$Just(_new);
										} else {
											return $elm$core$Maybe$Nothing;
										}
									},
									A2(
										$elm$core$Maybe$map,
										$elm$json$Json$Decode$decodeString($author$project$Types$WorkTimeEntry$jsonDecoder),
										response.k_)))));
				} else {
					var error = msg.a.a;
					return A3($author$project$Pages$WorkTimeTracking$DateDialog$handleErrorResponse, model, 'Virhe kirjausta luotaessa.', error);
				}
			case 10:
				var _v7 = model.c1;
				switch (_v7.$) {
					case 0:
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$DateDialog$OutNoOp);
					case 1:
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{c1: $author$project$Pages$WorkTimeTracking$DateDialog$NoDialog}),
							$elm$core$Platform$Cmd$none,
							$author$project$Pages$WorkTimeTracking$DateDialog$OutNoOp);
					default:
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$DateDialog$OutNoOp);
				}
			case 11:
				var _v8 = model.c1;
				switch (_v8.$) {
					case 0:
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$DateDialog$OutNoOp);
					case 1:
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$DateDialog$OutNoOp);
					default:
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{c1: $author$project$Pages$WorkTimeTracking$DateDialog$DraftEntry_HoursSelectionDialogOpen}),
							$elm$core$Platform$Cmd$none,
							$author$project$Pages$WorkTimeTracking$DateDialog$OutNoOp);
				}
			case 12:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$DateDialog$OutNoOp);
			default:
				var subMsg = msg.a;
				var _v9 = model.c1;
				switch (_v9.$) {
					case 0:
						var subModel = _v9.a;
						var _v10 = A3($author$project$Pages$WorkTimeTracking$EditEntryDialog$update, appContext.n1, subMsg, subModel);
						var updatedSubModel = _v10.a;
						var subCmd = _v10.b;
						var subOutMsg = _v10.c;
						var cmd = A2($elm$core$Platform$Cmd$map, $author$project$Pages$WorkTimeTracking$DateDialog$SubMsg_EditEntryDialog, subCmd);
						var updatedModel = _Utils_update(
							model,
							{
								c1: $author$project$Pages$WorkTimeTracking$DateDialog$Dialog_EditEntry(updatedSubModel)
							});
						switch (subOutMsg.$) {
							case 5:
								return _Utils_Tuple3(updatedModel, cmd, $author$project$Pages$WorkTimeTracking$DateDialog$OutNoOp);
							case 2:
								return _Utils_Tuple3(
									_Utils_update(
										updatedModel,
										{c1: $author$project$Pages$WorkTimeTracking$DateDialog$NoDialog}),
									cmd,
									$author$project$Pages$WorkTimeTracking$DateDialog$OutNoOp);
							case 4:
								var editedEntry = subOutMsg.a;
								return _Utils_Tuple3(
									_Utils_update(
										model,
										{
											c1: $author$project$Pages$WorkTimeTracking$DateDialog$NoDialog,
											lm: A3(
												$author$project$Utils$MiscUtils$list_replaceBy,
												function ($) {
													return $.er;
												},
												editedEntry,
												model.lm)
										}),
									$elm$core$Platform$Cmd$none,
									$author$project$Pages$WorkTimeTracking$DateDialog$OutEntryEdited(editedEntry));
							case 3:
								var deletedEntry = subOutMsg.a;
								return _Utils_Tuple3(
									_Utils_update(
										model,
										{
											c1: $author$project$Pages$WorkTimeTracking$DateDialog$NoDialog,
											lm: A2(
												$elm$core$List$filter,
												A2(
													$elm$core$Basics$composeR,
													function ($) {
														return $.er;
													},
													$elm$core$Basics$neq(deletedEntry.er)),
												model.lm)
										}),
									$elm$core$Platform$Cmd$none,
									$author$project$Pages$WorkTimeTracking$DateDialog$OutEntryDeleted(deletedEntry));
							case 0:
								var notification = subOutMsg.a;
								return _Utils_Tuple3(
									updatedModel,
									cmd,
									$author$project$Pages$WorkTimeTracking$DateDialog$OutNotification(notification));
							default:
								return _Utils_Tuple3(updatedModel, cmd, $author$project$Pages$WorkTimeTracking$DateDialog$OutGotAuthError);
						}
					case 1:
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$DateDialog$OutNoOp);
					default:
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$DateDialog$OutNoOp);
				}
		}
	});
var $author$project$Pages$WorkTimeTracking$State_WorkerPage$update = F4(
	function (appContext, user, msg, model) {
		switch (msg.$) {
			case 0:
				var date = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							c1: $author$project$Pages$WorkTimeTracking$State_WorkerPage$Dialog_Date(
								A3($author$project$Pages$WorkTimeTracking$DateDialog$init, date, model.bR, model.lm))
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$WorkTimeTracking$State_WorkerPage$OutNoOp);
			case 1:
				var year = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{bG: year}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$WorkTimeTracking$State_WorkerPage$OutNoOp);
			case 2:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$State_WorkerPage$OutExitClicked);
			case 3:
				var entry = msg.a;
				var _v1 = model.c1;
				switch (_v1.$) {
					case 0:
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$State_WorkerPage$OutNoOp);
					case 1:
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$State_WorkerPage$OutNoOp);
					case 2:
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$State_WorkerPage$OutNoOp);
					default:
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									c1: $author$project$Pages$WorkTimeTracking$State_WorkerPage$Dialog_EditEntry(
										A3($author$project$Pages$WorkTimeTracking$EditEntryDialog$init, model.gA, model.bR, entry))
								}),
							$elm$core$Platform$Cmd$none,
							$author$project$Pages$WorkTimeTracking$State_WorkerPage$OutNoOp);
				}
			case 4:
				var entries = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							c1: $author$project$Pages$WorkTimeTracking$State_WorkerPage$Dialog_MarkEntryAsPaid(
								A2($author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$init, appContext.dE, entries))
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$WorkTimeTracking$State_WorkerPage$OutNoOp);
			case 5:
				var entry = msg.a;
				return $author$project$Utils$ServerUtils$blockServerRequests(
					_Utils_Tuple3(
						model,
						A4(
							$author$project$Types$WorkTimeEntry$signCmd,
							appContext.n1,
							$author$project$Pages$WorkTimeTracking$State_WorkerPage$Entry_GotResponse_Sign(entry),
							$author$project$Types$User$id(user),
							entry.er),
						$author$project$Pages$WorkTimeTracking$State_WorkerPage$OutNoOp));
			case 6:
				var entry = msg.a;
				return $author$project$Utils$ServerUtils$blockServerRequests(
					_Utils_Tuple3(
						model,
						A3(
							$author$project$Types$WorkTimeEntry$unsignCmd,
							appContext.n1,
							$author$project$Pages$WorkTimeTracking$State_WorkerPage$Entry_GotResponse_Unsign(entry),
							entry.er),
						$author$project$Pages$WorkTimeTracking$State_WorkerPage$OutNoOp));
			case 7:
				if (!msg.b.$) {
					var response = msg.b.a;
					return $author$project$Utils$ServerUtils$unblockServerRequests(
						A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$State_WorkerPage$OutNoOp),
							A2(
								$elm$core$Maybe$map,
								function (newPayments) {
									return _Utils_Tuple3(
										_Utils_update(
											model,
											{
												c1: $author$project$Pages$WorkTimeTracking$State_WorkerPage$NoDialog,
												lm: A2($author$project$Types$WorkTimeEntry$applyPayments, newPayments, model.lm),
												nr: _Utils_ap(newPayments, model.nr)
											}),
										$elm$core$Platform$Cmd$none,
										$author$project$Pages$WorkTimeTracking$State_WorkerPage$OutNoOp);
								},
								A2(
									$elm$core$Maybe$andThen,
									function (parseResult) {
										if (!parseResult.$) {
											var payments = parseResult.a.nr;
											return $elm$core$Maybe$Just(payments);
										} else {
											return $elm$core$Maybe$Nothing;
										}
									},
									A2(
										$elm$core$Maybe$map,
										$elm$json$Json$Decode$decodeString($author$project$Types$WorkTimeEntry$markAsPaidResponseJsonDecoder),
										response.k_)))));
				} else {
					var error = msg.b.a;
					return A3($author$project$Pages$WorkTimeTracking$State_WorkerPage$handleErrorResponse, model, 'Virhe kuitatessa kirjausta maksetuksi.', error);
				}
			case 8:
				if (!msg.b.$) {
					var removedEntry = msg.a;
					return $author$project$Utils$ServerUtils$unblockServerRequests(
						_Utils_Tuple3(
							_Utils_update(
								model,
								{
									lm: A2(
										$elm$core$List$filter,
										A2(
											$elm$core$Basics$composeR,
											function ($) {
												return $.er;
											},
											$elm$core$Basics$neq(removedEntry.er)),
										model.lm)
								}),
							$elm$core$Platform$Cmd$none,
							$author$project$Pages$WorkTimeTracking$State_WorkerPage$OutNoOp));
				} else {
					var error = msg.b.a;
					return A3($author$project$Pages$WorkTimeTracking$State_WorkerPage$handleErrorResponse, model, 'Virhe kirjausta poistaessa.', error);
				}
			case 9:
				if (!msg.b.$) {
					var forEntry = msg.a;
					var response = msg.b.a;
					return $author$project$Utils$ServerUtils$unblockServerRequests(
						A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$State_WorkerPage$OutNoOp),
							A2(
								$elm$core$Maybe$map,
								function (signature) {
									return _Utils_Tuple3(
										_Utils_update(
											model,
											{
												lm: A2(
													$elm$core$List$map,
													function (entry) {
														return _Utils_eq(entry.er, forEntry.er) ? _Utils_update(
															entry,
															{
																i2: $elm$core$Maybe$Just(signature)
															}) : entry;
													},
													model.lm)
											}),
										$elm$core$Platform$Cmd$none,
										$author$project$Pages$WorkTimeTracking$State_WorkerPage$OutNoOp);
								},
								A2(
									$elm$core$Maybe$andThen,
									function (parseResult) {
										if (!parseResult.$) {
											var signature = parseResult.a.i2;
											return $elm$core$Maybe$Just(signature);
										} else {
											return $elm$core$Maybe$Nothing;
										}
									},
									A2(
										$elm$core$Maybe$map,
										$elm$json$Json$Decode$decodeString($author$project$Types$WorkTimeEntry$signResponseJsonDecoder),
										response.k_)))));
				} else {
					var error = msg.b.a;
					return A3($author$project$Pages$WorkTimeTracking$State_WorkerPage$handleErrorResponse, model, 'Virhe kirjausta hyväksyttäessä.', error);
				}
			case 10:
				if (!msg.b.$) {
					var forEntry = msg.a;
					return $author$project$Utils$ServerUtils$unblockServerRequests(
						_Utils_Tuple3(
							_Utils_update(
								model,
								{
									lm: A2(
										$elm$core$List$map,
										function (entry) {
											return _Utils_eq(entry.er, forEntry.er) ? _Utils_update(
												entry,
												{i2: $elm$core$Maybe$Nothing}) : entry;
										},
										model.lm)
								}),
							$elm$core$Platform$Cmd$none,
							$author$project$Pages$WorkTimeTracking$State_WorkerPage$OutNoOp));
				} else {
					var error = msg.b.a;
					return A3($author$project$Pages$WorkTimeTracking$State_WorkerPage$handleErrorResponse, model, 'Virhe hyväksyntää poistaessa', error);
				}
			case 11:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{c1: $author$project$Pages$WorkTimeTracking$State_WorkerPage$NoDialog}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$WorkTimeTracking$State_WorkerPage$OutNoOp);
			case 12:
				var _v4 = model.c1;
				switch (_v4.$) {
					case 0:
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$State_WorkerPage$OutNoOp);
					case 1:
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{c1: $author$project$Pages$WorkTimeTracking$State_WorkerPage$NoDialog}),
							$elm$core$Platform$Cmd$none,
							$author$project$Pages$WorkTimeTracking$State_WorkerPage$OutNoOp);
					case 2:
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$State_WorkerPage$OutNoOp);
					default:
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$State_WorkerPage$OutNoOp);
				}
			case 16:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$State_WorkerPage$OutNoOp);
			case 13:
				var subMsg = msg.a;
				var _v5 = model.c1;
				if (!_v5.$) {
					var subModel = _v5.a;
					var _v6 = A4($author$project$Pages$WorkTimeTracking$DateDialog$update, appContext, model.gA, subMsg, subModel);
					var updatedSubModel = _v6.a;
					var subCmd = _v6.b;
					var subOutMsg = _v6.c;
					var cmd = A2($elm$core$Platform$Cmd$map, $author$project$Pages$WorkTimeTracking$State_WorkerPage$SubMsg_DateDialog, subCmd);
					var updatedModel = _Utils_update(
						model,
						{
							c1: $author$project$Pages$WorkTimeTracking$State_WorkerPage$Dialog_Date(updatedSubModel)
						});
					switch (subOutMsg.$) {
						case 6:
							return _Utils_Tuple3(updatedModel, cmd, $author$project$Pages$WorkTimeTracking$State_WorkerPage$OutNoOp);
						case 0:
							var createdEntry = subOutMsg.a;
							return _Utils_Tuple3(
								_Utils_update(
									updatedModel,
									{
										c1: $author$project$Pages$WorkTimeTracking$State_WorkerPage$NoDialog,
										lm: A2($elm$core$List$cons, createdEntry, updatedModel.lm)
									}),
								cmd,
								$author$project$Pages$WorkTimeTracking$State_WorkerPage$OutNoOp);
						case 5:
							var notification = subOutMsg.a;
							return _Utils_Tuple3(
								updatedModel,
								cmd,
								$author$project$Pages$WorkTimeTracking$State_WorkerPage$OutNotification(notification));
						case 2:
							var deletedEntry = subOutMsg.a;
							return _Utils_Tuple3(
								_Utils_update(
									updatedModel,
									{
										lm: A2(
											$elm$core$List$filter,
											A2(
												$elm$core$Basics$composeR,
												function ($) {
													return $.er;
												},
												$elm$core$Basics$neq(deletedEntry.er)),
											updatedModel.lm)
									}),
								$elm$core$Platform$Cmd$none,
								$author$project$Pages$WorkTimeTracking$State_WorkerPage$OutNoOp);
						case 3:
							var editedEntry = subOutMsg.a;
							return _Utils_Tuple3(
								_Utils_update(
									updatedModel,
									{
										lm: A3(
											$author$project$Utils$MiscUtils$list_replaceBy,
											function ($) {
												return $.er;
											},
											editedEntry,
											updatedModel.lm)
									}),
								cmd,
								$author$project$Pages$WorkTimeTracking$State_WorkerPage$OutNoOp);
						case 4:
							return _Utils_Tuple3(
								_Utils_update(
									updatedModel,
									{c1: $author$project$Pages$WorkTimeTracking$State_WorkerPage$NoDialog}),
								cmd,
								$author$project$Pages$WorkTimeTracking$State_WorkerPage$OutNoOp);
						default:
							return _Utils_Tuple3(updatedModel, cmd, $author$project$Pages$WorkTimeTracking$State_WorkerPage$OutGotAuthError);
					}
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$State_WorkerPage$OutNoOp);
				}
			case 14:
				var subMsg = msg.a;
				var _v8 = model.c1;
				if (_v8.$ === 1) {
					var subModel = _v8.a;
					var _v9 = A3($author$project$Pages$WorkTimeTracking$EditEntryDialog$update, appContext.n1, subMsg, subModel);
					var updatedSubModel = _v9.a;
					var subCmd = _v9.b;
					var subOutMsg = _v9.c;
					var cmd = A2($elm$core$Platform$Cmd$map, $author$project$Pages$WorkTimeTracking$State_WorkerPage$SubMsg_EditEntryDialog, subCmd);
					var updatedModel = _Utils_update(
						model,
						{
							c1: $author$project$Pages$WorkTimeTracking$State_WorkerPage$Dialog_EditEntry(updatedSubModel)
						});
					switch (subOutMsg.$) {
						case 5:
							return _Utils_Tuple3(updatedModel, cmd, $author$project$Pages$WorkTimeTracking$State_WorkerPage$OutNoOp);
						case 2:
							return _Utils_Tuple3(
								_Utils_update(
									updatedModel,
									{c1: $author$project$Pages$WorkTimeTracking$State_WorkerPage$NoDialog}),
								cmd,
								$author$project$Pages$WorkTimeTracking$State_WorkerPage$OutNoOp);
						case 3:
							var deletedEntry = subOutMsg.a;
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{
										c1: $author$project$Pages$WorkTimeTracking$State_WorkerPage$NoDialog,
										lm: A2(
											$elm$core$List$filter,
											A2(
												$elm$core$Basics$composeR,
												function ($) {
													return $.er;
												},
												$elm$core$Basics$neq(deletedEntry.er)),
											model.lm)
									}),
								$elm$core$Platform$Cmd$none,
								$author$project$Pages$WorkTimeTracking$State_WorkerPage$OutNoOp);
						case 4:
							var editedEntry = subOutMsg.a;
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{
										c1: $author$project$Pages$WorkTimeTracking$State_WorkerPage$NoDialog,
										lm: A3(
											$author$project$Utils$MiscUtils$list_replaceBy,
											function ($) {
												return $.er;
											},
											editedEntry,
											model.lm)
									}),
								$elm$core$Platform$Cmd$none,
								$author$project$Pages$WorkTimeTracking$State_WorkerPage$OutNoOp);
						case 0:
							var notification = subOutMsg.a;
							return _Utils_Tuple3(
								updatedModel,
								cmd,
								$author$project$Pages$WorkTimeTracking$State_WorkerPage$OutNotification(notification));
						default:
							return _Utils_Tuple3(updatedModel, cmd, $author$project$Pages$WorkTimeTracking$State_WorkerPage$OutGotAuthError);
					}
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$State_WorkerPage$OutNoOp);
				}
			default:
				var subMsg = msg.a;
				var _v11 = model.c1;
				if (_v11.$ === 2) {
					var subModel = _v11.a;
					var _v12 = A2($author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$update, subMsg, subModel);
					var updatedSubModel = _v12.a;
					var subCmd = _v12.b;
					var subOutMsg = _v12.c;
					var cmd = A2($elm$core$Platform$Cmd$map, $author$project$Pages$WorkTimeTracking$State_WorkerPage$SubMsg_MarkEntryAsPaidDialog, subCmd);
					var updatedModel = _Utils_update(
						model,
						{
							c1: $author$project$Pages$WorkTimeTracking$State_WorkerPage$Dialog_MarkEntryAsPaid(updatedSubModel)
						});
					switch (subOutMsg.$) {
						case 2:
							return _Utils_Tuple3(updatedModel, cmd, $author$project$Pages$WorkTimeTracking$State_WorkerPage$OutNoOp);
						case 0:
							var entries = subOutMsg.a;
							var paymentComment = subOutMsg.b;
							var paymentDate = subOutMsg.c;
							return _Utils_Tuple3(
								updatedModel,
								A4(
									$author$project$Types$WorkTimeEntry$markAsPaidCmd,
									appContext.n1,
									$author$project$Pages$WorkTimeTracking$State_WorkerPage$Entry_GotResponse_MarkAsPaid(entries),
									user,
									{
										ho: A2(
											$elm$core$List$map,
											function ($) {
												return $.er;
											},
											entries),
										dp: paymentComment,
										nq: paymentDate
									}),
								$author$project$Pages$WorkTimeTracking$State_WorkerPage$OutNoOp);
						default:
							return _Utils_Tuple3(
								_Utils_update(
									updatedModel,
									{c1: $author$project$Pages$WorkTimeTracking$State_WorkerPage$NoDialog}),
								cmd,
								$author$project$Pages$WorkTimeTracking$State_WorkerPage$OutNoOp);
					}
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$WorkTimeTracking$State_WorkerPage$OutNoOp);
				}
		}
	});
var $author$project$Pages$WorkTimeTracking$update = F4(
	function (appContext, user, msg, model) {
		var _v0 = _Utils_Tuple2(msg, model._);
		_v0$6:
		while (true) {
			switch (_v0.b.$) {
				case 0:
					if (!_v0.a.$) {
						var msg_askingPin = _v0.a.a;
						var model_askingPin = _v0.b.a;
						return function (_v1) {
							var subModel = _v1.a;
							var subCmd = _v1.b;
							var subOutMsg = _v1.c;
							return A2(
								$elm$core$Maybe$withDefault,
								$author$project$Pages$WorkTimeTracking$update_askingPinState(
									_Utils_Tuple3(subModel, subCmd, subOutMsg)),
								A2($author$project$Pages$WorkTimeTracking$maybeTransitionFrom_askingPin, appContext.n1, subOutMsg));
						}(
							A3($author$project$Components$PinDialog$update, appContext, msg_askingPin, model_askingPin));
					} else {
						break _v0$6;
					}
				case 1:
					if (_v0.a.$ === 1) {
						var msg_Loading = _v0.a.a;
						var model_Loading = _v0.b.a;
						return function (_v2) {
							var subModel = _v2.a;
							var subCmd = _v2.b;
							var subOutMsg = _v2.c;
							return A2(
								$elm$core$Maybe$withDefault,
								$author$project$Pages$WorkTimeTracking$update_loadingState(
									_Utils_Tuple3(subModel, subCmd, subOutMsg)),
								A3($author$project$Pages$WorkTimeTracking$maybeTransitionFrom_loading, appContext, user, subOutMsg));
						}(
							A2($author$project$Pages$WorkTimeTracking$State_Loading$update, msg_Loading, model_Loading));
					} else {
						break _v0$6;
					}
				case 3:
					if (_v0.a.$ === 3) {
						var msg_WorkerPage = _v0.a.a;
						var model_WorkerPage = _v0.b.a;
						return function (_v3) {
							var subModel = _v3.a;
							var subCmd = _v3.b;
							var subOutMsg = _v3.c;
							return A2(
								$elm$core$Maybe$withDefault,
								$author$project$Pages$WorkTimeTracking$update_workerPageState(
									_Utils_Tuple3(subModel, subCmd, subOutMsg)),
								A2($author$project$Pages$WorkTimeTracking$maybeTransitionFrom_workerPage, appContext.n1, subOutMsg));
						}(
							A4($author$project$Pages$WorkTimeTracking$State_WorkerPage$update, appContext, user, msg_WorkerPage, model_WorkerPage));
					} else {
						break _v0$6;
					}
				case 4:
					if (_v0.a.$ === 4) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						return $author$project$Pages$WorkTimeTracking$update_noAccessState(
							A2($author$project$Pages$State_NoAccess$update, subMsg, subModel));
					} else {
						break _v0$6;
					}
				default:
					switch (_v0.a.$) {
						case 2:
							var msg_Main = _v0.a.a;
							var model_Main = _v0.b.a;
							return A3(
								$author$project$Pages$WorkTimeTracking$update_mainState,
								appContext,
								user,
								A4($author$project$Pages$WorkTimeTracking$State_Main$update, appContext, user, msg_Main, model_Main));
						case 5:
							var _v4 = _v0.a;
							var _v5 = $author$project$Types$User$workingHoursPageAccess(user);
							if (_v5 === 1) {
								return $author$project$Pages$WorkTimeTracking$doNothing(model);
							} else {
								return A2($author$project$Pages$WorkTimeTracking$askPinAgain, appContext, user);
							}
						default:
							break _v0$6;
					}
			}
		}
		return $author$project$Pages$WorkTimeTracking$doNothing(model);
	});
var $author$project$Main$update_LoggedIn = F3(
	function (model, msg, stateModel) {
		switch (msg.$) {
			case 9:
				var topMenuMsg = msg.a;
				var _v1 = stateModel;
				var appContext = _v1.q;
				var topMenuModel = _v1.dG;
				var _v2 = A3($author$project$Components$TopMenu$update, appContext, topMenuMsg, topMenuModel);
				var updatedTopMenuModel = _v2.a;
				var topMenuCmd = _v2.b;
				var outMsg = _v2.c;
				var updatedLoggedInModel = _Utils_update(
					stateModel,
					{dG: updatedTopMenuModel});
				switch (outMsg.$) {
					case 0:
						var pageLink = outMsg.a;
						var _v4 = A2($author$project$Main$navigateTo, updatedLoggedInModel, pageLink);
						var updatedloggedInModel2 = _v4.a;
						var navigateCmd = _v4.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									_: $author$project$Main$LoggedIn(updatedloggedInModel2)
								}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2(
										$elm$core$Platform$Cmd$map,
										$author$project$Main$Msg_LoggedIn,
										A2($elm$core$Platform$Cmd$map, $author$project$Main$GotTopMenuMsg, topMenuCmd)),
										A2($elm$core$Platform$Cmd$map, $author$project$Main$Msg_LoggedIn, navigateCmd)
									])));
					case 1:
						var _v5 = $author$project$Main$showUserNavigator(updatedLoggedInModel);
						var updatedloggedInModel2 = _v5.a;
						var userNavigatorCmd = _v5.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									_: $author$project$Main$LoggedIn(updatedloggedInModel2)
								}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2(
										$elm$core$Platform$Cmd$map,
										$author$project$Main$Msg_LoggedIn,
										A2($elm$core$Platform$Cmd$map, $author$project$Main$GotTopMenuMsg, topMenuCmd)),
										A2($elm$core$Platform$Cmd$map, $author$project$Main$Msg_LoggedIn, userNavigatorCmd)
									])));
					case 2:
						var notification = outMsg.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									P: $author$project$Components$Notification$start(notification),
									_: $author$project$Main$LoggedIn(updatedLoggedInModel)
								}),
							A2(
								$elm$core$Platform$Cmd$map,
								$author$project$Main$Msg_LoggedIn,
								A2($elm$core$Platform$Cmd$map, $author$project$Main$GotTopMenuMsg, topMenuCmd)));
					case 3:
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									_: $author$project$Main$LoggedIn(updatedLoggedInModel)
								}),
							$author$project$Main$logoutUser(0));
					default:
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									_: $author$project$Main$LoggedIn(updatedLoggedInModel)
								}),
							A2(
								$elm$core$Platform$Cmd$map,
								$author$project$Main$Msg_LoggedIn,
								A2($elm$core$Platform$Cmd$map, $author$project$Main$GotTopMenuMsg, topMenuCmd)));
				}
			case 5:
				var pageMsg = msg.a;
				var _v6 = stateModel.A;
				if (!_v6.$) {
					var pageModel = _v6.a;
					var _v7 = A4($author$project$Pages$Holidays$update, stateModel.q, stateModel.aS, pageMsg, pageModel);
					var updatedPageModel = _v7.a;
					var pageCmd = _v7.b;
					var outMsg = _v7.c;
					var cmd = A2(
						$elm$core$Platform$Cmd$map,
						$author$project$Main$Msg_LoggedIn,
						A2($elm$core$Platform$Cmd$map, $author$project$Main$GotHolidaysPageMsg, pageCmd));
					var updatedStateModel = _Utils_update(
						stateModel,
						{
							A: $author$project$Types$Page$HolidaysPage(updatedPageModel)
						});
					switch (outMsg.$) {
						case 0:
							var notification = outMsg.a;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										P: $author$project$Components$Notification$start(notification),
										_: $author$project$Main$LoggedIn(updatedStateModel)
									}),
								cmd);
						case 1:
							return A2(
								$author$project$Main$handleAuthError,
								_Utils_update(
									model,
									{
										_: $author$project$Main$LoggedIn(updatedStateModel)
									}),
								_List_fromArray(
									[cmd]));
						default:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										_: $author$project$Main$LoggedIn(updatedStateModel)
									}),
								cmd);
					}
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								_: $author$project$Main$LoggedIn(stateModel)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 6:
				var invoicingPageMsg = msg.a;
				var _v9 = stateModel.A;
				if (_v9.$ === 1) {
					var pageModel = _v9.a;
					var _v10 = A3($author$project$Pages$Invoicing$update, stateModel.q.n1, invoicingPageMsg, pageModel);
					var updatedPageModel = _v10.a;
					var pageCmd = _v10.b;
					var outMsg = _v10.c;
					var cmd = A2(
						$elm$core$Platform$Cmd$map,
						$author$project$Main$Msg_LoggedIn,
						A2($elm$core$Platform$Cmd$map, $author$project$Main$GotInvoicingPageMsg, pageCmd));
					var updatedStateModel = _Utils_update(
						stateModel,
						{
							A: $author$project$Types$Page$InvoicingPage(updatedPageModel)
						});
					switch (outMsg.$) {
						case 0:
							var notification = outMsg.a;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										P: $author$project$Components$Notification$start(notification),
										_: $author$project$Main$LoggedIn(updatedStateModel)
									}),
								cmd);
						case 1:
							return A2(
								$author$project$Main$handleAuthError,
								_Utils_update(
									model,
									{
										_: $author$project$Main$LoggedIn(updatedStateModel)
									}),
								_List_fromArray(
									[cmd]));
						default:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										_: $author$project$Main$LoggedIn(updatedStateModel)
									}),
								cmd);
					}
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								_: $author$project$Main$LoggedIn(stateModel)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 7:
				var tasklistPageMsg = msg.a;
				var _v12 = stateModel.A;
				if (_v12.$ === 3) {
					var pageModel = _v12.a;
					var _v13 = A3($author$project$Pages$Tasklist$update, stateModel.q, tasklistPageMsg, pageModel);
					var updatedPageModel = _v13.a;
					var pageCmd = _v13.b;
					var outMsg = _v13.c;
					var cmd = A2(
						$elm$core$Platform$Cmd$map,
						$author$project$Main$Msg_LoggedIn,
						A2($elm$core$Platform$Cmd$map, $author$project$Main$GotTasklistPageMsg, pageCmd));
					var updatedStateModel = _Utils_update(
						stateModel,
						{
							A: $author$project$Types$Page$TasklistPage(updatedPageModel)
						});
					var updatedModel = _Utils_update(
						model,
						{
							_: $author$project$Main$LoggedIn(updatedStateModel)
						});
					switch (outMsg.$) {
						case 0:
							var notification = outMsg.a;
							return _Utils_Tuple2(
								_Utils_update(
									updatedModel,
									{
										P: $author$project$Components$Notification$start(notification),
										_: $author$project$Main$LoggedIn(updatedStateModel)
									}),
								cmd);
						case 1:
							return A2(
								$author$project$Main$handleAuthError,
								updatedModel,
								_List_fromArray(
									[cmd]));
						default:
							return _Utils_Tuple2(updatedModel, cmd);
					}
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								_: $author$project$Main$LoggedIn(stateModel)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 10:
				var reportingPageMsg = msg.a;
				var _v15 = stateModel.A;
				if (_v15.$ === 2) {
					var pageModel = _v15.a;
					var _v16 = A4($author$project$Pages$Reporting$update, stateModel.q, stateModel.aS, reportingPageMsg, pageModel);
					var newPageModel = _v16.a;
					var pageCmd = _v16.b;
					var outMsg = _v16.c;
					var updatedStateModel = _Utils_update(
						stateModel,
						{
							A: $author$project$Types$Page$ReportingPage(newPageModel)
						});
					var cmd = A2(
						$elm$core$Platform$Cmd$map,
						$author$project$Main$Msg_LoggedIn,
						A2($elm$core$Platform$Cmd$map, $author$project$Main$GotReportingPageMsg, pageCmd));
					switch (outMsg.$) {
						case 1:
							var notification = outMsg.a;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										P: $author$project$Components$Notification$start(notification),
										_: $author$project$Main$LoggedIn(updatedStateModel)
									}),
								cmd);
						case 2:
							return A2(
								$author$project$Main$handleAuthError,
								_Utils_update(
									model,
									{
										_: $author$project$Main$LoggedIn(updatedStateModel)
									}),
								_List_fromArray(
									[cmd]));
						default:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										_: $author$project$Main$LoggedIn(updatedStateModel)
									}),
								cmd);
					}
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								_: $author$project$Main$LoggedIn(stateModel)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 11:
				var userNavigatorMsg = msg.a;
				var _v18 = stateModel.bO;
				if (!_v18.$) {
					var userNavigatorModel = _v18.a;
					var _v19 = A3($author$project$Components$UserNavigator$update, stateModel.q.n1, userNavigatorMsg, userNavigatorModel);
					var updatedUserNavigatorModel = _v19.a;
					var userNavigatorCmd = _v19.b;
					var outMsg = _v19.c;
					var updatedStateModel = _Utils_update(
						stateModel,
						{
							bO: $elm$core$Maybe$Just(updatedUserNavigatorModel)
						});
					var cmd = A2(
						$elm$core$Platform$Cmd$map,
						$author$project$Main$Msg_LoggedIn,
						A2($elm$core$Platform$Cmd$map, $author$project$Main$GotUserNavigatorMsg, userNavigatorCmd));
					switch (outMsg.$) {
						case 2:
							return A2(
								$author$project$Main$handleAuthError,
								_Utils_update(
									model,
									{
										_: $author$project$Main$LoggedIn(updatedStateModel)
									}),
								_List_fromArray(
									[cmd]));
						case 0:
							var user = outMsg.a;
							var _v21 = A2($author$project$Main$switchSelectedUser, updatedStateModel, user);
							var updatedModel2 = _v21.a;
							var switchUserCmd = _v21.b;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										_: $author$project$Main$LoggedIn(updatedModel2)
									}),
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											cmd,
											A2($elm$core$Platform$Cmd$map, $author$project$Main$Msg_LoggedIn, switchUserCmd)
										])));
						case 1:
							var _v22 = $author$project$Main$hideUserNavigator(updatedStateModel);
							var updatedModel2 = _v22.a;
							var hideUserNavigatorCmd = _v22.b;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										_: $author$project$Main$LoggedIn(updatedModel2)
									}),
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											cmd,
											A2($elm$core$Platform$Cmd$map, $author$project$Main$Msg_LoggedIn, hideUserNavigatorCmd)
										])));
						default:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										_: $author$project$Main$LoggedIn(updatedStateModel)
									}),
								$elm$core$Platform$Cmd$none);
					}
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								_: $author$project$Main$LoggedIn(stateModel)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 8:
				var workTimeTrackingPageMsg = msg.a;
				var _v23 = stateModel.A;
				if (_v23.$ === 4) {
					var pageModel = _v23.a;
					var _v24 = A4($author$project$Pages$WorkTimeTracking$update, stateModel.q, stateModel.aS, workTimeTrackingPageMsg, pageModel);
					var updatedPageModel = _v24.a;
					var pageCmd = _v24.b;
					var outMsg = _v24.c;
					var cmd = A2(
						$elm$core$Platform$Cmd$map,
						$author$project$Main$Msg_LoggedIn,
						A2($elm$core$Platform$Cmd$map, $author$project$Main$GotWorkTimeTrackingPageMsg, pageCmd));
					var updatedStateModel = _Utils_update(
						stateModel,
						{
							A: $author$project$Types$Page$WorkTimeTrackingPage(updatedPageModel)
						});
					switch (outMsg.$) {
						case 0:
							var notification = outMsg.a;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										P: $author$project$Components$Notification$start(notification),
										_: $author$project$Main$LoggedIn(updatedStateModel)
									}),
								cmd);
						case 1:
							return A2(
								$author$project$Main$handleAuthError,
								_Utils_update(
									model,
									{
										_: $author$project$Main$LoggedIn(updatedStateModel)
									}),
								_List_fromArray(
									[cmd]));
						default:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										_: $author$project$Main$LoggedIn(updatedStateModel)
									}),
								cmd);
					}
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								_: $author$project$Main$LoggedIn(stateModel)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 0:
				var pageLink = msg.a;
				var _v26 = A2($author$project$Main$navigateTo, stateModel, pageLink);
				var updatedModel = _v26.a;
				var navigateCmd = _v26.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							_: $author$project$Main$LoggedIn(updatedModel)
						}),
					A2($elm$core$Platform$Cmd$map, $author$project$Main$Msg_LoggedIn, navigateCmd));
			case 2:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							_: $author$project$Main$LoggedIn(stateModel)
						}),
					A2(
						$elm$core$Platform$Cmd$map,
						$author$project$Main$Msg_LoggedIn,
						$author$project$Main$checkLoginStatusCmd(stateModel.q.n1)));
			case 3:
				if (!msg.a.$) {
					var isLoginOk = msg.a.a;
					return isLoginOk ? _Utils_Tuple2(
						_Utils_update(
							model,
							{
								_: $author$project$Main$LoggedIn(stateModel)
							}),
						$elm$core$Platform$Cmd$none) : A2(
						$author$project$Main$handleAuthError,
						_Utils_update(
							model,
							{
								_: $author$project$Main$LoggedIn(stateModel)
							}),
						_List_Nil);
				} else {
					var httpError = msg.a.a;
					if ((httpError.$ === 3) && (httpError.a === 401)) {
						return A2(
							$author$project$Main$handleAuthError,
							_Utils_update(
								model,
								{
									_: $author$project$Main$LoggedIn(stateModel)
								}),
							_List_Nil);
					} else {
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									_: $author$project$Main$LoggedIn(stateModel)
								}),
							$elm$core$Platform$Cmd$none);
					}
				}
			case 4:
				var newToday = msg.a;
				var updatedAppContext = function (a) {
					return _Utils_update(
						a,
						{dE: newToday});
				}(stateModel.q);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							_: $author$project$Main$LoggedIn(
								_Utils_update(
									stateModel,
									{q: updatedAppContext}))
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var appContext = stateModel.q;
				return A2(
					$author$project$Main$init_Authenticating,
					model,
					{j0: appContext.n1.j0, av: appContext.n1.fx, lA: appContext.lA, ag: appContext.n1.ag, dE: appContext.dE, oK: $elm$core$Maybe$Nothing});
			default:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							_: $author$project$Main$LoggedIn(stateModel)
						}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Main$update = F2(
	function (msg, model) {
		var _v0 = _Utils_Tuple2(msg, model._);
		_v0$5:
		while (true) {
			switch (_v0.a.$) {
				case 0:
					if (!_v0.b.$) {
						var initializingMsg = _v0.a.a;
						var initializingModel = _v0.b.a;
						return A3($author$project$Main$update_Initializing, model, initializingMsg, initializingModel);
					} else {
						break _v0$5;
					}
				case 1:
					if (_v0.b.$ === 1) {
						var authenticatingMsg = _v0.a.a;
						var authenticatingModel = _v0.b.a;
						return A3($author$project$Main$update_Authenticating, model, authenticatingMsg, authenticatingModel);
					} else {
						break _v0$5;
					}
				case 2:
					if (_v0.b.$ === 2) {
						var loggedInMsg = _v0.a.a;
						var loggedInModel = _v0.b.a;
						return A3($author$project$Main$update_LoggedIn, model, loggedInMsg, loggedInModel);
					} else {
						break _v0$5;
					}
				case 4:
					var ms = _v0.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								P: A2($author$project$Components$Notification$run, ms, model.P)
							}),
						$elm$core$Platform$Cmd$none);
				default:
					if (_v0.b.$ === 2) {
						var _v1 = _v0.a;
						var loggedInModel = _v0.b.a;
						var updatedAppContext = function (a) {
							return _Utils_update(
								a,
								{gy: true});
						}(loggedInModel.q);
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									_: $author$project$Main$LoggedIn(
										_Utils_update(
											loggedInModel,
											{q: updatedAppContext}))
								}),
							$author$project$Main$stopDetectingTouchEvents(0));
					} else {
						break _v0$5;
					}
			}
		}
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	});
var $mdgriffith$elm_ui$Internal$Flag$bgColor = $mdgriffith$elm_ui$Internal$Flag$flag(8);
var $mdgriffith$elm_ui$Element$Background$color = function (clr) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$bgColor,
		A3(
			$mdgriffith$elm_ui$Internal$Model$Colored,
			'bg-' + $mdgriffith$elm_ui$Internal$Model$formatColorClass(clr),
			'background-color',
			clr));
};
var $mdgriffith$elm_ui$Internal$Model$AsColumn = 1;
var $mdgriffith$elm_ui$Internal$Model$asColumn = 1;
var $mdgriffith$elm_ui$Internal$Model$htmlClass = function (cls) {
	return $mdgriffith$elm_ui$Internal$Model$Attr(
		$elm$html$Html$Attributes$class(cls));
};
var $mdgriffith$elm_ui$Element$column = F2(
	function (attrs, children) {
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asColumn,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.kN + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.cQ)),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
						attrs))),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
	});
var $smucode$elm_flat_colors$FlatColors$DutchPalette$puffinsBill = A3($mdgriffith$elm_ui$Element$rgb255, 238, 90, 36);
var $author$project$Colors$primary = function (levelNo) {
	return A2($author$project$Colors$level, levelNo, $smucode$elm_flat_colors$FlatColors$DutchPalette$puffinsBill);
};
var $author$project$Styles$defaultFocus = {
	j6: $elm$core$Maybe$Nothing,
	ke: $elm$core$Maybe$Just(
		$author$project$Colors$primary(5)),
	n3: $elm$core$Maybe$Just(
		{
			fp: 1,
			fu: $author$project$Colors$primary(1),
			f1: _Utils_Tuple2(0, 0),
			gl: 1
		})
};
var $mdgriffith$elm_ui$Internal$Model$Fill = function (a) {
	return {$: 2, a: a};
};
var $mdgriffith$elm_ui$Element$fill = $mdgriffith$elm_ui$Internal$Model$Fill(1);
var $mdgriffith$elm_ui$Internal$Model$FocusStyleOption = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Element$focusStyle = $mdgriffith$elm_ui$Internal$Model$FocusStyleOption;
var $mdgriffith$elm_ui$Internal$Model$OnlyDynamic = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$StaticRootAndDynamic = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$AllowHover = 1;
var $mdgriffith$elm_ui$Internal$Model$Layout = 0;
var $mdgriffith$elm_ui$Internal$Model$focusDefaultStyle = {
	j6: $elm$core$Maybe$Nothing,
	ke: $elm$core$Maybe$Nothing,
	n3: $elm$core$Maybe$Just(
		{
			fp: 0,
			fu: A4($mdgriffith$elm_ui$Internal$Model$Rgba, 155 / 255, 203 / 255, 1, 1),
			f1: _Utils_Tuple2(0, 0),
			gl: 3
		})
};
var $mdgriffith$elm_ui$Internal$Model$optionsToRecord = function (options) {
	var combine = F2(
		function (opt, record) {
			switch (opt.$) {
				case 0:
					var hoverable = opt.a;
					var _v4 = record.lK;
					if (_v4.$ === 1) {
						return _Utils_update(
							record,
							{
								lK: $elm$core$Maybe$Just(hoverable)
							});
					} else {
						return record;
					}
				case 1:
					var focusStyle = opt.a;
					var _v5 = record.ly;
					if (_v5.$ === 1) {
						return _Utils_update(
							record,
							{
								ly: $elm$core$Maybe$Just(focusStyle)
							});
					} else {
						return record;
					}
				default:
					var renderMode = opt.a;
					var _v6 = record.ez;
					if (_v6.$ === 1) {
						return _Utils_update(
							record,
							{
								ez: $elm$core$Maybe$Just(renderMode)
							});
					} else {
						return record;
					}
			}
		});
	var andFinally = function (record) {
		return {
			ly: function () {
				var _v0 = record.ly;
				if (_v0.$ === 1) {
					return $mdgriffith$elm_ui$Internal$Model$focusDefaultStyle;
				} else {
					var focusable = _v0.a;
					return focusable;
				}
			}(),
			lK: function () {
				var _v1 = record.lK;
				if (_v1.$ === 1) {
					return 1;
				} else {
					var hoverable = _v1.a;
					return hoverable;
				}
			}(),
			ez: function () {
				var _v2 = record.ez;
				if (_v2.$ === 1) {
					return 0;
				} else {
					var actualMode = _v2.a;
					return actualMode;
				}
			}()
		};
	};
	return andFinally(
		A3(
			$elm$core$List$foldr,
			combine,
			{ly: $elm$core$Maybe$Nothing, lK: $elm$core$Maybe$Nothing, ez: $elm$core$Maybe$Nothing},
			options));
};
var $mdgriffith$elm_ui$Internal$Model$toHtml = F2(
	function (mode, el) {
		switch (el.$) {
			case 0:
				var html = el.a;
				return html($mdgriffith$elm_ui$Internal$Model$asEl);
			case 1:
				var styles = el.a.dx;
				var html = el.a.lM;
				return A2(
					html,
					mode(styles),
					$mdgriffith$elm_ui$Internal$Model$asEl);
			case 2:
				var text = el.a;
				return $mdgriffith$elm_ui$Internal$Model$textElement(text);
			default:
				return $mdgriffith$elm_ui$Internal$Model$textElement('');
		}
	});
var $mdgriffith$elm_ui$Internal$Model$renderRoot = F3(
	function (optionList, attributes, child) {
		var options = $mdgriffith$elm_ui$Internal$Model$optionsToRecord(optionList);
		var embedStyle = function () {
			var _v0 = options.ez;
			if (_v0 === 1) {
				return $mdgriffith$elm_ui$Internal$Model$OnlyDynamic(options);
			} else {
				return $mdgriffith$elm_ui$Internal$Model$StaticRootAndDynamic(options);
			}
		}();
		return A2(
			$mdgriffith$elm_ui$Internal$Model$toHtml,
			embedStyle,
			A4(
				$mdgriffith$elm_ui$Internal$Model$element,
				$mdgriffith$elm_ui$Internal$Model$asEl,
				$mdgriffith$elm_ui$Internal$Model$div,
				attributes,
				$mdgriffith$elm_ui$Internal$Model$Unkeyed(
					_List_fromArray(
						[child]))));
	});
var $mdgriffith$elm_ui$Internal$Model$FontFamily = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$FontSize = function (a) {
	return {$: 2, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$SansSerif = {$: 1};
var $mdgriffith$elm_ui$Internal$Model$Typeface = function (a) {
	return {$: 3, a: a};
};
var $mdgriffith$elm_ui$Internal$Flag$fontFamily = $mdgriffith$elm_ui$Internal$Flag$flag(5);
var $mdgriffith$elm_ui$Internal$Flag$fontSize = $mdgriffith$elm_ui$Internal$Flag$flag(4);
var $elm$core$String$words = _String_words;
var $mdgriffith$elm_ui$Internal$Model$renderFontClassName = F2(
	function (font, current) {
		return _Utils_ap(
			current,
			function () {
				switch (font.$) {
					case 0:
						return 'serif';
					case 1:
						return 'sans-serif';
					case 2:
						return 'monospace';
					case 3:
						var name = font.a;
						return A2(
							$elm$core$String$join,
							'-',
							$elm$core$String$words(
								$elm$core$String$toLower(name)));
					case 4:
						var name = font.a;
						var url = font.b;
						return A2(
							$elm$core$String$join,
							'-',
							$elm$core$String$words(
								$elm$core$String$toLower(name)));
					default:
						var name = font.a.mD;
						return A2(
							$elm$core$String$join,
							'-',
							$elm$core$String$words(
								$elm$core$String$toLower(name)));
				}
			}());
	});
var $mdgriffith$elm_ui$Internal$Model$rootStyle = function () {
	var families = _List_fromArray(
		[
			$mdgriffith$elm_ui$Internal$Model$Typeface('Open Sans'),
			$mdgriffith$elm_ui$Internal$Model$Typeface('Helvetica'),
			$mdgriffith$elm_ui$Internal$Model$Typeface('Verdana'),
			$mdgriffith$elm_ui$Internal$Model$SansSerif
		]);
	return _List_fromArray(
		[
			A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$bgColor,
			A3(
				$mdgriffith$elm_ui$Internal$Model$Colored,
				'bg-' + $mdgriffith$elm_ui$Internal$Model$formatColorClass(
					A4($mdgriffith$elm_ui$Internal$Model$Rgba, 1, 1, 1, 0)),
				'background-color',
				A4($mdgriffith$elm_ui$Internal$Model$Rgba, 1, 1, 1, 0))),
			A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$fontColor,
			A3(
				$mdgriffith$elm_ui$Internal$Model$Colored,
				'fc-' + $mdgriffith$elm_ui$Internal$Model$formatColorClass(
					A4($mdgriffith$elm_ui$Internal$Model$Rgba, 0, 0, 0, 1)),
				'color',
				A4($mdgriffith$elm_ui$Internal$Model$Rgba, 0, 0, 0, 1))),
			A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$fontSize,
			$mdgriffith$elm_ui$Internal$Model$FontSize(20)),
			A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$fontFamily,
			A2(
				$mdgriffith$elm_ui$Internal$Model$FontFamily,
				A3($elm$core$List$foldl, $mdgriffith$elm_ui$Internal$Model$renderFontClassName, 'font-', families),
				families))
		]);
}();
var $mdgriffith$elm_ui$Element$layoutWith = F3(
	function (_v0, attrs, child) {
		var options = _v0.nk;
		return A3(
			$mdgriffith$elm_ui$Internal$Model$renderRoot,
			options,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$htmlClass(
					A2(
						$elm$core$String$join,
						' ',
						_List_fromArray(
							[$mdgriffith$elm_ui$Internal$Style$classes.nM, $mdgriffith$elm_ui$Internal$Style$classes.j$, $mdgriffith$elm_ui$Internal$Style$classes.n6]))),
				_Utils_ap($mdgriffith$elm_ui$Internal$Model$rootStyle, attrs)),
			child);
	});
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $mdgriffith$elm_ui$Internal$Model$map = F2(
	function (fn, el) {
		switch (el.$) {
			case 1:
				var styled = el.a;
				return $mdgriffith$elm_ui$Internal$Model$Styled(
					{
						lM: F2(
							function (add, context) {
								return A2(
									$elm$virtual_dom$VirtualDom$map,
									fn,
									A2(styled.lM, add, context));
							}),
						dx: styled.dx
					});
			case 0:
				var html = el.a;
				return $mdgriffith$elm_ui$Internal$Model$Unstyled(
					A2(
						$elm$core$Basics$composeL,
						$elm$virtual_dom$VirtualDom$map(fn),
						html));
			case 2:
				var str = el.a;
				return $mdgriffith$elm_ui$Internal$Model$Text(str);
			default:
				return $mdgriffith$elm_ui$Internal$Model$Empty;
		}
	});
var $mdgriffith$elm_ui$Element$map = $mdgriffith$elm_ui$Internal$Model$map;
var $author$project$Colors$surface = function (levelNo) {
	return A2(
		$author$project$Colors$level,
		levelNo,
		A3($mdgriffith$elm_ui$Element$rgb255, 28, 28, 28));
};
var $mdgriffith$elm_ui$Internal$Model$Class = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Flag$fontAlignment = $mdgriffith$elm_ui$Internal$Flag$flag(12);
var $mdgriffith$elm_ui$Element$Font$alignLeft = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$fontAlignment, $mdgriffith$elm_ui$Internal$Style$classes.ov);
var $mdgriffith$elm_ui$Internal$Model$Max = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $mdgriffith$elm_ui$Element$maximum = F2(
	function (i, l) {
		return A2($mdgriffith$elm_ui$Internal$Model$Max, i, l);
	});
var $mdgriffith$elm_ui$Internal$Model$Min = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $mdgriffith$elm_ui$Element$minimum = F2(
	function (i, l) {
		return A2($mdgriffith$elm_ui$Internal$Model$Min, i, l);
	});
var $mdgriffith$elm_ui$Internal$Model$PaddingStyle = F5(
	function (a, b, c, d, e) {
		return {$: 7, a: a, b: b, c: c, d: d, e: e};
	});
var $mdgriffith$elm_ui$Internal$Flag$padding = $mdgriffith$elm_ui$Internal$Flag$flag(2);
var $mdgriffith$elm_ui$Element$paddingXY = F2(
	function (x, y) {
		if (_Utils_eq(x, y)) {
			var f = x;
			return A2(
				$mdgriffith$elm_ui$Internal$Model$StyleClass,
				$mdgriffith$elm_ui$Internal$Flag$padding,
				A5(
					$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
					'p-' + $elm$core$String$fromInt(x),
					f,
					f,
					f,
					f));
		} else {
			var yFloat = y;
			var xFloat = x;
			return A2(
				$mdgriffith$elm_ui$Internal$Model$StyleClass,
				$mdgriffith$elm_ui$Internal$Flag$padding,
				A5(
					$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
					'p-' + ($elm$core$String$fromInt(x) + ('-' + $elm$core$String$fromInt(y))),
					yFloat,
					xFloat,
					yFloat,
					xFloat));
		}
	});
var $mdgriffith$elm_ui$Internal$Model$Describe = function (a) {
	return {$: 2, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$Paragraph = {$: 9};
var $mdgriffith$elm_ui$Internal$Model$SpacingStyle = F3(
	function (a, b, c) {
		return {$: 5, a: a, b: b, c: c};
	});
var $mdgriffith$elm_ui$Internal$Flag$spacing = $mdgriffith$elm_ui$Internal$Flag$flag(3);
var $mdgriffith$elm_ui$Internal$Model$spacingName = F2(
	function (x, y) {
		return 'spacing-' + ($elm$core$String$fromInt(x) + ('-' + $elm$core$String$fromInt(y)));
	});
var $mdgriffith$elm_ui$Element$spacing = function (x) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$spacing,
		A3(
			$mdgriffith$elm_ui$Internal$Model$SpacingStyle,
			A2($mdgriffith$elm_ui$Internal$Model$spacingName, x, x),
			x,
			x));
};
var $mdgriffith$elm_ui$Element$paragraph = F2(
	function (attrs, children) {
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asParagraph,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$Describe($mdgriffith$elm_ui$Internal$Model$Paragraph),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$spacing(5),
						attrs))),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
	});
var $mdgriffith$elm_ui$Internal$Flag$borderRound = $mdgriffith$elm_ui$Internal$Flag$flag(17);
var $mdgriffith$elm_ui$Element$Border$rounded = function (radius) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$borderRound,
		A3(
			$mdgriffith$elm_ui$Internal$Model$Single,
			'br-' + $elm$core$String$fromInt(radius),
			'border-radius',
			$elm$core$String$fromInt(radius) + 'px'));
};
var $author$project$Components$Notification$view = function (notificationState) {
	if (notificationState.$ === 1) {
		var _v1 = notificationState.a;
		var message = _v1.a;
		var placement = _v1.b;
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$htmlAttribute(
						A2($elm$html$Html$Attributes$style, 'z-index', '99999')),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						A2($elm$html$Html$Attributes$style, 'position', 'fixed')),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						A2($elm$html$Html$Attributes$style, 'right', '24px'))
					]),
				function () {
					if (!placement) {
						return _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$htmlAttribute(
								A2($elm$html$Html$Attributes$style, 'top', '50px'))
							]);
					} else {
						return _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$htmlAttribute(
								A2($elm$html$Html$Attributes$style, 'bottom', '24px'))
							]);
					}
				}()),
			A2(
				$mdgriffith$elm_ui$Element$paragraph,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width(
						A2(
							$mdgriffith$elm_ui$Element$maximum,
							300,
							A2($mdgriffith$elm_ui$Element$minimum, 100, $mdgriffith$elm_ui$Element$fill))),
						A2($mdgriffith$elm_ui$Element$paddingXY, 16, 12),
						$mdgriffith$elm_ui$Element$Border$rounded(8),
						$mdgriffith$elm_ui$Element$Font$color(
						$author$project$Colors$onSurface(9)),
						$mdgriffith$elm_ui$Element$Background$color(
						$author$project$Colors$surface(2)),
						$mdgriffith$elm_ui$Element$Font$alignLeft
					]),
				$elm$core$List$singleton(
					$mdgriffith$elm_ui$Element$text(message))));
	} else {
		return $mdgriffith$elm_ui$Element$none;
	}
};
var $author$project$Pages$Login$ChangePassword = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$Login$ChangeUsername = function (a) {
	return {$: 4, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$AlignY = function (a) {
	return {$: 5, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$CenterY = 1;
var $mdgriffith$elm_ui$Element$centerY = $mdgriffith$elm_ui$Internal$Model$AlignY(1);
var $elm$html$Html$Attributes$alt = $elm$html$Html$Attributes$stringProperty('alt');
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $mdgriffith$elm_ui$Element$image = F2(
	function (attrs, _v0) {
		var src = _v0.oc;
		var description = _v0.le;
		var imageAttributes = A2(
			$elm$core$List$filter,
			function (a) {
				switch (a.$) {
					case 7:
						return true;
					case 8:
						return true;
					default:
						return false;
				}
			},
			attrs);
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asEl,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.lO),
				attrs),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(
				_List_fromArray(
					[
						A4(
						$mdgriffith$elm_ui$Internal$Model$element,
						$mdgriffith$elm_ui$Internal$Model$asEl,
						$mdgriffith$elm_ui$Internal$Model$NodeName('img'),
						_Utils_ap(
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Internal$Model$Attr(
									$elm$html$Html$Attributes$src(src)),
									$mdgriffith$elm_ui$Internal$Model$Attr(
									$elm$html$Html$Attributes$alt(description))
								]),
							imageAttributes),
						$mdgriffith$elm_ui$Internal$Model$Unkeyed(_List_Nil))
					])));
	});
var $mdgriffith$elm_ui$Element$Input$TextInputNode = function (a) {
	return {$: 0, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$NoAttribute = {$: 0};
var $mdgriffith$elm_ui$Element$Input$TextArea = {$: 1};
var $mdgriffith$elm_ui$Internal$Model$LivePolite = {$: 6};
var $mdgriffith$elm_ui$Element$Region$announce = $mdgriffith$elm_ui$Internal$Model$Describe($mdgriffith$elm_ui$Internal$Model$LivePolite);
var $mdgriffith$elm_ui$Internal$Model$AsRow = 0;
var $mdgriffith$elm_ui$Internal$Model$asRow = 0;
var $mdgriffith$elm_ui$Element$Input$applyLabel = F3(
	function (attrs, label, input) {
		if (label.$ === 1) {
			var labelText = label.a;
			return A4(
				$mdgriffith$elm_ui$Internal$Model$element,
				$mdgriffith$elm_ui$Internal$Model$asColumn,
				$mdgriffith$elm_ui$Internal$Model$NodeName('label'),
				attrs,
				$mdgriffith$elm_ui$Internal$Model$Unkeyed(
					_List_fromArray(
						[input])));
		} else {
			var position = label.a;
			var labelAttrs = label.b;
			var labelChild = label.c;
			var labelElement = A4(
				$mdgriffith$elm_ui$Internal$Model$element,
				$mdgriffith$elm_ui$Internal$Model$asEl,
				$mdgriffith$elm_ui$Internal$Model$div,
				labelAttrs,
				$mdgriffith$elm_ui$Internal$Model$Unkeyed(
					_List_fromArray(
						[labelChild])));
			switch (position) {
				case 2:
					return A4(
						$mdgriffith$elm_ui$Internal$Model$element,
						$mdgriffith$elm_ui$Internal$Model$asColumn,
						$mdgriffith$elm_ui$Internal$Model$NodeName('label'),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.et),
							attrs),
						$mdgriffith$elm_ui$Internal$Model$Unkeyed(
							_List_fromArray(
								[labelElement, input])));
				case 3:
					return A4(
						$mdgriffith$elm_ui$Internal$Model$element,
						$mdgriffith$elm_ui$Internal$Model$asColumn,
						$mdgriffith$elm_ui$Internal$Model$NodeName('label'),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.et),
							attrs),
						$mdgriffith$elm_ui$Internal$Model$Unkeyed(
							_List_fromArray(
								[input, labelElement])));
				case 0:
					return A4(
						$mdgriffith$elm_ui$Internal$Model$element,
						$mdgriffith$elm_ui$Internal$Model$asRow,
						$mdgriffith$elm_ui$Internal$Model$NodeName('label'),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.et),
							attrs),
						$mdgriffith$elm_ui$Internal$Model$Unkeyed(
							_List_fromArray(
								[input, labelElement])));
				default:
					return A4(
						$mdgriffith$elm_ui$Internal$Model$element,
						$mdgriffith$elm_ui$Internal$Model$asRow,
						$mdgriffith$elm_ui$Internal$Model$NodeName('label'),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.et),
							attrs),
						$mdgriffith$elm_ui$Internal$Model$Unkeyed(
							_List_fromArray(
								[labelElement, input])));
			}
		}
	});
var $mdgriffith$elm_ui$Element$Input$autofill = A2(
	$elm$core$Basics$composeL,
	$mdgriffith$elm_ui$Internal$Model$Attr,
	$elm$html$Html$Attributes$attribute('autocomplete'));
var $mdgriffith$elm_ui$Internal$Model$Behind = 5;
var $mdgriffith$elm_ui$Internal$Model$Nearby = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $mdgriffith$elm_ui$Element$createNearby = F2(
	function (loc, element) {
		if (element.$ === 3) {
			return $mdgriffith$elm_ui$Internal$Model$NoAttribute;
		} else {
			return A2($mdgriffith$elm_ui$Internal$Model$Nearby, loc, element);
		}
	});
var $mdgriffith$elm_ui$Element$behindContent = function (element) {
	return A2($mdgriffith$elm_ui$Element$createNearby, 5, element);
};
var $mdgriffith$elm_ui$Internal$Model$MoveY = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$TransformComponent = F2(
	function (a, b) {
		return {$: 10, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Flag$moveY = $mdgriffith$elm_ui$Internal$Flag$flag(26);
var $mdgriffith$elm_ui$Element$moveUp = function (y) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$TransformComponent,
		$mdgriffith$elm_ui$Internal$Flag$moveY,
		$mdgriffith$elm_ui$Internal$Model$MoveY(-y));
};
var $mdgriffith$elm_ui$Element$Input$calcMoveToCompensateForPadding = function (attrs) {
	var gatherSpacing = F2(
		function (attr, found) {
			if ((attr.$ === 4) && (attr.b.$ === 5)) {
				var _v2 = attr.b;
				var x = _v2.b;
				var y = _v2.c;
				if (found.$ === 1) {
					return $elm$core$Maybe$Just(y);
				} else {
					return found;
				}
			} else {
				return found;
			}
		});
	var _v0 = A3($elm$core$List$foldr, gatherSpacing, $elm$core$Maybe$Nothing, attrs);
	if (_v0.$ === 1) {
		return $mdgriffith$elm_ui$Internal$Model$NoAttribute;
	} else {
		var vSpace = _v0.a;
		return $mdgriffith$elm_ui$Element$moveUp(
			$elm$core$Basics$floor(vSpace / 2));
	}
};
var $mdgriffith$elm_ui$Internal$Flag$overflow = $mdgriffith$elm_ui$Internal$Flag$flag(20);
var $mdgriffith$elm_ui$Element$clip = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$overflow, $mdgriffith$elm_ui$Internal$Style$classes.kB);
var $mdgriffith$elm_ui$Internal$Flag$cursor = $mdgriffith$elm_ui$Internal$Flag$flag(21);
var $mdgriffith$elm_ui$Internal$Flag$borderColor = $mdgriffith$elm_ui$Internal$Flag$flag(28);
var $mdgriffith$elm_ui$Element$Border$color = function (clr) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$borderColor,
		A3(
			$mdgriffith$elm_ui$Internal$Model$Colored,
			'bc-' + $mdgriffith$elm_ui$Internal$Model$formatColorClass(clr),
			'border-color',
			clr));
};
var $mdgriffith$elm_ui$Element$rgb = F3(
	function (r, g, b) {
		return A4($mdgriffith$elm_ui$Internal$Model$Rgba, r, g, b, 1);
	});
var $mdgriffith$elm_ui$Element$Input$darkGrey = A3($mdgriffith$elm_ui$Element$rgb, 186 / 255, 189 / 255, 182 / 255);
var $mdgriffith$elm_ui$Element$Input$defaultTextPadding = A2($mdgriffith$elm_ui$Element$paddingXY, 12, 12);
var $mdgriffith$elm_ui$Element$Input$white = A3($mdgriffith$elm_ui$Element$rgb, 1, 1, 1);
var $mdgriffith$elm_ui$Internal$Model$BorderWidth = F5(
	function (a, b, c, d, e) {
		return {$: 6, a: a, b: b, c: c, d: d, e: e};
	});
var $mdgriffith$elm_ui$Element$Border$width = function (v) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$borderWidth,
		A5(
			$mdgriffith$elm_ui$Internal$Model$BorderWidth,
			'b-' + $elm$core$String$fromInt(v),
			v,
			v,
			v,
			v));
};
var $mdgriffith$elm_ui$Element$Input$defaultTextBoxStyle = _List_fromArray(
	[
		$mdgriffith$elm_ui$Element$Input$defaultTextPadding,
		$mdgriffith$elm_ui$Element$Border$rounded(3),
		$mdgriffith$elm_ui$Element$Border$color($mdgriffith$elm_ui$Element$Input$darkGrey),
		$mdgriffith$elm_ui$Element$Background$color($mdgriffith$elm_ui$Element$Input$white),
		$mdgriffith$elm_ui$Element$Border$width(1),
		$mdgriffith$elm_ui$Element$spacing(5),
		$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
		$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink)
	]);
var $mdgriffith$elm_ui$Element$Input$getHeight = function (attr) {
	if (attr.$ === 8) {
		var h = attr.a;
		return $elm$core$Maybe$Just(h);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $mdgriffith$elm_ui$Element$Input$hasFocusStyle = function (attr) {
	if (((attr.$ === 4) && (attr.b.$ === 11)) && (!attr.b.a)) {
		var _v1 = attr.b;
		var _v2 = _v1.a;
		return true;
	} else {
		return false;
	}
};
var $mdgriffith$elm_ui$Internal$Model$Label = function (a) {
	return {$: 5, a: a};
};
var $mdgriffith$elm_ui$Element$Input$hiddenLabelAttribute = function (label) {
	if (label.$ === 1) {
		var textLabel = label.a;
		return $mdgriffith$elm_ui$Internal$Model$Describe(
			$mdgriffith$elm_ui$Internal$Model$Label(textLabel));
	} else {
		return $mdgriffith$elm_ui$Internal$Model$NoAttribute;
	}
};
var $mdgriffith$elm_ui$Internal$Model$InFront = 4;
var $mdgriffith$elm_ui$Element$inFront = function (element) {
	return A2($mdgriffith$elm_ui$Element$createNearby, 4, element);
};
var $mdgriffith$elm_ui$Element$Input$isConstrained = function (len) {
	isConstrained:
	while (true) {
		switch (len.$) {
			case 1:
				return false;
			case 0:
				return true;
			case 2:
				return true;
			case 3:
				var l = len.b;
				var $temp$len = l;
				len = $temp$len;
				continue isConstrained;
			default:
				var l = len.b;
				return true;
		}
	}
};
var $mdgriffith$elm_ui$Element$Input$isHiddenLabel = function (label) {
	if (label.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $mdgriffith$elm_ui$Element$Input$isStacked = function (label) {
	if (!label.$) {
		var loc = label.a;
		switch (loc) {
			case 0:
				return false;
			case 1:
				return false;
			case 2:
				return true;
			default:
				return true;
		}
	} else {
		return true;
	}
};
var $mdgriffith$elm_ui$Element$Input$negateBox = function (box) {
	return {g1: -box.g1, h1: -box.h1, iN: -box.iN, jl: -box.jl};
};
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $mdgriffith$elm_ui$Internal$Model$paddingName = F4(
	function (top, right, bottom, left) {
		return 'pad-' + ($elm$core$String$fromInt(top) + ('-' + ($elm$core$String$fromInt(right) + ('-' + ($elm$core$String$fromInt(bottom) + ('-' + $elm$core$String$fromInt(left)))))));
	});
var $mdgriffith$elm_ui$Element$paddingEach = function (_v0) {
	var top = _v0.jl;
	var right = _v0.iN;
	var bottom = _v0.g1;
	var left = _v0.h1;
	if (_Utils_eq(top, right) && (_Utils_eq(top, bottom) && _Utils_eq(top, left))) {
		var topFloat = top;
		return A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$padding,
			A5(
				$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
				'p-' + $elm$core$String$fromInt(top),
				topFloat,
				topFloat,
				topFloat,
				topFloat));
	} else {
		return A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$padding,
			A5(
				$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
				A4($mdgriffith$elm_ui$Internal$Model$paddingName, top, right, bottom, left),
				top,
				right,
				bottom,
				left));
	}
};
var $mdgriffith$elm_ui$Element$Input$isFill = function (len) {
	isFill:
	while (true) {
		switch (len.$) {
			case 2:
				return true;
			case 1:
				return false;
			case 0:
				return false;
			case 3:
				var l = len.b;
				var $temp$len = l;
				len = $temp$len;
				continue isFill;
			default:
				var l = len.b;
				var $temp$len = l;
				len = $temp$len;
				continue isFill;
		}
	}
};
var $mdgriffith$elm_ui$Element$Input$isPixel = function (len) {
	isPixel:
	while (true) {
		switch (len.$) {
			case 1:
				return false;
			case 0:
				return true;
			case 2:
				return false;
			case 3:
				var l = len.b;
				var $temp$len = l;
				len = $temp$len;
				continue isPixel;
			default:
				var l = len.b;
				var $temp$len = l;
				len = $temp$len;
				continue isPixel;
		}
	}
};
var $mdgriffith$elm_ui$Internal$Model$paddingNameFloat = F4(
	function (top, right, bottom, left) {
		return 'pad-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(top) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(right) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(bottom) + ('-' + $mdgriffith$elm_ui$Internal$Model$floatClass(left)))))));
	});
var $mdgriffith$elm_ui$Element$Input$redistributeOver = F4(
	function (isMultiline, stacked, attr, els) {
		switch (attr.$) {
			case 9:
				return _Utils_update(
					els,
					{
						i: A2($elm$core$List$cons, attr, els.i)
					});
			case 7:
				var width = attr.a;
				return $mdgriffith$elm_ui$Element$Input$isFill(width) ? _Utils_update(
					els,
					{
						p: A2($elm$core$List$cons, attr, els.p),
						lT: A2($elm$core$List$cons, attr, els.lT),
						i: A2($elm$core$List$cons, attr, els.i)
					}) : (stacked ? _Utils_update(
					els,
					{
						p: A2($elm$core$List$cons, attr, els.p)
					}) : _Utils_update(
					els,
					{
						i: A2($elm$core$List$cons, attr, els.i)
					}));
			case 8:
				var height = attr.a;
				return (!stacked) ? _Utils_update(
					els,
					{
						p: A2($elm$core$List$cons, attr, els.p),
						i: A2($elm$core$List$cons, attr, els.i)
					}) : ($mdgriffith$elm_ui$Element$Input$isFill(height) ? _Utils_update(
					els,
					{
						p: A2($elm$core$List$cons, attr, els.p),
						i: A2($elm$core$List$cons, attr, els.i)
					}) : ($mdgriffith$elm_ui$Element$Input$isPixel(height) ? _Utils_update(
					els,
					{
						i: A2($elm$core$List$cons, attr, els.i)
					}) : _Utils_update(
					els,
					{
						i: A2($elm$core$List$cons, attr, els.i)
					})));
			case 6:
				return _Utils_update(
					els,
					{
						p: A2($elm$core$List$cons, attr, els.p)
					});
			case 5:
				return _Utils_update(
					els,
					{
						p: A2($elm$core$List$cons, attr, els.p)
					});
			case 4:
				switch (attr.b.$) {
					case 5:
						var _v1 = attr.b;
						return _Utils_update(
							els,
							{
								p: A2($elm$core$List$cons, attr, els.p),
								lT: A2($elm$core$List$cons, attr, els.lT),
								i: A2($elm$core$List$cons, attr, els.i),
								cK: A2($elm$core$List$cons, attr, els.cK)
							});
					case 7:
						var cls = attr.a;
						var _v2 = attr.b;
						var pad = _v2.a;
						var t = _v2.b;
						var r = _v2.c;
						var b = _v2.d;
						var l = _v2.e;
						if (isMultiline) {
							return _Utils_update(
								els,
								{
									ab: A2($elm$core$List$cons, attr, els.ab),
									i: A2($elm$core$List$cons, attr, els.i)
								});
						} else {
							var newTop = t - A2($elm$core$Basics$min, t, b);
							var newLineHeight = $mdgriffith$elm_ui$Element$htmlAttribute(
								A2(
									$elm$html$Html$Attributes$style,
									'line-height',
									'calc(1.0em + ' + ($elm$core$String$fromFloat(
										2 * A2($elm$core$Basics$min, t, b)) + 'px)')));
							var newHeight = $mdgriffith$elm_ui$Element$htmlAttribute(
								A2(
									$elm$html$Html$Attributes$style,
									'height',
									'calc(1.0em + ' + ($elm$core$String$fromFloat(
										2 * A2($elm$core$Basics$min, t, b)) + 'px)')));
							var newBottom = b - A2($elm$core$Basics$min, t, b);
							var reducedVerticalPadding = A2(
								$mdgriffith$elm_ui$Internal$Model$StyleClass,
								$mdgriffith$elm_ui$Internal$Flag$padding,
								A5(
									$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
									A4($mdgriffith$elm_ui$Internal$Model$paddingNameFloat, newTop, r, newBottom, l),
									newTop,
									r,
									newBottom,
									l));
							return _Utils_update(
								els,
								{
									ab: A2($elm$core$List$cons, attr, els.ab),
									lT: A2(
										$elm$core$List$cons,
										newHeight,
										A2($elm$core$List$cons, newLineHeight, els.lT)),
									i: A2($elm$core$List$cons, reducedVerticalPadding, els.i)
								});
						}
					case 6:
						var _v3 = attr.b;
						return _Utils_update(
							els,
							{
								ab: A2($elm$core$List$cons, attr, els.ab),
								i: A2($elm$core$List$cons, attr, els.i)
							});
					case 10:
						return _Utils_update(
							els,
							{
								ab: A2($elm$core$List$cons, attr, els.ab),
								i: A2($elm$core$List$cons, attr, els.i)
							});
					case 2:
						return _Utils_update(
							els,
							{
								p: A2($elm$core$List$cons, attr, els.p)
							});
					case 1:
						var _v4 = attr.b;
						return _Utils_update(
							els,
							{
								p: A2($elm$core$List$cons, attr, els.p)
							});
					default:
						var flag = attr.a;
						var cls = attr.b;
						return _Utils_update(
							els,
							{
								i: A2($elm$core$List$cons, attr, els.i)
							});
				}
			case 0:
				return els;
			case 1:
				var a = attr.a;
				return _Utils_update(
					els,
					{
						lT: A2($elm$core$List$cons, attr, els.lT)
					});
			case 2:
				return _Utils_update(
					els,
					{
						lT: A2($elm$core$List$cons, attr, els.lT)
					});
			case 3:
				return _Utils_update(
					els,
					{
						i: A2($elm$core$List$cons, attr, els.i)
					});
			default:
				return _Utils_update(
					els,
					{
						lT: A2($elm$core$List$cons, attr, els.lT)
					});
		}
	});
var $mdgriffith$elm_ui$Element$Input$redistribute = F3(
	function (isMultiline, stacked, attrs) {
		return function (redist) {
			return {
				ab: $elm$core$List$reverse(redist.ab),
				p: $elm$core$List$reverse(redist.p),
				lT: $elm$core$List$reverse(redist.lT),
				i: $elm$core$List$reverse(redist.i),
				cK: $elm$core$List$reverse(redist.cK)
			};
		}(
			A3(
				$elm$core$List$foldl,
				A2($mdgriffith$elm_ui$Element$Input$redistributeOver, isMultiline, stacked),
				{ab: _List_Nil, p: _List_Nil, lT: _List_Nil, i: _List_Nil, cK: _List_Nil},
				attrs));
	});
var $mdgriffith$elm_ui$Element$Input$renderBox = function (_v0) {
	var top = _v0.jl;
	var right = _v0.iN;
	var bottom = _v0.g1;
	var left = _v0.h1;
	return $elm$core$String$fromInt(top) + ('px ' + ($elm$core$String$fromInt(right) + ('px ' + ($elm$core$String$fromInt(bottom) + ('px ' + ($elm$core$String$fromInt(left) + 'px'))))));
};
var $mdgriffith$elm_ui$Internal$Model$Transparency = F2(
	function (a, b) {
		return {$: 12, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Flag$transparency = $mdgriffith$elm_ui$Internal$Flag$flag(0);
var $mdgriffith$elm_ui$Element$alpha = function (o) {
	var transparency = function (x) {
		return 1 - x;
	}(
		A2(
			$elm$core$Basics$min,
			1.0,
			A2($elm$core$Basics$max, 0.0, o)));
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$transparency,
		A2(
			$mdgriffith$elm_ui$Internal$Model$Transparency,
			'transparency-' + $mdgriffith$elm_ui$Internal$Model$floatClass(transparency),
			transparency));
};
var $mdgriffith$elm_ui$Element$Input$charcoal = A3($mdgriffith$elm_ui$Element$rgb, 136 / 255, 138 / 255, 133 / 255);
var $mdgriffith$elm_ui$Element$Input$renderPlaceholder = F3(
	function (_v0, forPlaceholder, on) {
		var placeholderAttrs = _v0.a;
		var placeholderEl = _v0.b;
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_Utils_ap(
				forPlaceholder,
				_Utils_ap(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$color($mdgriffith$elm_ui$Element$Input$charcoal),
							$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.ic + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.np)),
							$mdgriffith$elm_ui$Element$clip,
							$mdgriffith$elm_ui$Element$Border$color(
							A4($mdgriffith$elm_ui$Element$rgba, 0, 0, 0, 0)),
							$mdgriffith$elm_ui$Element$Background$color(
							A4($mdgriffith$elm_ui$Element$rgba, 0, 0, 0, 0)),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$alpha(
							on ? 1 : 0)
						]),
					placeholderAttrs)),
			placeholderEl);
	});
var $mdgriffith$elm_ui$Element$scrollbarY = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$overflow, $mdgriffith$elm_ui$Internal$Style$classes.nW);
var $elm$html$Html$span = _VirtualDom_node('span');
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$spellcheck = $elm$html$Html$Attributes$boolProperty('spellcheck');
var $mdgriffith$elm_ui$Element$Input$spellcheck = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Attr, $elm$html$Html$Attributes$spellcheck);
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $mdgriffith$elm_ui$Element$Input$value = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Attr, $elm$html$Html$Attributes$value);
var $mdgriffith$elm_ui$Element$Input$textHelper = F3(
	function (textInput, attrs, textOptions) {
		var withDefaults = _Utils_ap($mdgriffith$elm_ui$Element$Input$defaultTextBoxStyle, attrs);
		var redistributed = A3(
			$mdgriffith$elm_ui$Element$Input$redistribute,
			_Utils_eq(textInput.gz, $mdgriffith$elm_ui$Element$Input$TextArea),
			$mdgriffith$elm_ui$Element$Input$isStacked(textOptions.ma),
			withDefaults);
		var onlySpacing = function (attr) {
			if ((attr.$ === 4) && (attr.b.$ === 5)) {
				var _v9 = attr.b;
				return true;
			} else {
				return false;
			}
		};
		var heightConstrained = function () {
			var _v7 = textInput.gz;
			if (!_v7.$) {
				var inputType = _v7.a;
				return false;
			} else {
				return A2(
					$elm$core$Maybe$withDefault,
					false,
					A2(
						$elm$core$Maybe$map,
						$mdgriffith$elm_ui$Element$Input$isConstrained,
						$elm$core$List$head(
							$elm$core$List$reverse(
								A2($elm$core$List$filterMap, $mdgriffith$elm_ui$Element$Input$getHeight, withDefaults)))));
			}
		}();
		var getPadding = function (attr) {
			if ((attr.$ === 4) && (attr.b.$ === 7)) {
				var cls = attr.a;
				var _v6 = attr.b;
				var pad = _v6.a;
				var t = _v6.b;
				var r = _v6.c;
				var b = _v6.d;
				var l = _v6.e;
				return $elm$core$Maybe$Just(
					{
						g1: A2(
							$elm$core$Basics$max,
							0,
							$elm$core$Basics$floor(b - 3)),
						h1: A2(
							$elm$core$Basics$max,
							0,
							$elm$core$Basics$floor(l - 3)),
						iN: A2(
							$elm$core$Basics$max,
							0,
							$elm$core$Basics$floor(r - 3)),
						jl: A2(
							$elm$core$Basics$max,
							0,
							$elm$core$Basics$floor(t - 3))
					});
			} else {
				return $elm$core$Maybe$Nothing;
			}
		};
		var parentPadding = A2(
			$elm$core$Maybe$withDefault,
			{g1: 0, h1: 0, iN: 0, jl: 0},
			$elm$core$List$head(
				$elm$core$List$reverse(
					A2($elm$core$List$filterMap, getPadding, withDefaults))));
		var inputElement = A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asEl,
			function () {
				var _v3 = textInput.gz;
				if (!_v3.$) {
					var inputType = _v3.a;
					return $mdgriffith$elm_ui$Internal$Model$NodeName('input');
				} else {
					return $mdgriffith$elm_ui$Internal$Model$NodeName('textarea');
				}
			}(),
			_Utils_ap(
				function () {
					var _v4 = textInput.gz;
					if (!_v4.$) {
						var inputType = _v4.a;
						return _List_fromArray(
							[
								$mdgriffith$elm_ui$Internal$Model$Attr(
								$elm$html$Html$Attributes$type_(inputType)),
								$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.K)
							]);
					} else {
						return _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$clip,
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.lU),
								$mdgriffith$elm_ui$Element$Input$calcMoveToCompensateForPadding(withDefaults),
								$mdgriffith$elm_ui$Element$paddingEach(parentPadding),
								$mdgriffith$elm_ui$Internal$Model$Attr(
								A2(
									$elm$html$Html$Attributes$style,
									'margin',
									$mdgriffith$elm_ui$Element$Input$renderBox(
										$mdgriffith$elm_ui$Element$Input$negateBox(parentPadding)))),
								$mdgriffith$elm_ui$Internal$Model$Attr(
								A2($elm$html$Html$Attributes$style, 'box-sizing', 'content-box'))
							]);
					}
				}(),
				_Utils_ap(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Input$value(textOptions.bJ),
							$mdgriffith$elm_ui$Internal$Model$Attr(
							$elm$html$Html$Events$onInput(textOptions.mK)),
							$mdgriffith$elm_ui$Element$Input$hiddenLabelAttribute(textOptions.ma),
							$mdgriffith$elm_ui$Element$Input$spellcheck(textInput.aD),
							A2(
							$elm$core$Maybe$withDefault,
							$mdgriffith$elm_ui$Internal$Model$NoAttribute,
							A2($elm$core$Maybe$map, $mdgriffith$elm_ui$Element$Input$autofill, textInput.at))
						]),
					redistributed.lT)),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(_List_Nil));
		var wrappedInput = function () {
			var _v0 = textInput.gz;
			if (_v0.$ === 1) {
				return A4(
					$mdgriffith$elm_ui$Internal$Model$element,
					$mdgriffith$elm_ui$Internal$Model$asEl,
					$mdgriffith$elm_ui$Internal$Model$div,
					_Utils_ap(
						(heightConstrained ? $elm$core$List$cons($mdgriffith$elm_ui$Element$scrollbarY) : $elm$core$Basics$identity)(
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									A2($elm$core$List$any, $mdgriffith$elm_ui$Element$Input$hasFocusStyle, withDefaults) ? $mdgriffith$elm_ui$Internal$Model$NoAttribute : $mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.hu),
									$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.lX)
								])),
						redistributed.i),
					$mdgriffith$elm_ui$Internal$Model$Unkeyed(
						_List_fromArray(
							[
								A4(
								$mdgriffith$elm_ui$Internal$Model$element,
								$mdgriffith$elm_ui$Internal$Model$asParagraph,
								$mdgriffith$elm_ui$Internal$Model$div,
								A2(
									$elm$core$List$cons,
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									A2(
										$elm$core$List$cons,
										$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
										A2(
											$elm$core$List$cons,
											$mdgriffith$elm_ui$Element$inFront(inputElement),
											A2(
												$elm$core$List$cons,
												$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.lW),
												redistributed.cK)))),
								$mdgriffith$elm_ui$Internal$Model$Unkeyed(
									function () {
										if (textOptions.bJ === '') {
											var _v1 = textOptions.nt;
											if (_v1.$ === 1) {
												return _List_fromArray(
													[
														$mdgriffith$elm_ui$Element$text('\u00A0')
													]);
											} else {
												var place = _v1.a;
												return _List_fromArray(
													[
														A3($mdgriffith$elm_ui$Element$Input$renderPlaceholder, place, _List_Nil, textOptions.bJ === '')
													]);
											}
										} else {
											return _List_fromArray(
												[
													$mdgriffith$elm_ui$Internal$Model$unstyled(
													A2(
														$elm$html$Html$span,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class($mdgriffith$elm_ui$Internal$Style$classes.lV)
															]),
														_List_fromArray(
															[
																$elm$html$Html$text(textOptions.bJ + '\u00A0')
															])))
												]);
										}
									}()))
							])));
			} else {
				var inputType = _v0.a;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$element,
					$mdgriffith$elm_ui$Internal$Model$asEl,
					$mdgriffith$elm_ui$Internal$Model$div,
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						A2(
							$elm$core$List$cons,
							A2($elm$core$List$any, $mdgriffith$elm_ui$Element$Input$hasFocusStyle, withDefaults) ? $mdgriffith$elm_ui$Internal$Model$NoAttribute : $mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.hu),
							$elm$core$List$concat(
								_List_fromArray(
									[
										redistributed.i,
										function () {
										var _v2 = textOptions.nt;
										if (_v2.$ === 1) {
											return _List_Nil;
										} else {
											var place = _v2.a;
											return _List_fromArray(
												[
													$mdgriffith$elm_ui$Element$behindContent(
													A3($mdgriffith$elm_ui$Element$Input$renderPlaceholder, place, redistributed.ab, textOptions.bJ === ''))
												]);
										}
									}()
									])))),
					$mdgriffith$elm_ui$Internal$Model$Unkeyed(
						_List_fromArray(
							[inputElement])));
			}
		}();
		return A3(
			$mdgriffith$elm_ui$Element$Input$applyLabel,
			A2(
				$elm$core$List$cons,
				A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$cursor, $mdgriffith$elm_ui$Internal$Style$classes.kV),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$Input$isHiddenLabel(textOptions.ma) ? $mdgriffith$elm_ui$Internal$Model$NoAttribute : $mdgriffith$elm_ui$Element$spacing(5),
					A2($elm$core$List$cons, $mdgriffith$elm_ui$Element$Region$announce, redistributed.p))),
			textOptions.ma,
			wrappedInput);
	});
var $mdgriffith$elm_ui$Element$Input$currentPassword = F2(
	function (attrs, pass) {
		return A3(
			$mdgriffith$elm_ui$Element$Input$textHelper,
			{
				at: $elm$core$Maybe$Just('current-password'),
				aD: false,
				gz: $mdgriffith$elm_ui$Element$Input$TextInputNode(
					pass.i1 ? 'text' : 'password')
			},
			attrs,
			{ma: pass.ma, mK: pass.mK, nt: pass.nt, bJ: pass.bJ});
	});
var $mdgriffith$elm_ui$Element$Input$Above = 2;
var $mdgriffith$elm_ui$Element$Input$Label = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $mdgriffith$elm_ui$Element$Input$labelAbove = $mdgriffith$elm_ui$Element$Input$Label(2);
var $mdgriffith$elm_ui$Element$padding = function (x) {
	var f = x;
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$padding,
		A5(
			$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
			'p-' + $elm$core$String$fromInt(x),
			f,
			f,
			f,
			f));
};
var $author$project$Styles$password_input = F3(
	function (labelText, onChange, valueText) {
		return A2(
			$mdgriffith$elm_ui$Element$Input$currentPassword,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$padding(6),
					$mdgriffith$elm_ui$Element$centerY,
					$mdgriffith$elm_ui$Element$Background$color(
					$author$project$Colors$surface(1)),
					$mdgriffith$elm_ui$Element$Font$color(
					$author$project$Colors$onSurface(9)),
					$mdgriffith$elm_ui$Element$Border$width(0)
				]),
			{
				ma: A2(
					$mdgriffith$elm_ui$Element$Input$labelAbove,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$color(
							$author$project$Colors$onSurface(0))
						]),
					$mdgriffith$elm_ui$Element$text(labelText)),
				mK: function (value) {
					return onChange(value);
				},
				nt: $elm$core$Maybe$Nothing,
				i1: false,
				bJ: valueText
			});
	});
var $author$project$Components$Button$testId = F2(
	function (testIdText, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{
				e4: $elm$core$Maybe$Just(testIdText)
			});
	});
var $author$project$Components$Button$InvertedColors = $elm$core$Basics$identity;
var $author$project$Cursor$Progress = 25;
var $mdgriffith$elm_ui$Internal$Model$Right = 2;
var $mdgriffith$elm_ui$Element$alignRight = $mdgriffith$elm_ui$Internal$Model$AlignX(2);
var $author$project$Helpers$attributeIf = function (list) {
	return A2(
		$elm$core$List$filterMap,
		function (_v0) {
			var isTrue = _v0.a;
			var attribute = _v0.b;
			return isTrue ? $elm$core$Maybe$Just(attribute) : $elm$core$Maybe$Nothing;
		},
		list);
};
var $author$project$Helpers$attributeIfExists = function (list) {
	return A2(
		$elm$core$List$filterMap,
		function (_v0) {
			var maybeValue = _v0.a;
			var valueToAttribute = _v0.b;
			return A2($elm$core$Maybe$map, valueToAttribute, maybeValue);
		},
		list);
};
var $mdgriffith$elm_ui$Internal$Model$Hover = 1;
var $mdgriffith$elm_ui$Internal$Model$PseudoSelector = F2(
	function (a, b) {
		return {$: 11, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Flag$hover = $mdgriffith$elm_ui$Internal$Flag$flag(33);
var $elm$virtual_dom$VirtualDom$mapAttribute = _VirtualDom_mapAttribute;
var $mdgriffith$elm_ui$Internal$Model$mapAttrFromStyle = F2(
	function (fn, attr) {
		switch (attr.$) {
			case 0:
				return $mdgriffith$elm_ui$Internal$Model$NoAttribute;
			case 2:
				var description = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$Describe(description);
			case 6:
				var x = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$AlignX(x);
			case 5:
				var y = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$AlignY(y);
			case 7:
				var x = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$Width(x);
			case 8:
				var x = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$Height(x);
			case 3:
				var x = attr.a;
				var y = attr.b;
				return A2($mdgriffith$elm_ui$Internal$Model$Class, x, y);
			case 4:
				var flag = attr.a;
				var style = attr.b;
				return A2($mdgriffith$elm_ui$Internal$Model$StyleClass, flag, style);
			case 9:
				var location = attr.a;
				var elem = attr.b;
				return A2(
					$mdgriffith$elm_ui$Internal$Model$Nearby,
					location,
					A2($mdgriffith$elm_ui$Internal$Model$map, fn, elem));
			case 1:
				var htmlAttr = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$Attr(
					A2($elm$virtual_dom$VirtualDom$mapAttribute, fn, htmlAttr));
			default:
				var fl = attr.a;
				var trans = attr.b;
				return A2($mdgriffith$elm_ui$Internal$Model$TransformComponent, fl, trans);
		}
	});
var $mdgriffith$elm_ui$Internal$Model$removeNever = function (style) {
	return A2($mdgriffith$elm_ui$Internal$Model$mapAttrFromStyle, $elm$core$Basics$never, style);
};
var $mdgriffith$elm_ui$Internal$Model$unwrapDecsHelper = F2(
	function (attr, _v0) {
		var styles = _v0.a;
		var trans = _v0.b;
		var _v1 = $mdgriffith$elm_ui$Internal$Model$removeNever(attr);
		switch (_v1.$) {
			case 4:
				var style = _v1.b;
				return _Utils_Tuple2(
					A2($elm$core$List$cons, style, styles),
					trans);
			case 10:
				var flag = _v1.a;
				var component = _v1.b;
				return _Utils_Tuple2(
					styles,
					A2($mdgriffith$elm_ui$Internal$Model$composeTransformation, trans, component));
			default:
				return _Utils_Tuple2(styles, trans);
		}
	});
var $mdgriffith$elm_ui$Internal$Model$unwrapDecorations = function (attrs) {
	var _v0 = A3(
		$elm$core$List$foldl,
		$mdgriffith$elm_ui$Internal$Model$unwrapDecsHelper,
		_Utils_Tuple2(_List_Nil, $mdgriffith$elm_ui$Internal$Model$Untransformed),
		attrs);
	var styles = _v0.a;
	var transform = _v0.b;
	return A2(
		$elm$core$List$cons,
		$mdgriffith$elm_ui$Internal$Model$Transform(transform),
		styles);
};
var $mdgriffith$elm_ui$Element$mouseOver = function (decs) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$hover,
		A2(
			$mdgriffith$elm_ui$Internal$Model$PseudoSelector,
			1,
			$mdgriffith$elm_ui$Internal$Model$unwrapDecorations(decs)));
};
var $author$project$Components$Button$baseLookAttributes = function (_v0) {
	var isFocusable = _v0.bu;
	return _Utils_ap(
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$padding(5),
				$mdgriffith$elm_ui$Element$Border$rounded(6),
				$mdgriffith$elm_ui$Element$Font$color(
				$author$project$Colors$onSurface(6))
			]),
		$author$project$Helpers$attributeIf(
			_List_fromArray(
				[
					_Utils_Tuple2(
					isFocusable,
					$mdgriffith$elm_ui$Element$mouseOver(
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Background$color(
								$author$project$Colors$onSurface(0)),
								$mdgriffith$elm_ui$Element$Font$color(
								$author$project$Colors$onSurface(9))
							])))
				])));
};
var $mdgriffith$elm_ui$Internal$Model$Left = 0;
var $mdgriffith$elm_ui$Element$alignLeft = $mdgriffith$elm_ui$Internal$Model$AlignX(0);
var $mdgriffith$elm_ui$Element$row = F2(
	function (attrs, children) {
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asRow,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.cQ + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.a_)),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
						attrs))),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
	});
var $mdgriffith$elm_ui$Element$Font$size = function (i) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$fontSize,
		$mdgriffith$elm_ui$Internal$Model$FontSize(i));
};
var $author$project$Components$Button$buildLabel = function (options) {
	var textAttributes = _Utils_ap(
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Font$size(options.dC),
				function () {
				var _v6 = options.dB;
				if (!_v6) {
					return $mdgriffith$elm_ui$Element$centerX;
				} else {
					return $mdgriffith$elm_ui$Element$alignLeft;
				}
			}()
			]),
		$author$project$Helpers$attributeIfExists(
			_List_fromArray(
				[
					_Utils_Tuple2(options.dA, $mdgriffith$elm_ui$Element$Font$color)
				])));
	var textElement = function (string) {
		return (options.dV ? A2(
			$elm$core$Basics$composeR,
			$elm$core$List$singleton,
			$mdgriffith$elm_ui$Element$paragraph(
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$spacing(2),
					textAttributes))) : $mdgriffith$elm_ui$Element$el(textAttributes))(
			$mdgriffith$elm_ui$Element$text(string));
	};
	var iconAttributes = _Utils_ap(
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Element$paddingXY, 2, 0),
				$mdgriffith$elm_ui$Element$Font$size(options.c8)
			]),
		$author$project$Helpers$attributeIfExists(
			_List_fromArray(
				[
					_Utils_Tuple2(options.c7, $mdgriffith$elm_ui$Element$Font$color)
				])));
	var _v0 = _Utils_Tuple2(options.cc, options.bJ);
	if (!_v0.a.$) {
		if (!_v0.b.$) {
			var iconElem = _v0.a.a;
			var labelText = _v0.b.a;
			return A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$spacing(2)
					]),
				function () {
					var _v1 = options.eq;
					if (!_v1) {
						return _List_fromArray(
							[
								A2($mdgriffith$elm_ui$Element$el, iconAttributes, iconElem),
								textElement(labelText)
							]);
					} else {
						return _List_fromArray(
							[
								textElement(labelText),
								A2(
								$mdgriffith$elm_ui$Element$el,
								A2($elm$core$List$cons, $mdgriffith$elm_ui$Element$alignRight, iconAttributes),
								iconElem)
							]);
					}
				}());
		} else {
			var iconElem = _v0.a.a;
			var _v2 = _v0.b;
			return A2(
				$mdgriffith$elm_ui$Element$el,
				_Utils_ap(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$centerX
						]),
					iconAttributes),
				iconElem);
		}
	} else {
		if (!_v0.b.$) {
			var _v3 = _v0.a;
			var labelText = _v0.b.a;
			return textElement(labelText);
		} else {
			var _v4 = _v0.a;
			var _v5 = _v0.b;
			return $mdgriffith$elm_ui$Element$none;
		}
	}
};
var $mdgriffith$elm_ui$Internal$Model$Button = {$: 8};
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $mdgriffith$elm_ui$Element$Input$enter = 'Enter';
var $mdgriffith$elm_ui$Element$Input$focusDefault = function (attrs) {
	return A2($elm$core$List$any, $mdgriffith$elm_ui$Element$Input$hasFocusStyle, attrs) ? $mdgriffith$elm_ui$Internal$Model$NoAttribute : $mdgriffith$elm_ui$Internal$Model$htmlClass('focusable');
};
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $mdgriffith$elm_ui$Element$Events$onClick = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Attr, $elm$html$Html$Events$onClick);
var $elm$virtual_dom$VirtualDom$MayPreventDefault = function (a) {
	return {$: 2, a: a};
};
var $elm$html$Html$Events$preventDefaultOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayPreventDefault(decoder));
	});
var $mdgriffith$elm_ui$Element$Input$onKeyLookup = function (lookup) {
	var decode = function (code) {
		var _v0 = lookup(code);
		if (_v0.$ === 1) {
			return $elm$json$Json$Decode$fail('No key matched');
		} else {
			var msg = _v0.a;
			return $elm$json$Json$Decode$succeed(msg);
		}
	};
	var isKey = A2(
		$elm$json$Json$Decode$andThen,
		decode,
		A2($elm$json$Json$Decode$field, 'key', $elm$json$Json$Decode$string));
	return $mdgriffith$elm_ui$Internal$Model$Attr(
		A2(
			$elm$html$Html$Events$preventDefaultOn,
			'keydown',
			A2(
				$elm$json$Json$Decode$map,
				function (fired) {
					return _Utils_Tuple2(fired, true);
				},
				isKey)));
};
var $mdgriffith$elm_ui$Element$pointer = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$cursor, $mdgriffith$elm_ui$Internal$Style$classes.kU);
var $mdgriffith$elm_ui$Element$Input$space = ' ';
var $elm$html$Html$Attributes$tabindex = function (n) {
	return A2(
		_VirtualDom_attribute,
		'tabIndex',
		$elm$core$String$fromInt(n));
};
var $mdgriffith$elm_ui$Element$Input$button = F2(
	function (attrs, _v0) {
		var onPress = _v0.m2;
		var label = _v0.ma;
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asEl,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.d1 + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.a_ + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.nX + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.ic)))))),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Element$pointer,
							A2(
								$elm$core$List$cons,
								$mdgriffith$elm_ui$Element$Input$focusDefault(attrs),
								A2(
									$elm$core$List$cons,
									$mdgriffith$elm_ui$Internal$Model$Describe($mdgriffith$elm_ui$Internal$Model$Button),
									A2(
										$elm$core$List$cons,
										$mdgriffith$elm_ui$Internal$Model$Attr(
											$elm$html$Html$Attributes$tabindex(0)),
										function () {
											if (onPress.$ === 1) {
												return A2(
													$elm$core$List$cons,
													$mdgriffith$elm_ui$Internal$Model$Attr(
														$elm$html$Html$Attributes$disabled(true)),
													attrs);
											} else {
												var msg = onPress.a;
												return A2(
													$elm$core$List$cons,
													$mdgriffith$elm_ui$Element$Events$onClick(msg),
													A2(
														$elm$core$List$cons,
														$mdgriffith$elm_ui$Element$Input$onKeyLookup(
															function (code) {
																return _Utils_eq(code, $mdgriffith$elm_ui$Element$Input$enter) ? $elm$core$Maybe$Just(msg) : (_Utils_eq(code, $mdgriffith$elm_ui$Element$Input$space) ? $elm$core$Maybe$Just(msg) : $elm$core$Maybe$Nothing);
															}),
														attrs));
											}
										}()))))))),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(
				_List_fromArray(
					[label])));
	});
var $author$project$Cursor$cursorName = function (cursor_) {
	switch (cursor_) {
		case 0:
			return 'alias';
		case 1:
			return 'all-scroll';
		case 2:
			return 'auto';
		case 3:
			return 'cell';
		case 4:
			return 'context-menu';
		case 5:
			return 'col-resize';
		case 6:
			return 'copy';
		case 7:
			return 'crosshair';
		case 8:
			return 'default';
		case 9:
			return 'e-resize';
		case 10:
			return 'ew-resize';
		case 11:
			return 'grab';
		case 12:
			return 'grabbing';
		case 13:
			return 'help';
		case 14:
			return 'move';
		case 15:
			return 'n-resize';
		case 16:
			return 'ne-resize';
		case 17:
			return 'nesw-resize';
		case 18:
			return 'ns-resize';
		case 19:
			return 'nw-resize';
		case 20:
			return 'nwse-resize';
		case 21:
			return 'no-drop';
		case 22:
			return 'none';
		case 23:
			return 'not-allowed';
		case 24:
			return 'pointer';
		case 25:
			return 'progress';
		case 26:
			return 'row-resize';
		case 27:
			return 's-resize';
		case 28:
			return 'se-resize';
		case 29:
			return 'sw-resize';
		case 30:
			return 'text';
		case 31:
			return 'url';
		case 32:
			return 'w-resize';
		case 33:
			return 'wait';
		case 34:
			return 'zoom-in';
		default:
			return 'zoom-out';
	}
};
var $author$project$Cursor$cursor = function (cursor_) {
	return $mdgriffith$elm_ui$Element$htmlAttribute(
		A2(
			$elm$html$Html$Attributes$style,
			'cursor',
			$author$project$Cursor$cursorName(cursor_)));
};
var $author$project$Cursor$NotAllowed = 23;
var $mdgriffith$elm_ui$Internal$Model$Focus = 0;
var $mdgriffith$elm_ui$Internal$Flag$focus = $mdgriffith$elm_ui$Internal$Flag$flag(31);
var $mdgriffith$elm_ui$Element$focused = function (decs) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$focus,
		A2(
			$mdgriffith$elm_ui$Internal$Model$PseudoSelector,
			0,
			$mdgriffith$elm_ui$Internal$Model$unwrapDecorations(decs)));
};
var $author$project$Colors$none = A4($mdgriffith$elm_ui$Element$rgba, 0, 0, 0, 0);
var $author$project$Components$Button$disabledButtonAttributes = function (options) {
	return _Utils_ap(
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Border$color(
				$author$project$Colors$surface(6)),
				$author$project$Cursor$cursor(23),
				$mdgriffith$elm_ui$Element$focused(_List_Nil),
				$mdgriffith$elm_ui$Element$mouseOver(_List_Nil)
			]),
		function () {
			var _v0 = _Utils_Tuple2(options.bx, options.bJ);
			_v0$3:
			while (true) {
				if (_v0.b.$ === 1) {
					switch (_v0.a) {
						case 0:
							var _v1 = _v0.a;
							var _v2 = _v0.b;
							return _List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Background$color($author$project$Colors$none),
									$mdgriffith$elm_ui$Element$Font$color(
									$author$project$Colors$surface(3))
								]);
						case 2:
							var _v3 = _v0.a;
							var _v4 = _v0.b;
							return _List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Background$color($author$project$Colors$none),
									$mdgriffith$elm_ui$Element$Font$color(
									$author$project$Colors$surface(3))
								]);
						case 3:
							var _v5 = _v0.a;
							var _v6 = _v0.b;
							return _List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Background$color($author$project$Colors$none),
									$mdgriffith$elm_ui$Element$Font$color(
									$author$project$Colors$surface(3))
								]);
						default:
							break _v0$3;
					}
				} else {
					break _v0$3;
				}
			}
			return _List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Background$color(
					$author$project$Colors$surface(4)),
					$mdgriffith$elm_ui$Element$Font$color(
					$author$project$Colors$onSurface(6))
				]);
		}());
};
var $elm$html$Html$Events$keyCode = A2($elm$json$Json$Decode$field, 'keyCode', $elm$json$Json$Decode$int);
var $author$project$Components$Button$stopPropagationOnEnterKey = function (msg) {
	var enter = A2(
		$elm$json$Json$Decode$andThen,
		function (keyCode) {
			return (keyCode === 13) ? $elm$json$Json$Decode$succeed(
				_Utils_Tuple2(msg, true)) : $elm$json$Json$Decode$fail('not ENTER key');
		},
		$elm$html$Html$Events$keyCode);
	return $mdgriffith$elm_ui$Element$htmlAttribute(
		A2($elm$html$Html$Events$stopPropagationOn, 'keydown', enter));
};
var $author$project$Components$Button$handleEnterKey = function (onPress) {
	if (!onPress.$) {
		var pressMsg = onPress.a;
		return _List_fromArray(
			[
				$author$project$Components$Button$stopPropagationOnEnterKey(pressMsg)
			]);
	} else {
		return _List_Nil;
	}
};
var $elm$virtual_dom$VirtualDom$Custom = function (a) {
	return {$: 3, a: a};
};
var $elm$html$Html$Events$custom = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Custom(decoder));
	});
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$Event = F6(
	function (keys, button, clientPos, offsetPos, pagePos, screenPos) {
		return {b_: button, kA: clientPos, l9: keys, mI: offsetPos, nn: pagePos, nR: screenPos};
	});
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$BackButton = 4;
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$ErrorButton = 0;
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$ForwardButton = 5;
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$MainButton = 1;
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$MiddleButton = 2;
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$SecondButton = 3;
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$buttonFromId = function (id) {
	switch (id) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		default:
			return 0;
	}
};
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$buttonDecoder = A2(
	$elm$json$Json$Decode$map,
	$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$buttonFromId,
	A2($elm$json$Json$Decode$field, 'button', $elm$json$Json$Decode$int));
var $mpizenberg$elm_pointer_events$Internal$Decode$clientPos = A3(
	$elm$json$Json$Decode$map2,
	F2(
		function (a, b) {
			return _Utils_Tuple2(a, b);
		}),
	A2($elm$json$Json$Decode$field, 'clientX', $elm$json$Json$Decode$float),
	A2($elm$json$Json$Decode$field, 'clientY', $elm$json$Json$Decode$float));
var $mpizenberg$elm_pointer_events$Internal$Decode$Keys = F3(
	function (alt, ctrl, shift) {
		return {jY: alt, kS: ctrl, n4: shift};
	});
var $mpizenberg$elm_pointer_events$Internal$Decode$keys = A4(
	$elm$json$Json$Decode$map3,
	$mpizenberg$elm_pointer_events$Internal$Decode$Keys,
	A2($elm$json$Json$Decode$field, 'altKey', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 'ctrlKey', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 'shiftKey', $elm$json$Json$Decode$bool));
var $elm$json$Json$Decode$map6 = _Json_map6;
var $mpizenberg$elm_pointer_events$Internal$Decode$offsetPos = A3(
	$elm$json$Json$Decode$map2,
	F2(
		function (a, b) {
			return _Utils_Tuple2(a, b);
		}),
	A2($elm$json$Json$Decode$field, 'offsetX', $elm$json$Json$Decode$float),
	A2($elm$json$Json$Decode$field, 'offsetY', $elm$json$Json$Decode$float));
var $mpizenberg$elm_pointer_events$Internal$Decode$pagePos = A3(
	$elm$json$Json$Decode$map2,
	F2(
		function (a, b) {
			return _Utils_Tuple2(a, b);
		}),
	A2($elm$json$Json$Decode$field, 'pageX', $elm$json$Json$Decode$float),
	A2($elm$json$Json$Decode$field, 'pageY', $elm$json$Json$Decode$float));
var $mpizenberg$elm_pointer_events$Internal$Decode$screenPos = A3(
	$elm$json$Json$Decode$map2,
	F2(
		function (a, b) {
			return _Utils_Tuple2(a, b);
		}),
	A2($elm$json$Json$Decode$field, 'screenX', $elm$json$Json$Decode$float),
	A2($elm$json$Json$Decode$field, 'screenY', $elm$json$Json$Decode$float));
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$eventDecoder = A7($elm$json$Json$Decode$map6, $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$Event, $mpizenberg$elm_pointer_events$Internal$Decode$keys, $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$buttonDecoder, $mpizenberg$elm_pointer_events$Internal$Decode$clientPos, $mpizenberg$elm_pointer_events$Internal$Decode$offsetPos, $mpizenberg$elm_pointer_events$Internal$Decode$pagePos, $mpizenberg$elm_pointer_events$Internal$Decode$screenPos);
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onWithOptions = F3(
	function (event, options, tag) {
		return A2(
			$elm$html$Html$Events$custom,
			event,
			A2(
				$elm$json$Json$Decode$map,
				function (ev) {
					return {
						mp: tag(ev),
						iu: options.iu,
						ja: options.ja
					};
				},
				$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$eventDecoder));
	});
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$Event = F4(
	function (keys, changedTouches, targetTouches, touches) {
		return {ku: changedTouches, l9: keys, oo: targetTouches, oN: touches};
	});
var $elm$json$Json$Decode$map4 = _Json_map4;
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$Touch = F4(
	function (identifier, clientPos, pagePos, screenPos) {
		return {kA: clientPos, lN: identifier, nn: pagePos, nR: screenPos};
	});
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$touchDecoder = A5(
	$elm$json$Json$Decode$map4,
	$mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$Touch,
	A2($elm$json$Json$Decode$field, 'identifier', $elm$json$Json$Decode$int),
	$mpizenberg$elm_pointer_events$Internal$Decode$clientPos,
	$mpizenberg$elm_pointer_events$Internal$Decode$pagePos,
	$mpizenberg$elm_pointer_events$Internal$Decode$screenPos);
var $mpizenberg$elm_pointer_events$Internal$Decode$all = A2(
	$elm$core$List$foldr,
	$elm$json$Json$Decode$map2($elm$core$List$cons),
	$elm$json$Json$Decode$succeed(_List_Nil));
var $mpizenberg$elm_pointer_events$Internal$Decode$dynamicListOf = function (itemDecoder) {
	var decodeOne = function (n) {
		return A2(
			$elm$json$Json$Decode$field,
			$elm$core$String$fromInt(n),
			itemDecoder);
	};
	var decodeN = function (n) {
		return $mpizenberg$elm_pointer_events$Internal$Decode$all(
			A2(
				$elm$core$List$map,
				decodeOne,
				A2($elm$core$List$range, 0, n - 1)));
	};
	return A2(
		$elm$json$Json$Decode$andThen,
		decodeN,
		A2($elm$json$Json$Decode$field, 'length', $elm$json$Json$Decode$int));
};
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$touchListDecoder = $mpizenberg$elm_pointer_events$Internal$Decode$dynamicListOf;
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$eventDecoder = A5(
	$elm$json$Json$Decode$map4,
	$mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$Event,
	$mpizenberg$elm_pointer_events$Internal$Decode$keys,
	A2(
		$elm$json$Json$Decode$field,
		'changedTouches',
		$mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$touchListDecoder($mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$touchDecoder)),
	A2(
		$elm$json$Json$Decode$field,
		'targetTouches',
		$mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$touchListDecoder($mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$touchDecoder)),
	A2(
		$elm$json$Json$Decode$field,
		'touches',
		$mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$touchListDecoder($mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$touchDecoder)));
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$onWithOptions = F3(
	function (event, options, tag) {
		return A2(
			$elm$html$Html$Events$custom,
			event,
			A2(
				$elm$json$Json$Decode$map,
				function (ev) {
					return {
						mp: tag(ev),
						iu: options.iu,
						ja: options.ja
					};
				},
				$mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$eventDecoder));
	});
var $author$project$Components$Button$handlePointerClick = function (onPress) {
	if (!onPress.$) {
		var pressMsg = onPress.a;
		return A2(
			$elm$core$List$map,
			$mdgriffith$elm_ui$Element$htmlAttribute,
			_List_fromArray(
				[
					A3(
					$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onWithOptions,
					'click',
					{iu: true, ja: true},
					function (_v1) {
						return pressMsg;
					}),
					A3(
					$mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$onWithOptions,
					'end',
					{iu: true, ja: true},
					function (_v2) {
						return pressMsg;
					})
				]));
	} else {
		return _List_Nil;
	}
};
var $author$project$Helpers$htmlAttributeIfExists = function (maybeAttributes) {
	return A2(
		$elm$core$List$filterMap,
		function (_v0) {
			var maybeAttributeValue = _v0.a;
			var attributeName = _v0.b;
			return A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					$elm$html$Html$Attributes$attribute(attributeName),
					$mdgriffith$elm_ui$Element$htmlAttribute),
				maybeAttributeValue);
		},
		maybeAttributes);
};
var $author$project$Components$Button$iconOnly = function (options) {
	return (!_Utils_eq(options.cc, $elm$core$Maybe$Nothing)) && _Utils_eq(options.bJ, $elm$core$Maybe$Nothing);
};
var $author$project$Helpers$msgIfExists = function (list) {
	return A2(
		$elm$core$List$filterMap,
		function (_v0) {
			var maybeMsg = _v0.a;
			var msgToAttribute = _v0.b;
			return A2($elm$core$Maybe$map, msgToAttribute, maybeMsg);
		},
		list);
};
var $elm$html$Html$Events$onFocus = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'focus',
		$elm$json$Json$Decode$succeed(msg));
};
var $mdgriffith$elm_ui$Element$Events$onFocus = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Attr, $elm$html$Html$Events$onFocus);
var $elm$html$Html$Events$onBlur = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'blur',
		$elm$json$Json$Decode$succeed(msg));
};
var $mdgriffith$elm_ui$Element$Events$onLoseFocus = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Attr, $elm$html$Html$Events$onBlur);
var $author$project$Colors$onPrimary = function (levelNo) {
	return A2(
		$author$project$Colors$level,
		levelNo,
		A3($mdgriffith$elm_ui$Element$rgb, 0.9, 0.9, 0.9));
};
var $mdgriffith$elm_ui$Internal$Model$boxShadowClass = function (shadow) {
	return $elm$core$String$concat(
		_List_fromArray(
			[
				shadow.hU ? 'box-inset' : 'box-',
				$mdgriffith$elm_ui$Internal$Model$floatClass(shadow.f1.a) + 'px',
				$mdgriffith$elm_ui$Internal$Model$floatClass(shadow.f1.b) + 'px',
				$mdgriffith$elm_ui$Internal$Model$floatClass(shadow.fp) + 'px',
				$mdgriffith$elm_ui$Internal$Model$floatClass(shadow.gl) + 'px',
				$mdgriffith$elm_ui$Internal$Model$formatColorClass(shadow.fu)
			]));
};
var $mdgriffith$elm_ui$Internal$Flag$shadows = $mdgriffith$elm_ui$Internal$Flag$flag(19);
var $mdgriffith$elm_ui$Element$Border$shadow = function (almostShade) {
	var shade = {fp: almostShade.fp, fu: almostShade.fu, hU: false, f1: almostShade.f1, gl: almostShade.gl};
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$shadows,
		A3(
			$mdgriffith$elm_ui$Internal$Model$Single,
			$mdgriffith$elm_ui$Internal$Model$boxShadowClass(shade),
			'box-shadow',
			$mdgriffith$elm_ui$Internal$Model$formatBoxShadow(shade)));
};
var $author$project$Components$Button$primaryLookAttributes = F2(
	function (_v0, _v1) {
		var isFocusable = _v0.bu;
		var invertedColors = _v1;
		return A2(
			$elm$core$List$cons,
			$mdgriffith$elm_ui$Element$Border$color(
				$author$project$Colors$primary(2)),
			_Utils_ap(
				invertedColors ? _List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Font$color(
						$author$project$Colors$primary(0))
					]) : _List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Font$color(
						$author$project$Colors$onPrimary(0)),
						$mdgriffith$elm_ui$Element$Background$color(
						$author$project$Colors$primary(0)),
						$mdgriffith$elm_ui$Element$focused(
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Background$color(
								$author$project$Colors$primary(3)),
								$mdgriffith$elm_ui$Element$Border$color(
								$author$project$Colors$primary(7)),
								$mdgriffith$elm_ui$Element$Border$shadow(
								{
									fp: 0,
									fu: $author$project$Colors$primary(7),
									f1: _Utils_Tuple2(0, 0),
									gl: 1
								})
							]))
					]),
				$author$project$Helpers$attributeIf(
					_List_fromArray(
						[
							_Utils_Tuple2(
							isFocusable,
							$mdgriffith$elm_ui$Element$mouseOver(
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Background$color(
										$author$project$Colors$primary(3)),
										$mdgriffith$elm_ui$Element$Font$color(
										$author$project$Colors$onPrimary(2))
									])))
						]))));
	});
var $author$project$Colors$onSecondary = function (levelNo) {
	return A2(
		$author$project$Colors$level,
		levelNo,
		A3($mdgriffith$elm_ui$Element$rgb, 0, 0, 0));
};
var $smucode$elm_flat_colors$FlatColors$DutchPalette$merchantMarineBlue = A3($mdgriffith$elm_ui$Element$rgb255, 6, 82, 221);
var $author$project$Colors$secondary = function (levelNo) {
	return A2($author$project$Colors$level, levelNo, $smucode$elm_flat_colors$FlatColors$DutchPalette$merchantMarineBlue);
};
var $author$project$Components$Button$secondaryLookAttributes = F2(
	function (_v0, _v1) {
		var isFocusable = _v0.bu;
		var invertedColors = _v1;
		return invertedColors ? A2(
			$elm$core$List$cons,
			$mdgriffith$elm_ui$Element$Font$color(
				$author$project$Colors$secondary(0)),
			$author$project$Helpers$attributeIf(
				_List_fromArray(
					[
						_Utils_Tuple2(
						isFocusable,
						$mdgriffith$elm_ui$Element$mouseOver(
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Background$color(
									$author$project$Colors$secondary(0)),
									$mdgriffith$elm_ui$Element$Font$color(
									$author$project$Colors$surface(0))
								])))
					]))) : _Utils_ap(
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Font$color(
					$author$project$Colors$onSecondary(0)),
					$mdgriffith$elm_ui$Element$Background$color(
					$author$project$Colors$secondary(0)),
					$mdgriffith$elm_ui$Element$Border$color(
					$author$project$Colors$secondary(3)),
					$mdgriffith$elm_ui$Element$Border$width(1),
					$mdgriffith$elm_ui$Element$focused(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Background$color(
							$author$project$Colors$secondary(3)),
							$mdgriffith$elm_ui$Element$Border$color(
							$author$project$Colors$secondary(7)),
							$mdgriffith$elm_ui$Element$Border$shadow(
							{
								fp: 0,
								fu: $author$project$Colors$secondary(7),
								f1: _Utils_Tuple2(0, 0),
								gl: 1
							})
						]))
				]),
			$author$project$Helpers$attributeIf(
				_List_fromArray(
					[
						_Utils_Tuple2(
						isFocusable,
						$mdgriffith$elm_ui$Element$mouseOver(
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Background$color(
									$author$project$Colors$secondary(2))
								])))
					])));
	});
var $author$project$Components$Button$toggleButtonAttributes = F2(
	function (_v0, isSelected) {
		var isFocusable = _v0.bu;
		return A2(
			$elm$core$List$cons,
			$mdgriffith$elm_ui$Element$Border$width(1),
			isSelected ? _List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Font$color(
					$author$project$Colors$onSurface(8))
				]) : _Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Background$color(
						$author$project$Colors$surface(2)),
						$mdgriffith$elm_ui$Element$Border$color(
						$author$project$Colors$surface(5)),
						$mdgriffith$elm_ui$Element$Font$color(
						$author$project$Colors$surface(7))
					]),
				$author$project$Helpers$attributeIf(
					_List_fromArray(
						[
							_Utils_Tuple2(
							isFocusable,
							$mdgriffith$elm_ui$Element$mouseOver(
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Background$color(
										$author$project$Colors$surface(0)),
										$mdgriffith$elm_ui$Element$Border$color(
										$author$project$Colors$onSurface(7)),
										$mdgriffith$elm_ui$Element$Font$color(
										$author$project$Colors$onSurface(7))
									]))),
							_Utils_Tuple2(
							isFocusable,
							$mdgriffith$elm_ui$Element$focused(
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Border$color(
										$author$project$Colors$primary(2)),
										$mdgriffith$elm_ui$Element$Border$shadow(
										{
											fp: 0,
											fu: $author$project$Colors$primary(2),
											f1: _Utils_Tuple2(0, 0),
											gl: 1
										})
									])))
						]))));
	});
var $smucode$elm_flat_colors$FlatColors$DutchPalette$redPigment = A3($mdgriffith$elm_ui$Element$rgb255, 234, 32, 39);
var $author$project$Colors$warning = $smucode$elm_flat_colors$FlatColors$DutchPalette$redPigment;
var $author$project$Styles$xsFontSize = $mdgriffith$elm_ui$Element$Font$size($author$project$Styles$fontSizesInPixels.jB);
var $author$project$Components$Button$toElement = function (_v0) {
	var options = _v0;
	var onPress = function () {
		var _v16 = _Utils_Tuple2(options.cw, options.c9);
		switch (_v16.a.$) {
			case 0:
				if (!_v16.b) {
					var _v17 = _v16.a;
					return options.cj;
				} else {
					var _v18 = _v16.a;
					return $elm$core$Maybe$Nothing;
				}
			case 1:
				var _v19 = _v16.a;
				return $elm$core$Maybe$Nothing;
			default:
				if (!_v16.b) {
					return options.cj;
				} else {
					return $elm$core$Maybe$Nothing;
				}
		}
	}();
	var lookAttributes = function () {
		var _v2 = _Utils_Tuple2(options.cw, options.bx);
		switch (_v2.a.$) {
			case 0:
				switch (_v2.b) {
					case 0:
						var _v3 = _v2.a;
						var _v4 = _v2.b;
						return _List_Nil;
					case 1:
						var _v5 = _v2.a;
						var _v6 = _v2.b;
						return A2(
							$author$project$Components$Button$primaryLookAttributes,
							options,
							$author$project$Components$Button$iconOnly(options) ? true : false);
					case 2:
						var _v7 = _v2.a;
						var _v8 = _v2.b;
						return A2($author$project$Components$Button$secondaryLookAttributes, options, true);
					default:
						var _v9 = _v2.a;
						var _v10 = _v2.b;
						return _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Font$color($author$project$Colors$warning)
							]);
				}
			case 1:
				var _v11 = _v2.a;
				return $author$project$Components$Button$disabledButtonAttributes(options);
			default:
				switch (_v2.b) {
					case 0:
						var isSelected = _v2.a.a;
						var _v12 = _v2.b;
						return A2($author$project$Components$Button$toggleButtonAttributes, options, isSelected);
					case 1:
						var isSelected = _v2.a.a;
						var _v13 = _v2.b;
						return _Utils_ap(
							A2($author$project$Components$Button$primaryLookAttributes, options, false),
							A2($author$project$Components$Button$toggleButtonAttributes, options, isSelected));
					case 2:
						var isSelected = _v2.a.a;
						var _v14 = _v2.b;
						return _Utils_ap(
							A2($author$project$Components$Button$secondaryLookAttributes, options, false),
							A2($author$project$Components$Button$toggleButtonAttributes, options, isSelected));
					default:
						var isSelected = _v2.a.a;
						var _v15 = _v2.b;
						return _Utils_ap(
							A2($author$project$Components$Button$toggleButtonAttributes, options, isSelected),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$color($author$project$Colors$warning)
								]));
				}
		}
	}();
	var buttonHint = function () {
		var _v1 = options.eo;
		if (!_v1.$) {
			var hintText = _v1.a;
			return A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[$author$project$Styles$xsFontSize]),
				$mdgriffith$elm_ui$Element$text(hintText));
		} else {
			return $mdgriffith$elm_ui$Element$none;
		}
	}();
	var buttonElem = A2(
		$mdgriffith$elm_ui$Element$Input$button,
		_Utils_ap(
			$author$project$Components$Button$baseLookAttributes(options),
			_Utils_ap(
				lookAttributes,
				_Utils_ap(
					options.b3,
					_Utils_ap(
						$author$project$Helpers$attributeIf(
							_List_fromArray(
								[
									_Utils_Tuple2(
									options.c9,
									$author$project$Cursor$cursor(25))
								])),
						_Utils_ap(
							$author$project$Helpers$attributeIfExists(
								_List_fromArray(
									[
										_Utils_Tuple2(options.en, $mdgriffith$elm_ui$Element$height),
										_Utils_Tuple2(options.fg, $mdgriffith$elm_ui$Element$width)
									])),
							_Utils_ap(
								$author$project$Helpers$attributeIfExists(
									_List_fromArray(
										[
											_Utils_Tuple2(
											options.e0,
											A2($elm$core$Basics$composeR, $elm$html$Html$Attributes$tabindex, $mdgriffith$elm_ui$Element$htmlAttribute))
										])),
								_Utils_ap(
									$author$project$Helpers$msgIfExists(
										_List_fromArray(
											[
												_Utils_Tuple2(options.eF, $mdgriffith$elm_ui$Element$Events$onFocus),
												_Utils_Tuple2(options.eH, $mdgriffith$elm_ui$Element$Events$onLoseFocus)
											])),
									_Utils_ap(
										$author$project$Helpers$htmlAttributeIfExists(
											_List_fromArray(
												[
													_Utils_Tuple2(options.er, 'id'),
													_Utils_Tuple2(options.e4, 'data-test-id'),
													_Utils_Tuple2(options.e9, 'title')
												])),
										_Utils_ap(
											$author$project$Components$Button$handleEnterKey(onPress),
											$author$project$Components$Button$handlePointerClick(onPress)))))))))),
		{
			ma: $author$project$Components$Button$buildLabel(options),
			m2: $elm$core$Maybe$Nothing
		});
	return A2(
		$mdgriffith$elm_ui$Element$column,
		A2(
			$elm$core$List$cons,
			$mdgriffith$elm_ui$Element$spacing(2),
			$author$project$Helpers$attributeIfExists(
				_List_fromArray(
					[
						_Utils_Tuple2(options.en, $mdgriffith$elm_ui$Element$height),
						_Utils_Tuple2(options.fg, $mdgriffith$elm_ui$Element$width)
					]))),
		_List_fromArray(
			[
				buttonElem,
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$alignRight]),
				buttonHint)
			]));
};
var $mdgriffith$elm_ui$Element$Input$username = $mdgriffith$elm_ui$Element$Input$textHelper(
	{
		at: $elm$core$Maybe$Just('username'),
		aD: false,
		gz: $mdgriffith$elm_ui$Element$Input$TextInputNode('text')
	});
var $author$project$Styles$username_input = F3(
	function (labelText, onChange, valueText) {
		return A2(
			$mdgriffith$elm_ui$Element$Input$username,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$padding(6),
					$mdgriffith$elm_ui$Element$centerY,
					$mdgriffith$elm_ui$Element$Background$color(
					$author$project$Colors$surface(1)),
					$mdgriffith$elm_ui$Element$Font$color(
					$author$project$Colors$onSurface(9)),
					$mdgriffith$elm_ui$Element$Border$width(0)
				]),
			{
				ma: A2(
					$mdgriffith$elm_ui$Element$Input$labelAbove,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$color(
							$author$project$Colors$onSurface(0))
						]),
					$mdgriffith$elm_ui$Element$text(labelText)),
				mK: function (value) {
					return onChange(value);
				},
				nt: $elm$core$Maybe$Nothing,
				bJ: valueText
			});
	});
var $author$project$Pages$Login$view = function (model) {
	var logo = A2(
		$mdgriffith$elm_ui$Element$image,
		_List_fromArray(
			[$mdgriffith$elm_ui$Element$centerX]),
		{le: 'AutoDent logo', oc: 'TekstiLogo.svg'});
	var errorMessage = function () {
		var _v1 = model.dd;
		if (!_v1.$) {
			var errorText = _v1.a;
			return A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$Font$color($author$project$Colors$warning)
					]),
				$mdgriffith$elm_ui$Element$text(errorText));
		} else {
			return $mdgriffith$elm_ui$Element$none;
		}
	}();
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$centerX,
				$mdgriffith$elm_ui$Element$centerY,
				$mdgriffith$elm_ui$Element$spacing(20)
			]),
		A2(
			$elm$core$List$cons,
			logo,
			function () {
				var _v0 = model._;
				if (!_v0) {
					return _List_fromArray(
						[$mdgriffith$elm_ui$Element$none]);
				} else {
					return _List_fromArray(
						[
							A3($author$project$Styles$username_input, 'Käyttäjä', $author$project$Pages$Login$ChangeUsername, model.cG),
							A3($author$project$Styles$password_input, 'Salasana', $author$project$Pages$Login$ChangePassword, model.cl),
							errorMessage,
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerX]),
							$author$project$Components$Button$toElement(
								A2(
									$author$project$Components$Button$testId,
									'login-button',
									$author$project$Components$Button$primary(
										A2(
											$author$project$Components$Button$text,
											'KIRJAUDU',
											A2($author$project$Components$Button$onClick, $author$project$Pages$Login$LoginButtonClicked, $author$project$Components$Button$button))))))
						]);
				}
			}()));
};
var $author$project$Main$view_Authenticating = function (_v0) {
	var loginPageModel = _v0.di;
	var loginPage = A2(
		$mdgriffith$elm_ui$Element$map,
		$author$project$Main$GotLoginPageMsg,
		$author$project$Pages$Login$view(loginPageModel));
	return loginPage;
};
var $author$project$Main$view_Initializing = function (_v0) {
	return $mdgriffith$elm_ui$Element$none;
};
var $author$project$Components$TopMenu$UserMenu$ShowUserNavigator = {$: 3};
var $author$project$Types$User$canSwitchUser = function (user) {
	switch (user.$) {
		case 0:
			return true;
		case 2:
			return true;
		case 1:
			return true;
		default:
			var siteUser = user.a;
			switch (siteUser.$) {
				case 2:
					return false;
				case 0:
					return false;
				default:
					return false;
			}
	}
};
var $author$project$Components$Button$IconRight = 1;
var $author$project$Components$Button$iconRight = F2(
	function (iconElem, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{
				cc: $elm$core$Maybe$Just(iconElem),
				eq: 1
			});
	});
var $author$project$Components$TopMenu$UserMenu$isAnotherUserSelected = F2(
	function (selectedUser, _v0) {
		var loggedInUser = _v0.h4;
		return !_Utils_eq(selectedUser, loggedInUser);
	});
var $author$project$Components$TopMenu$UserMenu$OpenMenu = 0;
var $author$project$Icons$ellipsis_v = $author$project$Icons$fa_icon('fas fa-ellipsis-v');
var $author$project$Components$Button$id = F2(
	function (buttonId, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{
				er: $elm$core$Maybe$Just(buttonId)
			});
	});
var $author$project$Styles$xlFontSize = $mdgriffith$elm_ui$Element$Font$size($author$project$Styles$fontSizesInPixels.o6);
var $author$project$Components$TopMenu$UserMenu$menuToggleButton = F2(
	function (user, onClick) {
		return A2(
			$author$project$Components$Button$id,
			$author$project$Components$TopMenu$UserMenu$menuButtonId,
			A2(
				$author$project$Components$Button$iconRight,
				A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$author$project$Styles$xlFontSize]),
					$author$project$Icons$ellipsis_v),
				A2(
					$author$project$Components$Button$text,
					$author$project$Types$User$fullName(user),
					A2($author$project$Components$Button$onClick, onClick, $author$project$Components$Button$button))));
	});
var $author$project$Components$TopMenu$UserMenu$view_MenuClosed = function (selectedUser) {
	return A2(
		$mdgriffith$elm_ui$Element$map,
		$author$project$Components$TopMenu$UserMenu$Msg_MenuClosed,
		$author$project$Components$Button$toElement(
			A2($author$project$Components$TopMenu$UserMenu$menuToggleButton, selectedUser, 0)));
};
var $author$project$Components$TopMenu$UserMenu$CloseMenu = {$: 0};
var $author$project$Components$TopMenu$UserMenu$Logout = {$: 4};
var $author$project$Components$TopMenu$UserMenu$MenuItemGotFocus = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$TopMenu$UserMenu$OpenPwdDialog = {$: 3};
var $author$project$Components$TopMenu$UserMenu$ShowUserNavigator_MenuOpen = {$: 2};
var $author$project$KeyboardHelper$ArrowDownKey = 0;
var $author$project$KeyboardHelper$ArrowLeftKey = 1;
var $author$project$KeyboardHelper$ArrowRightKey = 2;
var $author$project$KeyboardHelper$ArrowUpKey = 3;
var $author$project$KeyboardHelper$BackspaceKey = 4;
var $author$project$KeyboardHelper$EnterKey = 5;
var $author$project$KeyboardHelper$EscKey = 6;
var $author$project$KeyboardHelper$OtherKey = 7;
var $author$project$KeyboardHelper$TabKey = 8;
var $author$project$KeyboardHelper$codeToKey = function (keyCode) {
	switch (keyCode) {
		case 8:
			return 4;
		case 9:
			return 8;
		case 13:
			return 5;
		case 27:
			return 6;
		case 37:
			return 1;
		case 38:
			return 3;
		case 39:
			return 2;
		case 40:
			return 0;
		default:
			return 7;
	}
};
var $author$project$KeyboardHelper$htmlEventToKey = function (handler) {
	return A2(
		$elm$json$Json$Decode$map,
		handler,
		A2($elm$json$Json$Decode$map, $author$project$KeyboardHelper$codeToKey, $elm$html$Html$Events$keyCode));
};
var $author$project$Components$TopMenu$UserMenu$lastMenuItemId = 'last-menu-item';
var $author$project$Components$TopMenu$UserMenu$handleKeyDown_MenuOpen = function (focusedMenuItemId) {
	return A2(
		$elm$html$Html$Events$custom,
		'keydown',
		$author$project$KeyboardHelper$htmlEventToKey(
			function (key) {
				var _v0 = function () {
					var _v1 = _Utils_Tuple2(key, focusedMenuItemId);
					_v1$2:
					while (true) {
						switch (_v1.a) {
							case 6:
								var _v2 = _v1.a;
								return _Utils_Tuple2($author$project$Components$TopMenu$UserMenu$CloseMenu, true);
							case 8:
								if (!_v1.b.$) {
									var _v3 = _v1.a;
									var focusedId = _v1.b.a;
									return _Utils_eq(focusedId, $author$project$Components$TopMenu$UserMenu$lastMenuItemId) ? _Utils_Tuple2($author$project$Components$TopMenu$UserMenu$CloseMenu, true) : _Utils_Tuple2($author$project$Components$TopMenu$UserMenu$NoOp_MenuOpen, false);
								} else {
									break _v1$2;
								}
							default:
								break _v1$2;
						}
					}
					return _Utils_Tuple2($author$project$Components$TopMenu$UserMenu$NoOp_MenuOpen, false);
				}();
				var message = _v0.a;
				var doPrevent = _v0.b;
				return {mp: message, iu: doPrevent, ja: doPrevent};
			}));
};
var $author$project$Icons$key = $author$project$Icons$fa_icon('fas fa-key');
var $author$project$Icons$logout = $author$project$Icons$fa_icon('fas fa-sign-out-alt');
var $author$project$Helpers$doNothing = $elm$core$Basics$identity;
var $author$project$Components$Button$onFocus = F2(
	function (msg, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{
				eF: $elm$core$Maybe$Just(msg)
			});
	});
var $author$project$Components$Button$width = F2(
	function (w, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{
				fg: $elm$core$Maybe$Just(w)
			});
	});
var $author$project$Components$DropdownMenu$menuItem = function (_v0) {
	var id = _v0.er;
	var onClick = _v0.cj;
	var maybeOnFocus = _v0.mk;
	var icon = _v0.cc;
	var isDisabled = _v0.l0;
	var text = _v0.bJ;
	return $author$project$Components$Button$toElement(
		A2(
			$author$project$Components$Button$width,
			$mdgriffith$elm_ui$Element$fill,
			A2(
				$author$project$Components$Button$id,
				id,
				function () {
					if (!maybeOnFocus.$) {
						var onFocus = maybeOnFocus.a;
						return $author$project$Components$Button$onFocus(
							onFocus(id));
					} else {
						return $author$project$Helpers$doNothing;
					}
				}()(
					A2(
						$author$project$Components$Button$iconRight,
						icon,
						A2(
							$author$project$Components$Button$text,
							text,
							A2(
								$author$project$Components$Button$disabled,
								isDisabled,
								A2($author$project$Components$Button$onClick, onClick, $author$project$Components$Button$button))))))));
};
var $author$project$Components$DropdownMenu$styles = _List_fromArray(
	[
		$mdgriffith$elm_ui$Element$Background$color(
		$author$project$Colors$surface(2)),
		$mdgriffith$elm_ui$Element$Border$rounded(4),
		$mdgriffith$elm_ui$Element$padding(4),
		$mdgriffith$elm_ui$Element$spacing(4)
	]);
var $author$project$Components$TopMenu$Dialog$view = F4(
	function (showOverlay, onKeyDown, onOutsideClick, contents) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_Utils_ap(
				showOverlay ? _List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Background$color(
						A4($mdgriffith$elm_ui$Element$rgba, 0, 0, 0, 0.7))
					]) : _List_Nil,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$htmlAttribute(
						A2($elm$html$Html$Attributes$style, 'position', 'fixed')),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						A2($elm$html$Html$Attributes$style, 'left', '0')),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						A2($elm$html$Html$Attributes$style, 'top', '0')),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						A2($elm$html$Html$Attributes$style, 'right', '0')),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						A2($elm$html$Html$Attributes$style, 'bottom', '0')),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						A2($elm$html$Html$Attributes$style, 'z-index', '9999')),
						$mdgriffith$elm_ui$Element$htmlAttribute(onKeyDown),
						$mdgriffith$elm_ui$Element$behindContent(
						A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$Events$onClick(onOutsideClick)
								]),
							$mdgriffith$elm_ui$Element$none)),
						$mdgriffith$elm_ui$Element$inFront(contents)
					])),
			$mdgriffith$elm_ui$Element$none);
	});
var $author$project$Icons$workers = $author$project$Icons$fa_icon('fas fa-user-friends');
var $author$project$Components$TopMenu$UserMenu$view_MenuOpen = F3(
	function (appContext, selectedUser, _v0) {
		var focusedMenuItemId = _v0.eg;
		var menuToggle = $author$project$Components$Button$toElement(
			A2(
				$author$project$Components$Button$onFocus,
				$author$project$Components$TopMenu$UserMenu$CloseMenu,
				A2($author$project$Components$TopMenu$UserMenu$menuToggleButton, selectedUser, $author$project$Components$TopMenu$UserMenu$CloseMenu)));
		var isAnotherUserSelected_ = A2($author$project$Components$TopMenu$UserMenu$isAnotherUserSelected, selectedUser, appContext);
		var canSwitchUser = $author$project$Types$User$canSwitchUser(selectedUser);
		var menu = A4(
			$author$project$Components$TopMenu$Dialog$view,
			false,
			$author$project$Components$TopMenu$UserMenu$handleKeyDown_MenuOpen(focusedMenuItemId),
			$author$project$Components$TopMenu$UserMenu$CloseMenu,
			A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$padding(8),
						$mdgriffith$elm_ui$Element$alignRight
					]),
				A2(
					$mdgriffith$elm_ui$Element$column,
					$author$project$Components$DropdownMenu$styles,
					_List_fromArray(
						[
							canSwitchUser ? $author$project$Components$DropdownMenu$menuItem(
							{
								cc: $author$project$Icons$workers,
								er: $author$project$Components$TopMenu$UserMenu$firstMenuItemId,
								l0: isAnotherUserSelected_,
								mk: $elm$core$Maybe$Just($author$project$Components$TopMenu$UserMenu$MenuItemGotFocus),
								cj: $author$project$Components$TopMenu$UserMenu$ShowUserNavigator_MenuOpen,
								bJ: 'Vaihda käyttäjä'
							}) : $mdgriffith$elm_ui$Element$none,
							$author$project$Components$DropdownMenu$menuItem(
							{
								cc: $author$project$Icons$key,
								er: canSwitchUser ? 'second-menu-item' : $author$project$Components$TopMenu$UserMenu$firstMenuItemId,
								l0: isAnotherUserSelected_,
								mk: $elm$core$Maybe$Just($author$project$Components$TopMenu$UserMenu$MenuItemGotFocus),
								cj: $author$project$Components$TopMenu$UserMenu$OpenPwdDialog,
								bJ: 'Vaihda salasana'
							}),
							$author$project$Components$DropdownMenu$menuItem(
							{
								cc: $author$project$Icons$logout,
								er: $author$project$Components$TopMenu$UserMenu$lastMenuItemId,
								l0: isAnotherUserSelected_,
								mk: $elm$core$Maybe$Just($author$project$Components$TopMenu$UserMenu$MenuItemGotFocus),
								cj: $author$project$Components$TopMenu$UserMenu$Logout,
								bJ: 'Kirjaudu ulos'
							})
						]))));
		return A2(
			$mdgriffith$elm_ui$Element$map,
			$author$project$Components$TopMenu$UserMenu$Msg_MenuOpen,
			A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$inFront(menu)
					]),
				menuToggle));
	});
var $author$project$Components$TopMenu$UserMenu$NoOp_PwdDialog = {$: 1};
var $author$project$Components$TopMenu$ChangePasswordDialog$Cancel = {$: 0};
var $author$project$Components$TopMenu$ChangePasswordDialog$ChangeConfirmNewPassword = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$TopMenu$ChangePasswordDialog$ChangeCurrentPassword = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$TopMenu$ChangePasswordDialog$ChangeNewPassword = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$TopMenu$ChangePasswordDialog$ChangePasswordButtonClicked = {$: 4};
var $author$project$Components$TopMenu$ChangePasswordDialog$areNewPasswordsMatching = F2(
	function (password1, password2) {
		return _Utils_eq(password1, password2) && ((!$elm$core$String$isEmpty(password1)) && (!$elm$core$String$isEmpty(password2)));
	});
var $mdgriffith$elm_ui$Internal$Flag$fontWeight = $mdgriffith$elm_ui$Internal$Flag$flag(13);
var $mdgriffith$elm_ui$Element$Font$bold = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$fontWeight, $mdgriffith$elm_ui$Internal$Style$classes.kd);
var $author$project$Helpers$hasLowerCase = function (string) {
	return !$elm$core$List$isEmpty(
		A2(
			$elm$core$List$filter,
			function (_char) {
				return $elm$core$Char$isLower(_char) || A2(
					$elm$core$List$member,
					_char,
					_List_fromArray(
						['ä', 'ö', 'å']));
			},
			$elm$core$String$toList(string)));
};
var $author$project$Helpers$hasNonAlphaNumeric = function (string) {
	return !$elm$core$List$isEmpty(
		A2(
			$elm$core$List$filter,
			function (_char) {
				return !($elm$core$Char$isAlphaNum(_char) || A2(
					$elm$core$List$member,
					_char,
					_List_fromArray(
						['ä', 'ö', 'å', 'Ä', 'Ö', 'Å'])));
			},
			$elm$core$String$toList(string)));
};
var $author$project$Helpers$hasNumbers = function (string) {
	return !$elm$core$List$isEmpty(
		A2(
			$elm$core$List$filter,
			$elm$core$Char$isDigit,
			$elm$core$String$toList(string)));
};
var $author$project$Helpers$hasUpperCase = function (string) {
	return !$elm$core$List$isEmpty(
		A2(
			$elm$core$List$filter,
			function (_char) {
				return $elm$core$Char$isUpper(_char) || A2(
					$elm$core$List$member,
					_char,
					_List_fromArray(
						['Ä', 'Ö', 'Å']));
			},
			$elm$core$String$toList(string)));
};
var $author$project$Components$TopMenu$ChangePasswordDialog$isNewPasswordSameAsCurrent = F2(
	function (currentPassword, newPassword) {
		return _Utils_eq(currentPassword, newPassword) && (!$elm$core$String$isEmpty(newPassword));
	});
var $author$project$Components$TopMenu$ChangePasswordDialog$isPasswordLongEnough = function (password) {
	return $elm$core$String$length(password) >= 8;
};
var $author$project$Components$TopMenu$ChangePasswordDialog$canSubmitPasswordChange = F3(
	function (currentPassword, newPassword, newPasswordAgain) {
		return A2($author$project$Components$TopMenu$ChangePasswordDialog$areNewPasswordsMatching, newPassword, newPasswordAgain) && ((!A2($author$project$Components$TopMenu$ChangePasswordDialog$isNewPasswordSameAsCurrent, currentPassword, newPassword)) && ((!$elm$core$String$isEmpty(currentPassword)) && ((!$elm$core$String$isEmpty(newPassword)) && ((!$elm$core$String$isEmpty(newPasswordAgain)) && ($author$project$Components$TopMenu$ChangePasswordDialog$isPasswordLongEnough(newPassword) && (($author$project$Helpers$hasNumbers(newPassword) || $author$project$Helpers$hasNonAlphaNumeric(newPassword)) && ($author$project$Helpers$hasLowerCase(newPassword) && $author$project$Helpers$hasUpperCase(newPassword))))))));
	});
var $author$project$Components$Input$defaultAttributes = _List_fromArray(
	[
		$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
		$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
		$mdgriffith$elm_ui$Element$padding(6),
		$mdgriffith$elm_ui$Element$centerY,
		$mdgriffith$elm_ui$Element$Background$color(
		$author$project$Colors$surface(1)),
		$mdgriffith$elm_ui$Element$Font$color(
		$author$project$Colors$onSurface(9)),
		$mdgriffith$elm_ui$Element$Border$width(0),
		$mdgriffith$elm_ui$Element$Font$alignLeft
	]);
var $mdgriffith$elm_ui$Element$Input$HiddenLabel = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Element$Input$labelHidden = $mdgriffith$elm_ui$Element$Input$HiddenLabel;
var $mdgriffith$elm_ui$Element$Input$newPassword = F2(
	function (attrs, pass) {
		return A3(
			$mdgriffith$elm_ui$Element$Input$textHelper,
			{
				at: $elm$core$Maybe$Just('new-password'),
				aD: false,
				gz: $mdgriffith$elm_ui$Element$Input$TextInputNode(
					pass.i1 ? 'text' : 'password')
			},
			attrs,
			{ma: pass.ma, mK: pass.mK, nt: pass.nt, bJ: pass.bJ});
	});
var $author$project$Components$Input$placeholder = function (placeholderText) {
	return $elm$core$Maybe$Just(
		A2(
			$mdgriffith$elm_ui$Element$Input$placeholder,
			_List_fromArray(
				[$mdgriffith$elm_ui$Element$centerY, $mdgriffith$elm_ui$Element$alignLeft, $mdgriffith$elm_ui$Element$Font$alignLeft]),
			$mdgriffith$elm_ui$Element$text(placeholderText)));
};
var $author$project$Components$Input$currentPasswordInput = F4(
	function (customAttributes, labelText, onChange, valueText) {
		return A2(
			$mdgriffith$elm_ui$Element$Input$newPassword,
			_Utils_ap($author$project$Components$Input$defaultAttributes, customAttributes),
			{
				ma: $mdgriffith$elm_ui$Element$Input$labelHidden(labelText),
				mK: function (value) {
					return onChange(value);
				},
				nt: $author$project$Components$Input$placeholder(labelText),
				i1: false,
				bJ: valueText
			});
	});
var $author$project$Components$TopMenu$ChangePasswordDialog$handleKeyDown = function (model) {
	return A2(
		$elm$html$Html$Events$custom,
		'keydown',
		$author$project$KeyboardHelper$htmlEventToKey(
			function (key) {
				var _v0 = function () {
					switch (key) {
						case 6:
							return _Utils_Tuple2($author$project$Components$TopMenu$ChangePasswordDialog$Cancel, true);
						case 5:
							return A3($author$project$Components$TopMenu$ChangePasswordDialog$canSubmitPasswordChange, model.aJ, model.G, model.aN) ? _Utils_Tuple2($author$project$Components$TopMenu$ChangePasswordDialog$ChangePasswordButtonClicked, true) : _Utils_Tuple2($author$project$Components$TopMenu$ChangePasswordDialog$NoOp, false);
						default:
							return _Utils_Tuple2($author$project$Components$TopMenu$ChangePasswordDialog$NoOp, false);
					}
				}();
				var message = _v0.a;
				var doPrevent = _v0.b;
				return {mp: message, iu: doPrevent, ja: doPrevent};
			}));
};
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $author$project$Utils$HtmlUtil$id = A2($elm$core$Basics$composeR, $elm$html$Html$Attributes$id, $mdgriffith$elm_ui$Element$htmlAttribute);
var $elm$html$Html$Attributes$autocomplete = function (bool) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'autocomplete',
		bool ? 'on' : 'off');
};
var $author$project$Utils$HtmlUtil$disableAutoComplete = $mdgriffith$elm_ui$Element$htmlAttribute(
	$elm$html$Html$Attributes$autocomplete(false));
var $author$project$Components$Input$newPasswordInput = F4(
	function (customAttributes, labelText, onChange, valueText) {
		return A2(
			$mdgriffith$elm_ui$Element$Input$newPassword,
			_Utils_ap(
				$author$project$Components$Input$defaultAttributes,
				A2($elm$core$List$cons, $author$project$Utils$HtmlUtil$disableAutoComplete, customAttributes)),
			{
				ma: $mdgriffith$elm_ui$Element$Input$labelHidden(labelText),
				mK: function (value) {
					return onChange(value);
				},
				nt: $author$project$Components$Input$placeholder(labelText),
				i1: false,
				bJ: valueText
			});
	});
var $author$project$Components$Button$Secondary = 2;
var $author$project$Components$Button$secondary = function (_v0) {
	var options = _v0;
	return _Utils_update(
		options,
		{bx: 2});
};
var $mdgriffith$elm_ui$Internal$Model$Px = function (a) {
	return {$: 0, a: a};
};
var $mdgriffith$elm_ui$Element$px = $mdgriffith$elm_ui$Internal$Model$Px;
var $author$project$Components$TopMenu$ChangePasswordDialog$view_passwordError = function (errorMessage) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$height(
				$mdgriffith$elm_ui$Element$px(18)),
				$mdgriffith$elm_ui$Element$centerX,
				$mdgriffith$elm_ui$Element$Font$size(18),
				$mdgriffith$elm_ui$Element$Font$color($author$project$Colors$warning)
			]),
		$mdgriffith$elm_ui$Element$text(errorMessage));
};
var $author$project$Components$TopMenu$ChangePasswordDialog$view = function (model) {
	var isNewSameAsCurrent = A2($author$project$Components$TopMenu$ChangePasswordDialog$isNewPasswordSameAsCurrent, model.aJ, model.G);
	var isLongEnough = $author$project$Components$TopMenu$ChangePasswordDialog$isPasswordLongEnough(model.G);
	var doesConfirmMatch = A2($author$project$Components$TopMenu$ChangePasswordDialog$areNewPasswordsMatching, model.G, model.aN);
	var areFieldsProperlyFilled = A3($author$project$Components$TopMenu$ChangePasswordDialog$canSubmitPasswordChange, model.aJ, model.G, model.aN);
	return A4(
		$author$project$Components$TopMenu$Dialog$view,
		true,
		$author$project$Components$TopMenu$ChangePasswordDialog$handleKeyDown(model),
		$author$project$Components$TopMenu$ChangePasswordDialog$Cancel,
		A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Background$color(
					$author$project$Colors$surface(2)),
					$mdgriffith$elm_ui$Element$Border$rounded(4),
					$mdgriffith$elm_ui$Element$padding(8),
					$mdgriffith$elm_ui$Element$centerX,
					$mdgriffith$elm_ui$Element$centerY,
					$mdgriffith$elm_ui$Element$Font$color(
					$author$project$Colors$onSurface(7)),
					$mdgriffith$elm_ui$Element$spacing(12)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$centerX,
							$mdgriffith$elm_ui$Element$Font$size(20),
							$mdgriffith$elm_ui$Element$Font$bold
						]),
					$mdgriffith$elm_ui$Element$text('VAIHDA SALASANA')),
					A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$spacing(6)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$spacing(8),
									$mdgriffith$elm_ui$Element$alignRight
								]),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text('Nykyinen salasana'),
									A4(
									$author$project$Components$Input$currentPasswordInput,
									_List_fromArray(
										[
											$author$project$Utils$HtmlUtil$id($author$project$Components$TopMenu$ChangePasswordDialog$currentPasswordInputId)
										]),
									'',
									$author$project$Components$TopMenu$ChangePasswordDialog$ChangeCurrentPassword,
									model.aJ)
								])),
							$author$project$Components$TopMenu$ChangePasswordDialog$view_passwordError(
							function () {
								var _v0 = model.a8;
								if (!_v0.$) {
									var errorMsg = _v0.a;
									return errorMsg;
								} else {
									return '';
								}
							}())
						])),
					A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$spacing(8)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$spacing(8),
									$mdgriffith$elm_ui$Element$alignRight
								]),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text('Uusi salasana'),
									A4($author$project$Components$Input$newPasswordInput, _List_Nil, '', $author$project$Components$TopMenu$ChangePasswordDialog$ChangeNewPassword, model.G)
								])),
							A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$spacing(8),
									$mdgriffith$elm_ui$Element$alignRight
								]),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text('Toista uusi salasana'),
									A4($author$project$Components$Input$newPasswordInput, _List_Nil, '', $author$project$Components$TopMenu$ChangePasswordDialog$ChangeConfirmNewPassword, model.aN)
								])),
							$author$project$Components$TopMenu$ChangePasswordDialog$view_passwordError(
							$elm$core$String$isEmpty(model.G) ? '' : (isNewSameAsCurrent ? 'sama kuin nykyinen salasana' : ((!isLongEnough) ? 'syötä vähintään 8 merkkiä' : ((!$author$project$Helpers$hasLowerCase(model.G)) ? 'vähintään yksi pieni kirjain' : ((!$author$project$Helpers$hasUpperCase(model.G)) ? 'vähintään yksi iso kirjain' : ((!($author$project$Helpers$hasNumbers(model.G) || $author$project$Helpers$hasNonAlphaNumeric(model.G))) ? 'vähintään yksi numero tai erikoismerkki' : ((!($elm$core$String$isEmpty(model.aN) || doesConfirmMatch)) ? 'salasana ei täsmää' : '')))))))
						])),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$alignRight,
								$mdgriffith$elm_ui$Element$spacing(8)
							]),
						_List_fromArray(
							[
								$author$project$Components$Button$toElement(
								$author$project$Components$Button$secondary(
									A2(
										$author$project$Components$Button$text,
										'PERUUTA',
										A2($author$project$Components$Button$onClick, $author$project$Components$TopMenu$ChangePasswordDialog$Cancel, $author$project$Components$Button$button)))),
								$author$project$Components$Button$toElement(
								A2(
									$author$project$Components$Button$text,
									'VAIHDA',
									A2(
										$author$project$Components$Button$disabled,
										!areFieldsProperlyFilled,
										$author$project$Components$Button$primary(
											A2($author$project$Components$Button$onClick, $author$project$Components$TopMenu$ChangePasswordDialog$ChangePasswordButtonClicked, $author$project$Components$Button$button)))))
							])))
				])));
};
var $author$project$Components$TopMenu$UserMenu$view_PwdDialog = F2(
	function (appContext, stateModel) {
		var pwdDialog = A2(
			$mdgriffith$elm_ui$Element$map,
			$author$project$Components$TopMenu$UserMenu$GotPwdDialogMsg,
			$author$project$Components$TopMenu$ChangePasswordDialog$view(stateModel));
		return A2(
			$mdgriffith$elm_ui$Element$map,
			$author$project$Components$TopMenu$UserMenu$Msg_PwdDialog,
			A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$inFront(pwdDialog)
					]),
				$author$project$Components$Button$toElement(
					A2($author$project$Components$TopMenu$UserMenu$menuToggleButton, appContext.h4, $author$project$Components$TopMenu$UserMenu$NoOp_PwdDialog))));
	});
var $author$project$Components$TopMenu$UserMenu$view = F3(
	function (appContext, selectedUser, model) {
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_Nil,
			_List_fromArray(
				[
					$author$project$Types$User$canSwitchUser(selectedUser) ? $author$project$Components$Button$toElement(
					A2(
						$author$project$Components$Button$tooltip,
						'Vaihda käyttäjää',
						A2(
							$author$project$Components$Button$iconRight,
							$author$project$Icons$workers,
							A2(
								$author$project$Components$Button$disabled,
								A2($author$project$Components$TopMenu$UserMenu$isAnotherUserSelected, selectedUser, appContext),
								A2($author$project$Components$Button$onClick, $author$project$Components$TopMenu$UserMenu$ShowUserNavigator, $author$project$Components$Button$button))))) : $mdgriffith$elm_ui$Element$none,
					function () {
					var _v0 = model._;
					switch (_v0.$) {
						case 0:
							return $author$project$Components$TopMenu$UserMenu$view_MenuClosed(selectedUser);
						case 1:
							var stateModel = _v0.a;
							return A3($author$project$Components$TopMenu$UserMenu$view_MenuOpen, appContext, selectedUser, stateModel);
						default:
							var stateModel = _v0.a;
							return A2($author$project$Components$TopMenu$UserMenu$view_PwdDialog, appContext, stateModel);
					}
				}()
				]));
	});
var $author$project$Components$Vehicles$Search$SearchButtonClicked = {$: 0};
var $author$project$Components$Vehicles$Search$SearchStringTypedIn = function (a) {
	return {$: 1, a: a};
};
var $author$project$Styles$largeFontSize = $mdgriffith$elm_ui$Element$Font$size($author$project$Styles$fontSizesInPixels.mc);
var $author$project$Icons$search = $author$project$Icons$fa_icon('fas fa-search');
var $mdgriffith$elm_ui$Element$Input$text = $mdgriffith$elm_ui$Element$Input$textHelper(
	{
		at: $elm$core$Maybe$Nothing,
		aD: false,
		gz: $mdgriffith$elm_ui$Element$Input$TextInputNode('text')
	});
var $author$project$Components$Input$textInput = F4(
	function (customAttributes, labelText, onChange, valueText) {
		return A2(
			$mdgriffith$elm_ui$Element$Input$text,
			_Utils_ap($author$project$Components$Input$defaultAttributes, customAttributes),
			{
				ma: $mdgriffith$elm_ui$Element$Input$labelHidden(labelText),
				mK: function (value) {
					return onChange(value);
				},
				nt: $author$project$Components$Input$placeholder(labelText),
				bJ: valueText
			});
	});
var $author$project$Components$Vehicles$Search$view_searchBar = function (model) {
	var _v0 = function () {
		var _v1 = model._;
		switch (_v1.$) {
			case 0:
				var typedInCharacters = _v1.a;
				return _Utils_Tuple3(typedInCharacters, false, false);
			case 1:
				var searchTerm = _v1.a;
				return _Utils_Tuple3(
					$author$project$Components$Vehicles$SearchTerm$toString(searchTerm),
					true,
					true);
			default:
				var searchTerm = _v1.a;
				return _Utils_Tuple3(
					$author$project$Components$Vehicles$SearchTerm$toString(searchTerm),
					true,
					false);
		}
	}();
	var searchTermString = _v0.a;
	var isDisabled = _v0.b;
	var showLoadingSpinner = _v0.c;
	var onInputEvent = isDisabled ? function (_v5) {
		return $author$project$Components$Vehicles$Search$NoOp;
	} : $author$project$Components$Vehicles$Search$SearchStringTypedIn;
	var triggerSearchOnEnter = $mdgriffith$elm_ui$Element$htmlAttribute(
		A2(
			$elm$html$Html$Events$custom,
			'keydown',
			$author$project$KeyboardHelper$htmlEventToKey(
				function (key) {
					return {
						mp: function () {
							var _v3 = _Utils_Tuple2(key, isDisabled);
							if ((_v3.a === 5) && (!_v3.b)) {
								var _v4 = _v3.a;
								return $author$project$Components$Vehicles$Search$SearchButtonClicked;
							} else {
								return $author$project$Components$Vehicles$Search$NoOp;
							}
						}(),
						iu: false,
						ja: false
					};
				})));
	var _v2 = showLoadingSpinner ? _Utils_Tuple2(
		'ladataan...',
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Font$color(
				$author$project$Colors$onSurface(5))
			])) : _Utils_Tuple2(searchTermString, _List_Nil);
	var inputText = _v2.a;
	var loadingStyles = _v2.b;
	return A2(
		$mdgriffith$elm_ui$Element$row,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width(
				$mdgriffith$elm_ui$Element$px(160)),
				$mdgriffith$elm_ui$Element$Background$color(
				$author$project$Colors$surface(1)),
				$mdgriffith$elm_ui$Element$spacing(1),
				triggerSearchOnEnter
			]),
		_List_fromArray(
			[
				A4(
				$author$project$Components$Input$textInput,
				_Utils_ap(
					_List_fromArray(
						[
							$author$project$Styles$largeFontSize,
							$author$project$Utils$HtmlUtil$id($author$project$Components$Vehicles$Search$licenseNumberSearchInputId),
							$author$project$Utils$HtmlUtil$disableAutoComplete
						]),
					loadingStyles),
				'Ajoneuvohaku',
				onInputEvent,
				inputText),
				$author$project$Components$Button$toElement(
				A2(
					$author$project$Components$Button$disabled,
					isDisabled,
					A2(
						$author$project$Components$Button$testId,
						'vehicle-history-search-button',
						A2(
							$author$project$Components$Button$icon,
							$author$project$Icons$search,
							A2($author$project$Components$Button$onClick, $author$project$Components$Vehicles$Search$SearchButtonClicked, $author$project$Components$Button$button)))))
			]));
};
var $author$project$Components$Vehicles$Search$HideVehicleListDialog = {$: 4};
var $author$project$Components$Vehicles$Search$VehicleRowClicked = function (a) {
	return {$: 3, a: a};
};
var $author$project$Icons$close = $author$project$Icons$fa_icon('fas fa-times');
var $author$project$Components$CloseButton$closeButton = function (onClick) {
	return A2(
		$mdgriffith$elm_ui$Element$Input$button,
		_List_fromArray(
			[
				$author$project$Utils$HtmlUtil$id($author$project$Components$CloseButton$myId)
			]),
		{
			ma: $author$project$Icons$close,
			m2: $elm$core$Maybe$Just(onClick)
		});
};
var $ohanhi$keyboard$Keyboard$Escape = {$: 62};
var $robinheghan$keyboard_events$Keyboard$Events$Keydown = 0;
var $robinheghan$keyboard_events$Keyboard$Events$eventToString = function (event) {
	switch (event) {
		case 0:
			return 'keydown';
		case 1:
			return 'keyup';
		default:
			return 'keypress';
	}
};
var $ohanhi$keyboard$Keyboard$Backspace = {$: 26};
var $ohanhi$keyboard$Keyboard$Clear = {$: 27};
var $ohanhi$keyboard$Keyboard$Copy = {$: 28};
var $ohanhi$keyboard$Keyboard$CrSel = {$: 29};
var $ohanhi$keyboard$Keyboard$Cut = {$: 30};
var $ohanhi$keyboard$Keyboard$Delete = {$: 31};
var $ohanhi$keyboard$Keyboard$EraseEof = {$: 32};
var $ohanhi$keyboard$Keyboard$ExSel = {$: 33};
var $ohanhi$keyboard$Keyboard$Insert = {$: 34};
var $ohanhi$keyboard$Keyboard$Paste = {$: 35};
var $ohanhi$keyboard$Keyboard$Redo = {$: 36};
var $ohanhi$keyboard$Keyboard$Undo = {$: 37};
var $ohanhi$keyboard$Keyboard$editingKey = function (_v0) {
	var value = _v0;
	switch (value) {
		case 'Backspace':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Backspace);
		case 'Clear':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Clear);
		case 'Copy':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Copy);
		case 'CrSel':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$CrSel);
		case 'Cut':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Cut);
		case 'Delete':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Delete);
		case 'EraseEof':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$EraseEof);
		case 'ExSel':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$ExSel);
		case 'Insert':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Insert);
		case 'Paste':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Paste);
		case 'Redo':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Redo);
		case 'Undo':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Undo);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $ohanhi$keyboard$Keyboard$F1 = {$: 38};
var $ohanhi$keyboard$Keyboard$F10 = {$: 47};
var $ohanhi$keyboard$Keyboard$F11 = {$: 48};
var $ohanhi$keyboard$Keyboard$F12 = {$: 49};
var $ohanhi$keyboard$Keyboard$F13 = {$: 50};
var $ohanhi$keyboard$Keyboard$F14 = {$: 51};
var $ohanhi$keyboard$Keyboard$F15 = {$: 52};
var $ohanhi$keyboard$Keyboard$F16 = {$: 53};
var $ohanhi$keyboard$Keyboard$F17 = {$: 54};
var $ohanhi$keyboard$Keyboard$F18 = {$: 55};
var $ohanhi$keyboard$Keyboard$F19 = {$: 56};
var $ohanhi$keyboard$Keyboard$F2 = {$: 39};
var $ohanhi$keyboard$Keyboard$F20 = {$: 57};
var $ohanhi$keyboard$Keyboard$F3 = {$: 40};
var $ohanhi$keyboard$Keyboard$F4 = {$: 41};
var $ohanhi$keyboard$Keyboard$F5 = {$: 42};
var $ohanhi$keyboard$Keyboard$F6 = {$: 43};
var $ohanhi$keyboard$Keyboard$F7 = {$: 44};
var $ohanhi$keyboard$Keyboard$F8 = {$: 45};
var $ohanhi$keyboard$Keyboard$F9 = {$: 46};
var $ohanhi$keyboard$Keyboard$functionKey = function (_v0) {
	var value = _v0;
	switch (value) {
		case 'F1':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$F1);
		case 'F2':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$F2);
		case 'F3':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$F3);
		case 'F4':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$F4);
		case 'F5':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$F5);
		case 'F6':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$F6);
		case 'F7':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$F7);
		case 'F8':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$F8);
		case 'F9':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$F9);
		case 'F10':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$F10);
		case 'F11':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$F11);
		case 'F12':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$F12);
		case 'F13':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$F13);
		case 'F14':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$F14);
		case 'F15':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$F15);
		case 'F16':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$F16);
		case 'F17':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$F17);
		case 'F18':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$F18);
		case 'F19':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$F19);
		case 'F20':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$F20);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $ohanhi$keyboard$Keyboard$ChannelDown = {$: 85};
var $ohanhi$keyboard$Keyboard$ChannelUp = {$: 86};
var $ohanhi$keyboard$Keyboard$MediaFastForward = {$: 87};
var $ohanhi$keyboard$Keyboard$MediaPause = {$: 88};
var $ohanhi$keyboard$Keyboard$MediaPlay = {$: 89};
var $ohanhi$keyboard$Keyboard$MediaPlayPause = {$: 90};
var $ohanhi$keyboard$Keyboard$MediaRecord = {$: 91};
var $ohanhi$keyboard$Keyboard$MediaRewind = {$: 92};
var $ohanhi$keyboard$Keyboard$MediaStop = {$: 93};
var $ohanhi$keyboard$Keyboard$MediaTrackNext = {$: 94};
var $ohanhi$keyboard$Keyboard$MediaTrackPrevious = {$: 95};
var $ohanhi$keyboard$Keyboard$mediaKey = function (_v0) {
	var value = _v0;
	switch (value) {
		case 'ChannelDown':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$ChannelDown);
		case 'ChannelUp':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$ChannelUp);
		case 'MediaFastForward':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$MediaFastForward);
		case 'MediaPause':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$MediaPause);
		case 'MediaPlay':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$MediaPlay);
		case 'MediaPlayPause':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$MediaPlayPause);
		case 'MediaRecord':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$MediaRecord);
		case 'MediaRewind':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$MediaRewind);
		case 'MediaStop':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$MediaStop);
		case 'MediaTrackNext':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$MediaTrackNext);
		case 'MediaTrackPrevious':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$MediaTrackPrevious);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $ohanhi$keyboard$Keyboard$Alt = {$: 1};
var $ohanhi$keyboard$Keyboard$AltGraph = {$: 2};
var $ohanhi$keyboard$Keyboard$CapsLock = {$: 3};
var $ohanhi$keyboard$Keyboard$Control = {$: 4};
var $ohanhi$keyboard$Keyboard$Fn = {$: 5};
var $ohanhi$keyboard$Keyboard$FnLock = {$: 6};
var $ohanhi$keyboard$Keyboard$Hyper = {$: 7};
var $ohanhi$keyboard$Keyboard$Meta = {$: 8};
var $ohanhi$keyboard$Keyboard$NumLock = {$: 9};
var $ohanhi$keyboard$Keyboard$ScrollLock = {$: 10};
var $ohanhi$keyboard$Keyboard$Shift = {$: 11};
var $ohanhi$keyboard$Keyboard$Super = {$: 12};
var $ohanhi$keyboard$Keyboard$Symbol = {$: 13};
var $ohanhi$keyboard$Keyboard$SymbolLock = {$: 14};
var $ohanhi$keyboard$Keyboard$modifierKey = function (_v0) {
	var value = _v0;
	switch (value) {
		case 'Alt':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Alt);
		case 'AltGraph':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$AltGraph);
		case 'CapsLock':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$CapsLock);
		case 'Control':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Control);
		case 'Fn':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Fn);
		case 'FnLock':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$FnLock);
		case 'Hyper':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Hyper);
		case 'Meta':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Meta);
		case 'NumLock':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$NumLock);
		case 'ScrollLock':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$ScrollLock);
		case 'Shift':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Shift);
		case 'Super':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Super);
		case 'OS':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Super);
		case 'Symbol':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Symbol);
		case 'SymbolLock':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$SymbolLock);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $ohanhi$keyboard$Keyboard$ArrowDown = {$: 18};
var $ohanhi$keyboard$Keyboard$ArrowLeft = {$: 19};
var $ohanhi$keyboard$Keyboard$ArrowRight = {$: 20};
var $ohanhi$keyboard$Keyboard$ArrowUp = {$: 21};
var $ohanhi$keyboard$Keyboard$End = {$: 22};
var $ohanhi$keyboard$Keyboard$Home = {$: 23};
var $ohanhi$keyboard$Keyboard$PageDown = {$: 24};
var $ohanhi$keyboard$Keyboard$PageUp = {$: 25};
var $ohanhi$keyboard$Keyboard$navigationKey = function (_v0) {
	var value = _v0;
	switch (value) {
		case 'ArrowDown':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$ArrowDown);
		case 'ArrowLeft':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$ArrowLeft);
		case 'ArrowRight':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$ArrowRight);
		case 'ArrowUp':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$ArrowUp);
		case 'Down':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$ArrowDown);
		case 'Left':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$ArrowLeft);
		case 'Right':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$ArrowRight);
		case 'Up':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$ArrowUp);
		case 'End':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$End);
		case 'Home':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Home);
		case 'PageDown':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$PageDown);
		case 'PageUp':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$PageUp);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $ohanhi$keyboard$Keyboard$oneOf = F2(
	function (fns, key) {
		oneOf:
		while (true) {
			if (!fns.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var fn = fns.a;
				var rest = fns.b;
				var _v1 = fn(key);
				if (!_v1.$) {
					var a = _v1.a;
					return $elm$core$Maybe$Just(a);
				} else {
					var $temp$fns = rest,
						$temp$key = key;
					fns = $temp$fns;
					key = $temp$key;
					continue oneOf;
				}
			}
		}
	});
var $ohanhi$keyboard$Keyboard$AppSwitch = {$: 73};
var $ohanhi$keyboard$Keyboard$Call = {$: 74};
var $ohanhi$keyboard$Keyboard$Camera = {$: 75};
var $ohanhi$keyboard$Keyboard$CameraFocus = {$: 76};
var $ohanhi$keyboard$Keyboard$EndCall = {$: 77};
var $ohanhi$keyboard$Keyboard$GoBack = {$: 78};
var $ohanhi$keyboard$Keyboard$GoHome = {$: 79};
var $ohanhi$keyboard$Keyboard$HeadsetHook = {$: 80};
var $ohanhi$keyboard$Keyboard$LastNumberRedial = {$: 81};
var $ohanhi$keyboard$Keyboard$MannerMode = {$: 83};
var $ohanhi$keyboard$Keyboard$Notification = {$: 82};
var $ohanhi$keyboard$Keyboard$VoiceDial = {$: 84};
var $ohanhi$keyboard$Keyboard$phoneKey = function (_v0) {
	var value = _v0;
	switch (value) {
		case 'AppSwitch':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$AppSwitch);
		case 'Call':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Call);
		case 'Camera':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Camera);
		case 'CameraFocus':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$CameraFocus);
		case 'EndCall':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$EndCall);
		case 'GoBack':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$GoBack);
		case 'GoHome':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$GoHome);
		case 'HeadsetHook':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$HeadsetHook);
		case 'LastNumberRedial':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$LastNumberRedial);
		case 'Notification':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Notification);
		case 'MannerMode':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$MannerMode);
		case 'VoiceDial':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$VoiceDial);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $ohanhi$keyboard$Keyboard$Again = {$: 58};
var $ohanhi$keyboard$Keyboard$Attn = {$: 59};
var $ohanhi$keyboard$Keyboard$Cancel = {$: 60};
var $ohanhi$keyboard$Keyboard$ContextMenu = {$: 61};
var $ohanhi$keyboard$Keyboard$Execute = {$: 63};
var $ohanhi$keyboard$Keyboard$Find = {$: 64};
var $ohanhi$keyboard$Keyboard$Finish = {$: 65};
var $ohanhi$keyboard$Keyboard$Help = {$: 66};
var $ohanhi$keyboard$Keyboard$Pause = {$: 67};
var $ohanhi$keyboard$Keyboard$Play = {$: 68};
var $ohanhi$keyboard$Keyboard$Props = {$: 69};
var $ohanhi$keyboard$Keyboard$Select = {$: 70};
var $ohanhi$keyboard$Keyboard$ZoomIn = {$: 71};
var $ohanhi$keyboard$Keyboard$ZoomOut = {$: 72};
var $ohanhi$keyboard$Keyboard$uiKey = function (_v0) {
	var value = _v0;
	switch (value) {
		case 'Again':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Again);
		case 'Attn':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Attn);
		case 'Cancel':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Cancel);
		case 'ContextMenu':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$ContextMenu);
		case 'Escape':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Escape);
		case 'Execute':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Execute);
		case 'Find':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Find);
		case 'Finish':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Finish);
		case 'Help':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Help);
		case 'Pause':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Pause);
		case 'Play':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Play);
		case 'Props':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Props);
		case 'Select':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Select);
		case 'ZoomIn':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$ZoomIn);
		case 'ZoomOut':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$ZoomOut);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $ohanhi$keyboard$Keyboard$Enter = {$: 15};
var $ohanhi$keyboard$Keyboard$Spacebar = {$: 17};
var $ohanhi$keyboard$Keyboard$Tab = {$: 16};
var $ohanhi$keyboard$Keyboard$whitespaceKey = function (_v0) {
	var value = _v0;
	switch (value) {
		case 'Enter':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Enter);
		case 'Tab':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Tab);
		case 'Spacebar':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Spacebar);
		case ' ':
			return $elm$core$Maybe$Just($ohanhi$keyboard$Keyboard$Spacebar);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $ohanhi$keyboard$Keyboard$anyKeyWith = function (charParser) {
	return $ohanhi$keyboard$Keyboard$oneOf(
		_List_fromArray(
			[$ohanhi$keyboard$Keyboard$whitespaceKey, charParser, $ohanhi$keyboard$Keyboard$modifierKey, $ohanhi$keyboard$Keyboard$navigationKey, $ohanhi$keyboard$Keyboard$editingKey, $ohanhi$keyboard$Keyboard$functionKey, $ohanhi$keyboard$Keyboard$uiKey, $ohanhi$keyboard$Keyboard$phoneKey, $ohanhi$keyboard$Keyboard$mediaKey]));
};
var $ohanhi$keyboard$Keyboard$Character = function (a) {
	return {$: 0, a: a};
};
var $ohanhi$keyboard$Keyboard$characterKeyUpper = function (_v0) {
	var value = _v0;
	return ($elm$core$String$length(value) === 1) ? $elm$core$Maybe$Just(
		$ohanhi$keyboard$Keyboard$Character(
			$elm$core$String$toUpper(value))) : $elm$core$Maybe$Nothing;
};
var $ohanhi$keyboard$Keyboard$anyKeyUpper = $ohanhi$keyboard$Keyboard$anyKeyWith($ohanhi$keyboard$Keyboard$characterKeyUpper);
var $ohanhi$keyboard$Keyboard$RawKey = $elm$core$Basics$identity;
var $ohanhi$keyboard$Keyboard$eventKeyDecoder = A2(
	$elm$json$Json$Decode$field,
	'key',
	A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $elm$json$Json$Decode$string));
var $robinheghan$keyboard_events$Keyboard$Events$messageSelector = function (decisionMap) {
	var helper = function (maybeKey) {
		if (maybeKey.$ === 1) {
			return $elm$json$Json$Decode$fail('No key we\'re interested in');
		} else {
			var key = maybeKey.a;
			return A2(
				$elm$core$Maybe$withDefault,
				$elm$json$Json$Decode$fail('No key we\'re interested in'),
				A2(
					$elm$core$Maybe$map,
					$elm$json$Json$Decode$succeed,
					A2(
						$elm$core$Maybe$map,
						$elm$core$Tuple$second,
						$elm$core$List$head(
							A2(
								$elm$core$List$filter,
								function (_v1) {
									var k = _v1.a;
									return _Utils_eq(k, key);
								},
								decisionMap)))));
		}
	};
	return A2(
		$elm$json$Json$Decode$andThen,
		helper,
		A2($elm$json$Json$Decode$map, $ohanhi$keyboard$Keyboard$anyKeyUpper, $ohanhi$keyboard$Keyboard$eventKeyDecoder));
};
var $robinheghan$keyboard_events$Keyboard$Events$customPerKey = F2(
	function (event, decisionMap) {
		return A2(
			$elm$html$Html$Events$custom,
			$robinheghan$keyboard_events$Keyboard$Events$eventToString(event),
			$robinheghan$keyboard_events$Keyboard$Events$messageSelector(decisionMap));
	});
var $author$project$Components$Modal$onEscKey = function (onClose) {
	return $mdgriffith$elm_ui$Element$htmlAttribute(
		A2(
			$robinheghan$keyboard_events$Keyboard$Events$customPerKey,
			0,
			_List_fromArray(
				[
					_Utils_Tuple2(
					$ohanhi$keyboard$Keyboard$Escape,
					{mp: onClose, iu: true, ja: true})
				])));
};
var $author$project$Components$Modal$modal = F2(
	function (_v0, modalContents) {
		var onClose = _v0.mR;
		var clickOutsideToClose = _v0.kz;
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Background$color(
					A4($mdgriffith$elm_ui$Element$rgba, 0, 0, 0, 0.7)),
					$mdgriffith$elm_ui$Element$htmlAttribute(
					A2($elm$html$Html$Attributes$style, 'position', 'fixed')),
					$mdgriffith$elm_ui$Element$htmlAttribute(
					A2($elm$html$Html$Attributes$style, 'left', '0')),
					$mdgriffith$elm_ui$Element$htmlAttribute(
					A2($elm$html$Html$Attributes$style, 'top', '0')),
					$mdgriffith$elm_ui$Element$htmlAttribute(
					A2($elm$html$Html$Attributes$style, 'right', '0')),
					$mdgriffith$elm_ui$Element$htmlAttribute(
					A2($elm$html$Html$Attributes$style, 'bottom', '0')),
					$mdgriffith$elm_ui$Element$htmlAttribute(
					A2($elm$html$Html$Attributes$style, 'z-index', '9999')),
					$mdgriffith$elm_ui$Element$htmlAttribute(
					A2($elm$html$Html$Attributes$style, 'height', '100vh')),
					$mdgriffith$elm_ui$Element$htmlAttribute(
					$elm$html$Html$Attributes$tabindex(-1)),
					$author$project$Components$Modal$onEscKey(onClose),
					$mdgriffith$elm_ui$Element$behindContent(
					A2(
						$mdgriffith$elm_ui$Element$el,
						_Utils_ap(
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$htmlAttribute(
									$elm$html$Html$Attributes$tabindex(-1)),
									$author$project$Components$Modal$onEscKey(onClose)
								]),
							clickOutsideToClose ? _List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Events$onClick(onClose)
								]) : _List_Nil),
						$mdgriffith$elm_ui$Element$none)),
					$mdgriffith$elm_ui$Element$inFront(
					A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$centerX,
								$mdgriffith$elm_ui$Element$centerY,
								$mdgriffith$elm_ui$Element$scrollbarY,
								$mdgriffith$elm_ui$Element$htmlAttribute(
								A2($elm$html$Html$Attributes$style, 'max-height', '100%'))
							]),
						modalContents))
				]),
			$mdgriffith$elm_ui$Element$none);
	});
var $author$project$Components$Vehicles$History$columnSpacing = 8;
var $author$project$Components$Vehicles$Table$Left = 0;
var $author$project$Components$Vehicles$Table$Middle = 1;
var $author$project$Components$Vehicles$Table$Right = 2;
var $author$project$Helpers$orderNumberString = A2(
	$elm$core$Basics$composeR,
	$elm$core$String$fromInt,
	function (intString) {
		return intString + '.';
	});
var $author$project$Helpers$format_float = function (_float) {
	return A2(
		$cuducos$elm_format_number$FormatNumber$format,
		$author$project$Helpers$finnishNumberLocale(2),
		_float);
};
var $author$project$Helpers$toEuroString = function (_float) {
	return $author$project$Helpers$format_float(_float) + ' €';
};
var $author$project$Types$IsoTime$toString = function (t) {
	return A3(
		$elm$core$String$padLeft,
		2,
		'0',
		$elm$core$String$fromInt(t.d5)) + ('.' + (A3(
		$elm$core$String$padLeft,
		2,
		'0',
		$elm$core$String$fromInt(t.mw)) + ('.' + ($elm$core$String$fromInt(t.fi) + (' ' + (A3(
		$elm$core$String$padLeft,
		2,
		'0',
		$elm$core$String$fromInt(t.ep)) + (':' + (A3(
		$elm$core$String$padLeft,
		2,
		'0',
		$elm$core$String$fromInt(t.ex)) + (':' + A3(
		$elm$core$String$padLeft,
		2,
		'0',
		$elm$core$String$fromInt(t.eX)))))))))));
};
var $author$project$Icons$lockClosed = $author$project$Icons$fa_icon('fas fa-lock');
var $author$project$Icons$lockOpen = $author$project$Icons$fa_icon('fas fa-lock-open');
var $elm$html$Html$Attributes$title = $elm$html$Html$Attributes$stringProperty('title');
var $author$project$Components$Vehicles$History$view_tasklistStateIndicator = function (tasklistStatus) {
	if (!tasklistStatus) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$htmlAttribute(
					$elm$html$Html$Attributes$title('Lomake on vielä avoin'))
				]),
			$author$project$Icons$lockOpen);
	} else {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$htmlAttribute(
					$elm$html$Html$Attributes$title('Lomake on jo suljettu'))
				]),
			$author$project$Icons$lockClosed);
	}
};
var $mdgriffith$elm_ui$Internal$Model$Padding = F5(
	function (a, b, c, d, e) {
		return {$: 0, a: a, b: b, c: c, d: d, e: e};
	});
var $mdgriffith$elm_ui$Internal$Model$Spaced = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $mdgriffith$elm_ui$Internal$Model$extractSpacingAndPadding = function (attrs) {
	return A3(
		$elm$core$List$foldr,
		F2(
			function (attr, _v0) {
				var pad = _v0.a;
				var spacing = _v0.b;
				return _Utils_Tuple2(
					function () {
						if (!pad.$) {
							var x = pad.a;
							return pad;
						} else {
							if ((attr.$ === 4) && (attr.b.$ === 7)) {
								var _v3 = attr.b;
								var name = _v3.a;
								var t = _v3.b;
								var r = _v3.c;
								var b = _v3.d;
								var l = _v3.e;
								return $elm$core$Maybe$Just(
									A5($mdgriffith$elm_ui$Internal$Model$Padding, name, t, r, b, l));
							} else {
								return $elm$core$Maybe$Nothing;
							}
						}
					}(),
					function () {
						if (!spacing.$) {
							var x = spacing.a;
							return spacing;
						} else {
							if ((attr.$ === 4) && (attr.b.$ === 5)) {
								var _v6 = attr.b;
								var name = _v6.a;
								var x = _v6.b;
								var y = _v6.c;
								return $elm$core$Maybe$Just(
									A3($mdgriffith$elm_ui$Internal$Model$Spaced, name, x, y));
							} else {
								return $elm$core$Maybe$Nothing;
							}
						}
					}());
			}),
		_Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing),
		attrs);
};
var $mdgriffith$elm_ui$Element$wrappedRow = F2(
	function (attrs, children) {
		var _v0 = $mdgriffith$elm_ui$Internal$Model$extractSpacingAndPadding(attrs);
		var padded = _v0.a;
		var spaced = _v0.b;
		if (spaced.$ === 1) {
			return A4(
				$mdgriffith$elm_ui$Internal$Model$element,
				$mdgriffith$elm_ui$Internal$Model$asRow,
				$mdgriffith$elm_ui$Internal$Model$div,
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.cQ + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.a_ + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.gF)))),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
							attrs))),
				$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
		} else {
			var _v2 = spaced.a;
			var spaceName = _v2.a;
			var x = _v2.b;
			var y = _v2.c;
			var newPadding = function () {
				if (!padded.$) {
					var _v5 = padded.a;
					var name = _v5.a;
					var t = _v5.b;
					var r = _v5.c;
					var b = _v5.d;
					var l = _v5.e;
					if ((_Utils_cmp(r, x / 2) > -1) && (_Utils_cmp(b, y / 2) > -1)) {
						var newTop = t - (y / 2);
						var newRight = r - (x / 2);
						var newLeft = l - (x / 2);
						var newBottom = b - (y / 2);
						return $elm$core$Maybe$Just(
							A2(
								$mdgriffith$elm_ui$Internal$Model$StyleClass,
								$mdgriffith$elm_ui$Internal$Flag$padding,
								A5(
									$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
									A4($mdgriffith$elm_ui$Internal$Model$paddingNameFloat, newTop, newRight, newBottom, newLeft),
									newTop,
									newRight,
									newBottom,
									newLeft)));
					} else {
						return $elm$core$Maybe$Nothing;
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}();
			if (!newPadding.$) {
				var pad = newPadding.a;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$element,
					$mdgriffith$elm_ui$Internal$Model$asRow,
					$mdgriffith$elm_ui$Internal$Model$div,
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.cQ + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.a_ + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.gF)))),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
							A2(
								$elm$core$List$cons,
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
								_Utils_ap(
									attrs,
									_List_fromArray(
										[pad]))))),
					$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
			} else {
				var halfY = -(y / 2);
				var halfX = -(x / 2);
				return A4(
					$mdgriffith$elm_ui$Internal$Model$element,
					$mdgriffith$elm_ui$Internal$Model$asEl,
					$mdgriffith$elm_ui$Internal$Model$div,
					attrs,
					$mdgriffith$elm_ui$Internal$Model$Unkeyed(
						_List_fromArray(
							[
								A4(
								$mdgriffith$elm_ui$Internal$Model$element,
								$mdgriffith$elm_ui$Internal$Model$asRow,
								$mdgriffith$elm_ui$Internal$Model$div,
								A2(
									$elm$core$List$cons,
									$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.cQ + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.a_ + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.gF)))),
									A2(
										$elm$core$List$cons,
										$mdgriffith$elm_ui$Internal$Model$Attr(
											A2(
												$elm$html$Html$Attributes$style,
												'margin',
												$elm$core$String$fromFloat(halfY) + ('px' + (' ' + ($elm$core$String$fromFloat(halfX) + 'px'))))),
										A2(
											$elm$core$List$cons,
											$mdgriffith$elm_ui$Internal$Model$Attr(
												A2(
													$elm$html$Html$Attributes$style,
													'width',
													'calc(100% + ' + ($elm$core$String$fromInt(x) + 'px)'))),
											A2(
												$elm$core$List$cons,
												$mdgriffith$elm_ui$Internal$Model$Attr(
													A2(
														$elm$html$Html$Attributes$style,
														'height',
														'calc(100% + ' + ($elm$core$String$fromInt(y) + 'px)'))),
												A2(
													$elm$core$List$cons,
													A2(
														$mdgriffith$elm_ui$Internal$Model$StyleClass,
														$mdgriffith$elm_ui$Internal$Flag$spacing,
														A3($mdgriffith$elm_ui$Internal$Model$SpacingStyle, spaceName, x, y)),
													_List_Nil))))),
								$mdgriffith$elm_ui$Internal$Model$Unkeyed(children))
							])));
			}
		}
	});
var $author$project$Components$Vehicles$History$columns = _List_fromArray(
	[
		{
		aH: 0,
		aR: A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.gh;
			},
			A2(
				$elm$core$Basics$composeR,
				$author$project$Helpers$orderNumberString,
				A2(
					$elm$core$Basics$composeR,
					$mdgriffith$elm_ui$Element$text,
					$mdgriffith$elm_ui$Element$el(
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$alignRight]))))),
		oI: '',
		fg: 20
	},
		{
		aH: 1,
		aR: A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.j4;
			},
			A2(
				$elm$core$Basics$composeR,
				$author$project$Types$IsoTime$toString,
				A2(
					$elm$core$Basics$composeR,
					$elm$core$String$dropRight(3),
					$mdgriffith$elm_ui$Element$text))),
		oI: 'Aika',
		fg: 120
	},
		{
		aH: 0,
		aR: A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.iA;
			},
			A2(
				$elm$core$Basics$composeR,
				$elm$core$List$map(
					function ($) {
						return $.ha;
					}),
				A2(
					$elm$core$Basics$composeR,
					$elm$core$List$sort,
					A2(
						$elm$core$Basics$composeR,
						$elm$core$List$map($mdgriffith$elm_ui$Element$text),
						$mdgriffith$elm_ui$Element$wrappedRow(
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$spacing(4)
								])))))),
		oI: 'Tuotteet',
		fg: 100
	},
		{
		aH: 0,
		aR: A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.kH;
			},
			A2(
				$elm$core$Basics$composeR,
				$mdgriffith$elm_ui$Element$text,
				A2(
					$elm$core$Basics$composeR,
					$mdgriffith$elm_ui$Element$el(
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$alignLeft])),
					A2(
						$elm$core$Basics$composeR,
						$elm$core$List$singleton,
						$mdgriffith$elm_ui$Element$paragraph(
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$spacing(0)
								])))))),
		oI: 'Kommentti',
		fg: 200
	},
		{
		aH: 0,
		aR: A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.jA;
			},
			A2(
				$elm$core$Basics$composeR,
				$elm$core$List$map(
					function ($) {
						return $.ha;
					}),
				A2(
					$elm$core$Basics$composeR,
					$elm$core$List$sort,
					A2(
						$elm$core$Basics$composeR,
						$elm$core$List$map($mdgriffith$elm_ui$Element$text),
						$mdgriffith$elm_ui$Element$wrappedRow(
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$spacing(4)
								])))))),
		oI: 'Tekijät',
		fg: 60
	},
		{
		aH: 2,
		aR: A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.iv;
			},
			A2(
				$elm$core$Basics$composeR,
				$author$project$Helpers$toEuroString,
				A2(
					$elm$core$Basics$composeR,
					$mdgriffith$elm_ui$Element$text,
					$mdgriffith$elm_ui$Element$el(
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$paddingEach(
								{g1: 0, h1: 0, iN: 8, jl: 0})
							]))))),
		oI: 'Hinta',
		fg: 60
	},
		{
		aH: 0,
		aR: A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.i3;
			},
			$mdgriffith$elm_ui$Element$text),
		oI: 'Toimipiste',
		fg: 120
	},
		{
		aH: 0,
		aR: A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.jf;
			},
			A2(
				$elm$core$Basics$composeR,
				$mdgriffith$elm_ui$Element$text,
				A2(
					$elm$core$Basics$composeR,
					$mdgriffith$elm_ui$Element$el(
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$alignLeft])),
					A2(
						$elm$core$Basics$composeR,
						$elm$core$List$singleton,
						$mdgriffith$elm_ui$Element$paragraph(_List_Nil))))),
		oI: 'Lomake',
		fg: 140
	},
		{
		aH: 0,
		aR: A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.jg;
			},
			A2(
				$elm$core$Basics$composeR,
				$author$project$Components$Vehicles$History$view_tasklistStateIndicator,
				$mdgriffith$elm_ui$Element$el(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$alignRight,
							A2($mdgriffith$elm_ui$Element$paddingXY, 4, 0)
						])))),
		oI: '',
		fg: 20
	}
	]);
var $author$project$Components$Vehicles$Table$rowPaddingX = 2;
var $author$project$Components$Vehicles$Table$tableWidth = F2(
	function (columns, columnSpacing) {
		var columnWidths = $elm$core$List$sum(
			A2(
				$elm$core$List$map,
				function ($) {
					return $.fg;
				},
				columns));
		var columnCount = $elm$core$List$length(columns);
		return (columnWidths + ((columnCount - 1) * columnSpacing)) + (2 * $author$project$Components$Vehicles$Table$rowPaddingX);
	});
var $author$project$Components$Vehicles$History$tableWidth = A2($author$project$Components$Vehicles$Table$tableWidth, $author$project$Components$Vehicles$History$columns, $author$project$Components$Vehicles$History$columnSpacing);
var $author$project$Components$Vehicles$Table$headerCell = function (tableColumn) {
	var labelAlignment = function () {
		var _v0 = tableColumn.aH;
		switch (_v0) {
			case 0:
				return $mdgriffith$elm_ui$Element$alignLeft;
			case 1:
				return $mdgriffith$elm_ui$Element$centerX;
			default:
				return $mdgriffith$elm_ui$Element$centerX;
		}
	}();
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width(
				$mdgriffith$elm_ui$Element$px(tableColumn.fg))
			]),
		A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[labelAlignment]),
			$mdgriffith$elm_ui$Element$text(tableColumn.oI)));
};
var $author$project$Styles$mediumFontSize = $mdgriffith$elm_ui$Element$Font$size($author$project$Styles$fontSizesInPixels.ml);
var $mdgriffith$elm_ui$Internal$Model$Top = 0;
var $mdgriffith$elm_ui$Element$alignTop = $mdgriffith$elm_ui$Internal$Model$AlignY(0);
var $author$project$Components$Vehicles$Table$rowCell = F2(
	function (entity, tableColumn) {
		var alignment = function () {
			var _v0 = tableColumn.aH;
			switch (_v0) {
				case 0:
					return $mdgriffith$elm_ui$Element$alignLeft;
				case 1:
					return $mdgriffith$elm_ui$Element$centerX;
				default:
					return $mdgriffith$elm_ui$Element$alignRight;
			}
		}();
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width(
					$mdgriffith$elm_ui$Element$px(tableColumn.fg)),
					$mdgriffith$elm_ui$Element$alignTop
				]),
			A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[alignment]),
					tableColumn.aR(entity))));
	});
var $author$project$Components$Button$setCustomAttributes = F2(
	function (attributes, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{b3: attributes});
	});
var $mdgriffith$elm_ui$Element$Border$widthXY = F2(
	function (x, y) {
		return A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$borderWidth,
			A5(
				$mdgriffith$elm_ui$Internal$Model$BorderWidth,
				'b-' + ($elm$core$String$fromInt(x) + ('-' + $elm$core$String$fromInt(y))),
				y,
				x,
				y,
				x));
	});
var $mdgriffith$elm_ui$Element$Border$widthEach = function (_v0) {
	var bottom = _v0.g1;
	var top = _v0.jl;
	var left = _v0.h1;
	var right = _v0.iN;
	return (_Utils_eq(top, bottom) && _Utils_eq(left, right)) ? (_Utils_eq(top, right) ? $mdgriffith$elm_ui$Element$Border$width(top) : A2($mdgriffith$elm_ui$Element$Border$widthXY, left, top)) : A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$borderWidth,
		A5(
			$mdgriffith$elm_ui$Internal$Model$BorderWidth,
			'b-' + ($elm$core$String$fromInt(top) + ('-' + ($elm$core$String$fromInt(right) + ('-' + ($elm$core$String$fromInt(bottom) + ('-' + $elm$core$String$fromInt(left))))))),
			top,
			right,
			bottom,
			left));
};
var $author$project$Components$Vehicles$Table$tableRow = F2(
	function (table, a) {
		var rowContents = A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$Border$widthEach(
					{g1: 1, h1: 0, iN: 0, jl: 0}),
					$mdgriffith$elm_ui$Element$Border$color(
					$author$project$Colors$onSurface(2)),
					$author$project$Styles$mediumFontSize,
					A2($mdgriffith$elm_ui$Element$paddingXY, $author$project$Components$Vehicles$Table$rowPaddingX, 3),
					$mdgriffith$elm_ui$Element$spacing(table.kG)
				]),
			A2(
				$elm$core$List$map,
				$author$project$Components$Vehicles$Table$rowCell(a),
				table.cP));
		var _v0 = A2(
			$elm$core$Maybe$withDefault,
			_Utils_Tuple2(false, $mdgriffith$elm_ui$Element$none),
			A2(
				$elm$core$Maybe$map,
				function (expandedRow) {
					return A2(table.nN, a, expandedRow.ll) ? _Utils_Tuple2(true, expandedRow.d3) : _Utils_Tuple2(false, $mdgriffith$elm_ui$Element$none);
				},
				table.lp));
		var isExpandedRow = _v0.a;
		var expandedRowContents = _v0.b;
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			_List_fromArray(
				[
					function () {
					var _v1 = table.m4;
					if (!_v1.$) {
						var onRowClick = _v1.a;
						return $author$project$Components$Button$toElement(
							A2(
								$author$project$Components$Button$setCustomAttributes,
								_Utils_ap(
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$Font$color(
											$author$project$Colors$onSurface(9)),
											A2($mdgriffith$elm_ui$Element$paddingXY, 0, 2)
										]),
									$author$project$Helpers$attributeIf(
										_List_fromArray(
											[
												_Utils_Tuple2(
												isExpandedRow,
												$mdgriffith$elm_ui$Element$Background$color(
													$author$project$Colors$surface(3)))
											]))),
								A2(
									$author$project$Components$Button$icon,
									rowContents,
									A2(
										$author$project$Components$Button$width,
										$mdgriffith$elm_ui$Element$px(
											A2($author$project$Components$Vehicles$Table$tableWidth, table.cP, table.kG)),
										A2(
											$author$project$Components$Button$onClick,
											onRowClick(a),
											$author$project$Components$Button$button)))));
					} else {
						return rowContents;
					}
				}(),
					expandedRowContents
				]));
	});
var $author$project$Components$Vehicles$Table$render = function (table) {
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$spacing(2)
			]),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Border$widthEach(
						{g1: 2, h1: 0, iN: 0, jl: 0}),
						$author$project$Styles$mediumFontSize,
						$mdgriffith$elm_ui$Element$Font$bold,
						$mdgriffith$elm_ui$Element$paddingEach(
						{g1: 2, h1: 0, iN: 0, jl: 0}),
						$mdgriffith$elm_ui$Element$spacing(table.kG)
					]),
				A2($elm$core$List$map, $author$project$Components$Vehicles$Table$headerCell, table.cP)),
				A2(
				$mdgriffith$elm_ui$Element$column,
				_Utils_ap(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$spacing(2)
						]),
					$author$project$Helpers$attributeIf(
						_List_fromArray(
							[
								_Utils_Tuple2(table.l2, $mdgriffith$elm_ui$Element$scrollbarY),
								_Utils_Tuple2(table.l3, $mdgriffith$elm_ui$Element$scrollbarY)
							]))),
				A2(
					$elm$core$List$map,
					$author$project$Components$Vehicles$Table$tableRow(table),
					table.n9))
			]));
};
var $author$project$Components$Vehicles$History$view = function (jobs) {
	var jobTableConfig = {
		kG: $author$project$Components$Vehicles$History$columnSpacing,
		cP: $author$project$Components$Vehicles$History$columns,
		lp: $elm$core$Maybe$Nothing,
		l2: false,
		l3: false,
		m4: $elm$core$Maybe$Nothing,
		nN: F2(
			function (_v0, _v1) {
				return false;
			}),
		n9: $elm$core$List$reverse(
			A2(
				$elm$core$List$sortBy,
				function ($) {
					return $.gh;
				},
				jobs))
	};
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width(
				$mdgriffith$elm_ui$Element$px($author$project$Components$Vehicles$History$tableWidth)),
				$mdgriffith$elm_ui$Element$Background$color(
				$author$project$Colors$surface(2)),
				$mdgriffith$elm_ui$Element$Font$color(
				$author$project$Colors$onSurface(7)),
				$mdgriffith$elm_ui$Element$spacing(12)
			]),
		_List_fromArray(
			[
				$author$project$Components$Vehicles$Table$render(jobTableConfig)
			]));
};
var $author$project$Icons$elementCollapsed = $author$project$Icons$fa_icon('fas fa-caret-right');
var $author$project$Icons$elementExpanded = $author$project$Icons$fa_icon('fas fa-caret-down');
var $author$project$Components$Vehicles$List$view = F3(
	function (vehicles, maybeVehicleInfo, onRowClick) {
		var tableWidth = $author$project$Components$Vehicles$History$tableWidth;
		var rowIdentityFunction = F2(
			function (a, b) {
				return _Utils_eq(a.de, b.de);
			});
		var maybeExpandedVehicle = A2($elm$core$Maybe$map, $elm$core$Tuple$first, maybeVehicleInfo);
		var columnSpacing = 8;
		var caretColumnWidth = 20;
		var dataColumnWidth = (((tableWidth - caretColumnWidth) / 3) | 0) - columnSpacing;
		var vehicleTableConfig = {
			kG: columnSpacing,
			cP: _List_fromArray(
				[
					{
					aH: 0,
					aR: function (rowVehicle) {
						return A2(
							$elm$core$Maybe$withDefault,
							$author$project$Icons$elementCollapsed,
							A2(
								$elm$core$Maybe$map,
								function (isExpandedRow) {
									return isExpandedRow ? $author$project$Icons$elementExpanded : $author$project$Icons$elementCollapsed;
								},
								A2(
									$elm$core$Maybe$map,
									A2(
										$elm$core$Basics$composeR,
										function ($) {
											return $.de;
										},
										$elm$core$Basics$eq(rowVehicle.de)),
									maybeExpandedVehicle)));
					},
					oI: '',
					fg: caretColumnWidth
				},
					{
					aH: 0,
					aR: A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.de;
						},
						A2($elm$core$Basics$composeR, $author$project$Types$Vehicle$LicenseNumber$toString, $mdgriffith$elm_ui$Element$text)),
					oI: 'Rekisterinro.',
					fg: dataColumnWidth
				},
					{
					aH: 0,
					aR: A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.mi;
						},
						$mdgriffith$elm_ui$Element$text),
					oI: 'Merkki',
					fg: dataColumnWidth
				},
					{
					aH: 0,
					aR: A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.mv;
						},
						$mdgriffith$elm_ui$Element$text),
					oI: 'Malli',
					fg: dataColumnWidth
				}
				]),
			lp: A2(
				$elm$core$Maybe$map,
				function (vehicleInfo) {
					return {d3: vehicleInfo.b, ll: vehicleInfo.a};
				},
				maybeVehicleInfo),
			l2: false,
			l3: true,
			m4: $elm$core$Maybe$Just(onRowClick),
			nN: rowIdentityFunction,
			n9: vehicles
		};
		return $author$project$Components$Vehicles$Table$render(vehicleTableConfig);
	});
var $author$project$Styles$xxlFontSize = $mdgriffith$elm_ui$Element$Font$size($author$project$Styles$fontSizesInPixels.o7);
var $author$project$Components$Vehicles$Search$view_vehiclesDialog = F3(
	function (searchTerm, vehicles, vehicleHistoryState) {
		var tableLabel = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$Font$bold, $author$project$Styles$xxlFontSize]),
			$mdgriffith$elm_ui$Element$text(
				'Ajoneuvot haulle \'' + ($author$project$Components$Vehicles$SearchTerm$toString(searchTerm) + ('\'' + (' (' + ($elm$core$String$fromInt(
					$elm$core$List$length(vehicles)) + ' kpl)'))))));
		var displayedVehicleHistory = function () {
			switch (vehicleHistoryState.$) {
				case 0:
					return $elm$core$Maybe$Nothing;
				case 1:
					return $elm$core$Maybe$Nothing;
				case 3:
					return $elm$core$Maybe$Nothing;
				default:
					var vehicleHistorySearchResult = vehicleHistoryState.a;
					if (!vehicleHistorySearchResult.$) {
						var licenseNumber = vehicleHistorySearchResult.a;
						return $elm$core$Maybe$Just(
							_Utils_Tuple2(
								{de: licenseNumber, mi: '', mv: ''},
								$mdgriffith$elm_ui$Element$text('Ajoneuvolle ei löytynyt töitä')));
					} else {
						var vehicle = vehicleHistorySearchResult.a;
						var vehicleHistoryJobs = vehicleHistorySearchResult.b;
						return $elm$core$Maybe$Just(
							_Utils_Tuple2(
								vehicle,
								$author$project$Components$Vehicles$History$view(vehicleHistoryJobs)));
					}
			}
		}();
		var closeButton = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$alignRight,
					$mdgriffith$elm_ui$Element$Font$color(
					$author$project$Colors$onSurface(5))
				]),
			$author$project$Components$CloseButton$closeButton($author$project$Components$Vehicles$Search$HideVehicleListDialog));
		return A2(
			$author$project$Components$Modal$modal,
			{kz: true, mR: $author$project$Components$Vehicles$Search$HideVehicleListDialog},
			A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Background$color(
						$author$project$Colors$onSurface(2)),
						$mdgriffith$elm_ui$Element$width(
						$mdgriffith$elm_ui$Element$px($author$project$Components$Vehicles$History$tableWidth + 55)),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						A2($elm$html$Html$Attributes$style, 'height', '95vh')),
						$mdgriffith$elm_ui$Element$padding(10),
						$mdgriffith$elm_ui$Element$spacing(4)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						_List_fromArray(
							[tableLabel, closeButton])),
						A3($author$project$Components$Vehicles$List$view, vehicles, displayedVehicleHistory, $author$project$Components$Vehicles$Search$VehicleRowClicked)
					])));
	});
var $author$project$Components$Vehicles$Search$view = function (model) {
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$inFront(
				function () {
					var _v0 = model._;
					switch (_v0.$) {
						case 0:
							return $mdgriffith$elm_ui$Element$none;
						case 1:
							return $mdgriffith$elm_ui$Element$none;
						default:
							var searchTerm = _v0.a;
							var vehicles = _v0.b;
							var vehicleHistoryState = _v0.c;
							return A3($author$project$Components$Vehicles$Search$view_vehiclesDialog, searchTerm, vehicles, vehicleHistoryState);
					}
				}())
			]),
		_List_fromArray(
			[
				$author$project$Components$Vehicles$Search$view_searchBar(model)
			]));
};
var $mdgriffith$elm_ui$Internal$Model$ImportFont = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $mdgriffith$elm_ui$Element$Font$external = function (_v0) {
	var url = _v0.oU;
	var name = _v0.mD;
	return A2($mdgriffith$elm_ui$Internal$Model$ImportFont, name, url);
};
var $mdgriffith$elm_ui$Element$Font$family = function (families) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$fontFamily,
		A2(
			$mdgriffith$elm_ui$Internal$Model$FontFamily,
			A3($elm$core$List$foldl, $mdgriffith$elm_ui$Internal$Model$renderFontClassName, 'ff-', families),
			families));
};
var $author$project$Types$Page$getAllowedLinks = function (user) {
	switch (user.$) {
		case 0:
			return _List_fromArray(
				[1, 2, 4]);
		case 1:
			return _List_fromArray(
				[1, 2, 4]);
		case 2:
			return _List_fromArray(
				[2, 4]);
		default:
			var siteUser = user.a;
			switch (siteUser.$) {
				case 2:
					return _List_fromArray(
						[3, 2, 0]);
				case 0:
					return _List_fromArray(
						[3, 2, 4, 0]);
				default:
					return _List_fromArray(
						[3, 0]);
			}
	}
};
var $mdgriffith$elm_ui$Element$Font$sansSerif = $mdgriffith$elm_ui$Internal$Model$SansSerif;
var $author$project$Components$TopMenu$NavigationLinkClicked = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Button$fontColor = F2(
	function (color, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{
				c7: $elm$core$Maybe$Just(color),
				dA: $elm$core$Maybe$Just(color)
			});
	});
var $author$project$Components$TopMenu$view_pageLinkButton = F2(
	function (pageLink, isSelected) {
		var textColor = isSelected ? $author$project$Colors$primary(0) : $author$project$Colors$surface(5);
		return $author$project$Components$Button$toElement(
			A2(
				$author$project$Components$Button$fontColor,
				textColor,
				A2(
					$author$project$Components$Button$textSize,
					$author$project$Styles$fontSizesInPixels.o7,
					A2(
						$author$project$Components$Button$text,
						$author$project$Types$Page$getTitle(pageLink),
						A2(
							$author$project$Components$Button$onClick,
							$author$project$Components$TopMenu$NavigationLinkClicked(pageLink),
							$author$project$Components$Button$button)))));
	});
var $author$project$Components$TopMenu$view_navigation = F2(
	function (selectedUser, currentPage) {
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$centerX,
					$mdgriffith$elm_ui$Element$centerY,
					$author$project$Styles$xxlFontSize,
					$mdgriffith$elm_ui$Element$Font$bold,
					$mdgriffith$elm_ui$Element$Font$color(
					$author$project$Colors$primary(0)),
					$mdgriffith$elm_ui$Element$Font$family(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$external(
							{mD: 'Roboto', oU: 'https://fonts.googleapis.com/css?family=Roboto'}),
							$mdgriffith$elm_ui$Element$Font$sansSerif
						]))
				]),
			A2(
				$elm$core$List$map,
				function (pageLink) {
					var isSelected = function () {
						switch (currentPage.$) {
							case 0:
								return !pageLink;
							case 1:
								return pageLink === 1;
							case 2:
								return pageLink === 2;
							case 3:
								return pageLink === 3;
							default:
								return pageLink === 4;
						}
					}();
					return A2($author$project$Components$TopMenu$view_pageLinkButton, pageLink, isSelected);
				},
				$author$project$Types$Page$getAllowedLinks(selectedUser)));
	});
var $author$project$Components$TopMenu$view = F4(
	function (appContext, user, currentPage, model) {
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$paddingEach(
					{g1: 2, h1: 5, iN: 5, jl: 8}),
					$mdgriffith$elm_ui$Element$spacing(20)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$image,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$paddingEach(
							{g1: 0, h1: 5, iN: 0, jl: 0}),
							$mdgriffith$elm_ui$Element$centerY
						]),
					{le: 'AutoDent logo', oc: 'TekstiLogo.svg'}),
					A2($author$project$Components$TopMenu$view_navigation, user, currentPage),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$alignRight]),
					A2(
						$mdgriffith$elm_ui$Element$map,
						$author$project$Components$TopMenu$GotVehicleSearchMsg,
						$author$project$Components$Vehicles$Search$view(model.dL))),
					A2(
					$mdgriffith$elm_ui$Element$map,
					$author$project$Components$TopMenu$GotUserMenuMsg,
					A3($author$project$Components$TopMenu$UserMenu$view, appContext, user, model.cF))
				]));
	});
var $author$project$Components$UserNavigator$Msg_Error = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$UserNavigator$view_Error = function (_v0) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Background$color($author$project$Colors$warning),
				$mdgriffith$elm_ui$Element$padding(6)
			]),
		$mdgriffith$elm_ui$Element$text('Virhe ladattaessa käyttäjiä'));
};
var $author$project$Components$UserNavigator$Exit = {$: 4};
var $author$project$Components$Box$Box = $elm$core$Basics$identity;
var $author$project$Components$Box$defaultOptions = {dZ: $elm$core$Maybe$Nothing, d3: $mdgriffith$elm_ui$Element$none, en: $elm$core$Maybe$Nothing, fg: $elm$core$Maybe$Nothing};
var $author$project$Components$Box$box = function (contents) {
	return _Utils_update(
		$author$project$Components$Box$defaultOptions,
		{d3: contents});
};
var $author$project$Components$Button$iconColor = F2(
	function (color, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{
				c7: $elm$core$Maybe$Just(color)
			});
	});
var $author$project$Components$Box$onClose = F2(
	function (msg, _v0) {
		var options = _v0;
		var closeButton = $author$project$Components$Button$toElement(
			$author$project$Components$Button$secondary(
				A2(
					$author$project$Components$Button$iconColor,
					$author$project$Colors$surface(4),
					A2(
						$author$project$Components$Button$icon,
						$author$project$Icons$close,
						A2($author$project$Components$Button$onClick, msg, $author$project$Components$Button$button)))));
		return _Utils_update(
			options,
			{
				dZ: $elm$core$Maybe$Just(closeButton)
			});
	});
var $author$project$Components$Box$toElement = function (_v0) {
	var options = _v0;
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_Utils_ap(
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Background$color(
					$author$project$Colors$surface(2)),
					$mdgriffith$elm_ui$Element$Border$color(
					$author$project$Colors$surface(3)),
					$mdgriffith$elm_ui$Element$Border$width(2),
					$mdgriffith$elm_ui$Element$Border$rounded(6),
					$mdgriffith$elm_ui$Element$padding(4),
					$mdgriffith$elm_ui$Element$spacing(4)
				]),
			_Utils_ap(
				$author$project$Helpers$attributeIfExists(
					_List_fromArray(
						[
							_Utils_Tuple2(options.en, $mdgriffith$elm_ui$Element$height),
							_Utils_Tuple2(options.fg, $mdgriffith$elm_ui$Element$width)
						])),
				$author$project$Helpers$attributeIfExists(
					_List_fromArray(
						[
							_Utils_Tuple2(
							A2(
								$elm$core$Maybe$map,
								$mdgriffith$elm_ui$Element$el(
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$alignRight])),
								options.dZ),
							$mdgriffith$elm_ui$Element$inFront)
						])))),
		options.d3);
};
var $author$project$Components$UserNavigator$isUserInNavigationGroup = F2(
	function (user, navigationGroup) {
		var usersInNavigationGroup = function () {
			switch (navigationGroup.$) {
				case 0:
					var washSites = navigationGroup.a;
					return A2(
						$elm$core$List$concatMap,
						function ($) {
							return $.S;
						},
						washSites);
				case 1:
					var mobileSites = navigationGroup.a;
					return A2(
						$elm$core$List$concatMap,
						function ($) {
							return $.S;
						},
						mobileSites);
				default:
					var users = navigationGroup.a;
					return users;
			}
		}();
		return A2($elm$core$List$member, user, usersInNavigationGroup);
	});
var $mdgriffith$elm_ui$Element$transparent = function (on) {
	return on ? A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$transparency,
		A2($mdgriffith$elm_ui$Internal$Model$Transparency, 'transparent', 1.0)) : A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$transparency,
		A2($mdgriffith$elm_ui$Internal$Model$Transparency, 'visible', 0.0));
};
var $author$project$Components$Box$height = F2(
	function (value, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{
				en: $elm$core$Maybe$Just(value)
			});
	});
var $author$project$Components$UserNavigator$SelectUser = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Button$ToggleButton = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Button$toggleButton = F2(
	function (msg, isSelected) {
		return _Utils_update(
			$author$project$Components$Button$defaultOptions,
			{
				cj: $elm$core$Maybe$Just(msg),
				cw: $author$project$Components$Button$ToggleButton(isSelected)
			});
	});
var $author$project$Components$UserNavigator$v_userNameButton = F2(
	function (isSelected, user) {
		return $author$project$Components$Button$toElement(
			$author$project$Components$Button$primary(
				A2(
					$author$project$Components$Button$textSize,
					$author$project$Styles$fontSizesInPixels.mc,
					A2(
						$author$project$Components$Button$text,
						$author$project$Types$User$fullName(user),
						A2(
							$author$project$Components$Button$toggleButton,
							$author$project$Components$UserNavigator$SelectUser(user),
							isSelected)))));
	});
var $author$project$Components$UserNavigator$v_selectNonSiteUserButton = F2(
	function (selectedUser, thisUser) {
		var isSelected = _Utils_eq(thisUser, selectedUser);
		return A2($author$project$Components$UserNavigator$v_userNameButton, isSelected, thisUser);
	});
var $author$project$Types$User$isSiteWorkerUser = function (user) {
	if ((user.$ === 3) && (user.a.$ === 1)) {
		var _v1 = user.a;
		return true;
	} else {
		return false;
	}
};
var $author$project$Icons$manager = $author$project$Icons$fa_icon('fas fa-user-tie');
var $author$project$Icons$site = $author$project$Icons$fa_icon('fas fa-store-alt');
var $author$project$Components$Button$iconSize = F2(
	function (size, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{c8: size});
	});
var $author$project$Components$UserNavigator$v_userIconButton = F4(
	function (isSelected, user, icon, tooltip) {
		return $author$project$Components$Button$toElement(
			$author$project$Components$Button$primary(
				A2(
					$author$project$Components$Button$tooltip,
					tooltip,
					A2(
						$author$project$Components$Button$iconSize,
						$author$project$Styles$fontSizesInPixels.mc,
						A2(
							$author$project$Components$Button$icon,
							icon,
							A2(
								$author$project$Components$Button$toggleButton,
								$author$project$Components$UserNavigator$SelectUser(user),
								isSelected))))));
	});
var $author$project$Components$UserNavigator$v_selectSiteUserButton = F3(
	function (site, selectedUser, thisUser) {
		var isSelected = _Utils_eq(thisUser, selectedUser);
		var _v0 = site.eZ;
		if (!_v0.$) {
			var siteHasWorkerAndManager = A3(
				$elm$core$Basics$composeL,
				$elm$core$Basics$not,
				$elm$core$List$isEmpty,
				A2($elm$core$List$filter, $author$project$Types$User$isSiteWorkerUser, site.S)) && A3(
				$elm$core$Basics$composeL,
				$elm$core$Basics$not,
				$elm$core$List$isEmpty,
				A2($elm$core$List$filter, $author$project$Types$User$isSiteManagerUser, site.S));
			var _v1 = function () {
				var _v2 = _Utils_Tuple2(
					siteHasWorkerAndManager,
					$author$project$Types$User$isSiteManagerUser(thisUser));
				if (!_v2.a) {
					return _Utils_Tuple2($author$project$Icons$site, 'Työjohto & työntekijät');
				} else {
					if (_v2.b) {
						return _Utils_Tuple2($author$project$Icons$manager, 'Työjohto');
					} else {
						return _Utils_Tuple2($author$project$Icons$workers, 'Työntekijät');
					}
				}
			}();
			var icon = _v1.a;
			var tooltip = _v1.b;
			return A4($author$project$Components$UserNavigator$v_userIconButton, isSelected, thisUser, icon, tooltip);
		} else {
			return A2($author$project$Components$UserNavigator$v_userNameButton, isSelected, thisUser);
		}
	});
var $author$project$Components$Badge$baseStyles = _List_fromArray(
	[
		$mdgriffith$elm_ui$Element$paddingEach(
		{g1: 3, h1: 5, iN: 5, jl: 4}),
		$mdgriffith$elm_ui$Element$Border$width(1),
		$mdgriffith$elm_ui$Element$Border$rounded(12),
		$mdgriffith$elm_ui$Element$Background$color(
		$author$project$Colors$surface(3)),
		$mdgriffith$elm_ui$Element$Font$color(
		$author$project$Colors$onSurface(7))
	]);
var $author$project$Components$Badge$custom = F2(
	function (customStyles, contents) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_Utils_ap($author$project$Components$Badge$baseStyles, customStyles),
			contents);
	});
var $author$project$Styles$smallFontSize = $mdgriffith$elm_ui$Element$Font$size($author$project$Styles$fontSizesInPixels.i5);
var $author$project$Pages$Tasklist$openTasklistsBadge = function (count) {
	return A2(
		$author$project$Components$Badge$custom,
		_List_fromArray(
			[
				$author$project$Styles$smallFontSize,
				$mdgriffith$elm_ui$Element$paddingEach(
				{g1: 3, h1: 4, iN: 5, jl: 3}),
				$mdgriffith$elm_ui$Element$Background$color(
				$author$project$Colors$primary(1))
			]),
		$mdgriffith$elm_ui$Element$text(
			$elm$core$String$fromInt(count)));
};
var $author$project$Helpers$showIf = F2(
	function (bool, showThis) {
		return bool ? showThis : $mdgriffith$elm_ui$Element$none;
	});
var $author$project$Components$UserNavigator$v_washSiteToggleButtons = F3(
	function (selectedUser, openTasklistCount, washSite) {
		var siteUserButtons = A2(
			$elm$core$List$map,
			A2($author$project$Components$UserNavigator$v_selectSiteUserButton, washSite, selectedUser),
			$author$project$Components$UserNavigator$sortWashSiteUsers(washSite.S));
		var siteLabel = A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Font$size($author$project$Styles$fontSizesInPixels.mc),
					$mdgriffith$elm_ui$Element$spacing(4),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$text(washSite.dv.mD),
					A2(
					$author$project$Helpers$showIf,
					openTasklistCount > 0,
					A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$alignRight]),
						$author$project$Pages$Tasklist$openTasklistsBadge(openTasklistCount)))
				]));
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$alignTop,
					$mdgriffith$elm_ui$Element$spacing(4)
				]),
			_List_fromArray(
				[
					siteLabel,
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$centerX,
							$mdgriffith$elm_ui$Element$spacing(4)
						]),
					siteUserButtons)
				]));
	});
var $author$project$Components$UserNavigator$v_userSelectButtonsForNavigationGroup = F3(
	function (selectedUser, activeTasklistCountsBySite, navigationGroup) {
		switch (navigationGroup.$) {
			case 0:
				var washSites = navigationGroup.a;
				return $elm$core$List$singleton(
					A2(
						$mdgriffith$elm_ui$Element$wrappedRow,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$spacing(4)
							]),
						A2(
							$elm$core$List$map,
							A2(
								$elm$core$Basics$composeR,
								$author$project$Components$Box$box,
								A2(
									$elm$core$Basics$composeR,
									$author$project$Components$Box$height($mdgriffith$elm_ui$Element$fill),
									$author$project$Components$Box$toElement)),
							A2(
								$elm$core$List$map,
								function (siteData) {
									var activeTasklistCount = A2(
										$elm$core$Maybe$withDefault,
										0,
										A2(
											$elm$core$Dict$get,
											$author$project$Types$Site$id(siteData.eZ),
											activeTasklistCountsBySite));
									return A3($author$project$Components$UserNavigator$v_washSiteToggleButtons, selectedUser, activeTasklistCount, siteData);
								},
								A2(
									$elm$core$List$sortBy,
									A2(
										$elm$core$Basics$composeR,
										function ($) {
											return $.dv;
										},
										function ($) {
											return $.mD;
										}),
									washSites)))));
			case 1:
				var mobileSites = navigationGroup.a;
				return $elm$core$List$concat(
					A2(
						$elm$core$List$map,
						function (mobileSite) {
							return A2(
								$elm$core$List$map,
								A2($author$project$Components$UserNavigator$v_selectSiteUserButton, mobileSite, selectedUser),
								mobileSite.S);
						},
						A2(
							$elm$core$List$sortBy,
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.dv;
								},
								function ($) {
									return $.mD;
								}),
							mobileSites)));
			default:
				var usersWithoutSite = navigationGroup.a;
				return A2(
					$elm$core$List$map,
					$author$project$Components$UserNavigator$v_selectNonSiteUserButton(selectedUser),
					usersWithoutSite);
		}
	});
var $author$project$Components$UserNavigator$v_navigationGroup = F4(
	function (selectedUser, activeTasklistCountsBySite, navigationGroup, isVisible) {
		return A2(
			$mdgriffith$elm_ui$Element$wrappedRow,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$Font$color(
						$author$project$Colors$onSurface(5))
					]),
				$author$project$Helpers$attributeIf(
					_List_fromArray(
						[
							_Utils_Tuple2(
							!isVisible,
							$mdgriffith$elm_ui$Element$height(
								$mdgriffith$elm_ui$Element$px(0))),
							_Utils_Tuple2(
							!isVisible,
							$mdgriffith$elm_ui$Element$transparent(true)),
							_Utils_Tuple2(
							!isVisible,
							$mdgriffith$elm_ui$Element$htmlAttribute(
								A2($elm$html$Html$Attributes$style, 'pointer-events', 'none')))
						]))),
			A3($author$project$Components$UserNavigator$v_userSelectButtonsForNavigationGroup, selectedUser, activeTasklistCountsBySite, navigationGroup));
	});
var $author$project$Components$UserNavigator$SelectNavigationGroup = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$UserNavigator$v_navigationGroupToggleButton = F3(
	function (selectedUser, thisGroup, buttonLabel) {
		var isGroupSelected = A2($author$project$Components$UserNavigator$isUserInNavigationGroup, selectedUser, thisGroup);
		return $author$project$Components$Button$toElement(
			$author$project$Components$Button$primary(
				A2(
					$author$project$Components$Button$textSize,
					$author$project$Styles$fontSizesInPixels.mc,
					A2(
						$author$project$Components$Button$text,
						buttonLabel,
						A2(
							$author$project$Components$Button$toggleButton,
							$author$project$Components$UserNavigator$SelectNavigationGroup(thisGroup),
							isGroupSelected)))));
	});
var $author$project$Components$UserNavigator$v_navigation = F3(
	function (selectedUser, activeTasklistCountsBySite, navigation) {
		var groupRows = A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			A2(
				$elm$core$List$map,
				function (navigationGroup) {
					var isVisible = A2($author$project$Components$UserNavigator$isUserInNavigationGroup, selectedUser, navigationGroup);
					return A4($author$project$Components$UserNavigator$v_navigationGroup, selectedUser, activeTasklistCountsBySite, navigationGroup, isVisible);
				},
				_List_fromArray(
					[navigation.dN, navigation.ey, navigation.fb])));
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$spacing(4)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$wrappedRow,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$Font$color(
							$author$project$Colors$onSurface(5)),
							$mdgriffith$elm_ui$Element$spacing(4)
						]),
					_List_fromArray(
						[
							A3($author$project$Components$UserNavigator$v_navigationGroupToggleButton, selectedUser, navigation.dN, 'PESUPISTEET'),
							A3($author$project$Components$UserNavigator$v_navigationGroupToggleButton, selectedUser, navigation.ey, 'LIIKKUVAT'),
							A3($author$project$Components$UserNavigator$v_navigationGroupToggleButton, selectedUser, navigation.fb, 'EI TOIMIPISTETTÄ')
						])),
					groupRows
				]));
	});
var $author$project$Components$UserNavigator$view_Loaded = F2(
	function (selectedUser, loadedModel) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$centerX,
					$mdgriffith$elm_ui$Element$padding(6)
				]),
			$author$project$Components$Box$toElement(
				A2(
					$author$project$Components$Box$onClose,
					$author$project$Components$UserNavigator$Exit,
					$author$project$Components$Box$box(
						A3($author$project$Components$UserNavigator$v_navigation, selectedUser, loadedModel.aG, loadedModel.eB)))));
	});
var $author$project$Components$UserNavigator$view_Loading = function (_v0) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Font$color(
				$author$project$Colors$onSurface(5)),
				$mdgriffith$elm_ui$Element$padding(6)
			]),
		$mdgriffith$elm_ui$Element$text('Ladataan käyttäjiä...'));
};
var $author$project$Components$UserNavigator$view = F2(
	function (user, model) {
		var _v0 = model._;
		switch (_v0.$) {
			case 0:
				var loadingModel = _v0.a;
				return A2(
					$mdgriffith$elm_ui$Element$map,
					$author$project$Components$UserNavigator$Msg_Loading,
					$author$project$Components$UserNavigator$view_Loading(loadingModel));
			case 1:
				var loadedModel = _v0.a;
				return A2(
					$mdgriffith$elm_ui$Element$map,
					$author$project$Components$UserNavigator$Msg_Loaded,
					A2($author$project$Components$UserNavigator$view_Loaded, user, loadedModel));
			default:
				var errorModel = _v0.a;
				return A2(
					$mdgriffith$elm_ui$Element$map,
					$author$project$Components$UserNavigator$Msg_Error,
					$author$project$Components$UserNavigator$view_Error(errorModel));
		}
	});
var $author$project$Pages$Holidays$State_Loaded$MouseLeftWeekColumns = {$: 8};
var $author$project$Colors$background = A3($mdgriffith$elm_ui$Element$rgb255, 48, 48, 48);
var $mdgriffith$elm_ui$Element$Font$italic = $mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.l5);
var $author$project$Colors$onBackground = A3($mdgriffith$elm_ui$Element$rgb255, 255, 255, 255);
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$defaultOptions = {iu: true, ja: false};
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$Event = F5(
	function (pointerType, pointer, pointerId, isPrimary, contactDetails) {
		return {kJ: contactDetails, l1: isPrimary, nu: pointer, nw: pointerId, nx: pointerType};
	});
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$ContactDetails = F5(
	function (width, height, pressure, tiltX, tiltY) {
		return {en: height, nA: pressure, oF: tiltX, oG: tiltY, fg: width};
	});
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$contactDetailsDecoder = A6(
	$elm$json$Json$Decode$map5,
	$mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$ContactDetails,
	A2($elm$json$Json$Decode$field, 'width', $elm$json$Json$Decode$float),
	A2($elm$json$Json$Decode$field, 'height', $elm$json$Json$Decode$float),
	A2($elm$json$Json$Decode$field, 'pressure', $elm$json$Json$Decode$float),
	A2($elm$json$Json$Decode$field, 'tiltX', $elm$json$Json$Decode$float),
	A2($elm$json$Json$Decode$field, 'tiltY', $elm$json$Json$Decode$float));
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$MouseType = 0;
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$PenType = 2;
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$TouchType = 1;
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$stringToPointerType = function (str) {
	switch (str) {
		case 'pen':
			return 2;
		case 'touch':
			return 1;
		default:
			return 0;
	}
};
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$pointerTypeDecoder = A2($elm$json$Json$Decode$map, $mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$stringToPointerType, $elm$json$Json$Decode$string);
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$eventDecoder = A6(
	$elm$json$Json$Decode$map5,
	$mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$Event,
	A2($elm$json$Json$Decode$field, 'pointerType', $mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$pointerTypeDecoder),
	$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$eventDecoder,
	A2($elm$json$Json$Decode$field, 'pointerId', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'isPrimary', $elm$json$Json$Decode$bool),
	$mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$contactDetailsDecoder);
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$onWithOptions = F3(
	function (event, options, tag) {
		return A2(
			$elm$html$Html$Events$custom,
			event,
			A2(
				$elm$json$Json$Decode$map,
				function (ev) {
					return {
						mp: tag(ev),
						iu: options.iu,
						ja: options.ja
					};
				},
				$mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$eventDecoder));
	});
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$onLeave = A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$onWithOptions, 'pointerleave', $mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$defaultOptions);
var $author$project$Pages$Holidays$State_Loaded$DismissPinDialog = {$: 11};
var $author$project$Pages$Holidays$State_Loaded$Pin_BackSpaceKeyPressed = function (a) {
	return {$: 9, a: a};
};
var $author$project$Pages$Holidays$State_Loaded$Pin_NumberKeyPressed = F2(
	function (a, b) {
		return {$: 10, a: a, b: b};
	});
var $author$project$KeyboardHelper$htmlEventToKeyCode = function (handler) {
	return A2($elm$json$Json$Decode$map, handler, $elm$html$Html$Events$keyCode);
};
var $author$project$KeyboardHelper$isArrowKey = function (keyCode) {
	return function (key) {
		return (!key) || ((key === 1) || ((key === 2) || (key === 3)));
	}(
		$author$project$KeyboardHelper$codeToKey(keyCode));
};
var $author$project$KeyboardHelper$isKey = F2(
	function (key, keyCode) {
		return _Utils_eq(
			key,
			$author$project$KeyboardHelper$codeToKey(keyCode));
	});
var $author$project$KeyboardHelper$keyCodeToNumber = function (keyCode) {
	return ((keyCode >= 48) && (keyCode < 58)) ? $elm$core$Maybe$Just(keyCode - 48) : (((keyCode >= 96) && (keyCode < 106)) ? $elm$core$Maybe$Just(keyCode - 96) : $elm$core$Maybe$Nothing);
};
var $author$project$Pages$Holidays$State_Loaded$view_pinDialog = function (currentPin) {
	var onNumberKeyPressed = A2(
		$elm$html$Html$Events$custom,
		'keydown',
		$author$project$KeyboardHelper$htmlEventToKeyCode(
			function (keyCode) {
				var message = A2($author$project$KeyboardHelper$isKey, 4, keyCode) ? $author$project$Pages$Holidays$State_Loaded$Pin_BackSpaceKeyPressed(currentPin) : A2(
					$elm$core$Maybe$withDefault,
					$author$project$Pages$Holidays$State_Loaded$NoOp,
					A2(
						$elm$core$Maybe$map,
						$author$project$Pages$Holidays$State_Loaded$Pin_NumberKeyPressed(currentPin),
						$author$project$KeyboardHelper$keyCodeToNumber(keyCode)));
				return {
					mp: message,
					iu: $author$project$KeyboardHelper$isArrowKey(keyCode),
					ja: $author$project$KeyboardHelper$isArrowKey(keyCode)
				};
			}));
	return A2(
		$author$project$Components$Modal$modal,
		{kz: true, mR: $author$project$Pages$Holidays$State_Loaded$DismissPinDialog},
		A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Background$color($author$project$Colors$background),
					$mdgriffith$elm_ui$Element$Border$color($author$project$Colors$onBackground),
					$mdgriffith$elm_ui$Element$Border$width(1),
					$mdgriffith$elm_ui$Element$Border$rounded(8),
					$mdgriffith$elm_ui$Element$centerX,
					$mdgriffith$elm_ui$Element$padding(8),
					$mdgriffith$elm_ui$Element$spacing(4)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$bold,
							$mdgriffith$elm_ui$Element$Font$color($author$project$Colors$onBackground)
						]),
					$mdgriffith$elm_ui$Element$text('Toteutuneiden lomien muokkaus vaatii PIN-koodin')),
					A4(
					$author$project$Components$Input$textInput,
					_List_fromArray(
						[
							$author$project$Utils$HtmlUtil$id('reporting-pin-input'),
							$author$project$Utils$HtmlUtil$disableAutoComplete,
							$mdgriffith$elm_ui$Element$htmlAttribute(onNumberKeyPressed)
						]),
					'Syötä PIN',
					function (_v0) {
						return $author$project$Pages$Holidays$State_Loaded$NoOp;
					},
					A2(
						$elm$core$String$repeat,
						$elm$core$List$length(currentPin),
						'*'))
				])));
};
var $author$project$Pages$Holidays$State_Loaded$Click_ToggleWeekColumnWidth = function (a) {
	return {$: 5, a: a};
};
var $mdgriffith$elm_ui$Element$Font$alignRight = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$fontAlignment, $mdgriffith$elm_ui$Internal$Style$classes.oz);
var $author$project$Colors$brightGreen = A3($mdgriffith$elm_ui$Element$rgb255, 0, 255, 0);
var $author$project$Icons$check = $author$project$Icons$fa_icon('fas fa-check');
var $author$project$Pages$Holidays$State_Loaded$collapsedWeekCellWidth = 22;
var $author$project$Pages$Holidays$State_Loaded$dayCellWidth = 12;
var $elm$core$Basics$pi = _Basics_pi;
var $elm$core$Basics$degrees = function (angleInDegrees) {
	return (angleInDegrees * $elm$core$Basics$pi) / 180;
};
var $mdgriffith$elm_ui$Element$Font$extraLight = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$fontWeight, $mdgriffith$elm_ui$Internal$Style$classes.os);
var $author$project$Pages$Holidays$State_Loaded$hoveredRowBackgroundColor = $mdgriffith$elm_ui$Element$Background$color(
	$author$project$Colors$surface(2));
var $mdgriffith$elm_ui$Element$Font$light = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$fontWeight, $mdgriffith$elm_ui$Internal$Style$classes.ow);
var $author$project$Icons$minus = $author$project$Icons$fa_icon('fas fa-minus');
var $mdgriffith$elm_ui$Element$moveDown = function (y) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$TransformComponent,
		$mdgriffith$elm_ui$Internal$Flag$moveY,
		$mdgriffith$elm_ui$Internal$Model$MoveY(y));
};
var $mdgriffith$elm_ui$Internal$Model$MoveX = function (a) {
	return {$: 0, a: a};
};
var $mdgriffith$elm_ui$Internal$Flag$moveX = $mdgriffith$elm_ui$Internal$Flag$flag(25);
var $mdgriffith$elm_ui$Element$moveLeft = function (x) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$TransformComponent,
		$mdgriffith$elm_ui$Internal$Flag$moveX,
		$mdgriffith$elm_ui$Internal$Model$MoveX(-x));
};
var $mdgriffith$elm_ui$Element$moveRight = function (x) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$TransformComponent,
		$mdgriffith$elm_ui$Internal$Flag$moveX,
		$mdgriffith$elm_ui$Internal$Model$MoveX(x));
};
var $author$project$OldTable$columnSpacing = 10;
var $author$project$OldTable$footerRowStyles = _List_fromArray(
	[
		$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
		$mdgriffith$elm_ui$Element$padding(6),
		$mdgriffith$elm_ui$Element$Background$color($author$project$Colors$background),
		$mdgriffith$elm_ui$Element$Border$widthEach(
		{g1: 1, h1: 0, iN: 0, jl: 0}),
		$mdgriffith$elm_ui$Element$Font$bold,
		$mdgriffith$elm_ui$Element$spacing($author$project$OldTable$columnSpacing)
	]);
var $author$project$OldTable$defaultHeaderRowStyles = function (customRowStyles) {
	return _Utils_ap(
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$padding(4),
				$mdgriffith$elm_ui$Element$Background$color($author$project$Colors$background),
				$mdgriffith$elm_ui$Element$Border$widthEach(
				{g1: 1, h1: 0, iN: 0, jl: 1}),
				$mdgriffith$elm_ui$Element$Font$bold,
				$mdgriffith$elm_ui$Element$spacing($author$project$OldTable$columnSpacing)
			]),
		customRowStyles);
};
var $author$project$OldTable$tableHeaderCell = function (colDef) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width(colDef.fg)
			]),
		colDef.hE);
};
var $author$project$OldTable$tableHeaderRow = F2(
	function (customRowStyles, colDefs) {
		return A2(
			$mdgriffith$elm_ui$Element$row,
			$author$project$OldTable$defaultHeaderRowStyles(customRowStyles),
			A2($elm$core$List$map, $author$project$OldTable$tableHeaderCell, colDefs));
	});
var $author$project$OldTable$defaultRowStyles = function (customRowStyles) {
	return _Utils_ap(
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$padding(4),
				$mdgriffith$elm_ui$Element$Border$widthEach(
				{g1: 1, h1: 0, iN: 0, jl: 0}),
				$mdgriffith$elm_ui$Element$spacing($author$project$OldTable$columnSpacing)
			]),
		customRowStyles);
};
var $author$project$OldTable$tableCell = F2(
	function (record, colDef) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Element$width(colDef.fg),
				colDef.dx),
			colDef.g4(record));
	});
var $author$project$OldTable$tableRow = F3(
	function (customRowStyles, colDefs, record) {
		return A2(
			$mdgriffith$elm_ui$Element$row,
			$author$project$OldTable$defaultRowStyles(customRowStyles),
			A2(
				$elm$core$List$map,
				$author$project$OldTable$tableCell(record),
				colDefs));
	});
var $author$project$OldTable$tableStyles = _List_fromArray(
	[
		$mdgriffith$elm_ui$Element$spacing(2),
		$mdgriffith$elm_ui$Element$Font$size(16)
	]);
var $author$project$OldTable$oldTable = F4(
	function (records, customRowStyles, colDefs, footerCells) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			$author$project$OldTable$tableStyles,
			A2(
				$mdgriffith$elm_ui$Element$column,
				_List_Nil,
				A2(
					$elm$core$List$cons,
					A2($author$project$OldTable$tableHeaderRow, customRowStyles, colDefs),
					_Utils_ap(
						A2(
							$elm$core$List$map,
							A2($author$project$OldTable$tableRow, customRowStyles, colDefs),
							records),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Element$row, $author$project$OldTable$footerRowStyles, footerCells)
							])))));
	});
var $author$project$Icons$plus = $author$project$Icons$fa_icon('fas fa-plus');
var $mdgriffith$elm_ui$Internal$Model$Rotate = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Flag$rotate = $mdgriffith$elm_ui$Internal$Flag$flag(24);
var $mdgriffith$elm_ui$Element$rotate = function (angle) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$TransformComponent,
		$mdgriffith$elm_ui$Internal$Flag$rotate,
		A2(
			$mdgriffith$elm_ui$Internal$Model$Rotate,
			_Utils_Tuple3(0, 0, 1),
			angle));
};
var $author$project$Types$Week$startDate = function (_v0) {
	var year = _v0.fi;
	var weekNumber = _v0.o$;
	return A3($justinmimbs$date$Date$fromWeekDate, year, weekNumber, 0);
};
var $author$project$Helpers$suffix = F2(
	function (suffix_, string) {
		return _Utils_ap(string, suffix_);
	});
var $author$project$Types$VacationBudget$dayCounts = F2(
	function (vacationType, vacationBudgets) {
		return $elm$core$List$sum(
			A2(
				$elm$core$List$map,
				function ($) {
					return $.hf;
				},
				A2(
					$elm$core$List$filter,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.jr;
						},
						$elm$core$Basics$eq(vacationType)),
					vacationBudgets)));
	});
var $author$project$Pages$Holidays$State_Loaded$summerVacationBudgetDays = F2(
	function (vacationBudgetsByWorker, workerId) {
		return A2(
			$author$project$Types$VacationBudget$dayCounts,
			1,
			A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				A2($elm$core$Dict$get, workerId, vacationBudgetsByWorker)));
	});
var $author$project$Pages$Holidays$State_Loaded$tableRowHeight = 26;
var $author$project$Components$Tooltip$tooltip = function (text) {
	return $mdgriffith$elm_ui$Element$htmlAttribute(
		$elm$html$Html$Attributes$title(text));
};
var $elm$core$Dict$map = F2(
	function (func, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				A2(func, key, value),
				A2($elm$core$Dict$map, func, left),
				A2($elm$core$Dict$map, func, right));
		}
	});
var $author$project$Pages$Holidays$State_Loaded$vacationBudgetsForYears = F2(
	function (_v0, vacationBudgetsByWorker) {
		var minYear = _v0.a;
		var maxYear = _v0.b;
		return A2(
			$elm$core$Dict$map,
			F2(
				function (_v1, vacationBudgets) {
					return A2(
						$elm$core$List$filter,
						function (_v2) {
							var year = _v2.fi;
							return (_Utils_cmp(year, minYear) > -1) && (_Utils_cmp(year, maxYear) < 1);
						},
						vacationBudgets);
				}),
			vacationBudgetsByWorker);
	});
var $author$project$Pages$Holidays$State_Loaded$vacationWeeksForYears = F2(
	function (_v0, vacationWeeksByWorker) {
		var minYear = _v0.a;
		var maxYear = _v0.b;
		return A2(
			$elm$core$Dict$map,
			F2(
				function (_v1, workerVacationWeeks) {
					return A2(
						$elm$core$List$filter,
						function (_v2) {
							var week = _v2.ao;
							return (_Utils_cmp(week.fi, minYear) > -1) && (_Utils_cmp(week.fi, maxYear) < 1);
						},
						workerVacationWeeks);
				}),
			vacationWeeksByWorker);
	});
var $author$project$Types$VacationState$VacationRequest = 0;
var $author$project$Pages$Holidays$State_Loaded$confirmedDayOffColor = $author$project$Colors$green;
var $author$project$Colors$yellow = A3($mdgriffith$elm_ui$Element$rgb255, 239, 183, 0);
var $author$project$Pages$Holidays$State_Loaded$requestedDayOffColor = $author$project$Colors$yellow;
var $author$project$Pages$Holidays$State_Loaded$cellBackgroundColor = F2(
	function (vacationStateMode, isVacation) {
		var _v0 = _Utils_Tuple2(vacationStateMode, isVacation);
		if (!_v0.b) {
			return $author$project$Colors$none;
		} else {
			if (_v0.a === 1) {
				var _v1 = _v0.a;
				return $author$project$Pages$Holidays$State_Loaded$confirmedDayOffColor;
			} else {
				var _v2 = _v0.a;
				return $author$project$Pages$Holidays$State_Loaded$requestedDayOffColor;
			}
		}
	});
var $mdgriffith$elm_ui$Element$Font$medium = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$fontWeight, $mdgriffith$elm_ui$Internal$Style$classes.ox);
var $author$project$Pages$Holidays$State_Loaded$view_vacationModeInstructions = function () {
	var labelMaker = function (vacationState) {
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(2)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$height(
							$mdgriffith$elm_ui$Element$px(14)),
							$mdgriffith$elm_ui$Element$width(
							$mdgriffith$elm_ui$Element$px(14)),
							$mdgriffith$elm_ui$Element$Background$color(
							A2($author$project$Pages$Holidays$State_Loaded$cellBackgroundColor, vacationState, true))
						]),
					$mdgriffith$elm_ui$Element$none),
					$mdgriffith$elm_ui$Element$text(
					function () {
						if (vacationState === 1) {
							return 'toteutuneet lomat';
						} else {
							return 'lomatoiveet';
						}
					}())
				]));
	};
	return A2(
		$mdgriffith$elm_ui$Element$row,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$centerX,
				$mdgriffith$elm_ui$Element$Font$medium,
				$author$project$Styles$mediumFontSize,
				$mdgriffith$elm_ui$Element$spacing(8)
			]),
		_List_fromArray(
			[
				labelMaker(1),
				labelMaker(0)
			]));
}();
var $author$project$Pages$Holidays$State_Loaded$MouseEnteredWeekCell = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$onEnter = A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$onWithOptions, 'pointerenter', $mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$defaultOptions);
var $author$project$Pages$Holidays$State_Loaded$Clicked_CollapsedWeek = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Holidays$State_Loaded$cellFontColor = F2(
	function (vacationStateMode, weekDaysOffCount) {
		var _v0 = _Utils_Tuple2(vacationStateMode, weekDaysOffCount);
		_v0$2:
		while (true) {
			if (_v0.a === 1) {
				if (_v0.b === 6) {
					var _v1 = _v0.a;
					return $author$project$Colors$onSurface(7);
				} else {
					break _v0$2;
				}
			} else {
				if (_v0.b === 6) {
					var _v2 = _v0.a;
					return $author$project$Colors$onSurface(7);
				} else {
					break _v0$2;
				}
			}
		}
		return $author$project$Colors$onSurface(9);
	});
var $author$project$Types$WorkerVacationWeek$daysOffCount = F2(
	function (vacationState, workerVacationWeek) {
		return $elm$core$List$length(
			A2(
				$elm$core$List$filter,
				$elm$core$Basics$eq(true),
				A2(
					$elm$core$List$map,
					A2($author$project$Types$WorkerVacationWeek$isDayOff, vacationState, workerVacationWeek),
					$author$project$Types$WorkerVacationWeek$possibleVacationWeekdays)));
	});
var $author$project$Components$Button$fontSize = F2(
	function (size, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{c8: size, dC: size});
	});
var $author$project$Components$Button$height = F2(
	function (h, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{
				en: $elm$core$Maybe$Just(h)
			});
	});
var $author$project$Pages$Holidays$State_Loaded$rowButtonHeight = $author$project$Pages$Holidays$State_Loaded$tableRowHeight - 6;
var $author$project$Pages$Holidays$State_Loaded$inactiveStripe = F2(
	function (hasVacations, inactiveVacationState) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Background$color(
					hasVacations ? A2($author$project$Pages$Holidays$State_Loaded$cellBackgroundColor, inactiveVacationState, true) : $author$project$Colors$none),
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px($author$project$Pages$Holidays$State_Loaded$tableRowHeight - $author$project$Pages$Holidays$State_Loaded$rowButtonHeight)),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			$mdgriffith$elm_ui$Element$none);
	});
var $author$project$Pages$Holidays$State_Loaded$view_collapsedWeek = F2(
	function (activeVacationState, workerVacationWeek) {
		var inactiveVacationState = function () {
			if (activeVacationState === 1) {
				return 0;
			} else {
				return 1;
			}
		}();
		var activeWeekdaysOff = A2($author$project$Types$WorkerVacationWeek$daysOffCount, activeVacationState, workerVacationWeek);
		var activeVacationStateButton = $author$project$Components$Button$toElement(
			A2(
				$author$project$Components$Button$setCustomAttributes,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$padding(2),
						$mdgriffith$elm_ui$Element$Border$rounded(0),
						$mdgriffith$elm_ui$Element$Border$width(0),
						$mdgriffith$elm_ui$Element$mouseOver(
						(!activeWeekdaysOff) ? _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Background$color(
								$author$project$Colors$onSurface(3)),
								$mdgriffith$elm_ui$Element$Font$color(
								$author$project$Colors$onSurface(9))
							]) : _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Background$color(
								$author$project$Colors$primary(5)),
								$mdgriffith$elm_ui$Element$Font$color(
								$author$project$Colors$onSurface(9))
							])),
						$mdgriffith$elm_ui$Element$Background$color(
						A2($author$project$Pages$Holidays$State_Loaded$cellBackgroundColor, activeVacationState, activeWeekdaysOff > 0))
					]),
				A2(
					$author$project$Components$Button$width,
					$mdgriffith$elm_ui$Element$fill,
					A2(
						$author$project$Components$Button$height,
						$mdgriffith$elm_ui$Element$px($author$project$Pages$Holidays$State_Loaded$rowButtonHeight),
						A2(
							$author$project$Components$Button$fontSize,
							15,
							A2(
								$author$project$Components$Button$fontColor,
								A2($author$project$Pages$Holidays$State_Loaded$cellFontColor, activeVacationState, activeWeekdaysOff),
								A2(
									$author$project$Components$Button$text,
									(!activeWeekdaysOff) ? '' : $elm$core$String$fromInt(activeWeekdaysOff),
									A2(
										$author$project$Components$Button$onClick,
										$author$project$Pages$Holidays$State_Loaded$Clicked_CollapsedWeek(workerVacationWeek),
										$author$project$Components$Button$button))))))));
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					A2(
						$elm$core$List$map,
						function (weekday) {
							return A2(
								$author$project$Pages$Holidays$State_Loaded$inactiveStripe,
								A3($author$project$Types$WorkerVacationWeek$isDayOff, inactiveVacationState, workerVacationWeek, weekday),
								inactiveVacationState);
						},
						$author$project$Types$WorkerVacationWeek$possibleVacationWeekdays)),
					activeVacationStateButton
				]));
	});
var $author$project$Pages$Holidays$State_Loaded$Clicked_ClearWholeWeek = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Holidays$State_Loaded$Click_Weekday = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$Types$Week$weekdayCharacter = function (weekday) {
	switch (weekday) {
		case 0:
			return 'M';
		case 1:
			return 'T';
		case 2:
			return 'K';
		case 3:
			return 'T';
		case 4:
			return 'P';
		case 5:
			return 'L';
		default:
			return 'S';
	}
};
var $author$project$Pages$Holidays$State_Loaded$view_weekday = F4(
	function (canEditVacations, activeVacationState, workerVacationWeek, weekday) {
		var isDayoffForActiveVacationState = A3($author$project$Types$WorkerVacationWeek$isDayOff, activeVacationState, workerVacationWeek, weekday);
		var inactiveVacationState = function () {
			if (activeVacationState === 1) {
				return 0;
			} else {
				return 1;
			}
		}();
		var isDayOffForInactiveVacationState = A3($author$project$Types$WorkerVacationWeek$isDayOff, inactiveVacationState, workerVacationWeek, weekday);
		var activeVacationStateButton = $author$project$Components$Button$toElement(
			A2(
				$author$project$Components$Button$setCustomAttributes,
				_Utils_ap(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$padding(2),
							$mdgriffith$elm_ui$Element$Border$rounded(0),
							$mdgriffith$elm_ui$Element$Border$width(0),
							$mdgriffith$elm_ui$Element$Background$color(
							A2($author$project$Pages$Holidays$State_Loaded$cellBackgroundColor, activeVacationState, isDayoffForActiveVacationState))
						]),
					$author$project$Helpers$attributeIf(
						_List_fromArray(
							[
								_Utils_Tuple2(
								isDayoffForActiveVacationState,
								$mdgriffith$elm_ui$Element$Font$color(
									$author$project$Colors$onSurface(9)))
							]))),
				A2(
					$author$project$Components$Button$width,
					$mdgriffith$elm_ui$Element$fill,
					A2(
						$author$project$Components$Button$height,
						$mdgriffith$elm_ui$Element$px($author$project$Pages$Holidays$State_Loaded$rowButtonHeight),
						A2(
							$author$project$Components$Button$fontSize,
							12,
							A2(
								$author$project$Components$Button$text,
								$elm$core$String$fromChar(
									$author$project$Types$Week$weekdayCharacter(weekday)),
								A2(
									$author$project$Components$Button$onClick,
									canEditVacations ? A2($author$project$Pages$Holidays$State_Loaded$Click_Weekday, workerVacationWeek, weekday) : $author$project$Pages$Holidays$State_Loaded$NoOp,
									$author$project$Components$Button$button)))))));
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			_List_fromArray(
				[
					A2($author$project$Pages$Holidays$State_Loaded$inactiveStripe, isDayOffForInactiveVacationState, inactiveVacationState),
					activeVacationStateButton
				]));
	});
var $author$project$Pages$Holidays$State_Loaded$view_expandedWeek = F3(
	function (editPermissions, vacationStateMode, workerVacationWeek) {
		var editOperationsPossible = function () {
			if (!editPermissions.$) {
				return false;
			} else {
				return true;
			}
		}();
		var clearWholeWeekButton = $author$project$Components$Button$toElement(
			A2(
				$author$project$Components$Button$setCustomAttributes,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$moveDown(3),
						$mdgriffith$elm_ui$Element$padding(2)
					]),
				A2(
					$author$project$Components$Button$height,
					$mdgriffith$elm_ui$Element$px($author$project$Pages$Holidays$State_Loaded$rowButtonHeight),
					A2(
						$author$project$Components$Button$iconSize,
						10,
						A2(
							$author$project$Components$Button$icon,
							$author$project$Icons$close,
							A2(
								$author$project$Components$Button$onClick,
								$author$project$Pages$Holidays$State_Loaded$Clicked_ClearWholeWeek(workerVacationWeek),
								$author$project$Components$Button$button))))));
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px($author$project$Pages$Holidays$State_Loaded$rowButtonHeight)),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$moveDown(3)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$alignLeft]),
					A2(
						$elm$core$List$map,
						A3($author$project$Pages$Holidays$State_Loaded$view_weekday, editOperationsPossible, vacationStateMode, workerVacationWeek),
						$author$project$Types$WorkerVacationWeek$possibleVacationWeekdays)),
					editOperationsPossible ? clearWholeWeekButton : $mdgriffith$elm_ui$Element$none
				]));
	});
var $author$project$Pages$Holidays$State_Loaded$view_weekCell = F4(
	function (workerVacationWeek, editPermissions, vacationStateMode, _v0) {
		var isExpandedWeek = _v0.a;
		var widthOfCell = _v0.b;
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$width(widthOfCell),
					$mdgriffith$elm_ui$Element$Border$color(
					$author$project$Colors$onSurface(5)),
					$mdgriffith$elm_ui$Element$Border$widthEach(
					{g1: 0, h1: 0, iN: 1, jl: 0}),
					$mdgriffith$elm_ui$Element$htmlAttribute(
					$mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$onEnter(
						function (_v1) {
							return A2($author$project$Pages$Holidays$State_Loaded$MouseEnteredWeekCell, workerVacationWeek.ao, workerVacationWeek.jy);
						}))
				]),
			((isExpandedWeek && A2($author$project$Types$WorkerVacationWeek$hasDaysOff, vacationStateMode, workerVacationWeek)) ? A2($author$project$Pages$Holidays$State_Loaded$view_expandedWeek, editPermissions, vacationStateMode) : $author$project$Pages$Holidays$State_Loaded$view_collapsedWeek(vacationStateMode))(workerVacationWeek));
	});
var $author$project$Pages$Holidays$State_Loaded$winterVacationBudgetDays = F2(
	function (vacationBudgetsByWorker, workerId) {
		return A2(
			$author$project$Types$VacationBudget$dayCounts,
			2,
			A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				A2($elm$core$Dict$get, workerId, vacationBudgetsByWorker)));
	});
var $author$project$Pages$Holidays$State_Loaded$vacationBudgetDays = F2(
	function (vacationBudgetsByWorker, workerId) {
		return A2($author$project$Pages$Holidays$State_Loaded$winterVacationBudgetDays, vacationBudgetsByWorker, workerId) + A2($author$project$Pages$Holidays$State_Loaded$summerVacationBudgetDays, vacationBudgetsByWorker, workerId);
	});
var $author$project$Pages$Holidays$State_Loaded$workerVacationDaysCount = F3(
	function (vacationWeeksByWorker, vacationState, workerId) {
		return $elm$core$List$sum(
			A2(
				$elm$core$List$map,
				$author$project$Types$WorkerVacationWeek$daysOffCount(vacationState),
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2($elm$core$Dict$get, workerId, vacationWeeksByWorker))));
	});
var $author$project$Pages$Holidays$State_Loaded$workerUsedBudget = F4(
	function (yearRange, allVacationWeeksByWorker, vacationState, workerId) {
		var vacationWeeksForYearRange = A2($author$project$Pages$Holidays$State_Loaded$vacationWeeksForYears, yearRange, allVacationWeeksByWorker);
		return A3($author$project$Pages$Holidays$State_Loaded$workerVacationDaysCount, vacationWeeksForYearRange, vacationState, workerId);
	});
var $author$project$Pages$Holidays$State_Loaded$workerRemainingBudget = F5(
	function (yearRange, vacationBudgetsByWorker, allVacationWeeksByWorker, vacationState, workerId) {
		var vacationDaysCount = A4($author$project$Pages$Holidays$State_Loaded$workerUsedBudget, yearRange, allVacationWeeksByWorker, vacationState, workerId);
		var budgetsForYearRange = A2($author$project$Pages$Holidays$State_Loaded$vacationBudgetsForYears, yearRange, vacationBudgetsByWorker);
		var budgetedCount = A2($author$project$Pages$Holidays$State_Loaded$vacationBudgetDays, budgetsForYearRange, workerId);
		return budgetedCount - vacationDaysCount;
	});
var $author$project$Pages$Holidays$State_Loaded$view_table = function (model) {
	var highlightRowIfHovered = function (worker) {
		var isHoveredRow = A2(
			$elm$core$Maybe$withDefault,
			false,
			A2(
				$elm$core$Maybe$map,
				$elm$core$Basics$eq(worker.er),
				A2($elm$core$Maybe$map, $elm$core$Tuple$second, model.c6)));
		return $author$project$Helpers$attributeIf(
			_List_fromArray(
				[
					_Utils_Tuple2(isHoveredRow, $author$project$Pages$Holidays$State_Loaded$hoveredRowBackgroundColor)
				]));
	};
	var headerRowVerticalHeight = 35;
	var workerColumnRenderer = {
		g4: function (worker) {
			var unusedVacationDaysForYear = A5(
				$author$project$Pages$Holidays$State_Loaded$workerRemainingBudget,
				_Utils_Tuple2(model.bG, model.bG),
				model.bP,
				model.al,
				model.C,
				worker.er);
			return A2(
				$mdgriffith$elm_ui$Element$row,
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					highlightRowIfHovered(worker)),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$height(
								$mdgriffith$elm_ui$Element$px($author$project$Pages$Holidays$State_Loaded$tableRowHeight)),
								$mdgriffith$elm_ui$Element$Font$alignLeft,
								$mdgriffith$elm_ui$Element$padding(2)
							]),
						$mdgriffith$elm_ui$Element$text(worker.mD)),
						(!unusedVacationDaysForYear) ? A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Font$color($author$project$Colors$brightGreen),
								$author$project$Styles$smallFontSize,
								$mdgriffith$elm_ui$Element$padding(2)
							]),
						$author$project$Icons$check) : $mdgriffith$elm_ui$Element$none
					]));
		},
		hE: A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px(headerRowVerticalHeight)),
					$mdgriffith$elm_ui$Element$moveDown(20),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			$mdgriffith$elm_ui$Element$text('Työntekijä')),
		dx: _List_Nil,
		fg: $mdgriffith$elm_ui$Element$px(210)
	};
	var expandedColumnWidth = $mdgriffith$elm_ui$Element$px(($author$project$Pages$Holidays$State_Loaded$dayCellWidth * 6) + 16);
	var dayCountColumn_header = F4(
		function (columnWidth_, fontColor, _v6, headerText) {
			var borderColor = _v6.a;
			var leftBorderWidth = _v6.b;
			var rightBorderWidth = _v6.c;
			return A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Border$color(borderColor),
						$mdgriffith$elm_ui$Element$Border$widthEach(
						{g1: 0, h1: leftBorderWidth, iN: rightBorderWidth, jl: 0}),
						$mdgriffith$elm_ui$Element$height(
						$mdgriffith$elm_ui$Element$px(headerRowVerticalHeight)),
						$mdgriffith$elm_ui$Element$moveDown(25),
						$mdgriffith$elm_ui$Element$width(
						$mdgriffith$elm_ui$Element$px(columnWidth_))
					]),
				A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Border$color(borderColor),
							$mdgriffith$elm_ui$Element$Border$widthEach(
							{g1: rightBorderWidth, h1: 0, iN: 0, jl: 0}),
							$mdgriffith$elm_ui$Element$moveRight(columnWidth_ - 13),
							$mdgriffith$elm_ui$Element$moveUp(30),
							$mdgriffith$elm_ui$Element$rotate(
							$elm$core$Basics$degrees(-45)),
							$mdgriffith$elm_ui$Element$width(
							$mdgriffith$elm_ui$Element$px(50))
						]),
					A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$alignLeft,
								$mdgriffith$elm_ui$Element$Font$color(fontColor),
								$mdgriffith$elm_ui$Element$Font$light,
								$author$project$Styles$mediumFontSize,
								$mdgriffith$elm_ui$Element$moveLeft(4),
								$mdgriffith$elm_ui$Element$moveUp(2)
							]),
						$mdgriffith$elm_ui$Element$text(headerText))));
		});
	var grayBorderColumn = {
		g4: function (worker) {
			return A2(
				$mdgriffith$elm_ui$Element$el,
				_Utils_ap(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$height(
							$mdgriffith$elm_ui$Element$px($author$project$Pages$Holidays$State_Loaded$tableRowHeight)),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					highlightRowIfHovered(worker)),
				$mdgriffith$elm_ui$Element$none);
		},
		hE: A4(
			dayCountColumn_header,
			1,
			$author$project$Colors$onSurface(1),
			_Utils_Tuple3(
				$author$project$Colors$onSurface(1),
				0,
				1),
			' '),
		dx: _List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Border$color(
				$author$project$Colors$onSurface(1)),
				$mdgriffith$elm_ui$Element$Border$widthEach(
				{g1: 0, h1: 0, iN: 1, jl: 0})
			]),
		fg: $mdgriffith$elm_ui$Element$px(1)
	};
	var sectionHeaderColumn = F2(
		function (columnWidth_, titleText) {
			return {
				g4: function (worker) {
					return A2(
						$mdgriffith$elm_ui$Element$el,
						_Utils_ap(
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$height(
									$mdgriffith$elm_ui$Element$px($author$project$Pages$Holidays$State_Loaded$tableRowHeight)),
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
								]),
							highlightRowIfHovered(worker)),
						$mdgriffith$elm_ui$Element$none);
				},
				hE: A4(
					dayCountColumn_header,
					columnWidth_,
					$author$project$Colors$onBackground,
					_Utils_Tuple3($author$project$Colors$onBackground, 2, 0),
					titleText),
				dx: _List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Border$color($author$project$Colors$onBackground),
						$mdgriffith$elm_ui$Element$Border$widthEach(
						{g1: 0, h1: 2, iN: 0, jl: 0})
					]),
				fg: $mdgriffith$elm_ui$Element$px(columnWidth_)
			};
		});
	var dayCountColumn = F5(
		function (columnWidth_, moveRight_, cellValueForWorker, includePlusSign, columnLabel) {
			return {
				g4: function (worker) {
					var cellValue = cellValueForWorker(worker.er);
					return A2(
						$mdgriffith$elm_ui$Element$el,
						_Utils_ap(
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$height(
									$mdgriffith$elm_ui$Element$px($author$project$Pages$Holidays$State_Loaded$tableRowHeight)),
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$author$project$Styles$mediumFontSize,
									$mdgriffith$elm_ui$Element$Font$alignRight,
									$mdgriffith$elm_ui$Element$paddingEach(
									{g1: 0, h1: 0, iN: 5, jl: 0})
								]),
							highlightRowIfHovered(worker)),
						A2(
							$mdgriffith$elm_ui$Element$el,
							_Utils_ap(
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$centerY
									]),
								$author$project$Helpers$attributeIf(
									_List_fromArray(
										[
											_Utils_Tuple2(
											!cellValue,
											$mdgriffith$elm_ui$Element$Font$color(
												$author$project$Colors$onSurface(5))),
											_Utils_Tuple2(
											cellValue < 0,
											$mdgriffith$elm_ui$Element$Font$color($author$project$Colors$warning))
										]))),
							function (intValue) {
								var _v5 = _Utils_Tuple2(includePlusSign, intValue > 0);
								if (_v5.a && _v5.b) {
									return A2(
										$mdgriffith$elm_ui$Element$row,
										_List_fromArray(
											[$mdgriffith$elm_ui$Element$alignRight]),
										_List_fromArray(
											[
												A2(
												$mdgriffith$elm_ui$Element$el,
												_List_fromArray(
													[
														$author$project$Styles$smallFontSize,
														$mdgriffith$elm_ui$Element$moveRight(1)
													]),
												$mdgriffith$elm_ui$Element$text('+')),
												$mdgriffith$elm_ui$Element$text(
												$elm$core$String$fromInt(intValue))
											]));
								} else {
									return $mdgriffith$elm_ui$Element$text(
										$elm$core$String$fromInt(intValue));
								}
							}(cellValue)));
				},
				hE: A4(
					dayCountColumn_header,
					columnWidth_ - moveRight_,
					$author$project$Colors$onSurface(5),
					_Utils_Tuple3($author$project$Colors$none, 0, 0),
					columnLabel),
				dx: _List_Nil,
				fg: $mdgriffith$elm_ui$Element$px(columnWidth_)
			};
		});
	var currentWolumnWidth = function (week) {
		var _v3 = A2($elm$core$Dict$get, week.o$, model.b1);
		if ((!_v3.$) && (_v3.a === 1)) {
			var _v4 = _v3.a;
			return _Utils_Tuple2(true, expandedColumnWidth);
		} else {
			return _Utils_Tuple2(
				false,
				$mdgriffith$elm_ui$Element$px($author$project$Pages$Holidays$State_Loaded$collapsedWeekCellWidth));
		}
	};
	var workerVacationWeeksColumnRender = function () {
		var columnWidthToggle = function (week) {
			return $author$project$Components$Button$toElement(
				A2(
					$author$project$Components$Button$setCustomAttributes,
					_List_fromArray(
						[
							A2($mdgriffith$elm_ui$Element$paddingXY, 0, 2)
						]),
					A2(
						$author$project$Components$Button$iconSize,
						12,
						A2(
							$author$project$Components$Button$width,
							$mdgriffith$elm_ui$Element$fill,
							A2(
								$author$project$Components$Button$icon,
								function () {
									var _v1 = A2($elm$core$Dict$get, week.o$, model.b1);
									if ((!_v1.$) && (_v1.a === 1)) {
										var _v2 = _v1.a;
										return $author$project$Icons$minus;
									} else {
										return $author$project$Icons$plus;
									}
								}(),
								A2(
									$author$project$Components$Button$onClick,
									$author$project$Pages$Holidays$State_Loaded$Click_ToggleWeekColumnWidth(week),
									$author$project$Components$Button$button))))));
		};
		var columnLabel = A2(
			$mdgriffith$elm_ui$Element$column,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$centerX,
							$mdgriffith$elm_ui$Element$Font$light,
							$mdgriffith$elm_ui$Element$height(
							$mdgriffith$elm_ui$Element$px(10))
						]),
					$mdgriffith$elm_ui$Element$text('VIIKOT')),
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_Nil,
					A2(
						$elm$core$List$map,
						function (week) {
							var _v0 = currentWolumnWidth(week);
							var isExpandedColumn = _v0.a;
							var columnWidth = _v0.b;
							return A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width(columnWidth),
										$mdgriffith$elm_ui$Element$moveRight(2)
									]),
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$centerX,
												$mdgriffith$elm_ui$Element$height(
												$mdgriffith$elm_ui$Element$px(headerRowVerticalHeight))
											]),
										A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$Font$extraLight,
													$mdgriffith$elm_ui$Element$rotate(
													$elm$core$Basics$degrees(-45)),
													$mdgriffith$elm_ui$Element$centerX,
													$mdgriffith$elm_ui$Element$moveRight(15),
													$mdgriffith$elm_ui$Element$moveDown(15),
													$author$project$Styles$smallFontSize
												]),
											A2(
												$mdgriffith$elm_ui$Element$el,
												_List_fromArray(
													[
														$author$project$Components$Tooltip$tooltip(
														'viikko ' + ($elm$core$String$fromInt(week.o$) + (' alkaa ' + A2(
															$justinmimbs$date$Date$format,
															'd.M.',
															$author$project$Types$Week$startDate(week))))),
														$mdgriffith$elm_ui$Element$width(
														$mdgriffith$elm_ui$Element$px(50))
													]),
												A2(
													$mdgriffith$elm_ui$Element$el,
													_List_fromArray(
														[
															$mdgriffith$elm_ui$Element$centerX,
															$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
															$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
															$mdgriffith$elm_ui$Element$moveRight(2)
														]),
													$mdgriffith$elm_ui$Element$text(
														A2(
															$justinmimbs$date$Date$format,
															'd.M.',
															$author$project$Types$Week$startDate(week))))))),
										A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$Border$widthEach(
												{g1: 0, h1: 0, iN: 1, jl: 0}),
												$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
											]),
										$mdgriffith$elm_ui$Element$text(
											function (weekNumberString) {
												return isExpandedColumn ? ('viikko ' + weekNumberString) : weekNumberString;
											}(
												$elm$core$String$fromInt(week.o$)))),
										A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$Border$widthEach(
												{g1: 0, h1: 0, iN: 1, jl: 0}),
												$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
											]),
										columnWidthToggle(week))
									]));
						},
						model.ff))
				]));
		return {
			g4: function (worker) {
				return A2(
					$elm$core$Maybe$withDefault,
					$mdgriffith$elm_ui$Element$none,
					A2(
						$elm$core$Maybe$map,
						$mdgriffith$elm_ui$Element$row(
							A2(
								$elm$core$List$cons,
								$mdgriffith$elm_ui$Element$height(
									$mdgriffith$elm_ui$Element$px($author$project$Pages$Holidays$State_Loaded$tableRowHeight)),
								highlightRowIfHovered(worker))),
						A2(
							$elm$core$Maybe$map,
							$elm$core$List$map(
								function (workerVacationWeek) {
									return A4(
										$author$project$Pages$Holidays$State_Loaded$view_weekCell,
										workerVacationWeek,
										model.aw,
										model.C,
										currentWolumnWidth(workerVacationWeek.ao));
								}),
							A2(
								$elm$core$Dict$get,
								worker.er,
								A2(
									$author$project$Pages$Holidays$State_Loaded$vacationWeeksForYears,
									_Utils_Tuple2(model.bG, model.bG),
									model.al)))));
			},
			hE: columnLabel,
			dx: _List_Nil,
			fg: $mdgriffith$elm_ui$Element$fill
		};
	}();
	return A4(
		$author$project$OldTable$oldTable,
		A2(
			$elm$core$List$sortBy,
			function ($) {
				return $.mD;
			},
			model.jA),
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Border$color(
				$author$project$Colors$onSurface(1)),
				$mdgriffith$elm_ui$Element$Border$widthEach(
				{g1: 2, h1: 0, iN: 0, jl: 0}),
				$mdgriffith$elm_ui$Element$spacing(0),
				$mdgriffith$elm_ui$Element$padding(0)
			]),
		_List_fromArray(
			[
				workerColumnRenderer,
				A2(sectionHeaderColumn, 10, 'LOMASALDO '),
				A5(
				dayCountColumn,
				30,
				10,
				A4(
					$author$project$Pages$Holidays$State_Loaded$workerRemainingBudget,
					_Utils_Tuple2(0, model.bG - 1),
					model.bP,
					model.al,
					model.C),
				true,
				A2(
					$author$project$Helpers$suffix,
					$author$project$Types$Year$toString(model.bG),
					'ennen ')),
				A2(
				sectionHeaderColumn,
				12,
				A2(
					$author$project$Helpers$suffix,
					$author$project$Types$Year$toString(model.bG),
					'LOMAT ')),
				A5(
				dayCountColumn,
				23,
				0,
				$author$project$Pages$Holidays$State_Loaded$summerVacationBudgetDays(
					A2(
						$author$project$Pages$Holidays$State_Loaded$vacationBudgetsForYears,
						_Utils_Tuple2(model.bG, model.bG),
						model.bP)),
				false,
				'kesälomat'),
				grayBorderColumn,
				A5(
				dayCountColumn,
				35,
				4,
				$author$project$Pages$Holidays$State_Loaded$winterVacationBudgetDays(
					A2(
						$author$project$Pages$Holidays$State_Loaded$vacationBudgetsForYears,
						_Utils_Tuple2(model.bG, model.bG),
						model.bP)),
				false,
				'talvilomat'),
				grayBorderColumn,
				A5(
				dayCountColumn,
				35,
				8,
				A3(
					$author$project$Pages$Holidays$State_Loaded$workerUsedBudget,
					_Utils_Tuple2(model.bG, model.bG),
					model.al,
					1),
				false,
				'toteutuneet'),
				A2(sectionHeaderColumn, 15, 'LOMASALDO '),
				A5(
				dayCountColumn,
				22,
				0,
				A4(
					$author$project$Pages$Holidays$State_Loaded$workerRemainingBudget,
					_Utils_Tuple2(0, model.bG),
					model.bP,
					model.al,
					model.C),
				true,
				''),
				A2(sectionHeaderColumn, 0, ''),
				workerVacationWeeksColumnRender
			]),
		_List_fromArray(
			[$author$project$Pages$Holidays$State_Loaded$view_vacationModeInstructions]));
};
var $author$project$Pages$Holidays$State_Loaded$Click_SelectVacationStateMode = function (a) {
	return {$: 4, a: a};
};
var $mdgriffith$elm_ui$Element$Border$roundEach = function (_v0) {
	var topLeft = _v0.oL;
	var topRight = _v0.oM;
	var bottomLeft = _v0.ki;
	var bottomRight = _v0.kj;
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$borderRound,
		A3(
			$mdgriffith$elm_ui$Internal$Model$Single,
			'br-' + ($elm$core$String$fromInt(topLeft) + ('-' + ($elm$core$String$fromInt(topRight) + ($elm$core$String$fromInt(bottomLeft) + ('-' + $elm$core$String$fromInt(bottomRight)))))),
			'border-radius',
			$elm$core$String$fromInt(topLeft) + ('px ' + ($elm$core$String$fromInt(topRight) + ('px ' + ($elm$core$String$fromInt(bottomRight) + ('px ' + ($elm$core$String$fromInt(bottomLeft) + 'px'))))))));
};
var $author$project$Components$Button$textColor = F2(
	function (color, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{
				dA: $elm$core$Maybe$Just(color)
			});
	});
var $author$project$Components$Button$toTabButtonElement = F2(
	function (isSelectedTab, button_) {
		var _v0 = isSelectedTab ? _Utils_Tuple2(
			$author$project$Colors$primary(0),
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Background$color($author$project$Colors$background)
				])) : _Utils_Tuple2(
			$author$project$Colors$onSurface(0),
			_List_Nil);
		var textColor_ = _v0.a;
		var backgroundColor = _v0.b;
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$Border$roundEach(
						{ki: 0, kj: 0, oL: 8, oM: 8}),
						$mdgriffith$elm_ui$Element$paddingEach(
						{g1: 2, h1: 8, iN: 8, jl: 2})
					]),
				backgroundColor),
			$author$project$Components$Button$toElement(
				A2($author$project$Components$Button$textColor, textColor_, button_)));
	});
var $author$project$Pages$Holidays$State_Loaded$view_vacationModeToggles = function (selectedVacationStateMode) {
	var buttonMaker = F2(
		function (selectedVacationState, thisVacationState) {
			return A2(
				$author$project$Components$Button$toTabButtonElement,
				_Utils_eq(selectedVacationState, thisVacationState),
				A2(
					$author$project$Components$Button$text,
					function () {
						if (thisVacationState === 1) {
							return 'toteutuneet lomat';
						} else {
							return 'lomatoiveet';
						}
					}(),
					A2(
						$author$project$Components$Button$onClick,
						$author$project$Pages$Holidays$State_Loaded$Click_SelectVacationStateMode(thisVacationState),
						$author$project$Components$Button$button)));
		});
	return A2(
		$mdgriffith$elm_ui$Element$row,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$spacing(10)
			]),
		_List_fromArray(
			[
				A2(buttonMaker, selectedVacationStateMode, 1),
				A2(buttonMaker, selectedVacationStateMode, 0)
			]));
};
var $author$project$Pages$Holidays$State_Loaded$Clicked_GotoYear = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$YearlyCalendar$NextYearButton = 1;
var $author$project$Components$YearlyCalendar$PreviousYearButton = 0;
var $author$project$Icons$chevronLeft = $author$project$Icons$fa_icon('fas fa-chevron-left');
var $author$project$Icons$chevronRight = $author$project$Icons$fa_icon('fas fa-chevron-right');
var $author$project$Components$YearlyCalendar$yearNavigationButton = F3(
	function (clicked_gotoYear, selectedYear, gotoYearButtonType) {
		var _v0 = function () {
			if (!gotoYearButtonType) {
				return _Utils_Tuple2(selectedYear - 1, $author$project$Icons$chevronLeft);
			} else {
				return _Utils_Tuple2(selectedYear + 1, $author$project$Icons$chevronRight);
			}
		}();
		var targetYear = _v0.a;
		var icon = _v0.b;
		return $author$project$Components$Button$toElement(
			function (button) {
				return A2(
					$elm$core$Maybe$withDefault,
					button,
					A2(
						$elm$core$Maybe$map,
						function (clickHandler) {
							return A2(
								$author$project$Components$Button$onClick,
								clickHandler(targetYear),
								button);
						},
						clicked_gotoYear));
			}(
				A2($author$project$Components$Button$icon, icon, $author$project$Components$Button$button)));
	});
var $author$project$Pages$Holidays$State_Loaded$yearIndicator = function (selectedYear) {
	return A2(
		$mdgriffith$elm_ui$Element$row,
		_List_Nil,
		_List_fromArray(
			[
				A3(
				$author$project$Components$YearlyCalendar$yearNavigationButton,
				$elm$core$Maybe$Just($author$project$Pages$Holidays$State_Loaded$Clicked_GotoYear),
				selectedYear,
				0),
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Font$color($author$project$Colors$onBackground),
						$mdgriffith$elm_ui$Element$moveUp(2)
					]),
				$mdgriffith$elm_ui$Element$text(
					$elm$core$String$fromInt(selectedYear))),
				A3(
				$author$project$Components$YearlyCalendar$yearNavigationButton,
				$elm$core$Maybe$Just($author$project$Pages$Holidays$State_Loaded$Clicked_GotoYear),
				selectedYear,
				1)
			]));
};
var $author$project$Pages$Holidays$State_Loaded$view = function (model) {
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$padding(8),
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$inFront(
				A2(
					$elm$core$Maybe$withDefault,
					$mdgriffith$elm_ui$Element$none,
					A2($elm$core$Maybe$map, $author$project$Pages$Holidays$State_Loaded$view_pinDialog, model.aW)))
			]),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$centerX]),
				$author$project$Pages$Holidays$State_Loaded$yearIndicator(model.bG)),
				$author$project$Pages$Holidays$State_Loaded$view_vacationModeToggles(model.C),
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Background$color($author$project$Colors$background),
						$mdgriffith$elm_ui$Element$Font$color($author$project$Colors$onBackground),
						$mdgriffith$elm_ui$Element$paddingEach(
						{g1: 30, h1: 10, iN: 25, jl: 65}),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$onLeave(
							function (_v0) {
								return $author$project$Pages$Holidays$State_Loaded$MouseLeftWeekColumns;
							}))
					]),
				$author$project$Pages$Holidays$State_Loaded$view_table(model)),
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$Font$color(
						$author$project$Colors$onSurface(3)),
						$mdgriffith$elm_ui$Element$Font$italic,
						$author$project$Styles$mediumFontSize,
						A2($mdgriffith$elm_ui$Element$paddingXY, 0, 4)
					]),
				$mdgriffith$elm_ui$Element$text('lomien ja lomatoiveiden muokkaus mahdollista vain työnjohdolle'))
			]));
};
var $author$project$Pages$Holidays$State_Loading$view = function (_v0) {
	var _v1 = 'loadingComponent';
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$centerX,
				$mdgriffith$elm_ui$Element$Font$color(
				$author$project$Colors$onPrimary(5)),
				$author$project$Styles$largeFontSize
			]),
		$mdgriffith$elm_ui$Element$text('Ladataan...'));
};
var $author$project$Pages$State_NoAccess$view = function (_v0) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$centerX,
				$mdgriffith$elm_ui$Element$Font$color(
				$author$project$Colors$onPrimary(5)),
				$author$project$Styles$largeFontSize
			]),
		$mdgriffith$elm_ui$Element$text('Käyttäjällä ei ole pääsyä tälle sivulle.'));
};
var $author$project$Pages$Holidays$view = function (model) {
	var _v0 = model._;
	switch (_v0.$) {
		case 0:
			var subModel = _v0.a;
			return A2(
				$mdgriffith$elm_ui$Element$map,
				$author$project$Pages$Holidays$Msg_Loading,
				$author$project$Pages$Holidays$State_Loading$view(subModel));
		case 1:
			var subModel = _v0.a;
			return A2(
				$mdgriffith$elm_ui$Element$map,
				$author$project$Pages$Holidays$Msg_Loaded,
				$author$project$Pages$Holidays$State_Loaded$view(subModel));
		default:
			var subModel = _v0.a;
			return A2(
				$mdgriffith$elm_ui$Element$map,
				$author$project$Pages$Holidays$Msg_NoAccess,
				$author$project$Pages$State_NoAccess$view(subModel));
	}
};
var $author$project$Pages$Invoicing$HideDialog = {$: 13};
var $author$project$Components$DatePicker$SelectNothing = {$: 1};
var $author$project$Components$DatePicker$UserChangedDatePicker = function (a) {
	return {$: 0, a: a};
};
var $fabhof$elm_ui_datepicker$DatePicker$defaultSettings = {
	fy: _List_fromArray(
		[
			A2($mdgriffith$elm_ui$Element$paddingXY, 4, 2),
			$mdgriffith$elm_ui$Element$Border$rounded(3),
			$mdgriffith$elm_ui$Element$mouseOver(
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Background$color(
					A3($mdgriffith$elm_ui$Element$rgb255, 115, 182, 255))
				]))
		]),
	fB: $elm$core$Basics$always(false),
	fC: _List_fromArray(
		[
			$mdgriffith$elm_ui$Element$Font$color(
			A3($mdgriffith$elm_ui$Element$rgb255, 128, 128, 128)),
			$mdgriffith$elm_ui$Element$Background$color(
			A3($mdgriffith$elm_ui$Element$rgb255, 221, 221, 221))
		]),
	ee: 0,
	fP: _List_fromArray(
		[
			$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
			$mdgriffith$elm_ui$Element$padding(8),
			$mdgriffith$elm_ui$Element$Font$bold
		]),
	mb: $elm$core$Maybe$Nothing,
	f$: $mdgriffith$elm_ui$Element$text('►'),
	ns: _List_fromArray(
		[
			$mdgriffith$elm_ui$Element$Border$width(1),
			$mdgriffith$elm_ui$Element$Border$color(
			A3($mdgriffith$elm_ui$Element$rgb255, 186, 189, 182)),
			$mdgriffith$elm_ui$Element$Border$roundEach(
			{ki: 3, kj: 3, oL: 0, oM: 0}),
			$mdgriffith$elm_ui$Element$moveDown(3),
			$mdgriffith$elm_ui$Element$padding(8),
			$mdgriffith$elm_ui$Element$spacing(4),
			$mdgriffith$elm_ui$Element$Background$color(
			A3($mdgriffith$elm_ui$Element$rgb255, 255, 255, 255))
		]),
	f8: $mdgriffith$elm_ui$Element$text('◄'),
	ge: _List_fromArray(
		[
			$mdgriffith$elm_ui$Element$Background$color(
			A3($mdgriffith$elm_ui$Element$rgb255, 0, 123, 255))
		]),
	gq: _List_fromArray(
		[
			$mdgriffith$elm_ui$Element$spacing(4),
			$mdgriffith$elm_ui$Element$centerX,
			$mdgriffith$elm_ui$Element$centerY
		]),
	gx: _List_fromArray(
		[
			$mdgriffith$elm_ui$Element$Background$color(
			A3($mdgriffith$elm_ui$Element$rgb255, 255, 193, 155))
		]),
	gB: _List_fromArray(
		[$mdgriffith$elm_ui$Element$Font$bold]),
	gG: _List_fromArray(
		[
			$mdgriffith$elm_ui$Element$Font$color(
			A3($mdgriffith$elm_ui$Element$rgb255, 128, 128, 128))
		])
};
var $author$project$Language$LongForm = 0;
var $author$project$Language$monthNameFin = F2(
	function (format, month) {
		var longName = function () {
			switch (month) {
				case 0:
					return 'Tammikuu';
				case 1:
					return 'Helmikuu';
				case 2:
					return 'Maaliskuu';
				case 3:
					return 'Huhtikuu';
				case 4:
					return 'Toukokuu';
				case 5:
					return 'Kesäkuu';
				case 6:
					return 'Heinäkuu';
				case 7:
					return 'Elokuu';
				case 8:
					return 'Syyskuu';
				case 9:
					return 'Lokakuu';
				case 10:
					return 'Marraskuu';
				default:
					return 'Joulukuu';
			}
		}();
		if (!format) {
			return longName;
		} else {
			return A2($elm$core$String$dropRight, 3, longName);
		}
	});
var $author$project$Language$finnishDates = {
	k0: function (x) {
		return $elm$core$String$fromInt(x) + '.';
	},
	mx: $author$project$Language$monthNameFin(0),
	my: $author$project$Language$monthNameFin(1),
	o1: $author$project$Language$weekdayNameFinnish(0),
	o2: $author$project$Language$weekdayNameFinnish(1)
};
var $author$project$Components$DatePicker$Cancel = {$: 2};
var $author$project$Components$DatePicker$handleKeyDown = A2(
	$elm$html$Html$Events$custom,
	'keydown',
	$author$project$KeyboardHelper$htmlEventToKey(
		function (key) {
			var _v0 = function () {
				if (key === 6) {
					return _Utils_Tuple2($author$project$Components$DatePicker$Cancel, true);
				} else {
					return _Utils_Tuple2($author$project$Components$DatePicker$NoOp, false);
				}
			}();
			var message = _v0.a;
			var doPrevent = _v0.b;
			return {mp: message, iu: doPrevent, ja: doPrevent};
		}));
var $fabhof$elm_ui_datepicker$DatePicker$Close = {$: 2};
var $fabhof$elm_ui_datepicker$DatePicker$Open = {$: 1};
var $fabhof$elm_ui_datepicker$DatePicker$PickerChanged = function (a) {
	return {$: 2, a: a};
};
var $fabhof$elm_ui_datepicker$DatePicker$TextChanged = function (a) {
	return {$: 1, a: a};
};
var $fabhof$elm_ui_datepicker$Internal$TestHelper$testAttribute = function (name) {
	return A2($elm$html$Html$Attributes$attribute, 'elm-test', name);
};
var $fabhof$elm_ui_datepicker$Internal$TestHelper$inputAttrHtml = $fabhof$elm_ui_datepicker$Internal$TestHelper$testAttribute('input');
var $fabhof$elm_ui_datepicker$Internal$TestHelper$inputAttr = $mdgriffith$elm_ui$Element$htmlAttribute($fabhof$elm_ui_datepicker$Internal$TestHelper$inputAttrHtml);
var $mdgriffith$elm_ui$Internal$Model$Below = 1;
var $mdgriffith$elm_ui$Element$below = function (element) {
	return A2($mdgriffith$elm_ui$Element$createNearby, 1, element);
};
var $fabhof$elm_ui_datepicker$Internal$TestHelper$calendarAttrHtml = $fabhof$elm_ui_datepicker$Internal$TestHelper$testAttribute('calendar');
var $fabhof$elm_ui_datepicker$Internal$TestHelper$calendarAttr = $mdgriffith$elm_ui$Element$htmlAttribute($fabhof$elm_ui_datepicker$Internal$TestHelper$calendarAttrHtml);
var $mdgriffith$elm_ui$Internal$Model$mapAttr = F2(
	function (fn, attr) {
		switch (attr.$) {
			case 0:
				return $mdgriffith$elm_ui$Internal$Model$NoAttribute;
			case 2:
				var description = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$Describe(description);
			case 6:
				var x = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$AlignX(x);
			case 5:
				var y = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$AlignY(y);
			case 7:
				var x = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$Width(x);
			case 8:
				var x = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$Height(x);
			case 3:
				var x = attr.a;
				var y = attr.b;
				return A2($mdgriffith$elm_ui$Internal$Model$Class, x, y);
			case 4:
				var flag = attr.a;
				var style = attr.b;
				return A2($mdgriffith$elm_ui$Internal$Model$StyleClass, flag, style);
			case 9:
				var location = attr.a;
				var elem = attr.b;
				return A2(
					$mdgriffith$elm_ui$Internal$Model$Nearby,
					location,
					A2($mdgriffith$elm_ui$Internal$Model$map, fn, elem));
			case 1:
				var htmlAttr = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$Attr(
					A2($elm$virtual_dom$VirtualDom$mapAttribute, fn, htmlAttr));
			default:
				var fl = attr.a;
				var trans = attr.b;
				return A2($mdgriffith$elm_ui$Internal$Model$TransformComponent, fl, trans);
		}
	});
var $mdgriffith$elm_ui$Element$mapAttribute = $mdgriffith$elm_ui$Internal$Model$mapAttr;
var $fabhof$elm_ui_datepicker$DatePicker$extAttrs = $elm$core$List$map(
	$mdgriffith$elm_ui$Element$mapAttribute($elm$core$Basics$never));
var $fabhof$elm_ui_datepicker$DatePicker$ChangeMonth = function (a) {
	return {$: 0, a: a};
};
var $fabhof$elm_ui_datepicker$DatePicker$extEle = $mdgriffith$elm_ui$Element$map($elm$core$Basics$never);
var $fabhof$elm_ui_datepicker$Internal$Date$formatMaybeLanguage = F2(
	function (maybeLanguage, string) {
		if (!maybeLanguage.$) {
			var language = maybeLanguage.a;
			return A2($justinmimbs$date$Date$formatWithLanguage, language, string);
		} else {
			return $justinmimbs$date$Date$format(string);
		}
	});
var $fabhof$elm_ui_datepicker$Internal$TestHelper$nextMonthAttrHtml = $fabhof$elm_ui_datepicker$Internal$TestHelper$testAttribute('nextMonth');
var $fabhof$elm_ui_datepicker$Internal$TestHelper$nextMonthAttr = $mdgriffith$elm_ui$Element$htmlAttribute($fabhof$elm_ui_datepicker$Internal$TestHelper$nextMonthAttrHtml);
var $fabhof$elm_ui_datepicker$Internal$TestHelper$previousMonthAttrHtml = $fabhof$elm_ui_datepicker$Internal$TestHelper$testAttribute('previousMonth');
var $fabhof$elm_ui_datepicker$Internal$TestHelper$previousMonthAttr = $mdgriffith$elm_ui$Element$htmlAttribute($fabhof$elm_ui_datepicker$Internal$TestHelper$previousMonthAttrHtml);
var $fabhof$elm_ui_datepicker$DatePicker$pickerHeader = function (_v0) {
	var visibleMonth = _v0.an;
	var onChange = _v0.mK;
	var settings = _v0.n2;
	return A2(
		$mdgriffith$elm_ui$Element$row,
		$fabhof$elm_ui_datepicker$DatePicker$extAttrs(settings.fP),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$alignLeft,
						$mdgriffith$elm_ui$Element$pointer,
						$mdgriffith$elm_ui$Element$Events$onClick(
						onChange(
							$fabhof$elm_ui_datepicker$DatePicker$PickerChanged(
								$fabhof$elm_ui_datepicker$DatePicker$ChangeMonth(
									A3($justinmimbs$date$Date$add, 1, -1, visibleMonth))))),
						$fabhof$elm_ui_datepicker$Internal$TestHelper$previousMonthAttr
					]),
				$fabhof$elm_ui_datepicker$DatePicker$extEle(settings.f8)),
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$centerX]),
				$mdgriffith$elm_ui$Element$text(
					A3($fabhof$elm_ui_datepicker$Internal$Date$formatMaybeLanguage, settings.mb, 'MMMM yyyy', visibleMonth))),
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$alignRight,
						$mdgriffith$elm_ui$Element$pointer,
						$mdgriffith$elm_ui$Element$Events$onClick(
						onChange(
							$fabhof$elm_ui_datepicker$DatePicker$PickerChanged(
								$fabhof$elm_ui_datepicker$DatePicker$ChangeMonth(
									A3($justinmimbs$date$Date$add, 1, 1, visibleMonth))))),
						$fabhof$elm_ui_datepicker$Internal$TestHelper$nextMonthAttr
					]),
				$fabhof$elm_ui_datepicker$DatePicker$extEle(settings.f$))
			]));
};
var $justinmimbs$date$Date$Day = 11;
var $justinmimbs$date$Date$daysSincePreviousWeekday = F2(
	function (wd, date) {
		return A2(
			$elm$core$Basics$modBy,
			7,
			($justinmimbs$date$Date$weekdayNumber(date) + 7) - $justinmimbs$date$Date$weekdayToNumber(wd));
	});
var $justinmimbs$date$Date$firstOfMonth = F2(
	function (y, m) {
		return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + 1;
	});
var $justinmimbs$date$Date$quarterToMonth = function (q) {
	return $justinmimbs$date$Date$numberToMonth((q * 3) - 2);
};
var $justinmimbs$date$Date$floor = F2(
	function (interval, date) {
		var rd = date;
		switch (interval) {
			case 0:
				return $justinmimbs$date$Date$firstOfYear(
					$justinmimbs$date$Date$year(date));
			case 1:
				return A2(
					$justinmimbs$date$Date$firstOfMonth,
					$justinmimbs$date$Date$year(date),
					$justinmimbs$date$Date$quarterToMonth(
						$justinmimbs$date$Date$quarter(date)));
			case 2:
				return A2(
					$justinmimbs$date$Date$firstOfMonth,
					$justinmimbs$date$Date$year(date),
					$justinmimbs$date$Date$month(date));
			case 3:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 0, date);
			case 4:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 0, date);
			case 5:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 1, date);
			case 6:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 2, date);
			case 7:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 3, date);
			case 8:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 4, date);
			case 9:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 5, date);
			case 10:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 6, date);
			default:
				return date;
		}
	});
var $fabhof$elm_ui_datepicker$Internal$Week$Week = $elm$core$Basics$identity;
var $fabhof$elm_ui_datepicker$Internal$Week$WeekModel = F7(
	function (day0, day1, day2, day3, day4, day5, day6) {
		return {cV: day0, cW: day1, cX: day2, cY: day3, cZ: day4, c_: day5, c$: day6};
	});
var $fabhof$elm_ui_datepicker$Internal$Week$addNextDay = function (_v0) {
	var _default = _v0.a;
	var days = _v0.b;
	var fn = _v0.c;
	if (days.b) {
		var day = days.a;
		var rest = days.b;
		return _Utils_Tuple3(
			_default,
			rest,
			fn(day));
	} else {
		return _Utils_Tuple3(
			_default,
			_List_Nil,
			fn(_default));
	}
};
var $fabhof$elm_ui_datepicker$Internal$Week$fromListWithDefault = F2(
	function (_default, items) {
		var _v0 = $fabhof$elm_ui_datepicker$Internal$Week$addNextDay(
			$fabhof$elm_ui_datepicker$Internal$Week$addNextDay(
				$fabhof$elm_ui_datepicker$Internal$Week$addNextDay(
					$fabhof$elm_ui_datepicker$Internal$Week$addNextDay(
						$fabhof$elm_ui_datepicker$Internal$Week$addNextDay(
							$fabhof$elm_ui_datepicker$Internal$Week$addNextDay(
								$fabhof$elm_ui_datepicker$Internal$Week$addNextDay(
									_Utils_Tuple3(_default, items, $fabhof$elm_ui_datepicker$Internal$Week$WeekModel))))))));
		var week = _v0.c;
		return week;
	});
var $justinmimbs$date$Date$Weeks = 2;
var $justinmimbs$date$Date$Years = 0;
var $justinmimbs$date$Date$intervalToUnits = function (interval) {
	switch (interval) {
		case 0:
			return _Utils_Tuple2(1, 0);
		case 1:
			return _Utils_Tuple2(3, 1);
		case 2:
			return _Utils_Tuple2(1, 1);
		case 11:
			return _Utils_Tuple2(1, 3);
		default:
			var week = interval;
			return _Utils_Tuple2(1, 2);
	}
};
var $justinmimbs$date$Date$ceiling = F2(
	function (interval, date) {
		var floored = A2($justinmimbs$date$Date$floor, interval, date);
		if (_Utils_eq(date, floored)) {
			return date;
		} else {
			var _v0 = $justinmimbs$date$Date$intervalToUnits(interval);
			var n = _v0.a;
			var unit = _v0.b;
			return A3($justinmimbs$date$Date$add, unit, n, floored);
		}
	});
var $justinmimbs$date$Date$rangeHelp = F5(
	function (unit, step, until, revList, current) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(current, until) < 0) {
				var _v0 = A3($justinmimbs$date$Date$add, unit, step, current);
				var next = _v0;
				var $temp$unit = unit,
					$temp$step = step,
					$temp$until = until,
					$temp$revList = A2($elm$core$List$cons, current, revList),
					$temp$current = next;
				unit = $temp$unit;
				step = $temp$step;
				until = $temp$until;
				revList = $temp$revList;
				current = $temp$current;
				continue rangeHelp;
			} else {
				return $elm$core$List$reverse(revList);
			}
		}
	});
var $justinmimbs$date$Date$range = F4(
	function (interval, step, _v0, _v1) {
		var start = _v0;
		var until = _v1;
		var _v2 = $justinmimbs$date$Date$intervalToUnits(interval);
		var n = _v2.a;
		var unit = _v2.b;
		var _v3 = A2($justinmimbs$date$Date$ceiling, interval, start);
		var first = _v3;
		return (_Utils_cmp(first, until) < 0) ? A5(
			$justinmimbs$date$Date$rangeHelp,
			unit,
			A2($elm$core$Basics$max, 1, step) * n,
			until,
			_List_Nil,
			first) : _List_Nil;
	});
var $justinmimbs$date$Date$Friday = 8;
var $justinmimbs$date$Date$Monday = 4;
var $justinmimbs$date$Date$Saturday = 9;
var $justinmimbs$date$Date$Sunday = 10;
var $justinmimbs$date$Date$Thursday = 7;
var $justinmimbs$date$Date$Tuesday = 5;
var $justinmimbs$date$Date$Wednesday = 6;
var $fabhof$elm_ui_datepicker$Internal$Week$weekdayToInterval = function (weekday) {
	switch (weekday) {
		case 0:
			return 4;
		case 1:
			return 5;
		case 2:
			return 6;
		case 3:
			return 7;
		case 4:
			return 8;
		case 5:
			return 9;
		default:
			return 10;
	}
};
var $fabhof$elm_ui_datepicker$Internal$Week$calendarWeekDays = F2(
	function (firstDayOfWeek, maybeLanguage) {
		var startDay = A2(
			$justinmimbs$date$Date$floor,
			$fabhof$elm_ui_datepicker$Internal$Week$weekdayToInterval(firstDayOfWeek),
			A3($justinmimbs$date$Date$fromCalendarDate, 2020, 0, 1));
		var days = A4(
			$justinmimbs$date$Date$range,
			11,
			1,
			startDay,
			A3($justinmimbs$date$Date$add, 3, 7, startDay));
		return A2(
			$fabhof$elm_ui_datepicker$Internal$Week$fromListWithDefault,
			'X',
			A2(
				$elm$core$List$map,
				A2($fabhof$elm_ui_datepicker$Internal$Date$formatMaybeLanguage, maybeLanguage, 'EEEEEE'),
				days));
	});
var $fabhof$elm_ui_datepicker$DatePicker$DateChanged = function (a) {
	return {$: 0, a: a};
};
var $fabhof$elm_ui_datepicker$Internal$TestHelper$dayInMonthAttrHtml = $fabhof$elm_ui_datepicker$Internal$TestHelper$testAttribute('dayInMonth');
var $fabhof$elm_ui_datepicker$Internal$TestHelper$dayInMonthAttr = $mdgriffith$elm_ui$Element$htmlAttribute($fabhof$elm_ui_datepicker$Internal$TestHelper$dayInMonthAttrHtml);
var $fabhof$elm_ui_datepicker$Internal$TestHelper$selectedAttrHtml = $fabhof$elm_ui_datepicker$Internal$TestHelper$testAttribute('selected');
var $fabhof$elm_ui_datepicker$Internal$TestHelper$selectedAttr = $mdgriffith$elm_ui$Element$htmlAttribute($fabhof$elm_ui_datepicker$Internal$TestHelper$selectedAttrHtml);
var $fabhof$elm_ui_datepicker$Internal$TestHelper$todayAttrHtml = A2($elm$html$Html$Attributes$attribute, 'elm-test-alt', 'today');
var $fabhof$elm_ui_datepicker$Internal$TestHelper$todayAttr = $mdgriffith$elm_ui$Element$htmlAttribute($fabhof$elm_ui_datepicker$Internal$TestHelper$todayAttrHtml);
var $fabhof$elm_ui_datepicker$DatePicker$dayView = F2(
	function (config, day) {
		var picker = config.f7;
		var settings = config.n2;
		var attributesForThisDay = $elm$core$List$concat(
			_List_fromArray(
				[
					$fabhof$elm_ui_datepicker$DatePicker$extAttrs(settings.fy),
					(!_Utils_eq(
					$justinmimbs$date$Date$month(config.an),
					$justinmimbs$date$Date$month(day))) ? $fabhof$elm_ui_datepicker$DatePicker$extAttrs(settings.gG) : _List_fromArray(
					[$fabhof$elm_ui_datepicker$Internal$TestHelper$dayInMonthAttr]),
					_Utils_eq(picker.dE, day) ? A2(
					$elm$core$List$cons,
					$fabhof$elm_ui_datepicker$Internal$TestHelper$todayAttr,
					$fabhof$elm_ui_datepicker$DatePicker$extAttrs(settings.gx)) : _List_Nil,
					_Utils_eq(
					config.n_,
					$elm$core$Maybe$Just(day)) ? A2(
					$elm$core$List$cons,
					$fabhof$elm_ui_datepicker$Internal$TestHelper$selectedAttr,
					$fabhof$elm_ui_datepicker$DatePicker$extAttrs(settings.ge)) : _List_Nil,
					settings.fB(day) ? $fabhof$elm_ui_datepicker$DatePicker$extAttrs(settings.fC) : _List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Events$onClick(
						config.mK(
							$fabhof$elm_ui_datepicker$DatePicker$DateChanged(day))),
						$mdgriffith$elm_ui$Element$pointer
					])
				]));
		return A2(
			$mdgriffith$elm_ui$Element$el,
			attributesForThisDay,
			$mdgriffith$elm_ui$Element$text(
				A3($fabhof$elm_ui_datepicker$Internal$Date$formatMaybeLanguage, settings.mb, 'dd', day)));
	});
var $fabhof$elm_ui_datepicker$Internal$Week$getDay = F2(
	function (index, _v0) {
		var week = _v0;
		switch (index) {
			case 0:
				return week.cV;
			case 1:
				return week.cW;
			case 2:
				return week.cX;
			case 3:
				return week.cY;
			case 4:
				return week.cZ;
			case 5:
				return week.c_;
			default:
				return week.c$;
		}
	});
var $fabhof$elm_ui_datepicker$Internal$Week$Day0 = 0;
var $fabhof$elm_ui_datepicker$Internal$Week$Day1 = 1;
var $fabhof$elm_ui_datepicker$Internal$Week$Day2 = 2;
var $fabhof$elm_ui_datepicker$Internal$Week$Day3 = 3;
var $fabhof$elm_ui_datepicker$Internal$Week$Day4 = 4;
var $fabhof$elm_ui_datepicker$Internal$Week$Day5 = 5;
var $fabhof$elm_ui_datepicker$Internal$Week$Day6 = 6;
var $fabhof$elm_ui_datepicker$Internal$Week$indexedMap = F2(
	function (fn, _v0) {
		var week = _v0;
		return {
			cV: A2(fn, 0, week.cV),
			cW: A2(fn, 1, week.cW),
			cX: A2(fn, 2, week.cX),
			cY: A2(fn, 3, week.cY),
			cZ: A2(fn, 4, week.cZ),
			c_: A2(fn, 5, week.c_),
			c$: A2(fn, 6, week.c$)
		};
	});
var $fabhof$elm_ui_datepicker$Internal$Week$toList = function (_v0) {
	var week = _v0;
	return _List_fromArray(
		[week.cV, week.cW, week.cX, week.cY, week.cZ, week.c_, week.c$]);
};
var $fabhof$elm_ui_datepicker$DatePicker$pickerColumns = function (config) {
	var weekdays = A2($fabhof$elm_ui_datepicker$Internal$Week$calendarWeekDays, config.n2.ee, config.n2.mb);
	var toColumn = F2(
		function (index, weekday) {
			return {
				hE: A2(
					$mdgriffith$elm_ui$Element$el,
					$fabhof$elm_ui_datepicker$DatePicker$extAttrs(config.n2.gB),
					$mdgriffith$elm_ui$Element$text(weekday)),
				oZ: function (week) {
					return A2(
						$fabhof$elm_ui_datepicker$DatePicker$dayView,
						config,
						A2($fabhof$elm_ui_datepicker$Internal$Week$getDay, index, week));
				},
				fg: $mdgriffith$elm_ui$Element$fill
			};
		});
	return $fabhof$elm_ui_datepicker$Internal$Week$toList(
		A2($fabhof$elm_ui_datepicker$Internal$Week$indexedMap, toColumn, weekdays));
};
var $mdgriffith$elm_ui$Element$InternalColumn = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$GridPosition = function (a) {
	return {$: 9, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$GridTemplateStyle = function (a) {
	return {$: 8, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$AsGrid = 3;
var $mdgriffith$elm_ui$Internal$Model$asGrid = 3;
var $mdgriffith$elm_ui$Internal$Model$getSpacing = F2(
	function (attrs, _default) {
		return A2(
			$elm$core$Maybe$withDefault,
			_default,
			A3(
				$elm$core$List$foldr,
				F2(
					function (attr, acc) {
						if (!acc.$) {
							var x = acc.a;
							return $elm$core$Maybe$Just(x);
						} else {
							if ((attr.$ === 4) && (attr.b.$ === 5)) {
								var _v2 = attr.b;
								var x = _v2.b;
								var y = _v2.c;
								return $elm$core$Maybe$Just(
									_Utils_Tuple2(x, y));
							} else {
								return $elm$core$Maybe$Nothing;
							}
						}
					}),
				$elm$core$Maybe$Nothing,
				attrs));
	});
var $mdgriffith$elm_ui$Internal$Flag$gridPosition = $mdgriffith$elm_ui$Internal$Flag$flag(35);
var $mdgriffith$elm_ui$Internal$Flag$gridTemplate = $mdgriffith$elm_ui$Internal$Flag$flag(34);
var $elm$core$List$repeatHelp = F3(
	function (result, n, value) {
		repeatHelp:
		while (true) {
			if (n <= 0) {
				return result;
			} else {
				var $temp$result = A2($elm$core$List$cons, value, result),
					$temp$n = n - 1,
					$temp$value = value;
				result = $temp$result;
				n = $temp$n;
				value = $temp$value;
				continue repeatHelp;
			}
		}
	});
var $elm$core$List$repeat = F2(
	function (n, value) {
		return A3($elm$core$List$repeatHelp, _List_Nil, n, value);
	});
var $mdgriffith$elm_ui$Element$tableHelper = F2(
	function (attrs, config) {
		var onGrid = F3(
			function (rowLevel, columnLevel, elem) {
				return A4(
					$mdgriffith$elm_ui$Internal$Model$element,
					$mdgriffith$elm_ui$Internal$Model$asEl,
					$mdgriffith$elm_ui$Internal$Model$div,
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Model$StyleClass,
							$mdgriffith$elm_ui$Internal$Flag$gridPosition,
							$mdgriffith$elm_ui$Internal$Model$GridPosition(
								{ft: columnLevel, en: 1, iP: rowLevel, fg: 1}))
						]),
					$mdgriffith$elm_ui$Internal$Model$Unkeyed(
						_List_fromArray(
							[elem])));
			});
		var columnWidth = function (col) {
			if (!col.$) {
				var colConfig = col.a;
				return colConfig.fg;
			} else {
				var colConfig = col.a;
				return colConfig.fg;
			}
		};
		var columnHeader = function (col) {
			if (!col.$) {
				var colConfig = col.a;
				return colConfig.hE;
			} else {
				var colConfig = col.a;
				return colConfig.hE;
			}
		};
		var maybeHeaders = function (headers) {
			return A2(
				$elm$core$List$all,
				$elm$core$Basics$eq($mdgriffith$elm_ui$Internal$Model$Empty),
				headers) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
				A2(
					$elm$core$List$indexedMap,
					F2(
						function (col, header) {
							return A3(onGrid, 1, col + 1, header);
						}),
					headers));
		}(
			A2($elm$core$List$map, columnHeader, config.cP));
		var add = F3(
			function (cell, columnConfig, cursor) {
				if (!columnConfig.$) {
					var col = columnConfig.a;
					return _Utils_update(
						cursor,
						{
							aZ: cursor.aZ + 1,
							aK: A2(
								$elm$core$List$cons,
								A3(
									onGrid,
									cursor.iP,
									cursor.aZ,
									A2(
										col.oZ,
										_Utils_eq(maybeHeaders, $elm$core$Maybe$Nothing) ? (cursor.iP - 1) : (cursor.iP - 2),
										cell)),
								cursor.aK)
						});
				} else {
					var col = columnConfig.a;
					return {
						aZ: cursor.aZ + 1,
						aK: A2(
							$elm$core$List$cons,
							A3(
								onGrid,
								cursor.iP,
								cursor.aZ,
								col.oZ(cell)),
							cursor.aK),
						iP: cursor.iP
					};
				}
			});
		var build = F3(
			function (columns, rowData, cursor) {
				var newCursor = A3(
					$elm$core$List$foldl,
					add(rowData),
					cursor,
					columns);
				return {aZ: 1, aK: newCursor.aK, iP: cursor.iP + 1};
			});
		var children = A3(
			$elm$core$List$foldl,
			build(config.cP),
			{
				aZ: 1,
				aK: _List_Nil,
				iP: _Utils_eq(maybeHeaders, $elm$core$Maybe$Nothing) ? 1 : 2
			},
			config.k_);
		var _v0 = A2(
			$mdgriffith$elm_ui$Internal$Model$getSpacing,
			attrs,
			_Utils_Tuple2(0, 0));
		var sX = _v0.a;
		var sY = _v0.b;
		var template = A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$gridTemplate,
			$mdgriffith$elm_ui$Internal$Model$GridTemplateStyle(
				{
					cP: A2($elm$core$List$map, columnWidth, config.cP),
					nP: A2(
						$elm$core$List$repeat,
						$elm$core$List$length(config.k_),
						$mdgriffith$elm_ui$Internal$Model$Content),
					gn: _Utils_Tuple2(
						$mdgriffith$elm_ui$Element$px(sX),
						$mdgriffith$elm_ui$Element$px(sY))
				}));
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asGrid,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				A2($elm$core$List$cons, template, attrs)),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(
				function () {
					if (maybeHeaders.$ === 1) {
						return children.aK;
					} else {
						var renderedHeaders = maybeHeaders.a;
						return _Utils_ap(
							renderedHeaders,
							$elm$core$List$reverse(children.aK));
					}
				}()));
	});
var $mdgriffith$elm_ui$Element$table = F2(
	function (attrs, config) {
		return A2(
			$mdgriffith$elm_ui$Element$tableHelper,
			attrs,
			{
				cP: A2($elm$core$List$map, $mdgriffith$elm_ui$Element$InternalColumn, config.cP),
				k_: config.k_
			});
	});
var $fabhof$elm_ui_datepicker$Internal$TestHelper$tableAttrHtml = $fabhof$elm_ui_datepicker$Internal$TestHelper$testAttribute('table');
var $fabhof$elm_ui_datepicker$Internal$TestHelper$tableAttr = $mdgriffith$elm_ui$Element$htmlAttribute($fabhof$elm_ui_datepicker$Internal$TestHelper$tableAttrHtml);
var $fabhof$elm_ui_datepicker$Internal$Week$weeksInMonth = F2(
	function (month, firstDayOfWeek) {
		var weekdayInterval = $fabhof$elm_ui_datepicker$Internal$Week$weekdayToInterval(firstDayOfWeek);
		var weekDays = function (startDay) {
			return A4(
				$justinmimbs$date$Date$range,
				11,
				1,
				startDay,
				A3($justinmimbs$date$Date$add, 3, 7, startDay));
		};
		var toWeek = function (list) {
			return A2(
				$fabhof$elm_ui_datepicker$Internal$Week$fromListWithDefault,
				A2($justinmimbs$date$Date$fromOrdinalDate, 2020, 1),
				list);
		};
		var firstOfMonth = A3(
			$justinmimbs$date$Date$fromCalendarDate,
			$justinmimbs$date$Date$year(month),
			$justinmimbs$date$Date$month(month),
			1);
		var start = A2($justinmimbs$date$Date$floor, weekdayInterval, firstOfMonth);
		var end = A2(
			$justinmimbs$date$Date$ceiling,
			weekdayInterval,
			A3($justinmimbs$date$Date$add, 1, 1, firstOfMonth));
		return A2(
			$elm$core$List$map,
			A2($elm$core$Basics$composeR, weekDays, toWeek),
			A4($justinmimbs$date$Date$range, 11, 7, start, end));
	});
var $fabhof$elm_ui_datepicker$DatePicker$pickerTable = function (config) {
	var settings = config.n2;
	return A2(
		$mdgriffith$elm_ui$Element$table,
		A2(
			$elm$core$List$cons,
			$fabhof$elm_ui_datepicker$Internal$TestHelper$tableAttr,
			$fabhof$elm_ui_datepicker$DatePicker$extAttrs(settings.gq)),
		{
			cP: $fabhof$elm_ui_datepicker$DatePicker$pickerColumns(config),
			k_: A2($fabhof$elm_ui_datepicker$Internal$Week$weeksInMonth, config.an, config.n2.ee)
		});
};
var $fabhof$elm_ui_datepicker$DatePicker$NothingToDo = {$: 3};
var $fabhof$elm_ui_datepicker$DatePicker$preventDefaultOnMouseDown = function (config) {
	return $mdgriffith$elm_ui$Element$htmlAttribute(
		A2(
			$elm$html$Html$Events$preventDefaultOn,
			'mousedown',
			$elm$json$Json$Decode$succeed(
				_Utils_Tuple2(
					config.mK(
						$fabhof$elm_ui_datepicker$DatePicker$PickerChanged($fabhof$elm_ui_datepicker$DatePicker$NothingToDo)),
					true))));
};
var $fabhof$elm_ui_datepicker$DatePicker$pickerView = function (config) {
	var settings = config.n2;
	return _List_fromArray(
		[
			$mdgriffith$elm_ui$Element$below(
			A2(
				$mdgriffith$elm_ui$Element$column,
				A2(
					$elm$core$List$cons,
					$fabhof$elm_ui_datepicker$Internal$TestHelper$calendarAttr,
					A2(
						$elm$core$List$cons,
						$fabhof$elm_ui_datepicker$DatePicker$preventDefaultOnMouseDown(config),
						$fabhof$elm_ui_datepicker$DatePicker$extAttrs(settings.ns))),
				_List_fromArray(
					[
						$fabhof$elm_ui_datepicker$DatePicker$pickerHeader(config),
						$fabhof$elm_ui_datepicker$DatePicker$pickerTable(config)
					])))
		]);
};
var $fabhof$elm_ui_datepicker$DatePicker$input = F2(
	function (attributes, inputConfig) {
		var settings = inputConfig.n2;
		var model = inputConfig.mv;
		var label = inputConfig.ma;
		var placeholder = inputConfig.nt;
		var selected = inputConfig.n_;
		var onChange = inputConfig.mK;
		var _v0 = model;
		var picker = _v0;
		var config = {ma: label, mK: onChange, f7: picker, nt: placeholder, n_: selected, n2: settings, bJ: inputConfig.bJ, an: picker.an};
		var inputAttributes = picker.nh ? attributes : _Utils_ap(
			attributes,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Events$onClick(
					onChange(
						$fabhof$elm_ui_datepicker$DatePicker$PickerChanged($fabhof$elm_ui_datepicker$DatePicker$Open)))
				]));
		var pickerEl = picker.nh ? $fabhof$elm_ui_datepicker$DatePicker$pickerView(config) : _List_Nil;
		return A2(
			$mdgriffith$elm_ui$Element$Input$text,
			_Utils_ap(
				inputAttributes,
				_Utils_ap(
					pickerEl,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Events$onFocus(
							onChange(
								$fabhof$elm_ui_datepicker$DatePicker$PickerChanged($fabhof$elm_ui_datepicker$DatePicker$Open))),
							$mdgriffith$elm_ui$Element$Events$onLoseFocus(
							onChange(
								$fabhof$elm_ui_datepicker$DatePicker$PickerChanged($fabhof$elm_ui_datepicker$DatePicker$Close))),
							$fabhof$elm_ui_datepicker$Internal$TestHelper$inputAttr
						]))),
			{
				ma: label,
				mK: A2($elm$core$Basics$composeL, onChange, $fabhof$elm_ui_datepicker$DatePicker$TextChanged),
				nt: placeholder,
				bJ: config.bJ
			});
	});
var $author$project$Components$DatePicker$view_calendarAndDateInput = function (model) {
	var label = A2(
		$elm$core$Maybe$withDefault,
		$mdgriffith$elm_ui$Element$Input$labelHidden('date'),
		A2(
			$elm$core$Maybe$map,
			A2(
				$elm$core$Basics$composeR,
				$mdgriffith$elm_ui$Element$text,
				$mdgriffith$elm_ui$Element$Input$labelAbove(_List_Nil)),
			model.fV));
	var inputHeight = model.eN ? 0 : 28;
	var defaultSettings = $fabhof$elm_ui_datepicker$DatePicker$defaultSettings;
	var backgroundColor = $author$project$Colors$surface(0);
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width(
				$mdgriffith$elm_ui$Element$px(250)),
				$mdgriffith$elm_ui$Element$height(
				$mdgriffith$elm_ui$Element$px(230 + inputHeight)),
				$mdgriffith$elm_ui$Element$Background$color(backgroundColor),
				$mdgriffith$elm_ui$Element$Border$rounded(8),
				$mdgriffith$elm_ui$Element$Font$color(
				$author$project$Colors$onSurface(4)),
				$mdgriffith$elm_ui$Element$Font$size(18),
				$mdgriffith$elm_ui$Element$padding(8)
			]),
		A2(
			$fabhof$elm_ui_datepicker$DatePicker$input,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$height(
						$mdgriffith$elm_ui$Element$px(inputHeight)),
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$Background$color(
						$author$project$Colors$surface(1)),
						$mdgriffith$elm_ui$Element$Font$color(
						$author$project$Colors$onSurface(9)),
						$mdgriffith$elm_ui$Element$Border$width(0),
						$mdgriffith$elm_ui$Element$padding(4),
						$mdgriffith$elm_ui$Element$htmlAttribute($author$project$Components$DatePicker$handleKeyDown),
						$author$project$Utils$HtmlUtil$id('date-input')
					]),
				model.eN ? _List_fromArray(
					[
						$mdgriffith$elm_ui$Element$htmlAttribute(
						A2($elm$html$Html$Attributes$style, 'visibility', 'hidden')),
						$mdgriffith$elm_ui$Element$focused(_List_Nil)
					]) : _List_Nil),
			{
				ma: label,
				mv: model.bD,
				mK: $author$project$Components$DatePicker$UserChangedDatePicker,
				nt: $elm$core$Maybe$Just(
					A2(
						$mdgriffith$elm_ui$Element$Input$placeholder,
						_List_Nil,
						$mdgriffith$elm_ui$Element$text('vvvv-kk-pp'))),
				n_: model.b4,
				n2: _Utils_update(
					defaultSettings,
					{
						mb: $elm$core$Maybe$Just($author$project$Language$finnishDates),
						ns: _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Border$rounded(8),
								$mdgriffith$elm_ui$Element$moveDown(3),
								$mdgriffith$elm_ui$Element$padding(8),
								$mdgriffith$elm_ui$Element$Background$color(backgroundColor),
								$mdgriffith$elm_ui$Element$spacing(4)
							])
					}),
				bJ: model.cU
			}));
};
var $author$project$Components$DatePicker$view = function (model) {
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$spacing(4)
			]),
		A2(
			$elm$core$List$cons,
			$author$project$Components$DatePicker$view_calendarAndDateInput(model),
			model.fl ? _List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$centerX]),
					$author$project$Components$Button$toElement(
						$author$project$Components$Button$secondary(
							A2(
								$author$project$Components$Button$textSize,
								$author$project$Styles$fontSizesInPixels.mc,
								A2(
									$author$project$Components$Button$text,
									'EI PÄIVÄMÄÄRÄÄ',
									A2($author$project$Components$Button$onClick, $author$project$Components$DatePicker$SelectNothing, $author$project$Components$Button$button))))))
				]) : _List_Nil));
};
var $author$project$Pages$Invoicing$view_changeDatesDialog = F3(
	function (dialogTitle, datePickerMsgToMsg, datePickerModel) {
		return A2(
			$author$project$Components$Modal$modal,
			{kz: true, mR: $author$project$Pages$Invoicing$HideDialog},
			A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Background$color($author$project$Colors$background),
						$mdgriffith$elm_ui$Element$padding(10),
						$mdgriffith$elm_ui$Element$Border$rounded(8),
						$mdgriffith$elm_ui$Element$spacing(8)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$Font$bold]),
								$mdgriffith$elm_ui$Element$text(dialogTitle)),
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$alignRight,
										$mdgriffith$elm_ui$Element$Font$color(
										$author$project$Colors$onSurface(5))
									]),
								$author$project$Components$CloseButton$closeButton($author$project$Pages$Invoicing$HideDialog))
							])),
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$centerX]),
						A2(
							$mdgriffith$elm_ui$Element$map,
							datePickerMsgToMsg,
							$author$project$Components$DatePicker$view(datePickerModel)))
					])));
	});
var $author$project$Pages$Invoicing$InvoiceSelectedTasklists = {$: 8};
var $author$project$Pages$Invoicing$ShowDeliveryDatesDialog = {$: 11};
var $author$project$Pages$Invoicing$ShowInvoiceDatesDialog = {$: 12};
var $author$project$Icons$calendar = $author$project$Icons$fa_icon('far fa-calendar-alt');
var $author$project$Styles$card_wrapper = F2(
	function (stretch, contents) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$padding(8),
						$mdgriffith$elm_ui$Element$Border$rounded(8),
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$centerY,
						$mdgriffith$elm_ui$Element$Background$color(
						$author$project$Colors$surface(0)),
						$mdgriffith$elm_ui$Element$Border$shadow(
						{
							fp: 1,
							fu: A4($mdgriffith$elm_ui$Element$rgba, 0, 0, 0, 0.1),
							f1: _Utils_Tuple2(1, 1),
							gl: 2
						})
					]),
				stretch ? _List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
					]) : _List_Nil),
			contents);
	});
var $author$project$Icons$invoice = $author$project$Icons$fa_icon('fas fa-file-invoice-dollar');
var $author$project$Pages$Invoicing$InvoiceTasklist = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$Invoicing$ReopenTasklist = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$Invoicing$ToggleSelectAllTasklists = {$: 2};
var $mdgriffith$elm_ui$Element$Input$tabindex = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Attr, $elm$html$Html$Attributes$tabindex);
var $mdgriffith$elm_ui$Element$Input$checkbox = F2(
	function (attrs, _v0) {
		var label = _v0.ma;
		var icon = _v0.cc;
		var checked = _v0.kv;
		var onChange = _v0.mK;
		var attributes = _Utils_ap(
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Input$isHiddenLabel(label) ? $mdgriffith$elm_ui$Internal$Model$NoAttribute : $mdgriffith$elm_ui$Element$spacing(6),
					$mdgriffith$elm_ui$Internal$Model$Attr(
					$elm$html$Html$Events$onClick(
						onChange(!checked))),
					$mdgriffith$elm_ui$Element$Region$announce,
					$mdgriffith$elm_ui$Element$Input$onKeyLookup(
					function (code) {
						return _Utils_eq(code, $mdgriffith$elm_ui$Element$Input$enter) ? $elm$core$Maybe$Just(
							onChange(!checked)) : (_Utils_eq(code, $mdgriffith$elm_ui$Element$Input$space) ? $elm$core$Maybe$Just(
							onChange(!checked)) : $elm$core$Maybe$Nothing);
					}),
					$mdgriffith$elm_ui$Element$Input$tabindex(0),
					$mdgriffith$elm_ui$Element$pointer,
					$mdgriffith$elm_ui$Element$alignLeft,
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			attrs);
		return A3(
			$mdgriffith$elm_ui$Element$Input$applyLabel,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$Attr(
					A2($elm$html$Html$Attributes$attribute, 'role', 'checkbox')),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Internal$Model$Attr(
						A2(
							$elm$html$Html$Attributes$attribute,
							'aria-checked',
							checked ? 'true' : 'false')),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$Input$hiddenLabelAttribute(label),
						attributes))),
			label,
			A4(
				$mdgriffith$elm_ui$Internal$Model$element,
				$mdgriffith$elm_ui$Internal$Model$asEl,
				$mdgriffith$elm_ui$Internal$Model$div,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$centerY,
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink)
					]),
				$mdgriffith$elm_ui$Internal$Model$Unkeyed(
					_List_fromArray(
						[
							icon(checked)
						]))));
	});
var $author$project$Components$Checkbox$checkbox = F3(
	function (label, onChange, isChecked) {
		var icon = function (checked) {
			return A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width(
						$mdgriffith$elm_ui$Element$px(14))
					]),
				checked ? A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(12),
							$mdgriffith$elm_ui$Element$Font$color(
							$author$project$Colors$onSurface(7))
						]),
					$author$project$Icons$check) : $mdgriffith$elm_ui$Element$none);
		};
		return A2(
			$mdgriffith$elm_ui$Element$Input$checkbox,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width(
					$mdgriffith$elm_ui$Element$px(18)),
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px(18)),
					$mdgriffith$elm_ui$Element$padding(3),
					$mdgriffith$elm_ui$Element$Border$rounded(2),
					$mdgriffith$elm_ui$Element$Background$color(
					isChecked ? $author$project$Colors$secondary(0) : $author$project$Colors$onSurface(0))
				]),
			{kv: isChecked, cc: icon, ma: label, mK: onChange});
	});
var $author$project$Types$Customer$getCustomer = F2(
	function (customers, customerId) {
		var mockCustomer = {kY: 1, er: 0, mD: '?'};
		return A2(
			$elm$core$Maybe$withDefault,
			mockCustomer,
			A2(
				$author$project$Helpers$firstWhere,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.er;
					},
					$elm$core$Basics$eq(customerId)),
				customers));
	});
var $author$project$Pages$Invoicing$getEndCustomerName = F2(
	function (customers, tasklist) {
		var customer = A2($author$project$Types$Customer$getCustomer, customers, tasklist.kX);
		var _v0 = customer.kY;
		if (!_v0) {
			return customer.mD;
		} else {
			return tasklist.nf;
		}
	});
var $author$project$Types$SelectedProduct$productsTotal = F2(
	function (priceInputMode, products) {
		return function (vatlessTotal) {
			if (priceInputMode === 1) {
				return vatlessTotal;
			} else {
				return $author$project$Helpers$roundCurrency(vatlessTotal * 1.255);
			}
		}(
			$elm$core$List$sum(
				A2(
					$elm$core$List$map,
					function (maybePrice) {
						if (!maybePrice.$) {
							var price = maybePrice.a;
							return price;
						} else {
							return 0;
						}
					},
					A2(
						$elm$core$List$map,
						function ($) {
							return $.iv;
						},
						products))));
	});
var $author$project$Pages$Invoicing$SelectTasklist = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Pages$Invoicing$rowCheckbox = F2(
	function (tasklist, isChecked) {
		return A3(
			$author$project$Components$Checkbox$checkbox,
			$mdgriffith$elm_ui$Element$Input$labelHidden('Valitse lomake'),
			function (checked) {
				return A2($author$project$Pages$Invoicing$SelectTasklist, tasklist, checked);
			},
			isChecked);
	});
var $author$project$Types$Tasklist$tasksTotal = F2(
	function (priceInputMode, tasks) {
		return $elm$core$List$sum(
			A2(
				$elm$core$List$map,
				$author$project$Types$SelectedProduct$productsTotal(priceInputMode),
				A2(
					$elm$core$List$map,
					function ($) {
						return $.iA;
					},
					tasks)));
	});
var $author$project$Pages$Invoicing$v_closedTable = function (model) {
	return A4(
		$author$project$OldTable$oldTable,
		$elm$core$Dict$values(model.r),
		_List_Nil,
		_List_fromArray(
			[
				{
				g4: function (tasklist) {
					return A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$centerX]),
						A2(
							$author$project$Pages$Invoicing$rowCheckbox,
							tasklist,
							A2($elm$core$Dict$member, tasklist.er, model.w)));
				},
				hE: function () {
					var selectedCount = $elm$core$Dict$size(model.w);
					var allCount = $elm$core$Dict$size(model.r);
					var isChecked = _Utils_eq(selectedCount, allCount);
					return A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$centerX]),
						A3(
							$author$project$Components$Checkbox$checkbox,
							$mdgriffith$elm_ui$Element$Input$labelHidden('Valitse kaikki lomakkeet'),
							$elm$core$Basics$always($author$project$Pages$Invoicing$ToggleSelectAllTasklists),
							isChecked));
				}(),
				dx: _List_Nil,
				fg: $mdgriffith$elm_ui$Element$px(30)
			},
				{
				g4: function (tasklist) {
					return A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$alignLeft]),
						$mdgriffith$elm_ui$Element$text(
							$elm$core$String$fromInt(tasklist.er)));
				},
				hE: A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$alignLeft]),
					$mdgriffith$elm_ui$Element$text('#')),
				dx: _List_Nil,
				fg: $mdgriffith$elm_ui$Element$px(60)
			},
				{
				g4: A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.gA;
					},
					A2(
						$elm$core$Basics$composeR,
						$author$project$Types$User$fullName,
						A2(
							$elm$core$Basics$composeR,
							$mdgriffith$elm_ui$Element$text,
							$mdgriffith$elm_ui$Element$el(
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$alignLeft]))))),
				hE: A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$alignLeft]),
					$mdgriffith$elm_ui$Element$text('Käyttäjä')),
				dx: _List_Nil,
				fg: $mdgriffith$elm_ui$Element$px(120)
			},
				{
				g4: function (tasklist) {
					return A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$alignLeft]),
						$mdgriffith$elm_ui$Element$text(
							A2($author$project$Pages$Invoicing$getEndCustomerName, model.ap, tasklist)));
				},
				hE: A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$alignLeft]),
					$mdgriffith$elm_ui$Element$text('Asiakas')),
				dx: _List_Nil,
				fg: $mdgriffith$elm_ui$Element$px(200)
			},
				{
				g4: function (tasklist) {
					return A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$alignLeft]),
						$mdgriffith$elm_ui$Element$text(tasklist.mD));
				},
				hE: A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$alignLeft]),
					$mdgriffith$elm_ui$Element$text('Lomake')),
				dx: _List_Nil,
				fg: $mdgriffith$elm_ui$Element$px(200)
			},
				{
				g4: function (tasklist) {
					return A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$alignRight]),
						$mdgriffith$elm_ui$Element$text(
							$elm$core$String$fromInt(
								$elm$core$List$length(tasklist.aj))));
				},
				hE: A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$alignRight]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerX]),
							$mdgriffith$elm_ui$Element$text('Työt')),
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$Font$medium]),
							$mdgriffith$elm_ui$Element$text(' kpl'))
						])),
				dx: _List_Nil,
				fg: $mdgriffith$elm_ui$Element$px(40)
			},
				{
				g4: function (tasklist) {
					return A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$alignRight]),
						$mdgriffith$elm_ui$Element$text(
							$author$project$Helpers$toEuroString(
								$elm$core$List$sum(
									A2(
										$elm$core$List$map,
										A2(
											$elm$core$Basics$composeR,
											function ($) {
												return $.iA;
											},
											$author$project$Types$SelectedProduct$productsTotal(1)),
										tasklist.aj)))));
				},
				hE: A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$alignRight]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerX]),
							$mdgriffith$elm_ui$Element$text('Hinta')),
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$Font$medium]),
							$mdgriffith$elm_ui$Element$text('(alv 0%)'))
						])),
				dx: _List_Nil,
				fg: $mdgriffith$elm_ui$Element$px(80)
			},
				{
				g4: A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.fA;
					},
					A2(
						$elm$core$Basics$composeR,
						$elm$core$Maybe$map(
							$justinmimbs$date$Date$format('dd.MM.y')),
						A2(
							$elm$core$Basics$composeR,
							$elm$core$Maybe$withDefault('-'),
							A2(
								$elm$core$Basics$composeR,
								$mdgriffith$elm_ui$Element$text,
								$mdgriffith$elm_ui$Element$el(
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$centerX])))))),
				hE: $mdgriffith$elm_ui$Element$text('Toimituspäivä'),
				dx: _List_Nil,
				fg: $mdgriffith$elm_ui$Element$px(150)
			},
				{
				g4: A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.fT;
					},
					A2(
						$elm$core$Basics$composeR,
						$elm$core$Maybe$map(
							$justinmimbs$date$Date$format('dd.MM.y')),
						A2(
							$elm$core$Basics$composeR,
							$elm$core$Maybe$withDefault('-'),
							A2(
								$elm$core$Basics$composeR,
								$mdgriffith$elm_ui$Element$text,
								$mdgriffith$elm_ui$Element$el(
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$centerX])))))),
				hE: $mdgriffith$elm_ui$Element$text('Laskupäivä'),
				dx: _List_Nil,
				fg: $mdgriffith$elm_ui$Element$px(150)
			},
				{
				g4: A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.fs;
					},
					A2(
						$elm$core$Basics$composeR,
						$elm$core$Maybe$map($author$project$Types$IsoTime$toString),
						A2(
							$elm$core$Basics$composeR,
							$elm$core$Maybe$withDefault('-'),
							A2(
								$elm$core$Basics$composeR,
								$mdgriffith$elm_ui$Element$text,
								$mdgriffith$elm_ui$Element$el(
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$alignLeft])))))),
				hE: $mdgriffith$elm_ui$Element$text('Suljettu'),
				dx: _List_Nil,
				fg: $mdgriffith$elm_ui$Element$px(150)
			},
				{
				g4: function (tasklist) {
					return A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Font$size(18),
								$mdgriffith$elm_ui$Element$spacing(2)
							]),
						_List_fromArray(
							[
								$author$project$Components$Button$toElement(
								A2(
									$author$project$Components$Button$tooltip,
									'Avaa lomake uudelleen',
									$author$project$Components$Button$secondary(
										A2(
											$author$project$Components$Button$icon,
											$author$project$Icons$lockOpen,
											A2(
												$author$project$Components$Button$onClick,
												$author$project$Pages$Invoicing$ReopenTasklist(tasklist),
												$author$project$Components$Button$button))))),
								$author$project$Components$Button$toElement(
								A2(
									$author$project$Components$Button$tooltip,
									'Laskuta lomake',
									$author$project$Components$Button$primary(
										A2(
											$author$project$Components$Button$icon,
											$author$project$Icons$invoice,
											A2(
												$author$project$Components$Button$onClick,
												$author$project$Pages$Invoicing$InvoiceTasklist(tasklist),
												$author$project$Components$Button$button)))))
							]));
				},
				hE: $mdgriffith$elm_ui$Element$text(''),
				dx: _List_Nil,
				fg: $mdgriffith$elm_ui$Element$px(60)
			}
			]),
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$none,
				$mdgriffith$elm_ui$Element$none,
				$mdgriffith$elm_ui$Element$none,
				$mdgriffith$elm_ui$Element$none,
				$mdgriffith$elm_ui$Element$none,
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width(
						$mdgriffith$elm_ui$Element$px(663))
					]),
				$mdgriffith$elm_ui$Element$none),
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width(
						$mdgriffith$elm_ui$Element$px(40)),
						$mdgriffith$elm_ui$Element$paddingEach(
						{g1: 0, h1: 0, iN: 0, jl: 0})
					]),
				$mdgriffith$elm_ui$Element$text(
					$elm$core$String$fromInt(
						$elm$core$List$length(
							A3(
								$elm$core$List$foldl,
								$elm$core$Basics$append,
								_List_Nil,
								A2(
									$elm$core$List$map,
									function ($) {
										return $.aj;
									},
									A2(
										$elm$core$List$map,
										$elm$core$Tuple$second,
										$elm$core$Dict$toList(model.r)))))))),
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width(
						$mdgriffith$elm_ui$Element$px(67)),
						$mdgriffith$elm_ui$Element$paddingEach(
						{g1: 0, h1: 0, iN: 0, jl: 0})
					]),
				$mdgriffith$elm_ui$Element$text(
					$author$project$Helpers$toEuroString(
						$elm$core$List$sum(
							A2(
								$elm$core$List$map,
								$author$project$Types$Tasklist$tasksTotal(1),
								A2(
									$elm$core$List$map,
									function ($) {
										return $.aj;
									},
									$elm$core$Dict$values(model.r))))))),
				$mdgriffith$elm_ui$Element$none,
				$mdgriffith$elm_ui$Element$none
			]));
};
var $author$project$Pages$Invoicing$view_closedTasklists = function (model) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$alignTop,
				$mdgriffith$elm_ui$Element$paddingEach(
				{g1: 0, h1: 0, iN: 0, jl: 10})
			]),
		A2(
			$author$project$Styles$card_wrapper,
			false,
			A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$spacing(6)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$paddingEach(
								{g1: 6, h1: 6, iN: 0, jl: 4}),
								$mdgriffith$elm_ui$Element$spacing(8),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						_List_fromArray(
							[
								$author$project$Components$Button$toElement(
								A2(
									$author$project$Components$Button$tooltip,
									'Muuta valittujen lomakkeiden laskutuspäivä',
									$author$project$Components$Button$primary(
										A2(
											$author$project$Components$Button$icon,
											$author$project$Icons$calendar,
											A2($author$project$Components$Button$onClick, $author$project$Pages$Invoicing$ShowInvoiceDatesDialog, $author$project$Components$Button$button))))),
								$author$project$Components$Button$toElement(
								A2(
									$author$project$Components$Button$tooltip,
									'Laskuta valitut lomakkeet',
									$author$project$Components$Button$primary(
										A2(
											$author$project$Components$Button$icon,
											$author$project$Icons$invoice,
											A2($author$project$Components$Button$onClick, $author$project$Pages$Invoicing$InvoiceSelectedTasklists, $author$project$Components$Button$button))))),
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$alignRight]),
								$author$project$Components$Button$toElement(
									A2(
										$author$project$Components$Button$tooltip,
										'Muuta valittujen lomakkeiden toimituspäivä',
										$author$project$Components$Button$secondary(
											A2(
												$author$project$Components$Button$icon,
												$author$project$Icons$calendar,
												A2($author$project$Components$Button$onClick, $author$project$Pages$Invoicing$ShowDeliveryDatesDialog, $author$project$Components$Button$button))))))
							])),
						$author$project$Pages$Invoicing$v_closedTable(model)
					]))));
};
var $author$project$Pages$Invoicing$v_invoiceQueuedTable = function (model) {
	return A4(
		$author$project$OldTable$oldTable,
		$elm$core$Dict$values(model.U),
		_List_Nil,
		_List_fromArray(
			[
				{
				g4: function (tasklist) {
					return A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$alignLeft]),
						$mdgriffith$elm_ui$Element$text(
							$elm$core$String$fromInt(tasklist.er)));
				},
				hE: A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$alignLeft]),
					$mdgriffith$elm_ui$Element$text('#')),
				dx: _List_Nil,
				fg: $mdgriffith$elm_ui$Element$px(60)
			},
				{
				g4: A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.gA;
					},
					A2(
						$elm$core$Basics$composeR,
						$author$project$Types$User$fullName,
						A2(
							$elm$core$Basics$composeR,
							$mdgriffith$elm_ui$Element$text,
							$mdgriffith$elm_ui$Element$el(
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$alignLeft]))))),
				hE: A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$alignLeft]),
					$mdgriffith$elm_ui$Element$text('Käyttäjä')),
				dx: _List_Nil,
				fg: $mdgriffith$elm_ui$Element$px(120)
			},
				{
				g4: function (tasklist) {
					return A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$alignLeft]),
						$mdgriffith$elm_ui$Element$text(
							A2($author$project$Pages$Invoicing$getEndCustomerName, model.ap, tasklist)));
				},
				hE: A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$alignLeft]),
					$mdgriffith$elm_ui$Element$text('Asiakas')),
				dx: _List_Nil,
				fg: $mdgriffith$elm_ui$Element$px(200)
			},
				{
				g4: function (tasklist) {
					return A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$alignLeft]),
						$mdgriffith$elm_ui$Element$text(tasklist.mD));
				},
				hE: A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$alignLeft]),
					$mdgriffith$elm_ui$Element$text('Lomake')),
				dx: _List_Nil,
				fg: $mdgriffith$elm_ui$Element$px(200)
			},
				{
				g4: function (tasklist) {
					return A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$alignRight]),
						$mdgriffith$elm_ui$Element$text(
							$elm$core$String$fromInt(
								$elm$core$List$length(tasklist.aj))));
				},
				hE: A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$alignRight]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerX]),
							$mdgriffith$elm_ui$Element$text('Työt')),
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$Font$medium]),
							$mdgriffith$elm_ui$Element$text(' kpl'))
						])),
				dx: _List_Nil,
				fg: $mdgriffith$elm_ui$Element$px(40)
			},
				{
				g4: function (tasklist) {
					return A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$alignRight]),
						$mdgriffith$elm_ui$Element$text(
							$author$project$Helpers$toEuroString(
								$elm$core$List$sum(
									A2(
										$elm$core$List$map,
										A2(
											$elm$core$Basics$composeR,
											function ($) {
												return $.iA;
											},
											$author$project$Types$SelectedProduct$productsTotal(1)),
										tasklist.aj)))));
				},
				hE: A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$alignRight]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerX]),
							$mdgriffith$elm_ui$Element$text('Hinta')),
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$Font$medium]),
							$mdgriffith$elm_ui$Element$text('(alv 0%)'))
						])),
				dx: _List_Nil,
				fg: $mdgriffith$elm_ui$Element$px(80)
			},
				{
				g4: A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.fA;
					},
					A2(
						$elm$core$Basics$composeR,
						$elm$core$Maybe$map(
							$justinmimbs$date$Date$format('dd.MM.y')),
						A2(
							$elm$core$Basics$composeR,
							$elm$core$Maybe$withDefault('-'),
							A2(
								$elm$core$Basics$composeR,
								$mdgriffith$elm_ui$Element$text,
								$mdgriffith$elm_ui$Element$el(
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$centerX])))))),
				hE: $mdgriffith$elm_ui$Element$text('Toimituspäivä'),
				dx: _List_Nil,
				fg: $mdgriffith$elm_ui$Element$px(150)
			},
				{
				g4: A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.fT;
					},
					A2(
						$elm$core$Basics$composeR,
						$elm$core$Maybe$map(
							$justinmimbs$date$Date$format('dd.MM.y')),
						A2(
							$elm$core$Basics$composeR,
							$elm$core$Maybe$withDefault('-'),
							A2(
								$elm$core$Basics$composeR,
								$mdgriffith$elm_ui$Element$text,
								$mdgriffith$elm_ui$Element$el(
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$centerX])))))),
				hE: $mdgriffith$elm_ui$Element$text('Laskupäivä'),
				dx: _List_Nil,
				fg: $mdgriffith$elm_ui$Element$px(150)
			},
				{
				g4: A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.fs;
					},
					A2(
						$elm$core$Basics$composeR,
						$elm$core$Maybe$map($author$project$Types$IsoTime$toString),
						A2(
							$elm$core$Basics$composeR,
							$elm$core$Maybe$withDefault('-'),
							A2(
								$elm$core$Basics$composeR,
								$mdgriffith$elm_ui$Element$text,
								$mdgriffith$elm_ui$Element$el(
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$alignLeft])))))),
				hE: $mdgriffith$elm_ui$Element$text('Suljettu'),
				dx: _List_Nil,
				fg: $mdgriffith$elm_ui$Element$px(150)
			},
				{
				g4: A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.l_;
					},
					A2(
						$elm$core$Basics$composeR,
						$elm$core$Maybe$map($author$project$Types$IsoTime$toString),
						A2(
							$elm$core$Basics$composeR,
							$elm$core$Maybe$withDefault('-'),
							A2(
								$elm$core$Basics$composeR,
								$mdgriffith$elm_ui$Element$text,
								$mdgriffith$elm_ui$Element$el(
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$alignLeft])))))),
				hE: $mdgriffith$elm_ui$Element$text('Jonoon'),
				dx: _List_Nil,
				fg: $mdgriffith$elm_ui$Element$px(150)
			}
			]),
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$none,
				$mdgriffith$elm_ui$Element$none,
				$mdgriffith$elm_ui$Element$none,
				$mdgriffith$elm_ui$Element$none,
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width(
						$mdgriffith$elm_ui$Element$px(625))
					]),
				$mdgriffith$elm_ui$Element$none),
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width(
						$mdgriffith$elm_ui$Element$px(30)),
						$mdgriffith$elm_ui$Element$paddingEach(
						{g1: 0, h1: 0, iN: 0, jl: 0})
					]),
				$mdgriffith$elm_ui$Element$text(
					$elm$core$String$fromInt(
						$elm$core$List$length(
							A3(
								$elm$core$List$foldl,
								$elm$core$Basics$append,
								_List_Nil,
								A2(
									$elm$core$List$map,
									function ($) {
										return $.aj;
									},
									A2(
										$elm$core$List$map,
										$elm$core$Tuple$second,
										$elm$core$Dict$toList(model.U)))))))),
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width(
						$mdgriffith$elm_ui$Element$px(70)),
						$mdgriffith$elm_ui$Element$paddingEach(
						{g1: 0, h1: 0, iN: 0, jl: 0})
					]),
				$mdgriffith$elm_ui$Element$text(
					$author$project$Helpers$toEuroString(
						$elm$core$List$sum(
							A2(
								$elm$core$List$map,
								$author$project$Types$Tasklist$tasksTotal(1),
								A2(
									$elm$core$List$map,
									function ($) {
										return $.aj;
									},
									$elm$core$Dict$values(model.U))))))),
				$mdgriffith$elm_ui$Element$none
			]));
};
var $author$project$Pages$Invoicing$view_invoiceQueuedTasklists = function (model) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$alignTop,
				$mdgriffith$elm_ui$Element$paddingEach(
				{g1: 0, h1: 0, iN: 0, jl: 10})
			]),
		A2(
			$author$project$Styles$card_wrapper,
			false,
			A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$spacing(6)
					]),
				_List_fromArray(
					[
						$author$project$Pages$Invoicing$v_invoiceQueuedTable(model)
					]))));
};
var $author$project$Types$IsoTime$nullTime = {d5: 0, ep: 0, h6: 0, ex: 0, mw: 0, f1: 0, eX: 0, fi: 0};
var $author$project$Pages$Invoicing$v_invoicedTable = function (model) {
	return A4(
		$author$project$OldTable$oldTable,
		$elm$core$List$reverse(
			A2(
				$elm$core$List$sortBy,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.hX;
					},
					A2(
						$elm$core$Basics$composeR,
						$elm$core$Maybe$withDefault($author$project$Types$IsoTime$nullTime),
						$author$project$Types$IsoTime$toString)),
				$elm$core$Dict$values(model.ae))),
		_List_Nil,
		_List_fromArray(
			[
				{
				g4: function (tasklist) {
					return A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$alignLeft]),
						$mdgriffith$elm_ui$Element$text(
							$elm$core$String$fromInt(tasklist.er)));
				},
				hE: A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$alignLeft]),
					$mdgriffith$elm_ui$Element$text('#')),
				dx: _List_Nil,
				fg: $mdgriffith$elm_ui$Element$px(60)
			},
				{
				g4: A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.gA;
					},
					A2(
						$elm$core$Basics$composeR,
						$author$project$Types$User$fullName,
						A2(
							$elm$core$Basics$composeR,
							$mdgriffith$elm_ui$Element$text,
							$mdgriffith$elm_ui$Element$el(
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$alignLeft]))))),
				hE: A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$alignLeft]),
					$mdgriffith$elm_ui$Element$text('Käyttäjä')),
				dx: _List_Nil,
				fg: $mdgriffith$elm_ui$Element$px(120)
			},
				{
				g4: function (tasklist) {
					return A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$alignLeft]),
						$mdgriffith$elm_ui$Element$text(
							A2($author$project$Pages$Invoicing$getEndCustomerName, model.ap, tasklist)));
				},
				hE: A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$alignLeft]),
					$mdgriffith$elm_ui$Element$text('Asiakas')),
				dx: _List_Nil,
				fg: $mdgriffith$elm_ui$Element$px(200)
			},
				{
				g4: function (tasklist) {
					return A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$alignLeft]),
						$mdgriffith$elm_ui$Element$text(tasklist.mD));
				},
				hE: A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$alignLeft]),
					$mdgriffith$elm_ui$Element$text('Lomake')),
				dx: _List_Nil,
				fg: $mdgriffith$elm_ui$Element$px(200)
			},
				{
				g4: function (tasklist) {
					return A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$alignRight]),
						$mdgriffith$elm_ui$Element$text(
							$elm$core$String$fromInt(
								$elm$core$List$length(tasklist.aj))));
				},
				hE: A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$alignRight]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerX]),
							$mdgriffith$elm_ui$Element$text('Työt')),
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$Font$medium]),
							$mdgriffith$elm_ui$Element$text(' kpl'))
						])),
				dx: _List_Nil,
				fg: $mdgriffith$elm_ui$Element$px(40)
			},
				{
				g4: function (tasklist) {
					return A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$alignRight]),
						$mdgriffith$elm_ui$Element$text(
							$author$project$Helpers$toEuroString(
								$elm$core$List$sum(
									A2(
										$elm$core$List$map,
										A2(
											$elm$core$Basics$composeR,
											function ($) {
												return $.iA;
											},
											$author$project$Types$SelectedProduct$productsTotal(1)),
										tasklist.aj)))));
				},
				hE: A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$alignRight]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerX]),
							$mdgriffith$elm_ui$Element$text('Hinta')),
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$Font$medium]),
							$mdgriffith$elm_ui$Element$text('(alv 0%)'))
						])),
				dx: _List_Nil,
				fg: $mdgriffith$elm_ui$Element$px(80)
			},
				{
				g4: A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.fA;
					},
					A2(
						$elm$core$Basics$composeR,
						$elm$core$Maybe$map(
							$justinmimbs$date$Date$format('dd.MM.y')),
						A2(
							$elm$core$Basics$composeR,
							$elm$core$Maybe$withDefault('-'),
							A2(
								$elm$core$Basics$composeR,
								$mdgriffith$elm_ui$Element$text,
								$mdgriffith$elm_ui$Element$el(
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$centerX])))))),
				hE: $mdgriffith$elm_ui$Element$text('Toimituspäivä'),
				dx: _List_Nil,
				fg: $mdgriffith$elm_ui$Element$px(150)
			},
				{
				g4: A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.fT;
					},
					A2(
						$elm$core$Basics$composeR,
						$elm$core$Maybe$map(
							$justinmimbs$date$Date$format('dd.MM.y')),
						A2(
							$elm$core$Basics$composeR,
							$elm$core$Maybe$withDefault('-'),
							A2(
								$elm$core$Basics$composeR,
								$mdgriffith$elm_ui$Element$text,
								$mdgriffith$elm_ui$Element$el(
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$centerX])))))),
				hE: $mdgriffith$elm_ui$Element$text('Laskupäivä'),
				dx: _List_Nil,
				fg: $mdgriffith$elm_ui$Element$px(150)
			},
				{
				g4: A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.fs;
					},
					A2(
						$elm$core$Basics$composeR,
						$elm$core$Maybe$map($author$project$Types$IsoTime$toString),
						A2(
							$elm$core$Basics$composeR,
							$elm$core$Maybe$withDefault('-'),
							A2(
								$elm$core$Basics$composeR,
								$mdgriffith$elm_ui$Element$text,
								$mdgriffith$elm_ui$Element$el(
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$alignLeft])))))),
				hE: $mdgriffith$elm_ui$Element$text('Suljettu'),
				dx: _List_Nil,
				fg: $mdgriffith$elm_ui$Element$px(150)
			},
				{
				g4: A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.hX;
					},
					A2(
						$elm$core$Basics$composeR,
						$elm$core$Maybe$map($author$project$Types$IsoTime$toString),
						A2(
							$elm$core$Basics$composeR,
							$elm$core$Maybe$withDefault('-'),
							A2(
								$elm$core$Basics$composeR,
								$mdgriffith$elm_ui$Element$text,
								$mdgriffith$elm_ui$Element$el(
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$alignLeft])))))),
				hE: $mdgriffith$elm_ui$Element$text('Siirretty'),
				dx: _List_Nil,
				fg: $mdgriffith$elm_ui$Element$px(150)
			}
			]),
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$none,
				$mdgriffith$elm_ui$Element$none,
				$mdgriffith$elm_ui$Element$none,
				$mdgriffith$elm_ui$Element$none,
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width(
						$mdgriffith$elm_ui$Element$px(625))
					]),
				$mdgriffith$elm_ui$Element$none),
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width(
						$mdgriffith$elm_ui$Element$px(30)),
						$mdgriffith$elm_ui$Element$paddingEach(
						{g1: 0, h1: 0, iN: 0, jl: 0})
					]),
				$mdgriffith$elm_ui$Element$text(
					$elm$core$String$fromInt(
						$elm$core$List$length(
							A3(
								$elm$core$List$foldl,
								$elm$core$Basics$append,
								_List_Nil,
								A2(
									$elm$core$List$map,
									function ($) {
										return $.aj;
									},
									A2(
										$elm$core$List$map,
										$elm$core$Tuple$second,
										$elm$core$Dict$toList(model.ae)))))))),
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width(
						$mdgriffith$elm_ui$Element$px(70)),
						$mdgriffith$elm_ui$Element$paddingEach(
						{g1: 0, h1: 0, iN: 0, jl: 0})
					]),
				$mdgriffith$elm_ui$Element$text(
					$author$project$Helpers$toEuroString(
						$elm$core$List$sum(
							A2(
								$elm$core$List$map,
								$author$project$Types$Tasklist$tasksTotal(1),
								A2(
									$elm$core$List$map,
									function ($) {
										return $.aj;
									},
									$elm$core$Dict$values(model.ae))))))),
				$mdgriffith$elm_ui$Element$none
			]));
};
var $author$project$Pages$Invoicing$view_invoicedTasklists = function (model) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$alignTop,
				$mdgriffith$elm_ui$Element$paddingEach(
				{g1: 0, h1: 0, iN: 0, jl: 10})
			]),
		A2(
			$author$project$Styles$card_wrapper,
			false,
			A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$spacing(6)
					]),
				_List_fromArray(
					[
						$author$project$Pages$Invoicing$v_invoicedTable(model)
					]))));
};
var $author$project$Pages$Invoicing$InvoiceQueuedTab = 1;
var $author$project$Pages$Invoicing$InvoicedTab = 2;
var $author$project$Pages$Invoicing$SelectTab = function (a) {
	return {$: 0, a: a};
};
var $mdgriffith$elm_ui$Element$Font$center = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$fontAlignment, $mdgriffith$elm_ui$Internal$Style$classes.oq);
var $author$project$Components$Badge$grayColors = _List_fromArray(
	[
		$mdgriffith$elm_ui$Element$Background$color(
		$author$project$Colors$surface(3)),
		$mdgriffith$elm_ui$Element$Border$color(
		$author$project$Colors$surface(4)),
		$mdgriffith$elm_ui$Element$Font$color(
		$author$project$Colors$onSurface(7))
	]);
var $author$project$Components$Badge$gray = function (contents) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_Utils_ap($author$project$Components$Badge$baseStyles, $author$project$Components$Badge$grayColors),
		contents);
};
var $author$project$Components$Badge$countBadge = function (count) {
	return $author$project$Components$Badge$gray(
		A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Font$center,
					$author$project$Styles$mediumFontSize,
					$mdgriffith$elm_ui$Element$paddingEach(
					{g1: 0, h1: 0, iN: 1, jl: 0})
				]),
			$mdgriffith$elm_ui$Element$text(
				$elm$core$String$fromInt(count))));
};
var $author$project$Helpers$when = F2(
	function (maybeA, _do) {
		if (!maybeA.$) {
			var a = maybeA.a;
			return _do(a);
		} else {
			return $author$project$Helpers$doNothing;
		}
	});
var $author$project$Pages$Invoicing$navButtonMaker = F4(
	function (tab, isSelected, labelText, maybeCount) {
		return A2(
			$author$project$Components$Button$toTabButtonElement,
			isSelected,
			A3(
				$elm$core$Basics$apR,
				A2($elm$core$Basics$composeL, $author$project$Components$Button$icon, $author$project$Components$Badge$countBadge),
				$author$project$Helpers$when(maybeCount),
				A2(
					$author$project$Components$Button$text,
					labelText,
					A2(
						$author$project$Components$Button$onClick,
						$author$project$Pages$Invoicing$SelectTab(tab),
						$author$project$Components$Button$button))));
	});
var $author$project$Pages$Invoicing$view_navTabs = function (model) {
	return A2(
		$mdgriffith$elm_ui$Element$row,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$paddingEach(
				{g1: 0, h1: 0, iN: 0, jl: 10}),
				$mdgriffith$elm_ui$Element$Background$color(
				$author$project$Colors$surface(0)),
				$mdgriffith$elm_ui$Element$spacing(10)
			]),
		A2(
			$elm$core$List$map,
			function (_v0) {
				var tab = _v0.a;
				var tabLabel = _v0.b;
				var rows = _v0.c;
				return A4(
					$author$project$Pages$Invoicing$navButtonMaker,
					tab,
					_Utils_eq(tab, model.cS),
					tabLabel,
					$elm$core$Maybe$Just(
						$elm$core$Dict$size(rows)));
			},
			_List_fromArray(
				[
					_Utils_Tuple3(0, 'Suljetut lomakkeet', model.r),
					_Utils_Tuple3(1, 'Siirto laskutukseen', model.U),
					_Utils_Tuple3(2, 'Netvisorissa (7pv)', model.ae)
				])));
};
var $author$project$Pages$Invoicing$view = function (model) {
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$Background$color($author$project$Colors$background),
				$mdgriffith$elm_ui$Element$Font$color($author$project$Colors$onBackground),
				$mdgriffith$elm_ui$Element$inFront(
				function () {
					var _v0 = model.dj;
					switch (_v0.$) {
						case 0:
							return $mdgriffith$elm_ui$Element$none;
						case 1:
							var datePickerModel = _v0.a;
							return A3($author$project$Pages$Invoicing$view_changeDatesDialog, 'Toimituspäivä', $author$project$Pages$Invoicing$GotDeliveryDatePickerMsg, datePickerModel);
						default:
							var datePickerModel = _v0.a;
							return A3($author$project$Pages$Invoicing$view_changeDatesDialog, 'Laskupäivä', $author$project$Pages$Invoicing$GotInvoiceDatePickerMsg, datePickerModel);
					}
				}())
			]),
		_List_fromArray(
			[
				$author$project$Pages$Invoicing$view_navTabs(model),
				function () {
				var _v1 = model.ea;
				if (!_v1.$) {
					var errorString = _v1.a;
					return $mdgriffith$elm_ui$Element$text(errorString);
				} else {
					var _v2 = model.cS;
					switch (_v2) {
						case 0:
							return $author$project$Pages$Invoicing$view_closedTasklists(model);
						case 1:
							return $author$project$Pages$Invoicing$view_invoiceQueuedTasklists(model);
						default:
							return $author$project$Pages$Invoicing$view_invoicedTasklists(model);
					}
				}
			}()
			]));
};
var $author$project$Pages$Reporting$BackSpaceKeyPressed = {$: 0};
var $author$project$Pages$Reporting$NumberKeyPressed = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Reporting$view_AskingPin = function (model) {
	var onNumberKeyPressed = A2(
		$elm$html$Html$Events$custom,
		'keydown',
		$author$project$KeyboardHelper$htmlEventToKeyCode(
			function (keyCode) {
				var message = A2($author$project$KeyboardHelper$isKey, 4, keyCode) ? $author$project$Pages$Reporting$BackSpaceKeyPressed : A2(
					$elm$core$Maybe$withDefault,
					$author$project$Pages$Reporting$NoOp_AskingPin,
					A2(
						$elm$core$Maybe$map,
						$author$project$Pages$Reporting$NumberKeyPressed,
						$author$project$KeyboardHelper$keyCodeToNumber(keyCode)));
				return {
					mp: message,
					iu: $author$project$KeyboardHelper$isArrowKey(keyCode),
					ja: $author$project$KeyboardHelper$isArrowKey(keyCode)
				};
			}));
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[$mdgriffith$elm_ui$Element$centerX]),
		A4(
			$author$project$Components$Input$textInput,
			_List_fromArray(
				[
					$author$project$Utils$HtmlUtil$id('reporting-pin-input'),
					$author$project$Utils$HtmlUtil$disableAutoComplete,
					$mdgriffith$elm_ui$Element$htmlAttribute(onNumberKeyPressed)
				]),
			'Syötä PIN',
			function (_v0) {
				return $author$project$Pages$Reporting$NoOp_AskingPin;
			},
			A2(
				$elm$core$String$repeat,
				$elm$core$String$length(model.bn),
				'*')));
};
var $author$project$Pages$Reporting$NoOp = {$: 2};
var $author$project$Pages$Reporting$view_NoAccessForUser = A2(
	$mdgriffith$elm_ui$Element$map,
	$elm$core$Basics$always($author$project$Pages$Reporting$NoOp),
	$author$project$Pages$State_NoAccess$view(
		{}));
var $author$project$Types$User$canAccessCompanyReports = function (user) {
	switch (user.$) {
		case 0:
			return true;
		case 2:
			return true;
		case 1:
			return true;
		default:
			return false;
	}
};
var $author$project$Pages$Reporting$ReportLinkClicked = function (a) {
	return {$: 5, a: a};
};
var $author$project$Components$Button$busy = F2(
	function (isBusy, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{c9: isBusy});
	});
var $author$project$Styles$cardShadow = function (level) {
	var brightness = (255 / 9) * level;
	return {
		fp: 3,
		fu: A4($mdgriffith$elm_ui$Element$rgba, brightness, brightness, brightness, 0.15),
		f1: _Utils_Tuple2(0, 1),
		gl: 2
	};
};
var $author$project$Pages$Reporting$view_reportLink = F2(
	function (isFetchingReport, reportLink) {
		var reportType = reportLink.cp;
		var reportPeriod = reportLink.iJ;
		var periodIndicator = function () {
			var _v0 = _Utils_Tuple3(reportType, reportPeriod.fi, reportPeriod.ao);
			if ((_v0.a === 2) && (!_v0.c.$)) {
				var _v1 = _v0.a;
				var year = _v0.b;
				var week = _v0.c.a;
				return A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$author$project$Styles$mediumFontSize,
							$mdgriffith$elm_ui$Element$spacing(4)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$alignLeft, $author$project$Styles$smallFontSize]),
							$mdgriffith$elm_ui$Element$text('VKO')),
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$alignRight]),
							$mdgriffith$elm_ui$Element$text(
								$elm$core$String$fromInt(week) + (' / ' + $elm$core$String$fromInt(year))))
						]));
			} else {
				return $mdgriffith$elm_ui$Element$none;
			}
		}();
		var downloadButton = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$Background$color(
					$author$project$Colors$surface(0)),
					$mdgriffith$elm_ui$Element$Border$rounded(8),
					$mdgriffith$elm_ui$Element$Border$shadow(
					$author$project$Styles$cardShadow(0)),
					$mdgriffith$elm_ui$Element$padding(3)
				]),
			$author$project$Components$Button$toElement(
				A2(
					$author$project$Components$Button$busy,
					isFetchingReport,
					A2(
						$author$project$Components$Button$icon,
						$author$project$Icons$excel_file,
						A2(
							$author$project$Components$Button$textSize,
							$author$project$Styles$fontSizesInPixels.ml,
							A2(
								$author$project$Components$Button$textColor,
								$author$project$Colors$onSurface(9),
								A2(
									$author$project$Components$Button$text,
									reportLink.ed,
									A2(
										$author$project$Components$Button$onClick,
										$author$project$Pages$Reporting$ReportLinkClicked(reportLink),
										$author$project$Components$Button$button))))))));
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$spacing(4)
				]),
			_List_fromArray(
				[periodIndicator, downloadButton]));
	});
var $author$project$Pages$Reporting$view_reportsList = F4(
	function (isFetchingReport, maybeHeader, sortReportLinks, reportLinks) {
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$padding(10),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$Font$color(
					$author$project$Colors$onSurface(9)),
					$mdgriffith$elm_ui$Element$Background$color($author$project$Colors$background),
					$mdgriffith$elm_ui$Element$Border$rounded(8),
					$mdgriffith$elm_ui$Element$spacing(8)
				]),
			A2(
				$elm$core$List$cons,
				function () {
					if (!maybeHeader.$) {
						var header = maybeHeader.a;
						return A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerX, $author$project$Styles$largeFontSize, $mdgriffith$elm_ui$Element$Font$bold]),
							$mdgriffith$elm_ui$Element$text(header));
					} else {
						return $mdgriffith$elm_ui$Element$none;
					}
				}(),
				$elm$core$List$isEmpty(reportLinks) ? _List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$centerX, $author$project$Styles$mediumFontSize, $mdgriffith$elm_ui$Element$Font$italic]),
						$mdgriffith$elm_ui$Element$text('ei raportteja'))
					]) : A2(
					$elm$core$List$map,
					$author$project$Pages$Reporting$view_reportLink(isFetchingReport),
					sortReportLinks(reportLinks))));
	});
var $author$project$Pages$Reporting$view_pivotSourceReports = function (_v0) {
	var isFetchingData = _v0.af;
	var pivotSourceReportLinks = _v0.eO;
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$alignTop,
				$mdgriffith$elm_ui$Element$centerX,
				$mdgriffith$elm_ui$Element$padding(10),
				$mdgriffith$elm_ui$Element$Font$color(
				$author$project$Colors$onSurface(9)),
				$mdgriffith$elm_ui$Element$Background$color($author$project$Colors$background),
				$mdgriffith$elm_ui$Element$Border$rounded(8)
			]),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$centerX,
						$author$project$Styles$xlFontSize,
						$mdgriffith$elm_ui$Element$Font$bold
					]),
				$mdgriffith$elm_ui$Element$text('PIVOT - LÄHDEDATA')),
				A2(
				$mdgriffith$elm_ui$Element$row,
				_List_Nil,
				_List_fromArray(
					[
						A4(
						$author$project$Pages$Reporting$view_reportsList,
						isFetchingData,
						$elm$core$Maybe$Nothing,
						A2(
							$elm$core$Basics$composeR,
							$elm$core$List$sortBy(
								function ($) {
									return $.ed;
								}),
							$elm$core$List$reverse),
						pivotSourceReportLinks)
					]))
			]));
};
var $author$project$Pages$Reporting$view_salesReports = function (_v0) {
	var isFetchingData = _v0.af;
	var weeklySalesReportLinks = _v0.fe;
	var monthlySalesReportLinks = _v0.eA;
	var sorter = A2(
		$elm$core$Basics$composeR,
		$elm$core$List$sortBy(
			function ($) {
				return $.ed;
			}),
		$elm$core$List$reverse);
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$alignTop,
				$mdgriffith$elm_ui$Element$centerX,
				$mdgriffith$elm_ui$Element$padding(10),
				$mdgriffith$elm_ui$Element$Font$color(
				$author$project$Colors$onSurface(9)),
				$mdgriffith$elm_ui$Element$Background$color($author$project$Colors$background),
				$mdgriffith$elm_ui$Element$Border$rounded(8)
			]),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$centerX,
						$author$project$Styles$xlFontSize,
						$mdgriffith$elm_ui$Element$Font$bold
					]),
				$mdgriffith$elm_ui$Element$text('MYYNNIT')),
				A2(
				$mdgriffith$elm_ui$Element$row,
				_List_Nil,
				_List_fromArray(
					[
						A4(
						$author$project$Pages$Reporting$view_reportsList,
						isFetchingData,
						$elm$core$Maybe$Just('Viikkoraportit'),
						sorter,
						weeklySalesReportLinks),
						A4(
						$author$project$Pages$Reporting$view_reportsList,
						isFetchingData,
						$elm$core$Maybe$Just('Kuukausiraportit'),
						sorter,
						monthlySalesReportLinks)
					]))
			]));
};
var $author$project$Pages$Reporting$view_companyWideReports = function (model) {
	return _List_fromArray(
		[
			$author$project$Pages$Reporting$view_salesReports(model),
			$author$project$Pages$Reporting$view_pivotSourceReports(model)
		]);
};
var $author$project$Pages$Reporting$NavigateToTab = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Reporting$view_siteReportTables = F4(
	function (selectedSite, restOfSites, htmlTables, isFetchingData) {
		var pageHeader = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Font$color($author$project$Colors$onBackground),
					$author$project$Styles$xxlFontSize
				]),
			$mdgriffith$elm_ui$Element$text(
				$author$project$Types$Site$name(selectedSite) + ' - viikkoraportit'));
		var loadingIndicator = isFetchingData ? A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$centerX,
					$mdgriffith$elm_ui$Element$Font$color(
					$author$project$Colors$onPrimary(5)),
					$author$project$Styles$largeFontSize
				]),
			$mdgriffith$elm_ui$Element$text('Ladataan...')) : $mdgriffith$elm_ui$Element$none;
		var loadedReportPeriodTables = A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
				]),
			A2(
				$elm$core$List$map,
				$mdgriffith$elm_ui$Element$html,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.fS;
					},
					htmlTables)));
		var allSites = A2($elm$core$List$cons, selectedSite, restOfSites);
		var switchSiteButtons = $elm$core$List$isEmpty(restOfSites) ? $mdgriffith$elm_ui$Element$none : A2(
			$mdgriffith$elm_ui$Element$wrappedRow,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(4),
					$mdgriffith$elm_ui$Element$padding(8)
				]),
			A2(
				$elm$core$List$map,
				function (site) {
					var otherSites = A2(
						$elm$core$List$filter,
						$elm$core$Basics$neq(site),
						allSites);
					var targetSiteReportingTab = A2($author$project$Pages$Reporting$SiteReportsTab, site, otherSites);
					return $author$project$Components$Button$toElement(
						$author$project$Components$Button$primary(
							A2(
								$author$project$Components$Button$textSize,
								$author$project$Styles$fontSizesInPixels.mc,
								A2(
									$author$project$Components$Button$text,
									$author$project$Types$Site$name(site),
									A2(
										$author$project$Components$Button$toggleButton,
										$author$project$Pages$Reporting$NavigateToTab(targetSiteReportingTab),
										_Utils_eq(site, selectedSite))))));
				},
				A2(
					$elm$core$List$sortBy,
					function (site) {
						if (!site.$) {
							return _Utils_Tuple2(
								0,
								$author$project$Types$Site$name(site));
						} else {
							return _Utils_Tuple2(
								1,
								$author$project$Types$Site$name(site));
						}
					},
					allSites)));
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$alignTop,
					$mdgriffith$elm_ui$Element$padding(8)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$htmlAttribute(
							A2($elm$html$Html$Attributes$style, 'width', '98vw'))
						]),
					switchSiteButtons),
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$htmlAttribute(
							A2($elm$html$Html$Attributes$style, 'width', '98vw'))
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerX]),
							pageHeader)
						])),
					loadedReportPeriodTables,
					loadingIndicator
				]));
	});
var $author$project$Pages$Reporting$view_tabNavigationButton = F2(
	function (targetTab, isSelected) {
		var labelText = function () {
			if (!targetTab.$) {
				return 'Yritystaso';
			} else {
				return 'Toimipistetaso';
			}
		}();
		return $author$project$Components$Button$toElement(
			A2(
				$author$project$Components$Button$textSize,
				$author$project$Styles$fontSizesInPixels.o6,
				A2(
					$author$project$Components$Button$text,
					labelText,
					$author$project$Components$Button$primary(
						A2(
							$author$project$Components$Button$toggleButton,
							$author$project$Pages$Reporting$NavigateToTab(targetTab),
							isSelected)))));
	});
var $author$project$Pages$Reporting$view_PinOk = F2(
	function (selectedUser, model) {
		var availableTabNavigationButtons = function () {
			var _v1 = _Utils_Tuple2(model.gg, model.dR);
			if (!_v1.a.$) {
				if (_v1.b.b) {
					var _v2 = _v1.a;
					var _v3 = _v1.b;
					var firstSite = _v3.a;
					var restOfSites = _v3.b;
					return _List_fromArray(
						[
							A2($author$project$Pages$Reporting$view_tabNavigationButton, $author$project$Pages$Reporting$CompanyWideReportsTab, true),
							A2(
							$author$project$Pages$Reporting$view_tabNavigationButton,
							A2($author$project$Pages$Reporting$SiteReportsTab, firstSite, restOfSites),
							false)
						]);
				} else {
					var _v4 = _v1.a;
					return _List_fromArray(
						[
							A2($author$project$Pages$Reporting$view_tabNavigationButton, $author$project$Pages$Reporting$CompanyWideReportsTab, true)
						]);
				}
			} else {
				var _v5 = _v1.a;
				var selectedSite = _v5.a;
				var restOfSites = _v5.b;
				return _Utils_ap(
					$author$project$Types$User$canAccessCompanyReports(selectedUser) ? _List_fromArray(
						[
							A2($author$project$Pages$Reporting$view_tabNavigationButton, $author$project$Pages$Reporting$CompanyWideReportsTab, false)
						]) : _List_Nil,
					_List_fromArray(
						[
							A2(
							$author$project$Pages$Reporting$view_tabNavigationButton,
							A2($author$project$Pages$Reporting$SiteReportsTab, selectedSite, restOfSites),
							true)
						]));
			}
		}();
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
					]),
				$author$project$Helpers$attributeIf(
					_List_fromArray(
						[
							_Utils_Tuple2(
							model.af,
							$author$project$Cursor$cursor(25))
						]))),
			_List_fromArray(
				[
					($elm$core$List$length(availableTabNavigationButtons) > 1) ? A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$centerX]),
					availableTabNavigationButtons) : $mdgriffith$elm_ui$Element$none,
					function () {
					var _v0 = model.gg;
					if (_v0.$ === 1) {
						var selectedSite = _v0.a;
						var restOfSites = _v0.b;
						return A4($author$project$Pages$Reporting$view_siteReportTables, selectedSite, restOfSites, model.cJ, model.af);
					} else {
						return A2(
							$mdgriffith$elm_ui$Element$wrappedRow,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$spacing(16),
									$mdgriffith$elm_ui$Element$paddingEach(
									{g1: 0, h1: 0, iN: 0, jl: 15})
								]),
							$author$project$Pages$Reporting$view_companyWideReports(model));
					}
				}()
				]));
	});
var $author$project$Pages$Reporting$view = F2(
	function (selectedUser, model) {
		var _v0 = model._;
		switch (_v0.$) {
			case 0:
				var model_AskingPin = _v0.a;
				return A2(
					$mdgriffith$elm_ui$Element$map,
					$author$project$Pages$Reporting$Msg_AskingPin,
					$author$project$Pages$Reporting$view_AskingPin(model_AskingPin));
			case 1:
				var model_PinOk = _v0.a;
				return A2(
					$mdgriffith$elm_ui$Element$map,
					$author$project$Pages$Reporting$Msg_PinOk,
					A2($author$project$Pages$Reporting$view_PinOk, selectedUser, model_PinOk));
			default:
				return $author$project$Pages$Reporting$view_NoAccessForUser;
		}
	});
var $author$project$Pages$Tasklist$view_Errored = F2(
	function (_v0, model) {
		return A2(
			$mdgriffith$elm_ui$Element$paragraph,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$centerX,
					$mdgriffith$elm_ui$Element$Font$color($author$project$Colors$warning)
				]),
			A2(
				$elm$core$List$map,
				$mdgriffith$elm_ui$Element$text,
				_List_fromArray(
					[
						A2($elm$core$Maybe$withDefault, 'Virhetilanne, pahoittelut.', model.eb),
						' Tarkista nettiyhteys ja kokeile ladata sivu uudestaan.'
					])));
	});
var $mdgriffith$elm_ui$Element$scrollbars = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$overflow, $mdgriffith$elm_ui$Internal$Style$classes.nU);
var $mdgriffith$elm_ui$Element$Background$uncropped = function (src) {
	return $mdgriffith$elm_ui$Internal$Model$Attr(
		A2($elm$virtual_dom$VirtualDom$style, 'background', 'url(\"' + (src + '\") center / contain no-repeat')));
};
var $author$project$Pages$Tasklist$TaskQueue$Msg_Errored = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$view_Errored = F2(
	function (_v0, _v1) {
		return $mdgriffith$elm_ui$Element$text('VIRHE');
	});
var $author$project$Pages$Tasklist$TaskQueue$HideEditorDialog = {$: 5};
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$CollapseButtonClicked = {$: 8};
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$CtrlAndEnterPressed = {$: 9};
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$HideSubDialog = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$SelectVehicleMake = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$Worker_Deselect = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$Worker_Select = function (a) {
	return {$: 6, a: a};
};
var $author$project$Components$Button$hint = F2(
	function (value, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{
				eo: $elm$core$Maybe$Just(value)
			});
	});
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$InstructionsUpdated = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$instructionsInputField = function (instructions) {
	return A4($author$project$Components$Input$textInput, _List_Nil, 'Ohjeet', $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$InstructionsUpdated, instructions);
};
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$LicenseNumberUpdated = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$licenseNumberInputField = function (licenseNumberField) {
	return A4(
		$author$project$Components$Input$textInput,
		_List_fromArray(
			[
				$author$project$Utils$HtmlUtil$id($author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$licenseNumberInputId),
				$author$project$Utils$HtmlUtil$disableAutoComplete
			]),
		'Rekisterinumero',
		$author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$LicenseNumberUpdated,
		$author$project$Types$Vehicle$LicenseNumberField$toString(licenseNumberField));
};
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$CloseSelectDialogClicked = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Components$ProductToggleButton$calculateButtonFontSize = function (productCode) {
	var codeLength = $elm$core$String$length(productCode);
	return (codeLength <= 3) ? 24 : ((codeLength === 4) ? 20 : 16);
};
var $author$project$Components$ProductToggleButton$button = F4(
	function (id, product, onToggle, isSelected) {
		return A2(
			$author$project$Components$Button$height,
			$mdgriffith$elm_ui$Element$px(40),
			A2(
				$author$project$Components$Button$width,
				$mdgriffith$elm_ui$Element$px(60),
				$author$project$Components$Button$primary(
					A2(
						$author$project$Components$Button$tooltip,
						product.mD,
						A2(
							$author$project$Components$Button$textSize,
							$author$project$Components$ProductToggleButton$calculateButtonFontSize(product.ha),
							A2(
								$author$project$Components$Button$text,
								product.ha,
								A2(
									$author$project$Components$Button$id,
									id,
									A2($author$project$Components$Button$toggleButton, onToggle, isSelected))))))));
	});
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$viewSelectProductDialog = F3(
	function (onClose, availableProducts, selectedProduct) {
		var removeSelectionButton = $author$project$Components$Button$toElement(
			A2(
				$author$project$Components$Button$text,
				'POISTA VALINTA',
				A2(
					$author$project$Components$Button$onClick,
					onClose($elm$core$Maybe$Nothing),
					$author$project$Components$Button$button)));
		var isExistingSelection = !_Utils_eq(selectedProduct, $elm$core$Maybe$Nothing);
		var title = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$Font$bold]),
			$mdgriffith$elm_ui$Element$text(
				isExistingSelection ? 'VALITSE TUOTE:' : 'LISÄÄ TUOTE:'));
		var closeButton = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Font$color(
					$author$project$Colors$onSurface(5))
				]),
			$author$project$Components$CloseButton$closeButton(
				onClose(selectedProduct)));
		var buttonMaker = function (product) {
			var isSelected = function () {
				if (!selectedProduct.$) {
					var selected = selectedProduct.a;
					return _Utils_eq(selected, product);
				} else {
					return false;
				}
			}();
			return $author$project$Components$Button$toElement(
				A4(
					$author$project$Components$ProductToggleButton$button,
					'product-toggle-' + $elm$core$String$fromInt(product.er),
					product,
					onClose(
						$elm$core$Maybe$Just(product)),
					isSelected));
		};
		var productButtons = A2(
			$author$project$Styles$card_wrapper,
			false,
			A2(
				$mdgriffith$elm_ui$Element$wrappedRow,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$spacing(8)
					]),
				A2(
					$elm$core$List$map,
					buttonMaker,
					A2(
						$elm$core$List$sortBy,
						function ($) {
							return $.ha;
						},
						availableProducts))));
		return A2(
			$author$project$Components$Modal$modal,
			{
				kz: true,
				mR: onClose(selectedProduct)
			},
			A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width(
						$mdgriffith$elm_ui$Element$px(636)),
						$mdgriffith$elm_ui$Element$Background$color($author$project$Colors$background),
						$mdgriffith$elm_ui$Element$Font$color($author$project$Colors$onBackground),
						$author$project$Styles$largeFontSize,
						$mdgriffith$elm_ui$Element$padding(8),
						$mdgriffith$elm_ui$Element$spacing(4)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						_List_fromArray(
							[
								title,
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$alignRight]),
								closeButton)
							])),
						productButtons,
						isExistingSelection ? A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$alignRight]),
						removeSelectionButton) : $mdgriffith$elm_ui$Element$none
					])));
	});
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$viewDialog = function (model) {
	var _v0 = model.c1;
	if (!_v0.$) {
		var productSelector = _v0.a;
		var availableUnselectedProducts = A2($author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$getUnselectedProducts, model.T, model.bc);
		var productOptions = function () {
			var _v1 = productSelector.aP;
			if (!_v1.$) {
				var product = _v1.a;
				return A2($elm$core$List$cons, product, availableUnselectedProducts);
			} else {
				return availableUnselectedProducts;
			}
		}();
		return A3(
			$author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$viewSelectProductDialog,
			$author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$CloseSelectDialogClicked(productSelector),
			productOptions,
			productSelector.aP);
	} else {
		return $mdgriffith$elm_ui$Element$none;
	}
};
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$OpenSelectDialogClicked = function (a) {
	return {$: 1, a: a};
};
var $author$project$Icons$ellipsis_h = $author$project$Icons$fa_icon('fas fa-ellipsis-h');
var $author$project$Components$Dropdown$OptionsListMouseEnter = {$: 5};
var $author$project$Components$Dropdown$OptionsListMouseLeave = {$: 6};
var $author$project$Cursor$Pointer = 24;
var $elm$html$Html$Events$onMouseEnter = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'mouseenter',
		$elm$json$Json$Decode$succeed(msg));
};
var $mdgriffith$elm_ui$Element$Events$onMouseEnter = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Attr, $elm$html$Html$Events$onMouseEnter);
var $elm$html$Html$Events$onMouseLeave = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'mouseleave',
		$elm$json$Json$Decode$succeed(msg));
};
var $mdgriffith$elm_ui$Element$Events$onMouseLeave = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Attr, $elm$html$Html$Events$onMouseLeave);
var $author$project$Components$Dropdown$view_option = F2(
	function (option, isFocused) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$alignLeft,
						$author$project$Cursor$cursor(24),
						$mdgriffith$elm_ui$Element$padding(3),
						$mdgriffith$elm_ui$Element$mouseOver(
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Background$color(
								$author$project$Colors$surface(0)),
								$mdgriffith$elm_ui$Element$Font$color(
								$author$project$Colors$onSurface(9))
							])),
						$mdgriffith$elm_ui$Element$Events$onClick(
						$author$project$Components$Dropdown$SelectOption(option)),
						$mdgriffith$elm_ui$Element$Events$onMouseEnter($author$project$Components$Dropdown$OptionsListMouseEnter),
						$mdgriffith$elm_ui$Element$Events$onMouseLeave($author$project$Components$Dropdown$OptionsListMouseLeave)
					]),
				isFocused ? _List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Background$color(
						$author$project$Colors$onSurface(1)),
						$mdgriffith$elm_ui$Element$Font$color(
						$author$project$Colors$onSurface(9))
					]) : _List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Font$color(
						$author$project$Colors$onSurface(5))
					])),
			A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$alignLeft]),
				$mdgriffith$elm_ui$Element$text(option)));
	});
var $author$project$Components$Dropdown$view_optionsList = function (_v0) {
	var visibleOptions = _v0.x;
	var inputText = _v0.K;
	var _v1 = visibleOptions;
	var options = _v1.a;
	var maybeFocusedIndex = _v1.b;
	var focusedIndex = function () {
		if (!maybeFocusedIndex.$) {
			var index = maybeFocusedIndex.a;
			return index;
		} else {
			return -1;
		}
	}();
	return ((!$elm$core$String$length(inputText)) || (!$elm$core$List$length(options))) ? $mdgriffith$elm_ui$Element$none : A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$centerY,
				A2($mdgriffith$elm_ui$Element$paddingXY, 3, 2),
				$mdgriffith$elm_ui$Element$Background$color(
				$author$project$Colors$surface(2)),
				$mdgriffith$elm_ui$Element$moveDown(33),
				$mdgriffith$elm_ui$Element$htmlAttribute(
				A2($elm$html$Html$Attributes$style, 'z-index', '9999'))
			]),
		A2(
			$elm$core$List$indexedMap,
			F2(
				function (optionIndex, option) {
					return A2(
						$author$project$Components$Dropdown$view_option,
						option,
						_Utils_eq(optionIndex, focusedIndex));
				}),
			$elm$core$List$sort(options)));
};
var $author$project$Components$Dropdown$view_suggestionText = function (model) {
	var _v0 = $author$project$Components$Dropdown$getSuggestedOption(model);
	if (!_v0.$) {
		var suggestedOption = _v0.a;
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Font$color(
					$author$project$Colors$onSurface(3))
				]),
			$mdgriffith$elm_ui$Element$text(suggestedOption));
	} else {
		return A2($mdgriffith$elm_ui$Element$el, _List_Nil, $mdgriffith$elm_ui$Element$none);
	}
};
var $author$project$Components$Dropdown$TextInputGettingFocus = {$: 2};
var $author$project$Components$Dropdown$TextInputLosingFocus = {$: 3};
var $author$project$Components$Dropdown$ValueChanged = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Dropdown$ArrowDownKeyPressed = {$: 11};
var $author$project$Components$Dropdown$ArrowUpKeyPressed = {$: 10};
var $author$project$Components$Dropdown$EnterKeyPressed = {$: 8};
var $author$project$Components$Dropdown$EscKeyPressed = {$: 9};
var $author$project$Components$Dropdown$TabKeyPressed = {$: 7};
var $author$project$Components$Dropdown$isOptionsListOpen = function (model) {
	if ($elm$core$String$isEmpty(model.K)) {
		return false;
	} else {
		var _v0 = model.x;
		if (!_v0.a.b) {
			return false;
		} else {
			return true;
		}
	}
};
var $author$project$Components$Dropdown$handleKeyDown = function (model) {
	return A2(
		$elm$html$Html$Events$custom,
		'keydown',
		$author$project$KeyboardHelper$htmlEventToKey(
			function (key) {
				var message = function () {
					switch (key) {
						case 8:
							return $author$project$Components$Dropdown$TabKeyPressed;
						case 5:
							return $author$project$Components$Dropdown$EnterKeyPressed;
						case 6:
							return $author$project$Components$Dropdown$EscKeyPressed;
						case 3:
							return $author$project$Components$Dropdown$ArrowUpKeyPressed;
						case 0:
							return $author$project$Components$Dropdown$ArrowDownKeyPressed;
						default:
							return $author$project$Components$Dropdown$NoOp;
					}
				}();
				var doPrevent = $author$project$Components$Dropdown$isOptionsListOpen(model) && A2(
					$elm$core$List$member,
					message,
					_List_fromArray(
						[$author$project$Components$Dropdown$EscKeyPressed, $author$project$Components$Dropdown$EnterKeyPressed, $author$project$Components$Dropdown$ArrowUpKeyPressed, $author$project$Components$Dropdown$ArrowDownKeyPressed]));
				return {mp: message, iu: doPrevent, ja: doPrevent};
			}));
};
var $author$project$Components$Dropdown$view_textInput = function (model) {
	var inputHasNoMatches = $elm$core$List$isEmpty(
		A2(
			$elm$core$List$filter,
			$author$project$Components$Dropdown$optionStartsWith(model.K),
			model.bT));
	var inputTextColor = inputHasNoMatches ? $author$project$Colors$warning : $author$project$Colors$onSurface(9);
	return A2(
		$mdgriffith$elm_ui$Element$Input$text,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Font$color(inputTextColor),
				$mdgriffith$elm_ui$Element$Background$color($author$project$Colors$none),
				$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$padding(6),
				$mdgriffith$elm_ui$Element$Border$width(0),
				$mdgriffith$elm_ui$Element$htmlAttribute(
				A2($elm$html$Html$Attributes$style, 'text-align', 'left')),
				$author$project$Utils$HtmlUtil$disableAutoComplete,
				$author$project$Utils$HtmlUtil$id(model.er),
				$mdgriffith$elm_ui$Element$Events$onFocus($author$project$Components$Dropdown$TextInputGettingFocus),
				$mdgriffith$elm_ui$Element$Events$onLoseFocus($author$project$Components$Dropdown$TextInputLosingFocus),
				$mdgriffith$elm_ui$Element$htmlAttribute(
				$author$project$Components$Dropdown$handleKeyDown(model))
			]),
		{
			ma: $mdgriffith$elm_ui$Element$Input$labelHidden(''),
			mK: $author$project$Components$Dropdown$ValueChanged,
			nt: model.nt,
			bJ: model.K
		});
};
var $author$project$Components$Dropdown$view = function (model) {
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$Border$rounded(3),
				$mdgriffith$elm_ui$Element$inFront(
				$author$project$Components$Dropdown$view_optionsList(model))
			]),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$centerY,
						$mdgriffith$elm_ui$Element$padding(6),
						$mdgriffith$elm_ui$Element$Background$color(
						$author$project$Colors$surface(1)),
						$mdgriffith$elm_ui$Element$Border$rounded(3),
						$mdgriffith$elm_ui$Element$inFront(
						$author$project$Components$Dropdown$view_textInput(model))
					]),
				$author$project$Components$Dropdown$view_suggestionText(model))
			]));
};
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$viewProductSelector = function (productSelector) {
	var openSelectProductDialogButton = A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[$mdgriffith$elm_ui$Element$alignRight]),
		$author$project$Components$Button$toElement(
			A2(
				$author$project$Components$Button$setCustomAttributes,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$htmlAttribute(
						$elm$html$Html$Attributes$tabindex(-1))
					]),
				A2(
					$author$project$Components$Button$height,
					$mdgriffith$elm_ui$Element$px(26),
					A2(
						$author$project$Components$Button$id,
						$author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$openSelectProductDialogButtonId(productSelector.l7),
						$author$project$Components$Button$secondary(
							A2(
								$author$project$Components$Button$icon,
								$author$project$Icons$ellipsis_h,
								A2(
									$author$project$Components$Button$onClick,
									$author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$OpenSelectDialogClicked(productSelector),
									$author$project$Components$Button$button))))))));
	var dropdown = A2(
		$mdgriffith$elm_ui$Element$map,
		$author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$GotDropdownMsg(productSelector),
		$author$project$Components$Dropdown$view(productSelector.lk));
	return A2(
		$mdgriffith$elm_ui$Element$row,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$inFront(openSelectProductDialogButton)
			]),
		_List_fromArray(
			[dropdown]));
};
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$view = function (model) {
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$spacing(4),
				$mdgriffith$elm_ui$Element$inFront(
				$author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$viewDialog(model))
			]),
		A2(
			$elm$core$List$map,
			A2(
				$elm$core$Basics$composeR,
				$author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$viewProductSelector,
				$mdgriffith$elm_ui$Element$el(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height(
							$mdgriffith$elm_ui$Element$px(26))
						]))),
			$elm$core$Dict$values(model.bc)));
};
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$productDropdownFields = function (productSelectorsModel) {
	return A2(
		$mdgriffith$elm_ui$Element$map,
		$author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$GotProductSelectorsMsg,
		$author$project$Pages$Tasklist$TaskQueue$TaskCreator$ProductSelectors$view(productSelectorsModel));
};
var $Gizra$elm_keyboard_event$Keyboard$Event$KeyboardEvent = F7(
	function (altKey, ctrlKey, key, keyCode, metaKey, repeat, shiftKey) {
		return {jZ: altKey, kT: ctrlKey, l7: key, l8: keyCode, mq: metaKey, nI: repeat, n5: shiftKey};
	});
var $Gizra$elm_keyboard_event$Keyboard$Event$decodeKey = $elm$json$Json$Decode$maybe(
	A2(
		$elm$json$Json$Decode$andThen,
		function (key) {
			return $elm$core$String$isEmpty(key) ? $elm$json$Json$Decode$fail('empty key') : $elm$json$Json$Decode$succeed(key);
		},
		A2($elm$json$Json$Decode$field, 'key', $elm$json$Json$Decode$string)));
var $Gizra$elm_keyboard_event$Keyboard$Event$decodeNonZero = A2(
	$elm$json$Json$Decode$andThen,
	function (code) {
		return (!code) ? $elm$json$Json$Decode$fail('code was zero') : $elm$json$Json$Decode$succeed(code);
	},
	$elm$json$Json$Decode$int);
var $Gizra$elm_keyboard_event$Keyboard$Event$decodeKeyCode = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2($elm$json$Json$Decode$field, 'keyCode', $Gizra$elm_keyboard_event$Keyboard$Event$decodeNonZero),
			A2($elm$json$Json$Decode$field, 'which', $Gizra$elm_keyboard_event$Keyboard$Event$decodeNonZero),
			A2($elm$json$Json$Decode$field, 'charCode', $Gizra$elm_keyboard_event$Keyboard$Event$decodeNonZero),
			$elm$json$Json$Decode$succeed(0)
		]));
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$A = {$: 0};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Add = {$: 85};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Alt = {$: 32};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Ambiguous = function (a) {
	return {$: 89, a: a};
};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$B = {$: 1};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Backspace = {$: 38};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$C = {$: 2};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$CapsLock = {$: 34};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$ChromeSearch = {$: 59};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Command = {$: 58};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Ctrl = function (a) {
	return {$: 31, a: a};
};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$D = {$: 3};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Decimal = {$: 87};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Delete = {$: 39};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Divide = {$: 88};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Down = {$: 29};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$E = {$: 4};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Eight = {$: 52};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$End = {$: 42};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Enter = {$: 37};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Escape = {$: 36};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F = {$: 5};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F1 = {$: 62};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F10 = {$: 71};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F11 = {$: 72};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F12 = {$: 73};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F2 = {$: 63};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F3 = {$: 64};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F4 = {$: 65};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F5 = {$: 66};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F6 = {$: 67};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F7 = {$: 68};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F8 = {$: 69};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F9 = {$: 70};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Five = {$: 49};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Four = {$: 48};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$G = {$: 6};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$H = {$: 7};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Home = {$: 43};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$I = {$: 8};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Insert = {$: 54};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$J = {$: 9};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$K = {$: 10};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$L = {$: 11};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Left = {$: 26};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$M = {$: 12};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Multiply = {$: 84};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$N = {$: 13};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Nine = {$: 53};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumLock = {$: 60};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadEight = {$: 82};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadFive = {$: 79};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadFour = {$: 78};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadNine = {$: 83};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadOne = {$: 75};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadSeven = {$: 81};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadSix = {$: 80};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadThree = {$: 77};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadTwo = {$: 76};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadZero = {$: 74};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$O = {$: 14};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$One = {$: 45};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$P = {$: 15};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$PageDown = {$: 41};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$PageUp = {$: 40};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$PauseBreak = {$: 56};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$PrintScreen = {$: 55};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Q = {$: 16};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$R = {$: 17};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Right = {$: 27};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$S = {$: 18};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$ScrollLock = {$: 61};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Seven = {$: 51};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Shift = function (a) {
	return {$: 30, a: a};
};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Six = {$: 50};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Spacebar = {$: 35};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Subtract = {$: 86};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$T = {$: 19};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Tab = {$: 33};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Three = {$: 47};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Two = {$: 46};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$U = {$: 20};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Unknown = function (a) {
	return {$: 90, a: a};
};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Up = {$: 28};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$V = {$: 21};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$W = {$: 22};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Windows = {$: 57};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$X = {$: 23};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Y = {$: 24};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Z = {$: 25};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Zero = {$: 44};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$fromCode = function (keyCode) {
	switch (keyCode) {
		case 8:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Backspace;
		case 9:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Tab;
		case 13:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Enter;
		case 16:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Shift($elm$core$Maybe$Nothing);
		case 17:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Ctrl($elm$core$Maybe$Nothing);
		case 18:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Alt;
		case 19:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$PauseBreak;
		case 20:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$CapsLock;
		case 27:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Escape;
		case 32:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Spacebar;
		case 33:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$PageUp;
		case 34:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$PageDown;
		case 35:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$End;
		case 36:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Home;
		case 37:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Left;
		case 38:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Up;
		case 39:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Right;
		case 40:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Down;
		case 44:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$PrintScreen;
		case 45:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Insert;
		case 46:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Delete;
		case 48:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Zero;
		case 49:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$One;
		case 50:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Two;
		case 51:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Three;
		case 52:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Four;
		case 53:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Five;
		case 54:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Six;
		case 55:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Seven;
		case 56:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Eight;
		case 57:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Nine;
		case 65:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$A;
		case 66:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$B;
		case 67:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$C;
		case 68:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$D;
		case 69:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$E;
		case 70:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F;
		case 71:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$G;
		case 72:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$H;
		case 73:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$I;
		case 74:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$J;
		case 75:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$K;
		case 76:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$L;
		case 77:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$M;
		case 78:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$N;
		case 79:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$O;
		case 80:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$P;
		case 81:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Q;
		case 82:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$R;
		case 83:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$S;
		case 84:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$T;
		case 85:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$U;
		case 86:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$V;
		case 87:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$W;
		case 88:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$X;
		case 89:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Y;
		case 90:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Z;
		case 91:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Ambiguous(
				_List_fromArray(
					[$SwiftsNamesake$proper_keyboard$Keyboard$Key$Windows, $SwiftsNamesake$proper_keyboard$Keyboard$Key$Command, $SwiftsNamesake$proper_keyboard$Keyboard$Key$ChromeSearch]));
		case 96:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadZero;
		case 97:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadOne;
		case 98:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadTwo;
		case 99:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadThree;
		case 100:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadFour;
		case 101:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadFive;
		case 102:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadSix;
		case 103:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadSeven;
		case 104:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadEight;
		case 105:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadNine;
		case 106:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Multiply;
		case 107:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Add;
		case 109:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Subtract;
		case 110:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Decimal;
		case 111:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Divide;
		case 112:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F1;
		case 113:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F2;
		case 114:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F3;
		case 115:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F4;
		case 116:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F5;
		case 117:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F6;
		case 118:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F7;
		case 119:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F8;
		case 120:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F9;
		case 121:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F10;
		case 122:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F11;
		case 123:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F12;
		case 144:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumLock;
		case 145:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$ScrollLock;
		default:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Unknown(keyCode);
	}
};
var $elm$json$Json$Decode$map7 = _Json_map7;
var $Gizra$elm_keyboard_event$Keyboard$Event$decodeKeyboardEvent = A8(
	$elm$json$Json$Decode$map7,
	$Gizra$elm_keyboard_event$Keyboard$Event$KeyboardEvent,
	A2($elm$json$Json$Decode$field, 'altKey', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 'ctrlKey', $elm$json$Json$Decode$bool),
	$Gizra$elm_keyboard_event$Keyboard$Event$decodeKey,
	A2($elm$json$Json$Decode$map, $SwiftsNamesake$proper_keyboard$Keyboard$Key$fromCode, $Gizra$elm_keyboard_event$Keyboard$Event$decodeKeyCode),
	A2($elm$json$Json$Decode$field, 'metaKey', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 'repeat', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 'shiftKey', $elm$json$Json$Decode$bool));
var $author$project$KeyboardHelper$triggerOnCtrlEnter = function (onCtrlEnter) {
	var decoder = A2(
		$elm$json$Json$Decode$andThen,
		function (keyboardEvent) {
			var isEnter = _Utils_eq(
				keyboardEvent.l7,
				$elm$core$Maybe$Just('Enter'));
			var isCtrl = keyboardEvent.kT;
			return (isCtrl && isEnter) ? $elm$json$Json$Decode$succeed(
				{mp: onCtrlEnter, iu: true, ja: true}) : $elm$json$Json$Decode$fail('not CTRL+ENTER');
		},
		$Gizra$elm_keyboard_event$Keyboard$Event$decodeKeyboardEvent);
	return $mdgriffith$elm_ui$Element$htmlAttribute(
		A2($elm$html$Html$Events$custom, 'keydown', decoder));
};
var $mdgriffith$elm_ui$Internal$Model$Bottom = 2;
var $mdgriffith$elm_ui$Element$alignBottom = $mdgriffith$elm_ui$Internal$Model$AlignY(2);
var $author$project$Helpers$findWithNeighbors = F2(
	function (pred, list) {
		findWithNeighbors:
		while (true) {
			if (list.b) {
				if (list.b.b) {
					if (list.b.b.b) {
						var a = list.a;
						var _v1 = list.b;
						var b = _v1.a;
						var _v2 = _v1.b;
						var c = _v2.a;
						var rest = _v2.b;
						if (pred(b)) {
							return _Utils_Tuple3(
								$elm$core$Maybe$Just(a),
								$elm$core$Maybe$Just(b),
								$elm$core$Maybe$Just(c));
						} else {
							if (pred(a)) {
								return _Utils_Tuple3(
									$elm$core$Maybe$Nothing,
									$elm$core$Maybe$Just(a),
									$elm$core$Maybe$Just(b));
							} else {
								var $temp$pred = pred,
									$temp$list = A2(
									$elm$core$List$cons,
									b,
									A2($elm$core$List$cons, c, rest));
								pred = $temp$pred;
								list = $temp$list;
								continue findWithNeighbors;
							}
						}
					} else {
						var a = list.a;
						var _v3 = list.b;
						var b = _v3.a;
						if (pred(a)) {
							return _Utils_Tuple3(
								$elm$core$Maybe$Nothing,
								$elm$core$Maybe$Just(a),
								$elm$core$Maybe$Just(b));
						} else {
							if (pred(b)) {
								return _Utils_Tuple3(
									$elm$core$Maybe$Just(a),
									$elm$core$Maybe$Just(b),
									$elm$core$Maybe$Nothing);
							} else {
								var $temp$pred = pred,
									$temp$list = _List_fromArray(
									[b]);
								pred = $temp$pred;
								list = $temp$list;
								continue findWithNeighbors;
							}
						}
					}
				} else {
					var a = list.a;
					return pred(a) ? _Utils_Tuple3(
						$elm$core$Maybe$Nothing,
						$elm$core$Maybe$Just(a),
						$elm$core$Maybe$Nothing) : _Utils_Tuple3($elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing);
				}
			} else {
				return _Utils_Tuple3($elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing);
			}
		}
	});
var $author$project$Helpers$transponator = F3(
	function (rowCount, index, a) {
		var rowNo = ((index / rowCount) | 0) + 1;
		var colNo = (index % rowCount) + 1;
		return {ft: rowNo, k9: a.k9, er: a.er, ma: a.ma, mD: a.mD, iP: colNo};
	});
var $author$project$Components$Vehicles$MakeDialog$vehicleMakeDialog = F4(
	function (makes, onSelectVehicleMake, onCloseButtonClick, selectedMake) {
		var sortedMakes = A2(
			$elm$core$List$sortBy,
			function ($) {
				return $.mD;
			},
			makes);
		var colCount = 4;
		var rowCount = $elm$core$Basics$ceiling(
			$elm$core$List$length(makes) / colCount);
		var makes_with_extra_data = A2(
			$elm$core$List$map,
			function (make) {
				var neighbors = A2(
					$author$project$Helpers$findWithNeighbors,
					function (m) {
						return _Utils_eq(m.mD, make.mD);
					},
					sortedMakes);
				return {ft: make.ft, ma: make.ma, mD: make.mD, ib: neighbors, iP: make.iP};
			},
			A2(
				$elm$core$List$indexedMap,
				$author$project$Helpers$transponator(rowCount),
				A2(
					$elm$core$List$map,
					function (m) {
						return {k9: $elm$core$Maybe$Nothing, er: m.er, ma: m.jC, mD: m.mD};
					},
					sortedMakes)));
		var buttonWidth = $mdgriffith$elm_ui$Element$px(148);
		var buttonHeight = $mdgriffith$elm_ui$Element$px(32);
		var makeSelectButton = F2(
			function (makeName, isSelected) {
				return $author$project$Components$Button$toElement(
					A2(
						$author$project$Components$Button$setCustomAttributes,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Border$rounded(2)
							]),
						A2(
							$author$project$Components$Button$height,
							buttonHeight,
							A2(
								$author$project$Components$Button$width,
								buttonWidth,
								A2(
									$author$project$Components$Button$textSize,
									$author$project$Styles$fontSizesInPixels.mc,
									A2(
										$author$project$Components$Button$text,
										makeName,
										A2(
											$author$project$Components$Button$toggleButton,
											onSelectVehicleMake(makeName),
											isSelected)))))));
			});
		var buttonMaker = function (vehicleMake) {
			var previousMake = function (_v0) {
				var a = _v0.a;
				return a;
			}(vehicleMake.ib);
			var isSelectedMake = _Utils_eq(vehicleMake.mD, selectedMake);
			var firstLetterChanged = A2(
				$elm$core$Maybe$withDefault,
				true,
				A2(
					$elm$core$Maybe$map,
					function (previous) {
						return (vehicleMake.mD === 'MUU') ? false : (!_Utils_eq(
							A2($elm$core$String$left, 1, previous.mD),
							A2($elm$core$String$left, 1, vehicleMake.mD)));
					},
					previousMake));
			return A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$spacing(4)
					]),
				_List_fromArray(
					[
						firstLetterChanged ? A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$centerX,
								$mdgriffith$elm_ui$Element$paddingEach(
								{g1: 0, h1: 0, iN: 0, jl: 4}),
								$mdgriffith$elm_ui$Element$Font$color(
								$author$project$Colors$onSurface(7))
							]),
						$mdgriffith$elm_ui$Element$text(
							A2($elm$core$String$left, 1, vehicleMake.mD))) : $mdgriffith$elm_ui$Element$none,
						A2(makeSelectButton, vehicleMake.mD, isSelectedMake)
					]));
		};
		return A2(
			$author$project$Components$Modal$modal,
			{kz: true, mR: onCloseButtonClick},
			A2(
				$author$project$Styles$card_wrapper,
				false,
				A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$padding(2),
							$mdgriffith$elm_ui$Element$spacing(8)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$centerX,
											$mdgriffith$elm_ui$Element$Font$bold,
											$mdgriffith$elm_ui$Element$Font$color($author$project$Colors$onBackground)
										]),
									$mdgriffith$elm_ui$Element$text('Ajoneuvon merkki')),
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$Font$color(
											$author$project$Colors$onSurface(5))
										]),
									$author$project$Components$CloseButton$closeButton(onCloseButtonClick))
								])),
							A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$spacing(8)
								]),
							A2(
								$elm$core$List$map,
								function (col) {
									var makes_for_col = A2(
										$elm$core$List$filter,
										function (m) {
											return m.mD !== 'MUU';
										},
										A2(
											$elm$core$List$filter,
											function (m) {
												return _Utils_eq(m.ft, col);
											},
											makes_with_extra_data));
									return A2(
										$mdgriffith$elm_ui$Element$column,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
												$mdgriffith$elm_ui$Element$alignTop
											]),
										_Utils_ap(
											A2($elm$core$List$map, buttonMaker, makes_for_col),
											_Utils_eq(col, colCount) ? _List_fromArray(
												[
													A2(
													$mdgriffith$elm_ui$Element$column,
													_List_fromArray(
														[$mdgriffith$elm_ui$Element$alignBottom]),
													_List_fromArray(
														[
															A2(makeSelectButton, 'MUU', selectedMake === 'MUU'),
															$author$project$Components$Button$toElement(
															A2(
																$author$project$Components$Button$height,
																buttonHeight,
																A2(
																	$author$project$Components$Button$width,
																	buttonWidth,
																	A2(
																		$author$project$Components$Button$textSize,
																		$author$project$Styles$fontSizesInPixels.mc,
																		$author$project$Components$Button$secondary(
																			A2(
																				$author$project$Components$Button$text,
																				'EI MERKKIÄ',
																				A2(
																					$author$project$Components$Button$onClick,
																					onSelectVehicleMake(''),
																					$author$project$Components$Button$button)))))))
														]))
												]) : _List_Nil));
								},
								A2($elm$core$List$range, 1, colCount)))
						]))));
	});
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$ShowVehicleMakeDialog = {$: 2};
var $author$project$Components$Vehicles$MakeSelector$vehicleMakeSelector = F3(
	function (vehicleMake, onShowVehicleMakeDialogClick, makeDropdownConfig) {
		if (!makeDropdownConfig.$) {
			var dropdownConfig = makeDropdownConfig.a;
			return A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$map,
						dropdownConfig.mU,
						$author$project$Components$Dropdown$view(dropdownConfig.lk)),
						$author$project$Components$Button$toElement(
						$author$project$Components$Button$secondary(
							A2(
								$author$project$Components$Button$setCustomAttributes,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$alignRight]),
								A2(
									$author$project$Components$Button$height,
									$mdgriffith$elm_ui$Element$fill,
									A2(
										$author$project$Components$Button$id,
										$author$project$Components$Vehicles$MakeSelector$openVehicleMakeDialogButtonId,
										A2(
											$author$project$Components$Button$icon,
											$author$project$Icons$ellipsis_h,
											A2($author$project$Components$Button$onClick, onShowVehicleMakeDialogClick, $author$project$Components$Button$button)))))))
					]));
		} else {
			var label = A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Font$size($author$project$Styles$fontSizesInPixels.mc)
					]),
				$elm$core$String$isEmpty(vehicleMake) ? A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$color(
							$author$project$Colors$onSurface(1))
						]),
					$mdgriffith$elm_ui$Element$text('Merkki')) : A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$color(
							$author$project$Colors$onSurface(9))
						]),
					$mdgriffith$elm_ui$Element$text(
						$elm$core$String$toUpper(vehicleMake))));
			return $author$project$Components$Button$toElement(
				A2(
					$author$project$Components$Button$id,
					$author$project$Components$Vehicles$MakeSelector$openVehicleMakeDialogButtonId,
					A2(
						$author$project$Components$Button$height,
						$mdgriffith$elm_ui$Element$fill,
						A2(
							$author$project$Components$Button$width,
							$mdgriffith$elm_ui$Element$fill,
							A2(
								$author$project$Components$Button$icon,
								label,
								A2($author$project$Components$Button$onClick, onShowVehicleMakeDialogClick, $author$project$Components$Button$button))))));
		}
	});
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$vehicleMakeDropdownField = F3(
	function (touchEventDetected, vehicleMakeDropdownModel, vehicleMake) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px(30)),
					$mdgriffith$elm_ui$Element$Background$color(
					$author$project$Colors$surface(1)),
					$mdgriffith$elm_ui$Element$Border$rounded(3)
				]),
			A3(
				$author$project$Components$Vehicles$MakeSelector$vehicleMakeSelector,
				vehicleMake,
				$author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$ShowVehicleMakeDialog,
				touchEventDetected ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
					{lk: vehicleMakeDropdownModel, mU: $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$GotMakeDropdownMsg})));
	});
var $author$project$Components$WorkerSelector$AllWorkersDialog_Hide = {$: 2};
var $author$project$Components$WorkerSelector$DeselectWorker = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Components$WorkerSelector$SelectWorker = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Components$WorkerSelector$dropDeletedWorkersUnlessSelected = F2(
	function (selectedWorkerIds, workers) {
		var isSelectedWorker = function (worker) {
			return A2($author$project$Helpers$isInSet, selectedWorkerIds, worker.er);
		};
		return A2(
			$elm$core$List$filter,
			function (worker) {
				return _Utils_eq(worker.k9, $elm$core$Maybe$Nothing) || isSelectedWorker(worker);
			},
			workers);
	});
var $mdgriffith$elm_ui$Internal$Model$Monospace = {$: 2};
var $mdgriffith$elm_ui$Element$Font$monospace = $mdgriffith$elm_ui$Internal$Model$Monospace;
var $author$project$Components$WorkerSelector$allWorkersDialog = F3(
	function (eventHandlers, availableWorkers, selectedWorkerIds) {
		var workersToShow = A2(
			$elm$core$List$sortBy,
			function ($) {
				return $.mD;
			},
			A2($author$project$Components$WorkerSelector$dropDeletedWorkersUnlessSelected, selectedWorkerIds, availableWorkers));
		var workerSelectButton = F2(
			function (workerOption, isSelected) {
				return A2(
					$elm$core$Maybe$withDefault,
					$mdgriffith$elm_ui$Element$none,
					A2(
						$elm$core$Maybe$map,
						function (worker) {
							var onClick = isSelected ? A2(
								$author$project$Components$WorkerSelector$DeselectWorker,
								worker,
								eventHandlers.f3(worker)) : A2(
								$author$project$Components$WorkerSelector$SelectWorker,
								worker,
								eventHandlers.f4(worker));
							return $author$project$Components$Button$toElement(
								A2(
									$author$project$Components$Button$setCustomAttributes,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$Border$rounded(2)
										]),
									$author$project$Components$Button$primary(
										A2(
											$author$project$Components$Button$height,
											$mdgriffith$elm_ui$Element$px(32),
											A2(
												$author$project$Components$Button$width,
												$mdgriffith$elm_ui$Element$px(250),
												A2(
													$author$project$Components$Button$iconRight,
													A2(
														$mdgriffith$elm_ui$Element$el,
														_List_fromArray(
															[
																$mdgriffith$elm_ui$Element$alignRight,
																$mdgriffith$elm_ui$Element$Border$width(1),
																$mdgriffith$elm_ui$Element$Border$rounded(4),
																$mdgriffith$elm_ui$Element$Border$color(
																$author$project$Colors$surface(4)),
																$mdgriffith$elm_ui$Element$padding(3),
																$mdgriffith$elm_ui$Element$Font$size(14),
																$mdgriffith$elm_ui$Element$Font$family(
																_List_fromArray(
																	[$mdgriffith$elm_ui$Element$Font$monospace]))
															]),
														$mdgriffith$elm_ui$Element$text(workerOption.ma)),
													A2(
														$author$project$Components$Button$textSize,
														$author$project$Styles$fontSizesInPixels.mc,
														A2(
															$author$project$Components$Button$text,
															workerOption.mD,
															A2($author$project$Components$Button$toggleButton, onClick, isSelected)))))))));
						},
						A3(
							$author$project$Helpers$firstInList,
							availableWorkers,
							function ($) {
								return $.er;
							},
							workerOption.er)));
			});
		var colCount = 3;
		var rowCount = $elm$core$Basics$ceiling(
			$elm$core$List$length(availableWorkers) / colCount);
		var workers_with_layout_data = A2(
			$elm$core$List$map,
			function (workerOption) {
				var neighbors = A2(
					$author$project$Helpers$findWithNeighbors,
					function (w) {
						return _Utils_eq(w.mD, workerOption.mD);
					},
					workersToShow);
				return {ft: workerOption.ft, k9: workerOption.k9, er: workerOption.er, ma: workerOption.ma, mD: workerOption.mD, ib: neighbors, iP: workerOption.iP};
			},
			A2(
				$elm$core$List$indexedMap,
				$author$project$Helpers$transponator(rowCount),
				A2(
					$elm$core$List$map,
					function (worker) {
						return {k9: worker.k9, er: worker.er, ma: worker.ha, mD: worker.mD};
					},
					workersToShow)));
		var buttonMaker = function (workerOption) {
			var previousWorker = function (_v0) {
				var a = _v0.a;
				return a;
			}(workerOption.ib);
			var isSelectedWorker = A2($author$project$Helpers$isInSet, selectedWorkerIds, workerOption.er);
			var firstLetterChanged = A2(
				$elm$core$Maybe$withDefault,
				true,
				A2(
					$elm$core$Maybe$map,
					function (previous) {
						return !_Utils_eq(
							A2($elm$core$String$left, 1, previous.mD),
							A2($elm$core$String$left, 1, workerOption.mD));
					},
					previousWorker));
			return A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$spacing(4)
					]),
				_List_fromArray(
					[
						firstLetterChanged ? A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$centerX,
								$mdgriffith$elm_ui$Element$paddingEach(
								{g1: 0, h1: 0, iN: 0, jl: 4}),
								$mdgriffith$elm_ui$Element$Font$color(
								$author$project$Colors$onSurface(7))
							]),
						$mdgriffith$elm_ui$Element$text(
							A2($elm$core$String$left, 1, workerOption.mD))) : $mdgriffith$elm_ui$Element$none,
						A2(workerSelectButton, workerOption, isSelectedWorker)
					]));
		};
		return A2(
			$author$project$Components$Modal$modal,
			{kz: true, mR: $author$project$Components$WorkerSelector$AllWorkersDialog_Hide},
			A2(
				$author$project$Styles$card_wrapper,
				false,
				A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$padding(2),
							$mdgriffith$elm_ui$Element$spacing(8)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$centerX,
											$mdgriffith$elm_ui$Element$Font$bold,
											$mdgriffith$elm_ui$Element$Font$color($author$project$Colors$onBackground)
										]),
									$mdgriffith$elm_ui$Element$text('Tekijät')),
									$author$project$Components$CloseButton$closeButton($author$project$Components$WorkerSelector$AllWorkersDialog_Hide)
								])),
							A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$spacing(8)
								]),
							A2(
								$elm$core$List$map,
								function (col) {
									var workers_for_col = A2(
										$elm$core$List$filter,
										function (w) {
											return _Utils_eq(w.ft, col);
										},
										workers_with_layout_data);
									return A2(
										$mdgriffith$elm_ui$Element$column,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
												$mdgriffith$elm_ui$Element$alignTop,
												$mdgriffith$elm_ui$Element$spacing(6)
											]),
										_List_fromArray(
											[
												A2(
												$mdgriffith$elm_ui$Element$column,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
													]),
												A2($elm$core$List$map, buttonMaker, workers_for_col))
											]));
								},
								A2($elm$core$List$range, 1, colCount)))
						]))));
	});
var $author$project$Components$WorkerSelector$AllWorkersDialog_Show = {$: 3};
var $author$project$Components$WorkerSelector$showAllWorkersButton = $author$project$Components$Button$toElement(
	A2(
		$author$project$Components$Button$id,
		$author$project$Components$WorkerSelector$openAllWorkersDialogButtonId,
		$author$project$Components$Button$secondary(
			A2(
				$author$project$Components$Button$icon,
				$author$project$Icons$ellipsis_h,
				A2($author$project$Components$Button$onClick, $author$project$Components$WorkerSelector$AllWorkersDialog_Show, $author$project$Components$Button$button)))));
var $author$project$Utils$Custom$byDefault = F2(
	function (_default, customOrDefault) {
		if (!customOrDefault.$) {
			var a = customOrDefault.a;
			return a;
		} else {
			return _default;
		}
	});
var $author$project$Utils$Custom$map = F2(
	function (mapper, customOrDefault) {
		if (!customOrDefault.$) {
			var a = customOrDefault.a;
			return $author$project$Utils$Custom$Custom(
				mapper(a));
		} else {
			return $author$project$Utils$Custom$UseDefault;
		}
	});
var $author$project$Components$WorkerSelector$workerToggleButton = F3(
	function (eventHandlers, styles, _v0) {
		var worker = _v0.a;
		var isSelected = _v0.b;
		var onClick = isSelected ? A2(
			$author$project$Components$WorkerSelector$DeselectWorker,
			worker,
			eventHandlers.f3(worker)) : A2(
			$author$project$Components$WorkerSelector$SelectWorker,
			worker,
			eventHandlers.f4(worker));
		return $author$project$Components$Button$toElement(
			$author$project$Components$Button$primary(
				A3(
					$elm$core$Basics$apR,
					A2($author$project$Utils$Custom$map, $author$project$Components$Button$fontSize, styles.g2),
					$author$project$Utils$Custom$byDefault($author$project$Helpers$doNothing),
					A2(
						$author$project$Components$Button$tooltip,
						worker.mD,
						A2(
							$author$project$Components$Button$text,
							worker.ha,
							A2($author$project$Components$Button$toggleButton, onClick, isSelected))))));
	});
var $author$project$Components$WorkerSelector$view = F2(
	function (model, eventHandlers) {
		var quickAccessWorkers = A2(
			$author$project$Components$WorkerSelector$dropDeletedWorkersUnlessSelected,
			model.ah,
			A2(
				$elm_community$list_extra$List$Extra$uniqueBy,
				function ($) {
					return $.er;
				},
				_Utils_ap(
					model.ga,
					A3(
						$author$project$Helpers$pickFromList,
						model.bU,
						function ($) {
							return $.er;
						},
						$elm$core$Set$toList(model.ah)))));
		var isSelectedWorker = function (worker) {
			return A2($author$project$Helpers$isInSet, model.ah, worker.er);
		};
		var workerToggleButtons = A2(
			$elm$core$List$map,
			A2($author$project$Components$WorkerSelector$workerToggleButton, eventHandlers, model.dx),
			A2(
				$elm$core$List$map,
				function (worker) {
					return _Utils_Tuple2(
						worker,
						isSelectedWorker(worker));
				},
				A2(
					$elm$core$List$sortBy,
					function ($) {
						return $.ha;
					},
					quickAccessWorkers)));
		return A2(
			$mdgriffith$elm_ui$Element$wrappedRow,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$spacing(6),
					$mdgriffith$elm_ui$Element$inFront(
					function () {
						var _v0 = model.dj;
						if (!_v0) {
							return A3($author$project$Components$WorkerSelector$allWorkersDialog, eventHandlers, model.bU, model.ah);
						} else {
							return $mdgriffith$elm_ui$Element$none;
						}
					}())
				]),
			_Utils_ap(
				workerToggleButtons,
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$alignRight]),
						$author$project$Components$WorkerSelector$showAllWorkersButton)
					])));
	});
var $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$view = F3(
	function (isPendingServerRequest, _v0, model) {
		var touchEventDetected = _v0.gy;
		var saveButton = $author$project$Components$Button$toElement(
			A2(
				$author$project$Components$Button$id,
				'create-queued-task-button',
				A2(
					$author$project$Components$Button$hint,
					'CTRL + ENTER',
					$author$project$Components$Button$primary(
						A2(
							$author$project$Components$Button$textSize,
							$author$project$Styles$fontSizesInPixels.mc,
							A2(
								$author$project$Components$Button$text,
								'LISÄÄ JONOON',
								A2(
									$author$project$Components$Button$busy,
									isPendingServerRequest,
									A2($author$project$Components$Button$onClick, $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$CreateButtonClicked, $author$project$Components$Button$button))))))));
		var fields = model.hs;
		var dialog = function () {
			var _v1 = model.dj;
			if (_v1 === 1) {
				return A4(
					$author$project$Components$Vehicles$MakeDialog$vehicleMakeDialog,
					model.am,
					$author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$SelectVehicleMake,
					$author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$HideSubDialog($author$project$Components$Vehicles$MakeSelector$openVehicleMakeDialogButtonId),
					fields.fd);
			} else {
				return $mdgriffith$elm_ui$Element$none;
			}
		}();
		var closeButton = $author$project$Components$Button$toElement(
			$author$project$Components$Button$secondary(
				A2(
					$author$project$Components$Button$textSize,
					$author$project$Styles$fontSizesInPixels.mc,
					A2(
						$author$project$Components$Button$text,
						'SULJE',
						A2(
							$author$project$Components$Button$busy,
							isPendingServerRequest,
							A2($author$project$Components$Button$onClick, $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$CollapseButtonClicked, $author$project$Components$Button$button))))));
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$paddingEach(
						{g1: 10, h1: 2, iN: 2, jl: 0}),
						$mdgriffith$elm_ui$Element$spacing(4),
						$mdgriffith$elm_ui$Element$inFront(dialog)
					]),
				$author$project$Helpers$attributeIf(
					_List_fromArray(
						[
							_Utils_Tuple2(
							!isPendingServerRequest,
							$author$project$KeyboardHelper$triggerOnCtrlEnter($author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$CtrlAndEnterPressed))
						]))),
			_List_fromArray(
				[
					$author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$licenseNumberInputField(fields.mf),
					A3($author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$vehicleMakeDropdownField, touchEventDetected, model.cH, fields.fd),
					$author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$instructionsInputField(fields.lY),
					$author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$productDropdownFields(model.dr),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Background$color(
							$author$project$Colors$surface(1)),
							$mdgriffith$elm_ui$Element$Border$rounded(4),
							$mdgriffith$elm_ui$Element$paddingEach(
							{g1: 0, h1: 3, iN: 0, jl: 2}),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					A2(
						$mdgriffith$elm_ui$Element$map,
						$author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$GotSubModuleMsg_WorkerSelector,
						A2(
							$author$project$Components$WorkerSelector$view,
							model.cx,
							{f3: $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$Worker_Deselect, f4: $author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$Worker_Select}))),
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$alignRight,
							$mdgriffith$elm_ui$Element$paddingEach(
							{g1: 0, h1: 0, iN: 0, jl: 2}),
							$mdgriffith$elm_ui$Element$spacing(2)
						]),
					A2(
						$elm$core$List$map,
						$mdgriffith$elm_ui$Element$el(
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$alignTop])),
						_List_fromArray(
							[closeButton, saveButton])))
				]));
	});
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$Cancel = {$: 19};
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$CloseVehicleMakeDialog = function (a) {
	return {$: 15, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$DeleteButtonClicked = {$: 18};
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$HideSubDialog = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$SaveButtonClicked = {$: 17};
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$CommentUpdated = function (a) {
	return {$: 9, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$InstructionsUpdated = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$Worker_Deselect = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$Worker_Select = function (a) {
	return {$: 13, a: a};
};
var $author$project$Icons$comment = $author$project$Icons$fa_icon('fas fa-comment');
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$SelectNothingDate = {$: 3};
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$ShowWorkDateDialog = {$: 16};
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$TargetDateDecreased = {$: 1};
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$TargetDateIncreased = {$: 2};
var $author$project$Helpers$dateToStringShort = function (date) {
	return A2($justinmimbs$date$Date$format, 'd.M.y', date);
};
var $author$project$Colors$success = $smucode$elm_flat_colors$FlatColors$DutchPalette$merchantMarineBlue;
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$view_editorItemIcon = function (icon) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width(
				$mdgriffith$elm_ui$Element$px(28)),
				$mdgriffith$elm_ui$Element$paddingEach(
				{g1: 0, h1: 0, iN: 8, jl: 0})
			]),
		A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[$mdgriffith$elm_ui$Element$centerX]),
			icon));
};
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$view_editorItemContainer = F2(
	function (icon, elements) {
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$color($author$project$Colors$success)
						]),
					$author$project$Pages$Tasklist$TaskQueue$TaskEditor$view_editorItemIcon(icon)),
					A2(
					$mdgriffith$elm_ui$Element$wrappedRow,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$spacing(4)
						]),
					elements)
				]));
	});
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$date_editor = function (selectedDate) {
	var selectedDateAsString = A2(
		$elm$core$Maybe$withDefault,
		'ei päivämäärää',
		A2(
			$elm$core$Maybe$map,
			function (date) {
				return $author$project$Language$dateToWeekdayFinAbbreviation(date) + (' ' + $author$project$Helpers$dateToStringShort(date));
			},
			selectedDate));
	var noTargetDateButton = $author$project$Components$Button$toElement(
		$author$project$Components$Button$secondary(
			A2(
				$author$project$Components$Button$textSize,
				$author$project$Styles$fontSizesInPixels.mc,
				A2(
					$author$project$Components$Button$text,
					'EI PÄIVÄMÄÄRÄÄ',
					A2($author$project$Components$Button$onClick, $author$project$Pages$Tasklist$TaskQueue$TaskEditor$SelectNothingDate, $author$project$Components$Button$button)))));
	var dateSelector = A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$paddingEach(
				{g1: 0, h1: 0, iN: 60, jl: 0}),
				$mdgriffith$elm_ui$Element$behindContent(
				A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$Events$onClick($author$project$Pages$Tasklist$TaskQueue$TaskEditor$ShowWorkDateDialog),
							$author$project$Cursor$cursor(24)
						]),
					$mdgriffith$elm_ui$Element$none))
			]),
		A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[$mdgriffith$elm_ui$Element$centerX]),
			_List_fromArray(
				[
					$author$project$Components$Button$toElement(
					$author$project$Components$Button$secondary(
						A2(
							$author$project$Components$Button$icon,
							$author$project$Icons$chevronLeft,
							A2($author$project$Components$Button$onClick, $author$project$Pages$Tasklist$TaskQueue$TaskEditor$TargetDateDecreased, $author$project$Components$Button$button)))),
					$author$project$Components$Button$toElement(
					A2(
						$author$project$Components$Button$width,
						$mdgriffith$elm_ui$Element$px(124),
						A2(
							$author$project$Components$Button$id,
							'open-work-date-dialog-button',
							A2(
								$author$project$Components$Button$textColor,
								$author$project$Colors$onSurface(9),
								A2(
									$author$project$Components$Button$text,
									selectedDateAsString,
									A2($author$project$Components$Button$onClick, $author$project$Pages$Tasklist$TaskQueue$TaskEditor$ShowWorkDateDialog, $author$project$Components$Button$button)))))),
					$author$project$Components$Button$toElement(
					$author$project$Components$Button$secondary(
						A2(
							$author$project$Components$Button$icon,
							$author$project$Icons$chevronRight,
							A2($author$project$Components$Button$onClick, $author$project$Pages$Tasklist$TaskQueue$TaskEditor$TargetDateIncreased, $author$project$Components$Button$button))))
				])));
	return A2(
		$author$project$Styles$card_wrapper,
		true,
		A2(
			$author$project$Pages$Tasklist$TaskQueue$TaskEditor$view_editorItemContainer,
			A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Events$onClick($author$project$Pages$Tasklist$TaskQueue$TaskEditor$ShowWorkDateDialog),
						$author$project$Cursor$cursor(24)
					]),
				$author$project$Icons$calendar),
			$elm$core$List$singleton(
				A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$inFront(
							A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$alignRight,
										$mdgriffith$elm_ui$Element$transparent(
										_Utils_eq(selectedDate, $elm$core$Maybe$Nothing))
									]),
								noTargetDateButton))
						]),
					dateSelector))));
};
var $author$project$Icons$info = $author$project$Icons$fa_icon('fas fa-info');
var $author$project$Types$User$priceInputModeVatPct = function (value) {
	if (value === 1) {
		return 0;
	} else {
		return 25.5;
	}
};
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$ProductPriceTyped = F2(
	function (a, b) {
		return {$: 10, a: a, b: b};
	});
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$SelectProduct = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$UnselectProduct = function (a) {
	return {$: 7, a: a};
};
var $mdgriffith$elm_ui$Element$Font$strike = $mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.oh);
var $author$project$Colors$accent = $smucode$elm_flat_colors$FlatColors$DutchPalette$redPigment;
var $elm$html$Html$Attributes$lang = $elm$html$Html$Attributes$stringProperty('lang');
var $elm$html$Html$Attributes$step = function (n) {
	return A2($elm$html$Html$Attributes$stringProperty, 'step', n);
};
var $author$project$Components$ProductPriceEditor$view_priceInput = F4(
	function (elementId, selectedProduct, currentText, onPriceChange) {
		var isValidPrice = !_Utils_eq(selectedProduct.iv, $elm$core$Maybe$Nothing);
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$spacing(2)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						(!isValidPrice) ? _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Border$rounded(4),
								$mdgriffith$elm_ui$Element$Border$width(1),
								$mdgriffith$elm_ui$Element$Border$color(
								$author$project$Colors$primary(0))
							]) : _List_Nil),
					A2(
						$mdgriffith$elm_ui$Element$Input$text,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
								A2($mdgriffith$elm_ui$Element$paddingXY, 2, 6),
								$mdgriffith$elm_ui$Element$centerY,
								$mdgriffith$elm_ui$Element$Background$color(
								$author$project$Colors$surface(0)),
								$mdgriffith$elm_ui$Element$Font$color(
								$author$project$Colors$onSurface(7)),
								$mdgriffith$elm_ui$Element$Font$alignRight,
								$mdgriffith$elm_ui$Element$Border$width(0),
								$mdgriffith$elm_ui$Element$htmlAttribute(
								$elm$html$Html$Attributes$type_('number')),
								$mdgriffith$elm_ui$Element$htmlAttribute(
								$elm$html$Html$Attributes$step('0.01')),
								$mdgriffith$elm_ui$Element$htmlAttribute(
								$elm$html$Html$Attributes$lang('fi-FI')),
								$author$project$Utils$HtmlUtil$id(elementId)
							]),
						{
							ma: $mdgriffith$elm_ui$Element$Input$labelHidden('hinta'),
							mK: onPriceChange(selectedProduct),
							nt: $elm$core$Maybe$Just(
								A2(
									$mdgriffith$elm_ui$Element$Input$placeholder,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$Font$color($author$project$Colors$accent),
											$mdgriffith$elm_ui$Element$Font$bold
										]),
									A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[$mdgriffith$elm_ui$Element$alignRight]),
										$mdgriffith$elm_ui$Element$text('')))),
							bJ: currentText
						})),
					A2(
					$mdgriffith$elm_ui$Element$el,
					(!isValidPrice) ? _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$color(
							$author$project$Colors$primary(0))
						]) : _List_Nil,
					$mdgriffith$elm_ui$Element$text('€'))
				]));
	});
var $author$project$Components$ProductPriceEditor$product_price_editor = F4(
	function (selectedProduct, currentPriceText, maybeDefaultPrice, onPriceChange) {
		var isDefaultPriceOverridden = (!_Utils_eq(maybeDefaultPrice, $elm$core$Maybe$Nothing)) && (!_Utils_eq(selectedProduct.iv, maybeDefaultPrice));
		var inputElementId = 'price-input-' + $elm$core$String$fromInt(selectedProduct.iz);
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					isDefaultPriceOverridden ? A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$alignRight,
								$mdgriffith$elm_ui$Element$Font$size(14),
								$mdgriffith$elm_ui$Element$Font$color(
								$author$project$Colors$surface(3)),
								$mdgriffith$elm_ui$Element$Font$strike
							]),
						$mdgriffith$elm_ui$Element$text(
							$author$project$Helpers$toEuroString(
								A2($elm$core$Maybe$withDefault, 0, maybeDefaultPrice)))) : $mdgriffith$elm_ui$Element$none),
					A4($author$project$Components$ProductPriceEditor$view_priceInput, inputElementId, selectedProduct, currentPriceText, onPriceChange)
				]));
	});
var $author$project$Components$ProductPriceEditor$render = F6(
	function (productPriceInputs, availableProduct, maybeSelectedProduct, defaultPrice, onPriceChange, onSelectProduct) {
		var _v0 = function () {
			if (!maybeSelectedProduct.$) {
				var p = maybeSelectedProduct.a;
				return _Utils_Tuple3(
					p,
					true,
					A2(
						$elm$core$Maybe$withDefault,
						'',
						A2($elm$core$Dict$get, p.iz, productPriceInputs)));
			} else {
				return _Utils_Tuple3(
					A2($author$project$Types$SelectedProduct$SelectedProduct, 0, $elm$core$Maybe$Nothing),
					false,
					'');
			}
		}();
		var selectedProduct = _v0.a;
		var isSelected = _v0.b;
		var currentPriceText = _v0.c;
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$Font$size(16)
				]),
			_List_fromArray(
				[
					isSelected ? A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$alignLeft, $mdgriffith$elm_ui$Element$centerY]),
					A4($author$project$Components$ProductPriceEditor$product_price_editor, selectedProduct, currentPriceText, defaultPrice, onPriceChange)) : A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$Font$color(
							$author$project$Colors$surface(3)),
							$mdgriffith$elm_ui$Element$Events$onClick(
							A2(onSelectProduct, availableProduct.er, defaultPrice)),
							$author$project$Cursor$cursor(24)
						]),
					(!_Utils_eq(defaultPrice, $elm$core$Maybe$Nothing)) ? A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$alignRight, $mdgriffith$elm_ui$Element$centerY]),
						$mdgriffith$elm_ui$Element$text(
							$author$project$Helpers$toEuroString(
								A2($elm$core$Maybe$withDefault, 0, defaultPrice)))) : A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
						$mdgriffith$elm_ui$Element$text('-')))
				]));
	});
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$product_selector = F4(
	function (productPriceInputs, selected_products, defaultPricesDict, available_product) {
		var selectedProduct = A2(
			$author$project$Helpers$firstWhere,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.iz;
				},
				$elm$core$Basics$eq(available_product.er)),
			selected_products);
		var isSelected = function () {
			if (!selectedProduct.$) {
				return true;
			} else {
				return false;
			}
		}();
		var defaultPrice = A2($elm$core$Dict$get, available_product.er, defaultPricesDict);
		var toggleProduct = isSelected ? $author$project$Pages$Tasklist$TaskQueue$TaskEditor$UnselectProduct(available_product.er) : A2($author$project$Pages$Tasklist$TaskQueue$TaskEditor$SelectProduct, available_product.er, defaultPrice);
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(6),
					$mdgriffith$elm_ui$Element$width(
					$mdgriffith$elm_ui$Element$px(135))
				]),
			_List_fromArray(
				[
					$author$project$Components$Button$toElement(
					A4(
						$author$project$Components$ProductToggleButton$button,
						'product-toggle-' + $elm$core$String$fromInt(available_product.er),
						available_product,
						toggleProduct,
						isSelected)),
					A6($author$project$Components$ProductPriceEditor$render, productPriceInputs, available_product, selectedProduct, defaultPrice, $author$project$Pages$Tasklist$TaskQueue$TaskEditor$ProductPriceTyped, $author$project$Pages$Tasklist$TaskQueue$TaskEditor$SelectProduct)
				]));
	});
var $author$project$Types$User$secondaryPriceInputMode = function (primaryPriceInputMode) {
	if (primaryPriceInputMode === 1) {
		return 0;
	} else {
		return 1;
	}
};
var $author$project$Icons$shopping_cart = $author$project$Icons$fa_icon('fas fa-shopping-cart');
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$products_editor = F5(
	function (priceInputMode, productPriceInputs, available_products, defaultPricesDict, selected_products) {
		var totalPrice = A2($author$project$Types$SelectedProduct$productsTotal, priceInputMode, selected_products);
		var secondaryPriceMode = $author$project$Types$User$secondaryPriceInputMode(priceInputMode);
		var totalPriceInSecondaryVatMode = A2($author$project$Types$SelectedProduct$productsTotal, secondaryPriceMode, selected_products);
		return A2(
			$author$project$Styles$card_wrapper,
			true,
			A2(
				$author$project$Pages$Tasklist$TaskQueue$TaskEditor$view_editorItemContainer,
				$author$project$Icons$shopping_cart,
				$elm$core$List$singleton(
					A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$spacing(4)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$row,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$spacing(4)
									]),
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$centerX,
												$mdgriffith$elm_ui$Element$Font$bold,
												$mdgriffith$elm_ui$Element$Font$size(26),
												$mdgriffith$elm_ui$Element$paddingEach(
												{g1: 0, h1: 90, iN: 0, jl: 0})
											]),
										$mdgriffith$elm_ui$Element$text(
											$author$project$Helpers$toEuroString(totalPrice))),
										A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$centerX,
												$mdgriffith$elm_ui$Element$Font$color(
												$author$project$Colors$secondary(0)),
												$mdgriffith$elm_ui$Element$Font$size(14)
											]),
										$mdgriffith$elm_ui$Element$text(
											'ALV ' + ($elm$core$String$fromFloat(
												$author$project$Types$User$priceInputModeVatPct(priceInputMode)) + '%'))),
										A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$alignRight,
												$mdgriffith$elm_ui$Element$Font$color(
												$author$project$Colors$secondary(0)),
												$mdgriffith$elm_ui$Element$Font$size(14),
												$mdgriffith$elm_ui$Element$paddingEach(
												{g1: 0, h1: 0, iN: 4, jl: 0})
											]),
										$mdgriffith$elm_ui$Element$text(
											'(' + ($author$project$Helpers$toEuroString(totalPriceInSecondaryVatMode) + (' ALV ' + ($elm$core$String$fromFloat(
												$author$project$Types$User$priceInputModeVatPct(secondaryPriceMode)) + ')')))))
									])),
								A2(
								$mdgriffith$elm_ui$Element$wrappedRow,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$paddingEach(
										{g1: 0, h1: 0, iN: 0, jl: 4}),
										$mdgriffith$elm_ui$Element$spacing(8)
									]),
								A2(
									$elm$core$List$map,
									A3($author$project$Pages$Tasklist$TaskQueue$TaskEditor$product_selector, productPriceInputs, selected_products, defaultPricesDict),
									A2(
										$elm$core$List$sortBy,
										function ($) {
											return $.ha;
										},
										available_products)))
							])))));
	});
var $author$project$Styles$text_input = F3(
	function (labelText, onChange, valueText) {
		return A2(
			$mdgriffith$elm_ui$Element$Input$text,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$padding(6),
					$mdgriffith$elm_ui$Element$centerY,
					$mdgriffith$elm_ui$Element$Background$color(
					$author$project$Colors$surface(1)),
					$mdgriffith$elm_ui$Element$Font$color(
					$author$project$Colors$onSurface(9)),
					$mdgriffith$elm_ui$Element$Border$width(0),
					$mdgriffith$elm_ui$Element$Font$alignLeft
				]),
			{
				ma: $mdgriffith$elm_ui$Element$Input$labelHidden(labelText),
				mK: function (value) {
					return onChange(value);
				},
				nt: $elm$core$Maybe$Just(
					A2(
						$mdgriffith$elm_ui$Element$Input$placeholder,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$Font$alignLeft]),
						$mdgriffith$elm_ui$Element$text(labelText))),
				bJ: valueText
			});
	});
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$LicenseNumberTypedIn = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$ShowVehicleMakeDialog = {$: 14};
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$VehicleModelUpdated = function (a) {
	return {$: 5, a: a};
};
var $author$project$Icons$car = $author$project$Icons$fa_icon('fas fa-car');
var $mdgriffith$elm_ui$Element$fillPortion = $mdgriffith$elm_ui$Internal$Model$Fill;
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$vehicle_editor = F2(
	function (_v0, model) {
		var touchEventDetected = _v0.gy;
		var vehicleRowFieldHeight = touchEventDetected ? $mdgriffith$elm_ui$Element$px(38) : $mdgriffith$elm_ui$Element$px(34);
		var fields = model.hs;
		return A2(
			$author$project$Styles$card_wrapper,
			true,
			A2(
				$author$project$Pages$Tasklist$TaskQueue$TaskEditor$view_editorItemContainer,
				$author$project$Icons$car,
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$spacing(4)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$row,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$height(vehicleRowFieldHeight),
										$mdgriffith$elm_ui$Element$spacing(6)
									]),
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$width(
												$mdgriffith$elm_ui$Element$fillPortion(1)),
												$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
											]),
										A4(
											$author$project$Components$Input$textInput,
											_List_Nil,
											'Rekisterinumero',
											$author$project$Pages$Tasklist$TaskQueue$TaskEditor$LicenseNumberTypedIn,
											$author$project$Types$Vehicle$LicenseNumberField$toString(fields.mf))),
										A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$width(
												$mdgriffith$elm_ui$Element$fillPortion(1)),
												$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
												$mdgriffith$elm_ui$Element$Background$color(
												$author$project$Colors$surface(1)),
												$mdgriffith$elm_ui$Element$Border$rounded(3)
											]),
										A3(
											$author$project$Components$Vehicles$MakeSelector$vehicleMakeSelector,
											fields.fd,
											$author$project$Pages$Tasklist$TaskQueue$TaskEditor$ShowVehicleMakeDialog,
											touchEventDetected ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
												{lk: model.ax, mU: $author$project$Pages$Tasklist$TaskQueue$TaskEditor$GotMakeDropdownMsg}))),
										A4(
										$author$project$Components$Input$textInput,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$width(
												$mdgriffith$elm_ui$Element$fillPortion(1)),
												$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
											]),
										'Malli',
										$author$project$Pages$Tasklist$TaskQueue$TaskEditor$VehicleModelUpdated,
										fields.oY)
									]))
							]))
					])));
	});
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$view_editableFields = F2(
	function (appContext, model) {
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Border$rounded(0),
					$mdgriffith$elm_ui$Element$spacing(6)
				]),
			_List_fromArray(
				[
					A2(
					$author$project$Styles$card_wrapper,
					true,
					A2(
						$author$project$Pages$Tasklist$TaskQueue$TaskEditor$view_editorItemContainer,
						$author$project$Icons$info,
						$elm$core$List$singleton(
							A4(
								$author$project$Components$Input$textInput,
								_List_fromArray(
									[
										$author$project$Utils$HtmlUtil$id('instructions-field')
									]),
								'Ohjeet',
								$author$project$Pages$Tasklist$TaskQueue$TaskEditor$InstructionsUpdated,
								model.hs.lY)))),
					A2($author$project$Pages$Tasklist$TaskQueue$TaskEditor$vehicle_editor, appContext, model),
					$author$project$Pages$Tasklist$TaskQueue$TaskEditor$date_editor(model.hs.e2),
					A5($author$project$Pages$Tasklist$TaskQueue$TaskEditor$products_editor, model.nC, model.aQ, model.j5, $elm$core$Dict$empty, model.hs.iA),
					A2(
					$author$project$Styles$card_wrapper,
					true,
					A2(
						$author$project$Pages$Tasklist$TaskQueue$TaskEditor$view_editorItemContainer,
						$author$project$Icons$workers,
						$elm$core$List$singleton(
							A2(
								$mdgriffith$elm_ui$Element$map,
								$author$project$Pages$Tasklist$TaskQueue$TaskEditor$GotSubModuleMsg_WorkerSelector,
								A2(
									$author$project$Components$WorkerSelector$view,
									model.cx,
									{f3: $author$project$Pages$Tasklist$TaskQueue$TaskEditor$Worker_Deselect, f4: $author$project$Pages$Tasklist$TaskQueue$TaskEditor$Worker_Select}))))),
					A2(
					$author$project$Styles$card_wrapper,
					true,
					A2(
						$author$project$Pages$Tasklist$TaskQueue$TaskEditor$view_editorItemContainer,
						$author$project$Icons$comment,
						$elm$core$List$singleton(
							A3($author$project$Styles$text_input, 'Lisätietoja', $author$project$Pages$Tasklist$TaskQueue$TaskEditor$CommentUpdated, model.hs.kH))))
				]));
	});
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$work_date_dialog = function (model) {
	var hideSubDialogMsg = $author$project$Pages$Tasklist$TaskQueue$TaskEditor$HideSubDialog('open-work-date-dialog-button');
	return A2(
		$author$project$Components$Modal$modal,
		{kz: true, mR: hideSubDialogMsg},
		A2(
			$author$project$Styles$card_wrapper,
			false,
			A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Background$color($author$project$Colors$background),
						$mdgriffith$elm_ui$Element$padding(10),
						$mdgriffith$elm_ui$Element$Border$rounded(8),
						$mdgriffith$elm_ui$Element$spacing(8)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$centerX,
										$mdgriffith$elm_ui$Element$Font$bold,
										$mdgriffith$elm_ui$Element$Font$color($author$project$Colors$onBackground)
									]),
								$mdgriffith$elm_ui$Element$text('Päivämäärä')),
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$alignRight,
										$mdgriffith$elm_ui$Element$Font$color(
										$author$project$Colors$onSurface(5))
									]),
								$author$project$Components$CloseButton$closeButton(hideSubDialogMsg))
							])),
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$centerX]),
						A2(
							$mdgriffith$elm_ui$Element$map,
							$author$project$Pages$Tasklist$TaskQueue$TaskEditor$GotDatePickerMsg,
							$author$project$Components$DatePicker$view(model.bm)))
					]))));
};
var $author$project$Pages$Tasklist$TaskQueue$TaskEditor$view = F3(
	function (appContext, isPendingServerUpdateRequest, model) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$author$project$Styles$largeFontSize,
					$mdgriffith$elm_ui$Element$inFront(
					function () {
						var _v0 = model.dj;
						switch (_v0) {
							case 1:
								return A4(
									$author$project$Components$Vehicles$MakeDialog$vehicleMakeDialog,
									model.am,
									$author$project$Pages$Tasklist$TaskQueue$TaskEditor$CloseVehicleMakeDialog,
									$author$project$Pages$Tasklist$TaskQueue$TaskEditor$HideSubDialog($author$project$Components$Vehicles$MakeSelector$openVehicleMakeDialogButtonId),
									model.hs.fd);
							case 2:
								return $author$project$Pages$Tasklist$TaskQueue$TaskEditor$work_date_dialog(model);
							default:
								return $mdgriffith$elm_ui$Element$none;
						}
					}())
				]),
			A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$htmlAttribute(
						A2($elm$html$Html$Attributes$style, 'max-height', '99vh')),
						$mdgriffith$elm_ui$Element$scrollbarY,
						$mdgriffith$elm_ui$Element$width(
						$mdgriffith$elm_ui$Element$px(670)),
						$mdgriffith$elm_ui$Element$Border$rounded(8),
						$mdgriffith$elm_ui$Element$Background$color($author$project$Colors$background),
						$mdgriffith$elm_ui$Element$Font$color($author$project$Colors$onBackground),
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$centerY,
						$mdgriffith$elm_ui$Element$padding(6)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$paddingEach(
								{g1: 12, h1: 0, iN: 0, jl: 6}),
								$mdgriffith$elm_ui$Element$centerX,
								$mdgriffith$elm_ui$Element$Font$bold
							]),
						$mdgriffith$elm_ui$Element$text('TYÖJONO - MUOKKAA TYÖTÄ')),
						A2($author$project$Pages$Tasklist$TaskQueue$TaskEditor$view_editableFields, appContext, model),
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$paddingEach(
								{g1: 4, h1: 4, iN: 4, jl: 8}),
								$mdgriffith$elm_ui$Element$spacing(8)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$alignLeft]),
								$author$project$Components$Button$toElement(
									A2(
										$author$project$Components$Button$busy,
										isPendingServerUpdateRequest,
										$author$project$Components$Button$warning(
											A2(
												$author$project$Components$Button$text,
												'POISTA',
												A2($author$project$Components$Button$onClick, $author$project$Pages$Tasklist$TaskQueue$TaskEditor$DeleteButtonClicked, $author$project$Components$Button$button)))))),
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$alignRight]),
								$author$project$Components$Button$toElement(
									$author$project$Components$Button$secondary(
										A2(
											$author$project$Components$Button$text,
											'PERUUTA',
											A2($author$project$Components$Button$onClick, $author$project$Pages$Tasklist$TaskQueue$TaskEditor$Cancel, $author$project$Components$Button$button))))),
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$alignRight]),
								$author$project$Components$Button$toElement(
									A2(
										$author$project$Components$Button$busy,
										isPendingServerUpdateRequest,
										$author$project$Components$Button$primary(
											A2(
												$author$project$Components$Button$text,
												'TALLENNA',
												A2($author$project$Components$Button$onClick, $author$project$Pages$Tasklist$TaskQueue$TaskEditor$SaveButtonClicked, $author$project$Components$Button$button))))))
							]))
					])));
	});
var $author$project$Pages$Tasklist$TaskQueue$DecreaseTaskPriorityClicked = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$IncreaseTaskPriorityClicked = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$MouseEnterBelowLastQueuedTask = function (a) {
	return {$: 18, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$MouseEnterQueuedTask = function (a) {
	return {$: 15, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$MouseLeaveQueuedTask = function (a) {
	return {$: 16, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$MouseLeaveTaskQueue = {$: 17};
var $author$project$Pages$Tasklist$TaskQueue$TaskCardClicked = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$ScrollDown = 1;
var $author$project$Pages$Tasklist$TaskQueue$ScrollQueue = function (a) {
	return {$: 13, a: a};
};
var $author$project$Pages$Tasklist$TaskQueue$ScrollUp = 0;
var $author$project$Components$Button$focusable = F2(
	function (value, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{bu: value});
	});
var $author$project$Icons$scrollDown = $author$project$Icons$fa_icon('fas fa-chevron-circle-down');
var $author$project$Icons$scrollUp = $author$project$Icons$fa_icon('fas fa-chevron-circle-up');
var $author$project$Pages$Tasklist$TaskQueue$queueScrollButtons = function (scrollButtons) {
	if (!scrollButtons.$) {
		return $mdgriffith$elm_ui$Element$none;
	} else {
		var scrollPosition = scrollButtons.a;
		var scrollQueueButton = function (scrollDirection) {
			var _v1 = function () {
				if (!scrollDirection) {
					return _Utils_Tuple2($author$project$Icons$scrollUp, $mdgriffith$elm_ui$Element$alignTop);
				} else {
					return _Utils_Tuple2($author$project$Icons$scrollDown, $mdgriffith$elm_ui$Element$alignBottom);
				}
			}();
			var icon = _v1.a;
			var verticalAlignment = _v1.b;
			return A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$height(
						$mdgriffith$elm_ui$Element$px(48)),
						$mdgriffith$elm_ui$Element$Background$color(
						A4($mdgriffith$elm_ui$Element$rgba, 0, 0, 0, 0.3)),
						verticalAlignment,
						$mdgriffith$elm_ui$Element$htmlAttribute(
						A2($elm$html$Html$Attributes$style, 'pointer-events', 'auto'))
					]),
				$author$project$Components$Button$toElement(
					A2(
						$author$project$Components$Button$focusable,
						false,
						A2(
							$author$project$Components$Button$width,
							$mdgriffith$elm_ui$Element$fill,
							A2(
								$author$project$Components$Button$onClick,
								$author$project$Pages$Tasklist$TaskQueue$ScrollQueue(scrollDirection),
								A2(
									$author$project$Components$Button$iconColor,
									A4($mdgriffith$elm_ui$Element$rgba, 255, 255, 255, 0.5),
									A2(
										$author$project$Components$Button$iconSize,
										32,
										A2($author$project$Components$Button$icon, icon, $author$project$Components$Button$button))))))));
		};
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$htmlAttribute(
					A2($elm$html$Html$Attributes$style, 'pointer-events', 'none'))
				]),
			_List_fromArray(
				[
					(scrollPosition > 0) ? scrollQueueButton(0) : $mdgriffith$elm_ui$Element$none,
					scrollQueueButton(1)
				]));
	}
};
var $author$project$Pages$Tasklist$TaskQueue$QueuedTaskCardList$isDragInProcess = function (dragStatus) {
	return !_Utils_eq(dragStatus, $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskDragStatus$NotDragging);
};
var $elm_community$list_extra$List$Extra$last = function (items) {
	last:
	while (true) {
		if (!items.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (!items.b.b) {
				var x = items.a;
				return $elm$core$Maybe$Just(x);
			} else {
				var rest = items.b;
				var $temp$items = rest;
				items = $temp$items;
				continue last;
			}
		}
	}
};
var $author$project$Pages$Tasklist$TaskQueue$QueuedTaskCardList$dragDummyAfterLastTask = F2(
	function (config, taskQueue) {
		var lastQueuedTask = $elm_community$list_extra$List$Extra$last(
			$author$project$Pages$Tasklist$TaskQueue$Types$TaskQueue$getTasks(taskQueue));
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
					]),
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2(
						$elm$core$Maybe$map,
						function (last) {
							return $author$project$Pages$Tasklist$TaskQueue$QueuedTaskCardList$isDragInProcess(config.O) ? _List_fromArray(
								[
									$mdgriffith$elm_ui$Element$htmlAttribute(
									$mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$onEnter(
										function (_v0) {
											return config.mX(last);
										}))
								]) : _List_Nil;
						},
						lastQueuedTask))),
			$mdgriffith$elm_ui$Element$none);
	});
var $author$project$Pages$Tasklist$TaskQueue$QueuedTaskCardList$emptyQueueNotification = A2(
	$mdgriffith$elm_ui$Element$el,
	_List_fromArray(
		[
			$mdgriffith$elm_ui$Element$centerX,
			$mdgriffith$elm_ui$Element$Font$italic,
			$mdgriffith$elm_ui$Element$Font$color(
			$author$project$Colors$onSurface(3)),
			$mdgriffith$elm_ui$Element$moveRight(5)
		]),
	$mdgriffith$elm_ui$Element$text('ei töitä jonossa'));
var $author$project$Pages$Tasklist$TaskQueue$Types$TaskQueue$isEmpty = function (_v0) {
	var tq = _v0;
	return $elm$core$List$isEmpty(tq.aj);
};
var $author$project$Pages$Tasklist$TaskQueue$QueuedTaskCardList$FirstInQueue = 0;
var $author$project$Pages$Tasklist$TaskQueue$QueuedTaskCardList$LastInQueue = 2;
var $author$project$Pages$Tasklist$TaskQueue$QueuedTaskCardList$MiddleOfQueue = 1;
var $author$project$Components$Drag$calculateDraggedDistance = F2(
	function (currentCoordinates, startCoordinates) {
		return {D: currentCoordinates.D - startCoordinates.D, E: currentCoordinates.E - startCoordinates.E};
	});
var $author$project$Components$Drag$applyDragPosition = function (_v0) {
	var startCoordinates = _v0.e_;
	var currentCoordinates = _v0.b2;
	var cursorOffset = _v0.fw;
	var draggedDistance = A2($author$project$Components$Drag$calculateDraggedDistance, currentCoordinates, startCoordinates);
	var finalCoordinates = {D: (startCoordinates.D + draggedDistance.D) - cursorOffset.D, E: (startCoordinates.E + draggedDistance.E) - cursorOffset.E};
	return A2(
		$elm$core$List$map,
		$mdgriffith$elm_ui$Element$htmlAttribute,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'position', 'fixed'),
				A2(
				$elm$html$Html$Attributes$style,
				'left',
				$elm$core$String$fromFloat(finalCoordinates.D) + 'px'),
				A2(
				$elm$html$Html$Attributes$style,
				'top',
				$elm$core$String$fromFloat(finalCoordinates.E) + 'px'),
				A2($elm$html$Html$Attributes$style, 'z-index', '9999'),
				A2($elm$html$Html$Attributes$style, 'pointer-events', 'none')
			]));
};
var $author$project$Pages$Tasklist$TaskQueue$QueuedTaskCardList$calculateRepositionWhenQueueReordered = F4(
	function (dragDirection, queueRowSpacing, draggedDomElement, indexInQueue) {
		if (dragDirection.$ === 1) {
			var fromIndex = dragDirection.a;
			var toIndex = dragDirection.b;
			return ((_Utils_cmp(toIndex, indexInQueue) < 1) && (_Utils_cmp(indexInQueue, fromIndex) < 0)) ? _List_fromArray(
				[
					$mdgriffith$elm_ui$Element$moveDown(draggedDomElement.en + queueRowSpacing)
				]) : _List_Nil;
		} else {
			var fromIndex = dragDirection.a;
			var toIndex = dragDirection.b;
			return ((_Utils_cmp(fromIndex, indexInQueue) < 0) && (_Utils_cmp(indexInQueue, toIndex) < 1)) ? _List_fromArray(
				[
					$mdgriffith$elm_ui$Element$moveUp(draggedDomElement.en + queueRowSpacing)
				]) : _List_Nil;
		}
	});
var $author$project$Components$Drag$undoDragPosition = function (_v0) {
	var startCoordinates = _v0.e_;
	var currentCoordinates = _v0.b2;
	var draggedDistance = A2($author$project$Components$Drag$calculateDraggedDistance, currentCoordinates, startCoordinates);
	return A2(
		$elm$core$List$map,
		$mdgriffith$elm_ui$Element$htmlAttribute,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'position', 'relative'),
				A2(
				$elm$html$Html$Attributes$style,
				'left',
				$elm$core$String$fromFloat(-draggedDistance.D) + 'px'),
				A2(
				$elm$html$Html$Attributes$style,
				'top',
				$elm$core$String$fromFloat(-draggedDistance.E) + 'px'),
				A2($elm$html$Html$Attributes$style, 'z-index', '9999'),
				A2($elm$html$Html$Attributes$style, 'pointer-events', 'none')
			]));
};
var $author$project$Pages$Tasklist$TaskQueue$QueuedTaskCardList$calculateUndoDragForDateLabel = function (dragStatus) {
	switch (dragStatus.$) {
		case 1:
			var dragInfo = dragStatus.a;
			return $author$project$Components$Drag$undoDragPosition(dragInfo);
		case 3:
			var dragInfo = dragStatus.a;
			return $author$project$Components$Drag$undoDragPosition(dragInfo);
		case 2:
			var dragInfo = dragStatus.a;
			return $author$project$Components$Drag$undoDragPosition(dragInfo);
		case 4:
			var dragInfo = dragStatus.a;
			return $author$project$Components$Drag$undoDragPosition(dragInfo);
		default:
			return _List_Nil;
	}
};
var $author$project$Cursor$Default = 8;
var $justinmimbs$date$Date$compare = F2(
	function (_v0, _v1) {
		var a = _v0;
		var b = _v1;
		return A2($elm$core$Basics$compare, a, b);
	});
var $author$project$Pages$Tasklist$TaskQueue$QueuedTaskCardList$formatDate = function (date) {
	return $elm$core$String$toUpper(
		$author$project$Language$dateToWeekdayFinAbbreviation(date)) + (' ' + A2($justinmimbs$date$Date$format, 'd.M.', date));
};
var $author$project$Pages$Tasklist$TaskQueue$QueuedTaskCardList$dayTitleElem = F3(
	function (taskPosition, maybeThisDate, maybePreviousDate) {
		var isThisDateEarlierThanPreviousOne = function () {
			if (!taskPosition) {
				return false;
			} else {
				var _v0 = _Utils_Tuple2(maybePreviousDate, maybeThisDate);
				if (!_v0.a.$) {
					if (!_v0.b.$) {
						var previousDate = _v0.a.a;
						var thisDate = _v0.b.a;
						return !A2($justinmimbs$date$Date$compare, thisDate, previousDate);
					} else {
						var _v1 = _v0.b;
						return false;
					}
				} else {
					if (!_v0.b.$) {
						var _v2 = _v0.a;
						return true;
					} else {
						var _v3 = _v0.a;
						var _v4 = _v0.b;
						return false;
					}
				}
			}
		}();
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$paddingEach(
						{g1: 0, h1: 0, iN: 0, jl: 2}),
						$author$project$Cursor$cursor(8),
						$mdgriffith$elm_ui$Element$Font$bold
					]),
				isThisDateEarlierThanPreviousOne ? _List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Font$color($author$project$Colors$warning),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						$elm$html$Html$Attributes$title('Tämän työn päivä on aikaisemmin kuin ylläolevan, vaikka tämä työ on alempana jonossa'))
					]) : _List_Nil),
			$mdgriffith$elm_ui$Element$text(
				A2(
					$elm$core$Maybe$withDefault,
					'EI AIKATAULUA',
					A2($elm$core$Maybe$map, $author$project$Pages$Tasklist$TaskQueue$QueuedTaskCardList$formatDate, maybeThisDate))));
	});
var $author$project$Styles$hidden = $mdgriffith$elm_ui$Element$htmlAttribute(
	A2($elm$html$Html$Attributes$style, 'display', 'none'));
var $elm$core$Elm$JsArray$foldl = _JsArray_foldl;
var $elm$core$Elm$JsArray$indexedMap = _JsArray_indexedMap;
var $elm$core$Array$indexedMap = F2(
	function (func, _v0) {
		var len = _v0.a;
		var tree = _v0.c;
		var tail = _v0.d;
		var initialBuilder = {
			B: _List_Nil,
			v: 0,
			y: A3(
				$elm$core$Elm$JsArray$indexedMap,
				func,
				$elm$core$Array$tailIndex(len),
				tail)
		};
		var helper = F2(
			function (node, builder) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldl, helper, builder, subTree);
				} else {
					var leaf = node.a;
					var offset = builder.v * $elm$core$Array$branchFactor;
					var mappedLeaf = $elm$core$Array$Leaf(
						A3($elm$core$Elm$JsArray$indexedMap, func, offset, leaf));
					return {
						B: A2($elm$core$List$cons, mappedLeaf, builder.B),
						v: builder.v + 1,
						y: builder.y
					};
				}
			});
		return A2(
			$elm$core$Array$builderToArray,
			true,
			A3($elm$core$Elm$JsArray$foldl, helper, initialBuilder, tree));
	});
var $author$project$Icons$arrowCircleDown = $author$project$Icons$fa_icon('fas fa-arrow-alt-circle-down');
var $author$project$Icons$arrowCircleUp = $author$project$Icons$fa_icon('fas fa-arrow-alt-circle-up');
var $author$project$Components$Button$tabIndex = F2(
	function (value, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{
				e0: $elm$core$Maybe$Just(value)
			});
	});
var $author$project$Pages$Tasklist$TaskQueue$QueuedTaskCard$changeQueuePositionButtons = F3(
	function (queuedTask, maybeOnIncreasePriorityClick, maybeOnDecreasePriorityClick) {
		var moveUpButton = function () {
			if (!maybeOnIncreasePriorityClick.$) {
				var onIncreasePriority = maybeOnIncreasePriorityClick.a;
				return $author$project$Components$Button$toElement(
					A2(
						$author$project$Components$Button$tabIndex,
						-1,
						$author$project$Components$Button$secondary(
							A2(
								$author$project$Components$Button$iconSize,
								$author$project$Styles$fontSizesInPixels.o7,
								A2(
									$author$project$Components$Button$icon,
									$author$project$Icons$arrowCircleUp,
									A2(
										$author$project$Components$Button$onClick,
										onIncreasePriority(queuedTask),
										$author$project$Components$Button$button))))));
			} else {
				return $mdgriffith$elm_ui$Element$none;
			}
		}();
		var moveDownButton = function () {
			if (!maybeOnDecreasePriorityClick.$) {
				var onDecreasePriority = maybeOnDecreasePriorityClick.a;
				return $author$project$Components$Button$toElement(
					A2(
						$author$project$Components$Button$tabIndex,
						-1,
						$author$project$Components$Button$secondary(
							A2(
								$author$project$Components$Button$iconSize,
								$author$project$Styles$fontSizesInPixels.o7,
								A2(
									$author$project$Components$Button$icon,
									$author$project$Icons$arrowCircleDown,
									A2(
										$author$project$Components$Button$onClick,
										onDecreasePriority(queuedTask),
										$author$project$Components$Button$button))))));
			} else {
				return $mdgriffith$elm_ui$Element$none;
			}
		}();
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$alignRight
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$alignTop]),
					moveUpButton),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$alignBottom]),
					moveDownButton)
				]));
	});
var $author$project$Pages$Tasklist$TaskQueue$QueuedTaskCard$view_cardEntryContainer = F2(
	function (icon, elements) {
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width(
							$mdgriffith$elm_ui$Element$px(28)),
							$mdgriffith$elm_ui$Element$Font$color(
							$author$project$Colors$secondary(2)),
							$mdgriffith$elm_ui$Element$paddingEach(
							{g1: 0, h1: 0, iN: 8, jl: 0})
						]),
					icon),
					A2(
					$mdgriffith$elm_ui$Element$wrappedRow,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$spacing(4)
						]),
					elements)
				]));
	});
var $author$project$Pages$Tasklist$TaskQueue$QueuedTaskCard$view_comment = function (comment) {
	return $elm$core$String$isEmpty(comment) ? $mdgriffith$elm_ui$Element$none : A2(
		$author$project$Pages$Tasklist$TaskQueue$QueuedTaskCard$view_cardEntryContainer,
		$author$project$Icons$comment,
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$paragraph,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$Font$italic, $mdgriffith$elm_ui$Element$alignLeft, $mdgriffith$elm_ui$Element$Font$alignLeft]),
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$text(comment)
					]))
			]));
};
var $author$project$Pages$Tasklist$TaskQueue$QueuedTaskCard$view_instructions = function (instructions) {
	return $elm$core$String$isEmpty(instructions) ? $mdgriffith$elm_ui$Element$none : A2(
		$author$project$Pages$Tasklist$TaskQueue$QueuedTaskCard$view_cardEntryContainer,
		$author$project$Icons$info,
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$paragraph,
				_List_fromArray(
					[$author$project$Styles$xxlFontSize, $mdgriffith$elm_ui$Element$Font$italic, $mdgriffith$elm_ui$Element$alignLeft, $mdgriffith$elm_ui$Element$Font$alignLeft]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$alignLeft]),
						$mdgriffith$elm_ui$Element$text(instructions))
					]))
			]));
};
var $author$project$Types$AvailableProduct$unknownAvailableProduct = {ha: '?', er: 0, mD: 'TUNTEMATON'};
var $author$project$Types$AvailableProduct$getById = F2(
	function (products, productId) {
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$Types$AvailableProduct$unknownAvailableProduct,
			A2(
				$author$project$Helpers$firstWhere,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.er;
					},
					$elm$core$Basics$eq(productId)),
				products));
	});
var $author$project$Components$Badge$lightGrayColors = _List_fromArray(
	[
		$mdgriffith$elm_ui$Element$Background$color(
		$author$project$Colors$surface(5)),
		$mdgriffith$elm_ui$Element$Border$color(
		$author$project$Colors$surface(6)),
		$mdgriffith$elm_ui$Element$Font$color(
		$author$project$Colors$onSurface(9))
	]);
var $author$project$Components$Badge$lightGray = function (contents) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_Utils_ap($author$project$Components$Badge$baseStyles, $author$project$Components$Badge$lightGrayColors),
		contents);
};
var $author$project$Pages$Tasklist$TaskQueue$QueuedTaskCard$product_badge = F2(
	function (productCode, maybePrice) {
		return $author$project$Components$Badge$lightGray(
			A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$spacing(4)
					]),
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$text(productCode),
						A2(
						$elm$core$Maybe$withDefault,
						$mdgriffith$elm_ui$Element$none,
						A2(
							$elm$core$Maybe$map,
							A2(
								$elm$core$Basics$composeR,
								$author$project$Helpers$toEuroString,
								A2(
									$elm$core$Basics$composeR,
									$mdgriffith$elm_ui$Element$text,
									$mdgriffith$elm_ui$Element$el(
										_List_fromArray(
											[
												$author$project$Styles$smallFontSize,
												$mdgriffith$elm_ui$Element$Font$color(
												$author$project$Colors$onSurface(8))
											])))),
							maybePrice))
					])));
	});
var $author$project$Pages$Tasklist$TaskQueue$QueuedTaskCard$view_selectedProducts = F3(
	function (priceInputMode, availableProducts, selectedProducts) {
		return $elm$core$List$isEmpty(selectedProducts) ? $mdgriffith$elm_ui$Element$none : A2(
			$author$project$Pages$Tasklist$TaskQueue$QueuedTaskCard$view_cardEntryContainer,
			$author$project$Icons$shopping_cart,
			A2(
				$elm$core$List$map,
				function (_v5) {
					var productCode = _v5.f9;
					var price = _v5.iv;
					return A2($author$project$Pages$Tasklist$TaskQueue$QueuedTaskCard$product_badge, productCode, price);
				},
				A2(
					$elm$core$List$sortBy,
					function ($) {
						return $.f9;
					},
					A2(
						$elm$core$List$map,
						function (selectedProduct) {
							var visiblePrice = function () {
								var _v0 = _Utils_Tuple2(priceInputMode, selectedProduct.iv);
								if (_v0.a === 1) {
									var _v1 = _v0.a;
									var maybeVatlessPrice = _v0.b;
									return maybeVatlessPrice;
								} else {
									if (!_v0.b.$) {
										var _v2 = _v0.a;
										var vatlessPrice = _v0.b.a;
										return $elm$core$Maybe$Just(
											$author$project$Helpers$roundCurrency(vatlessPrice * 1.255));
									} else {
										var _v3 = _v0.a;
										var _v4 = _v0.b;
										return $elm$core$Maybe$Nothing;
									}
								}
							}();
							var availableProduct = A2($author$project$Types$AvailableProduct$getById, availableProducts, selectedProduct.iz);
							return {iv: visiblePrice, f9: availableProduct.ha};
						},
						selectedProducts))));
	});
var $author$project$Components$Badge$primaryColors = _List_fromArray(
	[
		$mdgriffith$elm_ui$Element$Background$color(
		$author$project$Colors$primary(1)),
		$mdgriffith$elm_ui$Element$Border$color(
		$author$project$Colors$primary(2)),
		$mdgriffith$elm_ui$Element$Font$color(
		$author$project$Colors$onPrimary(9))
	]);
var $author$project$Components$Badge$primary = function (contents) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_Utils_ap($author$project$Components$Badge$baseStyles, $author$project$Components$Badge$primaryColors),
		contents);
};
var $author$project$Pages$Tasklist$TaskQueue$QueuedTaskCard$worker_badge = function (worker) {
	return $author$project$Components$Badge$primary(
		$mdgriffith$elm_ui$Element$text(worker.ha));
};
var $author$project$Pages$Tasklist$TaskQueue$QueuedTaskCard$view_selectedWorkers = function (workers) {
	return $elm$core$List$isEmpty(workers) ? $mdgriffith$elm_ui$Element$none : A2(
		$author$project$Pages$Tasklist$TaskQueue$QueuedTaskCard$view_cardEntryContainer,
		$author$project$Icons$workers,
		A2($elm$core$List$map, $author$project$Pages$Tasklist$TaskQueue$QueuedTaskCard$worker_badge, workers));
};
var $author$project$Pages$Tasklist$TaskQueue$QueuedTaskCard$view_totalPrice = F2(
	function (priceInputMode, products) {
		var total = A2($author$project$Types$SelectedProduct$productsTotal, priceInputMode, products);
		return (!total) ? $mdgriffith$elm_ui$Element$none : A2(
			$author$project$Pages$Tasklist$TaskQueue$QueuedTaskCard$view_cardEntryContainer,
			$author$project$Icons$euro_symbol,
			$elm$core$List$singleton(
				$mdgriffith$elm_ui$Element$text(
					$author$project$Helpers$toEuroString(total))));
	});
var $author$project$Pages$Tasklist$TaskQueue$QueuedTaskCard$view_vehicle = function (_v0) {
	var licenseNumber = _v0.de;
	var make = _v0.mi;
	var model = _v0.mv;
	return A2(
		$author$project$Pages$Tasklist$TaskQueue$QueuedTaskCard$view_cardEntryContainer,
		$author$project$Icons$car,
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$wrappedRow,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$spacing(6)
					]),
				_List_fromArray(
					[
						$author$project$Types$Vehicle$LicenseNumber$isEmpty(licenseNumber) ? $mdgriffith$elm_ui$Element$none : A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$author$project$Styles$xxlFontSize, $mdgriffith$elm_ui$Element$Font$bold]),
						$mdgriffith$elm_ui$Element$text(
							$author$project$Types$Vehicle$LicenseNumber$toString(licenseNumber))),
						$mdgriffith$elm_ui$Element$text(make),
						$mdgriffith$elm_ui$Element$text(model)
					]))
			]));
};
var $author$project$Pages$Tasklist$TaskQueue$QueuedTaskCard$view = F8(
	function (priceInputMode, allWorkers, availableProducts, maybeOnClick, maybeOnIncreasePriorityClick, maybeOnDecreasePriorityClick, queuedTask, isBeingDragged) {
		var overlayStyles = _Utils_ap(
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$transparent(true),
					$author$project$Cursor$cursor(24),
					$mdgriffith$elm_ui$Element$mouseOver(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$transparent(false)
						])),
					$mdgriffith$elm_ui$Element$htmlAttribute(
					A2($elm$html$Html$Attributes$style, 'user-drag', 'none')),
					$mdgriffith$elm_ui$Element$htmlAttribute(
					A2($elm$html$Html$Attributes$style, 'user-select', 'none')),
					$mdgriffith$elm_ui$Element$htmlAttribute(
					A2($elm$html$Html$Attributes$style, '-moz-user-drag', 'none')),
					$mdgriffith$elm_ui$Element$htmlAttribute(
					A2($elm$html$Html$Attributes$style, '-moz-user-select', 'none')),
					$mdgriffith$elm_ui$Element$htmlAttribute(
					A2($elm$html$Html$Attributes$style, '-webkit-user-drag', 'none')),
					$mdgriffith$elm_ui$Element$htmlAttribute(
					A2($elm$html$Html$Attributes$style, '-webkit-user-select', 'none')),
					$mdgriffith$elm_ui$Element$htmlAttribute(
					A2($elm$html$Html$Attributes$style, '-ms-user-drag', 'none')),
					$mdgriffith$elm_ui$Element$htmlAttribute(
					A2($elm$html$Html$Attributes$style, '-ms-user-select', 'none'))
				]),
			_Utils_ap(
				function () {
					if (!maybeOnClick.$) {
						var onClick_ = maybeOnClick.a;
						return _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Events$onClick(
								onClick_(queuedTask))
							]);
					} else {
						return _List_Nil;
					}
				}(),
				isBeingDragged ? _List_fromArray(
					[
						$mdgriffith$elm_ui$Element$htmlAttribute(
						A2($elm$html$Html$Attributes$style, 'pointer-events', 'none'))
					]) : _List_Nil));
		var cardStyles = _List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$height(
				A2($mdgriffith$elm_ui$Element$minimum, 64, $mdgriffith$elm_ui$Element$fill)),
				A2($mdgriffith$elm_ui$Element$paddingXY, 4, 6),
				$mdgriffith$elm_ui$Element$Background$color(
				$author$project$Colors$surface(3)),
				$mdgriffith$elm_ui$Element$Border$rounded(8),
				$mdgriffith$elm_ui$Element$Border$shadow(
				$author$project$Styles$cardShadow(3)),
				$mdgriffith$elm_ui$Element$Font$color(
				$author$project$Colors$onSurface(7)),
				$mdgriffith$elm_ui$Element$spacing(4)
			]);
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width(
					$mdgriffith$elm_ui$Element$px(210)),
					$mdgriffith$elm_ui$Element$inFront(
					A2(
						$mdgriffith$elm_ui$Element$el,
						overlayStyles,
						A3($author$project$Pages$Tasklist$TaskQueue$QueuedTaskCard$changeQueuePositionButtons, queuedTask, maybeOnIncreasePriorityClick, maybeOnDecreasePriorityClick)))
				]),
			A2(
				$mdgriffith$elm_ui$Element$column,
				cardStyles,
				_List_fromArray(
					[
						$author$project$Pages$Tasklist$TaskQueue$QueuedTaskCard$view_vehicle(
						$author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskFields$extractVehicle(queuedTask.hs)),
						$author$project$Pages$Tasklist$TaskQueue$QueuedTaskCard$view_instructions(queuedTask.hs.lY),
						A2($author$project$Pages$Tasklist$TaskQueue$QueuedTaskCard$view_totalPrice, priceInputMode, queuedTask.hs.iA),
						A3($author$project$Pages$Tasklist$TaskQueue$QueuedTaskCard$view_selectedProducts, priceInputMode, availableProducts, queuedTask.hs.iA),
						$author$project$Pages$Tasklist$TaskQueue$QueuedTaskCard$view_selectedWorkers(
						A3(
							$author$project$Helpers$pickFromList,
							allWorkers,
							function ($) {
								return $.er;
							},
							queuedTask.hs.jz)),
						$author$project$Pages$Tasklist$TaskQueue$QueuedTaskCard$view_comment(queuedTask.hs.kH)
					])));
	});
var $author$project$Pages$Tasklist$TaskQueue$QueuedTaskCardList$queuedTaskElem = F4(
	function (_v0, queuedTask, isBeingDragged, taskPosition) {
		var areUpdateButtonsDisabled = _v0.j1;
		var availableProducts = _v0.j5;
		var dragStatus = _v0.O;
		var onTaskCardClick = _v0.m8;
		var onIncreaseTaskPriorityClick = _v0.mV;
		var onDecreaseTaskPriorityClick = _v0.mT;
		var user = _v0.gA;
		var allWorkers = _v0.bU;
		var priceInputMode = $author$project$Types$User$priceInputMode(user);
		var allowEdits = $author$project$Types$User$canEditTaskQueues(user) && ((!areUpdateButtonsDisabled) && _Utils_eq(dragStatus, $author$project$Pages$Tasklist$TaskQueue$Types$QueuedTaskDragStatus$NotDragging));
		var onClick = allowEdits ? $elm$core$Maybe$Just(onTaskCardClick) : $elm$core$Maybe$Nothing;
		var onDecreasePriorityClick = (allowEdits && (taskPosition !== 2)) ? $elm$core$Maybe$Just(onDecreaseTaskPriorityClick) : $elm$core$Maybe$Nothing;
		var onIncreasePriorityClick = (allowEdits && (!(!taskPosition))) ? $elm$core$Maybe$Just(onIncreaseTaskPriorityClick) : $elm$core$Maybe$Nothing;
		return A8($author$project$Pages$Tasklist$TaskQueue$QueuedTaskCard$view, priceInputMode, allWorkers, availableProducts, onClick, onIncreasePriorityClick, onDecreasePriorityClick, queuedTask, isBeingDragged);
	});
var $author$project$Components$Drag$renderPlaceholder = function (draggedDomElement) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$height(
				$mdgriffith$elm_ui$Element$px(
					$elm$core$Basics$round(draggedDomElement.en))),
				$mdgriffith$elm_ui$Element$width(
				$mdgriffith$elm_ui$Element$px(
					$elm$core$Basics$round(draggedDomElement.fg)))
			]),
		$mdgriffith$elm_ui$Element$none);
};
var $author$project$Colors$brightYellow = A3($mdgriffith$elm_ui$Element$rgb255, 255, 255, 0);
var $author$project$Icons$warningTriangle = $author$project$Icons$fa_icon('fas fa-exclamation-triangle');
var $author$project$Components$QueueSkipNotice$toElement = A2(
	$mdgriffith$elm_ui$Element$el,
	_List_fromArray(
		[
			$mdgriffith$elm_ui$Element$Font$size(20),
			$mdgriffith$elm_ui$Element$Font$color($author$project$Colors$brightYellow),
			$mdgriffith$elm_ui$Element$padding(2),
			$author$project$Components$Tooltip$tooltip('Tämä ei ollut ensimmäinen vapaa työ jonossa'),
			$author$project$Cursor$cursor(8)
		]),
	$author$project$Icons$warningTriangle);
var $author$project$Pages$Tasklist$TaskQueue$QueuedTaskCardList$queueRows = F3(
	function (config, taskQueue, indexOfFirstVisibleTask) {
		var tasksArray = $elm$core$Array$fromList(
			$author$project$Pages$Tasklist$TaskQueue$Types$TaskQueue$getTasks(taskQueue));
		var rowSpacing = 10;
		var isQueueBeingReordered = function () {
			var _v10 = config.O;
			switch (_v10.$) {
				case 2:
					var dragInfo = _v10.a;
					var targetQueuedTask = _v10.b;
					return !_Utils_eq(dragInfo.fE.er, targetQueuedTask.er);
				case 4:
					var dragInfo = _v10.a;
					var targetQueuedTask = _v10.b;
					return !_Utils_eq(dragInfo.fE.er, targetQueuedTask.er);
				default:
					return false;
			}
		}();
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$spacing(rowSpacing)
				]),
			$elm$core$Array$toList(
				A2(
					$elm$core$Array$indexedMap,
					F2(
						function (thisIndex, thisQueuedTask) {
							var sequenceNumber = thisIndex + 1;
							var sequenceNumberElem = A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width(
										$mdgriffith$elm_ui$Element$px(26)),
										$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
										$author$project$Styles$xxlFontSize,
										$mdgriffith$elm_ui$Element$Font$bold
									]),
								A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
									$mdgriffith$elm_ui$Element$text(
										$author$project$Helpers$orderNumberString(sequenceNumber))));
							var repositionWhenQueueReordered = function () {
								var _v9 = config.O;
								switch (_v9.$) {
									case 0:
										return _List_Nil;
									case 1:
										return _List_Nil;
									case 3:
										return _List_Nil;
									case 2:
										var dragInfo = _v9.a;
										var dragDirection = _v9.c;
										return A4($author$project$Pages$Tasklist$TaskQueue$QueuedTaskCardList$calculateRepositionWhenQueueReordered, dragDirection, rowSpacing, dragInfo.hk, thisIndex);
									default:
										var dragInfo = _v9.a;
										var dragDirection = _v9.c;
										return A4($author$project$Pages$Tasklist$TaskQueue$QueuedTaskCardList$calculateRepositionWhenQueueReordered, dragDirection, rowSpacing, dragInfo.hk, thisIndex);
								}
							}();
							var isHidden = _Utils_cmp(thisIndex, indexOfFirstVisibleTask) < 0;
							var isFirstVisibleTask = _Utils_eq(thisIndex, indexOfFirstVisibleTask);
							var taskPosition = isFirstVisibleTask ? 0 : (_Utils_eq(
								sequenceNumber,
								$author$project$Pages$Tasklist$TaskQueue$Types$TaskQueue$length(taskQueue)) ? 2 : 1);
							var taskBeforeThisOne = (!taskPosition) ? $elm$core$Maybe$Nothing : A2($elm$core$Array$get, thisIndex - 1, tasksArray);
							var showDateLabel = A2(
								$elm$core$Maybe$withDefault,
								true,
								A2(
									$elm$core$Maybe$map,
									A2(
										$elm$core$Basics$composeR,
										function ($) {
											return $.hs;
										},
										A2(
											$elm$core$Basics$composeR,
											function ($) {
												return $.e2;
											},
											$elm$core$Basics$neq(thisQueuedTask.hs.e2))),
									taskBeforeThisOne));
							var hideIfScrolledPast = $author$project$Helpers$attributeIf(
								_List_fromArray(
									[
										_Utils_Tuple2(isHidden, $author$project$Styles$hidden)
									]));
							var dragBeaconAttribute = $mdgriffith$elm_ui$Element$htmlAttribute(
								A2(
									$elm$html$Html$Attributes$attribute,
									'data-drag-beacon',
									'queued-task-' + $elm$core$String$fromInt(
										$author$project$Pages$Tasklist$TaskQueue$Types$QueuedTask$unwrapQueuedTaskId(thisQueuedTask.er))));
							var dateLabel = A3(
								$author$project$Pages$Tasklist$TaskQueue$QueuedTaskCardList$dayTitleElem,
								taskPosition,
								thisQueuedTask.hs.e2,
								A2(
									$elm$core$Maybe$andThen,
									A2(
										$elm$core$Basics$composeR,
										function ($) {
											return $.hs;
										},
										function ($) {
											return $.e2;
										}),
									taskBeforeThisOne));
							var _v0 = function () {
								var _v1 = config.O;
								switch (_v1.$) {
									case 0:
										return _Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing);
									case 1:
										var dragInfo = _v1.a;
										return _Utils_Tuple2(
											$elm$core$Maybe$Just(dragInfo.fE),
											$elm$core$Maybe$Just(dragInfo.hk));
									case 3:
										var dragInfo = _v1.a;
										return _Utils_Tuple2(
											$elm$core$Maybe$Just(dragInfo.fE),
											$elm$core$Maybe$Just(dragInfo.hk));
									case 2:
										var dragInfo = _v1.a;
										return _Utils_Tuple2(
											$elm$core$Maybe$Just(dragInfo.fE),
											$elm$core$Maybe$Just(dragInfo.hk));
									default:
										var dragInfo = _v1.a;
										return _Utils_Tuple2(
											$elm$core$Maybe$Just(dragInfo.fE),
											$elm$core$Maybe$Just(dragInfo.hk));
								}
							}();
							var draggedQueuedTask = _v0.a;
							var draggedDomElement = _v0.b;
							var isThisTaskDragged = A2(
								$elm$core$Maybe$withDefault,
								false,
								A2(
									$elm$core$Maybe$map,
									A2(
										$elm$core$Basics$composeR,
										function ($) {
											return $.er;
										},
										$elm$core$Basics$eq(thisQueuedTask.er)),
									draggedQueuedTask));
							var repositionWhenDragged = function () {
								var _v5 = _Utils_Tuple2(isThisTaskDragged, config.O);
								if (_v5.a) {
									switch (_v5.b.$) {
										case 1:
											var dragInfo = _v5.b.a;
											return $author$project$Components$Drag$applyDragPosition(dragInfo);
										case 3:
											var dragInfo = _v5.b.a;
											return $author$project$Components$Drag$applyDragPosition(dragInfo);
										case 2:
											var _v6 = _v5.b;
											var dragInfo = _v6.a;
											return $author$project$Components$Drag$applyDragPosition(dragInfo);
										case 4:
											var _v7 = _v5.b;
											var dragInfo = _v7.a;
											return $author$project$Components$Drag$applyDragPosition(dragInfo);
										default:
											var _v8 = _v5.b;
											return _List_Nil;
									}
								} else {
									return _List_Nil;
								}
							}();
							var showQueueSkipNotice = isThisTaskDragged && A3($author$project$Components$QueueSkipNotice$checkIfNeeded, config.gA, taskQueue, thisQueuedTask);
							return A2(
								$mdgriffith$elm_ui$Element$column,
								_Utils_ap(
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
											dragBeaconAttribute
										]),
									hideIfScrolledPast),
								_List_fromArray(
									[
										function () {
										var _v2 = _Utils_Tuple2(isThisTaskDragged, draggedDomElement);
										if (_v2.a && (!_v2.b.$)) {
											var domElement = _v2.b.a;
											return $author$project$Components$Drag$renderPlaceholder(domElement);
										} else {
											return $mdgriffith$elm_ui$Element$none;
										}
									}(),
										A2(
										$mdgriffith$elm_ui$Element$column,
										A2(
											$elm$core$List$cons,
											$mdgriffith$elm_ui$Element$spacing(4),
											_Utils_ap(
												repositionWhenDragged,
												_Utils_ap(
													repositionWhenQueueReordered,
													$author$project$Pages$Tasklist$TaskQueue$QueuedTaskCardList$isDragInProcess(config.O) ? _List_fromArray(
														[
															$mdgriffith$elm_ui$Element$htmlAttribute(
															$mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$onEnter(
																function (_v3) {
																	return config.mY(thisQueuedTask);
																})),
															$mdgriffith$elm_ui$Element$htmlAttribute(
															$mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$onLeave(
																function (_v4) {
																	return config.mZ(thisQueuedTask);
																}))
														]) : _List_Nil))),
										_List_fromArray(
											[
												showDateLabel ? A2(
												$mdgriffith$elm_ui$Element$el,
												A2(
													$elm$core$List$cons,
													$mdgriffith$elm_ui$Element$centerX,
													(isThisTaskDragged && (!isQueueBeingReordered)) ? $author$project$Pages$Tasklist$TaskQueue$QueuedTaskCardList$calculateUndoDragForDateLabel(config.O) : _List_Nil),
												dateLabel) : $mdgriffith$elm_ui$Element$none,
												A2(
												$mdgriffith$elm_ui$Element$row,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
														$mdgriffith$elm_ui$Element$inFront(
														showQueueSkipNotice ? A2(
															$mdgriffith$elm_ui$Element$el,
															_List_fromArray(
																[
																	$mdgriffith$elm_ui$Element$alignRight,
																	$mdgriffith$elm_ui$Element$padding(4)
																]),
															$author$project$Components$QueueSkipNotice$toElement) : $mdgriffith$elm_ui$Element$none)
													]),
												_List_fromArray(
													[
														sequenceNumberElem,
														A4($author$project$Pages$Tasklist$TaskQueue$QueuedTaskCardList$queuedTaskElem, config, thisQueuedTask, isThisTaskDragged, taskPosition)
													]))
											]))
									]));
						}),
					tasksArray)));
	});
var $author$project$Pages$Tasklist$TaskQueue$QueuedTaskCardList$view = F3(
	function (config, taskQueue, scrollPosition) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$width(
						$mdgriffith$elm_ui$Element$px(250)),
						$author$project$Utils$HtmlUtil$id($author$project$Pages$Tasklist$TaskQueue$QueuedTaskCardList$domId)
					]),
				$author$project$Pages$Tasklist$TaskQueue$QueuedTaskCardList$isDragInProcess(config.O) ? _List_fromArray(
					[
						$mdgriffith$elm_ui$Element$htmlAttribute(
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$onLeave(
							function (_v0) {
								return config.m_;
							}))
					]) : _List_Nil),
			$author$project$Pages$Tasklist$TaskQueue$Types$TaskQueue$isEmpty(taskQueue) ? $author$project$Pages$Tasklist$TaskQueue$QueuedTaskCardList$emptyQueueNotification : A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
					]),
				_List_fromArray(
					[
						A3($author$project$Pages$Tasklist$TaskQueue$QueuedTaskCardList$queueRows, config, taskQueue, scrollPosition),
						A2($author$project$Pages$Tasklist$TaskQueue$QueuedTaskCardList$dragDummyAfterLastTask, config, taskQueue)
					])));
	});
var $author$project$Pages$Tasklist$TaskQueue$viewQueuedTasksList = F3(
	function (appContext, model, areAllUpdateButtonsDisabled) {
		var touchScreenAttributes = appContext.gy ? _List_fromArray(
			[
				$mdgriffith$elm_ui$Element$htmlAttribute(
				A2($elm$html$Html$Attributes$style, 'overflow-y', 'hidden')),
				$mdgriffith$elm_ui$Element$inFront(
				$author$project$Pages$Tasklist$TaskQueue$queueScrollButtons(model.be))
			]) : _List_Nil;
		var listScrollPosition = function () {
			var _v0 = model.be;
			if (!_v0.$) {
				return 0;
			} else {
				var scrollPosition = _v0.a;
				return scrollPosition;
			}
		}();
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
						$author$project$Utils$HtmlUtil$id('queued-task-cards-list-container'),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						A2($elm$html$Html$Attributes$style, 'overflow-x', 'hidden'))
					]),
				touchScreenAttributes),
			A3(
				$author$project$Pages$Tasklist$TaskQueue$QueuedTaskCardList$view,
				{
					bU: model.k_.bU,
					j1: areAllUpdateButtonsDisabled,
					j5: model.k_.ct,
					a$: model.a$,
					O: model.O,
					mT: $author$project$Pages$Tasklist$TaskQueue$DecreaseTaskPriorityClicked,
					mV: $author$project$Pages$Tasklist$TaskQueue$IncreaseTaskPriorityClicked,
					mX: $author$project$Pages$Tasklist$TaskQueue$MouseEnterBelowLastQueuedTask,
					mY: function (queuedTask) {
						return $author$project$Pages$Tasklist$TaskQueue$MouseEnterQueuedTask(queuedTask);
					},
					mZ: function (queuedTask) {
						return $author$project$Pages$Tasklist$TaskQueue$MouseLeaveQueuedTask(queuedTask);
					},
					m_: $author$project$Pages$Tasklist$TaskQueue$MouseLeaveTaskQueue,
					m8: $author$project$Pages$Tasklist$TaskQueue$TaskCardClicked,
					gA: model.gA
				},
				model.k_.z,
				listScrollPosition));
	});
var $author$project$Pages$Tasklist$TaskQueue$ShowTaskCreator = {$: 0};
var $mdgriffith$elm_ui$Element$Font$extraBold = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$fontWeight, $mdgriffith$elm_ui$Internal$Style$classes.or);
var $author$project$Icons$plusCircle = $author$project$Icons$fa_icon('fas fa-plus-circle');
var $author$project$Pages$Tasklist$TaskQueue$view_header = function (model) {
	var taskCreatorExpandButton = A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$alignRight,
				$mdgriffith$elm_ui$Element$transparent(
				function () {
					var _v0 = model.ai;
					if (!_v0.$) {
						return true;
					} else {
						return false;
					}
				}())
			]),
		$author$project$Components$Button$toElement(
			$author$project$Components$Button$primary(
				A2(
					$author$project$Components$Button$iconSize,
					24,
					A2(
						$author$project$Components$Button$icon,
						$author$project$Icons$plusCircle,
						A2($author$project$Components$Button$onClick, $author$project$Pages$Tasklist$TaskQueue$ShowTaskCreator, $author$project$Components$Button$button))))));
	var taskCount = $author$project$Pages$Tasklist$TaskQueue$Types$TaskQueue$length(model.k_.z);
	return A2(
		$mdgriffith$elm_ui$Element$row,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$spacing(2)
			]),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$moveRight(20),
						$mdgriffith$elm_ui$Element$spacing(2)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$centerX, $author$project$Styles$xlFontSize, $mdgriffith$elm_ui$Element$Font$extraBold]),
						$mdgriffith$elm_ui$Element$text('TYÖJONO ')),
						$author$project$Components$Badge$gray(
						$mdgriffith$elm_ui$Element$text(
							$elm$core$String$fromInt(taskCount)))
					])),
				$author$project$Types$User$canEditTaskQueues(model.gA) ? taskCreatorExpandButton : $mdgriffith$elm_ui$Element$none
			]));
};
var $author$project$Pages$Tasklist$TaskQueue$view_Loaded = F3(
	function (areParentUpdateButtonsDisabled, appContext, model) {
		var areAllUpdateButtonsDisabled = areParentUpdateButtonsDisabled || model.t;
		var openDialog = function () {
			var _v1 = model.dj;
			if (!_v1.$) {
				return $mdgriffith$elm_ui$Element$none;
			} else {
				var editorModel = _v1.b;
				return A2(
					$author$project$Components$Modal$modal,
					{kz: false, mR: $author$project$Pages$Tasklist$TaskQueue$HideEditorDialog},
					A2(
						$mdgriffith$elm_ui$Element$map,
						$author$project$Pages$Tasklist$TaskQueue$GotEditorMsg,
						A3($author$project$Pages$Tasklist$TaskQueue$TaskEditor$view, appContext, areAllUpdateButtonsDisabled, editorModel)));
			}
		}();
		return $author$project$Components$Box$toElement(
			A2(
				$author$project$Components$Box$height,
				$mdgriffith$elm_ui$Element$fill,
				$author$project$Components$Box$box(
					A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$Font$color(
								$author$project$Colors$onSurface(7)),
								$mdgriffith$elm_ui$Element$spacing(8),
								$mdgriffith$elm_ui$Element$inFront(openDialog)
							]),
						_List_fromArray(
							[
								$author$project$Pages$Tasklist$TaskQueue$view_header(model),
								function () {
								var _v0 = model.ai;
								if (!_v0.$) {
									var taskCreatorModel = _v0.a;
									return A2(
										$mdgriffith$elm_ui$Element$map,
										$author$project$Pages$Tasklist$TaskQueue$GotTaskCreatorMsg,
										A3($author$project$Pages$Tasklist$TaskQueue$TaskCreator$TaskCreator$view, areAllUpdateButtonsDisabled, appContext, taskCreatorModel));
								} else {
									return $mdgriffith$elm_ui$Element$none;
								}
							}(),
								A3($author$project$Pages$Tasklist$TaskQueue$viewQueuedTasksList, appContext, model, areAllUpdateButtonsDisabled)
							])))));
	});
var $author$project$Pages$Tasklist$TaskQueue$view_Loading = F2(
	function (_v0, _v1) {
		return $mdgriffith$elm_ui$Element$none;
	});
var $author$project$Pages$Tasklist$TaskQueue$view = F3(
	function (areParentUpdateButtonsDisabled, appContext, model) {
		var _v0 = model._;
		switch (_v0.$) {
			case 0:
				var loadingModel = _v0.a;
				return A2(
					$mdgriffith$elm_ui$Element$map,
					$author$project$Pages$Tasklist$TaskQueue$Msg_Loading,
					A2($author$project$Pages$Tasklist$TaskQueue$view_Loading, appContext, loadingModel));
			case 1:
				var loadedModel = _v0.a;
				return A2(
					$mdgriffith$elm_ui$Element$map,
					$author$project$Pages$Tasklist$TaskQueue$Msg_Loaded,
					A3($author$project$Pages$Tasklist$TaskQueue$view_Loaded, areParentUpdateButtonsDisabled, appContext, loadedModel));
			default:
				var erroredModel = _v0.a;
				return A2(
					$mdgriffith$elm_ui$Element$map,
					$author$project$Pages$Tasklist$TaskQueue$Msg_Errored,
					A2($author$project$Pages$Tasklist$TaskQueue$view_Errored, appContext, erroredModel));
		}
	});
var $author$project$Pages$Tasklist$CloseTasklistButtonClicked = F2(
	function (a, b) {
		return {$: 16, a: a, b: b};
	});
var $author$project$Pages$Tasklist$MouseEnterTasklist = function (a) {
	return {$: 24, a: a};
};
var $author$project$Pages$Tasklist$MouseLeaveTasklist = function (a) {
	return {$: 25, a: a};
};
var $author$project$Pages$Tasklist$NewTaskButtonClicked = function (a) {
	return {$: 14, a: a};
};
var $author$project$Components$Drag$NoDropZoneElement = {$: 0};
var $author$project$Pages$Tasklist$OneTimeCustomerContactInfoUpdated = F2(
	function (a, b) {
		return {$: 11, a: a, b: b};
	});
var $author$project$Pages$Tasklist$OneTimeCustomerNameUpdated = F2(
	function (a, b) {
		return {$: 10, a: a, b: b};
	});
var $author$project$Pages$Tasklist$StoreOneTimeCustomerContactInfo = function (a) {
	return {$: 13, a: a};
};
var $author$project$Pages$Tasklist$StoreOneTimeCustomerName = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Tasklist$TaskCardClicked = F2(
	function (a, b) {
		return {$: 15, a: a, b: b};
	});
var $author$project$Pages$Tasklist$ToggleWorkerOperationAcknowledgement = F2(
	function (a, b) {
		return {$: 21, a: a, b: b};
	});
var $author$project$Types$User$canCloseTasklist = function (user) {
	switch (user.$) {
		case 0:
			return true;
		case 1:
			return true;
		case 2:
			return true;
		default:
			var siteUser = user.a;
			switch (siteUser.$) {
				case 2:
					return true;
				case 0:
					return true;
				default:
					return false;
			}
	}
};
var $author$project$Types$User$canSeeTasklistTotalPrice = function (user) {
	switch (user.$) {
		case 0:
			return true;
		case 1:
			return true;
		case 2:
			return true;
		default:
			var siteUser = user.a;
			switch (siteUser.$) {
				case 2:
					return true;
				case 0:
					return true;
				default:
					return false;
			}
	}
};
var $author$project$Types$User$canSeeWorkerOperationNotices = function (user) {
	switch (user.$) {
		case 0:
			return true;
		case 2:
			return true;
		case 1:
			return true;
		default:
			var siteUser = user.a;
			switch (siteUser.$) {
				case 0:
					return true;
				case 2:
					return false;
				default:
					return false;
			}
	}
};
var $author$project$Components$Drag$DropZoneElement = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Drag$renderDropZoneElement = function (draggedDomElement) {
	return $author$project$Components$Drag$DropZoneElement(
		A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px(
						$elm$core$Basics$round(draggedDomElement.en)))
				]),
			$mdgriffith$elm_ui$Element$none));
};
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$defaultOptions = {iu: true, ja: false};
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$onEnd = A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$onWithOptions, 'touchend', $mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$defaultOptions);
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$defaultOptions = {iu: true, ja: false};
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onUp = A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onWithOptions, 'mouseup', $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$defaultOptions);
var $mdgriffith$elm_ui$Element$spacingXY = F2(
	function (x, y) {
		return A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$spacing,
			A3(
				$mdgriffith$elm_ui$Internal$Model$SpacingStyle,
				A2($mdgriffith$elm_ui$Internal$Model$spacingName, x, y),
				x,
				y));
	});
var $author$project$Pages$Tasklist$TasklistCard$v_tasklist_action_buttons_row = F3(
	function (isPendingServerRequest, config, tasklist) {
		var _v0 = config;
		var canCloseTasklist = _v0.km;
		var onCloseTasklistButtonClicked = _v0.mS;
		var onNewTaskButtonClicked = _v0.m$;
		var closeTasklistButton = A2(
			$mdgriffith$elm_ui$Element$el,
			isPendingServerRequest ? _List_Nil : _List_fromArray(
				[
					$mdgriffith$elm_ui$Element$htmlAttribute(
					$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onUp(
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.l9;
							},
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.kS;
								},
								onCloseTasklistButtonClicked(tasklist))))),
					$mdgriffith$elm_ui$Element$htmlAttribute(
					$mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$onEnd(
						function (_v1) {
							return A2(onCloseTasklistButtonClicked, tasklist, false);
						}))
				]),
			$author$project$Components$Button$toElement(
				A2(
					$author$project$Components$Button$busy,
					isPendingServerRequest,
					$author$project$Components$Button$secondary(
						A2(
							$author$project$Components$Button$textSize,
							$author$project$Styles$fontSizesInPixels.ml,
							A2($author$project$Components$Button$text, 'SULJE LOMAKE', $author$project$Components$Button$button))))));
		var newTaskButton = $author$project$Components$Button$toElement(
			A2(
				$author$project$Components$Button$busy,
				isPendingServerRequest,
				$author$project$Components$Button$primary(
					A2(
						$author$project$Components$Button$textSize,
						$author$project$Styles$fontSizesInPixels.mc,
						A2(
							$author$project$Components$Button$text,
							'UUSI TYÖ',
							A2(
								$author$project$Components$Button$onClick,
								onNewTaskButtonClicked(tasklist),
								$author$project$Components$Button$button))))));
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$paddingEach(
					{g1: 10, h1: 0, iN: 0, jl: 0}),
					$mdgriffith$elm_ui$Element$centerX,
					A2($mdgriffith$elm_ui$Element$spacingXY, 5, 0)
				]),
			_List_fromArray(
				[
					newTaskButton,
					canCloseTasklist ? closeTasklistButton : $mdgriffith$elm_ui$Element$none
				]));
	});
var $mdgriffith$elm_ui$Element$Input$multiline = F2(
	function (attrs, multi) {
		return A3(
			$mdgriffith$elm_ui$Element$Input$textHelper,
			{at: $elm$core$Maybe$Nothing, aD: multi.ob, gz: $mdgriffith$elm_ui$Element$Input$TextArea},
			attrs,
			{ma: multi.ma, mK: multi.mK, nt: multi.nt, bJ: multi.bJ});
	});
var $author$project$Components$Input$multilineInput = F4(
	function (customAttributes, labelText, onChange, valueText) {
		return A2(
			$mdgriffith$elm_ui$Element$Input$multiline,
			_Utils_ap($author$project$Components$Input$defaultAttributes, customAttributes),
			{
				ma: $mdgriffith$elm_ui$Element$Input$labelHidden(labelText),
				mK: function (value) {
					return onChange(value);
				},
				nt: $author$project$Components$Input$placeholder(labelText),
				ob: false,
				bJ: valueText
			});
	});
var $author$project$Pages$Tasklist$TasklistCard$v_tasklist_header = F2(
	function (config, tasklist) {
		var inputDefaultBorderStyles = _List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Border$width(2),
				$mdgriffith$elm_ui$Element$Border$rounded(4),
				$mdgriffith$elm_ui$Element$Border$color($author$project$Colors$none)
			]);
		var _v0 = config;
		var customer = _v0.kW;
		var onOneTimeCustomerContactInfoUpdated = _v0.m0;
		var onOneTimeCustomerNameUpdated = _v0.m1;
		var onStoreOneTimeCustomerContactInfo = _v0.m5;
		var onStoreOneTimeCustomerName = _v0.m6;
		var priceInputMode = _v0.nC;
		var secondaryPriceMode = $author$project$Types$User$secondaryPriceInputMode(priceInputMode);
		var vatIndicator = function (maybePrice) {
			var vatPercentString = $elm$core$String$fromFloat(
				$author$project$Types$User$priceInputModeVatPct(secondaryPriceMode));
			var totalPriceString = A2(
				$elm$core$Maybe$withDefault,
				'',
				A2($elm$core$Maybe$map, $author$project$Helpers$toEuroString, maybePrice));
			return A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$alignRight,
						$mdgriffith$elm_ui$Element$alignBottom,
						$author$project$Styles$smallFontSize,
						$mdgriffith$elm_ui$Element$Font$color(
						$author$project$Colors$onSurface(4)),
						$mdgriffith$elm_ui$Element$spacing(2)
					]),
				A2(
					$elm$core$List$map,
					$mdgriffith$elm_ui$Element$el(
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$alignRight])),
					A2(
						$elm$core$List$map,
						$mdgriffith$elm_ui$Element$text,
						_List_fromArray(
							[totalPriceString, ' ALV ' + vatPercentString]))));
		};
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$paddingEach(
					{g1: 4, h1: 10, iN: 10, jl: 10})
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$spacing(4)
						]),
					_List_fromArray(
						[
							(!_Utils_eq(customer.mD, tasklist.mD)) ? A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$Font$italic]),
							$elm$core$List$singleton(
								A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$alignLeft]),
									$mdgriffith$elm_ui$Element$text(customer.mD)))) : $mdgriffith$elm_ui$Element$none,
							A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_fromArray(
								[$author$project$Styles$largeFontSize, $mdgriffith$elm_ui$Element$Font$bold]),
							$elm$core$List$singleton(
								A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$alignLeft]),
									$mdgriffith$elm_ui$Element$text(tasklist.mD)))),
							function () {
							var _v1 = customer.kY;
							if (_v1 === 1) {
								return A2(
									$mdgriffith$elm_ui$Element$el,
									A2(
										$elm$core$List$cons,
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
										_Utils_ap(
											inputDefaultBorderStyles,
											$elm$core$String$isEmpty(tasklist.nf) ? _List_fromArray(
												[
													$mdgriffith$elm_ui$Element$Border$color($author$project$Colors$warning)
												]) : _List_Nil)),
									A4(
										$author$project$Components$Input$textInput,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$Events$onLoseFocus(
												onStoreOneTimeCustomerName(tasklist))
											]),
										'Nimi',
										onOneTimeCustomerNameUpdated(tasklist),
										tasklist.nf));
							} else {
								return $mdgriffith$elm_ui$Element$none;
							}
						}(),
							function () {
							var _v2 = customer.kY;
							if (_v2 === 1) {
								return A2(
									$mdgriffith$elm_ui$Element$el,
									A2(
										$elm$core$List$cons,
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
										inputDefaultBorderStyles),
									A4(
										$author$project$Components$Input$multilineInput,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$Events$onLoseFocus(
												onStoreOneTimeCustomerContactInfo(tasklist))
											]),
										'Yhteystiedot',
										onOneTimeCustomerContactInfoUpdated(tasklist),
										tasklist.ne));
							} else {
								return $mdgriffith$elm_ui$Element$none;
							}
						}(),
							((customer.kY === 1) && $elm$core$String$isEmpty(tasklist.nf)) ? $mdgriffith$elm_ui$Element$none : A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$spacing(4),
									$mdgriffith$elm_ui$Element$centerY,
									$mdgriffith$elm_ui$Element$Font$color(
									$author$project$Colors$onSurface(4))
								]),
							_List_fromArray(
								[
									$author$project$Icons$car,
									$mdgriffith$elm_ui$Element$text(
									$elm$core$String$fromInt(
										$elm$core$List$length(tasklist.aj)))
								]))
						])),
					A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$spacing(4)
						]),
					_Utils_ap(
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$alignRight,
										$author$project$Styles$smallFontSize,
										$mdgriffith$elm_ui$Element$Font$color(
										$author$project$Colors$onSurface(4))
									]),
								$mdgriffith$elm_ui$Element$text(
									'#' + $elm$core$String$fromInt(tasklist.er)))
							]),
						config.ko ? _List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$author$project$Styles$largeFontSize, $mdgriffith$elm_ui$Element$alignRight]),
								$mdgriffith$elm_ui$Element$text(
									$author$project$Helpers$toEuroString(
										A2($author$project$Types$Tasklist$tasksTotal, priceInputMode, tasklist.aj)))),
								vatIndicator(
								$elm$core$Maybe$Just(
									A2($author$project$Types$Tasklist$tasksTotal, secondaryPriceMode, tasklist.aj)))
							]) : _List_fromArray(
							[
								vatIndicator($elm$core$Maybe$Nothing)
							])))
				]));
	});
var $elm$core$List$partition = F2(
	function (pred, list) {
		var step = F2(
			function (x, _v0) {
				var trues = _v0.a;
				var falses = _v0.b;
				return pred(x) ? _Utils_Tuple2(
					A2($elm$core$List$cons, x, trues),
					falses) : _Utils_Tuple2(
					trues,
					A2($elm$core$List$cons, x, falses));
			});
		return A3(
			$elm$core$List$foldr,
			step,
			_Utils_Tuple2(_List_Nil, _List_Nil),
			list);
	});
var $elm_community$list_extra$List$Extra$gatherWith = F2(
	function (testFn, list) {
		var helper = F2(
			function (scattered, gathered) {
				if (!scattered.b) {
					return $elm$core$List$reverse(gathered);
				} else {
					var toGather = scattered.a;
					var population = scattered.b;
					var _v1 = A2(
						$elm$core$List$partition,
						testFn(toGather),
						population);
					var gathering = _v1.a;
					var remaining = _v1.b;
					return A2(
						helper,
						remaining,
						A2(
							$elm$core$List$cons,
							_Utils_Tuple2(toGather, gathering),
							gathered));
				}
			});
		return A2(helper, list, _List_Nil);
	});
var $elm_community$list_extra$List$Extra$gatherEqualsBy = F2(
	function (extract, list) {
		return A2(
			$elm_community$list_extra$List$Extra$gatherWith,
			F2(
				function (a, b) {
					return _Utils_eq(
						extract(a),
						extract(b));
				}),
			list);
	});
var $author$project$Pages$Tasklist$TaskCard$view_taskCardIcon = function (icon) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width(
				$mdgriffith$elm_ui$Element$px(28)),
				$mdgriffith$elm_ui$Element$paddingEach(
				{g1: 0, h1: 0, iN: 8, jl: 0})
			]),
		A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[$mdgriffith$elm_ui$Element$centerX]),
			icon));
};
var $author$project$Pages$Tasklist$TaskCard$view_taskCardItemContainer = F3(
	function (icon, isValid, elements) {
		var iconColor = isValid ? $author$project$Colors$success : $author$project$Colors$accent;
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$color(iconColor)
						]),
					$author$project$Pages$Tasklist$TaskCard$view_taskCardIcon(icon)),
					A2(
					$mdgriffith$elm_ui$Element$wrappedRow,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$spacing(4)
						]),
					elements)
				]));
	});
var $author$project$Pages$Tasklist$TaskCard$view_comment = function (comment) {
	return A3(
		$author$project$Pages$Tasklist$TaskCard$view_taskCardItemContainer,
		$author$project$Icons$comment,
		true,
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$paragraph,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$Font$italic, $mdgriffith$elm_ui$Element$alignLeft, $mdgriffith$elm_ui$Element$Font$alignLeft]),
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$text(comment)
					]))
			]));
};
var $author$project$Pages$Tasklist$TaskCard$selected_price_to_string = function (maybePrice) {
	if (!maybePrice.$) {
		var price = maybePrice.a;
		return $author$project$Helpers$toEuroString(price);
	} else {
		return '? €';
	}
};
var $author$project$Colors$onWarning = A3($mdgriffith$elm_ui$Element$rgb, 0.9, 0.9, 0.9);
var $author$project$Components$Badge$warningColors = _List_fromArray(
	[
		$mdgriffith$elm_ui$Element$Background$color($author$project$Colors$warning),
		$mdgriffith$elm_ui$Element$Border$color($author$project$Colors$warning),
		$mdgriffith$elm_ui$Element$Font$color($author$project$Colors$onWarning)
	]);
var $author$project$Components$Badge$warning = function (contents) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_Utils_ap($author$project$Components$Badge$baseStyles, $author$project$Components$Badge$warningColors),
		contents);
};
var $author$project$Pages$Tasklist$TaskCard$product_badge = F3(
	function (productCode, maybePrice, isValid) {
		var productCodeElem = $mdgriffith$elm_ui$Element$text(productCode);
		var priceElem = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$author$project$Styles$smallFontSize,
					$mdgriffith$elm_ui$Element$Font$color(
					$author$project$Colors$onSurface(5))
				]),
			$mdgriffith$elm_ui$Element$text(
				$author$project$Pages$Tasklist$TaskCard$selected_price_to_string(maybePrice)));
		return (isValid ? $author$project$Components$Badge$gray : $author$project$Components$Badge$warning)(
			A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$spacing(4)
					]),
				_List_fromArray(
					[productCodeElem, priceElem])));
	});
var $author$project$Pages$Tasklist$TaskCard$view_products = F3(
	function (priceInputMode, availableProducts, selectedProducts) {
		return A3(
			$author$project$Pages$Tasklist$TaskCard$view_taskCardItemContainer,
			$author$project$Icons$shopping_cart,
			$author$project$Types$TasklistTask$hasValidProducts(selectedProducts),
			A2(
				$elm$core$List$map,
				function (_v5) {
					var productCode = _v5.f9;
					var price = _v5.iv;
					var isValid = _v5.hY;
					return A3($author$project$Pages$Tasklist$TaskCard$product_badge, productCode, price, isValid);
				},
				A2(
					$elm$core$List$sortBy,
					function ($) {
						return $.f9;
					},
					A2(
						$elm$core$List$map,
						function (selectedProduct) {
							var visiblePrice = function () {
								var _v0 = _Utils_Tuple2(priceInputMode, selectedProduct.iv);
								if (_v0.a === 1) {
									var _v1 = _v0.a;
									var maybeVatlessPrice = _v0.b;
									return maybeVatlessPrice;
								} else {
									if (!_v0.b.$) {
										var _v2 = _v0.a;
										var vatlessPrice = _v0.b.a;
										return $elm$core$Maybe$Just(
											$author$project$Helpers$roundCurrency(vatlessPrice * 1.255));
									} else {
										var _v3 = _v0.a;
										var _v4 = _v0.b;
										return $elm$core$Maybe$Nothing;
									}
								}
							}();
							var availableProduct = A2($author$project$Types$AvailableProduct$getById, availableProducts, selectedProduct.iz);
							return {
								hY: $author$project$Types$TasklistTask$isValidProduct(selectedProduct),
								iv: visiblePrice,
								f9: availableProduct.ha
							};
						},
						selectedProducts))));
	});
var $author$project$Pages$Tasklist$TaskCard$view_totalPrice = F2(
	function (priceInputMode, products) {
		return A3(
			$author$project$Pages$Tasklist$TaskCard$view_taskCardItemContainer,
			$author$project$Icons$euro_symbol,
			true,
			$elm$core$List$singleton(
				A2(
					$mdgriffith$elm_ui$Element$el,
					_List_Nil,
					$mdgriffith$elm_ui$Element$text(
						$author$project$Helpers$toEuroString(
							A2($author$project$Types$SelectedProduct$productsTotal, priceInputMode, products))))));
	});
var $author$project$Types$Vehicle$LicenseNumber$isInStandardFormat = function (licenseNumber) {
	if (!licenseNumber.$) {
		var string = licenseNumber.a;
		return function (chars) {
			if (((((((chars.b && chars.b.b) && chars.b.b.b) && chars.b.b.b.b) && chars.b.b.b.b.b) && chars.b.b.b.b.b.b) && chars.b.b.b.b.b.b.b) && (!chars.b.b.b.b.b.b.b.b)) {
				var c1 = chars.a;
				var _v2 = chars.b;
				var c2 = _v2.a;
				var _v3 = _v2.b;
				var c3 = _v3.a;
				var _v4 = _v3.b;
				var c4 = _v4.a;
				var _v5 = _v4.b;
				var c5 = _v5.a;
				var _v6 = _v5.b;
				var c6 = _v6.a;
				var _v7 = _v6.b;
				var c7 = _v7.a;
				return $elm$core$Char$isAlpha(c1) && ($elm$core$Char$isAlpha(c2) && ($elm$core$Char$isAlpha(c3) && ((c4 === '-') && (($elm$core$Char$isDigit(c5) && (c5 !== '0')) && ($elm$core$Char$isDigit(c6) && $elm$core$Char$isDigit(c7))))));
			} else {
				return false;
			}
		}(
			$elm$core$String$toList(string));
	} else {
		return false;
	}
};
var $author$project$Pages$Tasklist$TaskCard$view_licenseNumber = function (_v0) {
	var licenseNumber = _v0.de;
	var make = _v0.mi;
	var notifyAboutNonStandardFormat = function (licNo) {
		return $author$project$Types$Vehicle$LicenseNumber$isInStandardFormat(licNo) ? $mdgriffith$elm_ui$Element$el(_List_Nil) : $mdgriffith$elm_ui$Element$el(
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Font$color($author$project$Colors$brightYellow),
					$author$project$Components$Tooltip$tooltip('Epätavallinen rekisterinumero')
				]));
	};
	return ($author$project$Types$Vehicle$LicenseNumber$isEmpty(licenseNumber) && (make !== '')) ? A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Font$color($author$project$Colors$accent)
			]),
		$mdgriffith$elm_ui$Element$text('REKNO')) : A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[$author$project$Styles$xxlFontSize, $mdgriffith$elm_ui$Element$Font$bold]),
		A2(
			notifyAboutNonStandardFormat,
			licenseNumber,
			$mdgriffith$elm_ui$Element$text(
				$author$project$Types$Vehicle$LicenseNumber$toString(licenseNumber))));
};
var $author$project$Pages$Tasklist$TaskCard$view_vehicle = function (vehicle) {
	return A3(
		$author$project$Pages$Tasklist$TaskCard$view_taskCardItemContainer,
		$author$project$Icons$car,
		$author$project$Types$TasklistTask$hasValidVehicle(vehicle),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$wrappedRow,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$spacing(6)
					]),
				_List_fromArray(
					[
						$author$project$Pages$Tasklist$TaskCard$view_licenseNumber(vehicle),
						$mdgriffith$elm_ui$Element$text(vehicle.mi),
						$mdgriffith$elm_ui$Element$text(vehicle.mv)
					]))
			]));
};
var $author$project$Pages$Tasklist$TaskCard$worker_badge = function (worker) {
	return $author$project$Components$Badge$gray(
		$mdgriffith$elm_ui$Element$text(worker.ha));
};
var $author$project$Pages$Tasklist$TaskCard$view_workers = function (workers) {
	return A3(
		$author$project$Pages$Tasklist$TaskCard$view_taskCardItemContainer,
		$author$project$Icons$workers,
		$author$project$Types$TasklistTask$hasValidWorkers(
			A2(
				$elm$core$List$map,
				function ($) {
					return $.er;
				},
				workers)),
		A2($elm$core$List$map, $author$project$Pages$Tasklist$TaskCard$worker_badge, workers));
};
var $author$project$Pages$Tasklist$TaskCard$view = F6(
	function (isPendingServerRequest, priceInputMode, allWorkers, taskAvailableProducts, onClick, task) {
		var surfaceLevel = 0;
		var isValidTask = $author$project$Types$TasklistTask$isValid(task);
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$paddingEach(
					{g1: 0, h1: 5, iN: 5, jl: 0})
				]),
			A2(
				$mdgriffith$elm_ui$Element$column,
				_Utils_ap(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$padding(4),
							$mdgriffith$elm_ui$Element$Background$color(
							$author$project$Colors$surface(surfaceLevel)),
							$mdgriffith$elm_ui$Element$Border$rounded(8),
							$mdgriffith$elm_ui$Element$Border$shadow(
							$author$project$Styles$cardShadow(surfaceLevel)),
							$mdgriffith$elm_ui$Element$Border$widthEach(
							{g1: 0, h1: 6, iN: 0, jl: 0}),
							$mdgriffith$elm_ui$Element$Border$color(
							isValidTask ? $author$project$Colors$success : $author$project$Colors$accent),
							$mdgriffith$elm_ui$Element$Font$color(
							$author$project$Colors$onSurface(7)),
							$author$project$Cursor$cursor(24),
							$mdgriffith$elm_ui$Element$spacing(4)
						]),
					$author$project$Helpers$attributeIf(
						_List_fromArray(
							[
								_Utils_Tuple2(
								isPendingServerRequest,
								$author$project$Cursor$cursor(25)),
								_Utils_Tuple2(
								!isPendingServerRequest,
								$mdgriffith$elm_ui$Element$Events$onClick(
									onClick(task)))
							]))),
				_List_fromArray(
					[
						$author$project$Pages$Tasklist$TaskCard$view_vehicle(task.oX),
						A2($author$project$Pages$Tasklist$TaskCard$view_totalPrice, priceInputMode, task.iA),
						A3($author$project$Pages$Tasklist$TaskCard$view_products, priceInputMode, taskAvailableProducts, task.iA),
						$author$project$Pages$Tasklist$TaskCard$view_workers(
						A3(
							$author$project$Helpers$pickFromList,
							allWorkers,
							function ($) {
								return $.er;
							},
							task.jz)),
						(task.kH !== '') ? $author$project$Pages$Tasklist$TaskCard$view_comment(task.kH) : $mdgriffith$elm_ui$Element$none
					])));
	});
var $author$project$Icons$workerCreatedNotice = $author$project$Icons$fa_icon('fas fa-user-plus');
var $author$project$Icons$workerEditedNotice = $author$project$Icons$fa_icon('fas fa-user-edit');
var $author$project$Pages$Tasklist$TasklistCard$view_tasksForDay = F5(
	function (isPendingServerRequest, config, maybeDate, tasklist, tasksForDay) {
		var renderWorkerOperationNotice = function (task) {
			return A2(
				$elm$core$Maybe$withDefault,
				$mdgriffith$elm_ui$Element$none,
				A2(
					$elm$core$Maybe$map,
					function (_v1) {
						var reason = _v1.nF;
						var isAcknowledged = _v1.l$;
						var tooltip = function () {
							var _v3 = _Utils_Tuple2(reason, isAcknowledged);
							if (!_v3.a) {
								if (!_v3.b) {
									var _v4 = _v3.a;
									return 'Työntekijän luoma työ (klikkaa kuitataksesi)';
								} else {
									var _v5 = _v3.a;
									return 'Työntekijän luoma työ (kuitattu)';
								}
							} else {
								if (!_v3.b) {
									var _v6 = _v3.a;
									return 'Työntekijän muokkaama työ (klikkaa kuitataksesi)';
								} else {
									var _v7 = _v3.a;
									return 'Työntekijän muokkaama työ (kuitattu)';
								}
							}
						}();
						var iconColor = isAcknowledged ? $author$project$Colors$brightGreen : $author$project$Colors$brightYellow;
						var icon = function () {
							if (!reason) {
								return $author$project$Icons$workerCreatedNotice;
							} else {
								return $author$project$Icons$workerEditedNotice;
							}
						}();
						return $author$project$Components$Button$toElement(
							A2(
								$author$project$Components$Button$tooltip,
								tooltip,
								A2(
									$author$project$Components$Button$setCustomAttributes,
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Element$paddingXY, 0, 3)
										]),
									A2(
										$author$project$Components$Button$iconSize,
										18,
										A2(
											$author$project$Components$Button$iconColor,
											iconColor,
											A2(
												$author$project$Components$Button$icon,
												icon,
												A2(
													$author$project$Components$Button$onClick,
													config.nb(task),
													$author$project$Components$Button$button)))))));
					},
					task.o4));
		};
		var formatDate = function (date) {
			return $elm$core$String$toUpper(
				$author$project$Language$dateToWeekdayFinAbbreviation(date)) + (' ' + A2($justinmimbs$date$Date$format, 'd.M.', date));
		};
		var dayTitle = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$centerX,
					$mdgriffith$elm_ui$Element$Font$color(
					$author$project$Colors$onSurface(5))
				]),
			$mdgriffith$elm_ui$Element$text(
				A2(
					$elm$core$Maybe$withDefault,
					'EI PÄIVÄMÄÄRÄÄ',
					A2($elm$core$Maybe$map, formatDate, maybeDate))));
		var _v0 = config;
		var priceInputMode = _v0.nC;
		var onTaskCardClicked = _v0.m9;
		var allAvailableProducts = _v0.T;
		var workers = _v0.jA;
		var renderTaskCard = function (task) {
			var workerOperationNotice = config.kp ? renderWorkerOperationNotice(task) : $mdgriffith$elm_ui$Element$none;
			var taskCard = A6(
				$author$project$Pages$Tasklist$TaskCard$view,
				isPendingServerRequest,
				priceInputMode,
				workers,
				allAvailableProducts,
				onTaskCardClicked(tasklist),
				task);
			var queueSkipNotice = task.lg ? A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$padding(1)
					]),
				$author$project$Components$QueueSkipNotice$toElement) : $mdgriffith$elm_ui$Element$none;
			return A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$inFront(
						A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$alignRight,
									A2($mdgriffith$elm_ui$Element$paddingXY, 8, 4),
									$mdgriffith$elm_ui$Element$spacing(4)
								]),
							_List_fromArray(
								[workerOperationNotice, queueSkipNotice])))
					]),
				taskCard);
		};
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$spacing(4)
				]),
			_List_fromArray(
				[
					dayTitle,
					A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$spacing(8)
						]),
					A2(
						$elm$core$List$map,
						renderTaskCard,
						$elm$core$List$reverse(
							A2(
								$elm$core$List$sortBy,
								function ($) {
									return $.er;
								},
								tasksForDay))))
				]));
	});
var $author$project$Pages$Tasklist$TasklistCard$v_tasks = F3(
	function (isPendingServerRequest, config, tasklist) {
		var maybeDateToSortString = A2(
			$elm$core$Basics$composeR,
			$elm$core$Maybe$map($justinmimbs$date$Date$toIsoString),
			$elm$core$Maybe$withDefault('0'));
		var tasksByDay = A2(
			$elm$core$List$map,
			function (_v1) {
				var firstTaskForDay = _v1.a;
				var rest = _v1.b;
				return _Utils_Tuple2(
					firstTaskForDay.b4,
					A2($elm$core$List$cons, firstTaskForDay, rest));
			},
			A2(
				$elm_community$list_extra$List$Extra$gatherEqualsBy,
				function ($) {
					return $.b4;
				},
				A2(
					$elm$core$List$sortBy,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.b4;
						},
						maybeDateToSortString),
					tasklist.aj)));
		return $elm$core$List$isEmpty(tasklist.aj) ? $mdgriffith$elm_ui$Element$none : A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$paddingEach(
					{g1: 8, h1: 0, iN: 0, jl: 4}),
					$mdgriffith$elm_ui$Element$spacing(12)
				]),
			A2(
				$elm$core$List$map,
				function (_v0) {
					var maybeDate = _v0.a;
					var tasksForDay = _v0.b;
					return A5($author$project$Pages$Tasklist$TasklistCard$view_tasksForDay, isPendingServerRequest, config, maybeDate, tasklist, tasksForDay);
				},
				$elm$core$List$reverse(
					A2(
						$elm$core$List$sortBy,
						A2($elm$core$Basics$composeR, $elm$core$Tuple$first, maybeDateToSortString),
						tasksByDay))));
	});
var $author$project$Pages$Tasklist$TasklistCard$view = F4(
	function (isPendingServerRequest, config, tasklist, dropZoneElement) {
		var surfaceLevel = 0;
		var _v0 = config;
		var customer = _v0.kW;
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width(
					A2(
						$mdgriffith$elm_ui$Element$maximum,
						240,
						A2($mdgriffith$elm_ui$Element$minimum, 240, $mdgriffith$elm_ui$Element$fill))),
					$mdgriffith$elm_ui$Element$alignTop,
					A2($mdgriffith$elm_ui$Element$paddingXY, 4, 0),
					$mdgriffith$elm_ui$Element$Border$rounded(8),
					$mdgriffith$elm_ui$Element$Border$shadow(
					$author$project$Styles$cardShadow(surfaceLevel)),
					$mdgriffith$elm_ui$Element$Background$color($author$project$Colors$background),
					$mdgriffith$elm_ui$Element$Font$color(
					A3($mdgriffith$elm_ui$Element$rgb255, 255, 255, 255))
				]),
			_List_fromArray(
				[
					A2($author$project$Pages$Tasklist$TasklistCard$v_tasklist_header, config, tasklist),
					((customer.kY === 1) && $elm$core$String$isEmpty(tasklist.nf)) ? $mdgriffith$elm_ui$Element$none : A3($author$project$Pages$Tasklist$TasklistCard$v_tasklist_action_buttons_row, isPendingServerRequest, config, tasklist),
					function () {
					if (!dropZoneElement.$) {
						return $mdgriffith$elm_ui$Element$none;
					} else {
						var dropZone = dropZoneElement.a;
						return dropZone;
					}
				}(),
					A3($author$project$Pages$Tasklist$TasklistCard$v_tasks, isPendingServerRequest, config, tasklist)
				]));
	});
var $author$project$Pages$Tasklist$view_tasklistCard = F2(
	function (model, tasklist) {
		var user = $author$project$Types$User$toUser(model.Y);
		var renderTasklistCard = A3(
			$author$project$Pages$Tasklist$TasklistCard$view,
			$author$project$Pages$Tasklist$areUpdateButtonsDisabled(model),
			{
				T: model.T,
				km: $author$project$Types$User$canCloseTasklist(user),
				ko: $author$project$Types$User$canSeeTasklistTotalPrice(user),
				kp: $author$project$Types$User$canSeeWorkerOperationNotices(user),
				kW: A2($author$project$Types$Customer$getCustomer, model.X, tasklist.kX),
				mS: $author$project$Pages$Tasklist$CloseTasklistButtonClicked,
				m$: $author$project$Pages$Tasklist$NewTaskButtonClicked,
				m0: $author$project$Pages$Tasklist$OneTimeCustomerContactInfoUpdated,
				m1: $author$project$Pages$Tasklist$OneTimeCustomerNameUpdated,
				m5: $author$project$Pages$Tasklist$StoreOneTimeCustomerContactInfo,
				m6: $author$project$Pages$Tasklist$StoreOneTimeCustomerName,
				m9: $author$project$Pages$Tasklist$TaskCardClicked,
				nb: $author$project$Pages$Tasklist$ToggleWorkerOperationAcknowledgement(tasklist),
				nC: $author$project$Types$User$priceInputMode(model.Y),
				jA: model.bU
			},
			tasklist);
		var tasklist_card_with_drag_watchers = function (dropZoneElement) {
			return A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$onEnter(
							function (_v1) {
								return $author$project$Pages$Tasklist$MouseEnterTasklist(tasklist);
							})),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$onLeave(
							function (_v2) {
								return $author$project$Pages$Tasklist$MouseLeaveTasklist(tasklist);
							}))
					]),
				renderTasklistCard(dropZoneElement));
		};
		var tasklist_card_without_drag_watchers = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
				]),
			renderTasklistCard($author$project$Components$Drag$NoDropZoneElement));
		var _v0 = model.O;
		if (!_v0.$) {
			return tasklist_card_without_drag_watchers;
		} else {
			var draggedDomElement = _v0.c;
			var maybeHoveredTasklist = _v0.d;
			var isThisTasklistHovered = A2(
				$elm$core$Maybe$withDefault,
				false,
				A2(
					$elm$core$Maybe$map,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.er;
						},
						$elm$core$Basics$eq(tasklist.er)),
					maybeHoveredTasklist));
			var dropZoneElement = isThisTasklistHovered ? $author$project$Components$Drag$renderDropZoneElement(draggedDomElement) : $author$project$Components$Drag$NoDropZoneElement;
			return tasklist_card_with_drag_watchers(dropZoneElement);
		}
	});
var $author$project$Pages$Tasklist$v_tasklistBoard = F3(
	function (appContext, model, tasklists) {
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					A2($mdgriffith$elm_ui$Element$paddingXY, 4, 8),
					$mdgriffith$elm_ui$Element$spacing(8),
					$mdgriffith$elm_ui$Element$clip
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$map,
					$author$project$Pages$Tasklist$GotTaskQueueMsg,
					A3(
						$author$project$Pages$Tasklist$TaskQueue$view,
						$author$project$Pages$Tasklist$areUpdateButtonsDisabled(model),
						appContext,
						model.N)),
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$scrollbars,
							$mdgriffith$elm_ui$Element$Background$uncropped('UusiLogo.svg'),
							$mdgriffith$elm_ui$Element$spacing(10)
						]),
					A2(
						$elm$core$List$map,
						$author$project$Pages$Tasklist$view_tasklistCard(model),
						tasklists))
				]));
	});
var $author$project$Pages$Tasklist$CloseTasklist = function (a) {
	return {$: 17, a: a};
};
var $author$project$Pages$Tasklist$HideTasklistConfirmationDialog = {$: 19};
var $mdgriffith$elm_ui$Internal$Model$AsTextColumn = 5;
var $mdgriffith$elm_ui$Internal$Model$asTextColumn = 5;
var $mdgriffith$elm_ui$Element$textColumn = F2(
	function (attrs, children) {
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asTextColumn,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Element$width(
					A2(
						$mdgriffith$elm_ui$Element$maximum,
						750,
						A2($mdgriffith$elm_ui$Element$minimum, 500, $mdgriffith$elm_ui$Element$fill))),
				attrs),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
	});
var $author$project$Pages$Tasklist$view_closeTasklistConfirmationDialog = function (tasklist) {
	var buttonText = function (buttonLabel) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Font$color(
					$author$project$Colors$secondary(5))
				]),
			$mdgriffith$elm_ui$Element$text(buttonLabel));
	};
	return A2(
		$author$project$Components$Modal$modal,
		{kz: true, mR: $author$project$Pages$Tasklist$HideTasklistConfirmationDialog},
		A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$author$project$Styles$largeFontSize
				]),
			A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$htmlAttribute(
						A2($elm$html$Html$Attributes$style, 'max-height', '99vh')),
						$mdgriffith$elm_ui$Element$scrollbarY,
						$mdgriffith$elm_ui$Element$width(
						$mdgriffith$elm_ui$Element$px(400)),
						$mdgriffith$elm_ui$Element$Border$rounded(8),
						$mdgriffith$elm_ui$Element$Background$color($author$project$Colors$background),
						$mdgriffith$elm_ui$Element$Font$color($author$project$Colors$onBackground),
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$centerY,
						$mdgriffith$elm_ui$Element$padding(6)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$paddingEach(
								{g1: 12, h1: 0, iN: 0, jl: 6}),
								$mdgriffith$elm_ui$Element$centerX,
								$mdgriffith$elm_ui$Element$Font$bold
							]),
						$mdgriffith$elm_ui$Element$text(
							'SULJE LOMAKE #' + ($elm$core$String$fromInt(tasklist.er) + '?'))),
						A2(
						$mdgriffith$elm_ui$Element$textColumn,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$author$project$Styles$mediumFontSize,
								$mdgriffith$elm_ui$Element$Font$alignLeft,
								$mdgriffith$elm_ui$Element$Font$color(
								$author$project$Colors$onSurface(7)),
								$mdgriffith$elm_ui$Element$padding(8),
								$mdgriffith$elm_ui$Element$spacing(4)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$paragraph,
								_List_Nil,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$text('Voit ohittaa tämän varmistuksen seuraavin tavoin:')
									])),
								A2(
								$mdgriffith$elm_ui$Element$paragraph,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[$mdgriffith$elm_ui$Element$Font$bold]),
										$mdgriffith$elm_ui$Element$text('A) ')),
										$mdgriffith$elm_ui$Element$text('tuplaklikkaamalla '),
										buttonText('SULJE LOMAKE'),
										$mdgriffith$elm_ui$Element$text('-nappia')
									])),
								A2(
								$mdgriffith$elm_ui$Element$paragraph,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[$mdgriffith$elm_ui$Element$Font$bold]),
										$mdgriffith$elm_ui$Element$text('B) ')),
										$mdgriffith$elm_ui$Element$text('pitämällä '),
										buttonText('CONTROL'),
										$mdgriffith$elm_ui$Element$text('-näppäintä pohjassa samalla kun painat '),
										buttonText('SULJE LOMAKE'),
										$mdgriffith$elm_ui$Element$text('-nappia')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$paddingEach(
								{g1: 4, h1: 4, iN: 4, jl: 8}),
								$mdgriffith$elm_ui$Element$alignRight,
								$mdgriffith$elm_ui$Element$spacing(8)
							]),
						_List_fromArray(
							[
								$author$project$Components$Button$toElement(
								$author$project$Components$Button$secondary(
									A2(
										$author$project$Components$Button$text,
										'PERUUTA',
										A2($author$project$Components$Button$onClick, $author$project$Pages$Tasklist$HideTasklistConfirmationDialog, $author$project$Components$Button$button)))),
								$author$project$Components$Button$toElement(
								A2(
									$author$project$Components$Button$id,
									'confirm-tasklist-close-button',
									$author$project$Components$Button$primary(
										A2(
											$author$project$Components$Button$text,
											'SULJE LOMAKE',
											A2(
												$author$project$Components$Button$onClick,
												$author$project$Pages$Tasklist$CloseTasklist(tasklist),
												$author$project$Components$Button$button)))))
							]))
					]))));
};
var $author$project$Pages$Tasklist$CloseTaskEditor = {$: 20};
var $author$project$Pages$Tasklist$TaskEditor$Cancel = {$: 17};
var $author$project$Pages$Tasklist$TaskEditor$DeleteTaskButtonClicked = {$: 16};
var $author$project$Pages$Tasklist$TaskEditor$SaveTaskButtonClicked = function (a) {
	return {$: 15, a: a};
};
var $author$project$Pages$Tasklist$TaskEditor$Worker_Deselect = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$Tasklist$TaskEditor$Worker_Select = function (a) {
	return {$: 11, a: a};
};
var $author$project$Types$User$canDeleteTask = function (user) {
	switch (user.$) {
		case 0:
			return true;
		case 1:
			return true;
		case 2:
			return true;
		default:
			var siteUser = user.a;
			switch (siteUser.$) {
				case 2:
					return true;
				case 0:
					return true;
				default:
					return false;
			}
	}
};
var $author$project$Pages$Tasklist$TaskEditor$TaskCommentUpdated = function (a) {
	return {$: 7, a: a};
};
var $author$project$Icons$customer = $author$project$Icons$fa_icon('fas fa-home');
var $author$project$Pages$Tasklist$TaskEditor$ShowWorkDateDialog = {$: 14};
var $author$project$Pages$Tasklist$TaskEditor$TaskDateDecreased = {$: 0};
var $author$project$Pages$Tasklist$TaskEditor$TaskDateIncreased = {$: 1};
var $author$project$Pages$Tasklist$TaskEditor$view_editorItemIcon = function (icon) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width(
				$mdgriffith$elm_ui$Element$px(28)),
				$mdgriffith$elm_ui$Element$paddingEach(
				{g1: 0, h1: 0, iN: 8, jl: 0})
			]),
		A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[$mdgriffith$elm_ui$Element$centerX]),
			icon));
};
var $author$project$Pages$Tasklist$TaskEditor$view_editorItemContainer = F3(
	function (icon, isValid, elements) {
		var iconColor = isValid ? $author$project$Colors$success : $author$project$Colors$accent;
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$color(iconColor)
						]),
					$author$project$Pages$Tasklist$TaskEditor$view_editorItemIcon(icon)),
					A2(
					$mdgriffith$elm_ui$Element$wrappedRow,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$spacing(4)
						]),
					elements)
				]));
	});
var $author$project$Pages$Tasklist$TaskEditor$date_editor = function (selectedDate) {
	var selectedDateAsString = A2(
		$elm$core$Maybe$withDefault,
		'-',
		A2(
			$elm$core$Maybe$map,
			function (date) {
				return $author$project$Language$dateToWeekdayFinAbbreviation(date) + (' ' + $author$project$Helpers$dateToStringShort(date));
			},
			selectedDate));
	return A2(
		$author$project$Styles$card_wrapper,
		true,
		A3(
			$author$project$Pages$Tasklist$TaskEditor$view_editorItemContainer,
			A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Events$onClick($author$project$Pages$Tasklist$TaskEditor$ShowWorkDateDialog),
						$author$project$Cursor$cursor(24)
					]),
				$author$project$Icons$calendar),
			$author$project$Types$TasklistTask$hasValidDate(selectedDate),
			$elm$core$List$singleton(
				A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$paddingEach(
							{g1: 0, h1: 0, iN: 60, jl: 0}),
							$mdgriffith$elm_ui$Element$behindContent(
							A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$Events$onClick($author$project$Pages$Tasklist$TaskEditor$ShowWorkDateDialog),
										$author$project$Cursor$cursor(24)
									]),
								$mdgriffith$elm_ui$Element$none))
						]),
					A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$centerX]),
						_List_fromArray(
							[
								$author$project$Components$Button$toElement(
								$author$project$Components$Button$secondary(
									A2(
										$author$project$Components$Button$icon,
										$author$project$Icons$chevronLeft,
										A2($author$project$Components$Button$onClick, $author$project$Pages$Tasklist$TaskEditor$TaskDateDecreased, $author$project$Components$Button$button)))),
								$author$project$Components$Button$toElement(
								A2(
									$author$project$Components$Button$width,
									$mdgriffith$elm_ui$Element$px(124),
									A2(
										$author$project$Components$Button$id,
										'open-work-date-dialog-button',
										A2(
											$author$project$Components$Button$textColor,
											$author$project$Colors$onSurface(9),
											A2(
												$author$project$Components$Button$text,
												selectedDateAsString,
												A2($author$project$Components$Button$onClick, $author$project$Pages$Tasklist$TaskEditor$ShowWorkDateDialog, $author$project$Components$Button$button)))))),
								$author$project$Components$Button$toElement(
								$author$project$Components$Button$secondary(
									A2(
										$author$project$Components$Button$icon,
										$author$project$Icons$chevronRight,
										A2($author$project$Components$Button$onClick, $author$project$Pages$Tasklist$TaskEditor$TaskDateIncreased, $author$project$Components$Button$button))))
							]))))));
};
var $author$project$Icons$list = $author$project$Icons$fa_icon('far fa-list-alt');
var $author$project$Pages$Tasklist$TaskEditor$InformCannotTouchProduct = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Tasklist$TaskEditor$ProductPriceTyped = F2(
	function (a, b) {
		return {$: 8, a: a, b: b};
	});
var $author$project$Pages$Tasklist$TaskEditor$SelectProduct = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Pages$Tasklist$TaskEditor$UnselectProduct = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pages$Tasklist$TaskEditor$product_selector = F5(
	function (productPriceInputs, selected_products, defaultPricesDict, userCanTouchProduct, available_product) {
		var selectedProduct = A2(
			$author$project$Helpers$firstWhere,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.iz;
				},
				$elm$core$Basics$eq(available_product.er)),
			selected_products);
		var onProductPriceTyped = userCanTouchProduct ? $author$project$Pages$Tasklist$TaskEditor$ProductPriceTyped : F2(
			function (_v3, _v4) {
				return $author$project$Pages$Tasklist$TaskEditor$InformCannotTouchProduct(available_product);
			});
		var onProductPriceClicked = userCanTouchProduct ? $author$project$Pages$Tasklist$TaskEditor$SelectProduct : F2(
			function (_v1, _v2) {
				return $author$project$Pages$Tasklist$TaskEditor$InformCannotTouchProduct(available_product);
			});
		var isSelected = function () {
			if (!selectedProduct.$) {
				return true;
			} else {
				return false;
			}
		}();
		var defaultPrice = A2($elm$core$Dict$get, available_product.er, defaultPricesDict);
		var toggleProduct = (!userCanTouchProduct) ? $author$project$Pages$Tasklist$TaskEditor$InformCannotTouchProduct(available_product) : (isSelected ? $author$project$Pages$Tasklist$TaskEditor$UnselectProduct(available_product.er) : A2($author$project$Pages$Tasklist$TaskEditor$SelectProduct, available_product.er, defaultPrice));
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(6),
					$mdgriffith$elm_ui$Element$width(
					$mdgriffith$elm_ui$Element$px(135))
				]),
			_List_fromArray(
				[
					$author$project$Components$Button$toElement(
					A4(
						$author$project$Components$ProductToggleButton$button,
						$author$project$Pages$Tasklist$TaskEditor$productToggleButtonHtmlId(available_product),
						available_product,
						toggleProduct,
						isSelected)),
					A6($author$project$Components$ProductPriceEditor$render, productPriceInputs, available_product, selectedProduct, defaultPrice, onProductPriceTyped, onProductPriceClicked)
				]));
	});
var $author$project$Pages$Tasklist$TaskEditor$products_editor = F6(
	function (priceInputMode, productPriceInputs, taskAvailableProducts, userAvailableProducts, defaultPricesDict, selected_products) {
		var totalPrice = A2($author$project$Types$SelectedProduct$productsTotal, priceInputMode, selected_products);
		var secondaryPriceMode = $author$project$Types$User$secondaryPriceInputMode(priceInputMode);
		var totalPriceInSecondaryVatMode = A2($author$project$Types$SelectedProduct$productsTotal, secondaryPriceMode, selected_products);
		return A2(
			$author$project$Styles$card_wrapper,
			true,
			A3(
				$author$project$Pages$Tasklist$TaskEditor$view_editorItemContainer,
				$author$project$Icons$shopping_cart,
				$author$project$Types$TasklistTask$hasValidProducts(selected_products),
				$elm$core$List$singleton(
					A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$spacing(4)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$row,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$spacing(4)
									]),
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$centerX,
												$mdgriffith$elm_ui$Element$Font$bold,
												$mdgriffith$elm_ui$Element$Font$size(26),
												$mdgriffith$elm_ui$Element$paddingEach(
												{g1: 0, h1: 90, iN: 0, jl: 0})
											]),
										$mdgriffith$elm_ui$Element$text(
											$author$project$Helpers$toEuroString(totalPrice))),
										A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$centerX,
												$mdgriffith$elm_ui$Element$Font$color(
												$author$project$Colors$secondary(0)),
												$mdgriffith$elm_ui$Element$Font$size(14)
											]),
										$mdgriffith$elm_ui$Element$text(
											'ALV ' + ($elm$core$String$fromFloat(
												$author$project$Types$User$priceInputModeVatPct(priceInputMode)) + '%'))),
										A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$alignRight,
												$mdgriffith$elm_ui$Element$Font$color(
												$author$project$Colors$secondary(0)),
												$mdgriffith$elm_ui$Element$Font$size(14),
												$mdgriffith$elm_ui$Element$paddingEach(
												{g1: 0, h1: 0, iN: 4, jl: 0})
											]),
										$mdgriffith$elm_ui$Element$text(
											'(' + ($author$project$Helpers$toEuroString(totalPriceInSecondaryVatMode) + (' ALV ' + ($elm$core$String$fromFloat(
												$author$project$Types$User$priceInputModeVatPct(secondaryPriceMode)) + ')')))))
									])),
								A2(
								$mdgriffith$elm_ui$Element$wrappedRow,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$paddingEach(
										{g1: 0, h1: 0, iN: 0, jl: 4}),
										$mdgriffith$elm_ui$Element$spacing(8)
									]),
								A2(
									$elm$core$List$map,
									function (product) {
										var userCanTouchProduct = A3(
											$elm$core$Basics$composeL,
											$elm$core$Basics$not,
											$elm$core$List$isEmpty,
											A2(
												$elm$core$List$filter,
												function (p) {
													return _Utils_eq(p.er, product.er);
												},
												userAvailableProducts));
										return A5($author$project$Pages$Tasklist$TaskEditor$product_selector, productPriceInputs, selected_products, defaultPricesDict, userCanTouchProduct, product);
									},
									A2(
										$elm$core$List$sortBy,
										function ($) {
											return $.ha;
										},
										taskAvailableProducts)))
							])))));
	});
var $author$project$Pages$Tasklist$TaskEditor$LicenseNumberTypedIn = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Tasklist$TaskEditor$ShowVehicleMakeDialog = {$: 12};
var $author$project$Pages$Tasklist$TaskEditor$VehicleModelUpdated = function (a) {
	return {$: 3, a: a};
};
var $author$project$Types$Vehicle$LicenseNumber$duplicateCount = F2(
	function (others, thisOne) {
		return $elm$core$List$length(
			A2(
				$elm$core$List$filter,
				$elm$core$Basics$eq(thisOne),
				others));
	});
var $author$project$Types$Vehicle$LicenseNumberField$isEmpty = A2($elm$core$Basics$composeR, $author$project$Types$Vehicle$LicenseNumberField$toString, $elm$core$String$isEmpty);
var $author$project$Pages$Tasklist$TaskEditor$withWarningBorder = F2(
	function (hasWarning, elem) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$Border$rounded(6),
					$mdgriffith$elm_ui$Element$Border$width(2),
					$mdgriffith$elm_ui$Element$Border$color(
					hasWarning ? $author$project$Colors$warning : $author$project$Colors$surface(0))
				]),
			elem);
	});
var $author$project$Pages$Tasklist$TaskEditor$vehicle_editor = F2(
	function (_v0, model) {
		var touchEventDetected = _v0.gy;
		var vehicleRowFieldHeight = touchEventDetected ? $mdgriffith$elm_ui$Element$px(38) : $mdgriffith$elm_ui$Element$px(34);
		var fields = model.hs;
		var licenseNumberField = fields.mf;
		var duplicateLicenseNumberCount = $author$project$Types$Vehicle$LicenseNumberField$isEmpty(licenseNumberField) ? 0 : A2(
			$author$project$Types$Vehicle$LicenseNumber$duplicateCount,
			A2(
				$elm$core$List$map,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.oX;
					},
					function ($) {
						return $.de;
					}),
				model.gu),
			$author$project$Types$Vehicle$LicenseNumberField$toLicenseNumber(licenseNumberField));
		return A2(
			$author$project$Styles$card_wrapper,
			true,
			A3(
				$author$project$Pages$Tasklist$TaskEditor$view_editorItemContainer,
				$author$project$Icons$car,
				$author$project$Types$TasklistTask$hasValidVehicle(
					$author$project$Types$TaskEditableFields$extractVehicle(fields)),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$spacing(4)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$row,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$height(vehicleRowFieldHeight),
										$mdgriffith$elm_ui$Element$spacing(6)
									]),
								_List_fromArray(
									[
										A2(
										$author$project$Pages$Tasklist$TaskEditor$withWarningBorder,
										$author$project$Types$Vehicle$LicenseNumberField$isEmpty(licenseNumberField),
										A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$width(
													$mdgriffith$elm_ui$Element$fillPortion(1)),
													$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
												]),
											A4(
												$author$project$Components$Input$textInput,
												_List_fromArray(
													[
														$author$project$Utils$HtmlUtil$id('vehicle-license-number')
													]),
												'Rekisterinumero',
												$author$project$Pages$Tasklist$TaskEditor$LicenseNumberTypedIn,
												$author$project$Types$Vehicle$LicenseNumberField$toString(licenseNumberField)))),
										A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$width(
												$mdgriffith$elm_ui$Element$fillPortion(1)),
												$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
												$mdgriffith$elm_ui$Element$Background$color(
												$author$project$Colors$surface(1)),
												$mdgriffith$elm_ui$Element$Border$rounded(3)
											]),
										A2(
											$author$project$Pages$Tasklist$TaskEditor$withWarningBorder,
											(fields.fd === '') && (!model.ax.cB),
											A3(
												$author$project$Components$Vehicles$MakeSelector$vehicleMakeSelector,
												fields.fd,
												$author$project$Pages$Tasklist$TaskEditor$ShowVehicleMakeDialog,
												touchEventDetected ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
													{lk: model.ax, mU: $author$project$Pages$Tasklist$TaskEditor$GotMakeDropdownMsg})))),
										A4(
										$author$project$Components$Input$textInput,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$width(
												$mdgriffith$elm_ui$Element$fillPortion(1)),
												$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
											]),
										'Malli',
										$author$project$Pages$Tasklist$TaskEditor$VehicleModelUpdated,
										fields.oY)
									])),
								(!duplicateLicenseNumberCount) ? $mdgriffith$elm_ui$Element$none : A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$color(
										$author$project$Colors$primary(2)),
										$author$project$Styles$mediumFontSize
									]),
								$mdgriffith$elm_ui$Element$text(
									(duplicateLicenseNumberCount === 1) ? 'Lomakkeella on jo ajoneuvo tällä rekisterinumerolla' : ('Lomakkeella on jo ' + ($elm$core$String$fromInt(duplicateLicenseNumberCount) + ' ajoneuvoa tällä rekisterinumerolla'))))
							]))
					])));
	});
var $author$project$Pages$Tasklist$TaskEditor$task_editor_fields = F2(
	function (appContext, model) {
		var tasklistAvailableProducts = A2(
			$elm$core$List$filter,
			function (product) {
				var isTasklistProduct = A2($elm$core$List$member, product.er, model.gv);
				var isSelectedProduct = A2(
					$elm$core$List$member,
					product.er,
					A2(
						$elm$core$List$map,
						function ($) {
							return $.iz;
						},
						model.hs.gf));
				return isTasklistProduct || isSelectedProduct;
			},
			model.T);
		var priceInputMode = $author$project$Types$User$priceInputMode(model.gA);
		var customer = A2($author$project$Types$Customer$getCustomer, model.ap, model.gt);
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Border$rounded(0),
					$mdgriffith$elm_ui$Element$spacing(6)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$spacing(6)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$alignTop,
									$mdgriffith$elm_ui$Element$Font$bold,
									$mdgriffith$elm_ui$Element$spacing(6)
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width(
											$mdgriffith$elm_ui$Element$fillPortion(37))
										]),
									A2(
										$author$project$Styles$card_wrapper,
										true,
										A3(
											$author$project$Pages$Tasklist$TaskEditor$view_editorItemContainer,
											$author$project$Icons$customer,
											true,
											$elm$core$List$singleton(
												$mdgriffith$elm_ui$Element$text(
													function () {
														var _v0 = customer.kY;
														if (!_v0) {
															return customer.mD;
														} else {
															return model.nf;
														}
													}()))))),
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width(
											$mdgriffith$elm_ui$Element$fillPortion(63))
										]),
									A2(
										$author$project$Styles$card_wrapper,
										true,
										A3(
											$author$project$Pages$Tasklist$TaskEditor$view_editorItemContainer,
											$author$project$Icons$list,
											true,
											$elm$core$List$singleton(
												$mdgriffith$elm_ui$Element$text(model.jf)))))
								])),
							A2($author$project$Pages$Tasklist$TaskEditor$vehicle_editor, appContext, model),
							$author$project$Pages$Tasklist$TaskEditor$date_editor(model.hs.b4)
						])),
					A6($author$project$Pages$Tasklist$TaskEditor$products_editor, priceInputMode, model.aQ, tasklistAvailableProducts, model.aT, model.fz, model.hs.gf),
					A2(
					$author$project$Styles$card_wrapper,
					true,
					A3(
						$author$project$Pages$Tasklist$TaskEditor$view_editorItemContainer,
						$author$project$Icons$workers,
						$author$project$Types$TasklistTask$hasValidWorkers(model.hs.jz),
						$elm$core$List$singleton(
							A2(
								$mdgriffith$elm_ui$Element$map,
								$author$project$Pages$Tasklist$TaskEditor$GotSubModuleMsg_WorkerSelector,
								A2(
									$author$project$Components$WorkerSelector$view,
									model.cx,
									{f3: $author$project$Pages$Tasklist$TaskEditor$Worker_Deselect, f4: $author$project$Pages$Tasklist$TaskEditor$Worker_Select}))))),
					A2(
					$author$project$Styles$card_wrapper,
					true,
					A3(
						$author$project$Pages$Tasklist$TaskEditor$view_editorItemContainer,
						$author$project$Icons$comment,
						true,
						$elm$core$List$singleton(
							A3($author$project$Styles$text_input, 'Lisätietoja', $author$project$Pages$Tasklist$TaskEditor$TaskCommentUpdated, model.hs.kH))))
				]));
	});
var $author$project$Pages$Tasklist$TaskEditor$work_date_dialog = function (model) {
	var hideSubDialogMsg = $author$project$Pages$Tasklist$TaskEditor$HideSubDialog(
		$elm$core$Maybe$Just('open-work-date-dialog-button'));
	return A2(
		$author$project$Components$Modal$modal,
		{kz: true, mR: hideSubDialogMsg},
		A2(
			$author$project$Styles$card_wrapper,
			false,
			A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Background$color($author$project$Colors$background),
						$mdgriffith$elm_ui$Element$padding(10),
						$mdgriffith$elm_ui$Element$Border$rounded(8),
						$mdgriffith$elm_ui$Element$spacing(8)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$centerX,
										$mdgriffith$elm_ui$Element$Font$bold,
										$mdgriffith$elm_ui$Element$Font$color($author$project$Colors$onBackground)
									]),
								$mdgriffith$elm_ui$Element$text('Päivämäärä')),
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$alignRight,
										$mdgriffith$elm_ui$Element$Font$color(
										$author$project$Colors$onSurface(5))
									]),
								$author$project$Components$CloseButton$closeButton(hideSubDialogMsg))
							])),
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$centerX]),
						A2(
							$mdgriffith$elm_ui$Element$map,
							$author$project$Pages$Tasklist$TaskEditor$GotDatePickerMsg,
							$author$project$Components$DatePicker$view(model.bm)))
					]))));
};
var $author$project$Pages$Tasklist$TaskEditor$view = F3(
	function (appContext, isPendingServerUpdateRequest, model) {
		var canDeleteTask = $author$project$Types$User$canDeleteTask(
			$author$project$Types$User$toUser(model.gA));
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$author$project$Styles$largeFontSize,
					$mdgriffith$elm_ui$Element$inFront(
					function () {
						var _v0 = model.dj;
						switch (_v0) {
							case 1:
								return A4(
									$author$project$Components$Vehicles$MakeDialog$vehicleMakeDialog,
									model.am,
									$author$project$Pages$Tasklist$TaskEditor$SelectVehicleMake,
									$author$project$Pages$Tasklist$TaskEditor$HideSubDialog(
										$elm$core$Maybe$Just($author$project$Components$Vehicles$MakeSelector$openVehicleMakeDialogButtonId)),
									model.hs.fd);
							case 2:
								return $author$project$Pages$Tasklist$TaskEditor$work_date_dialog(model);
							case 3:
								return A2(
									$mdgriffith$elm_ui$Element$map,
									$author$project$Pages$Tasklist$TaskEditor$GotSubModuleMsg_WorkerSelector,
									A2(
										$author$project$Components$WorkerSelector$view,
										model.cx,
										{f3: $author$project$Pages$Tasklist$TaskEditor$Worker_Deselect, f4: $author$project$Pages$Tasklist$TaskEditor$Worker_Select}));
							default:
								return $mdgriffith$elm_ui$Element$none;
						}
					}())
				]),
			A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$htmlAttribute(
						A2($elm$html$Html$Attributes$style, 'max-height', '99vh')),
						$mdgriffith$elm_ui$Element$scrollbarY,
						$mdgriffith$elm_ui$Element$width(
						$mdgriffith$elm_ui$Element$px(670)),
						$mdgriffith$elm_ui$Element$Border$rounded(8),
						$mdgriffith$elm_ui$Element$Background$color($author$project$Colors$background),
						$mdgriffith$elm_ui$Element$Font$color($author$project$Colors$onBackground),
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$centerY,
						$mdgriffith$elm_ui$Element$padding(6)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$paddingEach(
								{g1: 12, h1: 0, iN: 0, jl: 6}),
								$mdgriffith$elm_ui$Element$centerX,
								$mdgriffith$elm_ui$Element$Font$bold
							]),
						$mdgriffith$elm_ui$Element$text(
							function () {
								var _v1 = model.ez;
								if (!_v1) {
									return 'UUSI TYÖ';
								} else {
									return 'MUOKKAA TYÖTÄ';
								}
							}())),
						A2($author$project$Pages$Tasklist$TaskEditor$task_editor_fields, appContext, model),
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$paddingEach(
								{g1: 4, h1: 4, iN: 4, jl: 8}),
								$mdgriffith$elm_ui$Element$spacing(8)
							]),
						_List_fromArray(
							[
								function () {
								var _v2 = _Utils_Tuple2(canDeleteTask, model.ez);
								if (_v2.a && (_v2.b === 1)) {
									var _v3 = _v2.b;
									return A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[$mdgriffith$elm_ui$Element$alignLeft]),
										$author$project$Components$Button$toElement(
											$author$project$Components$Button$warning(
												A2(
													$author$project$Components$Button$busy,
													isPendingServerUpdateRequest,
													A2(
														$author$project$Components$Button$text,
														'POISTA',
														A2($author$project$Components$Button$onClick, $author$project$Pages$Tasklist$TaskEditor$DeleteTaskButtonClicked, $author$project$Components$Button$button))))));
								} else {
									return $mdgriffith$elm_ui$Element$none;
								}
							}(),
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$alignRight]),
								$author$project$Components$Button$toElement(
									$author$project$Components$Button$secondary(
										A2(
											$author$project$Components$Button$text,
											'PERUUTA',
											A2($author$project$Components$Button$onClick, $author$project$Pages$Tasklist$TaskEditor$Cancel, $author$project$Components$Button$button))))),
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$alignRight]),
								$author$project$Components$Button$toElement(
									$author$project$Components$Button$primary(
										A2(
											$author$project$Components$Button$busy,
											isPendingServerUpdateRequest,
											A2(
												$author$project$Components$Button$text,
												'TALLENNA',
												A2(
													$author$project$Components$Button$onClick,
													$author$project$Pages$Tasklist$TaskEditor$SaveTaskButtonClicked(model.hs),
													$author$project$Components$Button$button))))))
							]))
					])));
	});
var $author$project$Pages$Tasklist$view_taskEditorModal = F3(
	function (appContext, isBusy, taskEditorStatus) {
		var renderTaskEditor = function (editorModel) {
			return A2(
				$author$project$Components$Modal$modal,
				{kz: true, mR: $author$project$Pages$Tasklist$CloseTaskEditor},
				A2(
					$mdgriffith$elm_ui$Element$map,
					$author$project$Pages$Tasklist$GotTaskEditorMsg,
					A3($author$project$Pages$Tasklist$TaskEditor$view, appContext, isBusy, editorModel)));
		};
		switch (taskEditorStatus.$) {
			case 1:
				var editorModel = taskEditorStatus.a;
				return renderTaskEditor(editorModel);
			case 2:
				var editorModel = taskEditorStatus.a;
				return renderTaskEditor(editorModel);
			default:
				return $mdgriffith$elm_ui$Element$none;
		}
	});
var $elm$core$List$sortWith = _List_sortWith;
var $author$project$Pages$Tasklist$tasklistGroupOrder = F3(
	function (customers, a, b) {
		var oneTimeCustomerIds = A2(
			$elm$core$List$map,
			function ($) {
				return $.er;
			},
			A2(
				$elm$core$List$filter,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.kY;
					},
					$elm$core$Basics$eq(1)),
				customers));
		var isMobilePayGroup = function (group) {
			return A2($elm$core$String$contains, 'MobilePay', group.mD);
		};
		var hasOneTimeCustomers = function (group) {
			return A2(
				$elm$core$List$any,
				function (_v0) {
					var customerId = _v0.kX;
					return A2($elm$core$List$member, customerId, oneTimeCustomerIds);
				},
				group.e);
		};
		return (hasOneTimeCustomers(a) && (!hasOneTimeCustomers(b))) ? 2 : (((!hasOneTimeCustomers(a)) && hasOneTimeCustomers(b)) ? 0 : ((isMobilePayGroup(a) && (!isMobilePayGroup(b))) ? 2 : (((!isMobilePayGroup(a)) && isMobilePayGroup(b)) ? 0 : A2($elm$core$Basics$compare, a.mD, b.mD))));
	});
var $author$project$Pages$Tasklist$ToggleTasklistGroup = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $author$project$Helpers$skipIf = F2(
	function (bool, doThis) {
		return bool ? $author$project$Helpers$doNothing : doThis;
	});
var $author$project$Pages$Tasklist$v_tasklistGroupToggleButton = F2(
	function (selectedGroupNames, group) {
		var openTasklists = A2(
			$elm$core$List$filter,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.aj;
				},
				A2($elm$core$Basics$composeL, $elm$core$Basics$not, $elm$core$List$isEmpty)),
			group.e);
		var isActive = A2($elm$core$Set$member, group.mD, selectedGroupNames);
		var onClick = A2($author$project$Pages$Tasklist$ToggleTasklistGroup, group, !isActive);
		return $author$project$Components$Button$toElement(
			$author$project$Components$Button$primary(
				A3(
					$elm$core$Basics$apR,
					$author$project$Components$Button$iconRight(
						$author$project$Pages$Tasklist$openTasklistsBadge(
							$elm$core$List$length(openTasklists))),
					$author$project$Helpers$skipIf(
						$elm$core$List$isEmpty(openTasklists)),
					A2(
						$author$project$Components$Button$text,
						group.mD,
						A2($author$project$Components$Button$toggleButton, onClick, isActive)))));
	});
var $author$project$Pages$Tasklist$view_tasklistGroupToggles = function (model) {
	return A2(
		$mdgriffith$elm_ui$Element$wrappedRow,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$spacing(6)
			]),
		A2(
			$elm$core$List$map,
			$author$project$Pages$Tasklist$v_tasklistGroupToggleButton(model.bf),
			A2(
				$elm$core$List$sortWith,
				$author$project$Pages$Tasklist$tasklistGroupOrder(model.X),
				$elm$core$Dict$values(model.dS))));
};
var $author$project$Pages$Tasklist$view_Loaded = F2(
	function (appContext, model) {
		var visibleTasklists = A3(
			$elm$core$List$foldr,
			$elm$core$Basics$append,
			_List_Nil,
			A2(
				$elm$core$List$map,
				function (group) {
					return A2(
						$elm$core$List$filter,
						function (tasklist) {
							return _Utils_eq(tasklist.fN, group);
						},
						model.e);
				},
				$elm$core$List$sort(
					$elm$core$Set$toList(model.bf))));
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$paddingEach(
					{g1: 4, h1: 4, iN: 4, jl: 10}),
					$mdgriffith$elm_ui$Element$inFront(
					function () {
						var _v0 = model.R;
						if (_v0.$ === 2) {
							var tasklistToClose = _v0.a;
							return $author$project$Pages$Tasklist$view_closeTasklistConfirmationDialog(tasklistToClose);
						} else {
							return A3(
								$author$project$Pages$Tasklist$view_taskEditorModal,
								appContext,
								$author$project$Pages$Tasklist$areUpdateButtonsDisabled(model),
								model.M);
						}
					}()),
					$author$project$Styles$mediumFontSize
				]),
			_List_fromArray(
				[
					$author$project$Pages$Tasklist$view_tasklistGroupToggles(model),
					A3($author$project$Pages$Tasklist$v_tasklistBoard, appContext, model, visibleTasklists)
				]));
	});
var $author$project$Pages$Tasklist$view_Loading = F2(
	function (_v0, _v1) {
		return $mdgriffith$elm_ui$Element$none;
	});
var $author$project$Pages$Tasklist$view = F2(
	function (appContext, model) {
		var _v0 = model._;
		switch (_v0.$) {
			case 0:
				var loadingModel = _v0.a;
				return A2(
					$mdgriffith$elm_ui$Element$map,
					$author$project$Pages$Tasklist$Msg_Loading,
					A2($author$project$Pages$Tasklist$view_Loading, appContext, loadingModel));
			case 1:
				var loadedModel = _v0.a;
				return A2(
					$mdgriffith$elm_ui$Element$map,
					$author$project$Pages$Tasklist$Msg_Loaded,
					A2($author$project$Pages$Tasklist$view_Loaded, appContext, loadedModel));
			default:
				var erroredModel = _v0.a;
				return A2(
					$mdgriffith$elm_ui$Element$map,
					$author$project$Pages$Tasklist$Msg_Errored,
					A2($author$project$Pages$Tasklist$view_Errored, appContext, erroredModel));
		}
	});
var $author$project$Components$PinDialog$BackSpaceKeyPressed = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$PinDialog$NumberKeyPressed = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Components$PinDialog$onNumberKeyPressed = function (currentPin) {
	return A2(
		$elm$html$Html$Events$custom,
		'keydown',
		$author$project$KeyboardHelper$htmlEventToKeyCode(
			function (keyCode) {
				var message = A2($author$project$KeyboardHelper$isKey, 4, keyCode) ? $author$project$Components$PinDialog$BackSpaceKeyPressed(currentPin) : A2(
					$elm$core$Maybe$withDefault,
					$author$project$Components$PinDialog$NoOp,
					A2(
						$elm$core$Maybe$map,
						$author$project$Components$PinDialog$NumberKeyPressed(currentPin),
						$author$project$KeyboardHelper$keyCodeToNumber(keyCode)));
				return {
					mp: message,
					iu: $author$project$KeyboardHelper$isArrowKey(keyCode),
					ja: $author$project$KeyboardHelper$isArrowKey(keyCode)
				};
			}));
};
var $author$project$Components$PinDialog$view = function (_v0) {
	var currentPin = _v0.cR;
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[$mdgriffith$elm_ui$Element$centerX]),
		A4(
			$author$project$Components$Input$textInput,
			_List_fromArray(
				[
					$author$project$Utils$HtmlUtil$id($author$project$Components$PinDialog$pinInputId),
					$author$project$Utils$HtmlUtil$disableAutoComplete,
					$mdgriffith$elm_ui$Element$htmlAttribute(
					$author$project$Components$PinDialog$onNumberKeyPressed(currentPin))
				]),
			'Syötä PIN',
			function (_v1) {
				return $author$project$Components$PinDialog$NoOp;
			},
			A2(
				$elm$core$String$repeat,
				$elm$core$List$length(currentPin),
				'*')));
};
var $author$project$Pages$WorkTimeTracking$State_Loading$view = function (_v0) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$centerX,
				$mdgriffith$elm_ui$Element$Font$color(
				$author$project$Colors$onPrimary(5)),
				$author$project$Styles$largeFontSize
			]),
		$mdgriffith$elm_ui$Element$text('Ladataan...'));
};
var $author$project$Pages$WorkTimeTracking$ListElements$Archive = {$: 2};
var $author$project$Pages$WorkTimeTracking$State_Main$Entry_Clicked_Edit = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$WorkTimeTracking$State_Main$Entry_Clicked_Sign = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$WorkTimeTracking$ListElements$SignQueue = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$WorkTimeTracking$State_Main$WorkerNamesAndBalances = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$WorkTimeTracking$State_Main$WorkerNamesOnly = {$: 0};
var $author$project$Types$User$canSignWorkTimeEntries = function (user) {
	switch (user.$) {
		case 0:
			return true;
		case 2:
			return true;
		case 1:
			return true;
		default:
			return false;
	}
};
var $author$project$Styles$cardTitle = A2(
	$elm$core$Basics$composeR,
	$mdgriffith$elm_ui$Element$text,
	$mdgriffith$elm_ui$Element$el(
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Font$alignLeft,
				$mdgriffith$elm_ui$Element$Font$bold,
				$mdgriffith$elm_ui$Element$padding(4),
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$author$project$Styles$xxlFontSize
			])));
var $author$project$Pages$WorkTimeTracking$ListElements$column_WorkTimeEntry_balanceEffect = {
	g4: A2(
		$elm$core$Basics$composeR,
		$author$project$Types$WorkTimeEntry$renderBalanceEffect,
		$mdgriffith$elm_ui$Element$el(
			_List_fromArray(
				[$mdgriffith$elm_ui$Element$alignRight]))),
	hE: $mdgriffith$elm_ui$Element$text('Tunnit'),
	dx: _List_Nil,
	fg: $mdgriffith$elm_ui$Element$px(60)
};
var $author$project$Icons$edit = $author$project$Icons$fa_icon('fas fa-edit');
var $author$project$Pages$WorkTimeTracking$ListElements$column_WorkTimeEntry_editButton = F2(
	function (serverRequests, onClick) {
		return {
			g4: function (entry) {
				var isSigned = $author$project$Types$WorkTimeEntry$isSigned(entry);
				return $author$project$Components$Button$toElement(
					A2(
						$author$project$Components$Button$tooltip,
						isSigned ? 'hyväksyttyä kirjausta ei voi enää muokata' : 'muokkaa kirjausta',
						$author$project$Components$Button$secondary(
							A2(
								$author$project$Components$Button$onClick,
								onClick(entry),
								A2(
									$author$project$Components$Button$iconSize,
									14,
									A2(
										$author$project$Components$Button$icon,
										$author$project$Icons$edit,
										A2($author$project$Components$Button$disabled, isSigned || serverRequests.fo, $author$project$Components$Button$button)))))));
			},
			hE: $mdgriffith$elm_ui$Element$none,
			dx: _List_Nil,
			fg: $mdgriffith$elm_ui$Element$px(25)
		};
	});
var $author$project$Pages$WorkTimeTracking$ListElements$column_WorkTimeEntry_entryType = {
	g4: A2(
		$elm$core$Basics$composeR,
		function ($) {
			return $.ln;
		},
		A2(
			$elm$core$Basics$composeR,
			$author$project$Types$WorkTimeEntry$typeToString,
			A2(
				$elm$core$Basics$composeR,
				$mdgriffith$elm_ui$Element$text,
				$mdgriffith$elm_ui$Element$el(
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$centerX]))))),
	hE: A2(
		$mdgriffith$elm_ui$Element$paragraph,
		_List_Nil,
		$elm$core$List$singleton(
			$mdgriffith$elm_ui$Element$text('Kirjaus- tyyppi'))),
	dx: _List_Nil,
	fg: $mdgriffith$elm_ui$Element$px(80)
};
var $author$project$Pages$WorkTimeTracking$ListElements$column_WorkTimeEntry_markAsPaidButton = F2(
	function (isDisabled, onClickMarkEntryAsPaid) {
		return {
			g4: function (entry) {
				return A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$centerX]),
					$author$project$Components$Button$toElement(
						$author$project$Components$Button$primary(
							A2(
								$author$project$Components$Button$text,
								'Kuittaa maksetuksi',
								A2(
									$author$project$Components$Button$onClick,
									onClickMarkEntryAsPaid(entry),
									A2($author$project$Components$Button$disabled, isDisabled, $author$project$Components$Button$button))))));
			},
			hE: $mdgriffith$elm_ui$Element$text('Maksaminen'),
			dx: _List_Nil,
			fg: $mdgriffith$elm_ui$Element$px(160)
		};
	});
var $author$project$Helpers$dateToStringLong = function (date) {
	return A2($justinmimbs$date$Date$format, 'dd.MM.y', date);
};
var $author$project$Pages$WorkTimeTracking$ListElements$view_dayAndDate = function (date) {
	return A2(
		$mdgriffith$elm_ui$Element$row,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width(
				$mdgriffith$elm_ui$Element$px(105))
			]),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$alignLeft]),
				$mdgriffith$elm_ui$Element$text(
					$author$project$Language$dateToWeekdayFinAbbreviation(date))),
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$alignRight]),
				$mdgriffith$elm_ui$Element$text(
					$author$project$Helpers$dateToStringLong(date)))
			]));
};
var $author$project$Pages$WorkTimeTracking$ListElements$column_WorkTimeEntry_on = {
	g4: A2(
		$elm$core$Basics$composeR,
		function ($) {
			return $.mJ;
		},
		$author$project$Pages$WorkTimeTracking$ListElements$view_dayAndDate),
	hE: A2(
		$mdgriffith$elm_ui$Element$paragraph,
		_List_Nil,
		$elm$core$List$singleton(
			$mdgriffith$elm_ui$Element$text('Kirjauksen päivä'))),
	dx: _List_Nil,
	fg: $mdgriffith$elm_ui$Element$px(105)
};
var $author$project$Types$WorkTimeEntry$renderReflectInPay = function (_v0) {
	var reflectInPay = _v0.bF;
	return $mdgriffith$elm_ui$Element$text(
		reflectInPay ? 'kyllä' : 'ei');
};
var $author$project$Pages$WorkTimeTracking$ListElements$column_WorkTimeEntry_reflectInPay = {
	g4: $author$project$Types$WorkTimeEntry$renderReflectInPay,
	hE: A2(
		$mdgriffith$elm_ui$Element$paragraph,
		_List_Nil,
		$elm$core$List$singleton(
			$mdgriffith$elm_ui$Element$text('Huomioidaan palkassa'))),
	dx: _List_Nil,
	fg: $mdgriffith$elm_ui$Element$px(105)
};
var $author$project$Pages$WorkTimeTracking$ListElements$column_WorkTimeEntry_signButton = F2(
	function (isDisabled, onClickSignEntry) {
		return {
			g4: function (entry) {
				return $author$project$Components$Button$toElement(
					$author$project$Components$Button$primary(
						A2(
							$author$project$Components$Button$text,
							'Hyväksy',
							A2(
								$author$project$Components$Button$onClick,
								onClickSignEntry(entry),
								A2($author$project$Components$Button$disabled, isDisabled, $author$project$Components$Button$button)))));
			},
			hE: $mdgriffith$elm_ui$Element$text(''),
			dx: _List_Nil,
			fg: $mdgriffith$elm_ui$Element$px(80)
		};
	});
var $author$project$Types$WorkTimeEntry$renderSignature = function (_v0) {
	var at = _v0.j4;
	var by = _v0.kl;
	return A2(
		$mdgriffith$elm_ui$Element$row,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$spacing(8),
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
			]),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$alignLeft]),
				$mdgriffith$elm_ui$Element$text(by)),
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$alignRight, $mdgriffith$elm_ui$Element$Font$italic, $author$project$Styles$mediumFontSize]),
				$mdgriffith$elm_ui$Element$text(
					$author$project$Types$IsoTime$toString(at)))
			]));
};
var $author$project$Pages$WorkTimeTracking$ListElements$column_WorkTimeEntry_signature = {
	g4: A2(
		$elm$core$Basics$composeR,
		function ($) {
			return $.i2;
		},
		A2(
			$elm$core$Basics$composeR,
			$elm$core$Maybe$map($author$project$Types$WorkTimeEntry$renderSignature),
			$elm$core$Maybe$withDefault($mdgriffith$elm_ui$Element$none))),
	hE: $mdgriffith$elm_ui$Element$text('Hyväksynyt'),
	dx: _List_Nil,
	fg: $mdgriffith$elm_ui$Element$px(220)
};
var $author$project$Pages$WorkTimeTracking$ListElements$column_WorkTimeEntry_unsignButton = F2(
	function (isDisabled, onClickUnsignEntry) {
		return {
			g4: function (entry) {
				return $author$project$Components$Button$toElement(
					$author$project$Components$Button$warning(
						A2(
							$author$project$Components$Button$tooltip,
							'Poista hyväksyntä',
							A2(
								$author$project$Components$Button$icon,
								$author$project$Icons$ban,
								A2(
									$author$project$Components$Button$onClick,
									onClickUnsignEntry(entry),
									A2($author$project$Components$Button$disabled, isDisabled, $author$project$Components$Button$button))))));
			},
			hE: $mdgriffith$elm_ui$Element$text(''),
			dx: _List_Nil,
			fg: $mdgriffith$elm_ui$Element$px(25)
		};
	});
var $author$project$Pages$WorkTimeTracking$ListElements$column_comment = function (width) {
	return {
		g4: A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.kH;
			},
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Maybe$withDefault(''),
				A2(
					$elm$core$Basics$composeR,
					$mdgriffith$elm_ui$Element$text,
					A2(
						$elm$core$Basics$composeR,
						$elm$core$List$singleton,
						$mdgriffith$elm_ui$Element$paragraph(_List_Nil))))),
		hE: $mdgriffith$elm_ui$Element$text('Kommentti'),
		dx: _List_Nil,
		fg: width
	};
};
var $author$project$Pages$WorkTimeTracking$ListElements$rowLevelCheckbox = F3(
	function (workTimeEntryId, toggleHandler, isChecked) {
		return A3(
			$author$project$Components$Checkbox$checkbox,
			$mdgriffith$elm_ui$Element$Input$labelHidden('Valitse rivi'),
			function (checked) {
				return toggleHandler(
					_List_fromArray(
						[
							_Utils_Tuple2(workTimeEntryId, checked)
						]));
			},
			isChecked);
	});
var $elm$core$Set$size = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$size(dict);
};
var $author$project$Pages$WorkTimeTracking$ListElements$tableLevelCheckbox = F3(
	function (allEntryIds, selectedEntryIds, toggleHandler) {
		var areAllRowsSelected = _Utils_eq(
			$elm$core$Set$size(selectedEntryIds),
			$elm$core$List$length(allEntryIds));
		return A3(
			$author$project$Components$Checkbox$checkbox,
			$mdgriffith$elm_ui$Element$Input$labelHidden('Valitse kaikki rivit'),
			function (_v0) {
				return toggleHandler(
					A2(
						$elm$core$List$map,
						function (id) {
							return _Utils_Tuple2(id, !areAllRowsSelected);
						},
						allEntryIds));
			},
			areAllRowsSelected);
	});
var $author$project$Pages$WorkTimeTracking$ListElements$column_rowSelectCheckbox = F3(
	function (allEntryIds, selectedEntryIds, onToggleSelectedStates) {
		return {
			g4: function (workTimeEntry) {
				var isSelected = A2($elm$core$Set$member, workTimeEntry.er, selectedEntryIds);
				return A3($author$project$Pages$WorkTimeTracking$ListElements$rowLevelCheckbox, workTimeEntry.er, onToggleSelectedStates, isSelected);
			},
			hE: A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Element$paddingXY, 2, 0)
					]),
				A3($author$project$Pages$WorkTimeTracking$ListElements$tableLevelCheckbox, allEntryIds, selectedEntryIds, onToggleSelectedStates)),
			dx: _List_fromArray(
				[
					A2($mdgriffith$elm_ui$Element$paddingXY, 2, 0)
				]),
			fg: $mdgriffith$elm_ui$Element$px(30)
		};
	});
var $author$project$Pages$WorkTimeTracking$ListElements$column_worker = function (workersDict) {
	return {
		g4: function (entry) {
			return $mdgriffith$elm_ui$Element$text(
				A2(
					$elm$core$Maybe$withDefault,
					'TUNTEMATON',
					A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.mD;
						},
						A2($elm$core$Dict$get, entry.jy, workersDict))));
		},
		hE: $mdgriffith$elm_ui$Element$text('Työntekijä'),
		dx: _List_Nil,
		fg: $mdgriffith$elm_ui$Element$px(180)
	};
};
var $author$project$Pages$WorkTimeTracking$ListElements$column_worker_compensationType = function (workersDict) {
	return {
		g4: function (entry) {
			return $mdgriffith$elm_ui$Element$text(
				A2(
					$elm$core$Maybe$withDefault,
					'-',
					A2(
						$elm$core$Maybe$map,
						$author$project$Types$Worker$compensationTypeToString,
						A2(
							$elm$core$Maybe$map,
							function ($) {
								return $.kI;
							},
							A2($elm$core$Dict$get, entry.jy, workersDict)))));
		},
		hE: A2(
			$mdgriffith$elm_ui$Element$paragraph,
			_List_Nil,
			$elm$core$List$singleton(
				$mdgriffith$elm_ui$Element$text('Sopimus- tyyppi'))),
		dx: _List_Nil,
		fg: $mdgriffith$elm_ui$Element$px(100)
	};
};
var $author$project$Pages$WorkTimeTracking$ListElements$sortWorkTimeEntries = F3(
	function (workersDict, entriesListType_, entries_) {
		var workerName = function (workerId) {
			return A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.mD;
					},
					A2($elm$core$Dict$get, workerId, workersDict)));
		};
		return function () {
			if (entriesListType_.$ === 1) {
				return $elm$core$List$sortBy(
					function (_v1) {
						var on = _v1.mJ;
						var workerId = _v1.jy;
						return _Utils_Tuple2(
							workerName(workerId),
							$justinmimbs$date$Date$toIsoString(on));
					});
			} else {
				return A2(
					$elm$core$Basics$composeR,
					$elm$core$List$sortBy(
						function (_v2) {
							var on = _v2.mJ;
							var workerId = _v2.jy;
							return _Utils_Tuple2(
								$justinmimbs$date$Date$toIsoString(on),
								workerName(workerId));
						}),
					$elm$core$List$reverse);
			}
		}()(entries_);
	});
var $author$project$Pages$WorkTimeTracking$ListElements$element_entriesList = F5(
	function (entriesListType, serverRequests, userCanSignEntries, workers, allEntries) {
		var workersDict = $elm$core$Dict$fromList(
			A2(
				$elm$core$List$map,
				function (worker) {
					return _Utils_Tuple2(worker.er, worker);
				},
				workers));
		var entries = function () {
			switch (entriesListType.$) {
				case 0:
					return A2(
						$elm$core$List$filter,
						A2($elm$core$Basics$composeL, $elm$core$Basics$not, $author$project$Types$WorkTimeEntry$isSigned),
						allEntries);
				case 1:
					return A2($elm$core$List$filter, $author$project$Types$WorkTimeEntry$isInPaymentQueue, allEntries);
				default:
					return A2(
						$elm$core$List$filter,
						function (entry) {
							return $author$project$Types$WorkTimeEntry$isPaid(entry) || (!entry.bF);
						},
						A2($elm$core$List$filter, $author$project$Types$WorkTimeEntry$isSigned, allEntries));
			}
		}();
		var _v0 = function () {
			switch (entriesListType.$) {
				case 0:
					return _Utils_Tuple3('Hyväksyttävät kirjaukset', 'ei kuittaamattomia kirjauksia jonossa', _List_Nil);
				case 1:
					var onClickExportToExcel = entriesListType.a.mN;
					return _Utils_Tuple3(
						'Maksujono',
						'ei maksamattomia tunteja jonossa',
						_List_fromArray(
							[
								A2(
								$elm$core$Maybe$withDefault,
								$mdgriffith$elm_ui$Element$none,
								A2(
									$elm$core$Maybe$map,
									function (onClick) {
										return $author$project$Components$Button$toElement(
											A2(
												$author$project$Components$Button$tooltip,
												'Lataa maksujono Excel-tiedostossa',
												A2(
													$author$project$Components$Button$onClick,
													onClick,
													$author$project$Components$Button$secondary(
														A2($author$project$Components$Button$icon, $author$project$Icons$excel_file, $author$project$Components$Button$button)))));
									},
									onClickExportToExcel))
							]));
				default:
					return _Utils_Tuple3('Arkisto - kirjaukset', 'arkisto on tyhjä', _List_Nil);
			}
		}();
		var listHeaderText = _v0.a;
		var emptyNoticeText = _v0.b;
		var listActionButtons = _v0.c;
		return A2(
			$author$project$Styles$card_wrapper,
			true,
			A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$paddingEach(
								{g1: 2, h1: 0, iN: 0, jl: 0})
							]),
						_List_fromArray(
							[
								$author$project$Styles$cardTitle(listHeaderText),
								A2($mdgriffith$elm_ui$Element$row, _List_Nil, listActionButtons)
							])),
						$elm$core$List$isEmpty(entries) ? A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Font$italic,
								$mdgriffith$elm_ui$Element$Font$color(
								$author$project$Colors$onSurface(5)),
								$author$project$Styles$largeFontSize,
								$mdgriffith$elm_ui$Element$paddingEach(
								{g1: 0, h1: 20, iN: 0, jl: 0})
							]),
						$mdgriffith$elm_ui$Element$text(emptyNoticeText)) : A4(
						$author$project$OldTable$oldTable,
						A3($author$project$Pages$WorkTimeTracking$ListElements$sortWorkTimeEntries, workersDict, entriesListType, entries),
						_List_Nil,
						_Utils_ap(
							function () {
								switch (entriesListType.$) {
									case 0:
										return _List_Nil;
									case 1:
										var onToggleRowSelectedStates = entriesListType.a.na;
										var selectedEntryIds = entriesListType.a.n$;
										return A2(
											$elm$core$Maybe$withDefault,
											_List_Nil,
											A2(
												$elm$core$Maybe$map,
												$elm$core$List$singleton,
												A2(
													$elm$core$Maybe$map,
													A2(
														$author$project$Pages$WorkTimeTracking$ListElements$column_rowSelectCheckbox,
														A2(
															$elm$core$List$map,
															function ($) {
																return $.er;
															},
															entries),
														selectedEntryIds),
													onToggleRowSelectedStates)));
									default:
										return _List_Nil;
								}
							}(),
							_Utils_ap(
								function () {
									switch (entriesListType.$) {
										case 0:
											return _List_fromArray(
												[
													$author$project$Pages$WorkTimeTracking$ListElements$column_WorkTimeEntry_on,
													$author$project$Pages$WorkTimeTracking$ListElements$column_worker(workersDict),
													$author$project$Pages$WorkTimeTracking$ListElements$column_worker_compensationType(workersDict),
													$author$project$Pages$WorkTimeTracking$ListElements$column_WorkTimeEntry_entryType,
													$author$project$Pages$WorkTimeTracking$ListElements$column_WorkTimeEntry_balanceEffect,
													$author$project$Pages$WorkTimeTracking$ListElements$column_WorkTimeEntry_reflectInPay,
													$author$project$Pages$WorkTimeTracking$ListElements$column_comment(
													$mdgriffith$elm_ui$Element$px(400))
												]);
										case 1:
											return _List_fromArray(
												[
													$author$project$Pages$WorkTimeTracking$ListElements$column_worker(workersDict),
													$author$project$Pages$WorkTimeTracking$ListElements$column_worker_compensationType(workersDict),
													$author$project$Pages$WorkTimeTracking$ListElements$column_WorkTimeEntry_on,
													$author$project$Pages$WorkTimeTracking$ListElements$column_WorkTimeEntry_entryType,
													$author$project$Pages$WorkTimeTracking$ListElements$column_WorkTimeEntry_balanceEffect,
													$author$project$Pages$WorkTimeTracking$ListElements$column_comment(
													$mdgriffith$elm_ui$Element$px(300))
												]);
										default:
											return _List_fromArray(
												[
													$author$project$Pages$WorkTimeTracking$ListElements$column_WorkTimeEntry_on,
													$author$project$Pages$WorkTimeTracking$ListElements$column_worker(workersDict),
													$author$project$Pages$WorkTimeTracking$ListElements$column_worker_compensationType(workersDict),
													$author$project$Pages$WorkTimeTracking$ListElements$column_WorkTimeEntry_entryType,
													$author$project$Pages$WorkTimeTracking$ListElements$column_WorkTimeEntry_balanceEffect,
													$author$project$Pages$WorkTimeTracking$ListElements$column_WorkTimeEntry_reflectInPay,
													$author$project$Pages$WorkTimeTracking$ListElements$column_comment(
													$mdgriffith$elm_ui$Element$px(400))
												]);
									}
								}(),
								function () {
									switch (entriesListType.$) {
										case 0:
											var onClickEditEntry = entriesListType.a.mM;
											var onClickSignEntry = entriesListType.a.mP;
											return A2(
												$elm$core$List$cons,
												A2($author$project$Pages$WorkTimeTracking$ListElements$column_WorkTimeEntry_editButton, serverRequests, onClickEditEntry),
												userCanSignEntries ? _List_fromArray(
													[
														A2($author$project$Pages$WorkTimeTracking$ListElements$column_WorkTimeEntry_signButton, serverRequests.fo, onClickSignEntry)
													]) : _List_Nil);
										case 1:
											var onClickMarkEntriesAsPaid = entriesListType.a.mO;
											var onClickUnsignEntry = entriesListType.a.mQ;
											return A2(
												$elm$core$List$cons,
												$author$project$Pages$WorkTimeTracking$ListElements$column_WorkTimeEntry_signature,
												userCanSignEntries ? _List_fromArray(
													[
														A2($author$project$Pages$WorkTimeTracking$ListElements$column_WorkTimeEntry_unsignButton, serverRequests.fo, onClickUnsignEntry),
														A2(
														$author$project$Pages$WorkTimeTracking$ListElements$column_WorkTimeEntry_markAsPaidButton,
														serverRequests.fo,
														A2($elm$core$Basics$composeR, $elm$core$List$singleton, onClickMarkEntriesAsPaid))
													]) : _List_Nil);
										default:
											return _List_fromArray(
												[$author$project$Pages$WorkTimeTracking$ListElements$column_WorkTimeEntry_signature]);
									}
								}())),
						_List_Nil)
					])));
	});
var $author$project$Pages$WorkTimeTracking$ListElements$column_WorkTimePayment_balanceEffect = {
	g4: function (_v0) {
		var balanceEffect = _v0.gX;
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$alignRight,
					A2($mdgriffith$elm_ui$Element$paddingXY, 30, 0)
				]),
			$mdgriffith$elm_ui$Element$text(
				function (numberString) {
					return _Utils_ap(
						(balanceEffect > 0) ? '+' : '',
						numberString);
				}(
					A2($author$project$Helpers$floatToString, 1, balanceEffect))));
	},
	hE: $mdgriffith$elm_ui$Element$text('Maksettu, tuntia'),
	dx: _List_Nil,
	fg: $mdgriffith$elm_ui$Element$px(100)
};
var $author$project$Pages$WorkTimeTracking$ListElements$column_WorkTimePayment_created = {
	g4: function (_v0) {
		var createdAt = _v0.d4;
		var createdBy = _v0.kR;
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(8),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$alignLeft]),
					$mdgriffith$elm_ui$Element$text(createdBy)),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$alignRight, $mdgriffith$elm_ui$Element$Font$italic, $author$project$Styles$mediumFontSize]),
					$mdgriffith$elm_ui$Element$text(
						$author$project$Types$IsoTime$toString(createdAt)))
				]));
	},
	hE: $mdgriffith$elm_ui$Element$text('Kuitannut maksetuksi'),
	dx: _List_Nil,
	fg: $mdgriffith$elm_ui$Element$px(220)
};
var $author$project$Helpers$getInDict = F2(
	function (dict, key) {
		return A2($elm$core$Dict$get, key, dict);
	});
var $author$project$Pages$WorkTimeTracking$ListElements$column_WorkTimePayment_entryOn = function (entries) {
	var entriesDict = $elm$core$Dict$fromList(
		A2(
			$elm$core$List$map,
			function (workTimeEntry) {
				return _Utils_Tuple2(workTimeEntry.er, workTimeEntry);
			},
			entries));
	return {
		g4: function (payment) {
			return A2(
				$elm$core$Maybe$withDefault,
				$mdgriffith$elm_ui$Element$text('?'),
				A2(
					$elm$core$Maybe$map,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.mJ;
						},
						A2(
							$elm$core$Basics$composeR,
							$author$project$Pages$WorkTimeTracking$ListElements$view_dayAndDate,
							$mdgriffith$elm_ui$Element$el(
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$centerX])))),
					A2(
						$elm$core$Maybe$andThen,
						$author$project$Helpers$getInDict(entriesDict),
						$elm$core$List$head(payment.ho))));
		},
		hE: $mdgriffith$elm_ui$Element$text('Kirjauksen päivä'),
		dx: _List_Nil,
		fg: $mdgriffith$elm_ui$Element$px(130)
	};
};
var $author$project$Pages$WorkTimeTracking$ListElements$column_WorkTimePayment_on = {
	g4: A2(
		$elm$core$Basics$composeR,
		function ($) {
			return $.mJ;
		},
		A2(
			$elm$core$Basics$composeR,
			$author$project$Pages$WorkTimeTracking$ListElements$view_dayAndDate,
			$mdgriffith$elm_ui$Element$el(
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$centerX])))),
	hE: $mdgriffith$elm_ui$Element$text('Maksupäivä'),
	dx: _List_Nil,
	fg: $mdgriffith$elm_ui$Element$px(130)
};
var $author$project$Pages$WorkTimeTracking$ListElements$element_paymentsArchive = F3(
	function (workers, entries, payments) {
		var workersDict = $elm$core$Dict$fromList(
			A2(
				$elm$core$List$map,
				function (worker) {
					return _Utils_Tuple2(worker.er, worker);
				},
				workers));
		return A2(
			$author$project$Styles$card_wrapper,
			true,
			A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				_List_fromArray(
					[
						$author$project$Styles$cardTitle('Arkisto - maksut'),
						function () {
						if ($elm$core$List$isEmpty(payments)) {
							return A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$italic,
										$mdgriffith$elm_ui$Element$Font$color(
										$author$project$Colors$onSurface(5)),
										$author$project$Styles$largeFontSize,
										$mdgriffith$elm_ui$Element$paddingEach(
										{g1: 0, h1: 20, iN: 0, jl: 0})
									]),
								$mdgriffith$elm_ui$Element$text('arkisto on tyhjä'));
						} else {
							var entriesDict = $elm$core$Dict$fromList(
								A2(
									$elm$core$List$map,
									function (entry) {
										return _Utils_Tuple2(entry.er, entry);
									},
									entries));
							return A4(
								$author$project$OldTable$oldTable,
								$elm$core$List$reverse(
									A2(
										$elm$core$List$sortBy,
										function (payment) {
											var entry = A2(
												$elm$core$Maybe$andThen,
												$author$project$Helpers$getInDict(entriesDict),
												$elm$core$List$head(payment.ho));
											return _Utils_Tuple2(
												$justinmimbs$date$Date$toIsoString(payment.mJ),
												A2(
													$elm$core$Maybe$withDefault,
													'',
													A2(
														$elm$core$Maybe$map,
														A2(
															$elm$core$Basics$composeR,
															function ($) {
																return $.mJ;
															},
															$justinmimbs$date$Date$toIsoString),
														entry)));
										},
										payments)),
								_List_Nil,
								_List_fromArray(
									[
										$author$project$Pages$WorkTimeTracking$ListElements$column_WorkTimePayment_on,
										$author$project$Pages$WorkTimeTracking$ListElements$column_WorkTimePayment_entryOn(entries),
										$author$project$Pages$WorkTimeTracking$ListElements$column_worker(workersDict),
										$author$project$Pages$WorkTimeTracking$ListElements$column_worker_compensationType(workersDict),
										$author$project$Pages$WorkTimeTracking$ListElements$column_WorkTimePayment_balanceEffect,
										$author$project$Pages$WorkTimeTracking$ListElements$column_comment(
										$mdgriffith$elm_ui$Element$px(400)),
										$author$project$Pages$WorkTimeTracking$ListElements$column_WorkTimePayment_created
									]),
								_List_Nil);
						}
					}()
					])));
	});
var $author$project$Pages$WorkTimeTracking$EditEntryDialog$Hide_Triggered = {$: 5};
var $author$project$Pages$WorkTimeTracking$EditEntryDialog$HoursDialog_Hide = {$: 6};
var $author$project$Pages$WorkTimeTracking$EditEntryDialog$HoursDialog_Show = {$: 7};
var $author$project$Pages$WorkTimeTracking$EditEntryDialog$Updated_Comment = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$WorkTimeTracking$EditEntryDialog$Updated_Hours = function (a) {
	return {$: 9, a: a};
};
var $author$project$Pages$WorkTimeTracking$EditEntryDialog$Updated_ReflectInPay = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$WorkTimeTracking$EditEntryDialog$Clicked_Cancel = {$: 0};
var $author$project$Pages$WorkTimeTracking$EditEntryDialog$Clicked_Delete = {$: 1};
var $author$project$Pages$WorkTimeTracking$EditEntryDialog$Clicked_Save = {$: 2};
var $author$project$Icons$trash = $author$project$Icons$fa_icon('fas fa-trash');
var $author$project$Pages$WorkTimeTracking$EditEntryDialog$actionButtonsRow = function (serverRequests) {
	return A2(
		$mdgriffith$elm_ui$Element$row,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$alignRight,
				$mdgriffith$elm_ui$Element$spacing(8),
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
			]),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$alignLeft]),
				$author$project$Components$Button$toElement(
					A2(
						$author$project$Components$Button$tooltip,
						'Poista',
						$author$project$Components$Button$warning(
							A2(
								$author$project$Components$Button$onClick,
								$author$project$Pages$WorkTimeTracking$EditEntryDialog$Clicked_Delete,
								A2(
									$author$project$Components$Button$iconSize,
									14,
									A2(
										$author$project$Components$Button$icon,
										$author$project$Icons$trash,
										A2($author$project$Components$Button$disabled, serverRequests.fo, $author$project$Components$Button$button)))))))),
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$alignRight]),
				$author$project$Components$Button$toElement(
					A2(
						$author$project$Components$Button$onClick,
						$author$project$Pages$WorkTimeTracking$EditEntryDialog$Clicked_Cancel,
						A2($author$project$Components$Button$text, 'Peruuta', $author$project$Components$Button$button)))),
				$author$project$Components$Button$toElement(
				$author$project$Components$Button$primary(
					A2(
						$author$project$Components$Button$onClick,
						$author$project$Pages$WorkTimeTracking$EditEntryDialog$Clicked_Save,
						A2(
							$author$project$Components$Button$text,
							'Tallenna muutokset',
							A2($author$project$Components$Button$disabled, serverRequests.fo, $author$project$Components$Button$button)))))
			]));
};
var $author$project$Pages$WorkTimeTracking$EditEntryDialog$balanceEffectMinMax = function (entryType) {
	switch (entryType) {
		case 0:
			return _Utils_Tuple2(-24, 24);
		case 1:
			return _Utils_Tuple2(0, 24);
		case 3:
			return _Utils_Tuple2(0, 24);
		default:
			return _Utils_Tuple2(0, 24);
	}
};
var $author$project$Pages$WorkTimeTracking$EditEntryDialog$commentInput = F2(
	function (onUpdate, value) {
		return A4(
			$author$project$Components$Input$textInput,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Border$width(1)
				]),
			'Kommentti',
			onUpdate,
			value);
	});
var $author$project$Pages$WorkTimeTracking$EditEntryDialog$correctionTypeInfo = A2(
	$mdgriffith$elm_ui$Element$paragraph,
	_List_fromArray(
		[
			$mdgriffith$elm_ui$Element$alignLeft,
			$mdgriffith$elm_ui$Element$Font$color(
			$author$project$Colors$onSurface(4)),
			$author$project$Styles$mediumFontSize
		]),
	$elm$core$List$singleton(
		$mdgriffith$elm_ui$Element$text('(miinusmerkkinen korjaus vähentää saldoa, plus lisää)')));
var $author$project$Pages$WorkTimeTracking$EditEntryDialog$Updated_EntryType = function (a) {
	return {$: 11, a: a};
};
var $author$project$Icons$radioButtonNotSelected = A2(
	$mdgriffith$elm_ui$Element$el,
	_List_fromArray(
		[
			$mdgriffith$elm_ui$Element$Border$rounded(16),
			$mdgriffith$elm_ui$Element$Border$width(1),
			$mdgriffith$elm_ui$Element$height(
			$mdgriffith$elm_ui$Element$px(16)),
			$mdgriffith$elm_ui$Element$width(
			$mdgriffith$elm_ui$Element$px(16))
		]),
	$mdgriffith$elm_ui$Element$none);
var $author$project$Icons$radioButtonSelected = A2(
	$mdgriffith$elm_ui$Element$el,
	_List_fromArray(
		[
			$mdgriffith$elm_ui$Element$Border$rounded(16),
			$mdgriffith$elm_ui$Element$Border$width(1),
			$mdgriffith$elm_ui$Element$height(
			$mdgriffith$elm_ui$Element$px(16)),
			$mdgriffith$elm_ui$Element$width(
			$mdgriffith$elm_ui$Element$px(16))
		]),
	A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Border$rounded(8),
				$mdgriffith$elm_ui$Element$centerX,
				$mdgriffith$elm_ui$Element$centerY,
				$mdgriffith$elm_ui$Element$height(
				$mdgriffith$elm_ui$Element$px(8)),
				$mdgriffith$elm_ui$Element$width(
				$mdgriffith$elm_ui$Element$px(8)),
				$mdgriffith$elm_ui$Element$Background$color(
				$author$project$Colors$onSurface(5))
			]),
		$mdgriffith$elm_ui$Element$none));
var $author$project$Types$WorkTimeEntry$validEntryTypesForWorkerCompensationType = function (workerCompensationType) {
	if (!workerCompensationType) {
		return _List_fromArray(
			[1, 0]);
	} else {
		return _List_fromArray(
			[2, 3, 0]);
	}
};
var $author$project$Pages$WorkTimeTracking$EditEntryDialog$entryTypeSelector = F2(
	function (workerCompensationType, entry) {
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$alignLeft,
					$mdgriffith$elm_ui$Element$spacing(2)
				]),
			A2(
				$elm$core$List$map,
				function (entryType) {
					return $author$project$Components$Button$toElement(
						A2(
							$author$project$Components$Button$onClick,
							$author$project$Pages$WorkTimeTracking$EditEntryDialog$Updated_EntryType(entryType),
							A2(
								$author$project$Components$Button$icon,
								_Utils_eq(entry.ln, entryType) ? $author$project$Icons$radioButtonSelected : $author$project$Icons$radioButtonNotSelected,
								A2(
									$author$project$Components$Button$text,
									$author$project$Types$WorkTimeEntry$typeToString(entryType),
									$author$project$Components$Button$button))));
				},
				$author$project$Types$WorkTimeEntry$validEntryTypesForWorkerCompensationType(workerCompensationType)));
	});
var $author$project$Pages$WorkTimeTracking$EditEntryDialog$fieldWithLabel = F2(
	function (labelText, element) {
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(8),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$alignLeft,
							$mdgriffith$elm_ui$Element$width(
							$mdgriffith$elm_ui$Element$px(85))
						]),
					$mdgriffith$elm_ui$Element$text(labelText)),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					element)
				]));
	});
var $elm$core$Basics$truncate = _Basics_truncate;
var $author$project$Pages$WorkTimeTracking$EditEntryDialog$hoursOptionLabel = function (floatHours) {
	var fullHours = floatHours | 0;
	var minutes = $elm$core$Basics$round(
		60 * ($elm$core$Basics$abs(floatHours) - $elm$core$Basics$abs(fullHours)));
	return $elm$core$String$fromInt(fullHours) + ('h' + ((minutes > 0) ? (' ' + ($elm$core$String$fromInt(minutes) + 'min')) : ''));
};
var $author$project$Pages$WorkTimeTracking$EditEntryDialog$hoursSelectorButton = F2(
	function (onClick, selectedHours) {
		return $author$project$Components$Button$toElement(
			A2(
				$author$project$Components$Button$setCustomAttributes,
				_Utils_ap(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Border$width(1),
							$mdgriffith$elm_ui$Element$Border$color($author$project$Colors$none)
						]),
					$author$project$Helpers$attributeIf(
						_List_fromArray(
							[
								_Utils_Tuple2(
								!selectedHours,
								$mdgriffith$elm_ui$Element$Border$color($author$project$Colors$red))
							]))),
				A2(
					$author$project$Components$Button$text,
					$author$project$Pages$WorkTimeTracking$EditEntryDialog$hoursOptionLabel(selectedHours),
					A2(
						$author$project$Components$Button$width,
						$mdgriffith$elm_ui$Element$px(100),
						A2($author$project$Components$Button$onClick, onClick, $author$project$Components$Button$button)))));
	});
var $mdgriffith$elm_ui$Element$Input$OnRight = 0;
var $mdgriffith$elm_ui$Element$Input$labelRight = $mdgriffith$elm_ui$Element$Input$Label(0);
var $author$project$Pages$WorkTimeTracking$EditEntryDialog$reflectInPayCheckbox = F2(
	function (onToggle, isChecked) {
		return A3(
			$author$project$Components$Checkbox$checkbox,
			A2(
				$mdgriffith$elm_ui$Element$Input$labelRight,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Font$color(
						$author$project$Colors$onSurface(6))
					]),
				$mdgriffith$elm_ui$Element$text('Huomioidaan palkassa')),
			function (selected) {
				return onToggle(selected);
			},
			isChecked);
	});
var $author$project$Components$Button$TextLeft = 1;
var $author$project$Components$Button$textLeft = F2(
	function (buttonText, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{
				bJ: $elm$core$Maybe$Just(buttonText),
				dB: 1
			});
	});
var $author$project$Pages$WorkTimeTracking$EditEntryDialog$view_hoursSelectionDialog = F4(
	function (onHideTriggered, onSelect, selectedHours, _v0) {
		var minHours = _v0.a;
		var maxHours = _v0.b;
		var hourOptions = F2(
			function (min, max) {
				var itemCount = ((max - min) * 2) + 1;
				return A2(
					$elm$core$List$map,
					function (hour) {
						return {
							ma: $author$project$Pages$WorkTimeTracking$EditEntryDialog$hoursOptionLabel(hour),
							fc: hour
						};
					},
					$elm$core$Array$toList(
						A2(
							$elm$core$Array$initialize,
							itemCount,
							function (i) {
								return min + (i / 2);
							})));
			});
		return A2(
			$author$project$Components$Modal$modal,
			{kz: true, mR: onHideTriggered},
			A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$height(
						$mdgriffith$elm_ui$Element$px(550))
					]),
				A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Background$color($author$project$Colors$background),
							$mdgriffith$elm_ui$Element$padding(4)
						]),
					A2(
						$elm$core$List$map,
						function (hoursOption) {
							return $author$project$Components$Button$toElement(
								A2(
									$author$project$Components$Button$width,
									$mdgriffith$elm_ui$Element$fill,
									A2(
										$author$project$Components$Button$textLeft,
										hoursOption.ma,
										(_Utils_eq(hoursOption.fc, selectedHours) ? $author$project$Components$Button$primary : $elm$core$Basics$identity)(
											A2(
												$author$project$Components$Button$onClick,
												onSelect(hoursOption.fc),
												$author$project$Components$Button$button)))));
						},
						A2(hourOptions, minHours, maxHours)))));
	});
var $author$project$Pages$WorkTimeTracking$EditEntryDialog$view = function (_v0) {
	var dialogState = _v0.c1;
	var entry = _v0.s;
	var serverRequests = _v0.eY;
	var worker = _v0.bR;
	return A2(
		$author$project$Components$Modal$modal,
		{kz: true, mR: $author$project$Pages$WorkTimeTracking$EditEntryDialog$Hide_Triggered},
		A2(
			$author$project$Styles$card_wrapper,
			true,
			A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$author$project$Styles$largeFontSize,
						$mdgriffith$elm_ui$Element$spacing(8),
						$mdgriffith$elm_ui$Element$width(
						$mdgriffith$elm_ui$Element$px(365)),
						$mdgriffith$elm_ui$Element$inFront(
						function () {
							if (!dialogState) {
								return A4(
									$author$project$Pages$WorkTimeTracking$EditEntryDialog$view_hoursSelectionDialog,
									$author$project$Pages$WorkTimeTracking$EditEntryDialog$HoursDialog_Hide,
									$author$project$Pages$WorkTimeTracking$EditEntryDialog$Updated_Hours,
									entry.gX,
									$author$project$Pages$WorkTimeTracking$EditEntryDialog$balanceEffectMinMax(entry.ln));
							} else {
								return $mdgriffith$elm_ui$Element$none;
							}
						}())
					]),
				_List_fromArray(
					[
						$author$project$Styles$cardTitle('Muokkaa kirjausta'),
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$spacing(8),
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
									]),
								_List_fromArray(
									[
										A2(
										$author$project$Pages$WorkTimeTracking$EditEntryDialog$fieldWithLabel,
										'Työntekijä',
										A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$alignLeft,
													$mdgriffith$elm_ui$Element$Font$color(
													$author$project$Colors$onSurface(6)),
													$mdgriffith$elm_ui$Element$spacing(4)
												]),
											$mdgriffith$elm_ui$Element$text(worker.mD))),
										A2(
										$author$project$Pages$WorkTimeTracking$EditEntryDialog$fieldWithLabel,
										'Päivä',
										A2(
											$mdgriffith$elm_ui$Element$row,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$alignLeft,
													$mdgriffith$elm_ui$Element$Font$color(
													$author$project$Colors$onSurface(6)),
													$mdgriffith$elm_ui$Element$spacing(4)
												]),
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$text(
													A2(
														$author$project$Language$weekdayNameFinnish,
														0,
														$justinmimbs$date$Date$weekday(entry.mJ))),
													$mdgriffith$elm_ui$Element$text(
													$author$project$Helpers$dateToStringShort(entry.mJ))
												]))),
										A2(
										$author$project$Pages$WorkTimeTracking$EditEntryDialog$fieldWithLabel,
										'Tyyppi',
										A2($author$project$Pages$WorkTimeTracking$EditEntryDialog$entryTypeSelector, worker.kI, entry)),
										A2(
										$author$project$Pages$WorkTimeTracking$EditEntryDialog$fieldWithLabel,
										'',
										A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$alignBottom,
													A2($mdgriffith$elm_ui$Element$paddingXY, 6, 0)
												]),
											A2($author$project$Pages$WorkTimeTracking$EditEntryDialog$reflectInPayCheckbox, $author$project$Pages$WorkTimeTracking$EditEntryDialog$Updated_ReflectInPay, entry.bF))),
										A2(
										$author$project$Pages$WorkTimeTracking$EditEntryDialog$fieldWithLabel,
										'Tunnit',
										A2(
											$mdgriffith$elm_ui$Element$column,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$spacing(4),
													$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
												]),
											_List_fromArray(
												[
													A2($author$project$Pages$WorkTimeTracking$EditEntryDialog$hoursSelectorButton, $author$project$Pages$WorkTimeTracking$EditEntryDialog$HoursDialog_Show, entry.gX),
													function () {
													var _v2 = entry.ln;
													if (!_v2) {
														return $author$project$Pages$WorkTimeTracking$EditEntryDialog$correctionTypeInfo;
													} else {
														return $mdgriffith$elm_ui$Element$none;
													}
												}()
												]))),
										A2(
										$author$project$Pages$WorkTimeTracking$EditEntryDialog$fieldWithLabel,
										'Kommentti',
										A2(
											$author$project$Pages$WorkTimeTracking$EditEntryDialog$commentInput,
											$author$project$Pages$WorkTimeTracking$EditEntryDialog$Updated_Comment,
											A2($elm$core$Maybe$withDefault, '', entry.kH)))
									]))
							])),
						$author$project$Pages$WorkTimeTracking$EditEntryDialog$actionButtonsRow(serverRequests)
					]))));
};
var $author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$Clicked_Cancel = {$: 0};
var $author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$Clicked_Hide_DatePicker = {$: 3};
var $author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$Clicked_Show_DatePicker = {$: 2};
var $author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$Updated_PaymentComment = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$Clicked_Save = {$: 1};
var $author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$saveAndCancelButtonsRow = function (serverRequests) {
	return A2(
		$mdgriffith$elm_ui$Element$row,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$alignRight,
				$mdgriffith$elm_ui$Element$spacing(8)
			]),
		_List_fromArray(
			[
				$author$project$Components$Button$toElement(
				A2(
					$author$project$Components$Button$onClick,
					$author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$Clicked_Cancel,
					A2($author$project$Components$Button$text, 'Peruuta', $author$project$Components$Button$button))),
				$author$project$Components$Button$toElement(
				$author$project$Components$Button$primary(
					A2(
						$author$project$Components$Button$onClick,
						$author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$Clicked_Save,
						A2(
							$author$project$Components$Button$text,
							'Tallenna muutokset',
							A2($author$project$Components$Button$disabled, serverRequests.fo, $author$project$Components$Button$button)))))
			]));
};
var $author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$view = F2(
	function (serverRequests, _v0) {
		var dialogState = _v0.c1;
		var entries = _v0.lm;
		var paymentComment = _v0.dp;
		var paymentDate = _v0.bC;
		return A2(
			$author$project$Components$Modal$modal,
			{kz: true, mR: $author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$Clicked_Cancel},
			A2(
				$author$project$Styles$card_wrapper,
				true,
				A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$author$project$Styles$largeFontSize,
							$mdgriffith$elm_ui$Element$spacing(8),
							$mdgriffith$elm_ui$Element$width(
							$mdgriffith$elm_ui$Element$px(360)),
							$mdgriffith$elm_ui$Element$inFront(
							function () {
								if (!dialogState.$) {
									var subModel = dialogState.a;
									return A2(
										$author$project$Components$Modal$modal,
										{kz: true, mR: $author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$Clicked_Hide_DatePicker},
										A2(
											$mdgriffith$elm_ui$Element$map,
											$author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$SubMsg_DatePicker,
											$author$project$Components$DatePicker$view(subModel)));
								} else {
									return $mdgriffith$elm_ui$Element$none;
								}
							}())
						]),
					_List_fromArray(
						[
							$author$project$Styles$cardTitle(
							($elm$core$List$length(entries) > 1) ? 'Merkitse kirjaukset maksetuksi' : 'Merkitse kirjaus maksetuksi'),
							A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$spacing(8),
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
								]),
							_List_fromArray(
								[
									A2(
									$author$project$Pages$WorkTimeTracking$EditEntryDialog$fieldWithLabel,
									'Maksupäivä',
									$author$project$Components$Button$toElement(
										A2(
											$author$project$Components$Button$onClick,
											$author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$Clicked_Show_DatePicker,
											A2(
												$author$project$Components$Button$text,
												$author$project$Helpers$dateToStringShort(paymentDate),
												$author$project$Components$Button$button)))),
									A2(
									$author$project$Pages$WorkTimeTracking$EditEntryDialog$fieldWithLabel,
									'Kommentti',
									A2($author$project$Pages$WorkTimeTracking$EditEntryDialog$commentInput, $author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$Updated_PaymentComment, paymentComment))
								])),
							$author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$saveAndCancelButtonsRow(serverRequests)
						]))));
	});
var $author$project$Components$TabbedTable$Internals$TabbedTableInternals$SwitchedToTab = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Tabs$TabsInternals$Tab_ElementLabel = function (a) {
	return {$: 1, a: a};
};
var $author$project$Helpers$addItemToEnd = F2(
	function (x, xs) {
		return _Utils_ap(
			xs,
			_List_fromArray(
				[x]));
	});
var $author$project$Components$TabbedTable$AllItemsTab$getExtraColumns = function (_v0) {
	var options = _v0;
	return options.ec;
};
var $author$project$Components$TabbedTable$AllItemsTab$getIncludeGroupByColumn = function (_v0) {
	var options = _v0;
	return options.es;
};
var $author$project$Components$Table$TableApi$getSorting = function (_v0) {
	var options = _v0;
	return options.i8;
};
var $author$project$Components$Table$TableApi$pushColumns = F2(
	function (columns_, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{
				cP: _Utils_ap(columns_, options.cP)
			});
	});
var $author$project$Components$Table$TableApi$sortByColumn = F2(
	function (columnIndex, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{
				i8: function (a) {
					return _Utils_update(
						a,
						{kF: columnIndex});
				}(options.i8)
			});
	});
var $author$project$Components$TabbedTable$Internals$TabbedTableInternals$allItemsTable = F2(
	function (options, allItemsTab) {
		var includeGroupByColumn = $author$project$Components$TabbedTable$AllItemsTab$getIncludeGroupByColumn(allItemsTab);
		var extraColumns = $author$project$Components$TabbedTable$AllItemsTab$getExtraColumns(allItemsTab);
		return A2(
			$elm$core$Maybe$map,
			function (table) {
				return A2(
					$author$project$Components$Table$TableApi$sortByColumn,
					($author$project$Components$Table$TableApi$getSorting(table).kF + (includeGroupByColumn ? 1 : 0)) + $elm$core$List$length(extraColumns),
					A2(
						$author$project$Components$Table$TableApi$pushColumns,
						includeGroupByColumn ? A2($elm$core$List$cons, options.hC, extraColumns) : extraColumns,
						A2(
							$author$project$Components$Table$TableApi$rows,
							options.jS,
							A2($author$project$Components$Table$TableApi$aggregates, _List_Nil, table))));
			},
			$elm$core$List$head(options.ol));
	});
var $author$project$Components$Badge$countBadgeWithLabel = function (_v0) {
	var label = _v0.ma;
	var count = _v0.kQ;
	return A2(
		$mdgriffith$elm_ui$Element$row,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$spacing(4)
			]),
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$text(label),
				$author$project$Components$Badge$countBadge(count)
			]));
};
var $author$project$Components$TabbedTable$AllItemsTab$getName = function (_v0) {
	var options = _v0;
	return options.mD;
};
var $author$project$Components$Table$Msg$SortByColumn = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Table$Internals$Renderer$column_spacing = 4;
var $author$project$Types$Aggregate$compute = F2(
	function (aggregate, items) {
		return aggregate.jH(items);
	});
var $author$project$Components$Table$ColumnApi$getFooter = function (_v0) {
	var options = _v0;
	return options.ei;
};
var $author$project$Components$Table$ColumnApi$getWidth = function (_v0) {
	var options = _v0;
	return options.fg;
};
var $author$project$Components$Table$Internals$Renderer$footer_cell = F2(
	function (items, column) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					A2($mdgriffith$elm_ui$Element$paddingXY, 2, 2),
					$mdgriffith$elm_ui$Element$width(
					$mdgriffith$elm_ui$Element$px(
						$author$project$Components$Table$ColumnApi$getWidth(column)))
				]),
			A2(
				$elm$core$Maybe$withDefault,
				$mdgriffith$elm_ui$Element$none,
				A2(
					$elm$core$Maybe$map,
					function (footer) {
						var _v0 = footer.cT;
						if (!_v0.$) {
							var customRenderer = _v0.a;
							return customRenderer(items);
						} else {
							return $mdgriffith$elm_ui$Element$text(
								$author$project$Types$Value$toString(
									A2($author$project$Types$Aggregate$compute, footer.jH, items)));
						}
					},
					$author$project$Components$Table$ColumnApi$getFooter(column))));
	});
var $author$project$Components$Table$Internals$Renderer$column_rowNumber_width = $mdgriffith$elm_ui$Element$px(30);
var $author$project$Components$Table$Internals$Renderer$footer_cell_rowNumber = function (_v0) {
	var showRowNumbers = _v0.gj;
	return showRowNumbers ? A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($author$project$Components$Table$Internals$Renderer$column_rowNumber_width)
			]),
		$mdgriffith$elm_ui$Element$none) : $mdgriffith$elm_ui$Element$none;
};
var $author$project$Components$Table$Internals$Renderer$column_rowSelection_width = function (options) {
	return $mdgriffith$elm_ui$Element$px(
		26 + ((!$elm$core$List$isEmpty(options.iY)) ? 30 : 0));
};
var $author$project$Components$Table$Internals$Renderer$footer_cell_rowSelection = function (options) {
	var _v0 = options.gd;
	if (_v0.$ === 1) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width(
					$author$project$Components$Table$Internals$Renderer$column_rowSelection_width(options))
				]),
			$mdgriffith$elm_ui$Element$none);
	} else {
		return $mdgriffith$elm_ui$Element$none;
	}
};
var $author$project$Helpers$hasOnlyNothings = function (list) {
	return $elm$core$List$isEmpty(
		A2($elm$core$List$filterMap, $elm$core$Basics$identity, list));
};
var $author$project$Components$Table$Internals$Renderer$row_padding = A2($mdgriffith$elm_ui$Element$paddingXY, 3, 2);
var $author$project$Components$Table$Internals$Renderer$footer_row = function (options) {
	var footers = A2($elm$core$List$map, $author$project$Components$Table$ColumnApi$getFooter, options.cP);
	return $author$project$Helpers$hasOnlyNothings(footers) ? $mdgriffith$elm_ui$Element$none : A2(
		$mdgriffith$elm_ui$Element$row,
		_List_fromArray(
			[
				$author$project$Components$Table$Internals$Renderer$row_padding,
				$mdgriffith$elm_ui$Element$spacing($author$project$Components$Table$Internals$Renderer$column_spacing)
			]),
		_Utils_ap(
			_List_fromArray(
				[
					$author$project$Components$Table$Internals$Renderer$footer_cell_rowNumber(options),
					$author$project$Components$Table$Internals$Renderer$footer_cell_rowSelection(options)
				]),
			A2(
				$elm$core$List$map,
				$author$project$Components$Table$Internals$Renderer$footer_cell(options.dc),
				options.cP)));
};
var $author$project$Icons$arrowDown = $author$project$Icons$fa_icon('fas fa-arrow-down');
var $author$project$Icons$arrowUp = $author$project$Icons$fa_icon('fas fa-arrow-up');
var $author$project$Icons$arrowsUpDown = $author$project$Icons$fa_icon('fas fa-arrows-up-down');
var $author$project$Colors$darkGrey = A3($mdgriffith$elm_ui$Element$rgb255, 80, 80, 80);
var $author$project$Components$Table$Internals$Renderer$column_sortOrderIndicator = F2(
	function (tableSort, columnIndex) {
		return _Utils_eq(columnIndex, tableSort.kF) ? A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Font$color(
					$author$project$Colors$secondary(0))
				]),
			function () {
				var _v0 = tableSort.n8;
				if (!_v0) {
					return $author$project$Icons$arrowDown;
				} else {
					return $author$project$Icons$arrowUp;
				}
			}()) : A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Font$color($author$project$Colors$darkGrey)
				]),
			$author$project$Icons$arrowsUpDown);
	});
var $author$project$Components$Table$ColumnApi$getTitle = function (_v0) {
	var options = _v0;
	return options.oI;
};
var $author$project$Components$Table$ColumnApi$hasValueFunction = function (_v0) {
	var options = _v0;
	return !_Utils_eq(options.dK, $elm$core$Maybe$Nothing);
};
var $author$project$Components$Table$Internals$Renderer$header_row_rowNumber = function (_v0) {
	var showRowNumbers = _v0.gj;
	return showRowNumbers ? A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($author$project$Components$Table$Internals$Renderer$column_rowNumber_width)
			]),
		$mdgriffith$elm_ui$Element$none) : $mdgriffith$elm_ui$Element$none;
};
var $author$project$Components$Table$Msg$OpenSelectedRowsActionMenu = {$: 3};
var $author$project$Icons$chevronDown = $author$project$Icons$fa_icon('fas fa-chevron-down');
var $author$project$Components$Table$Msg$CloseSelectedRowsActionMenu = {$: 2};
var $author$project$Components$Table$Internals$Renderer$selectedRows_actionMenu = F4(
	function (allItems, selectedItems, msgTransformer, actions) {
		var _v0 = ($elm$core$List$isEmpty(selectedItems) || _Utils_eq(
			$elm$core$List$length(allItems),
			$elm$core$List$length(selectedItems))) ? _Utils_Tuple2(
			allItems,
			'Kaikki ' + ($elm$core$String$fromInt(
				$elm$core$List$length(allItems)) + ' riviä:')) : _Utils_Tuple2(
			selectedItems,
			'Valitut ' + ($elm$core$String$fromInt(
				$elm$core$List$length(selectedItems)) + ' riviä:'));
		var targetItems = _v0.a;
		var headerText = _v0.b;
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$htmlAttribute(
					A2($elm$html$Html$Attributes$style, 'z-index', '9999')),
					$mdgriffith$elm_ui$Element$behindContent(
					A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$htmlAttribute(
								$elm$html$Html$Attributes$tabindex(-1)),
								$mdgriffith$elm_ui$Element$htmlAttribute(
								A2($elm$html$Html$Attributes$style, 'position', 'fixed')),
								$mdgriffith$elm_ui$Element$htmlAttribute(
								A2($elm$html$Html$Attributes$style, 'left', '0')),
								$mdgriffith$elm_ui$Element$htmlAttribute(
								A2($elm$html$Html$Attributes$style, 'top', '0')),
								$mdgriffith$elm_ui$Element$htmlAttribute(
								A2($elm$html$Html$Attributes$style, 'right', '0')),
								$mdgriffith$elm_ui$Element$htmlAttribute(
								A2($elm$html$Html$Attributes$style, 'bottom', '0')),
								$mdgriffith$elm_ui$Element$Events$onClick(
								msgTransformer($author$project$Components$Table$Msg$CloseSelectedRowsActionMenu))
							]),
						$mdgriffith$elm_ui$Element$none))
				]),
			A2(
				$mdgriffith$elm_ui$Element$el,
				$author$project$Components$DropdownMenu$styles,
				A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$spacing(4)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerX, $author$project$Styles$xlFontSize]),
							$mdgriffith$elm_ui$Element$text(headerText)),
							A2(
							$mdgriffith$elm_ui$Element$column,
							_List_Nil,
							A2(
								$elm$core$List$map,
								function (action) {
									return $author$project$Components$Button$toElement(
										A2(
											$author$project$Components$Button$onClick,
											action.cj(targetItems),
											A2($author$project$Components$Button$text, action.mD, $author$project$Components$Button$button)));
								},
								actions)),
							A2(
							$mdgriffith$elm_ui$Element$column,
							_List_Nil,
							A2(
								$elm$core$List$map,
								function (action) {
									return $author$project$Components$DropdownMenu$menuItem(
										{
											cc: $author$project$Icons$key,
											er: 'TODO',
											l0: false,
											mk: $elm$core$Maybe$Nothing,
											cj: action.cj(targetItems),
											bJ: 'Vaihda salasana'
										});
								},
								actions))
						]))));
	});
var $author$project$Components$Table$Internals$Renderer$header_row_rowSelection_actions = F5(
	function (selectedRowActions, allItems, selectedItems, menuState, msgTransformer) {
		if (!selectedRowActions.b) {
			return $mdgriffith$elm_ui$Element$none;
		} else {
			if (!selectedRowActions.b.b) {
				var singleAction = selectedRowActions.a;
				return $author$project$Components$Button$toElement(
					A2(
						$author$project$Components$Button$tooltip,
						A2($elm$core$Maybe$withDefault, '', singleAction.e9),
						$author$project$Components$Button$secondary(
							A2(
								$author$project$Components$Button$onClick,
								singleAction.cj(
									$elm$core$List$isEmpty(selectedItems) ? allItems : selectedItems),
								A2($author$project$Components$Button$icon, singleAction.cc, $author$project$Components$Button$button)))));
			} else {
				var multipleActions = selectedRowActions;
				return A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$inFront(
							function () {
								if (!menuState) {
									return $mdgriffith$elm_ui$Element$none;
								} else {
									return A4($author$project$Components$Table$Internals$Renderer$selectedRows_actionMenu, allItems, selectedItems, msgTransformer, multipleActions);
								}
							}())
						]),
					A2(
						$mdgriffith$elm_ui$Element$map,
						msgTransformer,
						$author$project$Components$Button$toElement(
							A2(
								$author$project$Components$Button$onClick,
								$author$project$Components$Table$Msg$OpenSelectedRowsActionMenu,
								A2($author$project$Components$Button$icon, $author$project$Icons$chevronDown, $author$project$Components$Button$button)))));
			}
		}
	});
var $author$project$Components$Table$Msg$DeselectAllRows = {$: 5};
var $author$project$Components$Table$Msg$SelectAllRows = {$: 4};
var $author$project$Components$Table$Internals$Renderer$header_row_rowSelection_checkAll = F2(
	function (options, selectedItems) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[$mdgriffith$elm_ui$Element$centerX]),
			A2(
				$mdgriffith$elm_ui$Element$map,
				options.f_,
				A3(
					$author$project$Components$Checkbox$checkbox,
					$mdgriffith$elm_ui$Element$Input$labelHidden('Valitse kaikki rivit'),
					function (wasChecked) {
						return wasChecked ? $author$project$Components$Table$Msg$SelectAllRows : $author$project$Components$Table$Msg$DeselectAllRows;
					},
					_Utils_eq(
						$elm$core$List$length(selectedItems),
						$elm$core$List$length(options.dc)))));
	});
var $author$project$Components$Table$Internals$Renderer$header_row_rowSelection = function (options) {
	var _v0 = options.gd;
	if (_v0.$ === 1) {
		var menuState = _v0.a;
		var selectedItems = _v0.b;
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					A2($mdgriffith$elm_ui$Element$paddingXY, 0, 6),
					$mdgriffith$elm_ui$Element$width(
					$author$project$Components$Table$Internals$Renderer$column_rowSelection_width(options))
				]),
			_List_fromArray(
				[
					A2($author$project$Components$Table$Internals$Renderer$header_row_rowSelection_checkAll, options, selectedItems),
					A5($author$project$Components$Table$Internals$Renderer$header_row_rowSelection_actions, options.iY, options.dc, selectedItems, menuState, options.f_)
				]));
	} else {
		return $mdgriffith$elm_ui$Element$none;
	}
};
var $author$project$Components$Table$Internals$Renderer$header_row = F2(
	function (onClickColumnTitle, options) {
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Background$color($author$project$Colors$background),
					$mdgriffith$elm_ui$Element$Border$widthEach(
					{g1: 1, h1: 0, iN: 0, jl: 1}),
					$mdgriffith$elm_ui$Element$Font$bold,
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$author$project$Components$Table$Internals$Renderer$row_padding,
					$mdgriffith$elm_ui$Element$spacing($author$project$Components$Table$Internals$Renderer$column_spacing)
				]),
			_Utils_ap(
				_List_fromArray(
					[
						$author$project$Components$Table$Internals$Renderer$header_row_rowNumber(options),
						$author$project$Components$Table$Internals$Renderer$header_row_rowSelection(options)
					]),
				A2(
					$elm$core$List$indexedMap,
					F2(
						function (columnIndex, column) {
							return $author$project$Components$Table$ColumnApi$hasValueFunction(column) ? A2(
								$mdgriffith$elm_ui$Element$row,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$width(
										$mdgriffith$elm_ui$Element$px(
											$author$project$Components$Table$ColumnApi$getWidth(column)))
									]),
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$map,
										options.f_,
										A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$alignBottom,
													$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
												]),
											$author$project$Components$Button$toElement(
												A2(
													$author$project$Components$Button$onClick,
													onClickColumnTitle(columnIndex),
													A2(
														$author$project$Components$Button$width,
														$mdgriffith$elm_ui$Element$fill,
														A2(
															$author$project$Components$Button$textColor,
															$author$project$Colors$onBackground,
															A2(
																$author$project$Components$Button$fontSize,
																14,
																$author$project$Components$Button$breakTextToMultipleLines(
																	A2(
																		$author$project$Components$Button$text,
																		A2(
																			$elm$core$Maybe$withDefault,
																			'',
																			$author$project$Components$Table$ColumnApi$getTitle(column)),
																		$author$project$Components$Button$button))))))))),
										A2(
										$mdgriffith$elm_ui$Element$map,
										options.f_,
										A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[$mdgriffith$elm_ui$Element$alignBottom, $mdgriffith$elm_ui$Element$alignRight]),
											$author$project$Components$Button$toElement(
												A2(
													$author$project$Components$Button$iconRight,
													A2($author$project$Components$Table$Internals$Renderer$column_sortOrderIndicator, options.i8, columnIndex),
													A2(
														$author$project$Components$Button$iconSize,
														14,
														A2(
															$author$project$Components$Button$iconColor,
															$author$project$Colors$onBackground,
															A2(
																$author$project$Components$Button$onClick,
																onClickColumnTitle(columnIndex),
																$author$project$Components$Button$button)))))))
									])) : A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$alignBottom,
										$mdgriffith$elm_ui$Element$Font$color($author$project$Colors$onBackground),
										$mdgriffith$elm_ui$Element$Font$size(14),
										A2($mdgriffith$elm_ui$Element$paddingXY, 0, 6),
										$mdgriffith$elm_ui$Element$width(
										$mdgriffith$elm_ui$Element$px(
											$author$project$Components$Table$ColumnApi$getWidth(column)))
									]),
								$mdgriffith$elm_ui$Element$text(
									A2(
										$elm$core$Maybe$withDefault,
										'',
										$author$project$Components$Table$ColumnApi$getTitle(column))));
						}),
					options.cP)));
	});
var $author$project$Components$Table$Internals$TableInternals$createRow = F2(
	function (rowIndex, rowItem) {
		return {db: rowItem, nO: rowIndex + 1};
	});
var $author$project$Components$Table$Msg$RowSelectionToggled = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Helpers$isInList = F2(
	function (list, item) {
		return !_Utils_eq(
			$elm$core$Maybe$Nothing,
			A2(
				$elm_community$list_extra$List$Extra$find,
				$elm$core$Basics$eq(item),
				list));
	});
var $author$project$Components$Table$ColumnApi$getButtonFunctions = function (_v0) {
	var options = _v0;
	return options.dW;
};
var $author$project$Components$Table$Internals$Renderer$row_cell_buttons = F2(
	function (row_, column) {
		return function (buttonFunctions) {
			return $elm$core$List$isEmpty(buttonFunctions) ? $mdgriffith$elm_ui$Element$none : A2(
				$mdgriffith$elm_ui$Element$row,
				_List_Nil,
				A2(
					$elm$core$List$map,
					function (buttonFunction) {
						return $author$project$Components$Button$toElement(
							buttonFunction(row_.db));
					},
					buttonFunctions));
		}(
			$author$project$Components$Table$ColumnApi$getButtonFunctions(column));
	});
var $author$project$Components$Table$ColumnApi$getCustomRenderer = function (_v0) {
	var options = _v0;
	return options.cT;
};
var $author$project$Components$Table$Internals$Renderer$row_cell_dateTime = function (isoTime) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[$mdgriffith$elm_ui$Element$alignRight, $mdgriffith$elm_ui$Element$Font$italic, $author$project$Styles$mediumFontSize]),
		$mdgriffith$elm_ui$Element$text(
			$author$project$Types$IsoTime$toString(isoTime)));
};
var $author$project$Components$Table$Internals$Renderer$row_cell_dayAndDate = function (date) {
	return A2(
		$mdgriffith$elm_ui$Element$row,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width(
				$mdgriffith$elm_ui$Element$px(105))
			]),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$alignLeft]),
				$mdgriffith$elm_ui$Element$text(
					$author$project$Language$dateToWeekdayFinAbbreviation(date))),
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$alignRight]),
				$mdgriffith$elm_ui$Element$text(
					$author$project$Helpers$dateToStringLong(date)))
			]));
};
var $author$project$Components$Table$Internals$Renderer$row_cell_float = F2(
	function (decimals, _float) {
		return A2(
			$mdgriffith$elm_ui$Element$paragraph,
			_List_Nil,
			$elm$core$List$singleton(
				$mdgriffith$elm_ui$Element$text(
					A2($author$project$Helpers$floatToString, decimals, _float))));
	});
var $author$project$Components$Table$Internals$Renderer$row_cell_string = function (string) {
	return A2(
		$mdgriffith$elm_ui$Element$paragraph,
		_List_fromArray(
			[$mdgriffith$elm_ui$Element$Font$alignLeft]),
		$elm$core$List$singleton(
			$mdgriffith$elm_ui$Element$text(string)));
};
var $author$project$Components$Table$Internals$Renderer$row_cell_render_value = function (value) {
	switch (value.$) {
		case 0:
			var date = value.a;
			return $author$project$Components$Table$Internals$Renderer$row_cell_dayAndDate(date);
		case 1:
			var isoTime = value.a;
			return $author$project$Components$Table$Internals$Renderer$row_cell_dateTime(isoTime);
		case 2:
			var decimals = value.a;
			var _float = value.b;
			return A2($author$project$Components$Table$Internals$Renderer$row_cell_float, decimals, _float);
		default:
			var string = value.a;
			return $author$project$Components$Table$Internals$Renderer$row_cell_string(string);
	}
};
var $author$project$Components$Table$Internals$Renderer$row_cell_value = F2(
	function (row_, column) {
		var _v0 = $author$project$Components$Table$ColumnApi$getCustomRenderer(column);
		if (!_v0.$) {
			var customRenderer = _v0.a;
			return customRenderer(row_.db);
		} else {
			return A2(
				$elm$core$Maybe$withDefault,
				$mdgriffith$elm_ui$Element$none,
				A2(
					$elm$core$Maybe$map,
					$author$project$Components$Table$Internals$Renderer$row_cell_render_value,
					function (cellValue) {
						if (cellValue.$ === 1) {
							var value = cellValue.a;
							return $elm$core$Maybe$Just(value);
						} else {
							var maybeValue = cellValue.a;
							return maybeValue;
						}
					}(
						A2($author$project$Components$Table$Internals$TableInternals$cellValueInColumn, column, row_.db))));
		}
	});
var $author$project$Components$Table$Internals$Renderer$row_cell = F2(
	function (row_, column) {
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					A2($mdgriffith$elm_ui$Element$paddingXY, 2, 0),
					$mdgriffith$elm_ui$Element$width(
					$mdgriffith$elm_ui$Element$px(
						$author$project$Components$Table$ColumnApi$getWidth(column)))
				]),
			$author$project$Components$Table$ColumnApi$hasValueFunction(column) ? _List_fromArray(
				[
					A2($author$project$Components$Table$Internals$Renderer$row_cell_value, row_, column),
					A2($author$project$Components$Table$Internals$Renderer$row_cell_buttons, row_, column)
				]) : _List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$centerX]),
					A2($author$project$Components$Table$Internals$Renderer$row_cell_buttons, row_, column))
				]));
	});
var $author$project$Components$Table$Internals$Renderer$row_cell_rowNumber = function (row) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($author$project$Components$Table$Internals$Renderer$column_rowNumber_width)
			]),
		$mdgriffith$elm_ui$Element$text(
			$author$project$Helpers$orderNumberString(row.nO)));
};
var $author$project$Components$Table$Internals$Renderer$row_selection_checkbox = F3(
	function (item, onStateChange, isChecked) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[$mdgriffith$elm_ui$Element$centerX]),
			A3(
				$author$project$Components$Checkbox$checkbox,
				$mdgriffith$elm_ui$Element$Input$labelHidden('Valitse rivi'),
				onStateChange(item),
				isChecked));
	});
var $author$project$Components$Table$Internals$Renderer$row_render = F2(
	function (options, row_) {
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Background$color($author$project$Colors$none),
					$mdgriffith$elm_ui$Element$Border$widthEach(
					{g1: 1, h1: 0, iN: 0, jl: 0}),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$author$project$Components$Table$Internals$Renderer$row_padding,
					$mdgriffith$elm_ui$Element$spacing($author$project$Components$Table$Internals$Renderer$column_spacing)
				]),
			_Utils_ap(
				_List_fromArray(
					[
						options.gj ? $author$project$Components$Table$Internals$Renderer$row_cell_rowNumber(row_) : $mdgriffith$elm_ui$Element$none,
						function () {
						var _v0 = options.gd;
						if (_v0.$ === 1) {
							var selectedItems = _v0.b;
							return A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Element$paddingXY, 2, 0),
										$mdgriffith$elm_ui$Element$width(
										$author$project$Components$Table$Internals$Renderer$column_rowSelection_width(options))
									]),
								A2(
									$mdgriffith$elm_ui$Element$map,
									options.f_,
									A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[$mdgriffith$elm_ui$Element$alignLeft]),
										A3(
											$author$project$Components$Table$Internals$Renderer$row_selection_checkbox,
											row_.db,
											$author$project$Components$Table$Msg$RowSelectionToggled,
											A2($author$project$Helpers$isInList, selectedItems, row_.db)))));
						} else {
							return $mdgriffith$elm_ui$Element$none;
						}
					}()
					]),
				A2(
					$elm$core$List$map,
					$author$project$Components$Table$Internals$Renderer$row_cell(row_),
					options.cP)));
	});
var $author$project$Components$Table$Internals$TableInternals$valueInColumn = F2(
	function (item, column) {
		return A3(
			$elm$core$Basics$apR,
			$author$project$Components$Table$ColumnApi$getValueFunction(column),
			$elm$core$Maybe$withDefault(
				$elm$core$Basics$always(
					$author$project$Components$Table$ColumnApi$MaybeCellValue($elm$core$Maybe$Nothing))),
			item);
	});
var $author$project$Components$Table$Internals$TableInternals$sortItems = F3(
	function (columns, _v0, items) {
		var columnIndex = _v0.kF;
		var sortOrder = _v0.n8;
		return function () {
			if (!sortOrder) {
				return $elm$core$Basics$identity;
			} else {
				return $elm$core$List$reverse;
			}
		}()(
			A2(
				$elm$core$List$sortBy,
				function (item) {
					return $author$project$Components$Table$Internals$TableInternals$cellValue_toString(
						A2(
							$elm$core$Maybe$withDefault,
							$author$project$Components$Table$ColumnApi$MaybeCellValue($elm$core$Maybe$Nothing),
							A2(
								$elm$core$Maybe$map,
								$author$project$Components$Table$Internals$TableInternals$valueInColumn(item),
								A2($elm_community$list_extra$List$Extra$getAt, columnIndex, columns))));
				},
				items));
	});
var $author$project$Components$Table$Internals$Renderer$rows = function (options) {
	return A2(
		$elm$core$List$map,
		$author$project$Components$Table$Internals$Renderer$row_render(options),
		A2(
			$elm$core$List$indexedMap,
			$author$project$Components$Table$Internals$TableInternals$createRow,
			A3($author$project$Components$Table$Internals$TableInternals$sortItems, options.cP, options.i8, options.dc)));
};
var $author$project$Components$Table$Internals$Renderer$render_table = F2(
	function (onClickColumnTitle, options) {
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_Nil,
			A2(
				$elm$core$List$cons,
				A2($author$project$Components$Table$Internals$Renderer$header_row, onClickColumnTitle, options),
				_Utils_ap(
					$author$project$Components$Table$Internals$Renderer$rows(options),
					_List_fromArray(
						[
							$author$project$Components$Table$Internals$Renderer$footer_row(options)
						]))));
	});
var $author$project$Types$Aggregate$name = function (aggregate) {
	return aggregate.mD;
};
var $author$project$Types$Aggregate$render = F2(
	function (items, aggregate) {
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(4)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$Font$bold]),
					$mdgriffith$elm_ui$Element$text(
						A2(
							$author$project$Helpers$suffix,
							':',
							$author$project$Types$Aggregate$name(aggregate)))),
					$mdgriffith$elm_ui$Element$text(
					$author$project$Types$Value$toString(
						A2($author$project$Types$Aggregate$compute, aggregate, items)))
				]));
	});
var $author$project$Components$Table$Internals$Renderer$title_row = function (options) {
	return A2(
		$mdgriffith$elm_ui$Element$row,
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Element$paddingXY, 2, 0),
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
			]),
		_List_fromArray(
			[
				options.hI ? $mdgriffith$elm_ui$Element$none : A2(
				$elm$core$Maybe$withDefault,
				$mdgriffith$elm_ui$Element$none,
				A2(
					$elm$core$Maybe$map,
					A2(
						$elm$core$Basics$composeR,
						$mdgriffith$elm_ui$Element$text,
						$mdgriffith$elm_ui$Element$el(
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$Font$alignLeft, $mdgriffith$elm_ui$Element$Font$bold, $author$project$Styles$xxlFontSize]))),
					options.oI)),
				A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$spacing(4),
						(_Utils_eq(options.oI, $elm$core$Maybe$Nothing) || options.hI) ? $mdgriffith$elm_ui$Element$alignLeft : $mdgriffith$elm_ui$Element$alignRight
					]),
				A2(
					$elm$core$List$map,
					$author$project$Types$Aggregate$render(options.dc),
					options.jI))
			]));
};
var $author$project$Components$Table$Internals$Renderer$render = F2(
	function (onClickColumnTitle, options) {
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$author$project$Styles$largeFontSize,
					$mdgriffith$elm_ui$Element$padding(2),
					$mdgriffith$elm_ui$Element$spacing(8)
				]),
			_List_fromArray(
				[
					$author$project$Components$Table$Internals$Renderer$title_row(options),
					A2($author$project$Components$Table$Internals$Renderer$render_table, onClickColumnTitle, options)
				]));
	});
var $author$project$Components$Table$TableApi$render = function (_v0) {
	var options = _v0;
	return A2($author$project$Components$Table$Internals$Renderer$render, $author$project$Components$Table$Msg$SortByColumn, options);
};
var $author$project$Components$TabbedTable$Internals$TabbedTableInternals$allItemsTabToTab = F2(
	function (options, allItemsTab) {
		return {
			d3: A2(
				$elm$core$Maybe$withDefault,
				$mdgriffith$elm_ui$Element$none,
				A2(
					$elm$core$Maybe$map,
					$author$project$Components$Table$TableApi$render,
					A2($author$project$Components$TabbedTable$Internals$TabbedTableInternals$allItemsTable, options, allItemsTab))),
			ma: $author$project$Components$Tabs$TabsInternals$Tab_ElementLabel(
				$author$project$Components$Badge$countBadgeWithLabel(
					{
						kQ: $elm$core$List$length(options.jS),
						ma: $author$project$Components$TabbedTable$AllItemsTab$getName(allItemsTab)
					}))
		};
	});
var $author$project$Components$Table$TableApi$getRowCount = function (_v0) {
	var options = _v0;
	return $elm$core$List$length(options.dc);
};
var $author$project$Components$Table$TableApi$getTitle = function (_v0) {
	var options = _v0;
	return options.oI;
};
var $author$project$Components$TabbedTable$Internals$Renderer$buildTabs = function (options) {
	return A3(
		$elm$core$Basics$apR,
		A2(
			$elm$core$Maybe$map,
			$author$project$Helpers$addItemToEnd,
			A2(
				$elm$core$Maybe$map,
				$author$project$Components$TabbedTable$Internals$TabbedTableInternals$allItemsTabToTab(options),
				options.jT)),
		$elm$core$Maybe$withDefault($author$project$Helpers$doNothing),
		A2(
			$elm$core$List$map,
			function (table) {
				return {
					d3: $author$project$Components$Table$TableApi$render(table),
					ma: $author$project$Components$Tabs$TabsInternals$Tab_ElementLabel(
						$author$project$Components$Badge$countBadgeWithLabel(
							{
								kQ: $author$project$Components$Table$TableApi$getRowCount(table),
								ma: A2(
									$elm$core$Maybe$withDefault,
									'',
									$author$project$Components$Table$TableApi$getTitle(table))
							}))
				};
			},
			options.ol));
};
var $author$project$Components$Tabs$Renderer$renderTabLabel = function (label) {
	if (!label.$) {
		var string = label.a;
		return $mdgriffith$elm_ui$Element$text(string);
	} else {
		var element = label.a;
		return element;
	}
};
var $author$project$Components$TabbedTable$Tabulator$renderTabSelector = F5(
	function (msgTransformer, onSwitchToTab, selectedTabIndex, thisTabIndex, thisTab) {
		var commonAttributes = _List_fromArray(
			[$mdgriffith$elm_ui$Element$Font$bold]);
		return _Utils_eq(selectedTabIndex, thisTabIndex) ? A2(
			$mdgriffith$elm_ui$Element$el,
			_Utils_ap(
				_List_fromArray(
					[
						$author$project$Styles$largeFontSize,
						A2($mdgriffith$elm_ui$Element$paddingXY, 4, 0)
					]),
				commonAttributes),
			A2(
				$mdgriffith$elm_ui$Element$map,
				msgTransformer,
				$author$project$Components$Tabs$Renderer$renderTabLabel(thisTab.ma))) : $author$project$Components$Button$toElement(
			A2(
				$author$project$Components$Button$setCustomAttributes,
				_Utils_ap(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Background$color($author$project$Colors$background),
							$mdgriffith$elm_ui$Element$Border$roundEach(
							{ki: 0, kj: 0, oL: 8, oM: 8})
						]),
					commonAttributes),
				A2(
					$author$project$Components$Button$fontSize,
					16,
					A2(
						$author$project$Components$Button$onClick,
						onSwitchToTab(thisTabIndex),
						A2(
							$author$project$Components$Button$icon,
							A2(
								$mdgriffith$elm_ui$Element$map,
								msgTransformer,
								$author$project$Components$Tabs$Renderer$renderTabLabel(thisTab.ma)),
							$author$project$Components$Button$button)))));
	});
var $author$project$Components$TabbedTable$Tabulator$tabulator = function (_v0) {
	var allTabs = _v0.jU;
	var msgTransformer = _v0.f_;
	var onSwitchToTab = _v0.m7;
	var selectedTabIndex = _v0.iZ;
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$spacing(2)
			]),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$wrappedRow,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$spacing(6)
					]),
				A2(
					$elm$core$List$indexedMap,
					F2(
						function (tabIndex, tab) {
							return A5($author$project$Components$TabbedTable$Tabulator$renderTabSelector, msgTransformer, onSwitchToTab, selectedTabIndex, tabIndex, tab);
						}),
					allTabs)),
				A2(
				$elm$core$Maybe$withDefault,
				$mdgriffith$elm_ui$Element$none,
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.d3;
					},
					A2($elm_community$list_extra$List$Extra$getAt, selectedTabIndex, allTabs)))
			]));
};
var $author$project$Components$TabbedTable$Internals$Renderer$title_row = function (options) {
	return A2(
		$mdgriffith$elm_ui$Element$row,
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Element$paddingXY, 2, 0),
				$mdgriffith$elm_ui$Element$spacing(4)
			]),
		_List_fromArray(
			[
				A2(
				$elm$core$Maybe$withDefault,
				$mdgriffith$elm_ui$Element$none,
				A2(
					$elm$core$Maybe$map,
					A2(
						$elm$core$Basics$composeR,
						$mdgriffith$elm_ui$Element$text,
						$mdgriffith$elm_ui$Element$el(
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$alignLeft,
									$mdgriffith$elm_ui$Element$Font$bold,
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$author$project$Styles$xxlFontSize
								]))),
					options.oI))
			]));
};
var $author$project$Components$TabbedTable$Internals$Renderer$render = function (options) {
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$author$project$Styles$largeFontSize,
				$mdgriffith$elm_ui$Element$padding(2),
				$mdgriffith$elm_ui$Element$spacing(6)
			]),
		_List_fromArray(
			[
				$author$project$Components$TabbedTable$Internals$Renderer$title_row(options),
				$elm$core$List$isEmpty(options.jS) ? A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Element$paddingXY, 8, 0)
					]),
				$mdgriffith$elm_ui$Element$text('Ei rivejä')) : $author$project$Components$TabbedTable$Tabulator$tabulator(
				{
					jU: $author$project$Components$TabbedTable$Internals$Renderer$buildTabs(options),
					f_: A2($elm$core$Basics$composeR, $author$project$Components$TabbedTable$Internals$TabbedTableInternals$SubModuleMsg_Tabs, options.f_),
					m7: A2($elm$core$Basics$composeR, $author$project$Components$TabbedTable$Internals$TabbedTableInternals$SwitchedToTab, options.f_),
					iZ: options.iZ
				})
			]));
};
var $author$project$Components$TabbedTable$TabbedTableApi$render = function (_v0) {
	var options = _v0;
	return $author$project$Components$TabbedTable$Internals$Renderer$render(options);
};
var $author$project$Pages$WorkTimeTracking$State_Main$view_paymentQueue = function (model) {
	return A2(
		$author$project$Styles$card_wrapper,
		true,
		$author$project$Components$TabbedTable$TabbedTableApi$render(model.a9));
};
var $author$project$Utils$MiscUtils$flatten = A2(
	$elm$core$List$foldr,
	F2(
		function (elements, carry) {
			return _Utils_ap(elements, carry);
		}),
	_List_Nil);
var $elm_community$list_extra$List$Extra$groupWhile = F2(
	function (isSameGroup, items) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					if (!acc.b) {
						return _List_fromArray(
							[
								_Utils_Tuple2(x, _List_Nil)
							]);
					} else {
						var _v1 = acc.a;
						var y = _v1.a;
						var restOfGroup = _v1.b;
						var groups = acc.b;
						return A2(isSameGroup, x, y) ? A2(
							$elm$core$List$cons,
							_Utils_Tuple2(
								x,
								A2($elm$core$List$cons, y, restOfGroup)),
							groups) : A2(
							$elm$core$List$cons,
							_Utils_Tuple2(x, _List_Nil),
							acc);
					}
				}),
			_List_Nil,
			items);
	});
var $author$project$Utils$MiscUtils$groupByFirstCharacter = F2(
	function (itemToString, items) {
		return A2(
			$elm_community$list_extra$List$Extra$groupWhile,
			F2(
				function (itemA, itemB) {
					return _Utils_eq(
						A2(
							$elm$core$String$left,
							1,
							itemToString(itemA)),
						A2(
							$elm$core$String$left,
							1,
							itemToString(itemB)));
				}),
			A2($elm$core$List$sortBy, itemToString, items));
	});
var $elm_community$list_extra$List$Extra$greedyGroupsOfWithStep = F3(
	function (size, step, xs) {
		var xs_ = A2($elm$core$List$drop, step, xs);
		var okayXs = $elm$core$List$length(xs) > 0;
		var okayArgs = (size > 0) && (step > 0);
		return (okayArgs && okayXs) ? A2(
			$elm$core$List$cons,
			A2($elm$core$List$take, size, xs),
			A3($elm_community$list_extra$List$Extra$greedyGroupsOfWithStep, size, step, xs_)) : _List_Nil;
	});
var $elm_community$list_extra$List$Extra$greedyGroupsOf = F2(
	function (size, xs) {
		return A3($elm_community$list_extra$List$Extra$greedyGroupsOfWithStep, size, size, xs);
	});
var $author$project$Utils$MiscUtils$splitListIntoSizesOf = F2(
	function (columnCount, items) {
		var itemCount = $elm$core$List$length(items) + 1;
		var rowCount = ((itemCount / columnCount) | 0) + ((A2($elm$core$Basics$modBy, columnCount, itemCount) > 0) ? 1 : 0);
		return A2($elm_community$list_extra$List$Extra$greedyGroupsOf, rowCount, items);
	});
var $author$project$Pages$WorkTimeTracking$State_Main$Clicked_Worker = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$WorkTimeTracking$State_Main$view_workerButton = function (worker) {
	return $author$project$Components$Button$toElement(
		A2(
			$author$project$Components$Button$setCustomAttributes,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$padding(1)
				]),
			A2(
				$author$project$Components$Button$width,
				$mdgriffith$elm_ui$Element$fill,
				A2(
					$author$project$Components$Button$textLeft,
					worker.mD,
					A2(
						$author$project$Components$Button$onClick,
						$author$project$Pages$WorkTimeTracking$State_Main$Clicked_Worker(worker),
						$author$project$Components$Button$button)))));
};
var $author$project$Pages$WorkTimeTracking$State_Main$view_workerAlphabeticGroup_items = function (_v0) {
	var firstWorkerInGroup = _v0.a;
	var rest = _v0.b;
	var firstLetterInGroup = A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$Font$bold]),
		$mdgriffith$elm_ui$Element$text(
			A2($elm$core$String$left, 1, firstWorkerInGroup.mD)));
	return A2(
		$elm$core$List$cons,
		firstLetterInGroup,
		A2(
			$elm$core$List$map,
			$author$project$Pages$WorkTimeTracking$State_Main$view_workerButton,
			A2($elm$core$List$cons, firstWorkerInGroup, rest)));
};
var $author$project$Types$WorkTimeEntry$HoursBalance = 0;
var $author$project$Types$WorkTimeEntry$OpenPaymentsBalance = 1;
var $author$project$Types$WorkTimeEntry$sum = F2(
	function (balanceType, events) {
		switch (balanceType) {
			case 3:
				return A2($author$project$Types$WorkTimeEntry$sum, 0, events) + A2($author$project$Types$WorkTimeEntry$sum, 1, events);
			case 2:
				return $elm$core$List$sum(
					A2(
						$elm$core$List$map,
						function ($) {
							return $.gX;
						},
						events));
			case 0:
				return $elm$core$List$sum(
					A2(
						$elm$core$List$map,
						function ($) {
							return $.gX;
						},
						A2(
							$elm$core$List$filter,
							A2($elm$core$Basics$composeL, $elm$core$Basics$not, $author$project$Types$WorkTimeEntry$isReflectInPay),
							events)));
			default:
				return $elm$core$List$sum(
					A2(
						$elm$core$List$map,
						function ($) {
							return $.gX;
						},
						A2(
							$elm$core$List$filter,
							$author$project$Types$WorkTimeEntry$isReflectInPay,
							A2($elm$core$List$filter, $author$project$Types$WorkTimeEntry$isNotPaid, events))));
		}
	});
var $author$project$Pages$WorkTimeTracking$State_Main$view_workerNamesAndBalances = F2(
	function (entries, workers) {
		var openPaymentsSum = function (worker) {
			return A2(
				$author$project$Types$WorkTimeEntry$sum,
				1,
				A2(
					$elm$core$List$filter,
					$author$project$Types$WorkTimeEntry$isNotPaid,
					A2(
						$elm$core$List$filter,
						function (entry) {
							return _Utils_eq(entry.jy, worker.er);
						},
						entries)));
		};
		var hoursBalanceSum = function (worker) {
			return A2(
				$author$project$Types$WorkTimeEntry$sum,
				0,
				A2(
					$elm$core$List$filter,
					function (entry) {
						return _Utils_eq(entry.jy, worker.er);
					},
					entries));
		};
		var totalSum = function (worker) {
			return hoursBalanceSum(worker) + openPaymentsSum(worker);
		};
		var headerElement = A2(
			$elm$core$Basics$composeR,
			$elm$core$List$map($mdgriffith$elm_ui$Element$text),
			A2(
				$elm$core$Basics$composeR,
				$elm$core$List$map(
					$mdgriffith$elm_ui$Element$el(
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$centerX]))),
				$mdgriffith$elm_ui$Element$column(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$centerX,
							$mdgriffith$elm_ui$Element$spacing(2)
						]))));
		var floatToTextWithColor = function (_float) {
			return A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Font$color(
						(_float < 0) ? $author$project$Colors$red : ((_float > 0) ? $author$project$Colors$green : $author$project$Colors$onSurface(5)))
					]),
				$mdgriffith$elm_ui$Element$text(
					A2($author$project$Helpers$floatToString, 1, _float)));
		};
		return $elm$core$List$isEmpty(workers) ? $mdgriffith$elm_ui$Element$none : A4(
			$author$project$OldTable$oldTable,
			A2(
				$elm$core$List$sortBy,
				function ($) {
					return $.mD;
				},
				workers),
			_List_Nil,
			_List_fromArray(
				[
					{
					g4: A2(
						$elm$core$Basics$composeR,
						$author$project$Pages$WorkTimeTracking$State_Main$view_workerButton,
						$mdgriffith$elm_ui$Element$el(
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$alignLeft,
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
								]))),
					hE: $mdgriffith$elm_ui$Element$text('Nimi'),
					dx: _List_Nil,
					fg: $mdgriffith$elm_ui$Element$px(230)
				},
					{
					g4: A2(
						$elm$core$Basics$composeR,
						hoursBalanceSum,
						A2(
							$elm$core$Basics$composeR,
							floatToTextWithColor,
							$mdgriffith$elm_ui$Element$el(
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$alignRight,
										A2($mdgriffith$elm_ui$Element$paddingXY, 25, 0)
									])))),
					hE: A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$centerX,
								$mdgriffith$elm_ui$Element$spacing(4),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						_List_fromArray(
							[
								headerElement(
								_List_fromArray(
									['Tuntipankki'])),
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$author$project$Styles$smallFontSize]),
								$mdgriffith$elm_ui$Element$text('(ei sis. maksettavat)'))
							])),
					dx: _List_Nil,
					fg: $mdgriffith$elm_ui$Element$px(110)
				},
					{
					g4: A2(
						$elm$core$Basics$composeR,
						openPaymentsSum,
						A2(
							$elm$core$Basics$composeR,
							floatToTextWithColor,
							$mdgriffith$elm_ui$Element$el(
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$alignRight,
										A2($mdgriffith$elm_ui$Element$paddingXY, 25, 0)
									])))),
					hE: headerElement(
						_List_fromArray(
							['Avoimet', 'maksettavat'])),
					dx: _List_Nil,
					fg: $mdgriffith$elm_ui$Element$px(95)
				},
					{
					g4: A2(
						$elm$core$Basics$composeR,
						totalSum,
						A2(
							$elm$core$Basics$composeR,
							floatToTextWithColor,
							$mdgriffith$elm_ui$Element$el(
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$alignRight,
										A2($mdgriffith$elm_ui$Element$paddingXY, 25, 0)
									])))),
					hE: headerElement(
						_List_fromArray(
							['Kokonais-', 'saldo'])),
					dx: _List_Nil,
					fg: $mdgriffith$elm_ui$Element$px(95)
				}
				]),
			_List_Nil);
	});
var $author$project$Pages$WorkTimeTracking$State_Main$view_workers = F3(
	function (maybeWorkerCompensationType, listType, allWorkers) {
		var workersOfType = F2(
			function (workerCompensationType, workers) {
				if (!workerCompensationType.$) {
					var compensationType = workerCompensationType.a;
					return A2(
						$elm$core$List$filter,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.kI;
							},
							$elm$core$Basics$eq(compensationType)),
						workers);
				} else {
					return workers;
				}
			});
		return A2(
			$author$project$Styles$card_wrapper,
			true,
			A2(
				$mdgriffith$elm_ui$Element$column,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Styles$cardTitle(
						function () {
							if (!maybeWorkerCompensationType.$) {
								switch (maybeWorkerCompensationType.a) {
									case 0:
										var _v1 = maybeWorkerCompensationType.a;
										return 'Työntekijät - tuntipalkka';
									case 1:
										var _v2 = maybeWorkerCompensationType.a;
										return 'Työntekijät - kuukausipalkka';
									case 2:
										var _v3 = maybeWorkerCompensationType.a;
										return 'Työntekijät - ei palkkaa';
									default:
										var _v4 = maybeWorkerCompensationType.a;
										return 'Työntekijät - myyntiprovisio';
								}
							} else {
								return 'Työntekijät';
							}
						}()),
						function () {
						if ($elm$core$List$isEmpty(allWorkers)) {
							return A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$color(
										$author$project$Colors$onSurface(3)),
										$mdgriffith$elm_ui$Element$Font$italic
									]),
								$mdgriffith$elm_ui$Element$text('ei työntekijöitä'));
						} else {
							if (!listType.$) {
								return A2(
									$mdgriffith$elm_ui$Element$row,
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Element$paddingXY, 10, 10),
											$mdgriffith$elm_ui$Element$spacing(10)
										]),
									A2(
										$elm$core$List$map,
										$mdgriffith$elm_ui$Element$column(
											_List_fromArray(
												[$mdgriffith$elm_ui$Element$alignTop])),
										A2(
											$author$project$Utils$MiscUtils$splitListIntoSizesOf,
											8,
											$author$project$Utils$MiscUtils$flatten(
												A2(
													$elm$core$List$map,
													$author$project$Pages$WorkTimeTracking$State_Main$view_workerAlphabeticGroup_items,
													A2(
														$author$project$Utils$MiscUtils$groupByFirstCharacter,
														function ($) {
															return $.mD;
														},
														A2(workersOfType, maybeWorkerCompensationType, allWorkers)))))));
							} else {
								var entries = listType.a;
								var workers = A2(workersOfType, maybeWorkerCompensationType, allWorkers);
								var workerCount = $elm$core$List$length(workers);
								var workersInColumn1 = A2(
									$elm$core$List$take,
									((workerCount / 2) | 0) + 1,
									A2(
										$elm$core$List$sortBy,
										function ($) {
											return $.mD;
										},
										workers));
								var workersInColumn2 = A2(
									$elm$core$List$filter,
									function (worker) {
										return $elm$core$List$isEmpty(
											A2(
												$elm$core$List$filter,
												A2(
													$elm$core$Basics$composeR,
													function ($) {
														return $.er;
													},
													$elm$core$Basics$eq(worker.er)),
												workersInColumn1));
									},
									workers);
								return A2(
									$mdgriffith$elm_ui$Element$row,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$spacing(32)
										]),
									A2(
										$elm$core$List$map,
										$mdgriffith$elm_ui$Element$el(
											_List_fromArray(
												[$mdgriffith$elm_ui$Element$alignTop])),
										A2(
											$elm$core$List$map,
											$author$project$Pages$WorkTimeTracking$State_Main$view_workerNamesAndBalances(entries),
											_List_fromArray(
												[workersInColumn1, workersInColumn2]))));
							}
						}
					}()
					])));
	});
var $author$project$Pages$WorkTimeTracking$State_Main$view = F2(
	function (user, model) {
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Background$color($author$project$Colors$background),
					$mdgriffith$elm_ui$Element$Font$color($author$project$Colors$onBackground),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$padding(8),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$spacing(16),
					$mdgriffith$elm_ui$Element$inFront(
					function () {
						var _v0 = model.c1;
						switch (_v0.$) {
							case 0:
								var subModel = _v0.a;
								return A2(
									$mdgriffith$elm_ui$Element$map,
									$author$project$Pages$WorkTimeTracking$State_Main$SubMsg_EditEntryDialog,
									$author$project$Pages$WorkTimeTracking$EditEntryDialog$view(subModel));
							case 1:
								var subModel = _v0.a;
								return A2(
									$mdgriffith$elm_ui$Element$map,
									$author$project$Pages$WorkTimeTracking$State_Main$SubMsg_MarkEntryAsPaidDialog,
									A2($author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$view, model.eY, subModel));
							default:
								return $mdgriffith$elm_ui$Element$none;
						}
					}())
				]),
			$author$project$Types$User$canSignWorkTimeEntries(user) ? _List_fromArray(
				[
					A3(
					$author$project$Pages$WorkTimeTracking$State_Main$view_workers,
					$elm$core$Maybe$Just(0),
					$author$project$Pages$WorkTimeTracking$State_Main$WorkerNamesAndBalances(model.lm),
					model.jA),
					A3(
					$author$project$Pages$WorkTimeTracking$State_Main$view_workers,
					$elm$core$Maybe$Just(1),
					$author$project$Pages$WorkTimeTracking$State_Main$WorkerNamesAndBalances(model.lm),
					model.jA),
					A5(
					$author$project$Pages$WorkTimeTracking$ListElements$element_entriesList,
					$author$project$Pages$WorkTimeTracking$ListElements$SignQueue(
						{mM: $author$project$Pages$WorkTimeTracking$State_Main$Entry_Clicked_Edit, mP: $author$project$Pages$WorkTimeTracking$State_Main$Entry_Clicked_Sign}),
					model.eY,
					true,
					model.jA,
					model.lm),
					$author$project$Pages$WorkTimeTracking$State_Main$view_paymentQueue(model),
					A3($author$project$Pages$WorkTimeTracking$ListElements$element_paymentsArchive, model.jA, model.lm, model.nr),
					A5($author$project$Pages$WorkTimeTracking$ListElements$element_entriesList, $author$project$Pages$WorkTimeTracking$ListElements$Archive, model.eY, true, model.jA, model.lm)
				]) : _List_fromArray(
				[
					A3($author$project$Pages$WorkTimeTracking$State_Main$view_workers, $elm$core$Maybe$Nothing, $author$project$Pages$WorkTimeTracking$State_Main$WorkerNamesOnly, model.jA)
				]));
	});
var $author$project$Pages$WorkTimeTracking$State_WorkerPage$Entry_Clicked_Edit = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$WorkTimeTracking$State_WorkerPage$Entry_Clicked_MarkAsPaid = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$WorkTimeTracking$State_WorkerPage$Entry_Clicked_Sign = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pages$WorkTimeTracking$State_WorkerPage$Entry_Clicked_Unsign = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$WorkTimeTracking$ListElements$PaymentQueue = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$WorkTimeTracking$DateDialog$CloseDialog = {$: 0};
var $author$project$Pages$WorkTimeTracking$DateDialog$DraftEntry_SelectHoursDialog_Hide = {$: 10};
var $author$project$Pages$WorkTimeTracking$DateDialog$DraftEntry_Updated_Hours = function (a) {
	return {$: 6, a: a};
};
var $author$project$Types$WorkTimeEntry$TotalBalance = 2;
var $author$project$Pages$WorkTimeTracking$DateDialog$column_WorkTimeEntry_balanceEffect = {
	g4: A2(
		$elm$core$Basics$composeR,
		$elm$core$Tuple$second,
		A2(
			$elm$core$Basics$composeR,
			$author$project$Types$WorkTimeEntry$renderBalanceEffect,
			$mdgriffith$elm_ui$Element$el(
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$alignRight])))),
	hE: $mdgriffith$elm_ui$Element$text('Tunnit'),
	dx: _List_Nil,
	fg: $mdgriffith$elm_ui$Element$px(50)
};
var $author$project$Pages$WorkTimeTracking$DateDialog$column_WorkTimeEntry_comment = {
	g4: A2(
		$elm$core$Basics$composeR,
		$elm$core$Tuple$second,
		A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.kH;
			},
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Maybe$withDefault(''),
				A2(
					$elm$core$Basics$composeR,
					$mdgriffith$elm_ui$Element$text,
					A2(
						$elm$core$Basics$composeR,
						$elm$core$List$singleton,
						$mdgriffith$elm_ui$Element$paragraph(_List_Nil)))))),
	hE: $mdgriffith$elm_ui$Element$text('Kommentti'),
	dx: _List_Nil,
	fg: $mdgriffith$elm_ui$Element$px(200)
};
var $author$project$Pages$WorkTimeTracking$DateDialog$column_WorkTimeEntry_entryType = {
	g4: A2(
		$elm$core$Basics$composeR,
		$elm$core$Tuple$second,
		A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.ln;
			},
			A2(
				$elm$core$Basics$composeR,
				$author$project$Types$WorkTimeEntry$typeToString,
				A2(
					$elm$core$Basics$composeR,
					$mdgriffith$elm_ui$Element$text,
					$mdgriffith$elm_ui$Element$el(
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$centerX])))))),
	hE: $mdgriffith$elm_ui$Element$text('Tyyppi'),
	dx: _List_Nil,
	fg: $mdgriffith$elm_ui$Element$px(80)
};
var $author$project$Pages$WorkTimeTracking$DateDialog$column_WorkTimeEntry_reflectInPay = {
	g4: A2($elm$core$Basics$composeR, $elm$core$Tuple$second, $author$project$Types$WorkTimeEntry$renderReflectInPay),
	hE: A2(
		$mdgriffith$elm_ui$Element$paragraph,
		_List_Nil,
		$elm$core$List$singleton(
			$mdgriffith$elm_ui$Element$text('Huomioidaan palkassa'))),
	dx: _List_Nil,
	fg: $mdgriffith$elm_ui$Element$px(105)
};
var $author$project$Pages$WorkTimeTracking$DateDialog$column_WorkTimeEntry_signature = {
	g4: A2(
		$elm$core$Basics$composeR,
		$elm$core$Tuple$second,
		A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.i2;
			},
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Maybe$map(
					function ($) {
						return $.kl;
					}),
				A2(
					$elm$core$Basics$composeR,
					$elm$core$Maybe$withDefault('-'),
					A2(
						$elm$core$Basics$composeR,
						$mdgriffith$elm_ui$Element$text,
						$mdgriffith$elm_ui$Element$el(
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerX]))))))),
	hE: $mdgriffith$elm_ui$Element$text('Hyväksynyt'),
	dx: _List_Nil,
	fg: $mdgriffith$elm_ui$Element$px(90)
};
var $author$project$Pages$WorkTimeTracking$DateDialog$Entry_Clicked_Edit = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$WorkTimeTracking$DateDialog$column_edit = function (serverRequests) {
	return {
		g4: function (_v0) {
			var entry = _v0.b;
			var isSigned = $author$project$Types$WorkTimeEntry$isSigned(entry);
			return $author$project$Components$Button$toElement(
				A2(
					$author$project$Components$Button$tooltip,
					isSigned ? 'hyväksyttyä kirjausta ei voi enää muokata' : 'muokkaa kirjausta',
					$author$project$Components$Button$secondary(
						A2(
							$author$project$Components$Button$onClick,
							$author$project$Pages$WorkTimeTracking$DateDialog$Entry_Clicked_Edit(entry),
							A2(
								$author$project$Components$Button$iconSize,
								14,
								A2(
									$author$project$Components$Button$icon,
									$author$project$Icons$edit,
									A2($author$project$Components$Button$disabled, isSigned || serverRequests.fo, $author$project$Components$Button$button)))))));
		},
		hE: $mdgriffith$elm_ui$Element$none,
		dx: _List_Nil,
		fg: $mdgriffith$elm_ui$Element$px(20)
	};
};
var $author$project$Pages$WorkTimeTracking$DateDialog$column_rowNumber = {
	g4: A2(
		$elm$core$Basics$composeR,
		$elm$core$Tuple$first,
		A2(
			$elm$core$Basics$composeR,
			$author$project$Helpers$orderNumberString,
			A2(
				$elm$core$Basics$composeR,
				$mdgriffith$elm_ui$Element$text,
				$mdgriffith$elm_ui$Element$el(
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$alignRight]))))),
	hE: $mdgriffith$elm_ui$Element$none,
	dx: _List_Nil,
	fg: $mdgriffith$elm_ui$Element$px(20)
};
var $author$project$Pages$WorkTimeTracking$DateDialog$view_entriesList = F2(
	function (serverRequests, entries) {
		return A2(
			$author$project$Styles$card_wrapper,
			true,
			A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$spacing(8),
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				_List_fromArray(
					[
						$author$project$Styles$cardTitle('Päivän tapahtumat'),
						$elm$core$List$isEmpty(entries) ? A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$centerX,
								$mdgriffith$elm_ui$Element$Font$italic,
								$author$project$Styles$largeFontSize,
								$mdgriffith$elm_ui$Element$Font$color(
								$author$project$Colors$onSurface(5))
							]),
						$mdgriffith$elm_ui$Element$text('ei kirjauksia tälle päivälle')) : A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$centerX,
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
								$author$project$Styles$largeFontSize,
								$mdgriffith$elm_ui$Element$scrollbarY,
								$mdgriffith$elm_ui$Element$spacing(2),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$centerX]),
								A4(
									$author$project$OldTable$oldTable,
									A2(
										$elm$core$List$indexedMap,
										F2(
											function (i, entry) {
												return _Utils_Tuple2(i + 1, entry);
											}),
										$elm$core$List$reverse(
											A2(
												$elm$core$List$sortBy,
												A2(
													$elm$core$Basics$composeR,
													function ($) {
														return $.mJ;
													},
													$author$project$Helpers$dateToStringShort),
												entries))),
									_List_Nil,
									_List_fromArray(
										[
											$author$project$Pages$WorkTimeTracking$DateDialog$column_rowNumber,
											$author$project$Pages$WorkTimeTracking$DateDialog$column_WorkTimeEntry_entryType,
											$author$project$Pages$WorkTimeTracking$DateDialog$column_WorkTimeEntry_balanceEffect,
											$author$project$Pages$WorkTimeTracking$DateDialog$column_WorkTimeEntry_reflectInPay,
											$author$project$Pages$WorkTimeTracking$DateDialog$column_WorkTimeEntry_comment,
											$author$project$Pages$WorkTimeTracking$DateDialog$column_WorkTimeEntry_signature,
											$author$project$Pages$WorkTimeTracking$DateDialog$column_edit(serverRequests)
										]),
									_List_fromArray(
										[
											A2(
											$mdgriffith$elm_ui$Element$row,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$mdgriffith$elm_ui$Element$el,
													_List_fromArray(
														[
															$mdgriffith$elm_ui$Element$paddingEach(
															{g1: 0, h1: 32, iN: 0, jl: 0})
														]),
													$mdgriffith$elm_ui$Element$text('yhteensä')),
													A2(
													$mdgriffith$elm_ui$Element$el,
													_List_fromArray(
														[
															$mdgriffith$elm_ui$Element$paddingEach(
															{g1: 0, h1: 35, iN: 0, jl: 0})
														]),
													$author$project$Types$WorkTimeEntry$renderSum(
														A2($author$project$Types$WorkTimeEntry$sum, 2, entries))),
													$mdgriffith$elm_ui$Element$text(' tuntia')
												]))
										])))
							]))
					])));
	});
var $author$project$Pages$WorkTimeTracking$DateDialog$DraftEntry_Clicked_Clear = {$: 3};
var $author$project$Pages$WorkTimeTracking$DateDialog$DraftEntry_Clicked_Save = {$: 4};
var $author$project$Pages$WorkTimeTracking$DateDialog$DraftEntry_SelectHoursDialog_Show = {$: 11};
var $author$project$Pages$WorkTimeTracking$DateDialog$DraftEntry_Updated_Comment = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pages$WorkTimeTracking$DateDialog$DraftEntry_Updated_ReflectInPay = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$WorkTimeTracking$DateDialog$DraftEntry_Updated_EntryType = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$WorkTimeTracking$DateDialog$DraftCorrection = 0;
var $author$project$Pages$WorkTimeTracking$DateDialog$DraftOvertime = 2;
var $author$project$Pages$WorkTimeTracking$DateDialog$DraftTimeOff = 3;
var $author$project$Pages$WorkTimeTracking$DateDialog$entryType_to_draftType = function (entryType) {
	switch (entryType) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $author$project$Pages$WorkTimeTracking$DateDialog$entryTypeSelector = F2(
	function (workerCompensationType, draftEntry) {
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$alignLeft,
					$mdgriffith$elm_ui$Element$spacing(2)
				]),
			A2(
				$elm$core$List$map,
				function (draftEntryType) {
					return $author$project$Components$Button$toElement(
						A2(
							$author$project$Components$Button$onClick,
							$author$project$Pages$WorkTimeTracking$DateDialog$DraftEntry_Updated_EntryType(draftEntryType),
							A2(
								$author$project$Components$Button$icon,
								_Utils_eq(draftEntry.ln, draftEntryType) ? $author$project$Icons$radioButtonSelected : $author$project$Icons$radioButtonNotSelected,
								A2(
									$author$project$Components$Button$text,
									$author$project$Types$WorkTimeEntry$typeToString(
										$author$project$Pages$WorkTimeTracking$DateDialog$draftType_to_entryType(draftEntryType)),
									$author$project$Components$Button$button))));
				},
				A2(
					$elm$core$List$map,
					$author$project$Pages$WorkTimeTracking$DateDialog$entryType_to_draftType,
					$author$project$Types$WorkTimeEntry$validEntryTypesForWorkerCompensationType(workerCompensationType))));
	});
var $author$project$Pages$WorkTimeTracking$DateDialog$view_newDraftEntry = F3(
	function (serverRequests, workerCompensationType, draftEntry) {
		var saveAndCancelButtonsRow = function (isDisabled) {
			return A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$alignRight,
						$mdgriffith$elm_ui$Element$spacing(8)
					]),
				_List_fromArray(
					[
						$author$project$Components$Button$toElement(
						A2(
							$author$project$Components$Button$onClick,
							$author$project$Pages$WorkTimeTracking$DateDialog$DraftEntry_Clicked_Clear,
							A2(
								$author$project$Components$Button$text,
								'Tyhjennä',
								A2($author$project$Components$Button$disabled, isDisabled, $author$project$Components$Button$button)))),
						$author$project$Components$Button$toElement(
						$author$project$Components$Button$primary(
							A2(
								$author$project$Components$Button$onClick,
								$author$project$Pages$WorkTimeTracking$DateDialog$DraftEntry_Clicked_Save,
								A2(
									$author$project$Components$Button$disabled,
									!draftEntry.ar,
									A2(
										$author$project$Components$Button$text,
										'Tallenna kirjaus',
										A2($author$project$Components$Button$disabled, isDisabled, $author$project$Components$Button$button))))))
					]));
		};
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px(155)),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			A2(
				$author$project$Styles$card_wrapper,
				true,
				A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$author$project$Styles$largeFontSize,
							$mdgriffith$elm_ui$Element$spacing(8),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					_List_fromArray(
						[
							$author$project$Styles$cardTitle('Uusi kirjaus'),
							A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$height(
									$mdgriffith$elm_ui$Element$px(70)),
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$column,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
											$mdgriffith$elm_ui$Element$spacing(4),
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
										]),
									_List_fromArray(
										[
											A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[$mdgriffith$elm_ui$Element$alignTop]),
											A2($author$project$Pages$WorkTimeTracking$DateDialog$entryTypeSelector, workerCompensationType, draftEntry)),
											A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$alignBottom,
													A2($mdgriffith$elm_ui$Element$paddingXY, 6, 4)
												]),
											A2($author$project$Pages$WorkTimeTracking$EditEntryDialog$reflectInPayCheckbox, $author$project$Pages$WorkTimeTracking$DateDialog$DraftEntry_Updated_ReflectInPay, draftEntry.bF))
										])),
									A2(
									$mdgriffith$elm_ui$Element$column,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
											$mdgriffith$elm_ui$Element$spacing(4),
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
										]),
									_List_fromArray(
										[
											A2(
											$mdgriffith$elm_ui$Element$row,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$alignTop,
													$mdgriffith$elm_ui$Element$spacing(4),
													$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
												]),
											_List_fromArray(
												[
													A2($author$project$Pages$WorkTimeTracking$EditEntryDialog$hoursSelectorButton, $author$project$Pages$WorkTimeTracking$DateDialog$DraftEntry_SelectHoursDialog_Show, draftEntry.ar),
													function () {
													var _v0 = draftEntry.ln;
													if (!_v0) {
														return $author$project$Pages$WorkTimeTracking$EditEntryDialog$correctionTypeInfo;
													} else {
														return $mdgriffith$elm_ui$Element$none;
													}
												}()
												])),
											A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$alignBottom,
													$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
												]),
											A2($author$project$Pages$WorkTimeTracking$EditEntryDialog$commentInput, $author$project$Pages$WorkTimeTracking$DateDialog$DraftEntry_Updated_Comment, draftEntry.kH))
										]))
								])),
							saveAndCancelButtonsRow(serverRequests.fo)
						]))));
	});
var $author$project$Pages$WorkTimeTracking$DateDialog$view = function (model) {
	return A2(
		$author$project$Components$Modal$modal,
		{kz: true, mR: $author$project$Pages$WorkTimeTracking$DateDialog$CloseDialog},
		A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Background$color($author$project$Colors$background),
					$mdgriffith$elm_ui$Element$Font$color($author$project$Colors$onBackground),
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px(560)),
					A2($mdgriffith$elm_ui$Element$paddingXY, 8, 12),
					$mdgriffith$elm_ui$Element$spacing(8),
					$mdgriffith$elm_ui$Element$width(
					$mdgriffith$elm_ui$Element$px(700)),
					$mdgriffith$elm_ui$Element$inFront(
					function () {
						var _v0 = model.c1;
						switch (_v0.$) {
							case 0:
								var subModel = _v0.a;
								return A2(
									$mdgriffith$elm_ui$Element$map,
									$author$project$Pages$WorkTimeTracking$DateDialog$SubMsg_EditEntryDialog,
									$author$project$Pages$WorkTimeTracking$EditEntryDialog$view(subModel));
							case 1:
								return A4(
									$author$project$Pages$WorkTimeTracking$EditEntryDialog$view_hoursSelectionDialog,
									$author$project$Pages$WorkTimeTracking$DateDialog$DraftEntry_SelectHoursDialog_Hide,
									$author$project$Pages$WorkTimeTracking$DateDialog$DraftEntry_Updated_Hours,
									model.I.ar,
									$author$project$Pages$WorkTimeTracking$EditEntryDialog$balanceEffectMinMax(
										$author$project$Pages$WorkTimeTracking$DateDialog$draftType_to_entryType(model.I.ln)));
							default:
								return $mdgriffith$elm_ui$Element$none;
						}
					}())
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$centerX,
									$author$project$Styles$xxlFontSize,
									$mdgriffith$elm_ui$Element$Font$bold,
									$mdgriffith$elm_ui$Element$spacing(4)
								]),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text('TYÖAIKAPOIKKEUKSET')
								])),
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$alignRight]),
							$author$project$Components$CloseButton$closeButton($author$project$Pages$WorkTimeTracking$DateDialog$CloseDialog))
						])),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$Font$bold]),
					$mdgriffith$elm_ui$Element$text(model.bR.mD)),
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$centerX,
							$mdgriffith$elm_ui$Element$Font$bold,
							$mdgriffith$elm_ui$Element$spacing(4)
						]),
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$text(
							A2(
								$author$project$Language$weekdayNameFinnish,
								0,
								$justinmimbs$date$Date$weekday(model.b4))),
							$mdgriffith$elm_ui$Element$text(
							$author$project$Helpers$dateToStringShort(model.b4))
						])),
					A2($author$project$Pages$WorkTimeTracking$DateDialog$view_entriesList, model.eY, model.lm),
					A3($author$project$Pages$WorkTimeTracking$DateDialog$view_newDraftEntry, model.eY, model.bR.kI, model.I)
				])));
};
var $author$project$Pages$WorkTimeTracking$State_WorkerPage$Clicked_Calendar_DateCell = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$WorkTimeTracking$State_WorkerPage$Clicked_Calendar_GotoYear = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$YearlyCalendar$YearlyCalendar = $elm$core$Basics$identity;
var $author$project$Components$YearlyCalendar$events = F2(
	function (value, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{c2: value});
	});
var $author$project$Components$YearlyCalendar$header = F2(
	function (value, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{
				em: $elm$core$Maybe$Just(value)
			});
	});
var $author$project$Components$YearlyCalendar$onDateCellClick = F2(
	function (clickHandler, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{
				dY: $elm$core$Maybe$Just(clickHandler)
			});
	});
var $author$project$Components$YearlyCalendar$onGotoYearClick = F2(
	function (clickHandler, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{
				cO: $elm$core$Maybe$Just(clickHandler)
			});
	});
var $elm$core$Elm$JsArray$map = _JsArray_map;
var $elm$core$Array$map = F2(
	function (func, _v0) {
		var len = _v0.a;
		var startShift = _v0.b;
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = function (node) {
			if (!node.$) {
				var subTree = node.a;
				return $elm$core$Array$SubTree(
					A2($elm$core$Elm$JsArray$map, helper, subTree));
			} else {
				var values = node.a;
				return $elm$core$Array$Leaf(
					A2($elm$core$Elm$JsArray$map, func, values));
			}
		};
		return A4(
			$elm$core$Array$Array_elm_builtin,
			len,
			startShift,
			A2($elm$core$Elm$JsArray$map, helper, tree),
			A2($elm$core$Elm$JsArray$map, func, tail));
	});
var $author$project$Utils$Month$all = $elm$core$Array$toList(
	A2(
		$elm$core$Array$map,
		$justinmimbs$date$Date$numberToMonth,
		A2(
			$elm$core$Array$initialize,
			12,
			$elm$core$Basics$add(1))));
var $author$project$Components$YearlyCalendar$headerRow = function (_v0) {
	var headerText = _v0.em;
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[$mdgriffith$elm_ui$Element$alignLeft]),
		A2($elm$core$Maybe$withDefault, $mdgriffith$elm_ui$Element$none, headerText));
};
var $author$project$Utils$Month$toFinnishShortString = function (month) {
	switch (month) {
		case 0:
			return 'tammi';
		case 1:
			return 'helmi';
		case 2:
			return 'maalis';
		case 3:
			return 'huhti';
		case 4:
			return 'touko';
		case 5:
			return 'kesä';
		case 6:
			return 'heinä';
		case 7:
			return 'elo';
		case 8:
			return 'syys';
		case 9:
			return 'loka';
		case 10:
			return 'marras';
		default:
			return 'joulu';
	}
};
var $author$project$Types$Week$dates = function (week) {
	return A2(
		$elm$core$List$map,
		$author$project$Types$Week$dateAtWeekday(week),
		A2(
			$elm$core$List$map,
			$justinmimbs$date$Date$numberToWeekday,
			$elm$core$Array$toList(
				A2(
					$elm$core$Array$initialize,
					7,
					$elm$core$Basics$add(1)))));
};
var $author$project$Types$WorkTimeEntry$isOnDate = F2(
	function (date, entry) {
		return _Utils_eq(date, entry.mJ);
	});
var $author$project$Components$YearlyCalendar$renderDate = function (date) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Font$color(
				A2($author$project$Colors$add_highlight, 0.5, $author$project$Colors$background))
			]),
		$mdgriffith$elm_ui$Element$text(
			A2($justinmimbs$date$Date$format, 'd', date)));
};
var $author$project$Components$YearlyCalendar$renderEvent = function (event) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[$mdgriffith$elm_ui$Element$alignRight, $mdgriffith$elm_ui$Element$Font$alignRight]),
		$author$project$Types$WorkTimeEntry$renderBalanceEffect(event));
};
var $author$project$Components$YearlyCalendar$dayCell = F3(
	function (parameterStyles, options, date) {
		var todayStyles = _List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Border$color($author$project$Colors$red),
				$mdgriffith$elm_ui$Element$Border$rounded(8),
				$mdgriffith$elm_ui$Element$Border$width(2)
			]);
		var eventsForDay = A2(
			$elm$core$List$filter,
			$author$project$Types$WorkTimeEntry$isOnDate(date),
			options.c2);
		var styles = _Utils_ap(
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Border$color(
					$elm$core$List$isEmpty(eventsForDay) ? A2($author$project$Colors$add_highlight, 0.1, $author$project$Colors$background) : $author$project$Colors$primary(1)),
					$mdgriffith$elm_ui$Element$Border$width(1),
					$mdgriffith$elm_ui$Element$Border$rounded(4),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					A2($mdgriffith$elm_ui$Element$paddingXY, 0, 1),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			_Utils_eq(date, options.dE) ? todayStyles : _List_Nil);
		return $author$project$Components$Button$toElement(
			A2(
				$author$project$Components$Button$tooltip,
				_Utils_ap(
					$author$project$Helpers$dateToStringShort(date),
					_Utils_ap(
						_Utils_eq(date, options.dE) ? ' (TÄNÄÄN)' : '',
						(!$elm$core$List$isEmpty(eventsForDay)) ? (' ' + ($author$project$Types$WorkTimeEntry$totalToString(
							A2($author$project$Types$WorkTimeEntry$sum, 2, eventsForDay)) + ' tuntia')) : '')),
				A2(
					$author$project$Components$Button$icon,
					A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$height(parameterStyles.en),
								$mdgriffith$elm_ui$Element$spacing(2),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$alignLeft, $mdgriffith$elm_ui$Element$Font$alignLeft]),
								$author$project$Components$YearlyCalendar$renderDate(date)),
								A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$alignTop, $mdgriffith$elm_ui$Element$centerX]),
								A2($elm$core$List$map, $author$project$Components$YearlyCalendar$renderEvent, eventsForDay))
							])),
					A2(
						$author$project$Components$Button$setCustomAttributes,
						styles,
						A2(
							$author$project$Components$Button$width,
							parameterStyles.fg,
							A2(
								$author$project$Components$Button$height,
								parameterStyles.en,
								A2(
									$author$project$Components$Button$fontSize,
									parameterStyles.eh,
									function (button) {
										return A2(
											$elm$core$Maybe$withDefault,
											button,
											A2(
												$elm$core$Maybe$map,
												function (clickHandler) {
													return A2(
														$author$project$Components$Button$onClick,
														clickHandler(date),
														button);
												},
												options.dY));
									}($author$project$Components$Button$button))))))));
	});
var $author$project$Components$YearlyCalendar$weekNumberCell = F2(
	function (styles, week) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$alignTop,
						$mdgriffith$elm_ui$Element$Font$alignRight,
						$mdgriffith$elm_ui$Element$Font$italic,
						$author$project$Components$Tooltip$tooltip(
						'Viikko ' + $author$project$Types$Week$toString(week)),
						$mdgriffith$elm_ui$Element$width(
						$mdgriffith$elm_ui$Element$px(20)),
						$author$project$Cursor$cursor(8)
					]),
				styles),
			$mdgriffith$elm_ui$Element$text(
				$elm$core$String$fromInt(week.o$)));
	});
var $author$project$Components$YearlyCalendar$weekdayNames = function (_v0) {
	var cellWidth = _v0.g6;
	var cellSpacing = _v0.g5;
	var fontSize = _v0.eh;
	return A2(
		$mdgriffith$elm_ui$Element$row,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$alignRight,
				$mdgriffith$elm_ui$Element$spacing(cellSpacing)
			]),
		A2(
			$elm$core$List$map,
			$mdgriffith$elm_ui$Element$el(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Font$color(
						A2($author$project$Colors$add_highlight, 0.5, $author$project$Colors$background)),
						$mdgriffith$elm_ui$Element$Font$size(fontSize),
						$mdgriffith$elm_ui$Element$width(cellWidth)
					])),
			A2(
				$elm$core$List$map,
				$mdgriffith$elm_ui$Element$text,
				_List_fromArray(
					['M', 'T', 'K', 'T', 'P', 'L', 'S']))));
};
var $author$project$Components$YearlyCalendar$weekElement = F4(
	function (options, _v0, monthOfRow, week) {
		var renderWeekdayHeader = _v0.iI;
		var rowHeight = _v0.iQ;
		var fontSize = $author$project$Styles$fontSizesInPixels.ml;
		var dayCellWidth = 33;
		var dayCellSpacing = 2;
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$spacing(4)
				]),
			_List_fromArray(
				[
					renderWeekdayHeader ? A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$alignRight,
							$mdgriffith$elm_ui$Element$spacing(dayCellSpacing)
						]),
					_List_fromArray(
						[
							$author$project$Components$YearlyCalendar$weekdayNames(
							{
								g5: dayCellSpacing,
								g6: $mdgriffith$elm_ui$Element$px(dayCellWidth),
								eh: fontSize
							})
						])) : $mdgriffith$elm_ui$Element$none,
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$spacing(4)
						]),
					_List_fromArray(
						[
							A2(
							$author$project$Components$YearlyCalendar$weekNumberCell,
							_Utils_ap(
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$alignTop,
										$mdgriffith$elm_ui$Element$Font$size(fontSize),
										$mdgriffith$elm_ui$Element$moveDown(4)
									]),
								$author$project$Helpers$attributeIf(
									_List_fromArray(
										[
											_Utils_Tuple2(
											!A2(
												$elm$core$List$any,
												A2(
													$elm$core$Basics$composeR,
													$justinmimbs$date$Date$month,
													$elm$core$Basics$eq(monthOfRow)),
												$author$project$Types$Week$dates(week)),
											$author$project$Styles$hidden)
										]))),
							week),
							A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$spacing(dayCellSpacing)
								]),
							A2(
								$elm$core$List$map,
								$mdgriffith$elm_ui$Element$el(
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width(
											$mdgriffith$elm_ui$Element$px(dayCellWidth))
										])),
								A2(
									$elm$core$List$map,
									function (date) {
										return _Utils_eq(
											$justinmimbs$date$Date$month(date),
											monthOfRow) ? A3(
											$author$project$Components$YearlyCalendar$dayCell,
											{
												eh: fontSize,
												en: $mdgriffith$elm_ui$Element$px(rowHeight),
												fg: $mdgriffith$elm_ui$Element$px(dayCellWidth)
											},
											options,
											date) : $mdgriffith$elm_ui$Element$none;
									},
									$author$project$Types$Week$dates(week))))
						]))
				]));
	});
var $author$project$Utils$Month$firstDay = F2(
	function (year, month) {
		return A3($justinmimbs$date$Date$fromCalendarDate, year, month, 1);
	});
var $author$project$Utils$Month$week = F3(
	function (year, month, monthWeekNumber) {
		return $author$project$Types$Week$forDate(
			A2(
				$justinmimbs$date$Date$floor,
				4,
				A3(
					$justinmimbs$date$Date$add,
					2,
					monthWeekNumber - 1,
					A2($author$project$Utils$Month$firstDay, year, month))));
	});
var $author$project$Utils$Month$weeks = F2(
	function (year, month) {
		return A2(
			$elm$core$List$map,
			A2($author$project$Utils$Month$week, year, month),
			$elm$core$Array$toList(
				A2(
					$elm$core$Array$initialize,
					6,
					$elm$core$Basics$add(1))));
	});
var $author$project$Components$YearlyCalendar$monthRow = F2(
	function (options, month) {
		var renderWeekdayHeader = !month;
		var monthNameCell = A2(
			$elm$core$Basics$composeR,
			$author$project$Utils$Month$toFinnishShortString,
			A2(
				$elm$core$Basics$composeR,
				$mdgriffith$elm_ui$Element$text,
				$mdgriffith$elm_ui$Element$el(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$alignTop,
							$mdgriffith$elm_ui$Element$Font$alignRight,
							$author$project$Styles$mediumFontSize,
							$mdgriffith$elm_ui$Element$width(
							$mdgriffith$elm_ui$Element$px(40))
						]))));
		var eventsOnMonth = A2(
			$elm$core$List$filter,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.mJ;
				},
				A2(
					$elm$core$Basics$composeR,
					$justinmimbs$date$Date$month,
					$elm$core$Basics$eq(month))),
			options.c2);
		var maxEventsPerDay = A2(
			$elm$core$Maybe$withDefault,
			0,
			$elm$core$List$maximum(
				A2(
					$elm$core$List$map,
					function (_v0) {
						var tail = _v0.b;
						return 1 + $elm$core$List$length(tail);
					},
					A2(
						$elm_community$list_extra$List$Extra$groupWhile,
						F2(
							function (a, b) {
								return _Utils_eq(a.mJ, b.mJ);
							}),
						A2(
							$elm$core$List$sortBy,
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.mJ;
								},
								$author$project$Helpers$dateToStringShort),
							eventsOnMonth)))));
		var rowHeight = 24 + (A2($elm$core$Basics$max, 1, maxEventsPerDay) * 14);
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(4)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$alignTop,
							$mdgriffith$elm_ui$Element$moveDown(
							renderWeekdayHeader ? 22 : 4)
						]),
					monthNameCell(month)),
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$height(
							$mdgriffith$elm_ui$Element$px(
								renderWeekdayHeader ? (rowHeight + 20) : rowHeight)),
							$mdgriffith$elm_ui$Element$spacing(4)
						]),
					A2(
						$elm$core$List$map,
						A3(
							$author$project$Components$YearlyCalendar$weekElement,
							options,
							{iI: renderWeekdayHeader, iQ: rowHeight},
							month),
						A2($author$project$Utils$Month$weeks, options.bG, month)))
				]));
	});
var $author$project$Components$YearlyCalendar$yearNavigation = function (options) {
	return A2(
		$mdgriffith$elm_ui$Element$row,
		_List_Nil,
		_List_fromArray(
			[
				A3($author$project$Components$YearlyCalendar$yearNavigationButton, options.cO, options.bG, 0),
				$mdgriffith$elm_ui$Element$text(
				$elm$core$String$fromInt(options.bG)),
				A3($author$project$Components$YearlyCalendar$yearNavigationButton, options.cO, options.bG, 1)
			]));
};
var $author$project$Components$YearlyCalendar$toElement = function (_v0) {
	var options = _v0;
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$spacing(8)
			]),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				_List_fromArray(
					[
						$author$project$Components$YearlyCalendar$headerRow(options),
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$centerX,
								$mdgriffith$elm_ui$Element$moveLeft(40)
							]),
						$author$project$Components$YearlyCalendar$yearNavigation(options))
					])),
				A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$spacing(2)
					]),
				A2(
					$elm$core$List$map,
					$author$project$Components$YearlyCalendar$monthRow(options),
					$author$project$Utils$Month$all))
			]));
};
var $author$project$Components$YearlyCalendar$today = F2(
	function (value, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{dE: value});
	});
var $author$project$Components$YearlyCalendar$year = F2(
	function (value, _v0) {
		var options = _v0;
		return _Utils_update(
			options,
			{bG: value});
	});
var $author$project$Components$YearlyCalendar$yearlyCalendar = function () {
	var defaultYear = 2000;
	return {
		dY: $elm$core$Maybe$Nothing,
		cO: $elm$core$Maybe$Nothing,
		c2: _List_Nil,
		em: $elm$core$Maybe$Nothing,
		bG: defaultYear,
		dE: A3($justinmimbs$date$Date$fromCalendarDate, defaultYear, 0, 1)
	};
}();
var $author$project$Pages$WorkTimeTracking$State_WorkerPage$view_calendar = F3(
	function (today, selectedYear, entries) {
		return A2(
			$author$project$Styles$card_wrapper,
			true,
			$author$project$Components$YearlyCalendar$toElement(
				A2(
					$author$project$Components$YearlyCalendar$year,
					selectedYear,
					A2(
						$author$project$Components$YearlyCalendar$today,
						today,
						A2(
							$author$project$Components$YearlyCalendar$header,
							$author$project$Styles$cardTitle('Kalenterinäkymä'),
							A2(
								$author$project$Components$YearlyCalendar$onGotoYearClick,
								$author$project$Pages$WorkTimeTracking$State_WorkerPage$Clicked_Calendar_GotoYear,
								A2(
									$author$project$Components$YearlyCalendar$onDateCellClick,
									$author$project$Pages$WorkTimeTracking$State_WorkerPage$Clicked_Calendar_DateCell,
									A2($author$project$Components$YearlyCalendar$events, entries, $author$project$Components$YearlyCalendar$yearlyCalendar))))))));
	});
var $author$project$Pages$WorkTimeTracking$State_WorkerPage$Clicked_Exit = {$: 2};
var $author$project$Types$WorkTimeEntry$TotalOpenBalance = 3;
var $author$project$Pages$WorkTimeTracking$State_WorkerPage$view_header = function (model) {
	var headerFontSize = 24;
	return A2(
		$mdgriffith$elm_ui$Element$row,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Font$size(headerFontSize),
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
			]),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width(
						$mdgriffith$elm_ui$Element$fillPortion(1))
					]),
				A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$moveDown(1)
						]),
					$author$project$Components$Button$toElement(
						A2(
							$author$project$Components$Button$icon,
							A2(
								$mdgriffith$elm_ui$Element$row,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$spacing(4)
									]),
								_List_fromArray(
									[
										$author$project$Icons$chevronLeft,
										A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$Font$color($author$project$Colors$onBackground),
												$mdgriffith$elm_ui$Element$Font$size(headerFontSize)
											]),
										$mdgriffith$elm_ui$Element$text(model.bR.mD))
									])),
							A2($author$project$Components$Button$onClick, $author$project$Pages$WorkTimeTracking$State_WorkerPage$Clicked_Exit, $author$project$Components$Button$button))))),
				A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$spacing(8),
						$mdgriffith$elm_ui$Element$width(
						$mdgriffith$elm_ui$Element$fillPortion(1))
					]),
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$text('tuntipankki'),
						$author$project$Types$WorkTimeEntry$renderSum(
						A2($author$project$Types$WorkTimeEntry$sum, 0, model.lm)),
						$mdgriffith$elm_ui$Element$text('|'),
						$mdgriffith$elm_ui$Element$text(' avoimet maksettavat'),
						$author$project$Types$WorkTimeEntry$renderSum(
						A2(
							$author$project$Types$WorkTimeEntry$sum,
							1,
							A2($elm$core$List$filter, $author$project$Types$WorkTimeEntry$isNotPaid, model.lm))),
						$mdgriffith$elm_ui$Element$text('|'),
						$mdgriffith$elm_ui$Element$text('kokonaissaldo'),
						$author$project$Types$WorkTimeEntry$renderSum(
						A2($author$project$Types$WorkTimeEntry$sum, 3, model.lm))
					])),
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width(
						$mdgriffith$elm_ui$Element$fillPortion(1))
					]),
				$mdgriffith$elm_ui$Element$none)
			]));
};
var $author$project$Pages$WorkTimeTracking$State_WorkerPage$view = F3(
	function (appContext, user, model) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$padding(8),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Background$color($author$project$Colors$background),
						$mdgriffith$elm_ui$Element$Border$rounded(8),
						$mdgriffith$elm_ui$Element$Font$color($author$project$Colors$onBackground),
						$mdgriffith$elm_ui$Element$spacing(8),
						$mdgriffith$elm_ui$Element$padding(8),
						$mdgriffith$elm_ui$Element$inFront(
						function () {
							var _v0 = model.c1;
							switch (_v0.$) {
								case 0:
									var subModel = _v0.a;
									return A2(
										$mdgriffith$elm_ui$Element$map,
										$author$project$Pages$WorkTimeTracking$State_WorkerPage$SubMsg_DateDialog,
										$author$project$Pages$WorkTimeTracking$DateDialog$view(subModel));
								case 1:
									var subModel = _v0.a;
									return A2(
										$mdgriffith$elm_ui$Element$map,
										$author$project$Pages$WorkTimeTracking$State_WorkerPage$SubMsg_EditEntryDialog,
										$author$project$Pages$WorkTimeTracking$EditEntryDialog$view(subModel));
								case 2:
									var subModel = _v0.a;
									return A2(
										$mdgriffith$elm_ui$Element$map,
										$author$project$Pages$WorkTimeTracking$State_WorkerPage$SubMsg_MarkEntryAsPaidDialog,
										A2($author$project$Pages$WorkTimeTracking$MarkEntryAsPaidDialog$view, model.eY, subModel));
								default:
									return $mdgriffith$elm_ui$Element$none;
							}
						}())
					]),
				_List_fromArray(
					[
						$author$project$Pages$WorkTimeTracking$State_WorkerPage$view_header(model),
						A3($author$project$Pages$WorkTimeTracking$State_WorkerPage$view_calendar, appContext.dE, model.bG, model.lm),
						A5(
						$author$project$Pages$WorkTimeTracking$ListElements$element_entriesList,
						$author$project$Pages$WorkTimeTracking$ListElements$SignQueue(
							{mM: $author$project$Pages$WorkTimeTracking$State_WorkerPage$Entry_Clicked_Edit, mP: $author$project$Pages$WorkTimeTracking$State_WorkerPage$Entry_Clicked_Sign}),
						model.eY,
						$author$project$Types$User$canSignWorkTimeEntries(user),
						_List_fromArray(
							[model.bR]),
						model.lm),
						A5(
						$author$project$Pages$WorkTimeTracking$ListElements$element_entriesList,
						$author$project$Pages$WorkTimeTracking$ListElements$PaymentQueue(
							{mN: $elm$core$Maybe$Nothing, mO: $author$project$Pages$WorkTimeTracking$State_WorkerPage$Entry_Clicked_MarkAsPaid, mQ: $author$project$Pages$WorkTimeTracking$State_WorkerPage$Entry_Clicked_Unsign, na: $elm$core$Maybe$Nothing, n$: $elm$core$Set$empty}),
						model.eY,
						$author$project$Types$User$canSignWorkTimeEntries(user),
						_List_fromArray(
							[model.bR]),
						model.lm),
						A3(
						$author$project$Pages$WorkTimeTracking$ListElements$element_paymentsArchive,
						_List_fromArray(
							[model.bR]),
						model.lm,
						model.nr),
						A5(
						$author$project$Pages$WorkTimeTracking$ListElements$element_entriesList,
						$author$project$Pages$WorkTimeTracking$ListElements$Archive,
						model.eY,
						$author$project$Types$User$canSignWorkTimeEntries(user),
						_List_fromArray(
							[model.bR]),
						model.lm)
					])));
	});
var $author$project$Pages$WorkTimeTracking$view = F3(
	function (appContext, user, model) {
		var _v0 = model._;
		switch (_v0.$) {
			case 0:
				var subModel = _v0.a;
				return A2(
					$mdgriffith$elm_ui$Element$map,
					$author$project$Pages$WorkTimeTracking$Msg_AskingPin,
					$author$project$Components$PinDialog$view(subModel));
			case 1:
				var subModel = _v0.a;
				return A2(
					$mdgriffith$elm_ui$Element$map,
					$author$project$Pages$WorkTimeTracking$Msg_Loading,
					$author$project$Pages$WorkTimeTracking$State_Loading$view(subModel));
			case 2:
				var subModel = _v0.a;
				return A2(
					$mdgriffith$elm_ui$Element$map,
					$author$project$Pages$WorkTimeTracking$Msg_Main,
					A2($author$project$Pages$WorkTimeTracking$State_Main$view, user, subModel));
			case 3:
				var subModel = _v0.a;
				return A2(
					$mdgriffith$elm_ui$Element$map,
					$author$project$Pages$WorkTimeTracking$Msg_WorkerPage,
					A3($author$project$Pages$WorkTimeTracking$State_WorkerPage$view, appContext, user, subModel));
			default:
				var subModel = _v0.a;
				return A2(
					$mdgriffith$elm_ui$Element$map,
					$author$project$Pages$WorkTimeTracking$Msg_NoAccess,
					$author$project$Pages$State_NoAccess$view(subModel));
		}
	});
var $author$project$Main$view_LoggedIn = function (_v0) {
	var appContext = _v0.q;
	var currentPage = _v0.A;
	var selectedUser = _v0.aS;
	var topMenuModel = _v0.dG;
	var userNavigatorModel = _v0.bO;
	var userNavigator = A2(
		$elm$core$Maybe$withDefault,
		$mdgriffith$elm_ui$Element$none,
		A2(
			$elm$core$Maybe$map,
			A2(
				$elm$core$Basics$composeR,
				$author$project$Components$UserNavigator$view(selectedUser),
				$mdgriffith$elm_ui$Element$map($author$project$Main$GotUserNavigatorMsg)),
			userNavigatorModel));
	var topMenu = A2(
		$mdgriffith$elm_ui$Element$map,
		$author$project$Main$GotTopMenuMsg,
		A4($author$project$Components$TopMenu$view, appContext, selectedUser, currentPage, topMenuModel));
	var pageContent = function () {
		switch (currentPage.$) {
			case 0:
				var pageModel = currentPage.a;
				return A2(
					$mdgriffith$elm_ui$Element$map,
					$author$project$Main$GotHolidaysPageMsg,
					$author$project$Pages$Holidays$view(pageModel));
			case 1:
				var pageModel = currentPage.a;
				return A2(
					$mdgriffith$elm_ui$Element$map,
					$author$project$Main$GotInvoicingPageMsg,
					$author$project$Pages$Invoicing$view(pageModel));
			case 2:
				var pageModel = currentPage.a;
				return A2(
					$mdgriffith$elm_ui$Element$map,
					$author$project$Main$GotReportingPageMsg,
					A2($author$project$Pages$Reporting$view, selectedUser, pageModel));
			case 3:
				var pageModel = currentPage.a;
				return A2(
					$mdgriffith$elm_ui$Element$map,
					$author$project$Main$GotTasklistPageMsg,
					A2($author$project$Pages$Tasklist$view, appContext, pageModel));
			default:
				var pageModel = currentPage.a;
				return A2(
					$mdgriffith$elm_ui$Element$map,
					$author$project$Main$GotWorkTimeTrackingPageMsg,
					A3($author$project$Pages$WorkTimeTracking$view, appContext, selectedUser, pageModel));
		}
	}();
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$spacing(4)
			]),
		_List_fromArray(
			[userNavigator, topMenu, pageContent]));
};
var $author$project$Main$view = function (model) {
	var _v0 = function () {
		var _v1 = model._;
		switch (_v1.$) {
			case 0:
				var initializingModel = _v1.a;
				return _Utils_Tuple2(
					A2(
						$mdgriffith$elm_ui$Element$map,
						$author$project$Main$Msg_Initializing,
						$author$project$Main$view_Initializing(initializingModel)),
					'AutoDent');
			case 1:
				var authenticatingModel = _v1.a;
				return _Utils_Tuple2(
					A2(
						$mdgriffith$elm_ui$Element$map,
						$author$project$Main$Msg_Authenticating,
						$author$project$Main$view_Authenticating(authenticatingModel)),
					'AutoDent');
			default:
				var loggedInModel = _v1.a;
				return _Utils_Tuple2(
					A2(
						$mdgriffith$elm_ui$Element$map,
						$author$project$Main$Msg_LoggedIn,
						$author$project$Main$view_LoggedIn(loggedInModel)),
					loggedInModel.eM);
		}
	}();
	var contents = _v0.a;
	var pageTitle = _v0.b;
	return {
		kc: _List_fromArray(
			[
				A3(
				$mdgriffith$elm_ui$Element$layoutWith,
				{
					nk: _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$focusStyle($author$project$Styles$defaultFocus)
						])
				},
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$Background$color(
						$author$project$Colors$surface(0))
					]),
				A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
						]),
					_List_fromArray(
						[
							contents,
							$author$project$Components$Notification$view(model.P)
						])))
			]),
		oI: pageTitle
	};
};
var $author$project$Main$main = $elm$browser$Browser$document(
	{lS: $author$project$Main$init, oj: $author$project$Main$subscriptions, oQ: $author$project$Main$update, oZ: $author$project$Main$view});
_Platform_export({'Main':{'init':$author$project$Main$main(
	A2(
		$elm$json$Json$Decode$andThen,
		function (token) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (loginUrl) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (globalPin) {
							return A2(
								$elm$json$Json$Decode$andThen,
								function (dataRefetchIntervalMs) {
									return A2(
										$elm$json$Json$Decode$andThen,
										function (apiUrl) {
											return $elm$json$Json$Decode$succeed(
												{j0: apiUrl, av: dataRefetchIntervalMs, lA: globalPin, ag: loginUrl, oK: token});
										},
										A2($elm$json$Json$Decode$field, 'apiUrl', $elm$json$Json$Decode$string));
								},
								A2($elm$json$Json$Decode$field, 'dataRefetchIntervalMs', $elm$json$Json$Decode$int));
						},
						A2($elm$json$Json$Decode$field, 'globalPin', $elm$json$Json$Decode$string));
				},
				A2($elm$json$Json$Decode$field, 'loginUrl', $elm$json$Json$Decode$string));
		},
		A2(
			$elm$json$Json$Decode$field,
			'token',
			$elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
						A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$string)
					])))))(0)}});}(this));